import { BayLevelEnum, ForeAftEnum, } from "open-vessel-definition";
import { LCG_20_WITH_SEPARATION, SEPARATION_IN_BETWEEN, TCG_IN_MMM, generateCoordFunctions, sortRowsArray, } from "@tedivo/tedivo-bay-grid-pure";
import { feetToMillimeters, roundDec, sortNumericAsc, sortNumericDesc, } from "@tedivo/tedivo-pure-helpers";
import { createSvgText } from "../helpers/createSvgText";
import { drawDeckBridge } from "./drawParts/drawDeckBridge";
import { drawDeckSmokeStack } from "./drawParts/drawDeckSmokeStack";
import { generateTopContainerSymbols } from "./generateTopContainerSymbols";
import { lidDataForBaysValidatorFn } from "@tedivo/tedivo-ovd-validators";
import { makeHull } from "../helpers/convexHull";
import { setTopBayNumbers } from "../helpers/setTopBayNumbers";
const SVG_WIDTH = 1500;
export function createSimpleDeckView({ sizeSummary, baysData, masterCGs, lidData, lcgVcgTcgAndPairings, symbolsOptions, vesselPartsData, doDrawVesselParts = true, bayNumbersDoubleClickAction, svgGroupId = "createSimpleDeckView-g", }) {
    const { bayLevelPositionsAbove, bayLevelPositionsBelow, missingImportantVcgs, missingImportantLcgs, missingImportantTcgs, isoBaysArray, maxIsoBay, minLcg, maxLcg, maxVcg, minVcg, minTcg, maxTcg, totalSlotsCount, lcgsBy20Bay, blockBaysAndSizes, } = lcgVcgTcgAndPairings;
    const missingImportantXcgs = missingImportantTcgs || missingImportantLcgs || missingImportantVcgs;
    // 1. Add missing LCGs and VCGs if needed, return x,y functions
    const coordFns = generateCoordFunctions({
        missingImportantXcgs,
        maxIsoBay,
        sizeSummary,
        baysData,
        vesselPartsData,
        bayLevelPositionsAbove,
        bayLevelPositionsBelow,
        maxLcg,
        minLcg,
        maxVcg,
        minVcg,
        maxTcg,
        minTcg,
        lcgsBy20Bay,
        lcgNormalization: SVG_WIDTH,
        blockBaysAndSizes,
    });
    if (!coordFns)
        throw new Error("Failed to generate coord functions");
    const { xRange, zRange, xPos, zPos, scaled, addX, addZ, svgSizeRatio, allPartsLengthInMeters, sternLength, vOneBaySlots, } = coordFns;
    // 2. SVG Node
    const svgNode = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    if (xRange)
        svgNode.setAttribute("width", String(xRange));
    if (zRange)
        svgNode.setAttribute("height", String(zRange));
    if (xRange && zRange)
        svgNode.setAttribute("viewBox", `0 0 ${xRange} ${zRange}`);
    const svgGroup = document.createElementNS("http://www.w3.org/2000/svg", "g");
    svgGroup.setAttribute("id", svgGroupId);
    // 3. Add symbols
    const symbols = generateTopContainerSymbols(symbolsOptions || {}, svgSizeRatio);
    Object.keys(symbols).forEach((len) => {
        svgGroup.appendChild(symbols[len]);
    });
    // 4. Draw Vessel Parts
    drawVesselParts(doDrawVesselParts);
    // 5. Top bay numbers
    const drawTopBayNumbers = (blps) => setTopBayNumbers({
        blps,
        lcgsBy20Bay,
        svgNode: svgGroup,
        xPos,
        fontColor: symbolsOptions && symbolsOptions.fontColor,
        onDoubleClick: bayNumbersDoubleClickAction,
    });
    if (bayLevelPositionsAbove.length)
        drawTopBayNumbers(bayLevelPositionsAbove);
    else
        drawTopBayNumbers(bayLevelPositionsBelow);
    let validStats = {
        ok: 0,
        warning: 0,
        danger: 0,
    };
    if (totalSlotsCount > 0) {
        const blPosBelowOrAboveIfMissing = isoBaysArray
            .map((isoBay) => {
            var _a;
            const blp = bayLevelPositionsBelow.find((blp) => blp.isoBay === isoBay);
            if ((_a = blp === null || blp === void 0 ? void 0 : blp.sizes) === null || _a === void 0 ? void 0 : _a.length)
                return blp;
            return bayLevelPositionsAbove.find((blp) => blp.isoBay === isoBay);
        })
            .filter(Boolean);
        // 6. Ship Contour
        generateShipContour(missingImportantXcgs);
        // 7. Draw containers
        blPosBelowOrAboveIfMissing.forEach(generateContainers);
        // 8. Draw Lids
        const validStatsRet = drawLids(lcgsBy20Bay);
        if (validStatsRet)
            validStats = validStatsRet;
    }
    svgNode.appendChild(svgGroup);
    return {
        deckViewSvg: svgNode,
        missingImportantXcgs,
        xRange,
        zRange,
        xPos,
        zPos,
        lcgsBy20Bay,
        addX,
        validStats,
    };
    function generateContainers(blp) {
        const rows = Object.keys(blp.rows).sort();
        rows.forEach((rowName) => {
            const rowInfo = blp.rows[rowName];
            const maxSizeLcg = blp.maxSizeLcg;
            if (!blp.pairedHas40) {
                // If paired has 40, don't draw.
                const x = missingImportantXcgs &&
                    maxSizeLcg.size !== undefined &&
                    maxSizeLcg.size >= 40
                    ? (blp.pairedBay === ForeAftEnum.FWD
                        ? maxSizeLcg.foreLcg
                        : maxSizeLcg.aftLcg) || 0
                    : maxSizeLcg.lcg || 0;
                const y = rowInfo.tcg;
                if (x !== undefined && y !== undefined) {
                    const contSvg = document.createElementNS("http://www.w3.org/2000/svg", "use");
                    contSvg.setAttribute("href", `#dmbCn${maxSizeLcg.size}`);
                    contSvg.setAttribute("x", `${xPos(x)}`);
                    contSvg.setAttribute("y", `${zPos(y)}`);
                    if (blp.level === BayLevelEnum.ABOVE &&
                        (symbolsOptions === null || symbolsOptions === void 0 ? void 0 : symbolsOptions.dimmedStrokeColor)) {
                        contSvg.setAttribute("stroke", symbolsOptions.dimmedStrokeColor);
                        contSvg.setAttribute("stroke-opacity", "0.33");
                    }
                    contSvg.setAttribute("data-p", `${blp.isoBay}-${rowName}`);
                    contSvg.setAttribute("data-l", `${blp.level}`);
                    svgGroup.appendChild(contSvg);
                    if (symbolsOptions === null || symbolsOptions === void 0 ? void 0 : symbolsOptions.addRowLines) {
                        const textColor = (rowName === "00"
                            ? symbolsOptions === null || symbolsOptions === void 0 ? void 0 : symbolsOptions.fontColor
                            : Number(rowName) % 2 === 0
                                ? symbolsOptions === null || symbolsOptions === void 0 ? void 0 : symbolsOptions.portColor
                                : symbolsOptions === null || symbolsOptions === void 0 ? void 0 : symbolsOptions.stbdColor) || (symbolsOptions === null || symbolsOptions === void 0 ? void 0 : symbolsOptions.fontColor);
                        const stckNumber = createSvgText({
                            text: rowName,
                            x: 0,
                            y: 0,
                            fontSize: 7,
                            textColor: blp.level === BayLevelEnum.BELOW
                                ? textColor
                                : (symbolsOptions === null || symbolsOptions === void 0 ? void 0 : symbolsOptions.dimmedStrokeColor) || "#ddd",
                        });
                        stckNumber.setAttribute("transform", `rotate(90, 0,0) translate(${zPos(y)}, ${-xPos(x)} )`);
                        svgGroup.appendChild(stckNumber);
                    }
                }
            }
        });
    }
    function createSameBayAbBeSizesAndCgs(blpsAbove, blpsBelow) {
        const aboveBelowBlps = {};
        blpsAbove.forEach((blpAb) => {
            const { portTcg, starboardTcg, lcg, foreLcg, aftLcg, maxSize } = getRelevantXcgs(blpAb, true);
            addToBay(blpAb, portTcg, starboardTcg, lcg, foreLcg, aftLcg, maxSize);
        });
        blpsBelow.forEach((blpBe) => {
            const { portTcg, starboardTcg, lcg, foreLcg, aftLcg, maxSize } = getRelevantXcgs(blpBe, false);
            addToBay(blpBe, portTcg, starboardTcg, lcg, foreLcg, aftLcg, maxSize);
        });
        supplyB20sLongPointsFor40s();
        return aboveBelowBlps;
        function addToBay(blp, portTcg, starboardTcg, lcg, foreLcg, aftLcg, maxSize) {
            let pairedInstance = aboveBelowBlps[blp.isoBay];
            if (pairedInstance === undefined) {
                aboveBelowBlps[blp.isoBay] = {
                    aftLcg: aftLcg,
                    foreLcg: foreLcg,
                    lcg: lcg,
                    portTcg: 0,
                    starboardTcg: 0,
                    pairedBay: blp.pairedBay,
                    maxSize,
                    b20: maxSize < 40
                        ? {
                            fwdLPoint: foreLcg,
                            aftLPoint: aftLcg,
                        }
                        : undefined,
                };
                pairedInstance = aboveBelowBlps[blp.isoBay];
            }
            if (portTcg < pairedInstance.portTcg)
                pairedInstance.portTcg = roundDec(portTcg, 4);
            if (starboardTcg > pairedInstance.starboardTcg)
                pairedInstance.starboardTcg = roundDec(starboardTcg, 4);
        }
        function getRelevantXcgs(blp, isAbove) {
            var _a, _b;
            const tiers = Object.keys(blp.tiers).sort(isAbove ? sortNumericAsc : sortNumericDesc);
            const lcg = !missingImportantXcgs
                ? blp.maxSizeLcg.lcg || 0
                : (maxIsoBay + 1) * 0.5 * LCG_20_WITH_SEPARATION -
                    SEPARATION_IN_BETWEEN -
                    Number(blp.isoBay) * 0.5 * LCG_20_WITH_SEPARATION;
            const rows = Object.keys(blp.rows).sort(sortRowsArray);
            const allTcgs = rows.map((row) => blp.rows[row].tcg || 0);
            const portTcg = allTcgs[0];
            const starboardTcg = allTcgs[allTcgs.length - 1];
            return {
                vcg: (_a = blp.tiers[tiers[0]]) === null || _a === void 0 ? void 0 : _a.vcg,
                portTcg,
                starboardTcg,
                lcg: roundDec(lcg, 4),
                maxSize: (_b = blp.tiers[tiers[0]]) === null || _b === void 0 ? void 0 : _b.maxSize,
                foreLcg: roundDec(blp.maxSizeLcg.foreLcg || 0, 4),
                aftLcg: roundDec(blp.maxSizeLcg.aftLcg || 0, 4),
            };
        }
        function supplyB20sLongPointsFor40s() {
            const bays = Object.keys(aboveBelowBlps).sort();
            bays.forEach((bay) => {
                const bp = aboveBelowBlps[bay];
                if (bp.b20 || bp.maxSize < 40 || bp.pairedBay === undefined)
                    return;
                const midMaxSize = bp.maxSize * 0.5;
                const isPairedFwd = bp.pairedBay === ForeAftEnum.FWD;
                bp.b20 = {
                    fwdLPoint: isPairedFwd
                        ? bp.lcg
                        : bp.lcg + feetToMillimeters(midMaxSize || 0),
                    aftLPoint: isPairedFwd
                        ? bp.lcg - feetToMillimeters(midMaxSize || 0)
                        : bp.lcg,
                };
            });
        }
    }
    function generateShipContour(missingImportantXcgs) {
        const depthInMilimeters = roundDec(TCG_IN_MMM * svgSizeRatio, 1);
        const pairedBlps = createSameBayAbBeSizesAndCgs(bayLevelPositionsAbove, bayLevelPositionsBelow);
        const segmentsPort = [];
        const segmentsStbd = [];
        let prevPortTcg = undefined;
        let prevStarboardTcg = undefined;
        Object.keys(pairedBlps).forEach((isoBay) => {
            const paired = pairedBlps[isoBay];
            if (paired.portTcg === 0 && paired.starboardTcg === 0)
                return;
            const portBorder = roundDec(paired.portTcg - depthInMilimeters * 0.5, 2) - 2000;
            const stbdBorder = roundDec(paired.starboardTcg + depthInMilimeters * 0.5, 2) + 2000;
            // Add parts of line
            const fLcg = paired.foreLcg + (missingImportantXcgs ? 6000 : 0);
            const aLcg = paired.aftLcg;
            // Port side
            segmentsPort.push([xPos(fLcg, 1), zPos(portBorder, 1)]);
            segmentsPort.push([xPos(aLcg, 1), zPos(portBorder, 1)]);
            // Stbd side
            segmentsStbd.push([xPos(fLcg, 1), zPos(stbdBorder, 1)]);
            segmentsStbd.push([xPos(aLcg, 1), zPos(stbdBorder, 1)]);
            if ((prevPortTcg || 0) > portBorder)
                prevPortTcg = portBorder;
            if ((prevStarboardTcg || 0) < stbdBorder)
                prevStarboardTcg = stbdBorder;
        });
        // Finally, add stern
        segmentsPort.push([roundDec(addX * 0.25), zPos(prevPortTcg || 0, 1)]);
        segmentsStbd.push([roundDec(addX * 0.25), zPos(prevStarboardTcg || 0, 1)]);
        const bow = [
            [roundDec(segmentsPort[0][0]), roundDec(segmentsPort[0][1])],
            [xRange, roundDec(zRange * 0.5)],
            [roundDec(segmentsStbd[0][0]), roundDec(segmentsStbd[0][1])],
        ];
        const allSegments = [
            ...segmentsPort.reverse(),
            ...bow,
            ...segmentsStbd,
        ].map(([x, y]) => ({ x, y }));
        const uniqueSegments = makeHull(allSegments).map((point) => [
            point.x,
            point.y,
        ]);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const initialPoint = uniqueSegments.shift();
        const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
        path.setAttribute("stroke", (symbolsOptions === null || symbolsOptions === void 0 ? void 0 : symbolsOptions.shipStrokeColor) || "red");
        path.setAttribute("stroke-width", String((symbolsOptions === null || symbolsOptions === void 0 ? void 0 : symbolsOptions.strokeWidth) || 0.1));
        path.setAttribute("fill-opacity", "0");
        path.setAttribute("d", `M${initialPoint[0]},${initialPoint[1]} ${uniqueSegments
            .map((seg) => `L${seg[0]},${seg[1]}`)
            .join(" ")} z`);
        svgGroup.appendChild(path);
    }
    function drawLids(lcgsBy20Bay) {
        const halfDepthInMilimeters = roundDec(TCG_IN_MMM * 0.5, 1); // Doesn't use svgSizeRatio because xPos is applied later
        if (sizeSummary.maxRow === undefined)
            return;
        const validStats = {
            ok: 0,
            warning: 0,
            danger: 0,
        };
        // Validate lids
        const { result: { rowTcgsByBay, allLids: lidsDataWithValidation }, } = lidDataForBaysValidatorFn({
            data: {
                json: { sizeSummary, lidData },
                lcgVcgTcgAndPairings,
                lcgsBy20Bay,
            },
        });
        const tempMasterBeloTcgs = {};
        [
            ...lcgVcgTcgAndPairings.bayLevelPositionsBelow,
            ...lcgVcgTcgAndPairings.bayLevelPositionsAbove,
        ].forEach((blp) => {
            const rows = Object.keys(blp.rows);
            rows.forEach((row) => {
                var _a, _b;
                const maxTcg = Math.abs(tempMasterBeloTcgs[row] || 0);
                if (maxTcg < Math.abs(blp.rows[row].tcg || 0))
                    tempMasterBeloTcgs[row] = ((_b = (_a = blp.rows) === null || _a === void 0 ? void 0 : _a[row]) === null || _b === void 0 ? void 0 : _b.tcg) || 0;
            });
        });
        // Draw lids
        lidsDataWithValidation
            .filter((lid) => lcgsBy20Bay[lid.startIsoBay] !== undefined &&
            lcgsBy20Bay[lid.endIsoBay] !== undefined)
            .forEach((lid, idx) => {
            const foreLcg = lcgsBy20Bay[lid.startIsoBay].foreLcg;
            const aftLcg = lcgsBy20Bay[lid.endIsoBay].aftLcg;
            let portTcg = rowTcgsByBay[lid.startIsoBay][lid.portIsoRow] ||
                rowTcgsByBay[lid.endIsoBay][lid.portIsoRow] ||
                tempMasterBeloTcgs[lid.portIsoRow];
            let stbdTcg = rowTcgsByBay[lid.startIsoBay][lid.starboardIsoRow] ||
                rowTcgsByBay[lid.endIsoBay][lid.starboardIsoRow] ||
                tempMasterBeloTcgs[lid.starboardIsoRow];
            if (portTcg === undefined) {
                portTcg =
                    masterCGs.belowTcgs[lid.portIsoRow] ||
                        masterCGs.aboveTcgs[lid.portIsoRow];
            }
            if (stbdTcg === undefined) {
                stbdTcg =
                    masterCGs.belowTcgs[lid.starboardIsoRow] ||
                        masterCGs.aboveTcgs[lid.starboardIsoRow];
            }
            if (foreLcg !== undefined &&
                aftLcg !== undefined &&
                portTcg !== undefined &&
                stbdTcg !== undefined) {
                portTcg -= halfDepthInMilimeters;
                stbdTcg += halfDepthInMilimeters;
                const lidColor = lid.notValid === "danger"
                    ? (symbolsOptions === null || symbolsOptions === void 0 ? void 0 : symbolsOptions.lidDangerFillColor) || "red"
                    : lid.notValid === "warning"
                        ? (symbolsOptions === null || symbolsOptions === void 0 ? void 0 : symbolsOptions.lidWarningFillColor) || "yellow"
                        : (symbolsOptions === null || symbolsOptions === void 0 ? void 0 : symbolsOptions.lidFillColor) || "black";
                if (lid.notValid !== undefined)
                    validStats[lid.notValid]++;
                else
                    validStats.ok++;
                const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
                path.setAttribute("stroke", (symbolsOptions === null || symbolsOptions === void 0 ? void 0 : symbolsOptions.shipStrokeColor) || "red");
                path.setAttribute("stroke-width", String((symbolsOptions === null || symbolsOptions === void 0 ? void 0 : symbolsOptions.strokeWidth) || 0.1));
                path.setAttribute("fill", lidColor);
                path.setAttribute("fill-opacity", "0.4");
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                path.setAttribute("data-idx", lid.pk || String(idx));
                path.setAttribute("data-z", String(Math.round((xPos(aftLcg) + xPos(foreLcg)) * 0.5)));
                path.setAttribute("data-x", String(Math.round((zPos(portTcg) + zPos(stbdTcg)) * 0.5)));
                path.setAttribute("d", `M${xPos(aftLcg, 2)},${zPos(portTcg)}
              L${xPos(foreLcg, 2)},${zPos(portTcg)}
              L${xPos(foreLcg, 2)},${zPos(stbdTcg)}
              L${xPos(aftLcg, 2)},${zPos(stbdTcg)}
              z`);
                const xText = xPos((aftLcg + foreLcg) * 0.5, 2), yText = zPos((portTcg + stbdTcg) * 0.5, 2);
                const title = createSvgText({
                    text: lid.label,
                    x: 0,
                    y: (symbolsOptions === null || symbolsOptions === void 0 ? void 0 : symbolsOptions.addRowLines) ? 4 : 0,
                    textColor: (symbolsOptions === null || symbolsOptions === void 0 ? void 0 : symbolsOptions.lidTextColor) || "red",
                    fontSize: 8,
                });
                title.setAttribute("transform", `rotate(90, 0,0) translate(${yText}, ${-xText} )`);
                if (lid.overlapPort) {
                    const overlapPort = document.createElementNS("http://www.w3.org/2000/svg", "line");
                    overlapPort.setAttribute("stroke", (symbolsOptions === null || symbolsOptions === void 0 ? void 0 : symbolsOptions.shipStrokeColor) || "red");
                    overlapPort.setAttribute("stroke-width", String(((symbolsOptions === null || symbolsOptions === void 0 ? void 0 : symbolsOptions.strokeWidth) || 0.1) * 4));
                    overlapPort.setAttribute("x1", String(xPos(aftLcg, 2)));
                    overlapPort.setAttribute("y1", String(zPos(portTcg, 2)));
                    overlapPort.setAttribute("x2", String(xPos(foreLcg, 2)));
                    overlapPort.setAttribute("y2", String(zPos(portTcg, 2)));
                    overlapPort.setAttribute("stroke-linejoin", "round");
                    svgGroup.appendChild(overlapPort);
                }
                if (lid.overlapStarboard) {
                    const overlapStbd = document.createElementNS("http://www.w3.org/2000/svg", "line");
                    overlapStbd.setAttribute("stroke", (symbolsOptions === null || symbolsOptions === void 0 ? void 0 : symbolsOptions.shipStrokeColor) || "red");
                    overlapStbd.setAttribute("stroke-width", String(((symbolsOptions === null || symbolsOptions === void 0 ? void 0 : symbolsOptions.strokeWidth) || 0.1) * 4));
                    overlapStbd.setAttribute("x1", String(xPos(aftLcg, 2)));
                    overlapStbd.setAttribute("y1", String(zPos(stbdTcg, 2)));
                    overlapStbd.setAttribute("x2", String(xPos(foreLcg, 2)));
                    overlapStbd.setAttribute("y2", String(zPos(stbdTcg, 2)));
                    svgGroup.appendChild(overlapStbd);
                }
                svgGroup.appendChild(path);
                svgGroup.appendChild(title);
            }
        });
        return validStats;
    }
    function drawVesselParts(doDrawVesselParts) {
        if (!doDrawVesselParts)
            return;
        const rightOfShip = scaled(allPartsLengthInMeters + sternLength) + addX * 0.5;
        // console.log(
        //   "rightOfShip",
        //   { allPartsLengthInMeters, bowLength, sternLength },
        //   scaled(allPartsLengthInMeters + bowLength + sternLength),
        // );
        // const path2 = document.createElementNS(
        //   "http://www.w3.org/2000/svg",
        //   "path",
        // );
        // const ps: number[] = [];
        // vOneBaySlots.forEach((vOneBay, idx) => {
        //   const posX100 = vOneBay.positionX * 100;
        //   ps.push(feetToMillimeters(posX100));
        // });
        // console.log(ps);
        // path2.setAttribute("stroke", "red");
        // path2.setAttribute("stroke-width", "1");
        // path2.setAttribute(
        //   "d",
        //   ps.reduce((acc, po, idx) => {
        //     const p = rightOfShip - scaled(po);
        //     return `${acc} M${p},0 L${p},${yRange} `;
        //   }, ""),
        // );
        // svgNode.appendChild(path2);
        vOneBaySlots.forEach((vOneBay, idx) => {
            var _a, _b;
            if (vOneBay.type === "BAY")
                return;
            const posX100 = vOneBay.positionX * 100;
            const nextPosX100 = ((_a = vOneBaySlots[idx + 1]) === null || _a === void 0 ? void 0 : _a.positionX) * 100;
            let xtra = 0;
            const nextLen = (_b = vOneBaySlots[idx + 1]) === null || _b === void 0 ? void 0 : _b.len;
            if (nextLen !== undefined && nextLen > 40) {
                xtra = scaled(feetToMillimeters(nextLen - 40) * 0.5);
            }
            const cPosX = feetToMillimeters(posX100);
            const nPosX = feetToMillimeters(nextPosX100 !== undefined ? nextPosX100 - xtra : posX100 + vOneBay.len);
            const x = rightOfShip - scaled(nPosX * 0.5 + cPosX * 0.5);
            let paths = undefined;
            switch (vOneBay.type) {
                case "BRG":
                    paths = drawDeckBridge(vOneBay, zRange, addZ, svgSizeRatio);
                    break;
                case "SMK":
                    paths = drawDeckSmokeStack(vOneBay, zRange, addZ, svgSizeRatio);
                    break;
                // case "CRN":
                //   paths = drawSideCrane(vOneBay, midVcg, svgSizeRatio);
                //   break;
            }
            if (paths) {
                paths.forEach((p) => {
                    p.setAttribute("transform", `translate(${x}, ${0})`);
                    svgNode.appendChild(p);
                });
            }
        });
    }
}
