import { __awaiter } from "tslib";
import "./login.component.scss";
import { TedivoForm, translateTedivoForm } from "@tedivo/tedivo-form";
import { createScreen } from "../../../helpers/createScreen";
import awsCognito from "../../../../app/security/awsCognito";
import { getTranslation, i18nReactive } from "../../../../app/i18/i18tn";
import { I18nComponentRegisterer } from "@tedivo/tedivo-i18";
import { IntegratedDialogError } from "@tedivo/tedivo-ui";
import Services from "../../../../app/services";
import { getPreferencesValue } from "@tedivo/tedivo-preferences";
import goSquared from "../../../../app/tracking/goSquared";
import { passwordCompliesWithCriteria } from "@tedivo/tvd-api-models";
import { removeChildren } from "@tedivo/tedivo-dom-helpers";
import { routeFns } from "../../../../app/router/routes";
import router from "../../../../app/router";
import securityModule from "../../../../app/security/SecurityModule";
import { setAppTitle } from "../../../../app/setAppTitle";
import { showDetailedCriteriaCompliance } from "../../my-organization/change-password.component";
import topMessageElement from "../../../layout/top-tools/getTopMessageElement";
import { z } from "zod";
export class TVDAuthLoginComponent extends HTMLElement {
    constructor() {
        super();
        this.didChangePassword = false;
        this.prevLoginStatus = undefined;
        this.dialogError = new IntegratedDialogError(this, false, getTranslation("general:common.close"));
        this.returnUrl = routeFns.myCloud();
        this.doesAcceptMarketing = undefined;
        /** Replaces the UI */
        this.setCurrentFormAndNode = (fan) => {
            this.currentFormAndNode = fan;
            if (fan === null || fan === void 0 ? void 0 : fan.node) {
                this.divWithSpinner.setLoading(false);
                removeChildren(this.divWithSpinner);
                this.divWithSpinner.appendChild(fan.node);
            }
        };
        this.onShowScreen = () => {
            this.showScreen(false);
        };
        /** Shows/Replaces the log-in screen based on securityModule.isLoggedIn, force = false */
        this.showScreen = (force = false) => {
            var _a;
            const { isLoggedIn } = securityModule;
            if (isLoggedIn) {
                router.navigate(routeFns.myProfile(), undefined, true);
                return;
            }
            if (this.prevLoginStatus === isLoggedIn && !force)
                return;
            this.prepend(this.h1Node);
            setAppTitle(getTranslation("auth:logIn"));
            goSquared.addEvent("Log-In - Show page");
            const state = router.currentState;
            const queryString = router.getRouteQuerystring();
            const loginForm = createLoginFields(this.h1Node, this.onSubmitLoginForm, this.i18nCR, (state === null || state === void 0 ? void 0 : state.email) || (queryString === null || queryString === void 0 ? void 0 : queryString.email), state === null || state === void 0 ? void 0 : state.password, (state === null || state === void 0 ? void 0 : state.fromVerification) || false, !!queryString.hint);
            const h1Node = loginForm.node.querySelector("h1");
            if (h1Node)
                this.prepend(h1Node);
            const divCenteredInteractive = createLoginPart({
                loginForm,
                i18nCR: this.i18nCR,
                initialCreateAccount: (_a = window.location.hash) === null || _a === void 0 ? void 0 : _a.includes("newAccount"),
            });
            this.setCurrentFormAndNode(Object.assign(Object.assign({}, loginForm), { node: divCenteredInteractive }));
            if (queryString.returnUrl)
                this.returnUrl = queryString.returnUrl;
            this.prevLoginStatus = isLoggedIn;
        };
        this.onSubmitLoginForm = (values) => __awaiter(this, void 0, void 0, function* () {
            this.divWithSpinner.setLoading(true);
            const initialAuthResp = yield securityModule.authenticate(values.username, values.password);
            try {
                goSquared.addEvent("Log-In - Submit credentials");
                yield this.onSubmitAuthForm(initialAuthResp, values.username);
            }
            catch (e) {
                console.log(e);
                this.showError({
                    errorCode: e,
                    message: "",
                    translationKey: "errors:loginAttemptIncorrect",
                });
            }
            this.divWithSpinner.setLoading(false);
        });
        this.onSubmitAuthForm = (authResp, username) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const errorMessage = ((_a = authResp.error) === null || _a === void 0 ? void 0 : _a.message) || "";
            let translationKey = errorMessage;
            switch (authResp.code) {
                case "ok":
                    {
                        if (this.didChangePassword)
                            Services.accounts.notifyNewPassword();
                        if (this.doesAcceptMarketing !== undefined) {
                            Services.accounts.updateUserMarketingPreferences(this.doesAcceptMarketing);
                            this.doesAcceptMarketing = undefined;
                        }
                        topMessageElement.planIsExpired = securityModule.planIsExpired;
                        const shouldShowMfa = securityModule.organizationMfaForAllUsers &&
                            !securityModule.isMfaEnabled;
                        if (shouldShowMfa) {
                            router.navigate(routeFns.myProfile(true), undefined, true);
                        }
                        else {
                            const hasMultipleOrgs = awsCognito.allOrganizationsInfo.length > 1;
                            if (hasMultipleOrgs) {
                                const preferredOrgId = getPreferencesValue("preferredOrgId");
                                if (preferredOrgId) {
                                    awsCognito.changeCurrentOrganization(preferredOrgId);
                                    if (awsCognito.currentOrganizationId) {
                                        router.navigate(routeFns.myCloud(), undefined, true);
                                    }
                                    else {
                                        router.navigate(routeFns.myProfile(shouldShowMfa), undefined, true);
                                    }
                                }
                                else {
                                    router.navigate(routeFns.myProfile(shouldShowMfa), undefined, true);
                                }
                            }
                            else {
                                router.navigate(this.returnUrl || routeFns.myCloud(), undefined, true);
                            }
                        }
                    }
                    break;
                case "failure":
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    if (((_b = authResp.error) === null || _b === void 0 ? void 0 : _b.name) === "UserNotFoundException") {
                        this.showError("auth:loggedInFailed");
                        break;
                    }
                    if (errorMessage === "ORG_DISABLED" ||
                        errorMessage === "USER_DISABLED" ||
                        errorMessage === "EXPIRED_SUBSCRIPTION") {
                        translationKey = `enums:EnumEnabledStatus.${errorMessage}`;
                    }
                    this.showError({
                        errorCode: "",
                        message: errorMessage,
                        translationKey,
                    });
                    break;
                case "newPasswordRequired":
                    this.setCurrentFormAndNode(createNewPasswordFields(this.h1Node, (newValues) => __awaiter(this, void 0, void 0, function* () {
                        this.divWithSpinner.setLoading(true);
                        const nextAuthResp = yield securityModule.setNewPassword(username, newValues.password, !!newValues.acceptsMarketing);
                        this.doesAcceptMarketing = !!newValues.acceptsMarketing;
                        awsCognito.tempAcceptsMarketingMails = this.doesAcceptMarketing;
                        this.didChangePassword = true;
                        yield this.onSubmitAuthForm(nextAuthResp, username);
                        this.divWithSpinner.setLoading(false);
                    }), this.i18nCR));
                    break;
                case "totpRequired":
                    this.setCurrentFormAndNode(createVerifyMfaFields(this.h1Node, (newValues) => __awaiter(this, void 0, void 0, function* () {
                        this.divWithSpinner.setLoading(true);
                        const nextAuthResp = yield awsCognito.verifyTopTCode(String(newValues.code), username);
                        yield this.onSubmitAuthForm(nextAuthResp, username);
                        this.divWithSpinner.setLoading(false);
                    }), this.i18nCR));
                    break;
            }
            return authResp.code;
        });
        this.showError = (e) => {
            this.dialogError.show(getTranslation(`errors:errorHasOcurred`), getTranslation(typeof e === "string" ? e : e.translationKey));
            this.dialogError.onButtonClicked = () => {
                this.showScreen(true);
            };
            console.error(e);
        };
        this.i18nCR = new I18nComponentRegisterer(i18nReactive);
        this.divWithSpinner = document.createElement("div-spinner-element");
        this.h1Node = document.createElement("h1");
        setAppTitle(getTranslation("auth:logIn"));
    }
    connectedCallback() {
        this.appendChild(this.divWithSpinner);
        this.showScreen();
        goSquared.trackPage("Log-In");
        securityModule.addEventListener("loggedIn", this.onShowScreen);
        securityModule.addEventListener("loggedOut", this.onShowScreen);
    }
    disconnectedCallback() {
        this.i18nCR.disconnect();
        securityModule.removeEventListener("loggedIn", this.onShowScreen);
        securityModule.removeEventListener("loggedOut", this.onShowScreen);
    }
}
TVDAuthLoginComponent.observedAttributes = [];
customElements.define("tvd-auth-login-component", TVDAuthLoginComponent);
function createLoginFields(h1Node, onSubmitDataForm, i18nCR, email, password, fromVerification, hintPasswordFromEmail) {
    const AuthLoginFormValidator = z.object({
        username: z.string(),
        password: z.string().min(8),
    });
    const fields = [
        {
            type: "title",
            label: "auth:logIn",
        },
        {
            type: "textBox",
            name: "username",
            label: "auth:email",
            autoCompleteAttribute: "username",
            initialValue: email || undefined,
        },
        {
            type: "textBox",
            name: "password",
            label: "auth:password",
            autoCompleteAttribute: "current-password",
            isPassword: true,
            initialValue: password || undefined,
            placeholder: hintPasswordFromEmail
                ? getTranslation("auth:passwordFromEmail")
                : undefined,
        },
    ];
    const screen = createScreen({
        fields,
        onSubmitDataForm,
        formValidator: AuthLoginFormValidator,
        i18nCR,
        titleText: "auth:logIn",
        h1Text: "",
        h1Node,
    });
    const icon1 = document.createElement("img");
    icon1.setAttribute("title", getTranslation("general:appName"));
    icon1.setAttribute("alt", getTranslation("general:appName"));
    icon1.setAttribute("width", "522");
    icon1.setAttribute("heigth", "136");
    icon1.setAttribute("src", "/assets/images/logo_Designer-horizontal-color.svg");
    icon1.className = "tvd-h1-icon logo-light";
    const icon2 = document.createElement("img");
    icon2.setAttribute("title", getTranslation("general:appName"));
    icon2.setAttribute("alt", getTranslation("general:appName"));
    icon2.setAttribute("width", "522");
    icon2.setAttribute("heigth", "136");
    icon2.setAttribute("src", "/assets/images/logo_Designer-horizontal-white.svg");
    icon2.className = "tvd-h1-icon logo-dark";
    removeChildren(h1Node);
    h1Node.appendChild(icon1);
    h1Node.appendChild(icon2);
    const screenForm = screen.form;
    if (!screenForm)
        return screen;
    const controls = screenForm.getFormControlsByName();
    const userNameField = controls.username.field;
    userNameField.type = "email";
    userNameField.autocomplete = "username";
    userNameField.autocomplete = "current-password";
    // Proceed to log-in after verification
    if (fromVerification) {
        onSubmitDataForm(screenForm.getValues());
    }
    // Below the form, we add a link to the forgot password page
    const forgotPasswordLink = document.createElement("link-element");
    i18nCR.addConsumer(forgotPasswordLink, "auth:forgotPassword", "text");
    forgotPasswordLink.setAttribute("url", routeFns.forgotPassword());
    const orSeparator = document.createElement("span");
    orSeparator.className = "text-with-separation";
    i18nCR.addConsumer(orSeparator, "general:common.or", "innerHTML");
    screenForm.form.appendChild(orSeparator);
    screenForm.form.appendChild(forgotPasswordLink);
    return screen;
}
function createLoginPart({ loginForm, i18nCR, initialCreateAccount, }) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const tedFormNode = loginForm.node.querySelector(".tedivo-form");
    tedFormNode.classList.add("login-form-part");
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const h2 = tedFormNode.querySelector("h2");
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const parent = h2.parentNode;
    const h3 = document.createElement("h3");
    i18nCR.addConsumer(h3, "general:welcomeComp.subTitleLogin", "innerHTML");
    parent.appendChild(h3);
    const divCenteredInteractive = document.createElement("div");
    divCenteredInteractive.className = "login-screen-interactive";
    const divCentralFormsPart = document.createElement("div");
    divCentralFormsPart.className = "login-screen-split";
    divCenteredInteractive.appendChild(divCentralFormsPart);
    const { newAccountForm, node: newPartNode } = createNewHerePart();
    divCentralFormsPart.appendChild(tedFormNode);
    divCentralFormsPart.appendChild(createSlider());
    divCentralFormsPart.appendChild(newPartNode);
    if (initialCreateAccount)
        divCenteredInteractive.dataset.show = "newAccount";
    return divCenteredInteractive;
    function togglePart() {
        var _a;
        const currentPart = divCenteredInteractive.dataset.show || "";
        const isLogin = currentPart === "";
        if (isLogin) {
            divCenteredInteractive.dataset.show = "newAccount";
            newAccountForm === null || newAccountForm === void 0 ? void 0 : newAccountForm.focusOnFirstElement();
        }
        else {
            divCenteredInteractive.dataset.show = "";
            (_a = loginForm.form) === null || _a === void 0 ? void 0 : _a.focusOnFirstElement();
        }
    }
    function createSlider() {
        const newHerePart = document.createElement("div");
        newHerePart.className = "login-slider";
        const card1 = document.createElement("div");
        card1.className = "card-1";
        {
            const h2 = document.createElement("h2");
            i18nCR.addConsumer(h2, "general:welcomeComp.slider1.title", "innerHTML");
            const text = document.createElement("div");
            i18nCR.addConsumer(text, "general:welcomeComp.slider1.text", "innerHTML");
            const btn = document.createElement("sl-button");
            btn.className = "login-button-home";
            btn.variant = "primary";
            i18nCR.addConsumer(btn, "general:welcomeComp.slider1.button", "innerHTML");
            btn.onclick = () => {
                togglePart();
            };
            newHerePart.appendChild(card1);
            card1.appendChild(h2);
            card1.appendChild(text);
            card1.appendChild(btn);
        }
        const card2 = document.createElement("div");
        card2.className = "card-2";
        {
            const h2 = document.createElement("h2");
            i18nCR.addConsumer(h2, "general:welcomeComp.slider2.title", "innerHTML");
            const text = document.createElement("div");
            i18nCR.addConsumer(text, "general:welcomeComp.slider2.text", "innerHTML");
            const btn = document.createElement("sl-button");
            btn.className = "login-button-home";
            btn.variant = "primary";
            i18nCR.addConsumer(btn, "general:welcomeComp.slider2.button", "innerHTML");
            btn.onclick = () => {
                togglePart();
            };
            newHerePart.appendChild(card2);
            card2.appendChild(h2);
            card2.appendChild(text);
            card2.appendChild(btn);
        }
        return newHerePart;
    }
    function createNewHerePart() {
        const newHerePart = document.createElement("div");
        newHerePart.className = "login-new-here-part ";
        const ossCard = document.createElement("div");
        ossCard.className = "centered-content";
        const h2 = document.createElement("h2");
        i18nCR.addConsumer(h2, "general:welcomeComp.createNewAccount.title", "innerHTML");
        const newHere = document.createElement("div");
        newHere.className = "new-here-text";
        i18nCR.addConsumer(newHere, "general:welcomeComp.createNewAccount.text", "innerHTML");
        const newAccountBtn = document.createElement("sl-button");
        newAccountBtn.variant = "primary";
        i18nCR.addConsumer(newAccountBtn, "general:welcomeComp.createNewAccount.button", "innerHTML");
        const formValidator = z.object({
            email: z.string().email(),
        });
        const fields = [
            {
                type: "textBox",
                name: "email",
                label: "",
                placeholder: "general:common.workEmail",
            },
        ];
        const newAccountTedivoForm = new TedivoForm({
            fields: fields,
            onSubmit: onSubmitDataForm,
            formValidator: formValidator,
            submitButton: newAccountBtn,
        });
        newAccountBtn.addEventListener("click", () => {
            newAccountTedivoForm.doSubmitForm();
        });
        translateTedivoForm({
            tedivoForm: newAccountTedivoForm,
            i18nCR: i18nCR,
        });
        newHerePart.appendChild(ossCard);
        ossCard.appendChild(h2);
        ossCard.appendChild(newHere);
        ossCard.appendChild(newAccountTedivoForm.form);
        ossCard.appendChild(newAccountBtn);
        return { newAccountForm: newAccountTedivoForm, node: newHerePart };
        function onSubmitDataForm(vals) {
            router.navigate(`${routeFns.newAccount()}?email=${vals.email}`, undefined, true);
        }
    }
}
function createNewPasswordFields(h1Node, onSubmitDataForm, i18nCR) {
    const AuthLoginFormValidator = z
        .object({
        password: z
            .string()
            .refine((pswd) => passwordCompliesWithCriteria(pswd).length === 0),
        retypePassword: z.string(),
        acceptsMarketing: z.boolean().optional(),
        acceptsConditions: z.boolean().refine((v) => !!v),
        acceptsPrivacyPolicy: z.boolean().refine((v) => !!v),
    })
        .refine((vals) => vals.password === vals.retypePassword, {
        path: ["retypePassword"],
    });
    const passReqs = document.createElement("div");
    passReqs.className = "oss-password-compliance-container";
    const passwordReqsTitle = document.createElement("strong");
    passwordReqsTitle.innerHTML = getTranslation("auth:passwordSchemaTitle");
    passReqs.appendChild(passwordReqsTitle);
    const ul = document.createElement("ul");
    ul.className = "oss-password-compliance";
    passReqs.appendChild(ul);
    const fields = [
        [
            {
                type: "textBox",
                name: "password",
                autoCompleteAttribute: "new-password",
                label: "auth:password",
                isPassword: true,
                helpText: "auth:passwordSchema",
                inputListener: true,
            },
            {
                type: "textBox",
                name: "retypePassword",
                label: "auth:retypePassword",
                isPassword: true,
                helpText: "auth:passwordsMustMatch",
            },
        ],
        {
            name: "password",
            type: "node",
            node: passReqs,
        },
        {
            type: "checkbox",
            name: "acceptsConditions",
            label: "general:common.acceptsConditions",
            initialValue: false,
        },
        {
            type: "checkbox",
            name: "acceptsPrivacyPolicy",
            label: "general:common.acceptsPrivacyPolicy",
            initialValue: false,
        },
        {
            type: "checkbox",
            name: "acceptsMarketing",
            label: "general:common.acceptsMarketing",
            initialValue: false,
        },
    ];
    const screen = createScreen({
        fields,
        onSubmitDataForm,
        formValidator: AuthLoginFormValidator,
        i18nCR,
        h1Text: "auth:setNewPassword",
        titleText: "auth:logIn",
        h1Node,
    });
    const tedivoForm = screen.form;
    if (tedivoForm) {
        showDetailedCriteriaCompliance(true, new Set(), ul);
        tedivoForm.onDataChange = (data, name) => {
            if (name === "password") {
                const criteriaNotMet = new Set(passwordCompliesWithCriteria(data.password));
                showDetailedCriteriaCompliance(false, criteriaNotMet, ul);
            }
        };
    }
    return screen;
}
function createVerifyMfaFields(h1Node, onSubmitDataForm, i18nCR) {
    const AuthMfaFormValidator = z
        .object({
        code: z.string(),
    })
        .refine((vals) => vals.code.length === 6);
    const fields = [
        {
            type: "textBox",
            name: "code",
            label: "auth:mfa.mfaCode",
            autoCompleteAttribute: "one-time-code",
            isPassword: false,
        },
    ];
    const screen = createScreen({
        fields,
        onSubmitDataForm,
        formValidator: AuthMfaFormValidator,
        i18nCR,
        h1Text: "auth:mfa.mfaCodePlaceholder",
        titleText: "auth:logIn",
        h1Node,
    });
    return screen;
}
