import { Dragger, IDraggerDetails } from "@tedivo/tedivo-bay-grid-interactive";
import {
  I20LcgsByBay,
  IGetBayLcgVcgAndPairingsResult,
  TCG_IN_MMM,
  createRowsFromConfig,
  sortRowsArray,
} from "@tedivo/tedivo-bay-grid-pure";
import {
  IBayLevelData,
  ILidData,
  IMasterCGs,
  ISizeSummary,
  IVesselParts,
} from "open-vessel-definition";
import { ITable, SmartTable } from "@tedivo/tedivo-smart-table";
import { TedivoForm, translateTedivoForm } from "@tedivo/tedivo-form";
import { createDictionary, pad2, roundDec } from "@tedivo/tedivo-pure-helpers";
import createFormFields, {
  ILidDataStore,
  createLidSchemaValidator,
} from "./createLidsFormFields";

import { type EditDrawer } from "../../../../../common/EditDrawer";
import { IntegratedDialogError } from "@tedivo/tedivo-ui";
import LidRowsFactory from "./LidRowsFactory";
import { convertLidDataStoreToILidData } from "./convertILidDataToLidDataStore";
import { createSimpleDeckView } from "@tedivo/tedivo-bay-grid-core";
import { generateNewLabel } from "./generateNewLabel";
import { getTranslation } from "../../../../../../app/i18/i18tn";
import globalUnits from "../../../../../../app/units/globalUnits";
import goSquared from "../../../../../../app/tracking/goSquared";
import ovdJsonStore from "../../../../../../app/stores/OVDJsonStore";
import { z } from "zod";

const SVG_G_NAME = "editLidsSvgG";

export function createLidsEdit({
  sizeSummary,
  masterCGs,
  baysData,
  vesselPartsData,
  lidsData,
  lcgVcgTcgAndPairings,
  usingAModal,
  editDrawer,
}: {
  sizeSummary: ISizeSummary;
  masterCGs: IMasterCGs;
  baysData: IBayLevelData[];
  vesselPartsData: IVesselParts[];
  lidsData: ILidData[];
  lcgVcgTcgAndPairings: IGetBayLcgVcgAndPairingsResult;
  usingAModal: (isOpened: boolean) => void;
  editDrawer: EditDrawer;
}): { node: HTMLElement; submitFunction: () => boolean } {
  goSquared.addEvent("Edit-OVD - Edit Lids");

  const holder = document.createElement("div");
  holder.id = "editLidsHolder";
  holder.className = "edit-lids-holder";

  const editingArea = document.createElement("section");
  editingArea.id = "editingArea";
  editingArea.className = "editing-area";

  const currentSvg: { current: ISvgDetails | undefined } = {
    current: undefined,
  };

  let slotsDeckPositions: IDeckSlotsPositions;

  // This is the DIV where the Deck SVG with Lids will be
  const svgWrapper = document.createElement("div");
  svgWrapper.className = "lids-svg-mainHolder";

  const rowsFactory = new LidRowsFactory(editingArea, svgWrapper, usingAModal);
  rowsFactory.setData(lidsData);

  const { divTable, smartTable, tableEditSchema } = createEditingFields({
    sizeSummary,
    rowsFactory,
    onAction: onSelectedAction,
    onRowsSelected,
  });

  rowsFactory.smartTable = smartTable;

  editingArea.appendChild(divTable);
  holder.appendChild(editingArea);

  const btnAddLid = document.createElement("sl-button");
  btnAddLid.innerHTML = getTranslation("view:lids.new");
  btnAddLid.variant = "primary";
  btnAddLid.addEventListener(
    "click",
    () => {
      const maxIsoBayWithLid = Math.min(
        Math.max(...rowsFactory.getRawData().map((l) => l.endIsoBay), 1),
        sizeSummary.isoBays,
      );

      createLidModal({
        startIsoBay: maxIsoBayWithLid,
        endIsoBay: maxIsoBayWithLid + 2,
        portIsoRow: 0,
        starboardIsoRow: 0,
        label: "",
        weight: 0,
        overlapPort: 0,
        overlapStarboard: 0,
        pk: "",
      });
    },
    false,
  );
  divTable.appendChild(document.createElement("br"));
  divTable.appendChild(btnAddLid);

  setTimeout(() => {
    const drawing = drawSvgWithLids(
      sizeSummary,
      masterCGs,
      baysData,
      vesselPartsData,
      rowsFactory.getRawData(),
      lcgVcgTcgAndPairings,
    );

    if (drawing) {
      currentSvg.current = drawing;

      const { deckViewSvg, xPos, zPos } = currentSvg.current;

      slotsDeckPositions = createSlotsDeckPositions(
        masterCGs,
        lcgVcgTcgAndPairings,
        drawing.lcgsBy20Bay,
        xPos,
        zPos,
      );

      flipDeckSvg(deckViewSvg);
      svgWrapper.appendChild(deckViewSvg);

      const dragger = new Dragger(svgWrapper, onDraggerEvent);
      editingArea.appendChild(dragger.node);

      // When data changes, remove and re-create the SVG
      rowsFactory.onChange.set("SVG", () => {
        if (currentSvg.current?.deckViewSvg)
          currentSvg.current.deckViewSvg.remove(); // Remove previous SVG

        const newDrawing = drawSvgWithLids(
          sizeSummary,
          masterCGs,
          baysData,
          vesselPartsData,
          rowsFactory.getRawData(),
          lcgVcgTcgAndPairings,
        );

        if (newDrawing) {
          currentSvg.current = newDrawing;
          flipDeckSvg(newDrawing.deckViewSvg);
          svgWrapper.appendChild(newDrawing.deckViewSvg);
        }
      });
    }
  }, 1000);

  rowsFactory.onChange.set("TABLE", () => {
    smartTable.updateData(rowsFactory.getRawData());
    editDrawer.dataIsDirty = true;
  });

  const submitFunction = () => {
    rowsFactory.hasBeenSubmitted = true;
    const rawData = rowsFactory.getRawData();
    const validation = tableEditSchema.safeParse(rawData);

    if (!validation.success) {
      highlightTableErrors(
        validation.error,
        rawData.map(({ pk }, index) => ({ pk, index })),
      );

      const modal = new IntegratedDialogError(
        holder,
        true,
        getTranslation("general:common.close"),
      );
      modal.show(
        getTranslation("general:common.attention"),
        getTranslation("view:lids.invalidData"),
      );
      return false;
    }

    goSquared.addEvent("Edit-OVD - Edit Lids - Save");
    ovdJsonStore.setLids(rowsFactory.getData());
    return true;
  };

  return { node: holder, submitFunction };

  function onDraggerEvent(event: IDraggerDetails): void {
    if (currentSvg.current === undefined) return;
    if (event.hasDragged) onDrag(event);
    if (event.isDoubleClick) onDoubleClick(event);
  }

  function onDoubleClick(event: IDraggerDetails): void {
    const target = event.originalEvent?.target as Element;
    const pk = target.getAttribute("data-idx");

    if (!target || !pk) return;

    const lidData = rowsFactory.getSingleLidData(pk);

    if (!lidData) return;

    createLidModal({
      startIsoBay: lidData.startIsoBay || 0,
      endIsoBay: lidData.endIsoBay || 0,
      portIsoRow: lidData.portIsoRow || 0,
      starboardIsoRow: lidData.starboardIsoRow || 0,
      label: lidData.label || "",
      weight: lidData.weight || 0,
      overlapPort: lidData.overlapPort || 0,
      overlapStarboard: lidData.overlapStarboard || 0,
      pk: pk,
    });
  }

  function onDrag(event: IDraggerDetails): void {
    if (currentSvg.current === undefined) return;

    const { deckViewSvg, zRange, xRange } = currentSvg.current;

    const responsiveRatio =
      deckViewSvg.getBoundingClientRect().width / (zRange || 1);

    const slotsSelected = Object.keys(slotsDeckPositions).filter((k) => {
      const { maxZ, minZ, maxX, minX } = slotsDeckPositions[k];

      const box = {
        x0: minZ * responsiveRatio,
        x1: maxZ * responsiveRatio,
        y0: (xRange - maxX) * responsiveRatio,
        y1: (xRange - minX) * responsiveRatio,
      };

      return (
        event.startX <= box.x1 &&
        event.endX >= box.x0 &&
        event.startY <= box.y1 &&
        event.endY >= box.y0
      );
    });

    if (slotsSelected.length === 0) {
      new IntegratedDialogError(
        holder,
        true,
        getTranslation("general:common.close"),
      ).show(
        getTranslation("general:common.attention"),
        getTranslation("view:lids.noContainersBelow"),
      );
      return;
    }

    const tiersRowsSelected: { tiers: number[]; rows: number[] } = {
      tiers: [],
      rows: [],
    };

    slotsSelected.forEach((key) => {
      const [tier, row] = key.split("-");
      tiersRowsSelected.tiers.push(Number(tier));
      tiersRowsSelected.rows.push(Number(row));
    });

    const startIsoBay = Math.min.apply(null, tiersRowsSelected.tiers);
    const endIsoBay = Math.max.apply(null, tiersRowsSelected.tiers);
    const uniqueRows = tiersRowsSelected.rows
      .filter((v, idx, arr) => arr.indexOf(v) === idx)
      .map((v) => pad2(v))
      .sort(sortRowsArray);

    const allLabels = rowsFactory.getRawData().map((l) => l.label);

    createLidModal({
      startIsoBay,
      endIsoBay,
      portIsoRow: Number(uniqueRows[0]),
      starboardIsoRow: Number(uniqueRows[uniqueRows.length - 1]),
      label: generateNewLabel(pad2(startIsoBay), allLabels),
      weight: 0,
      overlapPort: 0,
      overlapStarboard: 0,
      pk: "",
    });
  }

  function createLidModal({
    startIsoBay,
    endIsoBay,
    portIsoRow,
    starboardIsoRow,
    label,
    weight,
    overlapPort,
    overlapStarboard,
    pk,
  }: {
    startIsoBay: number;
    endIsoBay: number;
    portIsoRow: number;
    starboardIsoRow: number;
    label: string;
    weight: number;
    overlapPort: number;
    overlapStarboard: number;
    pk: string;
  }) {
    const modal = document.createElement("sl-dialog");
    modal.label = getTranslation(`view:lids.${!pk ? "new" : "edit"}`);
    modal.setAttribute("style", "--width: 50vw");

    modal.addEventListener(
      "sl-after-hide",
      (ev) => {
        if (ev.target === modal) {
          usingAModal(false);
          modal.remove();
        }
      },
      false,
    );

    // Delete button
    if (pk) {
      const btnDeleteLid = document.createElement("sl-button");
      btnDeleteLid.innerHTML = getTranslation("general:common.delete");
      btnDeleteLid.variant = "danger";
      btnDeleteLid.slot = "footer";
      modal.appendChild(btnDeleteLid);
      const btnDeleteLidIcon = document.createElement("sl-icon");
      btnDeleteLidIcon.name = "trash";
      btnDeleteLidIcon.slot = "prefix";
      btnDeleteLid.appendChild(btnDeleteLidIcon);
      btnDeleteLid.addEventListener(
        "click",
        () => {
          modal.hide();
          rowsFactory.deleteLids([String(pk)]);
        },
        false,
      );
    }

    // Save button
    const btnSave = document.createElement("sl-button");
    btnSave.innerHTML = getTranslation("view:lids.save");
    btnSave.variant = "primary";
    btnSave.slot = "footer";
    modal.appendChild(btnSave);
    btnSave.addEventListener("click", saveLidWithFormData, false);

    const lidEditSchema = createLidSchemaValidator(sizeSummary);

    const tedivoForm = new TedivoForm<ILidDataStore>({
      fields: createFormFields(
        label,
        startIsoBay,
        endIsoBay,
        portIsoRow,
        starboardIsoRow,
        weight,
        overlapPort,
        overlapStarboard,
        String(!pk ? "" : pk),
      ),
      onSubmit: () => null,
      formValidator: lidEditSchema,
      submitButton: btnSave,
    });

    translateTedivoForm<ILidDataStore>({
      tedivoForm,
      getTranslation,
    });

    modal.appendChild(tedivoForm.form);
    holder.appendChild(modal);

    usingAModal(true);
    modal.show();

    function saveLidWithFormData() {
      const validResult = tedivoForm.doSubmitForm();

      if (!validResult.success) return false;

      const values = tedivoForm.getValues();
      const lidData: Omit<ILidDataStore, "pk"> = {
        label: values.label || "",
        startIsoBay: values.startIsoBay,
        endIsoBay: values.endIsoBay,
        portIsoRow: values.portIsoRow,
        starboardIsoRow: values.starboardIsoRow,
        weight: values.weight,
        overlapPort: values.overlapPort ? 1 : 0,
        overlapStarboard: values.overlapStarboard ? 1 : 0,
      };

      if (!pk) {
        rowsFactory.addLidData(lidData as ILidDataStore);
        rowsFactory.onChange.forEach((fn) => fn());
      } else {
        rowsFactory.updateLidData(String(pk), lidData as ILidDataStore);
        rowsFactory.onChange.get("SVG")?.();
      }

      modal.hide();
      return true;
    }
  }

  function onSelectedAction(action: string, pks: string[]) {
    if (pks.length === 0) {
      new IntegratedDialogError(
        holder,
        true,
        getTranslation("general:common.close"),
      ).show(
        getTranslation("general:common.attention"),
        getTranslation("view:lids.noLidsSelected"),
      );
      return;
    }

    switch (action) {
      case "delete":
        rowsFactory.deleteLids(pks);
        break;
      case "copyPaste":
        rowsFactory.copyPasteLids({
          pks: pks,
          cb: (newPks) => {
            smartTable.updateData(rowsFactory.getRawData());
            smartTable.pksSelected = newPks;
          },
        });
        break;
    }
  }

  function onRowsSelected(pks: string[]) {
    rowsFactory.hoverIsActive = pks.length === 0;

    // Highlight the selected checkboxes in the SVG
    rowsFactory.highlightPaths(pks);
  }

  /**
   * Generates an object with all the positions of the slots-containers over the deck
   */
  function createSlotsDeckPositions(
    masterCgs: IMasterCGs,
    lcgVcgTcgAndPairings: IGetBayLcgVcgAndPairingsResult,
    lcgsBy20Bay: I20LcgsByBay,
    xPos: (lcg: number, d?: number | undefined) => number,
    zPos: (tcg: number, d?: number | undefined) => number,
  ): IDeckSlotsPositions {
    const slotsPositionsInMm: IDeckSlotsPositions = {};

    const { bayLevelPositionsBelow, bayLevelPositionsAbove } =
      lcgVcgTcgAndPairings;

    const bayLevelPositionsBelowByBay = createDictionary(
      bayLevelPositionsBelow,
      (d) => d.isoBay,
    );

    const bayLevelPositionsAboveByBay = createDictionary(
      bayLevelPositionsAbove,
      (d) => d.isoBay,
    );

    const allBays = [...bayLevelPositionsBelow, ...bayLevelPositionsAbove]
      .map((k) => k.isoBay)
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort();

    const allRows = createRowsFromConfig(
      !!sizeSummary.centerLineRow,
      sizeSummary.maxRow || 100,
    );

    const masterTcgs = {
      ...masterCgs.aboveTcgs,
      ...masterCgs.belowTcgs,
    };

    allBays.forEach((isoBay) => {
      const blp =
        bayLevelPositionsBelowByBay[isoBay] ||
        bayLevelPositionsAboveByBay[isoBay];

      allRows.forEach((rowName) => {
        const tcg = blp.rows?.[rowName]?.tcg || masterTcgs[rowName];
        if (tcg === undefined) return;

        const strName = `${blp.isoBay}-${rowName}`;

        slotsPositionsInMm[strName] = {
          minX: xPos(lcgsBy20Bay[blp.isoBay].aftLcg),
          maxX: xPos(lcgsBy20Bay[blp.isoBay].foreLcg),
          minZ: roundDec(zPos((tcg || 0) - TCG_IN_MMM * 0.5), 0),
          maxZ: roundDec(zPos((tcg || 0) + TCG_IN_MMM * 0.5), 0),
        };
      });
    });

    return slotsPositionsInMm;
  }
}

const INPUT_FIELDS_TO_HIGHLIGHT_WITH_ERRORS = [
  "label",
  "portIsoRow",
  "starboardIsoRow",
  "startIsoBay",
  "endIsoBay",
  "weight",
  "label",
];

function highlightTableErrors(
  errors: z.ZodError<ILidDataStore[]> | undefined,
  allPks: Array<{ pk: string; index: number }>,
) {
  const allFieldsNames = allPks.flatMap((e) =>
    INPUT_FIELDS_TO_HIGHLIGHT_WITH_ERRORS.map(
      (field) => `input-${e.pk}-${field}`,
    ),
  );

  const idxToPkMap = allPks.reduce((acc, { pk, index }) => {
    acc[index] = pk;
    return acc;
  }, {} as Record<number, string>);

  const invalidCells = !errors
    ? []
    : errors.errors.map((e) => {
        const [pk, name] = e.path as [number, string];
        return `input-${idxToPkMap[pk]}-${String(name)}`;
      });

  let firstError: HTMLElement | undefined;

  allFieldsNames.forEach((f) => {
    const el = document.getElementById(f);
    if (!el) return;

    if (invalidCells.includes(f)) {
      if (!el.classList.contains("has-error")) el.classList.add("has-error");
      if (!firstError) firstError = el;
    } else {
      if (el.classList.contains("has-error")) el.classList.remove("has-error");
    }
  });

  if (firstError !== undefined) {
    firstError.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  }
}

function createEditingFields({
  sizeSummary,
  rowsFactory,
  onAction,
  onRowsSelected,
}: {
  sizeSummary: ISizeSummary;
  rowsFactory: LidRowsFactory;
  onAction: (action: string, pks: string[]) => void;
  onRowsSelected: (pks: string[]) => void;
}) {
  const divTable = document.createElement("div");
  divTable.className = "lids-table-mainHolder";

  const smartTableConfig: ITable<ILidDataStore> = {
    data: rowsFactory.getRawData(),
    defaultSort: ["startIsoBay", "label"],
    className:
      "tvd-table tvd-table-smart-table tvd-table-sticky tvd-table-sticky-0",
    fields: [
      {
        name: "label",
        label: getTranslation("general:common.label"),
        type: "inlineEdit",
        editType: "text",
        fixedWidth: "80px",
        onChange: (dta, name, table, value) => {
          updateFieldInRecord(name, value, dta.pk);
        },
      },
      {
        name: "startIsoBay",
        type: "inlineEditPadded",
        padZeros: 3,
        className: "centered",
        fixedWidth: "80px",
        onChange: (dta, name, table, value) => {
          updateFieldInRecord(name, value, dta.pk);
        },
        label: getTranslation("view:lids.startIsoBay"),
      },
      {
        name: "endIsoBay",
        type: "inlineEditPadded",
        padZeros: 3,
        className: "centered",
        fixedWidth: "80px",
        onChange: (dta, name, table, value) => {
          updateFieldInRecord(name, value, dta.pk);
        },
        label: getTranslation("view:lids.endIsoBay"),
      },
      {
        name: "portIsoRow",
        type: "inlineEditPadded",
        padZeros: 2,
        className: "centered",
        fixedWidth: "80px",
        onChange: (dta, name, table, value) => {
          updateFieldInRecord(name, value, dta.pk);
        },
        label: getTranslation("view:lids.portIsoRow"),
      },
      {
        name: "starboardIsoRow",
        type: "inlineEditPadded",
        padZeros: 2,
        className: "centered",
        fixedWidth: "80px",
        onChange: (dta, name, table, value) => {
          updateFieldInRecord(name, value, dta.pk);
        },
        label: getTranslation("view:lids.starboardIsoRow"),
      },
      {
        name: "weight",
        type: "inlineEditWithUnits",
        className: "centered",
        fixedWidth: "95px",
        onChange: (dta, name, table, value) => {
          updateFieldInRecord(name, value, dta.pk);
        },
        label: getTranslation("view:weight"),
        converter: globalUnits.massUnits,
      },
      {
        name: "overlapPort",
        type: "inlineToggle",
        className: "centered",
        fixedWidth: "110px",
        onToggle: (dta, name, table, value) => {
          updateFieldInRecord(name, value, dta.pk);
        },
        getValueFn: (dta) => dta.overlapPort === 1,
        label: getTranslation("view:lids.overlapPort"),
      },
      {
        name: "overlapStarboard",
        type: "inlineToggle",
        className: "centered",
        fixedWidth: "110px",
        onToggle: (dta, name, table, value) => {
          updateFieldInRecord(name, value, dta.pk);
        },
        getValueFn: (dta) => dta.overlapStarboard === 1,
        label: getTranslation("view:lids.overlapStarboard"),
      },
    ],
    settings: {
      rowAddCheckboxes: true,
      rowActions: [
        {
          value: "delete",
          name: getTranslation("general:common.deleteSelected"),
          icon: "trash",
        },
        {
          value: "copyPaste",
          name: getTranslation("general:common.copyPasteSelected"),
          icon: "copy",
        },
      ],
      rowOnCheckedChange: onRowsSelected,
      rowOnClickAction: (action, pks) => {
        onAction(action, pks);
      },
    },
    initialRows: 300,
    pkFunction: (dta) => `${dta.pk}`,
  };

  const smartTable = new SmartTable<ILidDataStore>().initialize(
    smartTableConfig,
  );

  const lidEditSchema = createLidSchemaValidator(sizeSummary);

  // Now add this object into an array
  const tableEditSchema = z.array(lidEditSchema);

  divTable.appendChild(smartTable);

  return {
    divTable,
    smartTable,
    tableEditSchema,
  };

  function updateFieldInRecord(
    name: keyof ILidDataStore,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    pk: string,
  ) {
    const record = rowsFactory.getSingleLidData(pk);

    if (record) {
      const nValue =
        name === "overlapPort" || name === "overlapStarboard"
          ? value
            ? 1
            : 0
          : value;

      const newObject: ILidDataStore = { ...record, [name]: nValue };

      rowsFactory.updateLidData(pk, newObject).then(() => {
        //smartTable.updateRowWithNewData(pk, newObject);
      });
    }

    rowsFactory.onChange.get("SVG")?.();

    const rawData = rowsFactory.getRawData();
    const validation = tableEditSchema.safeParse(rawData);

    //if (rowsFactory.hasBeenSubmitted) {
    highlightTableErrors(
      validation.error,
      rawData.map(({ pk }, index) => ({ pk, index })),
    );
    //}
  }
}

function drawSvgWithLids(
  sizeSummary: ISizeSummary,
  masterCGs: IMasterCGs,
  baysData: IBayLevelData[],
  vesselPartsData: IVesselParts[],
  lidsData: ILidDataStore[],
  lcgVcgTcgAndPairings: IGetBayLcgVcgAndPairingsResult,
): ISvgDetails | null {
  const root = getComputedStyle(document.body);

  const lidsDataValidated = validateLidData(lidsData).map((l) => ({
    ...convertLidDataStoreToILidData(l),
    pk: l.pk,
  }));

  try {
    const {
      deckViewSvg,
      missingImportantXcgs,
      xPos,
      addX,
      xRange,
      zPos,
      zRange,
      lcgsBy20Bay,
    } = createSimpleDeckView({
      sizeSummary,
      baysData,
      vesselPartsData,
      doDrawVesselParts: false,
      masterCGs,
      lidData: lidsDataValidated,
      lcgVcgTcgAndPairings,
      symbolsOptions: {
        strokeWidth: 1,
        strokeColor: root.getPropertyValue("--sl-color-neutral-200"),
        fontColor: root.getPropertyValue("--sl-color-neutral-700"),
        fillColor: root.getPropertyValue("--sl-color-neutral-50"),
        shipStrokeColor: root.getPropertyValue("--sl-color-primary-300"),
        dimmedStrokeColor: root.getPropertyValue("--sl-color-neutral-300"),
        lidFillColor: root.getPropertyValue("--sl-color-primary-200"),
        lidWarningFillColor: root.getPropertyValue("--sl-color-orange-200"),
        lidDangerFillColor: root.getPropertyValue("--sl-color-red-200"),
        lidTextColor: root.getPropertyValue("--sl-color-neutral-800"),
        portColor: root.getPropertyValue("--sl-color-red-400"),
        stbdColor: root.getPropertyValue("--sl-color-green-400"),
        addRowLines: true,
      },
      svgGroupId: SVG_G_NAME,
    });

    return {
      deckViewSvg,
      missingImportantXcgs,
      xPos,
      xRange,
      zPos,
      zRange,
      addX,
      lcgsBy20Bay,
    };
  } catch (e) {
    console.error(e);
    return null;
  }
}

function flipDeckSvg(svg: SVGElement): void {
  const width = svg.getAttribute("width");
  const height = svg.getAttribute("height");
  const viewBox = svg.getAttribute("viewBox")?.split(" ");

  svg.setAttribute("width", String(height));
  svg.setAttribute("height", String(width));
  svg.style.maxWidth = "400px";

  svg.setAttribute("data-time", String(Date.now()));

  if (viewBox) {
    svg.setAttribute(
      "viewBox",
      `${viewBox[1]} ${viewBox[0]} ${viewBox[3]} ${viewBox[2]}`,
    );
  }

  const g: SVGGElement | null = svg.querySelector(`#${SVG_G_NAME}`);
  if (g) {
    g.setAttribute(
      "transform",
      `rotate(-90, 0, ${Number(width) * 0.5}) translate(${
        Number(width) * -0.5
      }, ${Number(width) * 0.5})`,
    );
  }
}

function validateLidData(lidData?: ILidDataStore[]): ILidDataStore[] {
  if (!lidData || !lidData.length) return [];

  return lidData.filter((lid) => {
    return (
      lid.label !== undefined &&
      lid.portIsoRow !== undefined &&
      lid.starboardIsoRow !== undefined &&
      lid.startIsoBay !== undefined &&
      lid.endIsoBay !== undefined
    );
  });
}

interface ISvgDetails {
  deckViewSvg: SVGElement;
  missingImportantXcgs: boolean;
  xRange: number;
  zRange: number;
  xPos: (lcg: number, d?: number | undefined) => number;
  zPos: (tcg: number, d?: number | undefined) => number;
  addX: number;
  lcgsBy20Bay: I20LcgsByBay;
}

interface IDeckSlotsPositions {
  [name: string]: {
    minX: number;
    maxX: number;
    minZ: number;
    maxZ: number;
  };
}
