import { FEET_10_IN_MM } from "../../consts";
import { ForeAftEnum } from "open-vessel-definition";
import { I20Lcgs } from "../../types/I20Lcgs";
import { IBayPattern } from "../../types/IPositionPatterns";
import { IBaySizesAndCgs } from "../../types/IGetBayLcgVcgAndPairingsResult";
import { roundDec } from "@tedivo/tedivo-pure-helpers";

/** Find the LCG for a 20 bay of EACH level */
export function find20Lcgs(lcgsBy20Bay: {
  [bay: IBayPattern]: I20Lcgs;
}): (value: IBaySizesAndCgs) => void {
  return (bl) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const maxSize =
      bl.maxSizeLcg.size !== -Infinity ? bl.maxSizeLcg.size || 20 : 20;

    if (maxSize < 40) {
      const lcg2x20 = bl.maxSizeLcg.lcg || 0;

      lcgsBy20Bay[bl.isoBay] = {
        lcg: lcg2x20,
        aftLcg: roundDec(lcg2x20 - FEET_10_IN_MM, 2),
        foreLcg: roundDec(lcg2x20 + FEET_10_IN_MM, 2),
        paired: bl.pairedBay,
        maxSize,
      };
    } else {
      const lcg4x = bl.maxSizeLcg.lcg || 0;
      const pairedMultiplier = bl.pairedBay === ForeAftEnum.FWD ? -1 : 1;

      const lcg4x20 = roundDec(lcg4x + pairedMultiplier * FEET_10_IN_MM, 2);

      lcgsBy20Bay[bl.isoBay] = {
        lcg: lcg4x20,
        aftLcg: roundDec(lcg4x20 - FEET_10_IN_MM, 2),
        foreLcg: roundDec(lcg4x20 + FEET_10_IN_MM, 2),
        paired: bl.pairedBay,
        maxSize,
      };
    }
  };
}
