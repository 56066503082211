import { ScrollModeEnum } from "../../app/units/units-scroller-element";
import SlButton from "@shoelace-style/shoelace/dist/components/button/button";
import SlDrawer from "@shoelace-style/shoelace/dist/components/drawer/drawer";
import globalStore from "../../app/stores/globalStore";
import { removeChildren } from "@tedivo/tedivo-dom-helpers";

export class EditDrawer extends EventTarget {
  static getTranslation: (s: string, opts?: unknown) => string = (s) => s;

  drawer: SlDrawer;
  submitButton: SlButton;
  disabledOverlay: HTMLDivElement;
  private extraButtonsDiv: HTMLDivElement;
  public extraButtonsNodes: Array<SlButton> = [];

  onSave?: () => boolean | Promise<boolean>;

  private readOnlyMode = false;

  private internalFixedOverflow = false;
  private internalDisabled = false;
  private internalShouldNotSelectPanel = false;

  public internalShouldNotClose = false;
  public internalDataIsDirty = false;

  public internalPanelHeight = 0;

  get fixedScrollOverflow() {
    return this.internalFixedOverflow;
  }

  set fixedScrollOverflow(v: boolean) {
    this.internalFixedOverflow = v;
    if (v) {
      if (!this.drawer.classList.contains("fix-overflow"))
        this.drawer.classList.add("fix-overflow");
    } else {
      this.drawer.classList.remove("fix-overflow");
    }
  }

  get disabled() {
    return this.internalDisabled;
  }
  set disabled(v: boolean) {
    this.internalDisabled = v;
    this.disabledOverlay.style.display = v ? "block" : "none";
  }

  get shouldNotClose() {
    return this.internalShouldNotClose;
  }
  set shouldNotClose(v: boolean) {
    this.internalShouldNotClose = v;
  }

  get dataIsDirty() {
    return this.internalDataIsDirty;
  }
  set dataIsDirty(v: boolean) {
    this.internalDataIsDirty = v;
    this.dispatchEvent(new CustomEvent("data-dirty", { detail: v }));
  }

  get shouldNotSelectPanel() {
    return this.internalShouldNotSelectPanel;
  }
  set shouldNotSelectPanel(v: boolean) {
    if (!v) {
      this.drawer.classList.remove("do-not-selectt-panel");
    } else {
      if (!this.drawer.classList.contains("do-not-select-panel"))
        this.drawer.classList.add("do-not-select-panel");
    }
    this.internalShouldNotSelectPanel = v;
  }

  constructor() {
    super();

    const drawer = document.createElement("sl-drawer") as SlDrawer;
    drawer.className = "main-edit-drawer";
    drawer.placement = "end";

    const saveButton = document.createElement("sl-button");
    saveButton.innerHTML = EditDrawer.getTranslation("general:common.save");
    saveButton.variant = "primary";
    saveButton.type = "submit";
    saveButton.tabIndex = 10;

    const disabledOverlay = document.createElement("div");
    disabledOverlay.className = "drawer-disabled-overlay";

    const extraButtonsDiv = document.createElement("div");
    extraButtonsDiv.className = "extra-buttons-drawer";

    this.drawer = drawer;
    this.submitButton = saveButton;
    this.disabledOverlay = disabledOverlay;
    this.extraButtonsDiv = extraButtonsDiv;

    this.attachEvents();
  }

  private attachEvents() {
    const drawer = this.drawer;
    const submitButton = this.submitButton;

    drawer.addEventListener(
      "sl-request-close",
      (ev) => {
        if (ev.target === drawer) {
          if (!this.canClose && !this.readOnlyMode) {
            ev.preventDefault();
            this.promprUserIfShouldNotClose();
          }
        }
      },
      false,
    );

    drawer.addEventListener(
      "sl-after-hide",
      (ev) => {
        if (ev.target === drawer) {
          removeChildren(drawer);
          drawer.classList.remove("fix-overflow");

          const mainPanel = drawer.drawer.querySelector(
            "[part=body]",
          ) as HTMLElement;

          if (!mainPanel) return;

          mainPanel.style.height = "auto";
          mainPanel.style.overflow = "auto";
        }
      },
      false,
    );

    drawer.addEventListener(
      "sl-after-show",
      () => {
        const info1 = drawer.drawer
          .querySelector("[part=body]")
          ?.getBoundingClientRect();

        this.internalPanelHeight = info1?.height || 0;
      },
      false,
    );

    const doSave = async () => {
      if (this.onSave) {
        const result = await this.onSave();
        if (result) drawer.hide();
      } else {
        drawer.hide();
      }
      globalStore.touchLastUserInteraction();
    };

    // Attach Save event
    submitButton.addEventListener("click", doSave, false);
    submitButton.addEventListener(
      "keyup",
      (ev: KeyboardEvent) => {
        if (ev.key === "Enter" || ev.key === " ") doSave();
      },
      false,
    );
  }

  getEditDrawer({
    title,
    onSave,
    readOnlyMode = false,
    showUnits = true,
    extraButtons,
  }: IGetEditDrawerProps): SlDrawer {
    const drawer = this.drawer;
    this.readOnlyMode = readOnlyMode;

    globalStore.touchLastUserInteraction();

    if (onSave) this.onSave = onSave;

    removeChildren(drawer);
    removeChildren(this.extraButtonsDiv);
    this.extraButtonsNodes = [];

    if (extraButtons) {
      for (const btn of extraButtons) {
        const button = document.createElement("sl-button");
        button.innerHTML = btn.label;
        if (btn.outline) button.outline = true;
        if (btn.variant) button.variant = btn.variant;
        button.addEventListener("click", btn.onClick, false);
        button.disabled = btn.disabled || false;
        this.extraButtonsDiv.appendChild(button);
        this.extraButtonsNodes.push(button);
      }
    }

    const buttonsHolder = document.createElement("div");
    buttonsHolder.className = "extra-buttons-drawer";
    buttonsHolder.slot = "footer";
    buttonsHolder.appendChild(this.extraButtonsDiv);
    buttonsHolder.appendChild(this.submitButton);

    drawer.label = title;
    drawer.appendChild(buttonsHolder);
    drawer.appendChild(this.disabledOverlay);
    this.shouldNotClose = false;
    this.dataIsDirty = false;
    this.shouldNotSelectPanel = false;

    if (showUnits) {
      const unitsScroller = document.createElement("units-scroller-element");
      unitsScroller.align = "left";
      unitsScroller.doScroll = ScrollModeEnum.NO_SCROLL;
      unitsScroller.align = "right";
      unitsScroller.className = "block";
      drawer.appendChild(unitsScroller);
    }

    this.submitButton.disabled = readOnlyMode;

    return drawer;
  }

  public get canClose() {
    return !this.shouldNotClose && !this.dataIsDirty;
  }

  public promprUserIfShouldNotClose = (cb?: () => void) => {
    const dialog = document.createElement("sl-dialog");
    dialog.label = EditDrawer.getTranslation("general:dirtyData.title");
    dialog.innerHTML = EditDrawer.getTranslation("general:dirtyData.message");

    const btnDiscard = document.createElement("sl-button");
    btnDiscard.slot = "footer";
    btnDiscard.innerHTML = EditDrawer.getTranslation(
      "general:dirtyData.btnDiscard",
    );
    btnDiscard.variant = "danger";
    btnDiscard.addEventListener("click", () => {
      this.shouldNotClose = false;
      this.dataIsDirty = false;
      this.drawer.hide();
      cb?.();
    });
    dialog.appendChild(btnDiscard);

    if (!this.readOnlyMode) {
      const btnSave = document.createElement("sl-button");
      btnSave.slot = "footer";
      btnSave.innerHTML = EditDrawer.getTranslation(
        "general:dirtyData.btnSave",
      );
      btnSave.variant = "primary";
      btnSave.autofocus = true;
      btnSave.addEventListener("click", () => {
        this.onSave?.();
        this.drawer.hide();
        cb?.();
      });
      dialog.appendChild(btnSave);
    }

    this.drawer.appendChild(dialog);
    dialog.show();

    dialog.addEventListener("sl-after-hide", () => {
      dialog.remove();
    });
  };
}

interface IGetEditDrawerProps {
  title: string;
  onSave?: () => boolean | Promise<boolean>;
  readOnlyMode: boolean;
  showUnits?: boolean;
  extraButtons?: Array<{
    label: string;
    value: string;
    onClick: () => void;
    disabled?: boolean;
    variant?: "primary" | "danger" | "neutral";
    outline?: boolean;
  }>;
}
