import { addStyles } from "../../helpers/addStyles";
import { pad } from "@tedivo/tedivo-pure-helpers";
export class InputPadZeros extends HTMLElement {
    constructor() {
        super();
        this.required = false;
        this.size = "medium";
        this.internalValue = 0;
        this.padZeros = 0;
        this.internalId = "";
        this.internalLabel = "";
        this.internalDisabled = false;
        this.internalUseInputListener = false;
        this._onFocus = () => {
            var _a;
            this.internalValue = Number(this.internalValue);
            this.setDisplayValue(this.internalValue, (_a = this.internalValue) === null || _a === void 0 ? void 0 : _a.toString()).then(() => {
                if (this.input)
                    this.input.select();
            });
        };
        this._onBlur = () => {
            this.setDisplayValue(this.internalValue);
        };
        this.onChange = () => {
            this.calculateInternalValue();
        };
        this.calculateInternalValue = () => {
            const t = this.input;
            if (t.value === "") {
                this.internalValue = undefined;
                return;
            }
            const faceValue = Number(t.value);
            this.internalValue = faceValue;
        };
        const inp = document.createElement("sl-input");
        inp.noSpinButtons = true;
        this.input = inp;
    }
    get value() {
        this.calculateInternalValue();
        return this.internalValue;
    }
    set value(v) {
        this.internalValue = v;
        this.setDisplayValue(v);
    }
    get label() {
        return this.internalLabel;
    }
    set label(v) {
        this.internalLabel = v;
        if (this.input)
            this.input.label = v;
    }
    set helpText(v) {
        if (this.input)
            this.input.helpText = v;
    }
    get name() {
        return this.internalId;
    }
    set name(v) {
        this.internalId = v;
        if (this.input) {
            this.input.id = v;
            this.input.name = v;
        }
    }
    get disabled() {
        return this.internalDisabled;
    }
    set disabled(s) {
        this.internalDisabled = s;
        if (this.input)
            this.input.disabled = s;
    }
    get min() {
        var _a;
        return Number((_a = this.input) === null || _a === void 0 ? void 0 : _a.min) || undefined;
    }
    set min(s) {
        if (this.input)
            this.input.min = s !== null && s !== void 0 ? s : 0;
    }
    get max() {
        var _a;
        return Number((_a = this.input) === null || _a === void 0 ? void 0 : _a.max) || undefined;
    }
    set max(s) {
        if (this.input)
            this.input.max = s !== null && s !== void 0 ? s : 0;
    }
    get tabIndex() {
        var _a;
        return ((_a = this.input) === null || _a === void 0 ? void 0 : _a.tabIndex) || -1;
    }
    set tabIndex(v) {
        if (this.input)
            this.input.tabIndex = v;
    }
    get noSpinButtons() {
        if (this.input)
            return this.input.noSpinButtons;
        return false;
    }
    set noSpinButtons(v) {
        if (this.input)
            this.input.noSpinButtons = v;
    }
    focus() {
        if (this.input)
            this.input.focus();
    }
    select() {
        if (this.input)
            this.input.select();
    }
    setDisplayValue(v, overrideValue) {
        return new Promise((resolve) => {
            if (!this.input) {
                resolve();
                return;
            }
            if (overrideValue) {
                this.input.value = overrideValue;
                resolve();
                return;
            }
            if (v === undefined || isNaN(v)) {
                this.input.value = "";
                resolve();
                return;
            }
            this.input.value = pad(v, this.padZeros || 0);
            resolve();
        });
    }
    connectedCallback() {
        const shadow = this.attachShadow({ mode: "open" });
        const inp = this.input;
        inp.id = this.name;
        inp.name = this.name;
        inp.size = this.size;
        inp.type = "number";
        if (this.required)
            inp.required = true;
        if (this.label)
            inp.label = this.label;
        if (this.disabled)
            inp.disabled = this.disabled;
        shadow.appendChild(addStyles(styles));
        shadow.appendChild(inp);
        inp.addEventListener("sl-change", this.onChange, false);
        inp.addEventListener("focus", this._onFocus, false);
        inp.addEventListener("blur", this._onBlur, false);
    }
    disconnectedCallback() {
        this.input.removeEventListener("sl-change", this.onChange, false);
        this.input.removeEventListener("focus", this._onFocus, false);
        this.input.removeEventListener("sl-blur", this._onBlur, false);
    }
    attributeChangedCallback(name, oldValue, newValue) {
        switch (name) {
            case "class":
                if (newValue.indexOf("has-error") >= 0) {
                    if (!this.input.classList.contains("has-error"))
                        this.input.classList.add("has-error");
                }
                else {
                    this.input.classList.remove("has-error");
                }
                break;
            case "disabled":
                this.disabled = newValue === "true";
                break;
        }
    }
}
InputPadZeros.formAssociated = true;
InputPadZeros.observedAttributes = ["class", "disabled"];
customElements.define("tf-input-padded", InputPadZeros);
const styles = `
 
  sl-input.has-error:not([disabled])::part(form-control-label),
  sl-input.has-error:not([disabled])::part(form-control-help-text) {
    color: var(--sl-color-danger-700);
  }

  sl-input.has-error:not([disabled])::part(base) {
    border-color: var(--sl-color-danger-600);
  }

  sl-input.has-error:focus-within::part(base) {
    box-shadow: 0 0 0 var(--sl-focus-ring-width) var(--sl-color-danger-200);
  }
  
`;
