import HttpClient, { IResponseModel } from "../HttpClient";
import {
  IFile,
  IFileConsumerPublic,
  IFileNote,
  IFileState,
  IFileVersion,
  IFileVersionWithData,
  IFileVote,
  IFileWithConsumersUpdated,
  IFileWithData,
} from "@tedivo/tvd-api-models";

import { IBayLevelAdjustedBottomBase } from "@tedivo/tedivo-bay-grid-core";
import { ICompareResult } from "../../../components/features/comparer/comparer-component";
import { IVesselOneParametrizationFormElements } from "../../../components/features/view-json/vessel3D/types/IVesselOneParametrization";
import { IVesselParts } from "open-vessel-definition";
import NetworkService from "../NetworkService";

export declare interface FileService extends Record<string, unknown> {
  get(id: string, noData: true): Promise<IResponseModel<IFile>>;
  get(id: string): Promise<IResponseModel<IFileWithData>>;
}

const FilesService = {
  getAll: async (): Promise<IResponseModel<IFileWithConsumersUpdated[]>> => {
    return NetworkService.getMethod("files");
  },

  get: async (
    id: string,
    noData?: boolean,
  ): Promise<IResponseModel<IFileWithData>> => {
    return NetworkService.getMethod(
      `files/${id}${noData ? "?noData=true" : ""}`,
    );
  },

  isCommunityFile: async (
    orgId: string,
    id: string,
  ): Promise<IResponseModel<{ isCommunity: boolean }>> => {
    return NetworkService.getMethod(`files/isCommunity/${orgId}/${id}`);
  },

  getAllAdmin: async (
    orgId: string,
  ): Promise<IResponseModel<IFileWithConsumersUpdated[]>> => {
    const client = new HttpClient();
    return await client.request<IFileWithConsumersUpdated[]>({
      method: "GET",
      url: `files/org/${orgId}`,
    });
  },

  getAdmin: async (
    id: string,
    orgId: string,
  ): Promise<IResponseModel<IFileWithData>> => {
    const client = new HttpClient();
    return await client.request<IFileWithData>({
      method: "GET",
      url: `files/${id}/${orgId}`,
    });
  },

  delete: async (id: string): Promise<IResponseModel<unknown>> => {
    const client = new HttpClient();
    return await client.request<unknown>({
      method: "DELETE",
      url: `files/${id}`,
      data: undefined,
    });
  },

  create: async (
    data: Omit<IFileWithData, "fileId" | "createdAt">,
  ): Promise<IResponseModel<ICreateOutput>> => {
    const client = new HttpClient();
    return await client.request<ICreateOutput>({
      method: "PUT",
      url: "files",
      data,
    });
  },

  update: async (
    data: Omit<IFileWithData, "createdAt">,
  ): Promise<IResponseModel<ICreateOutput>> => {
    const client = new HttpClient();
    return await client.request<ICreateOutput>({
      method: "PUT",
      url: "files",
      data,
    });
  },

  changeFileState: async (
    fileId: string,
    newState: IFileState,
    comments?: string,
  ): Promise<IResponseModel<void>> => {
    const client = new HttpClient();
    return await client.request<void>({
      method: "PUT",
      url: "files/fileState",
      data: {
        fileId,
        newState,
        comments,
      },
    });
  },

  clone: async (
    fileId: string,
    organizationId: string,
    onlyCommunity: boolean,
    newFileName?: string,
  ): Promise<IResponseModel<ICreateOutput>> => {
    const client = new HttpClient();
    const nName = newFileName ? `?name=${encodeURI(newFileName)}` : "";
    return await client.request<ICreateOutput>({
      method: "PUT",
      url: `files/clone/${fileId}/${organizationId}${nName}`,
    });
  },

  ignoreCloneDiffers: async (
    fileId: string,
  ): Promise<IResponseModel<ICreateOutput>> => {
    const client = new HttpClient();
    return await client.request<ICreateOutput>({
      method: "PUT",
      url: `files/ignoreCloneDiffers/${fileId}`,
    });
  },

  ignoreConsumerDiffers: async (details: {
    sourceOrgId: string;
    sourceFileId: string;
    consumerOrgId: string;
    consumerFileId: string;
    ignoreType: "current" | "all";
  }): Promise<IResponseModel<ICreateOutput>> => {
    const client = new HttpClient();
    return await client.request<ICreateOutput>({
      method: "PUT",
      url: `files/ignoreConsumerDiffers`,
      data: {
        ...details,
      },
    });
  },

  updateVmdShipNameDiffers: async (
    fileId: string,
    action: "replace" | "ignore",
  ): Promise<IResponseModel<void>> => {
    const client = new HttpClient();
    return await client.request<void>({
      method: "PUT",
      url: `files/updateVmdShipNameDiffers/${fileId}`,
      data: { action },
    });
  },

  compareSourceTvl: async (
    fileId: string,
  ): Promise<IResponseModel<ICompareResult>> => {
    const client = new HttpClient();
    return await client.request<ICompareResult>({
      method: "POST",
      url: `files/compareSource/${fileId}`,
    });
  },

  compareOwnVersion: async (
    fileId: string,
    version: string | undefined,
  ): Promise<IResponseModel<ICompareResult>> => {
    const client = new HttpClient();
    return await client.request<ICompareResult>({
      method: "POST",
      url: `files/compareVersion/${fileId}?v=${version}`,
    });
  },

  compareConsumerTvl: async (details: {
    sourceFileId: string;
    consumerOrgId: string;
    consumerFileId: string;
  }): Promise<IResponseModel<ICompareResult>> => {
    const client = new HttpClient();
    return await client.request<ICompareResult>({
      method: "POST",
      url: `files/compareConsumer`,
      data: {
        ...details,
      },
    });
  },

  updateWithSource: async (fileId: string): Promise<IResponseModel<void>> => {
    const client = new HttpClient();
    return await client.request<void>({
      method: "POST",
      url: `files/updateWithSource/${fileId}`,
    });
  },

  updateWithVersion: async (
    fileId: string,
    version: string | undefined,
  ): Promise<IResponseModel<void>> => {
    const client = new HttpClient();
    return await client.request<void>({
      method: "POST",
      url: `files/updateWithVersion/${fileId}?v=${version}`,
    });
  },

  updateWithConsumer: async (details: {
    sourceFileId: string;
    consumerOrgId: string;
    consumerFileId: string;
  }): Promise<IResponseModel<void>> => {
    const client = new HttpClient();
    return await client.request<void>({
      method: "POST",
      url: `files/updateWithConsumer`,
      data: {
        ...details,
      },
    });
  },

  search: async (term: string): Promise<IResponseModel<IFileWithData>> => {
    const client = new HttpClient();
    return await client.request<IFileWithData>({
      method: "GET",
      url: `files/search?q=${term}`,
    });
  },

  getTvlVotes: async (
    orgId: string,
    fileId: string,
  ): Promise<IResponseModel<IFileVoteFromApi>> => {
    const client = new HttpClient();
    return await client.request<IFileVoteFromApi>({
      method: "GET",
      url: `fileVotes/${orgId}/${fileId}`,
    });
  },

  toggleOwnTvlVote: async (
    fileOrgId: string,
    fileId: string,
  ): Promise<IResponseModel<IFileVoteFromApi>> => {
    const client = new HttpClient();
    return await client.request<IFileVoteFromApi>({
      method: "PUT",
      url: `fileVotes`,
      data: {
        fileOrgId,
        fileId,
      },
    });
  },

  getFileVersions: async (
    orgId: string,
    fileId: string,
  ): Promise<IResponseModel<IFileVersion[]>> => {
    const client = new HttpClient();
    return await client.request<IFileVersion[]>({
      method: "GET",
      url: `files/versions/${orgId}/${fileId}`,
    });
  },

  getFileVersion: async (
    orgId: string,
    fileId: string,
    date: string,
  ): Promise<IResponseModel<IFileVersionWithData>> => {
    const client = new HttpClient();
    return await client.request<IFileVersionWithData>({
      method: "POST",
      url: `files/versions`,
      data: {
        fileId,
        organizationId: orgId,
        date: date,
      },
    });
  },

  getFileConsumers: async (
    orgId: string,
    fileId: string,
  ): Promise<IResponseModel<IFileConsumerPublic[]>> => {
    const client = new HttpClient();
    return await client.request<IFileConsumerPublic[]>({
      method: "GET",
      url: `files/consumers/${orgId}/${fileId}`,
    });
  },

  getFileNotes: async (
    orgId: string,
    fileId: string,
    lastEvaluatedKey?: string,
  ): Promise<IResponseModel<IFileNotesResponse>> => {
    const client = new HttpClient();
    const url = [
      `/fileNotes/${orgId}/${fileId}`,
      lastEvaluatedKey
        ? `?lastEvaluatedKey=${encodeURIComponent(
            lastEvaluatedKey.replace(/#/g, "|"),
          )}`
        : "",
    ].join("");

    return await client.request<IFileNotesResponse>({
      method: "GET",
      url,
    });
  },

  addFileNote: async (
    fileId: string,
    note: string,
  ): Promise<IResponseModel<ICreateOutput>> => {
    const client = new HttpClient();
    return await client.request<ICreateOutput>({
      method: "PUT",
      url: `/fileNotes`,
      data: {
        fileId,
        note,
      },
    });
  },

  deleteFileNote: async (
    fileId: string,
    date: string,
  ): Promise<IResponseModel<ICreateOutput>> => {
    const client = new HttpClient();
    return await client.request<ICreateOutput>({
      method: "DELETE",
      url: `/fileNotes/delete/${fileId}/${date}`,
    });
  },

  update3DViewData: async (
    fileId: string,
    v3DParams: {
      params: IVesselOneParametrizationFormElements;
      vesselParts: IVesselParts[];
      adjustedBottomBases: IBayLevelAdjustedBottomBase[];
    },
  ): Promise<IResponseModel<void>> => {
    const client = new HttpClient();
    return await client.request<void>({
      method: "PUT",
      url: `/files/update3DViewData/${fileId}`,
      data: v3DParams,
    });
  },
};

export default FilesService;

interface ICreateOutput {
  id: string;
  state?: IFileState;
  lastComment?: string;
}

type IFileVoteFromApi = Omit<IFileVote, "date"> & {
  totalVotes: number;
  date?: Date;
};

type IFileNotesResponse = {
  notes: IFileNote[];
  lastEvaluatedKey: string;
};
