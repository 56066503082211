import { __rest } from "tslib";
import { sortRowsArray } from "@tedivo/tedivo-bay-grid-pure";
import createBayGrid from "./createBayGrid";
/**
 * Creates a grid (above & below decks) from parameters
 * @returns ISvgWithSize { svg, width, height }
 */
export function createGridFromConfig(_a) {
    var { minAboveTier, maxAboveTier, minBelowTier, maxBelowTier, centerLineRow, maxRow } = _a, gridOptions = __rest(_a, ["minAboveTier", "maxAboveTier", "minBelowTier", "maxBelowTier", "centerLineRow", "maxRow"]);
    let aboveTiersArray = undefined;
    let belowTiersArray = undefined;
    const minRow = centerLineRow ? 0 : 1;
    if (Number(maxRow) < minRow) {
        throw { message: `tedivoGridFromConfig: maxRow must be >= ${minRow}` };
    }
    // Rows
    const rowsArray = new Array(Number(maxRow) + (centerLineRow ? 1 : 0))
        .fill(1)
        .map((_, idx) => String(idx + minRow).padStart(2, "0"))
        .sort(sortRowsArray);
    // Above Tiers
    if (minAboveTier !== undefined && maxAboveTier !== undefined) {
        const from = minAboveTier, to = maxAboveTier;
        aboveTiersArray = [];
        for (let i = from; i <= to; i += 2)
            aboveTiersArray.push(String(i).padStart(2, "0"));
    }
    // Below Tiers
    if (minBelowTier !== undefined && maxBelowTier !== undefined) {
        const from = minBelowTier, to = maxBelowTier;
        belowTiersArray = [];
        for (let i = from; i <= to; i += 2)
            belowTiersArray.push(String(i).padStart(2, "0"));
    }
    if (aboveTiersArray === undefined && belowTiersArray === undefined) {
        throw { message: `tedivoGridFromConfig: no tiers to draw` };
    }
    return createBayGrid(Object.assign(Object.assign({}, gridOptions), { aboveTiersArray: aboveTiersArray || [], belowTiersArray: belowTiersArray || [], rowsArray }));
}
