export function getBrowserLanguage<T = string>(
  defaultLanguage: T,
  supportedLanguages?: readonly T[],
): T {
  if ("localStorage" in window) {
    const savedLanguage = localStorage.getItem("i18n-language") as T;
    if (savedLanguage) return savedLanguage;
  }

  if ("navigator" in window) {
    const navigatorLanguages = navigator.languages
      ? navigator.languages.map((ln) => ln.split("-")[0])
      : new Array(navigator.language.split("-")[0]);

    if (supportedLanguages) {
      const autoLang = navigatorLanguages.find((lng) =>
        supportedLanguages.includes(lng as T),
      );

      if (autoLang) {
        return autoLang as T;
      }
    }
  }

  return defaultLanguage as T;
}
