import {
  BayLevelEnum,
  ForeAftEnum,
  IOpenVesselDefinitionV1,
  LcgReferenceEnum,
  PortStarboardEnum,
  TContainerLengths,
  ValuesSourceEnum,
  ValuesSourceRowTierEnum,
  ovdV1ToStafConverter,
} from "open-vessel-definition";
import { EnumHelpers, pad2 } from "@tedivo/tedivo-pure-helpers";
import {
  IAllBaysWith40sArePairedOutput,
  IValidationOutput,
} from "@tedivo/tedivo-ovd-validators";
import { IFileState, systemRights } from "@tedivo/tvd-api-models";
import {
  ITedivoShoelaceSelectOptions,
  TedivoForm,
  createSelectShoelace,
  translateTedivoForm,
} from "@tedivo/tedivo-form";

import { ICGsStatistics } from "@tedivo/tedivo-bay-grid-pure";
import { IJoinedRowTierPattern } from "open-vessel-definition/build/src/models/base/types/IPositionPatterns";
import SlSwitch from "@shoelace-style/shoelace/dist/components/switch/switch.component";
import UoLcgReferenceEnum from "../../../../app/enums/UoLcgReferenceEnum";
import VcgVerticalReferenceEnum from "../../../../app/enums/VcgVerticalReferenceEnum";
import { ViewJsonTypeEnum } from "../ViewJsonTypeEnum";
import { cloneFileDialog } from "../../my-cloud/cloneFileDialog";
import { getTranslation } from "../../../../app/i18/i18tn";
import globalUnits from "../../../../app/units/globalUnits";
import ovdJsonStore from "../../../../app/stores/OVDJsonStore";
import { routeFns } from "../../../../app/router/routes";
import router from "../../../../app/router";
import securityModule from "../../../../app/security/SecurityModule";
import stafSaveOptions from "../../../../app/stores/stafSaveOptions";
import { z } from "zod";

export function createDownloadOVDJsonLink(
  viewType: ViewJsonTypeEnum,
  fileOrgId: string,
  fileId: string,
  currentFileState: IFileState,
  cgsStats: ICGsStatistics,
): HTMLElement | null {
  const {
    currentJson: json,
    filenameSanitized: name,
    filenameSanitizedForSE,
  } = ovdJsonStore;

  if (securityModule.planIsReadOnly) {
    return null;
  }

  const userIsTedivoAdmin = securityModule.userIsTedivoAdmin;

  function canDownloadJsonOrStaf(right: string, orgId: string) {
    const isSameOrg = orgId === securityModule.currentOrganizationId;
    return (
      currentFileState !== "DRAFT" &&
      securityModule.userHasPermission(right) &&
      isSameOrg
    );
  }

  const options: TDownloadOptions = [
    // A. OVD JSON
    canDownloadJsonOrStaf(
      systemRights.ORG.DownloadOwnOrgFileAsOvd,
      fileOrgId,
    ) || userIsTedivoAdmin
      ? {
          name: getTranslation("view:download.downloadOVDJson"),
          value: "ovdJson",
          icon: "download",
        }
      : undefined,
    // B. STAF
    canDownloadJsonOrStaf(
      systemRights.ORG.DownloadOwnOrgFileAsStaf,
      fileOrgId,
    ) || userIsTedivoAdmin
      ? {
          name: getTranslation("view:download.downloadSTAF"),
          value: "stafFile",
          icon: "download",
        }
      : undefined,
    // C. Clone to my Account
    viewType === ViewJsonTypeEnum.VIEW_OVD &&
    (securityModule.userHasPermission(systemRights.ORG.CanDownloadPublicFile) ||
      userIsTedivoAdmin) &&
    currentFileState === "COMMUNITY"
      ? {
          name: getTranslation("general:search.cloneItToMyAccount"),
          value: "cloneToMyAccount",
          icon: "cloud-download-fill",
        }
      : undefined,
  ].filter(Boolean) as TDownloadOptions;

  if (currentFileState === "DRAFT") {
    return createNoDownloadButton();
  }

  if (options.length === 0) {
    return document.createElement("span");
  }

  const modal = document.createElement("sl-dialog");

  const downloadSelect = createSelectShoelace({
    id: "downloadBtn",
    caret: false,
    pill: true,
    ommitCheckSign: true,
    size: "small",
    variant: "primary",
    buttonText: getTranslation("view:download.title"),
    iconPrefix: "download",
    options,
    onChange: async (v: TDownloadType) => {
      let blob: Blob | undefined = undefined;
      let url: string | undefined = undefined;

      switch (v) {
        case "ovdJson":
        case "stafFile":
          blob = await createBlob(v, json, cgsStats);
          url = blob ? URL.createObjectURL(blob) : undefined;

          if (url) {
            const aDnd = document.createElement("a");
            aDnd.download =
              v === "ovdJson"
                ? `${name}.json`
                : `${filenameSanitizedForSE}.txt`;
            aDnd.href = url;
            aDnd.click();
          }
          break;
        case "cloneToMyAccount":
          cloneFileDialog({
            onlyCommunity: true,
            actionLabel: "general:search.cloneItToMyAccount",
            goSquaredLabel: "TVL - Clone file",
            modal: modal,
            fileData: {
              name: json?.shipData.shipName || "",
              fileId: fileId,
              organizationId: fileOrgId,
            },
          });
          break;
        case "compare":
          {
            const version = router.getRouteParams().version;
            if (!version) return;
            const route = routeFns.fileCompareOwnVersion(fileId, version);
            router.navigate(route, { organizationId: fileOrgId });
          }
          break;
      }
    },
  });

  const btnHolder = document.createElement("div");
  btnHolder.appendChild(downloadSelect);
  btnHolder.appendChild(modal);

  return btnHolder;
}

async function createBlob(
  type: TDownloadType,
  json: IOpenVesselDefinitionV1 | undefined,
  cgsStats: ICGsStatistics | undefined,
): Promise<Blob | undefined> {
  return new Promise((resolve) => {
    if (json === undefined) {
      resolve(undefined);
      return;
    }

    if (type === "ovdJson") {
      const blob = new Blob(
        [
          JSON.stringify(
            json,
            null,
            stafSaveOptions.isFormattedJsonSaved ? 2 : 0,
          ),
        ],
        {
          type: "application/json",
        },
      );

      resolve(blob);
    }

    if (type === "stafFile") {
      const isValidPairedBays = ovdJsonStore.ovdValidator
        .getLastResult(["exportStaf"])
        .find((v) => v.name === "allBaysWith40sArePairedValidator") as
        | IValidationOutput<IAllBaysWith40sArePairedOutput>
        | undefined;

      if (isValidPairedBays && !isValidPairedBays.isValid) {
        const listOfBays = (isValidPairedBays.result.invalidResults || [])
          .map(
            ({ bay, level }) =>
              `${bay} ${getTranslation(
                `enums:BayLevelEnum.${BayLevelEnum[level]}`,
              )}`,
          )
          .join(", ");

        createModalWarning(
          getTranslation("view:download.downloadSTAF"),
          getTranslation(`errors:validators.${isValidPairedBays.name}`) +
            "<br /><br />" +
            listOfBays,
          () => null,
        );
        return;
      }

      createStafExportDialog(json, cgsStats).then((stafFile) => {
        if (stafFile) {
          resolve(
            new Blob([stafFile], {
              type: "text/plain",
            }),
          );
        } else {
          resolve(undefined);
        }
      });
    }
  });
}

const FormValidator: z.Schema<IExportStafOptions> = z.object({
  tier82is: z.custom<number>().transform((value) => Number(value)),
  lcgRef: z.number(),
  tcgRef: z.number(),
  vcgRef: z.number(),
  removeBaysWithNonSizeSlots: z.boolean(),
  removeCGs: z.boolean(),
});

async function createStafExportDialog(
  json: IOpenVesselDefinitionV1,
  cgsStats: ICGsStatistics | undefined,
): Promise<string | null> {
  if (!ovdJsonStore.currentJson || !json || !cgsStats) {
    return null;
  }

  return new Promise((resolve) => {
    const dialog = document.createElement("sl-dialog");
    dialog.label = getTranslation("view:download.downloadSTAFOptions");
    // dialog.style.setProperty("--width", "900px");

    const btnGo = document.createElement("sl-button");
    btnGo.innerHTML = getTranslation("general:common.export");
    btnGo.slot = "footer";
    btnGo.variant = "primary";
    btnGo.type = "submit";

    const lcgRefOptions = EnumHelpers.getNamesAndValues(UoLcgReferenceEnum).map(
      ({ value, name }) => ({
        value,
        label: getTranslation(`enums:UoLcgReferenceEnum.${name}`),
      }),
    );

    const tcgRefOptions = EnumHelpers.getNamesAndValues(PortStarboardEnum).map(
      ({ value, name }) => ({
        value,
        label: getTranslation(`enums:PortStarboardEnum.${name}`),
      }),
    );

    const vcgRefOptions = EnumHelpers.getNamesAndValues(
      VcgVerticalReferenceEnum,
    ).map(({ value, name }) => ({
      value,
      label: getTranslation(`enums:VcgVerticalReferenceEnum.${name}`),
    }));

    const lpp = json.shipData?.lcgOptions?.lpp || 0;

    const cgsPartData = {
      lcgValues:
        json.shipData?.lcgOptions?.values || ValuesSourceEnum.ESTIMATED,
      lcgDataFilled: Math.floor(
        (cgsStats.definedLcgs / (cgsStats.countLcgs || 1)) * 100,
      ),
    };

    const isAboveBelow80 =
      json.sizeSummary.minAboveTier !== undefined &&
      json.sizeSummary.minAboveTier <= 80;

    const hiddenData: Partial<IExportStafOptions> = {};

    const optionsTiersReamp: { value: number; label: string }[] = [];

    if (!isAboveBelow80) {
      hiddenData.tier82is = 82;
    } else {
      for (let i = 68; i <= 98; i += 2) {
        optionsTiersReamp.push({
          value: i,
          label: `${getTranslation(
            i === 82
              ? "general:exportToStaf.doNotRemap"
              : "general:exportToStaf.remapTier82",
            {
              tier: pad2(i),
            },
          )}`,
        });
      }
    }

    const has24s =
      json.sizeSummary.maxBelowTier !== undefined &&
      json.sizeSummary.maxBelowTier > 22;

    let slToggle24s: SlSwitch | undefined = undefined;
    if (has24s) {
      slToggle24s = document.createElement("sl-switch");
      slToggle24s.innerHTML = getTranslation("general:exportToStaf.remove24s");
      slToggle24s.helpText = getTranslation(
        "general:exportToStaf.remove24sHelp",
      );
      slToggle24s.checked = true;
      slToggle24s.className = "space-below";
    }

    const tedivoForm = new TedivoForm<IExportStafOptions>({
      fields: [
        {
          name: "lcgRef",
          type: "radioButtonList",
          label: getTranslation("view:lcgReference"),
          fieldset: true,
          isNumericEnum: true,
          options: lcgRefOptions,
          initialValue: globalUnits.units.lcgRef,
          disabled: lpp === 0,
        },
        {
          name: "tcgRef",
          type: "radioButtonList",
          label: getTranslation("view:tcgDirection"),
          fieldset: true,
          isNumericEnum: true,
          options: tcgRefOptions,
          initialValue: globalUnits.units.tcgDir,
        },
        {
          name: "vcgRef",
          type: "radioButtonList",
          label: getTranslation("view:vcgReference"),
          fieldset: true,
          isNumericEnum: true,
          options: vcgRefOptions,
          initialValue: globalUnits.units.vcgRef,
        },
        isAboveBelow80
          ? {
              name: "tier82is",
              type: "select",
              options: optionsTiersReamp,
              label: "general:exportToStaf.remapAboveTiers",
              initialValue: 82,
            }
          : undefined,
        {
          name: "removeBaysWithNonSizeSlots",
          type: "checkbox",
          label: getTranslation("view:download.removeBaysWithNonSizeSlots"),
          initialValue: areThereSlotsWithoutSizeInSomeBay(),
        },
        {
          name: "removeCGs",
          type: "checkbox",
          label: getTranslation("view:download.removeCGs"),
          initialValue:
            cgsPartData.lcgValues === ValuesSourceEnum.KNOWN &&
            cgsPartData.lcgDataFilled < 100,
        },
      ],
      onSubmit: () => null,
      hiddenData,
      formValidator: FormValidator,
      submitButton: btnGo,
    });

    translateTedivoForm<IExportStafOptions>({ tedivoForm, getTranslation });

    const slDetails = document.createElement("sl-details");
    slDetails.summary = getTranslation("general:common.advancedOptions");
    slDetails.open = false;

    const slIconExpand = document.createElement("sl-icon");
    slIconExpand.name = "chevron-down";
    slIconExpand.slot = "expand-icon";
    const slIconCollapse = document.createElement("sl-icon");
    slIconCollapse.name = "chevron-left";
    slIconCollapse.slot = "collapse-icon";

    slDetails.appendChild(slIconExpand);
    slDetails.appendChild(slIconCollapse);
    slDetails.appendChild(tedivoForm.form);

    if (slToggle24s) dialog.appendChild(slToggle24s);
    dialog.appendChild(slDetails);
    dialog.appendChild(btnGo);

    dialog.addEventListener("sl-request-close", () => {
      dialog.remove();
      resolve(null);
    });

    btnGo.addEventListener("click", () => {
      const validResult = tedivoForm.doSubmitForm();
      if (validResult.success) {
        const values = validResult.data;
        createStafFile(values);
      }
    });

    document.body.appendChild(dialog);
    dialog.show();

    function createStafFile(values: IExportStafOptions) {
      dialog.hide();
      dialog.remove();

      //Do conversion here and resolve with STAF file converted
      const stafFile = ovdV1ToStafConverter(json, {
        cgOptions: {
          lcgOptions: {
            reference:
              values.lcgRef === UoLcgReferenceEnum.AFT_PERPENDICULAR_DFWD
                ? LcgReferenceEnum.AFT_PERPENDICULAR
                : values.lcgRef === UoLcgReferenceEnum.FWD_PERPENDICULAR_DAFT
                ? LcgReferenceEnum.FWD_PERPENDICULAR
                : LcgReferenceEnum.MIDSHIPS,

            orientationIncrease:
              values.lcgRef === UoLcgReferenceEnum.AFT_PERPENDICULAR_DFWD
                ? ForeAftEnum.FWD
                : values.lcgRef === UoLcgReferenceEnum.FWD_PERPENDICULAR_DAFT
                ? ForeAftEnum.AFT
                : values.lcgRef === UoLcgReferenceEnum.MIDSHIPS_DFWD
                ? ForeAftEnum.FWD
                : ForeAftEnum.AFT,

            lpp,
            values:
              ovdJsonStore.currentJson?.shipData?.lcgOptions.values ||
              ValuesSourceEnum.ESTIMATED,
          },
          vcgOptions: {
            values:
              ovdJsonStore.currentJson?.shipData?.vcgOptions.values ===
              ValuesSourceEnum.ESTIMATED
                ? ValuesSourceRowTierEnum.ESTIMATED
                : ValuesSourceRowTierEnum.BY_STACK,

            heightFactor:
              values.vcgRef === VcgVerticalReferenceEnum.VCG_45_8_6
                ? 0.45
                : values.vcgRef === VcgVerticalReferenceEnum.VCG_50_8_6
                ? 0.5
                : 0,
          },
          tcgOptions: {
            values:
              ovdJsonStore.currentJson?.shipData?.tcgOptions.values ||
              ValuesSourceEnum.ESTIMATED,

            direction: values.tcgRef || PortStarboardEnum.STARBOARD,
          },
        },
        tier82is: values.tier82is || 82,
        removeBaysWithNonSizeSlots: values.removeBaysWithNonSizeSlots,
        removeCGs: values.removeCGs,
        removeBelowTiers24AndHigher: slToggle24s?.checked || false,
      });

      resolve(stafFile);
    }

    function areThereSlotsWithoutSizeInSomeBay() {
      const bls = json.baysData;
      for (let i = 0; i < bls.length; i++) {
        const bl = bls[i];

        const perSlotInfo = bl.perSlotInfo;
        if (!perSlotInfo) continue;

        const slotsData = Object.keys(perSlotInfo) as IJoinedRowTierPattern[];
        if (slotsData.length === 0) continue;

        const allSizesSet = new Set<TContainerLengths>();

        // Get all sizes in this bay
        slotsData.forEach((slot) => {
          const slotSizes = Object.keys(perSlotInfo[slot].sizes).map(
            Number,
          ) as TContainerLengths[];

          slotSizes.forEach((size) => allSizesSet.add(size));
        });

        const allSizes = Array.from(allSizesSet);

        if (allSizes.length === 0) return true;
      }

      return false;
    }
  });
}

function createModalWarning(
  title: string,
  message: string,
  onOk: () => void,
): void {
  const dialog = document.createElement("sl-dialog");
  dialog.label = title;

  const btnOk = document.createElement("sl-button");
  btnOk.innerHTML = getTranslation("general:common.ok");
  btnOk.slot = "footer";
  btnOk.variant = "primary";
  btnOk.type = "submit";

  const p = document.createElement("p");
  p.innerHTML = message;

  dialog.appendChild(p);
  dialog.appendChild(btnOk);

  dialog.addEventListener("sl-request-close", () => {
    dialog.remove();
  });

  btnOk.addEventListener("click", () => {
    dialog.hide();
    dialog.remove();
    onOk();
  });

  document.body.appendChild(dialog);
  dialog.show();
}

function createNoDownloadButton() {
  const btn = document.createElement("sl-button");
  btn.size = "small";
  btn.outline = true;
  btn.pill = true;

  const icon = document.createElement("sl-icon");
  icon.name = "download";
  icon.slot = "prefix";

  btn.variant = "neutral";
  btn.innerHTML = getTranslation("view:download.title");
  btn.appendChild(icon);

  btn.addEventListener("click", () => {
    createModalWarning(
      getTranslation("view:download.downloadNotPossible"),
      getTranslation("view:download.downloadNotPossibleText"),
      () => null,
    );
  });

  return btn;
}

type TDownloadType = "ovdJson" | "stafFile" | "cloneToMyAccount" | "compare";

type TDownloadOptions = Array<
  ITedivoShoelaceSelectOptions & { value: TDownloadType }
>;

interface IExportStafOptions extends Record<string, unknown> {
  tier82is?: number;
  lcgRef?: number;
  tcgRef?: number;
  vcgRef?: number;
  removeBaysWithNonSizeSlots?: boolean;
  removeCGs?: boolean;
  remove24s?: boolean;
}
