import { __awaiter } from "tslib";
import "./my-cloud.component.scss";
import { createFilesList } from "./createFilesList";
import { createSelectShoelace, } from "@tedivo/tedivo-form";
import { getPreferencesValue, setPreferencesKeyAndValue, } from "@tedivo/tedivo-preferences";
import { getTranslation, i18nReactive } from "../../../app/i18/i18tn";
import { I18nComponentRegisterer } from "@tedivo/tedivo-i18";
import { IntegratedDialog } from "@tedivo/tedivo-ui";
import Services from "../../../app/services";
import { cloneFileDialog } from "./cloneFileDialog";
import globalStore from "../../../app/stores/globalStore";
import goSquared from "../../../app/tracking/goSquared";
import { removeChildren } from "@tedivo/tedivo-dom-helpers";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import securityModule from "../../../app/security/SecurityModule";
import { setAppTitle } from "../../../app/setAppTitle";
import topMessageElement from "../../layout/top-tools/getTopMessageElement";
export class TVDMyCloudComponent extends HTMLElement {
    constructor() {
        super();
        this.actionsBox = undefined;
        this.dialogTagFile = new IntegratedDialog({
            parentNode: this,
        });
        this.dialogFileNotes = new IntegratedDialog({
            parentNode: this,
            width: "80vw",
        });
        this.lastFilter = undefined;
        this.lastTagFilter = undefined;
        this.fileTags = { tags: {}, filesByTag: {} };
        this.sortOptions = {
            name: "name",
            ascending: true,
        };
        this.currentColumns = [];
        this.displayFiles = () => __awaiter(this, void 0, void 0, function* () {
            var _a;
            globalStore.touchLastUserInteraction();
            const wrapper = this.wrapper;
            const mainBox = this.mainBox;
            removeChildren(mainBox);
            wrapper.setLoading(true);
            const [files, organizationUsersBySub, fileTags] = yield Promise.all([
                Services.files.getAll(),
                securityModule.getOrganizationUsersBySub(securityModule.currentOrganizationId),
                Services.organizations.getFileTags(),
            ]);
            this.fileTags = fileTags.data || { tags: {}, filesByTag: {} };
            const hideFileDeleteButton = securityModule.currentOrganizationPlanId === "FREE";
            const maxFreeFilesReached = hideFileDeleteButton &&
                files.data &&
                ((_a = files.data) === null || _a === void 0 ? void 0 : _a.length) >= securityModule.currentOrganizationMaxFreeTrialFiles;
            if (files.statusCode === 200) {
                const filesWithTags = addTagsToFiles(files.data || [], this.fileTags);
                mainBox.appendChild(createFilesList({
                    data: filesWithTags,
                    fileTags: this.fileTags,
                    onDelete: this.deleteFile,
                    onOpen: this.openFile,
                    onSort: this.saveSortKey,
                    onClone: maxFreeFilesReached ? undefined : this.duplicateFile,
                    onRefreshList: this.displayFiles,
                    onFilter: (s) => {
                        this.lastFilter = s;
                    },
                    onTagsUpdated: (tags, table) => {
                        this.fileTags = tags;
                        const filesWithTags = addTagsToFiles(files.data || [], tags);
                        table.updateData(filesWithTags);
                    },
                    onTagSelected: (tag) => {
                        this.lastTagFilter = tag;
                    },
                    onColumnSelectorChange: this.onColumnSelectorChange,
                    onError: this.onError,
                    sortBy: this.sortOptions,
                    actionsDropdown: this.actionsBox,
                    dialogHistory: this.dialogHistory,
                    dialogTagFile: this.dialogTagFile,
                    dialogFileNotes: this.dialogFileNotes,
                    isReadOnly: securityModule.planIsReadOnly,
                    organizationUsersBySub,
                    initialTextFilter: this.lastFilter,
                    initialTagFilter: this.lastTagFilter,
                    initialColumnsVisible: this.currentColumns,
                    canShowDeleteButton: !hideFileDeleteButton,
                }));
            }
            if (maxFreeFilesReached) {
                const div = document.createElement("div");
                div.className = "small-info";
                div.innerHTML = getTranslation("errors:maxFreeDownloadsReached");
                wrapper.appendChild(div);
            }
            wrapper.setLoading(false);
        });
        this.deleteFile = (id, dta) => {
            const dialog = this.deleteDialog;
            removeChildren(dialog);
            dialog.label = getTranslation("general:cloud.deleteFile");
            const htmlContent = document.createElement("div");
            htmlContent.innerHTML = `${getTranslation("general:cloud.sureDeleteFile")}<br /><br /><strong>${dta.name}</strong>`;
            const closeBtn = document.createElement("sl-button");
            closeBtn.slot = "footer";
            closeBtn.variant = "danger";
            closeBtn.autofocus = true;
            closeBtn.tabIndex = 0;
            closeBtn.innerHTML = getTranslation("general:common.delete");
            closeBtn.addEventListener("click", () => __awaiter(this, void 0, void 0, function* () {
                closeBtn.disabled = true;
                closeBtn.loading = true;
                goSquared.addEvent("My-Cloud - Delete file");
                yield Services.files.delete(id);
                yield this.displayFiles();
                dialog.hide();
            }), false);
            dialog.appendChild(htmlContent);
            dialog.appendChild(closeBtn);
            this.appendChild(dialog);
            dialog.show();
        };
        this.openFile = (id) => __awaiter(this, void 0, void 0, function* () {
            const viewState = {
                source: "cloudOvdJson",
                cloudId: id,
            };
            goSquared.addEvent("My-Cloud - Edit file");
            router.navigate(routeFns.ovdEdit(id), viewState);
        });
        this.duplicateFile = (dta) => __awaiter(this, void 0, void 0, function* () {
            const dialog = this.deleteDialog;
            removeChildren(dialog);
            cloneFileDialog({
                onlyCommunity: false,
                modal: dialog,
                fileData: {
                    fileId: dta.fileId,
                    name: dta.name || "",
                    organizationId: securityModule.currentOrganizationId,
                },
                actionLabel: "view:edit.duplicateFile.title",
                goSquaredLabel: "Edit-OVD - Duplicate",
            });
            this.appendChild(dialog);
            dialog.show();
        });
        this.onColumnSelectorChange = (cols) => {
            this.currentColumns = cols;
            setPreferencesKeyAndValue("myCloud-columns", cols);
        };
        this.saveSortKey = (sortFields) => {
            this.sortOptions = sortFields[0];
            setPreferencesKeyAndValue("myCloud-filesSort", sortFields[0]);
        };
        this.onError = (e) => {
            console.error(e);
            const errorEvent = typeof e === "string"
                ? new CustomEvent("customError", {
                    detail: {
                        errorCode: e,
                        message: "errors:errorHasOcurred",
                        translationKey: getTranslation(e),
                    },
                })
                : new CustomEvent("customError", {
                    detail: {
                        errorCode: e.errorCode,
                        message: "errors:errorHasOcurred",
                        translationKey: getTranslation(e.translationKey || e.message || "errors:errorHasOcurred"),
                    },
                });
            document.body.dispatchEvent(new CustomEvent("customError", errorEvent));
        };
        this.i18nCR = new I18nComponentRegisterer(i18nReactive);
        this.wrapper = document.createElement("div-spinner-element");
        this.mainBox = document.createElement("div");
        this.deleteDialog = document.createElement("sl-dialog");
        this.dialogHistory = document.createElement("sl-dialog");
        setAppTitle(getTranslation("menu:myCloud"));
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            goSquared.trackPage("My Cloud");
            goSquared.addEvent("My-Cloud - Show page");
            {
                this.dialogHistory.setAttribute("style", "--width: 80vw");
                this.dialogHistory.label = getTranslation("view:edit.fileHistory.title");
                this.mainBox.className = "oss-card";
            }
            const wrapper = this.wrapper, h1 = document.createElement("h1");
            const mainBox = this.mainBox;
            wrapper.appendChild(h1);
            wrapper.appendChild(mainBox);
            this.appendChild(wrapper);
            this.appendChild(this.dialogHistory);
            // Translations
            this.i18nCR
                .addConsumer(topMessageElement.element, "general:appName", "innerHTML")
                .addConsumer(h1, "general:cloud.myCloud", "innerHTML", {
                name: securityModule.currentOrganizationName,
            });
            // Actions
            const divActions = document.createElement("div");
            divActions.className = "top-actions";
            const reloadBtnIcon = document.createElement("sl-icon");
            reloadBtnIcon.name = "arrow-clockwise";
            const reloadBtn = document.createElement("sl-button");
            reloadBtn.outline = true;
            reloadBtn.variant = "primary";
            reloadBtn.appendChild(reloadBtnIcon);
            reloadBtn.style.marginLeft = "0.5em";
            reloadBtn.title = getTranslation("general:common.refresh");
            reloadBtn.className = "smart-table-reload-btn";
            reloadBtn.addEventListener("click", () => __awaiter(this, void 0, void 0, function* () {
                reloadBtn.disabled = true;
                yield this.displayFiles();
                reloadBtn.disabled = false;
            }));
            if (!securityModule.planIsReadOnly) {
                const actionsOptions = [
                    {
                        name: getTranslation("menu:newOVD"),
                        value: routeFns.ovdNew(),
                        icon: "file-plus",
                    },
                    {
                        name: getTranslation("menu:fileOpen"),
                        value: routeFns.ovdOpen(),
                        icon: "file-code",
                    },
                    {
                        name: getTranslation("menu:fileImportStaf"),
                        value: routeFns.importStaf(),
                        icon: "file-arrow-up",
                    },
                ];
                const actionsDropdown = createSelectShoelace({
                    id: "actions",
                    caret: false,
                    variant: "primary",
                    placement: "bottom-end",
                    buttonText: getTranslation("general:common.newFile"),
                    iconSuffix: "plus-lg",
                    options: actionsOptions,
                    onChange: (v) => {
                        router.navigate(v);
                    },
                });
                divActions.appendChild(actionsDropdown);
                this.actionsBox = divActions;
            }
            divActions.appendChild(reloadBtn);
            const sortOptionsSavedStr = getPreferencesValue("myCloud-filesSort");
            if (sortOptionsSavedStr) {
                this.sortOptions = JSON.parse(sortOptionsSavedStr);
            }
            this.currentColumns =
                getPreferencesValue("myCloud-columns") ||
                    [];
            this.displayFiles();
        });
    }
}
TVDMyCloudComponent.observedAttributes = [];
customElements.define("tvd-my-cloud-component", TVDMyCloudComponent);
function addTagsToFiles(files, fileTags) {
    const filesAndTagsMap = Object.keys(fileTags.filesByTag).reduce((acc, tag) => {
        const filesTagged = fileTags.filesByTag[tag];
        filesTagged.forEach((f) => {
            if (!acc[f])
                acc[f] = [];
            acc[f].push(fileTags.tags[tag] || tag);
        });
        return acc;
    }, {});
    const filesWithTags = (files || []).map((f) => {
        const tags = filesAndTagsMap[f.fileId] || [];
        return Object.assign(Object.assign({}, f), { tagsStr: tags.sort().join(", ") });
    });
    return filesWithTags;
}
