import { __awaiter } from "tslib";
import { BayLevelEnum, PositionFormatEnum, } from "open-vessel-definition";
import { calculateTeusFromSlots, getBayLcgVcgTcgAndPairings, } from "@tedivo/tedivo-bay-grid-pure";
import { LogEventEntitiesEnum, LogEventTypesEnum, } from "@tedivo/tvd-api-models";
import { arraysAreEqual, cloneObject, diffArrays, objectsAreEqual, pad3, roundDec, sanitizeString, sortByMultipleFields, } from "@tedivo/tedivo-pure-helpers";
import BeaconServices from "../beaconServices";
import { OvdValidator } from "@tedivo/tedivo-ovd-validators";
import Services from "../services";
import { errorTracking } from "../tracking/errorTracking";
import { safeSet } from "./safeSet";
import securityModule from "../security/SecurityModule";
const TypedOvdJsonStoreEventTarget = EventTarget;
export class OVDJsonStore extends TypedOvdJsonStoreEventTarget {
    constructor() {
        super(...arguments);
        this.internalJson = undefined;
        this.originalInternalJson = undefined;
        this.internalName = undefined;
        this.internalCloudInfo = undefined;
        this._lcgVcgTcgAndPairings = undefined;
        this._timeoutEmitter = undefined;
        this.readonlyMode = false;
        this.ovdValidator = new OvdValidator();
        this.saveToCloud = (originalSource, vmdShipNameLatest) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d;
            if (this.readonlyMode)
                return;
            const json = this.internalJson;
            const shipData = json === null || json === void 0 ? void 0 : json.shipData;
            if (!json || !(shipData === null || shipData === void 0 ? void 0 : shipData.shipName))
                return;
            const { cgsStats: cgs } = getBayLcgVcgTcgAndPairings({
                bls: json.baysData,
                vesselPartsData: json.vesselPartsData || [],
                sizeSummary: json.sizeSummary,
                masterCGs: json.shipData.masterCGs,
            });
            const cgsPercentage = roundDec(((cgs.definedLcgs + cgs.definedBottomBase + cgs.definedTcgs) /
                (cgs.countLcgs + cgs.countBottomBase + cgs.countTcgs)) *
                100, 2), lcgsPercentage = roundDec((cgs.definedLcgs / cgs.countLcgs) * 100, 2), tcgsPercentage = roundDec((cgs.definedTcgs / cgs.countTcgs) * 100, 2), vcgsPercentage = roundDec((cgs.definedBottomBase / cgs.countBottomBase) * 100, 2);
            const dataToSave = {
                name: shipData.shipName,
                vmdShipNameLatest,
                vmdShipNameDiffers: vmdShipNameLatest
                    ? shipData.shipName !== vmdShipNameLatest
                    : false,
                cgsPercentage,
                lcgsPercentage,
                tcgsPercentage,
                vcgsPercentage,
                imoCode: shipData.imoCode,
                callSign: shipData.callSign,
                shipClass: shipData.shipClass,
                lastModified: new Date(),
                shipNameAkas: ((_a = shipData.shipNameAkas) === null || _a === void 0 ? void 0 : _a.join("\n")) || "",
                originalSource,
                data: JSON.stringify(json),
            };
            if (this.tvdId !== undefined) {
                // UPDATE;
                const { data } = yield Services.files.update(Object.assign({ fileId: this.tvdId.fileId, userSub: this.tvdId.userSub, organizationId: this.tvdId.organizationId, organizationName: securityModule.currentOrganizationName, state: this.tvdId.fileState, teus: calculateTeusFromSlots(json.baysData), hasHatchCovers: !!((_b = json.lidData) === null || _b === void 0 ? void 0 : _b.length) }, dataToSave));
                if (!data) {
                    this.dispatchEvent(new CustomEvent("error"));
                }
                else {
                    if (data === null || data === void 0 ? void 0 : data.state) {
                        this.tvdId.fileState = data.state;
                        this.tvdId.lastComment = data.lastComment;
                    }
                    this.tvdId.updatedBy = securityModule.userSub;
                }
            }
            else {
                // CREATE
                const apiResp = yield Services.files.create(Object.assign({ userSub: securityModule.userSub, organizationId: securityModule.currentOrganizationId, organizationName: securityModule.currentOrganizationName, state: "DRAFT", hasHatchCovers: !!((_c = json.lidData) === null || _c === void 0 ? void 0 : _c.length), teus: calculateTeusFromSlots(json.baysData) }, dataToSave));
                if (((_d = apiResp.data) === null || _d === void 0 ? void 0 : _d.id) !== undefined) {
                    this.tvdId = {
                        fileId: apiResp.data.id,
                        userSub: securityModule.userSub,
                        organizationId: securityModule.currentOrganizationId,
                        organizationName: securityModule.currentOrganizationName,
                        createdAt: new Date(),
                        fileState: "DRAFT",
                        shipNameAkaStr: "",
                    };
                }
                else {
                    this.tvdId = undefined;
                    throw apiResp;
                }
            }
        });
        this.emitChange = (changeInfo) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            window.clearTimeout(this._timeoutEmitter);
            const json = this.internalJson;
            if (!json)
                return;
            this._lcgVcgTcgAndPairings = getBayLcgVcgTcgAndPairings({
                bls: json.baysData,
                vesselPartsData: json.vesselPartsData || [],
                sizeSummary: json.sizeSummary,
                masterCGs: json.shipData.masterCGs,
            });
            const oldState = ((_a = this.tvdId) === null || _a === void 0 ? void 0 : _a.fileState) || "DRAFT";
            this._timeoutEmitter = window.setTimeout(() => {
                this.ovdValidator.validate(json, this._lcgVcgTcgAndPairings, undefined);
                // Set loading
                this.dispatchEvent(new CustomEvent("updatingDB", { detail: { isUpdating: true } }));
                // Emit change (OPTIMISTIC SAVE)
                this.dispatchEvent(new CustomEvent("jsonUpdated", {
                    detail: changeInfo,
                }));
                this.dispatchEvent(new CustomEvent("jsonLoaded"));
                this.saveToCloud().then(() => {
                    var _a;
                    const newState = ((_a = this.tvdId) === null || _a === void 0 ? void 0 : _a.fileState) || "DRAFT";
                    // Remove loading
                    this.dispatchEvent(new CustomEvent("updatingDB", { detail: { isUpdating: false } }));
                    if (oldState !== newState)
                        this.dispatchEvent(new CustomEvent("stateChanged", {
                            detail: { oldState, newState },
                        }));
                });
            }, 10);
        });
    }
    setJson(shipName, json) {
        this.internalName = shipName;
        this.internalJson = json === undefined ? undefined : cloneObject(json);
        this.originalInternalJson =
            json === undefined ? undefined : cloneObject(json);
        if (json !== undefined) {
            Object.freeze(this.originalInternalJson);
            this._lcgVcgTcgAndPairings = getBayLcgVcgTcgAndPairings({
                bls: json.baysData,
                vesselPartsData: json.vesselPartsData || [],
                sizeSummary: json.sizeSummary,
                masterCGs: json.shipData.masterCGs,
            });
            this.ovdValidator.validate(json, this._lcgVcgTcgAndPairings, undefined);
        }
        errorTracking.leaveBreadcrumb(`JSON "${this.internalName}" total slots defined: ${this.totalSlotsDefined}`);
        this.dispatchEvent(new CustomEvent("jsonLoaded"));
        return this;
    }
    setLpp(lpp) {
        var _a, _b;
        if (this.internalJson === undefined)
            return this;
        if (((_b = (_a = this.internalJson.shipData) === null || _a === void 0 ? void 0 : _a.lcgOptions) === null || _b === void 0 ? void 0 : _b.lpp) === lpp)
            return this;
        safeSet(this.internalJson.shipData, "lcgOptions.lpp", lpp);
        this.emitChange({ type: OVDChangesEnum.SHIP_SIZE });
        return this;
    }
    setCgOptions(lcgValues, tcgValues, vcgValues, loa, sternToAftPp, lpp) {
        if (this.internalJson === undefined || !this.internalCloudInfo)
            return this;
        const oldData = {
            lcgValues: this.internalJson.shipData.lcgOptions.values,
            tcgValues: this.internalJson.shipData.tcgOptions.values,
            vcgValues: this.internalJson.shipData.vcgOptions.values,
            loa: this.internalJson.shipData.loa,
            sternToAftPp: this.internalJson.shipData.sternToAftPp,
            lpp: this.internalJson.shipData.lcgOptions.lpp,
        };
        const newData = {
            lcgValues,
            tcgValues,
            vcgValues,
            loa,
            sternToAftPp,
            lpp,
        };
        const isDataEqual = objectsAreEqual(oldData, newData);
        if (isDataEqual) {
            return this;
        }
        if (lcgValues !== undefined) {
            safeSet(this.internalJson.shipData, "lcgOptions.values", lcgValues);
        }
        if (tcgValues !== undefined) {
            safeSet(this.internalJson.shipData, "tcgOptions.values", tcgValues);
        }
        if (vcgValues !== undefined) {
            safeSet(this.internalJson.shipData, "vcgOptions.values", vcgValues);
        }
        if (loa !== undefined) {
            safeSet(this.internalJson.shipData, "loa", loa);
        }
        if (sternToAftPp !== undefined) {
            safeSet(this.internalJson.shipData, "sternToAftPp", sternToAftPp);
        }
        if (lpp !== undefined)
            safeSet(this.internalJson.shipData, "lcgOptions.lpp", lpp);
        this.emitChange({ type: OVDChangesEnum.FULL });
        BeaconServices.logEvents.notifyXhttp(Object.assign({ eventEntity: LogEventEntitiesEnum.File, eventType: LogEventTypesEnum.Modified, subEvent: "CGsOptions", itemId: this.internalCloudInfo.fileId }, securityModule.getBeaconMetadata()));
        return this;
    }
    setShipDataGeneralInfo({ lineOperator, shipClass, shipName, callSign, imoCode, positionFormat, containersLengths, featuresAllowed: fA, yearBuilt, loa, shipNameAkaStr, }, doEmitChange = true) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        if (this.internalJson === undefined)
            return this;
        const featuresAllowed = fA
            ? {
                slotCoolStowProhibited: fA.indexOf("slotCoolStowProhibited") >= 0,
                slotHazardousProhibited: fA.indexOf("slotHazardousProhibited") >= 0,
                slotConeRequired: fA.indexOf("slotConeRequired") >= 0,
            }
            : {
                slotCoolStowProhibited: false,
                slotHazardousProhibited: false,
                slotConeRequired: false,
            };
        const shipNameAkas = (shipNameAkaStr === null || shipNameAkaStr === void 0 ? void 0 : shipNameAkaStr.replace(/,/i, "\n").split("\n").map((s) => s.trim().toUpperCase())) || [];
        const newShipData = {
            lineOperator,
            shipClass: shipClass || "",
            shipName,
            callSign,
            imoCode,
            positionFormat: positionFormat || PositionFormatEnum.BAY_STACK_TIER,
            containersLengths: containersLengths || [],
            featuresAllowed,
            yearBuilt,
            loa,
            shipNameAkas,
        };
        const oldSubShipData = {
            lineOperator: (_a = this.internalJson.shipData) === null || _a === void 0 ? void 0 : _a.lineOperator,
            shipClass: ((_b = this.internalJson.shipData) === null || _b === void 0 ? void 0 : _b.shipClass) || "",
            shipName: (_c = this.internalJson.shipData) === null || _c === void 0 ? void 0 : _c.shipName,
            callSign: (_d = this.internalJson.shipData) === null || _d === void 0 ? void 0 : _d.callSign,
            imoCode: (_e = this.internalJson.shipData) === null || _e === void 0 ? void 0 : _e.imoCode,
            positionFormat: ((_f = this.internalJson.shipData) === null || _f === void 0 ? void 0 : _f.positionFormat) ||
                PositionFormatEnum.BAY_STACK_TIER,
            containersLengths: ((_g = this.internalJson.shipData) === null || _g === void 0 ? void 0 : _g.containersLengths) || [],
            featuresAllowed: ((_h = this.internalJson.shipData) === null || _h === void 0 ? void 0 : _h.featuresAllowed) || {
                slotCoolStowProhibited: false,
                slotHazardousProhibited: false,
                slotConeRequired: false,
            },
            yearBuilt: (_j = this.internalJson.shipData) === null || _j === void 0 ? void 0 : _j.yearBuilt,
            shipNameAkas: ((_k = this.internalJson.shipData) === null || _k === void 0 ? void 0 : _k.shipNameAkas) || [],
        };
        const shipDataIsEqual = objectsAreEqual(oldSubShipData, newShipData, true);
        if (shipDataIsEqual)
            return this;
        const shipData = this.internalJson.shipData;
        safeSet(shipData, "lineOperator", lineOperator);
        safeSet(shipData, "shipClass", shipClass);
        safeSet(shipData, "shipName", shipName);
        safeSet(shipData, "callSign", callSign);
        safeSet(shipData, "imoCode", imoCode);
        safeSet(shipData, "positionFormat", positionFormat);
        safeSet(shipData, "containersLengths", containersLengths);
        safeSet(shipData, "yearBuilt", yearBuilt);
        if (loa !== undefined)
            safeSet(shipData, "loa", loa);
        safeSet(shipData, "shipNameAkas", shipNameAkas);
        if (fA) {
            const featuresAllowed = {
                slotCoolStowProhibited: fA.indexOf("slotCoolStowProhibited") >= 0,
                slotHazardousProhibited: fA.indexOf("slotHazardousProhibited") >= 0,
                slotConeRequired: fA.indexOf("slotConeRequired") >= 0,
            };
            safeSet(shipData, "featuresAllowed", featuresAllowed);
        }
        if (doEmitChange) {
            this.emitChange({ type: OVDChangesEnum.SHIP_DATA_GENERAL_INFO });
            if (!shipDataIsEqual && this.internalCloudInfo)
                BeaconServices.logEvents.notifyXhttp(Object.assign({ eventEntity: LogEventEntitiesEnum.File, eventType: LogEventTypesEnum.Modified, subEvent: "VesselInfo", itemId: this.internalCloudInfo.fileId }, securityModule.getBeaconMetadata()));
        }
        return this;
    }
    setShipSize(prms) {
        var _a, _b;
        if (this.internalJson === undefined || !this.internalCloudInfo)
            return this;
        const prevSizeSummary = Object.assign({}, this.internalJson.sizeSummary);
        const createEmptyBayData = (isoBay, level) => ({
            isoBay,
            level,
            infoByContLength: {},
            perSlotInfo: {},
            centerLineRow: prms.centerLineRow ? 1 : 0,
        });
        const newSizeSummary = {
            isoBays: prms.isoBays,
            centerLineRow: prms.centerLineRow ? 1 : 0,
            maxRow: prms.maxRow,
            minBelowTier: prms.minBelowTier,
            maxBelowTier: prms.maxBelowTier,
            minAboveTier: prms.minAboveTier,
            maxAboveTier: prms.maxAboveTier,
        };
        if (objectsAreEqual(prevSizeSummary, newSizeSummary))
            return this;
        // If isoBays changes, create/delete new bayData
        if (prms.isoBays !== prevSizeSummary.isoBays) {
            const currentBaysData = this.internalJson.baysData;
            const baysDataPerIsoBayAndLevel = {};
            currentBaysData.forEach((bl) => {
                if (!baysDataPerIsoBayAndLevel[bl.isoBay])
                    baysDataPerIsoBayAndLevel[bl.isoBay] = {};
                baysDataPerIsoBayAndLevel[bl.isoBay][bl.level === BayLevelEnum.ABOVE ? "above" : "below"] = bl;
            });
            const newBaysData = [];
            for (let i = 1; i <= prms.isoBays; i += 2) {
                const isoBay = pad3(i);
                if (prms.hasAboveDeck) {
                    const bayDataAbove = (_a = baysDataPerIsoBayAndLevel[isoBay]) === null || _a === void 0 ? void 0 : _a.above;
                    if (bayDataAbove !== undefined) {
                        newBaysData.push(bayDataAbove);
                    }
                    else {
                        newBaysData.push(createEmptyBayData(isoBay, BayLevelEnum.ABOVE));
                    }
                }
                if (prms.hasBelowDeck) {
                    const bayDataBelow = (_b = baysDataPerIsoBayAndLevel[isoBay]) === null || _b === void 0 ? void 0 : _b.below;
                    if (bayDataBelow !== undefined) {
                        newBaysData.push(bayDataBelow);
                    }
                    else {
                        newBaysData.push(createEmptyBayData(isoBay, BayLevelEnum.BELOW));
                    }
                }
            }
            newBaysData.sort(sortByMultipleFields([
                { name: "isoBay", ascending: true },
                { name: "level", ascending: true },
            ]));
            this.internalJson.baysData = newBaysData;
        }
        this.internalJson.sizeSummary = newSizeSummary;
        this.emitChange({ type: OVDChangesEnum.SHIP_SIZE });
        BeaconServices.logEvents.notifyXhttp(Object.assign({ eventEntity: LogEventEntitiesEnum.File, eventType: LogEventTypesEnum.Modified, subEvent: "Size", itemId: this.internalCloudInfo.fileId }, securityModule.getBeaconMetadata()));
        return this;
    }
    setPairings(pairings) {
        const json = this.internalJson;
        if (json === undefined)
            return this;
        let emitChange = false;
        const pairingsMap = pairings.reduce((acc, p) => {
            acc.set(p.isoBay, p.pairedBay);
            return acc;
        }, new Map());
        for (let i = 0; i < json.baysData.length; i++) {
            const bayData = json.baysData[i];
            const pairedBay = pairingsMap.get(bayData.isoBay);
            if (pairedBay !== undefined && bayData.pairedBay !== pairedBay) {
                bayData.pairedBay = pairedBay;
                emitChange = true;
            }
        }
        if (emitChange)
            this.emitChange({ type: OVDChangesEnum.ALL_BAYS });
        return this;
    }
    setLids(lidData) {
        var _a, _b;
        if (this.internalJson === undefined || !this.internalCloudInfo)
            return this;
        if (arraysAreEqual(this.internalJson.lidData, lidData, true))
            return this;
        const diffs = diffArrays(this.internalJson.lidData, lidData, true);
        const diffsBays = [
            ...(((_a = diffs.added) === null || _a === void 0 ? void 0 : _a.flatMap((l) => [l.startIsoBay, l.endIsoBay])) || []),
            ...(((_b = diffs.removed) === null || _b === void 0 ? void 0 : _b.flatMap((l) => [l.startIsoBay, l.endIsoBay])) || []),
        ].filter((v, i, a) => a.indexOf(v) === i);
        this.internalJson.lidData = lidData;
        this.emitChange({
            type: OVDChangesEnum.LIDS,
            data: diffsBays,
        });
        BeaconServices.logEvents.notifyXhttp(Object.assign({ eventEntity: LogEventEntitiesEnum.File, eventType: LogEventTypesEnum.Modified, subEvent: "HatchCovers", itemId: this.internalCloudInfo.fileId }, securityModule.getBeaconMetadata()));
        return this;
    }
    setVesselParts(vesselPartsData) {
        if (this.internalJson === undefined || !this.internalCloudInfo)
            return this;
        if (arraysAreEqual(this.internalJson.vesselPartsData, vesselPartsData, true))
            return this;
        this.internalJson.vesselPartsData = vesselPartsData;
        BeaconServices.logEvents.notifyXhttp(Object.assign({ eventEntity: LogEventEntitiesEnum.File, eventType: LogEventTypesEnum.Modified, subEvent: "VesselParts", itemId: this.internalCloudInfo.fileId }, securityModule.getBeaconMetadata()));
        return this;
    }
    findBayInfo(isoBay, level) {
        if (this.internalJson === undefined)
            return undefined;
        return this.internalJson.baysData.find((bl) => bl.isoBay === isoBay && bl.level === level);
    }
    replaceBayInfo(isoBay, level, bayDataSrc, doEmitChange = true) {
        if (this.internalJson === undefined)
            return this;
        const bayData = cleanUpBay(bayDataSrc);
        const idx = this.internalJson.baysData.findIndex((bl) => bl.isoBay === isoBay && bl.level === level);
        if (idx >= 0) {
            this.internalJson.baysData[idx] = bayData;
        }
        else {
            this.internalJson.baysData.push(bayData);
            this.internalJson.baysData.sort(sortByMultipleFields([
                { name: "isoBay", ascending: true },
                { name: "level", ascending: true },
            ]));
        }
        if (doEmitChange)
            this.emitChange({
                type: OVDChangesEnum.BAY_INFO,
                data: [`${isoBay}-${level}`],
            });
        return this;
    }
    replaceJson(json) {
        if (this.internalJson === undefined)
            return this;
        this.internalJson = json;
        this.emitChange({ type: OVDChangesEnum.FULL });
        return this;
    }
    setCGsInfo(isoBay, level, perRowInfo, infoByContLength) {
        if (this.internalJson === undefined || !this.internalCloudInfo)
            return this;
        const idx = this.internalJson.baysData.findIndex((bl) => bl.isoBay === isoBay && bl.level === level);
        if (idx < 0)
            return this;
        if (objectsAreEqual(perRowInfo, this.internalJson.baysData[idx].perRowInfo) &&
            objectsAreEqual(infoByContLength, this.internalJson.baysData[idx].infoByContLength)) {
            return this;
        }
        this.internalJson.baysData[idx].perRowInfo = perRowInfo;
        this.internalJson.baysData[idx].infoByContLength = infoByContLength;
        this.emitChange({
            type: OVDChangesEnum.CGS_INFO,
            data: [`${isoBay}-${level}`],
        });
        BeaconServices.logEvents.notifyXhttp(Object.assign({ eventEntity: LogEventEntitiesEnum.File, eventType: LogEventTypesEnum.Modified, subEvent: "BayCGs", itemId: this.internalCloudInfo.fileId }, securityModule.getBeaconMetadata()));
        return this;
    }
    setMasterCGs(masterCGs) {
        if (this.internalJson === undefined || !this.internalCloudInfo)
            return this;
        if (objectsAreEqual(this.internalJson.shipData.masterCGs, masterCGs))
            return this;
        this.internalJson.shipData.masterCGs = masterCGs;
        this.emitChange({ type: OVDChangesEnum.MASTER_CGS });
        BeaconServices.logEvents.notifyXhttp(Object.assign({ eventEntity: LogEventEntitiesEnum.File, eventType: LogEventTypesEnum.Modified, subEvent: "MasterCGs", itemId: this.internalCloudInfo.fileId }, securityModule.getBeaconMetadata()));
        return this;
    }
    setFileState(newState, comments) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.internalJson === undefined || !this.tvdId)
                return undefined;
            const oldState = this.tvdId.fileState;
            if (oldState === newState)
                return undefined;
            this.tvdId.fileState = newState;
            this.dispatchEvent(new CustomEvent("updatingDB", { detail: { isUpdating: true } }));
            const resp = yield Services.files.changeFileState(this.tvdId.fileId, newState, comments);
            this.dispatchEvent(new CustomEvent("updatingDB", { detail: { isUpdating: false } }));
            if (resp.statusCode !== 200) {
                this.tvdId.fileState = oldState;
            }
            else {
                this.tvdId.fileState = newState;
                if (newState !== "DRAFT")
                    this.tvdId.lastComment = comments;
                this.dispatchEvent(new CustomEvent("stateChanged", { detail: { oldState, newState } }));
                this.dispatchEvent(new CustomEvent("jsonUpdated", {
                    detail: { type: OVDChangesEnum.FILE_STATE },
                }));
            }
            return resp;
        });
    }
    set3DViewParams(params_1) {
        return __awaiter(this, arguments, void 0, function* (params, vesselParts = [], adjustedBottomBases = []) {
            if (this.internalJson === undefined || !this.tvdId)
                return undefined;
            this.dispatchEvent(new CustomEvent("updatingDB", { detail: { isUpdating: true } }));
            const resp = yield Services.files.update3DViewData(this.tvdId.fileId, {
                params,
                vesselParts,
                adjustedBottomBases,
            });
            this.tvdId.v3DParams = { params, vesselParts, adjustedBottomBases };
            this.setVesselParts(vesselParts);
            this.dispatchEvent(new CustomEvent("updatingDB", { detail: { isUpdating: false } }));
            if (resp.statusCode === 200 || resp.statusCode === 204) {
                this.dispatchEvent(new CustomEvent("jsonUpdated", {
                    detail: { type: OVDChangesEnum.VESSEL_PARTS },
                }));
            }
            return resp;
        });
    }
    get originalJson() {
        return this.originalInternalJson;
    }
    get currentJson() {
        return this.internalJson;
    }
    get filenameSanitized() {
        return sanitizeString(this.internalName || "");
    }
    get filenameSanitizedForSE() {
        var _a;
        const shipData = (_a = this.internalJson) === null || _a === void 0 ? void 0 : _a.shipData;
        function snUpp(s) {
            return sanitizeString(s || "").toUpperCase();
        }
        const name = shipData
            ? `${snUpp(shipData.shipClass || "___")}.${snUpp(shipData.lineOperator || "___")}.${snUpp(shipData.shipName || "___")}`
            : sanitizeString(this.internalName || "");
        return name;
    }
    get tvdId() {
        return this.internalCloudInfo;
    }
    set tvdId(cloudInfo) {
        this.internalCloudInfo = cloudInfo;
    }
    get totalSlotsDefined() {
        if (this.internalJson === undefined)
            return 0;
        return this.internalJson.baysData.reduce((acc, bayData) => acc + Object.keys(bayData.perSlotInfo || {}).length, 0);
    }
    get lcgVcgTcgAndPairings() {
        return this._lcgVcgTcgAndPairings;
    }
    restoreOriginalJson() {
        this.setJson(this.internalName || "", cloneObject(this.originalInternalJson));
        return this;
    }
}
const ovdJsonStore = new OVDJsonStore();
export default ovdJsonStore;
function cleanUpBay(bayData) {
    deleteVerboseOptionalFalsyKeysInBayData(bayData, [
        "label20",
        "label40",
        "pairedBay",
        "reeferPlugs",
        "doors",
        "reeferPlugLimit",
        "athwartShip",
        "foreHatch",
        "ventilated",
        "heatSrcFore",
        "ignitionSrcFore",
        "quartersFore",
        "engineRmBulkFore",
    ]);
    return bayData;
    function deleteVerboseOptionalFalsyKeysInBayData(bayData, keys) {
        keys.forEach((key) => {
            if (!bayData[key])
                delete bayData[key];
        });
    }
}
export var OVDChangesEnum;
(function (OVDChangesEnum) {
    OVDChangesEnum["SHIP_DATA_GENERAL_INFO"] = "SHIP_DATA_GENERAL_INFO";
    OVDChangesEnum["SHIP_SIZE"] = "SHIP_SIZE";
    OVDChangesEnum["ALL_BAYS"] = "ALL_BAYS";
    OVDChangesEnum["LIDS"] = "LIDS";
    OVDChangesEnum["BULKHEADS"] = "BULKHEADS";
    OVDChangesEnum["VESSEL_PARTS"] = "VESSEL_PARTS";
    OVDChangesEnum["BAY_INFO"] = "BAY_INFO";
    OVDChangesEnum["CGS_INFO"] = "CGS_INFO";
    OVDChangesEnum["MASTER_CGS"] = "MASTER_CGS";
    OVDChangesEnum["FILE_STATE"] = "FILE_STATE";
    OVDChangesEnum["FULL"] = "FULL";
})(OVDChangesEnum || (OVDChangesEnum = {}));
