import SlBreadcrumbItem from "@shoelace-style/shoelace/dist/components/breadcrumb-item/breadcrumb-item";
import router from "../../../app/router";

export default class BreadcrumbsElement extends HTMLElement {
  public static observedAttributes = [];
  public items: IBcItems[] = [];
  private bcs: SlBreadcrumbItem[] = [];

  connectedCallback() {
    const bcHolder = document.createElement("sl-breadcrumb");

    this.items.forEach(({ href, label }) => {
      const bcItem = document.createElement("sl-breadcrumb-item");
      bcItem.innerHTML = label;
      bcItem.href = href;

      bcItem.onclick = function (ev: Event) {
        console.log("click", ev.target);
        ev.preventDefault();
        if (href) router.navigate(href);
        return false;
      };
      bcHolder.appendChild(bcItem);
      this.bcs.push(bcItem);
    });

    this.appendChild(bcHolder);
  }

  setLastItemText(label: string) {
    this.bcs[this.bcs.length - 1].innerHTML = label;
  }
}

customElements.define("breadcrumbs-element", BreadcrumbsElement);

declare global {
  interface HTMLElementTagNameMap {
    "breadcrumbs-element": BreadcrumbsElement;
  }
}

interface IBcItems {
  href?: string;
  label: string;
}
