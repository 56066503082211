import { IBayLevelData } from "open-vessel-definition";
import { IBayLevelPairing } from "./IBayLevelPairing";
import { SmartTable } from "@tedivo/tedivo-smart-table";

export default class PairingsRowsFactory {
  usingAModal: ((a: boolean) => void) | undefined = undefined;
  onChange: Map<string, () => void> = new Map();

  private _data: Map<string, IBayLevelPairing> = new Map();

  private _editingArea: HTMLElement;

  public smartTable: SmartTable<IBayLevelPairing> | undefined = undefined;

  public hasBeenSubmitted = false;

  constructor(editingArea: HTMLElement, usingAModal: (a: boolean) => void) {
    this.usingAModal = usingAModal;
    this._editingArea = editingArea;
  }

  setData(data: IBayLevelData[]) {
    this._data.clear();
    data.forEach((d) => {
      if (this._data.get(d.isoBay) === undefined) {
        this._data.set(d.isoBay, {
          isoBay: d.isoBay,
          pairedBay: d.pairedBay,
        });
      }
    });
  }

  setRawData(data: IBayLevelPairing[]) {
    this._data.clear();
    data.forEach((d) => {
      this._data.set(d.isoBay, {
        isoBay: d.isoBay,
        pairedBay: d.pairedBay,
      });
    });
  }

  getRawData(): IBayLevelPairing[] {
    return Array.from(this._data.values());
  }

  addPrbData(data: IBayLevelPairing): string {
    this._data.set(data.isoBay, data);
    return data.isoBay;
  }

  updatePrbData(pk: string, data: IBayLevelPairing): Promise<void> {
    return new Promise((resolve) => {
      if (this._data.get(pk)) {
        this._data.set(pk, { ...data });
        this.onChange.forEach((f) => f());
      }
      resolve();
    });
  }

  deletePrbData(pk: string) {
    this._data.delete(pk);
  }

  getSinglePrbData(pk: string) {
    return this._data.get(pk);
  }
}
