import Bugsnag from "@bugsnag/js";
import BugsnagPerformance, { DefaultRoutingProvider, } from "@bugsnag/browser-performance";
import { routeFns } from "../router/routes";
export const errorTracking = {
    start(bugsnagId, stage) {
        var _a;
        const appVersion = `v1.0.${(_a = process.env.NX_PUBLIC_BUILD_NUMBER) !== null && _a !== void 0 ? _a : "Beta"}`;
        Bugsnag.start({
            apiKey: bugsnagId || "",
            releaseStage: stage || "unknown",
            appVersion,
            maxBreadcrumbs: 100,
        });
        console.info("TEDIVO Vessel Designer ", appVersion);
        if (process.env.NX_PUBLIC_STAGE === "dev")
            window.Bugsnag = Bugsnag;
        BugsnagPerformance.start({
            apiKey: bugsnagId,
            releaseStage: stage || "unknown",
            appVersion,
            routingProvider: new DefaultRoutingProvider(resolveRoutes()),
        });
    },
    identify(userSub, email, displayName, organizationName) {
        if (!Bugsnag.isStarted())
            return;
        Bugsnag.setUser(userSub, email, `${displayName} / ${organizationName}`);
    },
    leaveBreadcrumb(message, 
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metadata, type) {
        if (!Bugsnag.isStarted())
            return;
        Bugsnag.leaveBreadcrumb(message, metadata, type);
    },
};
function resolveRoutes() {
    const pathsWithParams = [
        routeFns.fileCompareTvlSource(":id"),
        routeFns.fileCompareOwnVersion(":id", ":version"),
        routeFns.fileCompareConsumer(":id", ":consumerOrgId", ":consumerFileId"),
        routeFns.fileMap(":id"),
        routeFns.adminInvoices(":orgId"),
        routeFns.ovdEdit(":id"),
        routeFns.ovdViewOnly(":id"),
        routeFns.ovdViewOnlyVersion(":id", ":version"),
        routeFns.theirCloud(":orgId"),
        routeFns.viewTvl(":orgId", ":fileId"),
        routeFns.adminOrganizationUsers(":orgId"),
    ];
    const regexes = new Map();
    pathsWithParams.forEach((path) => {
        regexes.set(path, new RegExp(path.replace(/:\w+/g, "([\\w-]+)").replace(/\//g, "\\/")));
    });
    return function resolveRoute(url) {
        regexes.forEach((regrex, path) => {
            const match = url.pathname.match(regrex);
            if (match) {
                return path;
            }
        });
        return url.pathname;
    };
}
