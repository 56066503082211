import { getRowsAndTiersFromSlotKeys, } from "@tedivo/tedivo-bay-grid-pure";
import { pad2 } from "@tedivo/tedivo-pure-helpers";
export function getStackWeightsOfBayLevel(bl, level) {
    var _a;
    if (!bl)
        return [];
    // 1. Which rows have slots defined?
    const perSlotInfo = bl.perSlotInfo || {};
    const effectiveSlotKeys = Object.keys(perSlotInfo).filter((sk) => perSlotInfo[sk].restricted !== 1 &&
        Object.keys(perSlotInfo[sk].sizes || {}).length > 0);
    if (effectiveSlotKeys.length === 0)
        return []; // No slots defined
    const { rows, tiersByRow } = getRowsAndTiersFromSlotKeys(effectiveSlotKeys);
    // 2. Which sizes are defined for each row?
    const sizesByRow = rows.reduce((acc, row) => {
        effectiveSlotKeys
            .filter((sk) => sk.startsWith(pad2(row)))
            .forEach((sk) => {
            const sizes = perSlotInfo[sk].sizes || {};
            Object.keys(sizes).forEach((size) => {
                if (!acc[row])
                    acc[row] = new Set();
                acc[row].add(Number(size));
            });
        });
        return acc;
    }, {});
    const ifcl = bl === null || bl === void 0 ? void 0 : bl.infoByContLength;
    const perRowInfoEach = (_a = bl === null || bl === void 0 ? void 0 : bl.perRowInfo) === null || _a === void 0 ? void 0 : _a.each;
    const res = [];
    // 3. Get the stack weights for each size
    Object.keys(sizesByRow).forEach((row) => {
        const bwOfRow = sizesByRow[row];
        const sws = new Map();
        bwOfRow.forEach((size) => {
            var _a, _b, _c;
            const perRowInfo = (_b = (_a = perRowInfoEach === null || perRowInfoEach === void 0 ? void 0 : perRowInfoEach[row]) === null || _a === void 0 ? void 0 : _a.rowInfoByLength) === null || _b === void 0 ? void 0 : _b[size];
            const weight = (perRowInfo === null || perRowInfo === void 0 ? void 0 : perRowInfo.rowWeight) || ((_c = ifcl === null || ifcl === void 0 ? void 0 : ifcl[size]) === null || _c === void 0 ? void 0 : _c.rowWeight);
            if (weight)
                sws.set(size, weight);
        });
        res.push({
            row,
            bottomTier: tiersByRow[row].minTier,
            level,
            sws,
        });
    });
    return res;
}
