import { IBayPattern, getAllThePairedBays } from "@tedivo/tedivo-bay-grid-pure";
import {
  IValidationInput,
  IValidationOutput,
  IValidator,
  TValidatorSpecialScopes,
} from "../types";

import { BayLevelEnum } from "open-vessel-definition";

const name = "allBaysWith40sArePairedValidator";
const okTranslationKey = "validators:bays.allBaysWith40sArePaired.ok";
const errorTranslationKey = "validators:bays.allBaysWith40sArePaired.error";

const specialScopes: TValidatorSpecialScopes[] = [];

/** Check if all bays with 40s are paired */
export const allBaysWith40sArePairedValidator: IValidator<IAllBaysWith40sArePairedOutput> =
  {
    name,
    okTranslationKey,
    errorTranslationKey,
    fn: allBaysWith40sArePairedValidatorFn,
    specialScopes,
  };

function allBaysWith40sArePairedValidatorFn({
  data: { json },
}: IValidationInput): IValidationOutput<IAllBaysWith40sArePairedOutput> {
  const baysData = json.baysData;
  const { pairedBays, unpairedBays } = getAllThePairedBays(baysData, true);
  const isValid = unpairedBays.length === 0;

  return {
    name,
    specialScopes,
    isValid,
    messageTranslationKey: isValid ? okTranslationKey : errorTranslationKey,
    result: {
      validResults: pairedBays.map(({ base, level }) => ({ bay: base, level })),
      invalidResults: unpairedBays.map(({ base, level }) => ({
        bay: base,
        level,
      })),
    },
  };
}

export interface IAllBaysWith40sArePairedOutput {
  validResults: IAllBaysWith40sArePairedSingleResult[];
  invalidResults: IAllBaysWith40sArePairedSingleResult[];
}

interface IAllBaysWith40sArePairedSingleResult {
  bay: IBayPattern;
  level: BayLevelEnum;
}
