/**
 * Object to Store. It uses the localStorage indeed. This is a copy.
 */
export const store: IStoreData = {};

/**
 * Gets the value of preferences by KEY
 */
export function getPreferencesValue(key: string): IStoreTypes | undefined {
  if ("localStorage" in globalThis) {
    const val = localStorage.getItem(key);
    if (val === undefined || val === null) return undefined;

    const valNumeric = Number(val);
    if (!isNaN(valNumeric)) return valNumeric;

    if (val === "false") return false;
    if (val === "true") return true;

    return typeof val === "string" &&
      val[0] !== "[" &&
      val[val.length - 1] !== "]"
      ? val
      : JSON.parse(val);
  }
  return store[key];
}

/**
 * Sets the value of preferences of KEY
 */
export function setPreferencesKeyAndValue(
  key: string,
  value: IStoreTypes,
): void {
  store[key] = value;

  if ("localStorage" in globalThis) {
    localStorage.setItem(
      key,
      typeof value === "string" ? value : JSON.stringify(value),
    );
  }
}

interface IStoreData {
  [name: string]: IStoreTypes;
}

type IStoreTypes =
  | string
  | string[]
  | number
  | number[]
  | boolean
  | boolean[]
  | object;
