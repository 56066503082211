import {
  IFormAndNode,
  TCreateScreenInput,
  createScreen,
} from "../../../helpers/createScreen";

import { ComponentWithFormAndNode } from "../../../common/ComponentWithFormAndNode";
import { I18nComponentRegisterer } from "@tedivo/tedivo-i18";
import { IAuthCreateAccountFields } from "../../../../app/services/accounts/accounts";
import { IFields } from "@tedivo/tedivo-form";
import { RECAPTCHA_SITE_KEY_V2_WIDGET } from "../../../../app/tracking/googleRecaptcha";
import Services from "../../../../app/services";
import { TVDColorModeElement } from "../../../layout/top-tools/tvd-color-mode.element";
import { getTranslation } from "../../../../app/i18/i18tn";
import goSquared from "../../../../app/tracking/goSquared";
import { removeChildren } from "@tedivo/tedivo-dom-helpers";
import { routeFns } from "../../../../app/router/routes";
import router from "../../../../app/router";
import securityModule from "../../../../app/security/SecurityModule";
import { setAppTitle } from "../../../../app/setAppTitle";
import { z } from "zod";

export class TVDAuthCreateAccountComponent extends ComponentWithFormAndNode<IAuthCreateAccountFieldsWithDisclosure> {
  public static observedAttributes = [];

  private recatpchaToken?: string;

  constructor() {
    super();
    setAppTitle(getTranslation("general:organizations.createNewAccount"));
  }

  connectedCallback() {
    const { isLoggedIn } = securityModule;
    if (isLoggedIn) {
      router.navigate(routeFns.home());
      return;
    }

    const gRecaptchaDiv = document.createElement("div");
    gRecaptchaDiv.id = "create-account-recaptcha";

    this.appendChild(this.divWithSpinner);

    const queryString = router.getRouteQuerystring();

    goSquared.trackPage("Create Account");
    goSquared.addEvent("Create-Account - Show page");

    this.setCurrentFormAndNode(
      createNewAccountFields(
        this.onSubmitCreateOrgForm,
        queryString,
        this.i18nCR,
        gRecaptchaDiv,
        this.verifyCallbackGoogleRecaptchaV2,
      ),
    );
  }

  private onSubmitCreateOrgForm = async (values: IAuthCreateAccountFields) => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const me = this;

    const recaptchaToken = me.recatpchaToken || "";

    if (!recaptchaToken) {
      me.showDialog({
        errorCode: "errors:recaptcha.missing",
        message: "errors:recaptcha.missing",
        translationKey: "errors:recaptcha.missing",
      });
      return;
    }

    try {
      me.divWithSpinner.setLoading(true);
      goSquared.addEvent("Create-Account - Submit create");

      const resp = await Services.accounts.createNewAccount({
        ...values,
        recaptchaToken,
      });

      if (resp.statusCode === 200) {
        removeChildren(me.divWithSpinner);
        me.setCurrentFormAndNode(
          createResponseOkMessage(values.email, resp.data?.hash),
        );
      } else {
        throw new Error(resp.message);
      }
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errorMessage = typeof e === "string" ? e : (e as any).message;

      me.showDialog({
        errorCode: errorMessage,
        message: errorMessage,
        translationKey: errorMessage,
      });
    } finally {
      me.divWithSpinner.setLoading(false).then(() => {
        try {
          if (window.grecaptcha && window.grecaptcha.reset)
            window.grecaptcha.reset();
        } catch (e) {
          console.warn(e);
        }
      });
    }
  };

  private verifyCallbackGoogleRecaptchaV2 = (token: string) => {
    this.recatpchaToken = token;
  };
}

customElements.define(
  "tvd-auth-create-account-component",
  TVDAuthCreateAccountComponent,
);

declare global {
  interface HTMLElementTagNameMap {
    "tvd-auth-create-account-component": TVDAuthCreateAccountComponent;
  }
}

function createNewAccountFields(
  onSubmitDataForm: (values: IAuthCreateAccountFieldsWithDisclosure) => void,
  queryString: Record<string, string | undefined>,
  i18nCR: I18nComponentRegisterer,
  gRecaptchaDiv: HTMLDivElement,
  verifyCallback: (token: string) => void,
): IFormAndNode<IAuthCreateAccountFieldsWithDisclosure> {
  const AuthLoginFormValidator = z.object({
    name: z.string().min(1),
    familyName: z.string().min(1),
    orgName: z.string().min(1),
    email: z.string().email(),
    recaptchaToken: z.any().optional(),
  });

  const fields: IFields<IAuthCreateAccountFieldsWithDisclosure> = [
    [
      {
        type: "textBox",
        name: "name",
        label: "general:common.firstName",
      },
      {
        type: "textBox",
        name: "familyName",
        label: "general:users.familyName",
      },
    ],
    {
      type: "textBox",
      name: "email",
      label: "general:common.workEmail",
      initialValue: queryString.email
        ? decodeURI(queryString.email)
        : undefined,
    },
    {
      type: "textBox",
      name: "orgName",
      label: "general:organizations.companyName",
    },
    {
      type: "node",
      node: gRecaptchaDiv,
      name: "recaptchaToken",
    },
  ];

  const createScreenProps: TCreateScreenInput<IAuthCreateAccountFieldsWithDisclosure> =
    {
      fields,
      onSubmitDataForm,
      formValidator: AuthLoginFormValidator,
      i18nCR,
      h1Text: "general:organizations.createNewAccount",
      formId: "create-account-form",
    };

  // Recaptcha V2
  const darkMode = (
    document.getElementById("color-mode-select") as TVDColorModeElement | null
  )?.effectiveMode;

  if (window.grecaptcha && window.grecaptcha.render) {
    window.grecaptcha.render(gRecaptchaDiv, {
      sitekey: RECAPTCHA_SITE_KEY_V2_WIDGET,
      callback: verifyCallback,
      theme: darkMode || "light",
    });
  } else {
    window.onloadCallback = () => {
      window.grecaptcha?.render?.(gRecaptchaDiv, {
        sitekey: RECAPTCHA_SITE_KEY_V2_WIDGET,
        callback: verifyCallback,
        theme: darkMode || "light",
      });
    };
  }

  const screen =
    createScreen<IAuthCreateAccountFieldsWithDisclosure>(createScreenProps);

  return screen;
}

function createResponseOkMessage(email: string, hash: string | undefined) {
  const h1 = document.createElement("h1");
  const wrapper = document.createElement("div");

  const box = document.createElement("div");
  box.className = "oss-card";

  h1.innerHTML = getTranslation("general:organizations.verifyEmail");
  box.innerHTML = getTranslation("general:organizations.newAccountCreated", {
    email,
  });

  const btnResend = document.createElement("sl-button");
  btnResend.innerHTML = getTranslation("general:organizations.resendEmail");

  btnResend.addEventListener("click", async () => {
    btnResend.disabled = true;
    btnResend.loading = true;
    await Services.accounts.resendVerificationEmail(hash || "", email);
    btnResend.disabled = false;
    btnResend.loading = false;
  });

  wrapper.appendChild(h1);
  wrapper.appendChild(box);
  wrapper.appendChild(btnResend);

  return {
    node: wrapper,
    form: undefined,
  };
}

type IAuthCreateAccountFieldsWithDisclosure = IAuthCreateAccountFields & {
  recaptchaToken?: string;
};
