import { ForeAftEnum, IBayLevelData } from "open-vessel-definition";
import {
  IAllBaysWith40sArePairedOutput,
  IValidationOutput,
} from "@tedivo/tedivo-ovd-validators";

import { getTranslation } from "../../../../../app/i18/i18tn";

export function getHeaderDetailPills(
  blA: IBayLevelData | undefined,
  blB: IBayLevelData | undefined,
  validationResult: IValidationOutput<unknown>[],
): HTMLElement {
  const holder = document.createElement("div");
  holder.setAttribute("part", "details-holder");

  if (blA === undefined && blB === undefined) {
    return holder;
  }

  const foreAftTranslations: { [key in ForeAftEnum | 0]: string } = {
    [ForeAftEnum.AFT]: getTranslation(`enums:ForeAftEnum.AFT`),
    [ForeAftEnum.FWD]: getTranslation(`enums:ForeAftEnum.FWD`),
    [0]: getTranslation(`general:common.unknown`),
  };

  const arrowDirection: { [key in ForeAftEnum | 0]: string } = {
    [ForeAftEnum.AFT]: "→",
    [ForeAftEnum.FWD]: "←",
    [0]: "?",
  };

  const yesNoTranslations = (v: boolean) =>
    getTranslation(v ? "general:common.yes" : "general:common.no");

  const yesNoSymbols = (v: boolean) => (v ? "✓" : "&times;");

  const allBaysWith40sArePairedValidatorResult = validationResult.find(
    (v) => v.name === "allBaysWith40sArePairedValidator",
  ) as IValidationOutput<IAllBaysWith40sArePairedOutput> | undefined;

  drawSingleDetail(blA, blB, {
    key: "pairedBay",
    labelKey: "view:details.paired",
    iconName: "link",
    textStrFn: (a) => foreAftTranslations[a?.pairedBay || 0],
    textIconFn: (a) => ({
      content: arrowDirection[a?.pairedBay || 0],
      nodeType: "span",
      className: "bay-arrow-direction",
    }),
    warningFn: (a, b) => {
      if (allBaysWith40sArePairedValidatorResult) {
        return allBaysWith40sArePairedValidatorResult.result.invalidResults.some(
          (r) =>
            (r.bay === a?.isoBay && a.level === r.level) ||
            (r.bay === b?.isoBay && b.level === r.level),
        );
      }

      return false;
    },
  });

  drawSingleDetail(blA, blB, {
    key: "reeferPlugs",
    labelKey: "view:details.reeferPlugs",
    iconName: "plug",
    undefinedIsZero: true,
    textStrFn: (a) => foreAftTranslations[a?.reeferPlugs || 0],
    textIconFn: (a) => ({ content: arrowDirection[a?.reeferPlugs || 0] }),
  });

  drawSingleDetail(blA, blB, {
    key: "doors",
    labelKey: "view:details.doors",
    iconName: "door-closed",
    undefinedIsZero: true,
    textStrFn: (a) => foreAftTranslations[a?.doors || 0],
    textIconFn: (a) => ({ content: arrowDirection[a?.doors || 0] }),
  });

  drawSingleDetail(blA, blB, {
    key: "telescoping",
    labelKey: "view:details.telescoping",
    iconName: "arrow-left-right",
    ommitBothTrue: true,
    undefinedIsZero: true,
    textStrFn: (a) => yesNoTranslations(!!a?.telescoping),
    textIconFn: (a) => ({ content: yesNoSymbols(!!a?.telescoping) }),
  });

  drawNotesIcon(blA, blB, {
    labelKey: "view:edit.bayLevelData.notes",
    iconName: "journal-text",
  });

  return holder;

  function appendTextWithIcon(
    node: HTMLElement,
    {
      content,
      nodeType,
      className,
    }: {
      content: string;
      nodeType?: string;
      className?: string;
    },
  ) {
    if (nodeType) {
      const n = document.createElement(nodeType);
      n.className = className || "";
      n.innerHTML = content;
      n.setAttribute("part", className || "");
      node.appendChild(n);
    } else {
      node.appendChild(document.createTextNode(content));
    }
  }

  function drawSingleDetail(
    blA: IBayLevelData | undefined,
    blB: IBayLevelData | undefined,
    {
      iconName,
      labelKey,
      key,
      ommitBothTrue,
      undefinedIsZero = false,
      textStrFn,
      textIconFn,
      warningFn,
    }: {
      iconName: string;
      labelKey: string;
      key: keyof IBayLevelData;
      ommitBothTrue?: boolean;
      undefinedIsZero?: boolean;
      textStrFn: (a: IBayLevelData) => string;
      textIconFn: (a: IBayLevelData) => {
        content: string;
        nodeType?: string;
        className?: string;
      };
      warningFn?: (
        a: IBayLevelData | undefined,
        b: IBayLevelData | undefined,
      ) => boolean;
    },
  ) {
    if (blA === undefined && blB === undefined) return;
    const label = getTranslation(labelKey);

    const valA = !undefinedIsZero ? blA?.[key] : blA?.[key] || 0;
    const valB = !undefinedIsZero ? blB?.[key] : blB?.[key] || 0;

    if (valA && valB && valA === valB) {
      const commonBl = blA ?? (blB as IBayLevelData);
      const title = `${label}: ${textStrFn(commonBl)}`;

      const badge = document.createElement("sl-badge");
      const icon = document.createElement("sl-icon");
      icon.setAttribute("name", iconName);
      icon.className = "icon-big";

      badge.title = title;
      badge.appendChild(icon);
      badge.variant = "neutral";
      badge.pill = true;
      if (!ommitBothTrue) appendTextWithIcon(badge, textIconFn(commonBl));

      holder.appendChild(badge);

      if (warningFn && warningFn(blA, blB)) {
        badge.variant = "warning";
      } else {
        badge.variant = "neutral";
      }
    } else if (valA !== valB) {
      const title = [
        blA
          ? `${label} ${getTranslation(
              `enums:BayLevelEnum.ABOVE`,
            )}: ${textStrFn(blA)}`
          : undefined,
        blB
          ? `${label} ${getTranslation(
              `enums:BayLevelEnum.BELOW`,
            )}: ${textStrFn(blB)}`
          : undefined,
      ]
        .filter(Boolean)
        .join("\n");

      const inners: HTMLElement[] = [];
      if (blA) {
        const supNode = document.createElement("sup");
        appendTextWithIcon(supNode, textIconFn(blA));
        inners.push(supNode);
      }
      if (blB) {
        const subNode = document.createElement("sub");
        appendTextWithIcon(subNode, textIconFn(blB));
        inners.push(subNode);
      }

      const badge = document.createElement("sl-badge");
      const icon = document.createElement("sl-icon");
      icon.setAttribute("name", iconName);
      icon.className = "icon-big";

      const innerSpan = document.createElement("span");
      if (inners[0]) innerSpan.appendChild(inners[0]);
      if (inners[0] && inners[1])
        innerSpan.appendChild(document.createTextNode("/"));
      if (inners[1]) innerSpan.appendChild(inners[1]);

      badge.title = title;
      badge.appendChild(icon);
      badge.appendChild(innerSpan);

      if (warningFn && warningFn(blA, blB)) {
        badge.variant = "warning";
      } else {
        badge.variant = "neutral";
      }

      badge.pill = true;
      holder.appendChild(badge);
    }
  }

  function drawNotesIcon(
    blA: IBayLevelData | undefined,
    blB: IBayLevelData | undefined,
    {
      iconName,
      labelKey,
    }: {
      iconName: string;
      labelKey: string;
    },
  ) {
    if (!blA?.meta?.notes && !blB?.meta?.notes) {
      return;
    }

    const label = getTranslation(labelKey);
    const aboveNotes = (blA?.meta?.notes || "").trim();
    const belowNotes = (blB?.meta?.notes || "").trim();

    const title: string[] = [];
    if (aboveNotes) {
      title.push(`${label} ${getTranslation(`enums:BayLevelEnum.ABOVE`)}:`);
      title.push(`${aboveNotes}`);
    }

    if (belowNotes) {
      if (title.length > 0) title.push("");
      title.push(`${label} ${getTranslation(`enums:BayLevelEnum.BELOW`)}:`);
      title.push(`${belowNotes}`);
    }

    const contentHtml = document.createElement("div");
    contentHtml.innerHTML = title.join("\n").replace(/\n/g, "<br />");
    contentHtml.slot = "content";

    const slTooltip = document.createElement("sl-tooltip");
    slTooltip.appendChild(contentHtml);
    slTooltip.placement = "bottom-end";

    //${blA?.meta?.notes || ""}<br />${blB?.meta?.notes || ""}`;

    const badge = document.createElement("sl-badge");
    const icon = document.createElement("sl-icon");
    icon.setAttribute("name", iconName);
    icon.className = "icon-big";

    //badge.title = title.join("\n");
    badge.appendChild(icon);
    badge.variant = "success";
    badge.pill = true;

    slTooltip.appendChild(badge);
    holder.appendChild(slTooltip);
  }
}
