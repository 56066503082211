import SlButton from "@shoelace-style/shoelace/dist/components/button/button.component";
import SlDialog from "@shoelace-style/shoelace/dist/components/dialog/dialog.component";
import SlIcon from "@shoelace-style/shoelace/dist/components/icon/icon.component";
import SlIconButton from "@shoelace-style/shoelace/dist/components/icon-button/icon-button.component";
import { stylesFromStringToTag } from "@tedivo/tedivo-dom-helpers";

export class DeleteButtonWithConfirm extends HTMLElement {
  private confirmDelete: SlButton | SlIconButton | undefined = undefined;
  private deleteConfirmationDialog: SlDialog | undefined = undefined;

  public buttonText = "Delete";
  public confirmDeleteText = "Confirm deletion?";
  public cancelText = "Cancel";

  public onConfirmDelete: () => void = () => null;

  constructor() {
    super();
  }

  connectedCallback() {
    const inlineDeleteButton: SlButton | SlIconButton =
      this.slot === "header-actions"
        ? (document.createElement("sl-icon-button") as SlIconButton)
        : (document.createElement("sl-button") as SlButton);

    // First button
    if (this.slot === "header-actions") {
      inlineDeleteButton.name = "trash";
      inlineDeleteButton.slot = "header-actions";
      inlineDeleteButton.title = this.buttonText;
      inlineDeleteButton.classList.add("danger");
    } else {
      inlineDeleteButton.slot = "footer";
      inlineDeleteButton.innerHTML = this.buttonText;
      inlineDeleteButton.tabIndex = 0;
      (inlineDeleteButton as SlButton).variant = "danger";
      (inlineDeleteButton as SlButton).type = "button";

      const icon = document.createElement("sl-icon") as SlIcon;
      icon.name = "trash";
      icon.slot = "prefix";
      inlineDeleteButton.appendChild(icon);
    }

    this.appendChild(inlineDeleteButton);

    // Confirm dialog and confirm button
    const deleteConfirmationDialog = this.createConfirmDeleteDialog();

    /** Show the confirmatipn dialog */
    inlineDeleteButton.onclick = function () {
      deleteConfirmationDialog.show();
    };

    this.appendChild(stylesFromStringToTag(styles));
  }

  createConfirmDeleteDialog() {
    const confirmationDialog = document.createElement("sl-dialog");
    confirmationDialog.classList.add("higher-modal-z-index");

    /** Set the dialog label */
    confirmationDialog.label = this.buttonText;

    /** Set the dialog content */
    confirmationDialog.appendChild(
      document.createTextNode(this.confirmDeleteText),
    );

    /** Create the final delete button */
    const confirmDeleteButton = document.createElement("sl-button");
    confirmDeleteButton.slot = "footer";
    confirmDeleteButton.innerText = this.buttonText;
    confirmDeleteButton.variant = "danger";

    const icon = document.createElement("sl-icon") as SlIcon;
    icon.name = "trash";
    icon.slot = "prefix";
    confirmDeleteButton.appendChild(icon);

    confirmationDialog.appendChild(confirmDeleteButton);

    const cancelDeleteButton = document.createElement("sl-button");
    cancelDeleteButton.slot = "footer";
    cancelDeleteButton.innerText = this.cancelText;
    confirmationDialog.appendChild(cancelDeleteButton);

    /** Execute deletion callback */
    confirmDeleteButton.onclick = () => {
      this.onConfirmDelete();
      confirmationDialog.hide();
    };

    /** Never mind */
    cancelDeleteButton.onclick = () => {
      confirmationDialog.hide();
    };

    document.body.appendChild(confirmationDialog);
    this.deleteConfirmationDialog = confirmationDialog;

    return confirmationDialog;
  }

  disconnectedCallback() {
    this.confirmDelete?.remove();
    this.deleteConfirmationDialog?.remove();
  }
}

customElements.define("delete-button", DeleteButtonWithConfirm);

declare global {
  interface HTMLElementTagNameMap {
    "delete-button": DeleteButtonWithConfirm;
  }
}

const styles = `
  .danger {
    color: var(--sl-color-danger-500);
  }
`;
