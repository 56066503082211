import {
  SlotDataReducer,
  TReducerISlotDataActions,
} from "@tedivo/tedivo-bay-grid-pure";

import { ISlotData } from "open-vessel-definition";
import { ISlotSizeOptions } from "open-vessel-definition/build/src/models/v1/parts/ISlotData";

export function slotHasAttribute(
  slot: ISlotData,
  action: TReducerISlotDataActions,
): boolean {
  const newSlot = SlotDataReducer[action](slot);
  const retFn = SlotFieldComparer[action];
  return retFn ? retFn(slot, newSlot) : false;
}

export const SlotFieldComparer: Partial<{
  [key in TReducerISlotDataActions]: (a: ISlotData, b: ISlotData) => boolean;
}> = {
  ADD_SIZE_20: (a, b) => a.sizes && b.sizes && !!a.sizes[20] === !!b.sizes[20],
  ADD_SIZE_24: (a, b) => a.sizes && b.sizes && !!a.sizes[24] === !!b.sizes[24],
  ADD_SIZE_40: (a, b) => a.sizes && b.sizes && !!a.sizes[40] === !!b.sizes[40],
  ADD_SIZE_45: (a, b) => a.sizes && b.sizes && !!a.sizes[45] === !!b.sizes[45],
  ADD_SIZE_48: (a, b) => a.sizes && b.sizes && !!a.sizes[48] === !!b.sizes[48],
  ADD_SIZE_53: (a, b) => a.sizes && b.sizes && !!a.sizes[53] === !!b.sizes[53],
  ADD_COOLSTOWPROHIBITED: (a, b) =>
    a.coolStowProhibited === b.coolStowProhibited,
  ADD_REEFER: (a, b) => a.reefer === b.reefer,
  ADD_RESTRICTED: (a, b) => a.restricted === b.restricted,
  ADD_HAZARDOUSPROHIBITED: (a, b) =>
    a.hazardousProhibited === b.hazardousProhibited,
  ADD_SIZE_20_CONEREQ: (a, b) =>
    (a.sizes?.[20] as ISlotSizeOptions)?.cone !== undefined &&
    (a.sizes?.[20] as ISlotSizeOptions)?.cone ==
      (b.sizes?.[20] as ISlotSizeOptions)?.cone,
  ADD_SIZE_24_CONEREQ: (a, b) =>
    (a.sizes?.[24] as ISlotSizeOptions)?.cone !== undefined &&
    (a.sizes?.[24] as ISlotSizeOptions)?.cone ==
      (b.sizes?.[24] as ISlotSizeOptions)?.cone,
  ADD_SIZE_40_CONEREQ: (a, b) =>
    (a.sizes?.[40] as ISlotSizeOptions)?.cone !== undefined &&
    (a.sizes?.[40] as ISlotSizeOptions)?.cone ==
      (b.sizes?.[40] as ISlotSizeOptions)?.cone,
  ADD_SIZE_45_CONEREQ: (a, b) =>
    (a.sizes?.[45] as ISlotSizeOptions)?.cone !== undefined &&
    (a.sizes?.[45] as ISlotSizeOptions)?.cone ==
      (b.sizes?.[45] as ISlotSizeOptions)?.cone,
  ADD_SIZE_48_CONEREQ: (a, b) =>
    (a.sizes?.[48] as ISlotSizeOptions)?.cone !== undefined &&
    (a.sizes?.[48] as ISlotSizeOptions)?.cone ==
      (b.sizes?.[48] as ISlotSizeOptions)?.cone,
  ADD_SIZE_53_CONEREQ: (a, b) =>
    (a.sizes?.[53] as ISlotSizeOptions)?.cone !== undefined &&
    (a.sizes?.[53] as ISlotSizeOptions)?.cone ==
      (b.sizes?.[53] as ISlotSizeOptions)?.cone,
};
