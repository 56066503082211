import router from "../../../app/router";
class BreadcrumbsElement extends HTMLElement {
    constructor() {
        super(...arguments);
        this.items = [];
        this.bcs = [];
    }
    connectedCallback() {
        const bcHolder = document.createElement("sl-breadcrumb");
        this.items.forEach(({ href, label }) => {
            const bcItem = document.createElement("sl-breadcrumb-item");
            bcItem.innerHTML = label;
            bcItem.href = href;
            bcItem.onclick = function (ev) {
                console.log("click", ev.target);
                ev.preventDefault();
                if (href)
                    router.navigate(href);
                return false;
            };
            bcHolder.appendChild(bcItem);
            this.bcs.push(bcItem);
        });
        this.appendChild(bcHolder);
    }
    setLastItemText(label) {
        this.bcs[this.bcs.length - 1].innerHTML = label;
    }
}
BreadcrumbsElement.observedAttributes = [];
export default BreadcrumbsElement;
customElements.define("breadcrumbs-element", BreadcrumbsElement);
