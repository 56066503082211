import {
  BayLevelEnum,
  IBayLevelData,
  IVesselParts,
  VesselPartTypeEnum,
} from "open-vessel-definition";
import {
  ONE_MILLIMETER_IN_FEET,
  createDictionary,
  roundDec,
} from "@tedivo/tedivo-pure-helpers";

import { I20LcgsByBay } from "./types/I20Lcgs";
import { IBayPattern } from "./types/IPositionPatterns";
import { IVesselOneBaySlot } from "./types/IVesselOneBaySlot";
import { getAllThePairedBays } from "./getAllThePairedBays";
import { getSizesFromSlots } from "./getSizesFromSlots";

export function calculateSlotsForVesselOne(
  bls: IBayLevelData[],
  calculatedSlots: Array<string | IVesselParts>,
  lcgsBy20Bay: I20LcgsByBay,
): Array<IVesselOneBaySlot> {
  const baySlots: IVesselOneBaySlot[] = [];
  const { pairedBays } = getAllThePairedBays(bls, false);

  const blsDict = createDictionary(bls, (b) => `${b.isoBay}-${b.level}`);
  const lenToVOne = (len: number) => len * ONE_MILLIMETER_IN_FEET;

  const firstBayIndexInCalculatedSlots = calculatedSlots.findIndex(
    (b) => typeof b === "string",
  );

  const lenOfInitialParts =
    firstBayIndexInCalculatedSlots === 0
      ? 0
      : calculatedSlots
          .slice(0, firstBayIndexInCalculatedSlots)
          .reduce((acc, part) => {
            if (typeof part !== "object") return acc;
            return acc + part.len;
          }, 0);

  const maxLcgFore = roundDec(
    Math.max(...Object.values(lcgsBy20Bay).map((v) => v.foreLcg)) +
      lenOfInitialParts,
    4,
  );

  const posX = (f: number): number =>
    ((maxLcgFore - f) / 100) * ONE_MILLIMETER_IN_FEET;

  let lastPositionXAft = 0;

  const processed: string[] = [];
  calculatedSlots.forEach((b) => {
    // If it's a string, it's a bay
    if (typeof b === "string" && !isNaN(Number(b))) {
      const bay = b as IBayPattern;
      if (processed.includes(bay)) return;

      const paired =
        pairedBays.find((pair) => pair.base === bay)?.paired ||
        pairedBays.find((pair) => pair.paired === bay)?.base;

      const maxSize = Math.max(
        ...[
          ...(getSizesFromSlots(
            blsDict[`${bay}-${BayLevelEnum.ABOVE}`]?.perSlotInfo,
          )?.sizes || []),
          ...(getSizesFromSlots(
            blsDict[`${bay}-${BayLevelEnum.BELOW}`]?.perSlotInfo,
          )?.sizes || []),
          ...(paired
            ? getSizesFromSlots(
                blsDict[`${paired}-${BayLevelEnum.ABOVE}`]?.perSlotInfo,
              )?.sizes || []
            : []),
          ...(paired
            ? getSizesFromSlots(
                blsDict[`${paired}-${BayLevelEnum.BELOW}`]?.perSlotInfo,
              )?.sizes || []
            : []),
        ],
      );

      const id = paired ? `${bay}-${paired}` : bay;
      const calcLen = maxSize === -Infinity ? (paired ? 40 : 20) : undefined;

      const lcgs = [lcgsBy20Bay[bay].foreLcg, lcgsBy20Bay[bay].aftLcg];

      if (paired) {
        lcgs.push(lcgsBy20Bay[paired].foreLcg, lcgsBy20Bay[paired].aftLcg);
      }

      const lashingBridges =
        blsDict[`${bay}-${BayLevelEnum.ABOVE}`]?.lashingBridges ||
        (paired &&
          blsDict[`${paired}-${BayLevelEnum.ABOVE}`]?.lashingBridges) ||
        undefined;

      const bulkheads =
        blsDict[`${bay}-${BayLevelEnum.BELOW}`]?.bulkhead ||
        (paired && blsDict[`${paired}-${BayLevelEnum.BELOW}`]?.bulkhead) ||
        undefined;

      const positionX = posX(Math.max(...lcgs));

      baySlots.push({
        id,
        type: VesselPartTypeEnum.BAY,
        label: id,
        lashingBridges,
        bulkheads,
        len: maxSize,
        calcLen,
        maxSize,
        positionX,
      });

      lastPositionXAft = positionX + maxSize / 100;

      processed.push(bay);
      if (paired) processed.push(paired);

      return;
    }

    if (typeof b !== "object") return;

    let len = 0;

    switch (b.type) {
      case VesselPartTypeEnum.CRANE:
        len = lenToVOne(b.len || 10000);
        baySlots.push({
          id: b.id,
          type: VesselPartTypeEnum.CRANE,
          label: b.label,
          cranes: b.cranes,
          len,
          positionX: lastPositionXAft,
          original: b,
        });
        break;

      case VesselPartTypeEnum.BRIDGE:
        len = lenToVOne(b.len || 40000);
        baySlots.push({
          id: b.id,
          type: VesselPartTypeEnum.BRIDGE,
          label: b.label,
          heatSrcBelow: b.heatSrcBelow,
          len,
          positionX: lastPositionXAft,

          original: b,
        });
        break;

      case VesselPartTypeEnum.SMOKE:
        len = lenToVOne(b.len || 20000);
        baySlots.push({
          id: b.id,
          type: VesselPartTypeEnum.SMOKE,
          label: b.label,
          numberOfSmokeStacks: b.numberOfSmokeStacks,
          len,
          positionX: lastPositionXAft,

          original: b,
        });
        break;

      case VesselPartTypeEnum.SPACER:
        len = lenToVOne(b.len || 0);

        baySlots.push({
          id: b.id,
          type: VesselPartTypeEnum.SPACER,
          label: b.label,
          len,
          positionX: lastPositionXAft,

          original: b,
        });
        break;
    }

    lastPositionXAft += len;
  });

  return baySlots;
}
