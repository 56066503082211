import { __rest } from "tslib";
import createGridPart from "./createGridPart";
import { roundDec } from "@tedivo/tedivo-pure-helpers";
/**
 * Generates a SVG Grid (Above & Below) with dimensions
 * @returns ISvgWithSize {svg, width, height}
 */
export default function createBayGrid(_a) {
    var { aboveTiersArray, belowTiersArray, belowGridSeparation = 0, lidData } = _a, commonGridOptions = __rest(_a, ["aboveTiersArray", "belowTiersArray", "belowGridSeparation", "lidData"]);
    const svgGroup = document.createElementNS("http://www.w3.org/2000/svg", "g");
    let height = 0, aboveHeight = 0, width = 0;
    const symbols = {};
    const cellPositions = {};
    if (aboveTiersArray.length) {
        const aboveSvg = createGridPart(Object.assign(Object.assign({}, commonGridOptions), { tiersArray: aboveTiersArray.slice().sort(sortNumericDesc), displayNumbers: { left: true, right: true, top: true } }));
        height = aboveSvg.height;
        aboveHeight = aboveSvg.height;
        width = aboveSvg.width;
        svgGroup.appendChild(aboveSvg.svgGroup);
        Object.assign(cellPositions, aboveSvg.cellPositions);
        Object.assign(symbols, aboveSvg.symbols);
    }
    if (belowTiersArray.length) {
        const belowSvg = createGridPart(Object.assign(Object.assign({}, commonGridOptions), { tiersArray: belowTiersArray.slice().sort(sortNumericDesc), displayNumbers: { left: true, right: true, bottom: true } }));
        belowSvg.svgGroup.setAttribute("transform", `translate(0, ${height > 0 ? height + belowGridSeparation : 0})`);
        svgGroup.appendChild(belowSvg.svgGroup);
        if (height > 0) {
            Object.assign(cellPositions, Object.keys(belowSvg.cellPositions).reduce((acc, pos) => {
                acc[pos] = {
                    x: belowSvg.cellPositions[pos].x,
                    y: belowSvg.cellPositions[pos].y + height + belowGridSeparation,
                };
                return acc;
            }, {}));
        }
        else {
            Object.assign(cellPositions, belowSvg.cellPositions);
        }
        Object.assign(symbols, belowSvg.symbols);
        height += belowGridSeparation + belowSvg.height;
    }
    const lidsPath = drawLids({
        lidData,
        yPos: aboveHeight ? aboveHeight + (belowGridSeparation || 0) * 0.5 : 0,
        containerWidth: commonGridOptions.containerWidth,
        lidHeight: commonGridOptions.lidHeight,
        cellPositions,
        lidBorderColor: commonGridOptions.nodesOptions.lidBorderColor,
        lidBackgroundColor: commonGridOptions.nodesOptions.lidBackgroundColor,
        cellsAreSeparated: commonGridOptions.cellSeparation !== undefined &&
            commonGridOptions.cellSeparation > 0,
    });
    if (lidsPath)
        svgGroup.appendChild(lidsPath);
    return {
        svgGroup,
        width,
        height,
        symbols,
        cellPositions,
    };
}
function sortNumericDesc(a, b) {
    return Number(b) - Number(a);
}
function drawLids({ lidData, yPos, containerWidth, lidHeight, cellPositions, lidBorderColor, lidBackgroundColor, cellsAreSeparated, }) {
    if (lidData === undefined || lidData === null || lidData.length === 0)
        return null;
    const LIDHEIGHT = lidHeight;
    const OVERLAP_WIDTH = roundDec(containerWidth * 0.15, 3);
    const OVERLAP_HEIGHT = roundDec(LIDHEIGHT * (cellsAreSeparated ? 0.42 : 0.5), 3);
    const rowPositions = {};
    Object.keys(cellPositions).forEach((pos) => {
        const [row] = pos.split("|");
        rowPositions[row] = cellPositions[pos].x;
    });
    const svgLidsPath = document.createElementNS("http://www.w3.org/2000/svg", "g");
    const lidDataSorted = lidData
        .slice()
        .sort((a, b) => rowPositions[a.portIsoRow] < rowPositions[b.portIsoRow] ? -1 : 1);
    const maxLidIndex = lidDataSorted.length - 1;
    // Important. Because of sorting, we draw from Left to Right. PrevLid will be at the left always.
    lidDataSorted.forEach((lid, idx) => {
        svgLidsPath.appendChild(addLidSvgPathInfo({
            lid,
            yOffset: yPos - LIDHEIGHT * 0.5,
            prevLid: idx > 0 ? lidDataSorted[idx - 1] : undefined,
            nextLid: idx < maxLidIndex ? lidDataSorted[idx + 1] : undefined,
        }));
    });
    return svgLidsPath;
    function addLidSvgPathInfo({ lid, yOffset, prevLid, nextLid, }) {
        const svgLidPath = document.createElementNS("http://www.w3.org/2000/svg", "path");
        const left = rowPositions[lid.portIsoRow];
        const right = rowPositions[lid.starboardIsoRow];
        const leftTop = lid.overlapPort
            ? `M${left},${OVERLAP_HEIGHT + yOffset} h-${OVERLAP_WIDTH} v-${OVERLAP_HEIGHT}`
            : (prevLid === null || prevLid === void 0 ? void 0 : prevLid.overlapStarboard)
                ? `M${left + OVERLAP_WIDTH},${yOffset}`
                : `M${left},${yOffset}`;
        const rightTop = lid.overlapStarboard
            ? `L${right + containerWidth + OVERLAP_WIDTH},${yOffset} v${OVERLAP_HEIGHT} h-${OVERLAP_WIDTH} `
            : (nextLid === null || nextLid === void 0 ? void 0 : nextLid.overlapPort)
                ? `L${right + containerWidth - OVERLAP_WIDTH},${yOffset} V${yOffset + LIDHEIGHT - OVERLAP_HEIGHT} h${OVERLAP_WIDTH}`
                : `L${right + containerWidth},${yOffset}`;
        const rightBottom = `L${right + containerWidth},${yOffset + LIDHEIGHT}`;
        const leftBottom = (prevLid === null || prevLid === void 0 ? void 0 : prevLid.overlapStarboard)
            ? `L${left},${yOffset + LIDHEIGHT} v-${OVERLAP_HEIGHT} h${OVERLAP_WIDTH}`
            : `L${left},${yOffset + LIDHEIGHT}`;
        const d = [leftTop, rightTop, rightBottom, leftBottom, "z"].join("\n");
        svgLidPath.setAttribute("d", d);
        svgLidPath.setAttribute("fill", lidBackgroundColor || "black");
        svgLidPath.setAttribute("fill-opacity", "0.5");
        svgLidPath.setAttribute("stroke", lidBorderColor || "black");
        return svgLidPath;
    }
}
