import { feetToMillimeters, roundDec } from "@tedivo/tedivo-pure-helpers";

import { CONT_LENS } from "@tedivo/tedivo-bay-grid-pure";
import { ForeAftEnum } from "open-vessel-definition";

const PAIRED_OPTIONS = [0, ForeAftEnum.FWD, ForeAftEnum.AFT];

export function generateSideContainerSymbols(
  {
    strokeWidth = 0.15,
    pairedNoneContainerColor = "gray",
    pairedAftContainerColor = "blue",
    pairedFwdContainerColor = "green",
  }: IGenerateSideContainerSymbolsProps,
  svgSizeRatio: number,
) {
  const strokeDashLength = 1.5;
  const heightInMilimeters = roundDec(feetToMillimeters(8.5) * svgSizeRatio, 1);

  const symbols = {} as { [name: string]: SVGElement };

  const colors = [
    pairedNoneContainerColor,
    pairedFwdContainerColor,
    pairedAftContainerColor,
  ];

  CONT_LENS.forEach((len) => {
    const lenghtInMilimeters = roundDec(
      feetToMillimeters(len) * svgSizeRatio,
      1,
    );

    PAIRED_OPTIONS.forEach((paired) => {
      const symKey = `${len}-${paired}`;

      const svgBoxSymbol = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "symbol",
      );

      const rect = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "path",
      );

      rect.setAttribute("stroke", colors[paired]);
      rect.setAttribute("stroke-width", String(strokeWidth));
      rect.setAttribute("fill", colors[paired]);
      rect.setAttribute("fill-opacity", "0.25");
      rect.setAttribute("stroke-opacity", "0.66");

      if (!isNaN(svgSizeRatio)) {
        // Outer box. This is centered on LCG/VCG (centroid)
        const pathParts: string[] = [
          `M${-lenghtInMilimeters * 0.5},${
            -heightInMilimeters * 0.5
          } h${lenghtInMilimeters} v${heightInMilimeters} h-${lenghtInMilimeters} z`,
        ];

        if (len >= 40) {
          let lineFrom = roundDec(-heightInMilimeters * 0.5);
          let lineTo = roundDec(lineFrom + strokeDashLength);
          while (lineTo < heightInMilimeters * 0.5) {
            pathParts.push(`M0,${lineFrom} L0,${lineTo}`);
            lineFrom = roundDec(lineTo + strokeDashLength);
            lineTo = roundDec(lineFrom + strokeDashLength);
          }
        }

        rect.setAttribute("d", pathParts.join(" "));
      }

      svgBoxSymbol.id = `smbCn${symKey}`;
      svgBoxSymbol.setAttribute("style", "overflow:visible");
      svgBoxSymbol.appendChild(rect);
      symbols[symKey] = svgBoxSymbol;
    });
  });

  return symbols;
}

export interface IGenerateSideContainerSymbolsProps {
  fillColor?: string;
  strokeColor?: string;
  shipStrokeColor?: string;
  lidFillColor?: string;
  strokeWidth?: number;
  fontColor?: string;
  pairedNoneContainerColor?: string;
  pairedAftContainerColor?: string;
  pairedFwdContainerColor?: string;
  warningColor?: string;
}
