import { routeFns } from "../router/routes";
import router from "../router";
import securityModule from "../security/SecurityModule";

export function bindRouter() {
  // Start the router to display a component
  router.addMiddleware((url: string) => {
    if (
      securityModule.isLoggedIn &&
      securityModule.organizationMfaForAllUsers &&
      !securityModule.isMfaEnabled
    ) {
      return routeFns.login(undefined, true);
    } else {
      return url;
    }
  });

  setTimeout(() => {
    router.start();
  }, 0);
}
