import SlButton from "@shoelace-style/shoelace/dist/components/button/button";

export default class LinkButtonElement extends HTMLElement {
  public static observedAttributes = ["link"];

  private btn: SlButton;
  private url = "";
  private stateToPass: Record<string, unknown> | undefined = undefined;

  onClick:
    | ((
        path: string,
        state?: unknown,
        replaceCurrentHref?: boolean,
      ) => Promise<void>)
    | undefined = undefined;

  constructor() {
    super();

    const btn = document.createElement("sl-button") as SlButton;
    btn.type = "button";
    btn.addEventListener(
      "click",
      () => this.onClick?.(this.url, this.stateToPass),
      false,
    );
    btn.size = "small";
    btn.variant = "primary";
    btn.pill = true;

    this.btn = btn;
  }

  connectedCallback() {
    this.appendChild(this.btn);
  }

  override get innerHTML() {
    return this.btn.innerHTML;
  }
  override set innerHTML(value: string) {
    this.btn.innerHTML = value;
  }

  get link() {
    return this.url;
  }
  set link(value: string) {
    this.url = value;
  }

  set state(value: Record<string, unknown>) {
    this.stateToPass = value;
  }

  get buttonElement() {
    return this.btn;
  }
}

customElements.define("link-button-element", LinkButtonElement);

declare global {
  interface HTMLElementTagNameMap {
    "link-button-element": LinkButtonElement;
  }
}
