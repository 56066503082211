import {
  IBayPattern,
  IBaySizesAndCgs,
  IRowPattern,
  createRowsFromConfig,
} from "@tedivo/tedivo-bay-grid-pure";
import {
  IValidationInput,
  IValidationOutput,
  IValidator,
  TValidatorSpecialScopes,
} from "../types";

import { ILidData } from "open-vessel-definition";

const name = "lidDataForBaysValidator";
const okTranslationKey = "validators:lids.lidDataForBaysValidator.ok";
const errorTranslationKey = "validators:lids.lidDataForBaysValidator.error";
const specialScopes: TValidatorSpecialScopes[] = [];

/** Check if lids extend correctly over below bays */
export const lidDataForBaysValidator: IValidator<ILidDataValidatorForBaysOutput> =
  {
    name,
    okTranslationKey,
    errorTranslationKey,
    fn: lidDataForBaysValidatorFn,
    specialScopes,
  };

export function lidDataForBaysValidatorFn({
  data: { json, lcgVcgTcgAndPairings },
}: IValidationInput): IValidationOutput<ILidDataValidatorForBaysOutput> {
  const { sizeSummary, lidData } = json;
  const blps = lcgVcgTcgAndPairings?.bayLevelPositionsBelow || [];
  const blockBaysAndSizesBy20Bay =
    lcgVcgTcgAndPairings?.blockBaysAndSizesBy20Bay || {};

  if (sizeSummary.maxRow === undefined)
    return {
      name,
      isValid: true,
      messageTranslationKey: okTranslationKey,
      result: {
        validResults: [],
        invalidResults: [],
        allLids: [],
        rowTcgsByBay: {},
      },
    };

  const rowsArraySorted = createRowsFromConfig(
    !!sizeSummary.centerLineRow,
    sizeSummary.maxRow,
  );

  const rowTcgsByBay = copyTcgOfRowsByBay(blps);

  const lidsDataWithValidation = lidData.map((lid) => {
    const portIndex = rowsArraySorted.indexOf(lid.portIsoRow);
    const stbdIndex = rowsArraySorted.indexOf(lid.starboardIsoRow);

    let notValid: "warning" | "danger" | undefined = undefined;
    let containersWithTcg = 0;

    const bays = [
      ...blockBaysAndSizesBy20Bay[lid.startIsoBay].allBays.split("-"),
      ...blockBaysAndSizesBy20Bay[lid.endIsoBay].allBays.split("-"),
    ].filter((b, idx, arr) => arr.indexOf(b) === idx) as IBayPattern[];

    for (let i = portIndex; i <= stbdIndex; i++) {
      const row = rowsArraySorted[i];
      const tcg = bays.reduce((acc, bay) => {
        return acc || rowTcgsByBay?.[bay]?.[row];
      }, undefined as number | undefined);

      if (tcg !== undefined) {
        containersWithTcg++;
      }
    }

    if (containersWithTcg === 0) {
      notValid = "danger";
    } else if (containersWithTcg < Math.abs(stbdIndex - portIndex) + 1) {
      notValid = "warning";
    }

    const res: ILidDataWithValidation = { ...lid, notValid };

    return res;
  });

  const invalidResults = lidsDataWithValidation.filter((lid) => lid.notValid);
  const validResults = lidsDataWithValidation.filter((lid) => !lid.notValid);
  const isValid = invalidResults.length === 0;

  return {
    name,
    isValid,
    messageTranslationKey: isValid ? okTranslationKey : errorTranslationKey,
    result: {
      validResults,
      invalidResults,
      allLids: lidsDataWithValidation,
      rowTcgsByBay,
    },
  };
}

export interface ILidDataWithValidation extends ILidData {
  notValid: "warning" | "danger" | undefined;
}

interface IRowsTcgs {
  [row: IRowPattern]: number;
}

interface IBayRowsTcgs {
  [bay: IBayPattern]: IRowsTcgs;
}

//  Helpers
function copyTcgOfRowsByBay(blps: IBaySizesAndCgs[]) {
  const rowTcgsByBay: IBayRowsTcgs = {};

  blps.forEach(copyTcgOfRows);

  function copyTcgOfRows(blp: IBaySizesAndCgs) {
    const rows = blp.rows;
    const rowKeys = Object.keys(rows) as IRowPattern[];
    if (!rowTcgsByBay[blp.isoBay]) rowTcgsByBay[blp.isoBay] = {};
    rowKeys.forEach((rowKey) => {
      rowTcgsByBay[blp.isoBay][rowKey] = rows[rowKey].tcg || 0;
    });
  }

  return rowTcgsByBay;
}

interface ILidDataValidatorForBaysOutput {
  validResults: ILidDataWithValidation[];
  invalidResults: ILidDataWithValidation[];
  allLids: ILidDataWithValidation[];
  rowTcgsByBay: IBayRowsTcgs;
}
