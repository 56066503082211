import {
  I20LcgsByBay,
  IGetBayLcgVcgAndPairingsResult,
} from "@tedivo/tedivo-bay-grid-pure";
import {
  IValidationOutput,
  IValidator,
  TValidatorSpecialScopes,
} from "./types";
import {
  createDictionary,
  createDictionaryMultiple,
} from "@tedivo/tedivo-pure-helpers";

import { IOpenVesselDefinitionV1 } from "open-vessel-definition";
import { allBaysWith40sArePairedValidator } from "./baysData/allBaysWith40sArePairedValidator";
import { cgsValidator } from "./CGs/cgsValidator";
import { lidDataForBaysValidator } from "./lids/lidDataForBaysValidator";

export class OvdValidator {
  static validators: IValidator<unknown>[] = [
    allBaysWith40sArePairedValidator,
    lidDataForBaysValidator,
    cgsValidator,
  ];

  private lastResult: IValidationOutput<unknown>[] = [];
  private lastResultByName:
    | { [key: string]: IValidationOutput<unknown> }
    | undefined = undefined;

  constructor() {
    const valsById = createDictionaryMultiple(
      OvdValidator.validators,
      (a) => a.name,
    );

    /** Check there aren't duplicate validators by name */
    Object.keys(valsById).forEach((key) => {
      const vals = valsById[key];
      if (vals.length > 1) {
        throw new Error(`Duplicate validator name: ${key}`);
      }
    });
  }

  /** Execute all the validators and cache the result */
  validate(
    json: IOpenVesselDefinitionV1,
    lcgVcgTcgAndPairings: IGetBayLcgVcgAndPairingsResult | undefined,
    lcgsBy20Bay: I20LcgsByBay | undefined,
  ): IValidationOutput<unknown>[] {
    const result = OvdValidator.validators.map((validator) =>
      validator.fn({ data: { json, lcgVcgTcgAndPairings, lcgsBy20Bay } }),
    );

    this.lastResult = result;
    this.lastResultByName = undefined;

    // console.log(
    //   `Validation result: \n`,
    //   JSON.stringify(
    //     result.map((a) => ({
    //       name: a.name,
    //       isValid: a.isValid,
    //       message: a.messageTranslationKey,
    //     })),
    //     null,
    //     2,
    //   ),
    // );

    return result;
  }

  /** Get the last result of the validators by scope */
  public getLastResult = (
    scopes?: TValidatorSpecialScopes[],
  ): IValidationOutput<unknown>[] => {
    return this.lastResult.filter(
      (a) =>
        !scopes ||
        (a.specialScopes && a.specialScopes.some((sc) => scopes.includes(sc))),
    );
  };

  /** Gets a single result by name */
  public getLastResultByName = <T>(name: string) => {
    if (!this.lastResultByName) {
      this.lastResultByName = createDictionary(this.lastResult, (a) => a.name);
    }

    return this.lastResultByName[name] as IValidationOutput<T> | undefined;
  };
}
