export function buildObserverWithCallback(
  callback: (newIndex: number) => void,
): IntersectionObserver {
  const ioOptions = {
    rootMargin: "100px 0px",
    threshold: [0, 1],
  };

  const handleIntersect = (
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver,
  ) => {
    for (let e = 0; e < entries.length; e += 1) {
      if (entries[e].isIntersecting) {
        const target = entries[e].target as HTMLElement,
          index = Number(target.dataset["index"]);

        //Disconnect
        observer.unobserve(target);

        //Do the stuff
        callback(index + 1);
      }
    }
  };

  return new IntersectionObserver(handleIntersect, ioOptions);
}
