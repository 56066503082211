import { __awaiter } from "tslib";
import { BayLevelEnum, } from "open-vessel-definition";
import { createSimpleSideView, } from "@tedivo/tedivo-bay-grid-core";
import { getAllThePairedBays, getBayLcgVcgTcgAndPairings, } from "@tedivo/tedivo-bay-grid-pure";
import { createVesselOneFormFields, vesselOneFormValidator, } from "./create3DViewForm";
import { TedivoForm, createInputWithUnits, translateTedivoForm, } from "@tedivo/tedivo-form";
import { createDictionary, pad2, roundDec } from "@tedivo/tedivo-pure-helpers";
import FieldsValuesStore from "../../../../../app/stores/FieldsValuesStore";
import { getTranslation } from "../../../../../app/i18/i18tn";
import globalUnits from "../../../../../app/units/globalUnits";
import goSquared from "../../../../../app/tracking/goSquared";
import ovdJsonStore from "../../../../../app/stores/OVDJsonStore";
import { removeChildren } from "@tedivo/tedivo-dom-helpers";
export function create3DViewEdit({ sizeSummary, shipData, v3DParams, baysData, vesselPartsData, lcgVcgTcgAndPairings, submitButton, }) {
    var _a, _b, _c;
    goSquared.addEvent("Edit-OVD - Edit Vessel Parts");
    let sideViewNode = null;
    const DEFAULT_PARAMS = {
        labels: {
            labelVesselName: shipData.shipName || "",
            labelCountry: "",
            labelIMOCode: shipData.imoCode || "",
            labelLateral: shipData.lineOperator,
        },
        colors: {
            mainColor: "#7384a2",
            bridgeColor: "#cccccc",
            bottomColor: "#b5736e",
            cranesColor: "#464b59",
            smokeBottomColor: "#cccccc",
            smokeTopColor: "#333333",
            separatorColor: "#700000",
            belowSeparatorColor: "#b8b4a9",
            labelsNoseColor: "#dddddd",
            labelsTailColor: "#dddddd",
            labelsSideColor: "#eeeeee",
            labelsBaysColor: "#cccccc",
        },
        tailOptions: {
            propellers: 1,
            rudders: 1,
        },
    };
    const dataStore = new FieldsValuesStore();
    dataStore.onChange = (values) => {
        const newAdjustedBottomBases = calculateAdjustedBottomBases(values || new Map());
        const tempSvgNode = createSideViewForEditing({
            sizeSummary,
            baysData,
            lcgVcgTcgAndPairings,
            dataStore,
            adjustedBottomBases: newAdjustedBottomBases,
        });
        if (sideViewNode)
            sideViewNode.replaceWith(tempSvgNode);
        sideViewNode = tempSvgNode;
    };
    const holder = document.createElement("div");
    holder.className = "sections-oss-card";
    // 1. Editor of the parts
    const editorComp = document.createElement("tvd-vesselparts-editor-component");
    editorComp.style.position = "relative";
    editorComp.onApplyChanges = updateAllSideView;
    editorComp.setData({
        vesselPartsData: vesselPartsData || [],
        bls: baysData,
        lcgVcgTcgAndPairings,
        adjustedBottomBases: v3DParams === null || v3DParams === void 0 ? void 0 : v3DParams.adjustedBottomBases,
    });
    const editorSection = document.createElement("section");
    editorSection.appendChild(editorComp);
    const sideViewSection = document.createElement("section");
    sideViewSection.className = "side-view-section";
    // 2. Form
    const formFields = createVesselOneFormFields({
        labels: Object.assign(Object.assign({}, DEFAULT_PARAMS.labels), (_a = v3DParams === null || v3DParams === void 0 ? void 0 : v3DParams.params) === null || _a === void 0 ? void 0 : _a.labels),
        colors: Object.assign(Object.assign({}, DEFAULT_PARAMS.colors), (_b = v3DParams === null || v3DParams === void 0 ? void 0 : v3DParams.params) === null || _b === void 0 ? void 0 : _b.colors),
        tailOptions: Object.assign(Object.assign({}, DEFAULT_PARAMS.tailOptions), (_c = v3DParams === null || v3DParams === void 0 ? void 0 : v3DParams.params) === null || _c === void 0 ? void 0 : _c.tailOptions),
    });
    const tedivoForm = new TedivoForm({
        fields: formFields,
        onSubmit: () => undefined,
        formValidator: vesselOneFormValidator,
        submitButton: submitButton,
    });
    translateTedivoForm({
        tedivoForm,
        getTranslation: getTranslation,
    });
    // Append to holder
    holder.appendChild(editorSection);
    if (lcgVcgTcgAndPairings.totalSlotsCount > 0)
        holder.appendChild(sideViewSection);
    holder.appendChild(tedivoForm.form);
    return {
        node: holder,
        tedivoForm,
        submitFunction: submitPassedToEditDrawer,
    };
    function submitPassedToEditDrawer() {
        return __awaiter(this, void 0, void 0, function* () {
            const validResult = tedivoForm.doSubmitForm();
            if (validResult.success) {
                goSquared.addEvent("Edit-OVD - Edit Vessel Parts - Save");
                const result = {
                    labels: {
                        labelVesselName: validResult.data.labelVesselName,
                        labelCountry: validResult.data.labelCountry,
                        labelIMOCode: validResult.data.labelIMOCode,
                        labelLateral: validResult.data.labelLateral,
                    },
                    colors: {
                        mainColor: validResult.data.mainColor,
                        bridgeColor: validResult.data.bridgeColor,
                        bottomColor: validResult.data.bottomColor,
                        cranesColor: validResult.data.cranesColor,
                        smokeBottomColor: validResult.data.smokeBottomColor,
                        smokeTopColor: validResult.data.smokeTopColor,
                        separatorColor: validResult.data.separatorColor,
                        belowSeparatorColor: validResult.data.belowSeparatorColor,
                        labelsNoseColor: validResult.data.labelsNoseColor,
                        labelsTailColor: validResult.data.labelsTailColor,
                        labelsSideColor: validResult.data.labelsSideColor,
                        labelsBaysColor: validResult.data.labelsBaysColor,
                    },
                    tailOptions: {
                        propellers: validResult.data.propellers,
                        rudders: validResult.data.rudders,
                    },
                };
                ovdJsonStore.set3DViewParams(result, editorComp.getVesselParts(), calculateAdjustedBottomBases(dataStore.values));
                return true;
            }
            else {
                return false;
            }
        });
    }
    function updateAllSideView() {
        return __awaiter(this, void 0, void 0, function* () {
            const lcgVcgTcgAndPairings = getBayLcgVcgTcgAndPairings({
                bls: baysData,
                vesselPartsData: editorComp.getVesselParts() || [],
                sizeSummary,
                masterCGs: shipData.masterCGs,
            });
            removeChildren(sideViewSection);
            sideViewNode = createSideViewForEditing({
                sizeSummary,
                baysData,
                lcgVcgTcgAndPairings,
                dataStore,
                adjustedBottomBases: (v3DParams === null || v3DParams === void 0 ? void 0 : v3DParams.adjustedBottomBases) || [],
            });
            if (lcgVcgTcgAndPairings.missingImportantVcgs) {
                const panelInputs = createBottomBasesDetailsPanel(baysData, v3DParams === null || v3DParams === void 0 ? void 0 : v3DParams.adjustedBottomBases, lcgVcgTcgAndPairings, dataStore);
                sideViewSection.appendChild(panelInputs);
            }
            sideViewSection.appendChild(sideViewNode);
        });
    }
}
function calculateAdjustedBottomBases(values) {
    const allKeys = Array.from(values.keys()).sort();
    const newAdjustedBottomBases = [];
    allKeys.forEach((key) => {
        const [baysStr, level] = key.split("|");
        const bays = baysStr.split("-");
        const value = values.get(key);
        if (value === undefined)
            return;
        newAdjustedBottomBases.push({
            bays,
            level: parseInt(level),
            bottomBase: value,
        });
    });
    return newAdjustedBottomBases;
}
function createSideViewForEditing({ sizeSummary, baysData, lcgVcgTcgAndPairings, dataStore, vesselPartsData = [], adjustedBottomBases, }) {
    const root = getComputedStyle(document.body);
    const symbolsOptions = {
        strokeWidth: 1,
        strokeColor: root.getPropertyValue("--sl-color-neutral-400"),
        fontColor: root.getPropertyValue("--sl-color-neutral-700"),
        fillColor: root.getPropertyValue("--sl-color-neutral-50"),
        shipStrokeColor: root.getPropertyValue("--sl-color-primary-300"),
        pairedNoneContainerColor: root.getPropertyValue("--sl-color-violet-400"),
        pairedAftContainerColor: root.getPropertyValue("--sl-color-teal-400"),
        pairedFwdContainerColor: root.getPropertyValue("--sl-color-neutral-400"),
        warningColor: root.getPropertyValue("--sl-color-warning-700"),
    };
    const { sideViewSvg, svgSizeRatio } = createSimpleSideView({
        sizeSummary,
        baysData,
        lcgVcgTcgAndPairings,
        symbolsOptions,
        vesselPartsData,
        adjustedBottomBases,
    });
    sideViewSvg.classList.add("max-height-400");
    if (lcgVcgTcgAndPairings.missingImportantVcgs) {
        sideViewSvg.classList.add("sideview-action-move");
        const gs = sideViewSvg.querySelectorAll("g");
        const adjustBB = adjustedBottomBases.reduce((acc, bb) => {
            acc[`${bb.bays.join("-")}|${bb.level}`] = String(bb.bottomBase);
            return acc;
        }, {});
        gs.forEach((g) => {
            g.dataset.initialY = adjustBB[`${g.getAttribute("data-id")}`] || "0";
            attachUpAndDownDraggingActions(g, (s, diffY, initialY) => {
                // Set the new value
                const diffYInMm = -roundDec(diffY / svgSizeRatio, 2) + Number(initialY);
                //#region Help with the other level (Above->Below or Below->Above)
                const [baysStr, levelStr] = s.split("|");
                const level = Number(levelStr);
                const otherId = `${baysStr}|${level === BayLevelEnum.ABOVE ? BayLevelEnum.BELOW : BayLevelEnum.ABOVE}`;
                const otherG = sideViewSvg.querySelector(`g[data-id="${otherId}"]`);
                if (!otherG) {
                    dataStore.setValue(s, diffYInMm);
                    return;
                }
                const otherGHeight = Number(otherG.getAttribute("data-height") || "0");
                const otherGBBase = Number(otherG.getAttribute("data-bbase") || "0");
                const otherGAdjust = dataStore.getValue(otherId) || 0;
                const ownGBBase = Number(g.getAttribute("data-bbase") || "0");
                if (level === BayLevelEnum.ABOVE) {
                    const effectiveAboveBottom = ownGBBase + diffYInMm;
                    const effectiveBelowTop = otherGBBase + otherGAdjust + otherGHeight;
                    if (effectiveAboveBottom < otherGBBase + otherGAdjust) {
                        dataStore.executeChange();
                        return;
                    }
                    else if (effectiveAboveBottom - 1000 < effectiveBelowTop) {
                        dataStore.setValue(s, diffYInMm);
                        dataStore.setValue(otherId, effectiveAboveBottom - otherGBBase - (otherGHeight + 2000));
                        dataStore.executeChange();
                    }
                }
                else {
                    const effectiveAboveBottom = otherGBBase + otherGAdjust;
                    const ownHeight = Number(g.getAttribute("data-height") || "0");
                    const effectiveBelowTop = ownGBBase + diffYInMm + ownHeight;
                    if (effectiveAboveBottom < ownGBBase + diffYInMm) {
                        dataStore.executeChange();
                        return;
                    }
                    else if (effectiveAboveBottom - 1000 < effectiveBelowTop) {
                        dataStore.setValue(s, diffYInMm);
                        dataStore.setValue(otherId, ownGBBase + diffYInMm + ownHeight + 2000 - otherGBBase);
                        dataStore.executeChange();
                    }
                }
                //#endregion
            });
        });
    }
    return sideViewSvg;
}
function createBottomBasesDetailsPanel(bls, adjustedBottomBases, lcgVcgTcgAndPairings, dataStore) {
    const adjustedBottomBasesDict = adjustedBottomBases
        ? adjustedBottomBases.reduce((acc, bb) => {
            acc[`${bb.bays.join("-")}|${bb.level}`] = bb.bottomBase;
            return acc;
        }, {})
        : {};
    const bayLevelPositionsAllDict = createDictionary([
        ...lcgVcgTcgAndPairings.bayLevelPositionsAbove,
        ...lcgVcgTcgAndPairings.bayLevelPositionsBelow,
    ], (bl) => `${bl.isoBay}-${bl.level}`);
    const pairedBays = getAllThePairedBays(bls, true);
    const blsUniqueKeys = pairedBays.pairedBays
        .map((pb) => `${pb.base}-${pb.paired}|${pb.level}`)
        .concat(pairedBays.unpairedBays.map((ub) => `${ub.base}|${ub.level}`))
        .sort();
    const details = document.createElement("sl-details");
    details.className = "side-view-details";
    details.summary = getTranslation("view:view3D.sections.adjutstBottomBases");
    const inputsHolder = document.createElement("div");
    inputsHolder.className = "side-view-details-inputs";
    blsUniqueKeys.forEach((bKey) => {
        var _a, _b;
        const customBb = adjustedBottomBasesDict[bKey];
        const [baysStr, level] = bKey.split("|");
        const bays = baysStr.split("-");
        const minTier = Math.min(((_a = bayLevelPositionsAllDict[`${bays[0]}-${level}`]) === null || _a === void 0 ? void 0 : _a.minTier) || Infinity, ((_b = bayLevelPositionsAllDict[`${bays[1]}-${level}`]) === null || _b === void 0 ? void 0 : _b.minTier) || Infinity);
        if (minTier === Infinity)
            return;
        const input = createInputWithUnits({
            label: `${baysStr}-${getTranslation(`enums:BayLevelEnum.${BayLevelEnum[Number(level)]}`)} (${pad2(minTier)})`,
            name: bKey,
            converter: globalUnits.lengthUnits,
            converterPrecision: 3,
            value: customBb,
            placeholder: 0,
        });
        dataStore.registerInputField(input);
        inputsHolder.appendChild(input);
    });
    details.appendChild(inputsHolder);
    return details;
}
function attachUpAndDownDraggingActions(n, updateCb) {
    const svg = n.ownerSVGElement;
    // const id = n.getAttribute("data-id");
    const rectMat = n.transform.baseVal.getItem(0).matrix;
    const orginalX = rectMat.e;
    const originalY = rectMat.f;
    let isDragging = false;
    let startMouseY = 0;
    let startTranslateY = 0;
    let diffMouseY = 0;
    let diffOverallY = 0;
    n.addEventListener("mousedown", (e) => {
        isDragging = true;
        startMouseY = e.clientY;
        startTranslateY = n.transform.baseVal.getItem(0).matrix.f;
        svg.addEventListener("mouseup", onMouseUp);
        svg.addEventListener("mousemove", onMouseMove);
    });
    function onMouseMove(e) {
        if (!isDragging) {
            removeHandlers();
            return;
        }
        diffMouseY = e.clientY - startMouseY;
        diffOverallY = startTranslateY + diffMouseY - originalY;
        n.transform.baseVal
            .getItem(0)
            .setTranslate(orginalX, startTranslateY + diffMouseY);
    }
    function onMouseUp() {
        isDragging = false;
        const dsId = n.dataset.id || "";
        const dsInitialY = Number(n.dataset.initialY || "0");
        updateCb(dsId, diffOverallY, dsInitialY);
        removeHandlers();
    }
    function removeHandlers() {
        svg.removeEventListener("mousemove", onMouseMove);
        svg.removeEventListener("mouseup", onMouseUp);
    }
}
