export const findParentBy = (
  e: Event | Element | null | undefined,
  criteria: string,
): Element | null => {
  if (!e) {
    return null;
  }

  let prop: "className" | "id" | "nodeName", initial: string;

  if (typeof criteria === "string") {
    initial = criteria[0];
    switch (initial) {
      case ".":
        prop = "className";
        criteria = criteria.substring(1);
        break;
      case "#":
        prop = "id";
        criteria = criteria.substring(1);
        break;
      default:
        prop = "nodeName";
    }
  } else {
    return null;
  }

  let t: Element | null = null;
  if (e instanceof Element) {
    t = e;
  } else if (e instanceof EventTarget) {
    t = e.target as Element;
  }

  let valsProp: Array<string> = [];
  if (t) {
    valsProp = String(t[prop] || "").split(" ");
  }

  while (t && valsProp.indexOf(criteria) < 0) {
    if (!t.parentElement || t.tagName === "HTML") {
      return null;
    }
    t = t.parentElement;
    if (t) {
      valsProp = String(t[prop] || "").split(" ");
    }
  }
  return t;
};
