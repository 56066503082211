import { TedivoForm, TfCheckbox } from "@tedivo/tedivo-form";

import { IEditBayFields } from "./bay-edit.component";
import { ISlotData } from "open-vessel-definition";

export function updateFormOnSlotsChanged(
  slotData: ISlotData[],
  tedivoForm: TedivoForm<IEditBayFields>,
) {
  // Enable/disable centerLineRow field and boxes
  const some00RowInUse = slotData
    .map((slotData) => slotData.pos)
    .some((pos) => pos.indexOf("00") === 0);

  const centerLineRowField = tedivoForm.getFormControlsByName().centerLineRow;
  (centerLineRowField.field as TfCheckbox).disabled = some00RowInUse;
}
