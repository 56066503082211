/* eslint-disable @typescript-eslint/no-explicit-any */

import { pad2, pad3 } from "@tedivo/tedivo-pure-helpers";

import { IMapConfigArray } from "@tedivo/tedivo-ui";
import ISizeSummary from "open-vessel-definition/build/src/models/base/ISizeSummary";

const ViewSizeSummaryConfig: IMapConfigArray<
  ISizeSummary & { totalTEUs: number }
> = [
  {
    label: "general:grid.isoBays",
    key: "isoBays",
    type: "string",
    undefinedIsDash: true,
    format: (v) => pad3(v as number),
  },
  {
    label: "view:maxRow",
    key: "maxRow",
    type: "string",
    undefinedIsDash: true,
    format: (v: any) => pad2((v || 0) as number) as string,
  },
  {
    label: "view:centerLineRow",
    key: "centerLineRow",
    type: "yesNo",
    undefinedIsDash: true,
  },
  {
    label: "view:maxAboveTier",
    key: "maxAboveTier",
    type: "string",
    undefinedIsDash: true,
  },
  {
    label: "view:minAboveTier",
    key: "minAboveTier",
    type: "string",
    undefinedIsDash: true,
  },
  {
    label: "view:maxBelowTier",
    key: "maxBelowTier",
    type: "string",
    undefinedIsDash: true,
    format: (v: any) => pad2((v || 0) as number) as string,
  },
  {
    label: "view:minBelowTier",
    key: "minBelowTier",
    type: "string",
    undefinedIsDash: true,
    format: (v: any) => pad2((v || 0) as number) as string,
  },
  {
    label: "view:totalTEUs",
    key: "totalTEUs",
    type: "number",
    undefinedIsDash: true,
  },
];

export default ViewSizeSummaryConfig;
