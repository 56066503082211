import {
  IRowTiersFromSlotsResult,
  getRowsAndTiersFromSlotKeys,
} from "../getRowsAndTiersFromSlotKeys";
import { ISlotData, TContainerLengths } from "open-vessel-definition";

import { ISlotPattern } from "../types/IPositionPatterns";
import { SmartSlotData } from "./SmartSlotData";

export class SmartSlotsData {
  private readonly _smartSlots: SmartSlotData[];
  private readonly _smartSlotsMap: { [pos: ISlotPattern]: SmartSlotData };
  private readonly _smartSlotsMapBySize: { [size: string]: SmartSlotData[] };

  constructor(slots: ISlotData[]) {
    this._smartSlots = slots.map((slot) => new SmartSlotData(slot));

    this._smartSlotsMap = {};
    this._smartSlotsMapBySize = {};

    for (let i = 0; i < this._smartSlots.length; i++) {
      const slot = this._smartSlots[i];
      this._smartSlotsMap[slot.pos] = slot;

      slot.allSizes.forEach((size) => {
        if (slot.isRestricted) return;

        if (!this._smartSlotsMapBySize[size])
          this._smartSlotsMapBySize[size] = [];

        this._smartSlotsMapBySize[size].push(slot);
      });
    }
  }

  getSlotByPos(pos: ISlotPattern): SmartSlotData | undefined {
    return this._smartSlotsMap[pos];
  }

  getSlotsKeys(): ISlotPattern[] {
    return Object.keys(this._smartSlotsMap) as ISlotPattern[];
  }

  getSlotsBySize(size: TContainerLengths): SmartSlotData[] {
    return this._smartSlotsMapBySize[size] || [];
  }

  getSlotsData(): ISlotData[] {
    return this._smartSlots.map((slot) => slot.data);
  }

  getPerSlotInfoObject(): Record<ISlotPattern, ISlotData> {
    return this._smartSlots.reduce((acc, slot) => {
      acc[slot.pos] = slot.data;
      return acc;
    }, {} as Record<ISlotPattern, ISlotData>);
  }

  addSlot(slot: ISlotData): void {
    this._smartSlots.push(new SmartSlotData(slot));
  }

  getRowsAndTiers(): IRowTiersFromSlotsResult {
    return getRowsAndTiersFromSlotKeys(this.getSlotsKeys());
  }

  getAllSizesInSet(): { sizes: TContainerLengths[]; definedSlots: number } {
    const sizes = (
      Object.keys(this._smartSlotsMapBySize).map(Number) as TContainerLengths[]
    ).sort();

    const definedSlots = sizes.reduce((acc, size) => {
      acc += this._smartSlotsMapBySize[size].length;
      return acc;
    }, 0);

    return { sizes, definedSlots };
  }

  get someHave40s(): boolean {
    return this.some((slot) => slot.has40s);
  }

  some(cb: (slot: SmartSlotData) => boolean): boolean {
    return this._smartSlots.some(cb);
  }

  forEach(cb: (slot: SmartSlotData) => void): void {
    this._smartSlots.forEach(cb);
  }
}
