import { feetToMillimeters, roundDec } from "@tedivo/tedivo-pure-helpers";

import { IVesselOneBaySlotSmoke } from "@tedivo/tedivo-bay-grid-pure";

const SMOKE_TOP = 2400;
const SMOKE_SMOKE = 2000;

export function drawSideSmokeStack(
  bridge: IVesselOneBaySlotSmoke,
  midVcg: number,
  svgSizeRatio: number,
): SVGPathElement[] {
  const mainPath = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "path",
  );

  mainPath.setAttribute("stroke", "#888");
  mainPath.setAttribute("fill", "#fff");
  mainPath.setAttribute("stroke-width", "1");
  mainPath.setAttribute("fill-opacity", "0.2");
  mainPath.setAttribute("data-type", bridge.type);
  mainPath.setAttribute("data-id", bridge.id);

  const result: SVGPathElement[] = [mainPath];

  const w2 = roundDec(feetToMillimeters(bridge.len) * 0.5 * 0.45, 2);

  const w2Svg = w2 * svgSizeRatio;
  const top = roundDec(SMOKE_TOP * svgSizeRatio);
  const snokeBelow = SMOKE_SMOKE * svgSizeRatio;

  mainPath.setAttribute(
    "d",
    `M${-w2Svg},${top + snokeBelow} 
     L${-w2Svg},${midVcg - snokeBelow} 
     L${-w2Svg * 2},${midVcg - snokeBelow} 
     L${-w2Svg * 2},${midVcg} 
     L${w2Svg * 2},${midVcg} 
     L${w2Svg * 2},${midVcg - snokeBelow} 
     L${w2Svg},${midVcg - snokeBelow} 
     L${w2Svg},${top + snokeBelow}
     L${-w2Svg},${top + snokeBelow}`,
  );

  {
    const ceiling = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "path",
    );

    ceiling.setAttribute("stroke", "#888");
    ceiling.setAttribute("fill", "#3d2814");
    ceiling.setAttribute("stroke-width", "1");
    ceiling.setAttribute("fill-opacity", "1");

    ceiling.setAttribute(
      "d",
      `M${-w2Svg - 2},${top} L${-w2Svg},${top + snokeBelow} L${w2Svg},${
        top + snokeBelow
      } L${w2Svg + 2},${top} L${-w2Svg - 2},${top}`,
    );

    result.push(ceiling);
  }

  return result;
}
