import { ISlotData } from "open-vessel-definition";
import { ISlotPattern } from "../types/IPositionPatterns";
import TContainerLengths from "../TContainerLengths";

export class SmartSlotData {
  data: ISlotData;

  constructor(data: ISlotData) {
    this.data = data;
  }

  get pos(): ISlotPattern {
    return this.data.pos;
  }

  get allSizes(): TContainerLengths[] {
    const sizes = this.data.sizes || {};

    return (Object.keys(sizes).map(Number) as TContainerLengths[]).filter(
      (size) => sizes[size] !== 0 && sizes[size] !== undefined,
    );
  }

  get hasNoSizes(): boolean {
    return this.allSizes.length === 0;
  }

  get has20s(): boolean {
    return this.allSizes.some((size) => size < 40);
  }

  get has40s(): boolean {
    return this.allSizes.some((size) => size >= 40);
  }

  get isRestricted(): boolean {
    return !!this.data.restricted;
  }
}
