import { SlotDataReducer, } from "@tedivo/tedivo-bay-grid-pure";
export function slotHasAttribute(slot, action) {
    const newSlot = SlotDataReducer[action](slot);
    const retFn = SlotFieldComparer[action];
    return retFn ? retFn(slot, newSlot) : false;
}
export const SlotFieldComparer = {
    ADD_SIZE_20: (a, b) => a.sizes && b.sizes && !!a.sizes[20] === !!b.sizes[20],
    ADD_SIZE_24: (a, b) => a.sizes && b.sizes && !!a.sizes[24] === !!b.sizes[24],
    ADD_SIZE_40: (a, b) => a.sizes && b.sizes && !!a.sizes[40] === !!b.sizes[40],
    ADD_SIZE_45: (a, b) => a.sizes && b.sizes && !!a.sizes[45] === !!b.sizes[45],
    ADD_SIZE_48: (a, b) => a.sizes && b.sizes && !!a.sizes[48] === !!b.sizes[48],
    ADD_SIZE_53: (a, b) => a.sizes && b.sizes && !!a.sizes[53] === !!b.sizes[53],
    ADD_COOLSTOWPROHIBITED: (a, b) => a.coolStowProhibited === b.coolStowProhibited,
    ADD_REEFER: (a, b) => a.reefer === b.reefer,
    ADD_RESTRICTED: (a, b) => a.restricted === b.restricted,
    ADD_HAZARDOUSPROHIBITED: (a, b) => a.hazardousProhibited === b.hazardousProhibited,
    ADD_SIZE_20_CONEREQ: (a, b) => {
        var _a, _b, _c, _d, _e, _f;
        return ((_b = (_a = a.sizes) === null || _a === void 0 ? void 0 : _a[20]) === null || _b === void 0 ? void 0 : _b.cone) !== undefined &&
            ((_d = (_c = a.sizes) === null || _c === void 0 ? void 0 : _c[20]) === null || _d === void 0 ? void 0 : _d.cone) ==
                ((_f = (_e = b.sizes) === null || _e === void 0 ? void 0 : _e[20]) === null || _f === void 0 ? void 0 : _f.cone);
    },
    ADD_SIZE_24_CONEREQ: (a, b) => {
        var _a, _b, _c, _d, _e, _f;
        return ((_b = (_a = a.sizes) === null || _a === void 0 ? void 0 : _a[24]) === null || _b === void 0 ? void 0 : _b.cone) !== undefined &&
            ((_d = (_c = a.sizes) === null || _c === void 0 ? void 0 : _c[24]) === null || _d === void 0 ? void 0 : _d.cone) ==
                ((_f = (_e = b.sizes) === null || _e === void 0 ? void 0 : _e[24]) === null || _f === void 0 ? void 0 : _f.cone);
    },
    ADD_SIZE_40_CONEREQ: (a, b) => {
        var _a, _b, _c, _d, _e, _f;
        return ((_b = (_a = a.sizes) === null || _a === void 0 ? void 0 : _a[40]) === null || _b === void 0 ? void 0 : _b.cone) !== undefined &&
            ((_d = (_c = a.sizes) === null || _c === void 0 ? void 0 : _c[40]) === null || _d === void 0 ? void 0 : _d.cone) ==
                ((_f = (_e = b.sizes) === null || _e === void 0 ? void 0 : _e[40]) === null || _f === void 0 ? void 0 : _f.cone);
    },
    ADD_SIZE_45_CONEREQ: (a, b) => {
        var _a, _b, _c, _d, _e, _f;
        return ((_b = (_a = a.sizes) === null || _a === void 0 ? void 0 : _a[45]) === null || _b === void 0 ? void 0 : _b.cone) !== undefined &&
            ((_d = (_c = a.sizes) === null || _c === void 0 ? void 0 : _c[45]) === null || _d === void 0 ? void 0 : _d.cone) ==
                ((_f = (_e = b.sizes) === null || _e === void 0 ? void 0 : _e[45]) === null || _f === void 0 ? void 0 : _f.cone);
    },
    ADD_SIZE_48_CONEREQ: (a, b) => {
        var _a, _b, _c, _d, _e, _f;
        return ((_b = (_a = a.sizes) === null || _a === void 0 ? void 0 : _a[48]) === null || _b === void 0 ? void 0 : _b.cone) !== undefined &&
            ((_d = (_c = a.sizes) === null || _c === void 0 ? void 0 : _c[48]) === null || _d === void 0 ? void 0 : _d.cone) ==
                ((_f = (_e = b.sizes) === null || _e === void 0 ? void 0 : _e[48]) === null || _f === void 0 ? void 0 : _f.cone);
    },
    ADD_SIZE_53_CONEREQ: (a, b) => {
        var _a, _b, _c, _d, _e, _f;
        return ((_b = (_a = a.sizes) === null || _a === void 0 ? void 0 : _a[53]) === null || _b === void 0 ? void 0 : _b.cone) !== undefined &&
            ((_d = (_c = a.sizes) === null || _c === void 0 ? void 0 : _c[53]) === null || _d === void 0 ? void 0 : _d.cone) ==
                ((_f = (_e = b.sizes) === null || _e === void 0 ? void 0 : _e[53]) === null || _f === void 0 ? void 0 : _f.cone);
    },
};
