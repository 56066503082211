import {
  BayLevelEnum,
  IBayLevelData,
  IBaySlotData,
  IShipData,
  ISlotData,
  TBayRowInfo,
} from "open-vessel-definition";
import {
  IBayPattern,
  ISlotPattern,
  createRowsFromConfig,
  getRowsAndTiersFromSlotKeys,
} from "@tedivo/tedivo-bay-grid-pure";

import { IBayRowInfo } from "open-vessel-definition/build/src/models/v1/parts/IBayLevelData";
import { IEditBayFields } from "./bay-edit.component";
import { pad2 } from "@tedivo/tedivo-pure-helpers";

export function postProcessingForm(
  shipData: IShipData,
  result: IEditBayFields,
  isoBay: IBayPattern,
  level: BayLevelEnum,
  prevBayData?: IBayLevelData,
  newSlotsData?: ISlotData[],
): IBayLevelData {
  const {
    notes,
    pairedBay,
    doors,
    reeferPlugs,
    centerLineRow,
    athwartShip,
    foreHatch,
    ventilated,
    heatSrcFore,
    ignitionSrcFore,
    quartersFore,
    engineRmBulkFore,
    telescoping,
    ...restOfFormData
  } = result;

  const bayData: IBayLevelData = {
    ...prevBayData,
    ...restOfFormData,
    isoBay,
    level,
    pairedBay: pairedBay || undefined,
    doors: doors || undefined,
    reeferPlugs: reeferPlugs || undefined,
    centerLineRow: centerLineRow ? 1 : 0,
    athwartShip: athwartShip ? 1 : undefined,
    foreHatch: foreHatch ? 1 : undefined,
    ventilated: ventilated ? 1 : undefined,
    heatSrcFore: heatSrcFore ? 1 : undefined,
    ignitionSrcFore: ignitionSrcFore ? 1 : undefined,
    quartersFore: quartersFore ? 1 : undefined,
    engineRmBulkFore: engineRmBulkFore ? 1 : undefined,
    telescoping: telescoping ? 1 : undefined,
    meta: { notes },
    infoByContLength: prevBayData?.infoByContLength || {},
  };

  if (newSlotsData !== undefined) {
    const newPerSlotInfo: IBaySlotData = {};
    const newPerRowInfo: TBayRowInfo = { each: {}, common: {} };
    const newSlotsDataPosArray = newSlotsData.map((slotData) => slotData.pos);

    const { maxRow, centerLineRow, tiersByRow } =
      getRowsAndTiersFromSlotKeys(newSlotsDataPosArray);

    // 1. perSlotInfo
    newSlotsData.forEach((slotData) => {
      const pos = slotData.pos;
      newPerSlotInfo[pos] = slotData;
    });

    bayData.perSlotInfo = newPerSlotInfo;

    // 2. perRowInfo
    const newRows = createRowsFromConfig(!!centerLineRow, maxRow);
    const prevRowsCalcs = getRowsAndTiersFromSlotKeys(
      (prevBayData?.perSlotInfo
        ? Object.keys(prevBayData?.perSlotInfo)
        : []) as ISlotPattern[],
    );

    let rowsThatDifferInMinTier = 0;

    newRows.forEach((row) => {
      const prevRowInfoClonedOrNew: IBayRowInfo = prevBayData?.perRowInfo
        ?.each?.[row]
        ? {
            ...prevBayData.perRowInfo.each?.[row],
            rowInfoByLength: {
              ...prevBayData.perRowInfo.each?.[row].rowInfoByLength,
            },
          }
        : { isoRow: row };

      if (prevRowsCalcs.tiersByRow[row]?.minTier !== tiersByRow[row]?.minTier) {
        const newMinTier =
          tiersByRow[row]?.minTier !== undefined
            ? pad2(tiersByRow[row]?.minTier)
            : undefined;

        prevRowInfoClonedOrNew.bottomBase =
          newMinTier !== undefined
            ? shipData.masterCGs.bottomBases[newMinTier]
            : undefined;

        prevRowInfoClonedOrNew.maxHeight;
        rowsThatDifferInMinTier += 1;
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      newPerRowInfo.each![row] = prevRowInfoClonedOrNew;
    });

    if (rowsThatDifferInMinTier < prevRowsCalcs.rows.length) {
      newPerRowInfo.common = { ...prevBayData?.perRowInfo?.common };
    }

    bayData.perRowInfo = newPerRowInfo;
  }

  return bayData;
}
