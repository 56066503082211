import { __awaiter } from "tslib";
import { getTranslation, i18nReactive } from "../../../app/i18/i18tn";
import { I18nComponentRegisterer } from "@tedivo/tedivo-i18";
import { IntegratedDialog } from "@tedivo/tedivo-ui";
import Services from "../../../app/services";
import securityModule from "../../../app/security/SecurityModule";
import { systemRights } from "@tedivo/tvd-api-models";
export class TVDVoteTvlFileComponent extends HTMLElement {
    constructor() {
        super();
        this._fileOrgId = undefined;
        this._fileId = undefined;
        this._isOwnVote = false;
        this.update = () => __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            if (!this._fileOrgId || !this._fileId)
                return;
            this.voteButton.loading = true;
            const resp = yield Services.files.getTvlVotes(this._fileOrgId, this._fileId);
            this.voteButton.loading = false;
            if (resp.statusCode === 200 || resp.statusCode === 204) {
                this.updateVoteButtonContent(((_a = resp.data) === null || _a === void 0 ? void 0 : _a.totalVotes) || 0, ((_b = resp.data) === null || _b === void 0 ? void 0 : _b.date) ? true : false);
            }
        });
        this.updateVoteButtonContent = (totalVotes, isOwnVote) => {
            this.i18nCR.addConsumer(this.voteButton, "general:votes.totalVotes", "innerHTML", { totalVotes });
            this.iconButton.name = isOwnVote ? "heart-fill" : "heart";
            this.voteButton.appendChild(this.iconButton);
            this.voteButton.disabled = false;
            this._isOwnVote = isOwnVote;
        };
        this.toggleVote = () => __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            if (!securityModule.userHasPermission(systemRights.ORG.CanVotePublicFiles) ||
                !this._fileOrgId ||
                !this._fileId ||
                !(yield continueWithVotingAction(this._isOwnVote)))
                return;
            this.voteButton.loading = true;
            const resp = yield Services.files.toggleOwnTvlVote(this._fileOrgId, this._fileId);
            this.voteButton.loading = false;
            if (resp.statusCode === 204 || resp.statusCode === 200) {
                this.updateVoteButtonContent(((_a = resp.data) === null || _a === void 0 ? void 0 : _a.totalVotes) || 0, ((_b = resp.data) === null || _b === void 0 ? void 0 : _b.date) ? true : false);
            }
            else {
                const err = resp.message || "Error toggling vote";
                document.body.dispatchEvent(new CustomEvent("customError", {
                    detail: {
                        errorCode: "",
                        message: "general:votes.vote",
                        translationKey: err,
                    },
                }));
            }
            function continueWithVotingAction(isOwnVote) {
                return __awaiter(this, void 0, void 0, function* () {
                    return new Promise((resolve) => {
                        if (!isOwnVote)
                            return resolve(true);
                        const intDialog = new IntegratedDialog({
                            parentNode: document.body,
                            removeDialogWhenHidden: true,
                            buttonsAttrs: [
                                {
                                    label: getTranslation("general:votes.removeVote"),
                                    value: "remove",
                                    variant: "danger",
                                    type: "submit",
                                    icon: "heartbreak-fill",
                                },
                                {
                                    label: getTranslation("general:common.cancel"),
                                    value: "cancel",
                                    variant: "primary",
                                    type: "button",
                                    icon: "heart-fill",
                                    autofocus: true,
                                },
                            ],
                        });
                        intDialog.onButtonClicked = (value) => {
                            resolve(value === "remove");
                        };
                        intDialog.show(getTranslation("general:votes.removeVoteQuestion"), getTranslation("general:votes.removeVoteConfirm"));
                    });
                });
            }
        });
        this.voteButton = document.createElement("sl-button");
        this.iconButton = document.createElement("sl-icon");
        this.i18nCR = new I18nComponentRegisterer(i18nReactive);
    }
    set fileOrgId(orgId) {
        this._fileOrgId = orgId;
    }
    set fileId(fileId) {
        this._fileId = fileId;
    }
    connectedCallback() {
        this.createNodes();
        this.update();
    }
    createNodes() {
        this.iconButton.name = "heart";
        this.iconButton.slot = "prefix";
        this.voteButton.variant = "success";
        this.voteButton.size = "small";
        this.voteButton.outline = true;
        this.voteButton.innerHTML = getTranslation("general:votes.totalVotes", {
            totalVotes: 0,
        });
        this.voteButton.disabled = true;
        this.voteButton.appendChild(this.iconButton);
        this.voteButton.addEventListener("click", this.toggleVote);
        this.appendChild(this.voteButton);
    }
}
TVDVoteTvlFileComponent.observedAttributes = [];
customElements.define("tvd-vote-tvl-component", TVDVoteTvlFileComponent);
