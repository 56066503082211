import globalUnits from "../units/globalUnits";
import ovdJsonStore from "../stores/OVDJsonStore";

export function bindOvdJsonStoreEvents() {
  ovdJsonStore.addEventListener(
    "jsonLoaded",
    () => {
      globalUnits.setLpp(
        ovdJsonStore.currentJson?.shipData?.lcgOptions?.lpp || 0,
      );
    },
    false,
  );
}
