import SlButton from "@shoelace-style/shoelace/dist/components/button/button";
import { pad3 } from "@tedivo/tedivo-pure-helpers";

class BaySelectorComponent {
  public dropdown: HTMLElement;
  private triggerButton: SlButton;
  private clickOutside: HTMLElement;
  private menu: HTMLElement;

  private internalValue: number;
  private isOpened = false;

  private items: { [key: string]: HTMLElement } = {};

  get value() {
    return pad3(this.internalValue);
  }

  set value(s: string) {
    const cItem = this.items[this.value];
    if (cItem) {
      cItem.classList.remove("selected");
    }

    this.internalValue = Number(s);

    const newValue = pad3(this.internalValue);
    const newItem = this.items[newValue];
    if (newItem) {
      newItem.classList.add("selected");
    }

    this.triggerButton.innerHTML = newValue;
  }

  constructor(maxBay: number, onChange: (v: string) => void, initialValue = 1) {
    const bayOptions: string[] = [];
    for (let b = 1; b <= maxBay; b += 2) {
      bayOptions.push(pad3(b));
    }

    this.dropdown = document.createElement("div");
    this.triggerButton = document.createElement("sl-button");
    this.menu = document.createElement("ul");
    this.clickOutside = document.createElement("div");

    this.internalValue = initialValue;

    this.dropdown.className = "dropdown-bays";
    this.menu.className = "dropdown-bays-menu";
    this.clickOutside.className = "dropdown-bays-close";

    const initialValueStr = pad3(this.internalValue || 1);

    this.triggerButton.innerHTML = initialValueStr;
    this.triggerButton.slot = "trigger";
    this.triggerButton.caret = true;
    this.triggerButton.tabIndex = 0;

    bayOptions.forEach((val) => {
      const item = document.createElement("li");
      item.dataset.value = val;
      item.textContent = val;
      this.menu.appendChild(item);
      item.setAttribute("role", "button");
      this.items[val] = item;

      if (initialValueStr === val) {
        item.className = "selected";
      }
    });

    this.dropdown.appendChild(this.triggerButton);
    this.dropdown.appendChild(this.clickOutside);
    this.dropdown.appendChild(this.menu);

    this.hideMenu();

    this.attachListeners(onChange);
  }

  showMenu() {
    this.menu.style.display = "flex";
    this.clickOutside.style.display = "block";
    this.isOpened = true;
  }

  hideMenu() {
    this.menu.style.display = "none";
    this.clickOutside.style.display = "none";
    this.isOpened = false;
  }

  private attachListeners(onChange: (v: string) => void) {
    this.triggerButton.addEventListener("click", () => {
      this.isOpened ? this.hideMenu() : this.showMenu();
    });

    this.triggerButton.addEventListener("keydown", (ev: KeyboardEvent) => {
      if (ev.key !== " ") return;
      ev.preventDefault();
      this.isOpened ? this.hideMenu() : this.showMenu();
    });

    this.clickOutside.addEventListener("click", () => {
      this.hideMenu();
    });

    this.menu.addEventListener(
      "click",
      (ev: Event) => {
        const target = ev.target as HTMLElement;
        const newValue = target.dataset.value;

        if (newValue) {
          this.value = newValue;

          if (onChange) {
            onChange(newValue);
          }

          this.dropdown.dispatchEvent(
            new CustomEvent("selected", { detail: { value: newValue } }),
          );

          this.hideMenu();
        }
      },
      false,
    );
  }
}

export default BaySelectorComponent;
