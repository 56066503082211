import {
  ITransponserFunctions,
  IUnitsConverterFunctions,
  roundDec,
} from "@tedivo/tedivo-pure-helpers";

import SlInput from "@shoelace-style/shoelace/dist/components/input/input";
import { addStyles } from "../../helpers/addStyles";

export class InputWithUnits<T> extends HTMLElement {
  public static formAssociated = true;
  public static observedAttributes = ["class", "disabled"];

  public required = false;
  public size: "small" | "medium" | "large" = "medium";
  public converter?: IUnitsConverterFunctions<T>;
  public transponser?: ITransponserFunctions<T>;

  private internalValue? = 0;
  private internalId = "";
  private input: SlInput;
  private internalPlaceHolder?: number = undefined;
  private internalLabel = "";
  private internalDisabled = false;
  private internalUseInputListener = false;
  private internalConverterPrecision: number | undefined = undefined;

  get value(): number | undefined {
    this.calculateInternalValue();
    return this.internalValue;
  }

  set value(v: number | undefined) {
    this.internalValue = v;
    this.setDisplayValue(v);
  }

  get placeholder(): number | undefined {
    return this.internalPlaceHolder;
  }

  set placeholder(v: number | undefined) {
    this.internalPlaceHolder = v;
    if (v !== undefined) this.setPlaceholderValue(v);
  }

  get label() {
    return this.internalLabel;
  }

  set label(v: string) {
    this.internalLabel = v;
    if (this.input) this.input.label = v;
  }

  set helpText(v: string) {
    if (this.input) this.input.helpText = v;
  }

  get helpText() {
    return this.input?.helpText || "";
  }

  get name() {
    return this.internalId;
  }

  set name(v: string) {
    this.internalId = v;
    if (this.input) {
      this.input.id = v;
      this.input.name = v;
    }
  }

  get converterPrecision() {
    return this.internalConverterPrecision;
  }
  set converterPrecision(v: number | undefined) {
    this.internalConverterPrecision = v;
  }

  get disabled() {
    return this.internalDisabled;
  }
  set disabled(s: boolean) {
    this.internalDisabled = s;
    if (this.input) this.input.disabled = s;
  }

  get min() {
    return Number(this.input?.min) || undefined;
  }
  set min(s: number | undefined) {
    if (this.input) this.input.min = s ?? 0;
  }

  get max() {
    return Number(this.input?.max) || undefined;
  }
  set max(s: number | undefined) {
    if (this.input) this.input.max = s ?? 0;
  }

  get tabIndex(): number {
    return this.input?.tabIndex || -1;
  }

  set tabIndex(v: number) {
    if (this.input) this.input.tabIndex = v;
  }

  get noSpinButtons() {
    if (this.input) return this.input.noSpinButtons;
    return false;
  }

  set noSpinButtons(v: boolean) {
    if (this.input) this.input.noSpinButtons = v;
  }

  focus() {
    if (this.input) this.input.focus();
  }

  select() {
    if (this.input) this.input.select();
  }

  private setDisplayValue(v: number | undefined) {
    if (!this.input) return;
    if (v === undefined || isNaN(v)) {
      this.input.value = "";
      return;
    }

    // Display value -> First transponse, then convert
    let intValue = this.transponser
      ? this.transponser.fromValueToDisplay(v)
      : v;

    intValue = this.converter
      ? this.converter.fromValueToDisplay(intValue)
      : intValue;

    if (this.internalConverterPrecision !== undefined) {
      intValue = roundDec(intValue, this.internalConverterPrecision);
    }

    this.input.value = String(intValue);
  }

  private setPlaceholderValue(v: number | undefined) {
    if (!this.input) return;
    if (v === undefined || String(v) === "" || isNaN(v)) {
      this.input.placeholder = "";
      return;
    }

    const val = Number(v);

    // Display value -> First transponse, then convert
    let intValue = this.transponser
      ? this.transponser.fromValueToDisplay(val)
      : val;

    intValue = this.converter
      ? this.converter.fromValueToDisplay(intValue)
      : intValue;

    this.input.placeholder = String(intValue);
  }

  constructor() {
    super();

    const inp = document.createElement("sl-input");
    this.input = inp;
  }

  connectedCallback() {
    const shadow = this.attachShadow({ mode: "open" });

    const inp = this.input;
    inp.id = this.name;
    inp.name = this.name;
    inp.size = this.size;
    inp.type = "number";

    if (this.required) inp.required = true;
    if (this.label) inp.label = this.label;
    if (this.disabled) inp.disabled = this.disabled;

    shadow.appendChild(addStyles(styles));
    shadow.appendChild(inp);

    this.setDisplayValue(this.internalValue);
    this.setPlaceholderValue(this.internalPlaceHolder);

    inp.addEventListener("sl-change", this.onChange, false);
  }

  disconnectedCallback() {
    this.input.removeEventListener("sl-change", this.onChange, false);
  }

  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    switch (name) {
      case "class":
        if (newValue.indexOf("has-error") >= 0) {
          if (!this.input.classList.contains("has-error"))
            this.input.classList.add("has-error");
        } else {
          this.input.classList.remove("has-error");
        }
        break;
      case "disabled":
        this.disabled = newValue === "true";
        break;
    }
  }

  private onChange = () => {
    this.calculateInternalValue();
  };

  private calculateInternalValue = () => {
    const t = this.input;
    if (t.value === "") {
      this.internalValue = undefined;
      return;
    }

    const faceValue = Number(t.value);
    if (isNaN(faceValue)) return;

    // First convert, then transpose
    let intValue = this.converter
      ? this.converter.fromDisplayToValue(faceValue)
      : faceValue;

    intValue = this.transponser
      ? this.transponser.fromDisplayToValue(intValue)
      : intValue;

    this.internalValue = intValue;
  };
}

customElements.define("tf-input-units", InputWithUnits);
declare global {
  interface HTMLElementTagNameMap {
    "tf-input-units": InputWithUnits<unknown>;
  }
}

const styles = `
 
  sl-input.has-error:not([disabled])::part(form-control-label),
  sl-input.has-error:not([disabled])::part(form-control-help-text) {
    color: var(--sl-color-danger-700);
  }

  sl-input.has-error:not([disabled])::part(base) {
    border-color: var(--sl-color-danger-600);
  }

  sl-input.has-error:focus-within::part(base) {
    box-shadow: 0 0 0 var(--sl-focus-ring-width) var(--sl-color-danger-200);
  }
  
`;

export function createInputWithUnits<T>({
  name,
  value,
  placeholder,
  converter,
  transponser,
  size = "small",
  disabled = false,
  noSpinButtons = false,
  label = "",
  converterPrecision,
}: {
  name: string;
  value: number | undefined;
  placeholder?: number;
  converter?: IUnitsConverterFunctions<T>;
  transponser?: ITransponserFunctions<unknown>;
  size?: "small" | "medium" | "large";
  disabled?: boolean;
  noSpinButtons?: boolean;
  label?: string;
  converterPrecision?: number;
}) {
  const inp = new InputWithUnits();
  if (converter) inp.converter = converter;
  if (transponser) inp.transponser = transponser;
  if (label) inp.label = label;
  inp.name = name;
  inp.size = size;
  inp.value = value;
  inp.placeholder = placeholder;
  inp.disabled = disabled;
  inp.noSpinButtons = noSpinButtons;
  inp.converterPrecision = converterPrecision;
  return inp;
}
