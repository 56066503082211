import { __awaiter } from "tslib";
import { createScreen } from "../../helpers/createScreen";
import { getTranslation, i18nReactive } from "../../../app/i18/i18tn";
import { DivWithSpinner } from "@tedivo/tedivo-ui";
import { I18nComponentRegisterer } from "@tedivo/tedivo-i18";
import { IntegratedDialogError } from "@tedivo/tedivo-ui";
import Services from "../../../app/services";
import goSquared from "../../../app/tracking/goSquared";
import { removeChildren } from "@tedivo/tedivo-dom-helpers";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import securityModule from "../../../app/security/SecurityModule";
import { setAppTitle } from "../../../app/setAppTitle";
import { z } from "zod";
export class TVDForgotPasswordComponent extends HTMLElement {
    constructor() {
        super();
        this.showError = (e) => {
            this.dialogError.show(getTranslation(`errors:errorHasOcurred`), getTranslation(typeof e === "string" ? e : e.translationKey));
        };
        /** Replaces the UI */
        this.setCurrentFormAndNode = (fan) => {
            this.currentFormAndNode = fan;
            if (fan === null || fan === void 0 ? void 0 : fan.node) {
                this.divWithSpinner.setLoading(false);
                removeChildren(this.divWithSpinner);
                this.divWithSpinner.appendChild(fan.node);
            }
        };
        this.onSubmitForgotPasswordForm = (values) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            goSquared.addEvent("Forgot password - Submit");
            this.divWithSpinner.setLoading(true);
            const resp = yield Services.accounts.forgotMyPassword(values.email);
            this.divWithSpinner.setLoading(false);
            if (!((_a = resp.data) === null || _a === void 0 ? void 0 : _a.id)) {
                this.showError({
                    errorCode: String(resp.statusCode),
                    message: resp.code || "errorModifyingUser",
                    translationKey: resp.message || "errors:errorModifyingUser",
                });
                return false;
            }
            else {
                router.navigate(routeFns.login());
                return true;
            }
        });
        this.i18nCR = new I18nComponentRegisterer(i18nReactive);
        this.dialogError = new IntegratedDialogError(this, false, getTranslation("general:common.close"));
        this.divWithSpinner = new DivWithSpinner();
        setAppTitle(getTranslation("auth:forgotPassword"));
    }
    connectedCallback() {
        if (securityModule.isLoggedIn) {
            router.navigate(routeFns.login());
            return;
        }
        goSquared.trackPage("Forgot password");
        goSquared.addEvent("Forgot password - Show page");
        this.appendChild(this.divWithSpinner);
        this.setCurrentFormAndNode(createForgotPasswordScreen(this.onSubmitForgotPasswordForm, this.i18nCR));
    }
    disconnectedCallback() {
        this.i18nCR.disconnect();
    }
}
TVDForgotPasswordComponent.observedAttributes = [];
customElements.define("tvd-forgot-password-component", TVDForgotPasswordComponent);
function createForgotPasswordScreen(onSubmitDataForm, i18nCR) {
    const AuthForgotPasswordFormValidator = z.object({
        email: z
            .string()
            .email()
            .transform((v) => v === null || v === void 0 ? void 0 : v.toLowerCase()),
    });
    const fields = [
        {
            type: "title",
            label: "auth:forgotPasswordInstructions",
            tagName: "p",
        },
        {
            type: "textBox",
            name: "email",
            label: "general:common.email",
        },
    ];
    return createScreen({
        fields,
        onSubmitDataForm,
        formValidator: AuthForgotPasswordFormValidator,
        i18nCR,
        h1Text: "auth:forgotPassword",
        titleText: "auth:logIn",
    });
}
