import { I18nComponentRegisterer } from "@tedivo/tedivo-i18";
import { i18nReactive } from "../../../app/i18/i18tn";
import { stylesFromStringToTag } from "@tedivo/tedivo-dom-helpers";
export class TitleWithActions extends HTMLElement {
    constructor() {
        super(...arguments);
        this.titleNodeName = "h2";
        this._titleHtml = "";
        this.i18nCR = new I18nComponentRegisterer(i18nReactive);
        this.titleNode = undefined;
        this.headingNode = undefined;
        this.setHeadingNode = (node) => {
            this.headingNode = node;
        };
    }
    get titleHtml() {
        return this._titleHtml;
    }
    set titleHtml(value) {
        this._titleHtml = value;
        this.setAttribute("titleHtml", value);
    }
    connectedCallback() {
        const shadowRoot = this.attachShadow({ mode: "open" });
        const titleNode = document.createElement(this.titleNodeName);
        this.i18nCR.addConsumer(titleNode, this.titleHtml, "innerHTML");
        if (this.headingNode) {
            titleNode.appendChild(this.headingNode);
        }
        const slot = document.createElement("slot");
        slot.name = "actions";
        shadowRoot.appendChild(stylesFromStringToTag(styles));
        shadowRoot.appendChild(titleNode);
        shadowRoot.appendChild(slot);
    }
    disconnectedCallback() {
        this.i18nCR.disconnect();
    }
    attributeChangedCallback(name, oldValue, newValue) {
        if (name === "titleHtml") {
            if (this.titleNode)
                this.i18nCR.addConsumer(this.titleNode, newValue, "innerHTML");
        }
    }
}
TitleWithActions.observedAttributes = [];
customElements.define("title-with-actions-component", TitleWithActions);
const styles = `
    display: flex;
    align-items: center;
  
    h1:first-child,
    h2:first-child,
    h3:first-child,
    h4:first-child,
    h5:first-child {
      margin-top: 0;
    }
  
    h1,
    h2,
    h3,
    h4,
    h5 {
      margin-top: 0;
      flex-grow: 4;
      font-weight: var(--sl-font-weight-semibold);
    }

    div[slot="actions"] {
      display: flex;
      justify-content: flex-end;
      flex-grow: 1;
    }
  
  `;
