import { SimpleObserver } from "@tedivo/tedivo-pure-helpers";

type OnOffLineObserverType = [isOnline: boolean];

class OnOffLineObserver extends SimpleObserver<OnOffLineObserverType> {
  public isOnline = globalThis.navigator.onLine;

  constructor() {
    super();

    globalThis.addEventListener("online", () => {
      this.update(true).notify();
      this.isOnline = true;
    });

    globalThis.addEventListener("offline", () => {
      this.update(false).notify();
      this.isOnline = false;
    });
  }
}

const onOffLineObserver = new OnOffLineObserver();

export { onOffLineObserver };
