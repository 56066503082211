import { removeChildren } from "@tedivo/tedivo-dom-helpers";
export class DictionaryDisplay extends HTMLElement {
    constructor() {
        super(...arguments);
        this.elementsInternal = [];
    }
    static getDisplayValue(el) {
        return String(el.value);
    }
    get elements() {
        return this.elementsInternal;
    }
    set elements(v) {
        this.elementsInternal = v;
        this.drawElements();
    }
    connectedCallback() {
        this.drawElements();
    }
    drawElements() {
        // Remove previous
        removeChildren(this);
        // Holder
        const holder = document.createElement("section");
        holder.className = "dictionary-display";
        const docFrag = document.createDocumentFragment();
        // Elements
        this.elements.filter(Boolean).forEach((el) => {
            const li = document.createElement("div"), label = document.createElement("span"), value = document.createElement("span");
            const text = DictionaryDisplay.getDisplayValue(el);
            label.className = "dd-label";
            label.innerHTML = el.label;
            value.className = "dd-value";
            value.ariaLabel = el.label;
            value.title = `${el.label}: ${text}`;
            value.innerHTML = text;
            if (el.className)
                li.className = el.className;
            li.appendChild(label);
            li.appendChild(value);
            if (el.breakBefore) {
                const breakDiv = document.createElement("div");
                breakDiv.className = "break";
                docFrag.appendChild(breakDiv);
            }
            docFrag.appendChild(li);
        });
        this.appendChild(docFrag);
    }
}
DictionaryDisplay.observedAttributes = [];
customElements.define("dictionary-display-element", DictionaryDisplay);
