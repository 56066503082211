/* eslint-disable @typescript-eslint/no-explicit-any */

import { RecursiveKeyOf } from "@tedivo/tedivo-pure-helpers";

export function safeSet<T>(obj: T, key: RecursiveKeyOf<T>, value: unknown) {
  const parts = key.split(".");

  if (parts.length === 0) {
    return;
  } else if (parts.length === 1) {
    (obj as any)[key] = value;
  } else {
    const immediatePart = parts.shift();

    if (immediatePart === undefined) return;

    if ((obj as any)[immediatePart] === undefined)
      (obj as any)[immediatePart] = {};

    safeSet((obj as any)[immediatePart], parts.join("."), value);
  }
}
