import { addStyles } from "../../helpers/addStyles";
import { removeChildren } from "@tedivo/tedivo-dom-helpers";
export class TfRadioGroup extends HTMLElement {
    constructor() {
        super();
        this.internalRadios = {};
        this.itemsInfo = [];
        this.attached = false;
        this.internalValue = undefined;
        this.internalLabel = undefined;
        this.internalHelpText = undefined;
        this.internalDisabled = false;
        this.internalFieldset = false;
        this.internalSingleColumn = false;
        this.isNumericEnum = false;
        this.onCheckboxClicked = (ev) => {
            const target = ev.target;
            this.internalValue = target.value;
            // this.dispatchEvent(new Event("sl-change"));
        };
        this.internalRadioGroup = document.createElement("sl-radio-group");
        this.internalRadioGroup.className = "sl-radio-group";
        this.internalRadioGroup.setAttribute("exportparts", "form-control form-control-label form-control-input help-text");
        this.internalRadioGroup.addEventListener("sl-change", this.onCheckboxClicked, false);
    }
    get style() {
        return this.internalRadioGroup.style;
    }
    get name() {
        return this.internalRadioGroup.id || "";
    }
    set name(n) {
        this.setAttribute("id", n);
        this.setAttribute("name", n);
        this.internalRadioGroup.id = n;
    }
    get label() {
        return this.internalLabel;
    }
    set label(v) {
        var _a;
        this.internalLabel = v;
        this.internalRadioGroup.label = `${(_a = this.internalLabel) !== null && _a !== void 0 ? _a : ""} ${this.internalHelpText ? ` (${this.internalHelpText})` : ""}`;
    }
    get helpText() {
        return this.internalHelpText;
    }
    set helpText(v) {
        var _a;
        this.internalHelpText = v;
        this.internalRadioGroup.label = `${(_a = this.internalLabel) !== null && _a !== void 0 ? _a : ""} ${this.internalHelpText ? ` (${this.internalHelpText})` : ""}`;
    }
    get singleColumn() {
        return this.internalSingleColumn;
    }
    set singleColumn(v) {
        this.internalSingleColumn = v;
        if (v && !this.internalRadioGroup.classList.contains("single-column"))
            this.internalRadioGroup.classList.add("single-column");
        else
            this.internalRadioGroup.classList.remove("single-column");
    }
    get tabIndex() {
        return this.internalRadioGroup.tabIndex;
    }
    set tabIndex(v) {
        this.internalRadioGroup.tabIndex = v;
    }
    get size() {
        return "medium";
    }
    set size(_s) {
        //Nothings
    }
    get fieldset() {
        return this.internalFieldset;
    }
    set fieldset(v) {
        if (v && !this.internalRadioGroup.classList.contains("with-fieldset"))
            this.internalRadioGroup.classList.add("with-fieldset");
        else
            this.internalRadioGroup.classList.remove("with-fieldset");
        this.internalFieldset = v;
    }
    get items() {
        return this.itemsInfo;
    }
    set items(v) {
        this.itemsInfo = v;
        if (!this.attached)
            return;
        removeChildren(this.internalRadioGroup);
        this.internalRadios = {};
        v.forEach((itemInfo) => {
            const radio = document.createElement("sl-radio");
            radio.innerHTML = itemInfo.label;
            radio.value = String(itemInfo.value);
            this.internalRadioGroup.appendChild(radio);
            this.internalRadios[String(itemInfo.value)] = radio;
        });
        if (this.internalValue !== undefined) {
            this.setOption(String(this.internalValue));
        }
        this.disabled = this.internalDisabled;
    }
    get value() {
        return this.isNumericEnum
            ? Number(this.internalValue)
            : String(this.internalValue);
    }
    set value(s) {
        this.internalValue = s;
        this.setOption(String(s));
    }
    get valueAsNumber() {
        return Number(this.internalValue);
    }
    set valueAsNumber(s) {
        this.internalValue = Number(s);
        this.setOption(String(s));
    }
    get disabled() {
        return this.internalDisabled;
    }
    set disabled(s) {
        this.internalDisabled = s;
        Object.keys(this.internalRadios).forEach((rad) => {
            this.internalRadios[rad].disabled = s;
        });
    }
    focus() {
        if (this.itemsInfo) {
            this.internalRadios[String(this.itemsInfo[0].value)].focus();
        }
    }
    connectedCallback() {
        this.attached = true;
        const shadow = this.attachShadow({ mode: "open" });
        shadow.appendChild(addStyles(styles));
        shadow.appendChild(this.internalRadioGroup);
        this.items = this.itemsInfo;
    }
    disconnectedCallback() {
        this.attached = false;
    }
    setOption(value) {
        if (!this.attached)
            return;
        this.internalRadioGroup.value = value;
    }
    attributeChangedCallback(attrName, oldVal, newVal) {
        switch (attrName) {
            case "class":
                if (newVal.indexOf("has-error") >= 0) {
                    if (!this.internalRadioGroup.classList.contains("has-error"))
                        this.internalRadioGroup.classList.add("has-error");
                }
                else {
                    this.internalRadioGroup.classList.remove("has-error");
                }
        }
    }
}
TfRadioGroup.observedAttributes = ["class"];
customElements.define("tf-radio-group", TfRadioGroup);
const styles = `
 
  sl-radio { 
    display: inline-flex;
    margin-right: 2em;
  }

   sl-radio:last-child { 
    margin-right: 1em;
  }

  .with-fieldset::part(form-control) {
    border: solid var(--sl-panel-border-width) var(--sl-panel-border-color);
    border-radius: var(--sl-border-radius-medium);
    padding: var(--sl-spacing-large);
    padding-top: var(--sl-spacing-x-small);
  }

  :not(.with-fieldset)::part(form-control-input) {
    height: calc(var(--sl-input-height-medium) - var(--sl-input-border-width) * 2);
    display: flex;
    align-items: center;
  }
  
  .with-fieldset::part(form-control-label) {
    font-family: var(--sl-input-font-family);
    font-size: var(--sl-input-font-size-medium);
    font-weight: var(--sl-input-font-weight);
    color: var(--sl-input-label-color);
    padding: 0;
    margin-bottom: var(--sl-spacing-small) !important;
  }

  .single-column::part(form-control-input) {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }
`;
