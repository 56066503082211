import { __awaiter } from "tslib";
import { GRID_OPTIONS_BY_CELL_DIMENSION, GRID_OPTIONS_BY_CELL_GAP, } from "./gridConfigConstants";
import { createTiersFromConfig, } from "@tedivo/tedivo-bay-grid-pure";
import { createGridFromConfig, } from "@tedivo/tedivo-bay-grid-core";
import { IntersectStatusEnum, createObserverWithCallback, removeChildren, } from "@tedivo/tedivo-dom-helpers";
import SizeSmallMidLargeEnum from "../../../../../app/enums/SizeSmallMidLargeEnum";
import { getHeaderDetailPills } from "./getHeaderDetailPills";
const EXTRA_SPACE_TOP = 60;
class BayBoxComponent extends HTMLElement {
    constructor() {
        super();
        this.data = undefined;
        this.nodesOptions = undefined;
        this._scale = 1;
        this.createGridOfBay = (bayBox) => {
            return () => {
                var _a, _b;
                if (!this.data)
                    return;
                const { sizeSummary, shipData, lidData, bays, isoBay, bayAbove, bayBelow, enabledCells, slotsDataAbove, slotsDataBelow, slotsData, width, height, ssMaxRow, cellSize, cellSeparation, validationResult, } = this.data;
                this._holderIntersectionStatus = IntersectStatusEnum.Rendered;
                console.time(`createGridFromConfig ${isoBay}`);
                const top = document.createElement("div");
                top.setAttribute("part", "top");
                const h3 = document.createElement("h3");
                h3.setAttribute("part", "title");
                h3.innerHTML = bays.map((b, idx) => (idx === 0 ? b : `(${b})`)).join(" ");
                top.appendChild(h3);
                const editBtn = (_a = this.createEditBayButtonFunction) === null || _a === void 0 ? void 0 : _a.call(this, isoBay, sizeSummary, shipData, bayAbove, bayBelow, enabledCells, slotsDataAbove, slotsDataBelow);
                if (editBtn)
                    top.appendChild(editBtn);
                bayBox.appendChild(top);
                bayBox.appendChild(getHeaderDetailPills(bayAbove, bayBelow, validationResult));
                /** In case we need to show as disabled the 00 row column for some bays */
                const prohibitedRow00 = sizeSummary.centerLineRow
                    ? get00RowCells(sizeSummary, !(bayAbove === undefined || (bayAbove === null || bayAbove === void 0 ? void 0 : bayAbove.centerLineRow)), !(bayBelow === undefined || (bayBelow === null || bayBelow === void 0 ? void 0 : bayBelow.centerLineRow)))
                    : [];
                const { svgGroup, symbols, cellPositions } = createGridFromConfig(Object.assign(Object.assign(Object.assign(Object.assign({}, GRID_OPTIONS_BY_CELL_DIMENSION[cellSize]), GRID_OPTIONS_BY_CELL_GAP[cellSeparation]), this.data.sizeSummary), { maxRow: ssMaxRow, centerLineRow: (bayAbove === null || bayAbove === void 0 ? void 0 : bayAbove.centerLineRow) || (bayBelow === null || bayBelow === void 0 ? void 0 : bayBelow.centerLineRow) ? 1 : 0, nodesOptions: this.nodesOptions || {}, enabledCells, prohibitedCells: prohibitedRow00, cellsDrawFunction: (_b = this.createFnSlotCell) === null || _b === void 0 ? void 0 : _b.call(this, cellSize), cellsToDraw: slotsData, lidData }));
                const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
                svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
                svg.setAttribute("width", `${width}px`);
                svg.setAttribute("height", `${height}px`);
                svg.setAttribute("viewBox", `0 0 ${width} ${height}`);
                svg.setAttribute("part", "svg-bay");
                svg.setAttribute("role", "figure");
                if (symbols)
                    Object.keys(symbols).forEach((n) => svg.appendChild(symbols[n]));
                svg.appendChild(svgGroup);
                console.timeEnd(`createGridFromConfig ${isoBay}`);
                bayBox.appendChild(svg);
                if (cellSize === SizeSmallMidLargeEnum.XLARGE && this.drawStackWeights)
                    bayBox.appendChild(this.drawStackWeights({
                        bayAbove,
                        bayBelow,
                        cellPositions,
                        cellSize,
                        cellPadding: cellSeparation,
                    }));
            };
        };
        this._holderIntersectionStatus = IntersectStatusEnum.Initialized;
        this.bayBox = document.createElement("div");
        this.bayBox.setAttribute("part", "box");
        this.attachShadow({ mode: "open" });
    }
    get holderIntersectionStatus() {
        return this._holderIntersectionStatus;
    }
    get scale() {
        return this._scale;
    }
    set scale(value) {
        var _a, _b;
        if (value === this._scale)
            return;
        this._scale = value;
        this.setSize(((_a = this.data) === null || _a === void 0 ? void 0 : _a.width) || 0, ((_b = this.data) === null || _b === void 0 ? void 0 : _b.height) || 0);
    }
    setSize(width, height) {
        const bayBox = this.bayBox;
        bayBox.style.width = `${this._scale * width}px`;
        bayBox.style.height = `${this._scale * height + EXTRA_SPACE_TOP}px`;
        if (this._scale === 1) {
            bayBox.style.transform = ``;
        }
        else {
            bayBox.style.transform = `scale(${this._scale})`;
        }
        return this;
    }
    setData(data) {
        return __awaiter(this, void 0, void 0, function* () {
            this.setAttribute("data-bay", data.isoBay);
            this.data = data;
            this.drawWithObserver(this.shadowRoot || null);
            return this;
        });
    }
    setDrawingColors(nodesOptions) {
        this.nodesOptions = nodesOptions;
        return this;
    }
    setDrawingProps({ createEditBayButtonFunction, createFnSlotCell, drawStackWeights, }) {
        this.createFnSlotCell = createFnSlotCell;
        this.createEditBayButtonFunction = createEditBayButtonFunction;
        this.drawStackWeights = drawStackWeights;
        return this;
    }
    /** Prepares the box to be drawn when visible */
    drawWithObserver(root) {
        var _a;
        if (!((_a = this.data) === null || _a === void 0 ? void 0 : _a.isoBay) || !root) {
            return;
        }
        const bayBox = this.bayBox;
        removeChildren(bayBox);
        if (this.holderIntersectionStatus ===
            IntersectStatusEnum.WaitingForIntersection) {
            return;
        }
        this._holderIntersectionStatus = IntersectStatusEnum.WaitingForIntersection;
        const ioObserver = createObserverWithCallback(this.createGridOfBay(bayBox), { delay: 1 });
        ioObserver.observe(bayBox);
        root.appendChild(bayBox);
    }
    updateData(newData) {
        this.data = Object.assign(Object.assign({}, this.data), newData);
        if (this.shadowRoot) {
            removeChildren(this.bayBox);
            this.drawWithObserver(this.shadowRoot);
        }
        return this;
    }
    connectedCallback() {
        const shadowRoot = this.shadowRoot;
        this.drawWithObserver(shadowRoot);
    }
}
export default BayBoxComponent;
customElements.define("bay-box-component", BayBoxComponent);
function get00RowCells(sizeSummary, aboveShouldHide00 = false, belowShouldHide00 = false) {
    const tiers = [
        ...(aboveShouldHide00
            ? createTiersFromConfig(sizeSummary.minAboveTier, sizeSummary.maxAboveTier)
            : []),
        ...(belowShouldHide00
            ? createTiersFromConfig(sizeSummary.minBelowTier, sizeSummary.maxBelowTier)
            : []),
    ];
    return tiers.map((tier) => `00|${tier}`);
}
