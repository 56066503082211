import { feetToMillimeters, roundDec } from "@tedivo/tedivo-pure-helpers";

import { IVesselOneBaySlotCrane } from "@tedivo/tedivo-bay-grid-pure";

const CRANE_TOP = 2400;
const CRANE_SMOKE = 2000;
const FLOOR_HEIGHT = 2400;

export function drawSideCrane(
  bridge: IVesselOneBaySlotCrane,
  midVcg: number,
  svgSizeRatio: number,
): SVGPathElement[] {
  const mainPath = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "path",
  );

  mainPath.setAttribute("stroke", "#888");
  mainPath.setAttribute("fill", "#fff");
  mainPath.setAttribute("stroke-width", "1");
  mainPath.setAttribute("fill-opacity", "0.2");
  mainPath.setAttribute("data-type", bridge.type);
  mainPath.setAttribute("data-id", bridge.id);

  const result: SVGPathElement[] = [mainPath];

  const w2 = roundDec(feetToMillimeters(bridge.len) * 0.5 * 0.3, 2);

  const w2Svg = w2 * svgSizeRatio;
  const top = roundDec(CRANE_TOP * svgSizeRatio);
  const snokeBelow = CRANE_SMOKE * svgSizeRatio;

  const heightOfBridge = roundDec(midVcg / svgSizeRatio, 1) - FLOOR_HEIGHT;
  const floors = Math.floor(heightOfBridge / FLOOR_HEIGHT);

  mainPath.setAttribute(
    "d",
    `M${-w2Svg},${top + snokeBelow} 
     L${-w2Svg},${midVcg} 
     L${w2Svg},${midVcg} 
     L${w2Svg},${top + snokeBelow}
     L${-w2Svg},${top + snokeBelow}`,
  );

  {
    const ceiling = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "path",
    );

    ceiling.setAttribute("stroke", "#888");
    ceiling.setAttribute("fill", "#999");
    ceiling.setAttribute("stroke-width", "1");
    ceiling.setAttribute("fill-opacity", "1");

    ceiling.setAttribute(
      "d",
      `M${-w2Svg * 2},${top} L${-w2Svg * 2},${top + snokeBelow} L${w2Svg * 2},${
        top + snokeBelow
      } L${w2Svg * 2},${top} L${-w2Svg * 2},${top}`,
    );

    result.push(ceiling);

    const crossesPoints: string[] = [];

    for (let j = 0; j < floors; j++) {
      const y = top + snokeBelow + j * FLOOR_HEIGHT * svgSizeRatio;

      if (y + FLOOR_HEIGHT * svgSizeRatio > midVcg) {
        break;
      }

      crossesPoints.push(
        `M${-w2Svg},${y} 
         L${w2Svg},${y + FLOOR_HEIGHT * svgSizeRatio}`,
      );

      crossesPoints.push(
        `M${w2Svg},${y} 
         L${-w2Svg},${y + FLOOR_HEIGHT * svgSizeRatio}`,
      );
    }

    const crosses = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "path",
    );

    crosses.setAttribute("stroke", "#888");
    crosses.setAttribute("stroke-width", "1");
    crosses.setAttribute("fill-opacity", "0");
    crosses.setAttribute("d", crossesPoints.join(" "));

    result.push(crosses);
  }

  return result;
}
