import { pad2, sortNumericDesc } from "@tedivo/tedivo-pure-helpers";

import { ITierPattern } from "./types/IPositionPatterns";

export function createTiersFromConfig(
  minTier: number | ITierPattern | undefined,
  maxTier: number | ITierPattern | undefined,
  sortAsc = true,
): Array<ITierPattern> {
  if (minTier === undefined || maxTier === undefined) return [];

  const iMinTier = Number(minTier);
  const iMaxTier = Number(maxTier);
  const tiers: Array<ITierPattern> = [];
  for (let i = iMinTier; i <= iMaxTier; i += 2) {
    tiers.push(pad2(i));
  }

  if (!sortAsc) {
    return tiers.sort(sortNumericDesc);
  }

  return tiers;
}
