import {
  IReturnDrawFunction,
  createSlotCapabilitiesSvg,
} from "@tedivo/tedivo-bay-grid-core";

import { ISlotData } from "open-vessel-definition";
import { SLOT_OPTIONS_BY_CELL_DIMENSION } from "./gridConfigConstants";
import SizeSmallMidLargeEnum from "../../../../../app/enums/SizeSmallMidLargeEnum";
import { slotIdJoinedToMixed } from "@tedivo/tedivo-bay-grid-pure";

export function createFnSlotCell(cellSize: SizeSmallMidLargeEnum) {
  return function createSlotCell(slotData: ISlotData): IReturnDrawFunction {
    const svgG = createSlotCapabilitiesSvg({
      ...SLOT_OPTIONS_BY_CELL_DIMENSION[cellSize],
      slotData,
      centered: true,
    });

    return {
      node: svgG,
      pos: slotIdJoinedToMixed(slotData.pos),
    };
  };
}
