import {
  ICreateGridFromConfigProps,
  ISvgWithSize,
} from "../../types/ICreateBayGridBaseProps";
import { IRowPattern, sortRowsArray } from "@tedivo/tedivo-bay-grid-pure";

import createBayGrid from "./createBayGrid";

/**
 * Creates a grid (above & below decks) from parameters
 * @returns ISvgWithSize { svg, width, height }
 */
export function createGridFromConfig<U>({
  minAboveTier,
  maxAboveTier,
  minBelowTier,
  maxBelowTier,
  centerLineRow,
  maxRow,
  ...gridOptions
}: ICreateGridFromConfigProps<U>): ISvgWithSize {
  let aboveTiersArray: Array<IRowPattern> | undefined = undefined;
  let belowTiersArray: Array<IRowPattern> | undefined = undefined;

  const minRow = centerLineRow ? 0 : 1;

  if (Number(maxRow) < minRow) {
    throw { message: `tedivoGridFromConfig: maxRow must be >= ${minRow}` };
  }

  // Rows
  const rowsArray = new Array(Number(maxRow) + (centerLineRow ? 1 : 0))
    .fill(1)
    .map((_, idx) => String(idx + minRow).padStart(2, "0") as IRowPattern)
    .sort(sortRowsArray);

  // Above Tiers
  if (minAboveTier !== undefined && maxAboveTier !== undefined) {
    const from = minAboveTier,
      to = maxAboveTier;

    aboveTiersArray = [];
    for (let i = from; i <= to; i += 2)
      aboveTiersArray.push(String(i).padStart(2, "0") as IRowPattern);
  }

  // Below Tiers
  if (minBelowTier !== undefined && maxBelowTier !== undefined) {
    const from = minBelowTier,
      to = maxBelowTier;

    belowTiersArray = [];
    for (let i = from; i <= to; i += 2)
      belowTiersArray.push(String(i).padStart(2, "0") as IRowPattern);
  }

  if (aboveTiersArray === undefined && belowTiersArray === undefined) {
    throw { message: `tedivoGridFromConfig: no tiers to draw` };
  }

  return createBayGrid({
    ...gridOptions,
    aboveTiersArray: aboveTiersArray || [],
    belowTiersArray: belowTiersArray || [],
    rowsArray,
  });
}
