import {
  I20LcgsByBay,
  IBayPattern,
  IBaySizesAndCgs,
} from "@tedivo/tedivo-bay-grid-pure";

import { ForeAftEnum } from "open-vessel-definition";
import { createSvgText } from "./createSvgText";
import { handleDoubleClick } from "@tedivo/tedivo-dom-helpers";
import { sortNumericAsc } from "@tedivo/tedivo-pure-helpers";

interface ISetTopBayNumbersProps {
  blps: IBaySizesAndCgs[];
  lcgsBy20Bay: I20LcgsByBay;
  svgNode: SVGElement;
  xPos: (x: number, dec?: number) => number;
  fontColor: string | undefined;
  onDoubleClick?: (node: SVGElement | HTMLElement) => void;
}

export function setTopBayNumbers({
  blps,
  lcgsBy20Bay,
  svgNode,
  xPos,
  fontColor,
  onDoubleClick,
}: ISetTopBayNumbersProps): void {
  const blpsByBay: { [bay: IBayPattern]: IBaySizesAndCgs } = {};
  blps.forEach((blp) => {
    blpsByBay[blp.isoBay] = blp;
  });

  const allBays = Object.keys(lcgsBy20Bay).sort(
    sortNumericAsc,
  ) as IBayPattern[];

  allBays.forEach((bay) => {
    const x = lcgsBy20Bay[bay].lcg;

    if (x !== undefined) {
      const bayNumberSvgText = createSvgText({
        text: getDecoratedIsoNumber(blpsByBay[bay] || bay),
        x: xPos(x, 2),
        y: 6,
        fontSize: 8,
        textAnchor: "middle",
        textColor: fontColor || "#aaa",
      });

      // bayNumber.setAttribute("font-family", "Arial, Helvetica, sans-serif");
      bayNumberSvgText.dataset["bay"] = bay;
      svgNode.appendChild(bayNumberSvgText);

      if (onDoubleClick) {
        handleDoubleClick(bayNumberSvgText, onDoubleClick);
      }
    }
  });

  function getDecoratedIsoNumber(blp: IBaySizesAndCgs | string): string {
    if (typeof blp === "string") return blp;

    if (blp.pairedBay) {
      return blp.pairedBay === ForeAftEnum.FWD
        ? `${blp.isoBay}&middot;`
        : `&middot;${blp.isoBay}`;
    }
    return blp.isoBay;
  }
}
