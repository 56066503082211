import { __awaiter } from "tslib";
import "./my-cloud.component.scss";
import { createFilesList } from "./createFilesList";
import { getPreferencesValue, setPreferencesKeyAndValue, } from "@tedivo/tedivo-preferences";
import { getTranslation, i18nReactive } from "../../../app/i18/i18tn";
import { I18nComponentRegisterer } from "@tedivo/tedivo-i18";
import Services from "../../../app/services";
import globalStore from "../../../app/stores/globalStore";
import goSquared from "../../../app/tracking/goSquared";
import { removeChildren } from "@tedivo/tedivo-dom-helpers";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import { setAppTitle } from "../../../app/setAppTitle";
import topMessageElement from "../../layout/top-tools/getTopMessageElement";
export class TVDTheirCloudComponent extends HTMLElement {
    constructor() {
        super();
        this.orgId = undefined;
        this.sortOptions = {
            name: "name",
            ascending: true,
        };
        this.openFile = (id) => __awaiter(this, void 0, void 0, function* () {
            const state = router.currentState;
            const viewState = {
                source: "cloudOvdJson",
                cloudId: id,
                organizationId: this.orgId,
                organizationName: state.organizationName,
                impersonate: true,
            };
            goSquared.addEvent("Tedivo Admin Their-Cloud - View file");
            router.navigate(routeFns.ovdViewOnly(id), viewState);
        });
        this.saveSortKey = (sortFields) => {
            this.sortOptions = sortFields[0];
            setPreferencesKeyAndValue("myCloud-filesSort", sortFields[0]);
        };
        this.onError = (e) => {
            console.error(e);
            const errorEvent = typeof e === "string"
                ? new CustomEvent("customError", {
                    detail: {
                        errorCode: e,
                        message: "errors:errorHasOcurred",
                        translationKey: getTranslation(e),
                    },
                })
                : new CustomEvent("customError", {
                    detail: {
                        errorCode: e.errorCode,
                        message: "errors:errorHasOcurred",
                        translationKey: getTranslation(e.translationKey || e.message || "errors:errorHasOcurred"),
                    },
                });
            document.body.dispatchEvent(new CustomEvent("customError", errorEvent));
        };
        this.i18nCR = new I18nComponentRegisterer(i18nReactive);
        this.wrapper = document.createElement("div-spinner-element");
        this.mainBox = document.createElement("div");
        this.dialogHistory = document.createElement("sl-dialog");
        setAppTitle(getTranslation("general:organizations.organizations"));
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            goSquared.trackPage("Tedivo Admin Pages - Their-Cloud");
            goSquared.addEvent("Tedivo Admin Their-Cloud - Show page");
            this.dialogHistory.setAttribute("style", "--width: 80vw");
            this.dialogHistory.label = getTranslation("view:edit.fileHistory.title");
            this.mainBox.className = "oss-card";
            const wrapper = this.wrapper, h1 = document.createElement("h1");
            const mainBox = this.mainBox;
            wrapper.appendChild(h1);
            wrapper.appendChild(mainBox);
            this.appendChild(wrapper);
            const state = router.currentState;
            // Translations
            const i18nCR = this.i18nCR;
            i18nCR.addConsumer(topMessageElement.element, "general:appName", "innerHTML");
            i18nCR.addConsumer(h1, "general:cloud.myCloud", "innerHTML", {
                name: state.organizationName,
            });
            this.orgId = router.getRouteParams().orgId;
            const sortOptionsSavedStr = getPreferencesValue("myCloud-filesSort");
            if (sortOptionsSavedStr) {
                this.sortOptions = JSON.parse(sortOptionsSavedStr);
            }
            this.displayFiles(this.orgId);
        });
    }
    displayFiles(organizationId) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!organizationId) {
                router.navigate(routeFns.myCloud());
                return;
            }
            globalStore.touchLastUserInteraction();
            const wrapper = this.wrapper;
            const mainBox = this.mainBox;
            removeChildren(mainBox);
            wrapper.setLoading(true);
            const data = yield Services.files.getAllAdmin(organizationId);
            if (data.statusCode === 200)
                mainBox.appendChild(createFilesList({
                    data: data.data || [],
                    fileTags: undefined,
                    onDelete: undefined,
                    onOpen: this.openFile,
                    onSort: this.saveSortKey,
                    onTagsUpdated: undefined,
                    onTagSelected: undefined,
                    onFilter: undefined,
                    onClone: undefined,
                    onColumnSelectorChange: undefined,
                    onError: this.onError,
                    onRefreshList: undefined,
                    sortBy: this.sortOptions,
                    actionsDropdown: undefined,
                    dialogHistory: this.dialogHistory,
                    dialogTagFile: undefined,
                    dialogFileNotes: undefined,
                    isReadOnly: true,
                    organizationUsersBySub: undefined,
                }));
            wrapper.setLoading(false);
        });
    }
}
TVDTheirCloudComponent.observedAttributes = [];
customElements.define("tvd-their-cloud-component", TVDTheirCloudComponent);
