import "./VesselPartsEditorComponent.scss";

import {
  I20LcgsByBay,
  IGetBayLcgVcgAndPairingsResult,
  calculateSlotsForVesselOne,
  preCalculateCurrentVesselParts,
} from "@tedivo/tedivo-bay-grid-pure";
import { IActionVesselPart, partsReducer } from "./partsReducer";
import { IBayLevelData, IVesselParts } from "open-vessel-definition";

import { IBayLevelAdjustedBottomBase } from "@tedivo/tedivo-bay-grid-core";
import { drawSlotsNodes } from "./draw/drawSlotsNodes";
import { getTranslation } from "../../../../../../../app/i18/i18tn";
import { removeChildren } from "@tedivo/tedivo-dom-helpers";

export class TvdVesselPartsEditorComponent extends HTMLElement {
  private mainHolder: HTMLElement;

  private vesselPartsData: Array<IVesselParts> = [];
  private adjustedBottomBases: Array<IBayLevelAdjustedBottomBase> = [];
  private bls: IBayLevelData[] = [];
  private lcgVcgTcgAndPairings?: IGetBayLcgVcgAndPairingsResult;

  public onApplyChanges: (() => void) | undefined = undefined;

  constructor() {
    super();
    this.mainHolder = document.createElement("div");
  }

  connectedCallback() {
    const mainHolder = this.mainHolder;
    mainHolder.className = "bay-slots-editor";

    const h2 = document.createElement("h2");
    h2.innerHTML = getTranslation("view:view3D.editor");
    h2.className = "subtitle";

    this.appendChild(h2);
    this.appendChild(mainHolder);

    this.update();

    forceScrollSideWays(mainHolder);
  }

  setData({
    vesselPartsData,
    adjustedBottomBases,
    bls,
    lcgVcgTcgAndPairings,
  }: {
    vesselPartsData: Array<IVesselParts>;
    adjustedBottomBases?: Array<IBayLevelAdjustedBottomBase>;
    bls?: IBayLevelData[];
    lcgVcgTcgAndPairings?: IGetBayLcgVcgAndPairingsResult;
  }) {
    this.vesselPartsData = vesselPartsData;
    if (bls) this.bls = bls;
    if (lcgVcgTcgAndPairings) this.lcgVcgTcgAndPairings = lcgVcgTcgAndPairings;
    if (adjustedBottomBases) this.adjustedBottomBases = adjustedBottomBases;
  }

  update() {
    if (!this.lcgVcgTcgAndPairings) return;

    removeChildren(this.mainHolder);

    const preCalculatedSlots = preCalculateCurrentVesselParts(
      this.bls,
      this.vesselPartsData,
    );

    const lcgsBy20Bay = this.lcgVcgTcgAndPairings.lcgsBy20Bay as I20LcgsByBay;

    const vOneBaySlots = calculateSlotsForVesselOne(
      this.bls,
      preCalculatedSlots,
      lcgsBy20Bay,
    );

    this.mainHolder.appendChild(
      drawSlotsNodes({
        calculatedSlots: vOneBaySlots,
        lcgVcgTcgAndPairings: this.lcgVcgTcgAndPairings,
        applyChanges: this.applyChanges,
      }),
    );

    if (this.onApplyChanges) {
      this.onApplyChanges();
    }
  }

  applyChanges = (cmd: IActionVesselPart) => {
    const newDataPartsSlots = partsReducer(this.vesselPartsData, cmd);

    this.setData({ vesselPartsData: newDataPartsSlots });
    this.update();
  };

  getVesselParts(): Array<IVesselParts> {
    return this.vesselPartsData;
  }

  getAdjustedBottomBases(): Array<IBayLevelAdjustedBottomBase> {
    return this.adjustedBottomBases || [];
  }
}

customElements.define(
  "tvd-vesselparts-editor-component",
  TvdVesselPartsEditorComponent,
);

declare global {
  interface HTMLElementTagNameMap {
    "tvd-vesselparts-editor-component": TvdVesselPartsEditorComponent;
  }
}

function forceScrollSideWays(element: HTMLElement) {
  const CONSTANT_SCROLL = 60;
  element.addEventListener("wheel", (event) => {
    const [x, y] = [event.deltaX, event.deltaY];
    let magnitude = 0;

    if (x === 0) {
      event.preventDefault();
      magnitude = y > 0 ? CONSTANT_SCROLL : -CONSTANT_SCROLL;
      element.scrollBy({ left: magnitude });
    }
  });
}
