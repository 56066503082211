import {
  BayLevelEnum,
  ForeAftEnum,
  IBayLevelData,
  IMasterCGs,
  TContainerLengths,
  ValuesSourceEnum,
} from "open-vessel-definition";
import {
  IBayPattern,
  IRowPattern,
  ITierPattern,
  getRowsAndTiersFromSlotKeys,
  getSizesFromSlots,
  sortRowsArray,
} from "@tedivo/tedivo-bay-grid-pure";
import {
  IValidationInput,
  IValidationOutput,
  IValidator,
  TValidatorSpecialScopes,
} from "../types";

import { IJoinedRowTierPattern } from "open-vessel-definition/build/src/models/base/types/IPositionPatterns";
import { sortNumericAsc } from "@tedivo/tedivo-pure-helpers";

const name = "cgsValidator";
const okTranslationKey = "validators:cgs.cgsValidator.ok";
const errorTranslationKey = "validators:cgs.cgsValidator.error";

const specialScopes: TValidatorSpecialScopes[] = [];

/** Check if all bays with 40s are paired */
export const cgsValidator: IValidator<ICGsMissingOutput> = {
  name,
  okTranslationKey,
  errorTranslationKey,
  fn: cgsValidatorFn,
  specialScopes,
};

export function cgsValidatorFn({
  data: { json },
}: IValidationInput): IValidationOutput<ICGsMissingOutput> {
  const missingLCGs: IMissingBayLCG[] = [];
  const missingBulkheadLCGs: IMissingBayBulkheadLCG[] = [];

  const baysData = json.baysData;
  const masterCGs = json.shipData.masterCGs || ({} as IMasterCGs);

  const rowsAbove = calculateRowsFromSlots(
    baysData.filter((bl) => bl.level === BayLevelEnum.ABOVE),
  );
  const rowsBelow = calculateRowsFromSlots(
    baysData.filter((bl) => bl.level === BayLevelEnum.BELOW),
  );

  // 1. TCGs ----------------------------------------------
  // Check only for Master TCGs
  const masterAboveTCGs = rowsAbove.reduce((acc, row) => {
    if (masterCGs.aboveTcgs[row] === undefined) acc.push(row);
    return acc;
  }, [] as string[]);

  const masterBelowTCGs = rowsBelow.reduce((acc, row) => {
    if (masterCGs.belowTcgs[row] === undefined) acc.push(row);
    return acc;
  }, [] as string[]);

  // 2. VCGs ----------------------------------------------
  // Check only for Master Bottom Bases
  const minBases = new Set<ITierPattern>();
  baysData.forEach((bl) => {
    const perSlotInfo = bl.perSlotInfo;
    if (perSlotInfo !== undefined) {
      const { minTier } = getRowsAndTiersFromSlotKeys(
        Object.keys(perSlotInfo) as IJoinedRowTierPattern[],
      );
      if (minTier) minBases.add(minTier);
    }
  });

  const masterVCGs = Array.from(minBases)
    .filter((tier) => Number(tier) < 200)
    .reduce((acc, tier) => {
      if (masterCGs.bottomBases[tier] === undefined) acc.push(tier);
      return acc;
    }, [] as string[]);

  // 3. LCGs ----------------------------------------------
  baysData.forEach((bl) => {
    const perSlotInfo = bl.perSlotInfo;
    if (perSlotInfo !== undefined) {
      const sizes = getSizesFromSlots(perSlotInfo).sizes;
      sizes.forEach((size) => {
        if (bl.infoByContLength?.[size]?.lcg === undefined) {
          missingLCGs.push({
            bay: bl.isoBay,
            level: bl.level,
            size,
          });
        }
      });
    }

    const bulkhead = bl.bulkhead;

    if (bulkhead !== undefined) {
      if (bulkhead.aft && bulkhead.aftLcg === undefined)
        missingBulkheadLCGs.push({
          bay: bl.isoBay,
          level: bl.level,
          pos: ForeAftEnum.AFT,
        });
      if (bulkhead.fore && bulkhead.foreLcg === undefined)
        missingBulkheadLCGs.push({
          bay: bl.isoBay,
          level: bl.level,
          pos: ForeAftEnum.FWD,
        });
    }
  });

  const cgsAreKnown =
    json.shipData.lcgOptions.values === ValuesSourceEnum.KNOWN ||
    json.shipData.vcgOptions.values === ValuesSourceEnum.KNOWN ||
    json.shipData.tcgOptions.values === ValuesSourceEnum.KNOWN;

  const totalMissingCGs =
    masterAboveTCGs.length +
    masterBelowTCGs.length +
    masterVCGs.length +
    missingLCGs.length +
    missingBulkheadLCGs.length;

  const isValid = !cgsAreKnown || totalMissingCGs === 0;
  const messageTranslationKey = !cgsAreKnown
    ? "validators:cgs.cgsValidator.notNeeded"
    : totalMissingCGs === 0
    ? okTranslationKey
    : errorTranslationKey;

  return {
    name,
    isValid,
    messageTranslationKey,
    result: {
      validResults: [],
      invalidResults: [],
      summary: {
        masterAboveTCGs: masterAboveTCGs.sort(sortRowsArray),
        masterBelowTCGs: masterBelowTCGs.sort(sortRowsArray),
        masterVCGs: masterVCGs.sort(sortNumericAsc),
        missingLCGs,
        missingBulkheadLCGs,
        totalMissingCGs,
      },
    },
  };
}

function calculateRowsFromSlots(baysData: IBayLevelData[] = []) {
  const rows = new Set<IRowPattern>();
  baysData.forEach((bl) => {
    const rowsFromSlotsInfo = bl.perSlotInfo
      ? Object.keys(bl.perSlotInfo).map((s) => s.substring(0, 2))
      : [];
    rowsFromSlotsInfo.forEach((r) => rows.add(r as IRowPattern));
  });
  return Array.from(rows);
}

interface IMissingBayLCG {
  bay: IBayPattern;
  level: BayLevelEnum;
  size: TContainerLengths;
}

interface IMissingBayBulkheadLCG {
  bay: IBayPattern;
  level: BayLevelEnum;
  pos: ForeAftEnum;
}

export interface ICGsMissingOutput {
  validResults: never[];
  invalidResults: never[];
  summary: {
    masterAboveTCGs: string[];
    masterBelowTCGs: string[];
    masterVCGs: string[];
    missingLCGs: IMissingBayLCG[];
    missingBulkheadLCGs: IMissingBayBulkheadLCG[];
    totalMissingCGs: number;
  };
}
