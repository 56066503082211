import { __awaiter } from "tslib";
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import { createErrorNotification } from "../notifications/createNotification";
import { getTranslation } from "../i18/i18tn";
import { onOffLineObserver } from "../offline/observer/OnOffLineObserver";
import securityModule from "../security/SecurityModule";
class HttpClient {
    constructor() {
        this.baseUrl = (process.env.NX_PUBLIC_API_URL || "http://127.0.0.1:3000").replace(/$^\//, "");
        this.axiosInstance = axios.create();
    }
    request(_a) {
        return __awaiter(this, arguments, void 0, function* ({ method, url, data, }) {
            var _b, _c, _d, _e, _f, _g, _h;
            if (onOffLineObserver.isOnline === false) {
                onOffLineObserver.notify();
                return {
                    statusCode: 503,
                    code: "general:onOffLine.offline",
                    message: "The application is offline",
                    data: undefined,
                };
            }
            else {
                try {
                    const config = {};
                    if (securityModule.idToken) {
                        config.headers = {
                            Authorization: `Bearer ${securityModule.idToken}`,
                            "X-Tvd-Org-Id": securityModule.currentOrganizationId,
                        };
                    }
                    securityModule.xhrRequestFired();
                    const callUrl = `${this.baseUrl}/${url
                        .replace(/\/{2,4}/g, "/")
                        .replace(/^\//, "")}`;
                    let response;
                    let params = undefined;
                    switch (method) {
                        case "GET": {
                            if (data)
                                params = { id: data };
                            response = yield this.axiosInstance.get(callUrl, Object.assign(Object.assign({}, config), { params }));
                            break;
                        }
                        case "POST": {
                            response = yield this.axiosInstance.post(callUrl, data, config);
                            break;
                        }
                        case "PUT": {
                            response = yield this.axiosInstance.put(callUrl, data, config);
                            break;
                        }
                        case "DELETE": {
                            response = yield this.axiosInstance.delete(callUrl, Object.assign(Object.assign({}, config), { params: data }));
                            break;
                        }
                        default:
                            break;
                    }
                    const statusCode = (response === null || response === void 0 ? void 0 : response.status) || 400;
                    const responseModel = {
                        data: response === null || response === void 0 ? void 0 : response.data,
                        statusCode,
                    };
                    return responseModel;
                }
                catch (error) {
                    console.log(error);
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        if (((_b = error.response) === null || _b === void 0 ? void 0 : _b.status) === 401) {
                            securityModule.signOut();
                        }
                        else if (((_c = error.response) === null || _c === void 0 ? void 0 : _c.status) === 403) {
                            createErrorNotification("errors:notAllowed");
                            securityModule.dispatchEvent(new CustomEvent("httpCallProhibited"));
                        }
                        const errRes = error.response.data;
                        createErrorNotification(error === undefined
                            ? "errors:errorHasOcurred"
                            : typeof errRes === "string"
                                ? errRes
                                : getTranslation(errRes.translationKey ||
                                    errRes.message ||
                                    error.response.message ||
                                    "errors:errorHasOcurred"));
                        return {
                            statusCode: ((_d = error.response) === null || _d === void 0 ? void 0 : _d.status) || 400,
                            code: (_f = (_e = error.response) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.code,
                            message: (_h = (_g = error.response) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.message,
                        };
                    }
                    else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.log(error.request);
                        securityModule.dispatchEvent(new CustomEvent("ajaxError"));
                    }
                    else {
                        // Something happened in setting up the request that triggered an Error
                        console.log("Error", error.message);
                    }
                    return {
                        statusCode: 400,
                        code: "",
                        message: "Unknown error",
                        data: undefined,
                    };
                }
            }
        });
    }
}
export default HttpClient;
