import {
  CraneSideEnum,
  ForeAftEnum,
  IVesselPartBridge,
  IVesselPartCrane,
  IVesselPartSmokeStack,
  IVesselPartSpacer,
  IVesselParts,
  VesselPartTypeEnum,
} from "open-vessel-definition";
import {
  IGetBayLcgVcgAndPairingsResult,
  IVesselOneBaySlot,
} from "@tedivo/tedivo-bay-grid-pure";

import { IActionVesselPart } from "../../partsReducer";
import { PartType } from "../types";
import { calculateSpaceAvailableNext } from "../helpers/calculateSpaceAvailableNext";
import { createModalForBridge } from "./createModalForBridge";
import { createModalForCranes } from "./createModalForCranes";
import { createModalForSmokeStacks } from "./createModalForSmokeStacks";
import { createModalForSpacer } from "./createModalForSpacer";
import { createSimpleAlert } from "@tedivo/tedivo-ui";
import { getTranslation } from "../../../../../../../../../app/i18/i18tn";

/** Launches modals for creation, updation and deletion of parts */
export function launchPartLaunchModal({
  partType,
  original,
  slotRefId,
  posRef,
  currentLabels,
  calculatedSlots,
  lcgVcgTcgAndPairings,
  applyChanges,
}: {
  partType: PartType;
  original?: IVesselParts;
  slotRefId: string;
  posRef: ForeAftEnum;
  currentLabels: Record<string, string>;
  calculatedSlots: Array<IVesselOneBaySlot>;
  lcgVcgTcgAndPairings: IGetBayLcgVcgAndPairingsResult;
  applyChanges: (data: IActionVesselPart) => void;
}) {
  const spaceAvailableCalcFn = (
    slotRefId: string,
    posRef: ForeAftEnum,
    id: string | undefined,
  ) =>
    calculateSpaceAvailableNext({
      slotRefId,
      posRef,
      calculatedSlots,
      lcgVcgTcgAndPairings,
      includeLenOfId: id,
    });

  const spaceAvailable = spaceAvailableCalcFn(slotRefId, posRef, original?.id);

  if (spaceAvailable !== undefined) {
    if (spaceAvailable <= 0) {
      createSimpleAlert({
        title: getTranslation("general:common.error"),
        message: getTranslation("view:view3D.alerts.noSpaceAvailable"),
        closeLabel: getTranslation("general:common.accept"),
      });
      return;
    }
    if (spaceAvailable < 1000) {
      createSimpleAlert({
        title: getTranslation("general:common.attention"),
        message: getTranslation("view:view3D.alerts.notEnoughSpace"),
        closeLabel: getTranslation("general:common.accept"),
        onOk: () => {
          launch();
        },
      });
      return;
    }
  }

  launch();

  function launch() {
    switch (partType) {
      case "addBridge":
        createModalForBridge({
          bridgeData: {
            id: original?.id,
            label:
              original?.label ||
              `Bridge ${
                calculatedSlots.filter(
                  (s) => s.type === VesselPartTypeEnum.BRIDGE,
                ).length || ""
              }`.trim(),
            slotRefId: original?.slotRefId || slotRefId,
            posRef: original?.posRef || posRef,
            len: original?.len || 0,
            heatSrcBelow:
              (original as IVesselPartBridge)?.heatSrcBelow || false,
            type: VesselPartTypeEnum.BRIDGE,
          },
          currentLabels,
          calculatedSlots,
          maxLen: spaceAvailable,
          defaultLen: 14000,
          onSubmitAction: applyChanges,
          spaceAvailableCalcFn,
        });
        break;

      case "addCrane":
        createModalForCranes({
          craneData: {
            id: original?.id,
            label:
              original?.label ||
              `Cranes ${
                calculatedSlots.filter(
                  (s) => s.type === VesselPartTypeEnum.CRANE,
                ).length + 1
              }`,
            slotRefId: original?.slotRefId || slotRefId,
            posRef: original?.posRef || posRef,
            len: original?.len || 0,
            cranes: (original as IVesselPartCrane)?.cranes || [
              CraneSideEnum.PORT,
            ],
            type: VesselPartTypeEnum.CRANE,
          },
          currentLabels,
          calculatedSlots,
          maxLen: spaceAvailable,
          defaultLen: 6096,
          onSubmitAction: applyChanges,
          spaceAvailableCalcFn,
        });
        break;

      case "addSmoke":
        createModalForSmokeStacks({
          smokeStacksData: {
            id: original?.id,
            label:
              original?.label ||
              `Smoke Stack ${
                calculatedSlots.filter(
                  (s) => s.type === VesselPartTypeEnum.SMOKE,
                ).length || ""
              }`,
            slotRefId: original?.slotRefId || slotRefId,
            posRef: original?.posRef || posRef,
            len: original?.len || 0,
            numberOfSmokeStacks:
              (original as IVesselPartSmokeStack)?.numberOfSmokeStacks ?? 1,
            type: VesselPartTypeEnum.SMOKE,
          },
          currentLabels,
          calculatedSlots,
          maxLen: spaceAvailable,
          defaultLen: 6096,
          onSubmitAction: applyChanges,
          spaceAvailableCalcFn,
        });
        break;

      case "addSpacer":
        createModalForSpacer({
          spacerData: {
            id: original?.id,
            label:
              original?.label ||
              `Gap ${
                calculatedSlots.filter(
                  (s) => s.type === VesselPartTypeEnum.SPACER,
                ).length + 1
              }`,
            slotRefId: original?.slotRefId || slotRefId,
            posRef: original?.posRef || posRef,
            len: original?.len || 0,
            heatSrcBelow:
              (original as IVesselPartSpacer)?.heatSrcBelow || false,
            type: VesselPartTypeEnum.SPACER,
          },
          currentLabels,
          calculatedSlots,
          maxLen: spaceAvailable,
          defaultLen: 6096,
          onSubmitAction: applyChanges,
          spaceAvailableCalcFn,
        });
        break;
    }
  }
}
