export class LinkElement extends HTMLElement {
  public static observedAttributes = ["text", "url"];
  public static onClickLink: ((url: string) => void) | undefined = undefined;

  private aElement: HTMLAnchorElement = document.createElement("a");

  set text(value: string) {
    this.setAttribute("text", value);
  }

  get text() {
    return this.getAttribute("text") || "";
  }

  set url(value: string) {
    this.setAttribute("url", value);
  }

  get url() {
    return this.getAttribute("url") || "";
  }

  constructor() {
    super();
    if (!LinkElement.onClickLink) {
      console.warn("LinkElement.onClickLink is not set");
    }
  }

  connectedCallback() {
    const a = this.aElement;
    const url = this.url;

    a.innerHTML = this.text;
    a.href = url;
    a.setAttribute("data-url", url);

    a.onclick = (ev: Event) => {
      ev.preventDefault();
      const url = a.getAttribute("data-url");
      if (url && LinkElement.onClickLink) LinkElement.onClickLink(url);
      return false;
    };

    this.appendChild(a);
  }

  attributeChangedCallback(attrName: string) {
    switch (attrName) {
      case "text": {
        this.aElement.innerHTML =
          this.attributes.getNamedItem("text")?.value || "";
        break;
      }
      case "url": {
        const url = this.attributes.getNamedItem("url")?.value || "";
        this.aElement.href = url;
        this.aElement.setAttribute("data-url", url);
        break;
      }
    }
  }
}

customElements.define("link-element", LinkElement);

declare global {
  interface HTMLElementTagNameMap {
    "link-element": LinkElement;
  }
}
