import { ForeAftEnum, VesselPartTypeEnum } from "open-vessel-definition";
import {
  ITedivoShoelaceSelectProps,
  createSelectShoelace,
} from "@tedivo/tedivo-form";

import { IActionVesselPart } from "../partsReducer";
import { IGetBayLcgVcgAndPairingsResult } from "@tedivo/tedivo-bay-grid-pure";
import { IVesselOneBaySlot } from "../../../../../vessel3D/types/IVesselOneBaySlot";
import { PARTS_COLORS } from "./partsColors";
import { PartType } from "./types";
import { getTranslation } from "../../../../../../../../app/i18/i18tn";
import { launchPartLaunchModal } from "./modals/launchPartLaunchModal";

const commonOptions: PickCommonOptionsTedivoShoelace = {
  caret: false,
  ommitCheckSign: true,
  iconPrefix: "plus",
  buttonText: "",
  outlined: true,
  variant: "primary",
  size: "small",
  pill: true,
  hoist: true,
};

export function drawSlotsNodes({
  calculatedSlots,
  lcgVcgTcgAndPairings,
  applyChanges,
}: {
  calculatedSlots: Array<IVesselOneBaySlot>;
  lcgVcgTcgAndPairings: IGetBayLcgVcgAndPairingsResult;
  applyChanges: (data: IActionVesselPart) => void;
}): DocumentFragment {
  const mainHolder = document.createDocumentFragment();
  const vesselSlotsHolder = document.createElement("div");
  vesselSlotsHolder.className = "vessel-slots-holder";
  vesselSlotsHolder.dataset.bow = getTranslation("general:ship.bow");
  vesselSlotsHolder.dataset.stern = getTranslation("general:ship.stern");
  mainHolder.appendChild(vesselSlotsHolder);

  const editorActionOptions: { name: string; value: PartType }[] = [
    {
      name: getTranslation("view:view3D.actions.addBridge"),
      value: "addBridge",
    },
    { name: getTranslation("view:view3D.actions.addCrane"), value: "addCrane" },
    {
      name: getTranslation("view:view3D.actions.addSmokeStack"),
      value: "addSmoke",
    },
    {
      name: getTranslation("view:view3D.actions.addSpacer"),
      value: "addSpacer",
    },
  ];

  const currentLabels = calculatedSlots.reduce((acc, slot) => {
    acc[slot.id] = slot.label;
    return acc;
  }, {} as Record<string, string>);

  const last = calculatedSlots.length - 1;

  calculatedSlots
    .slice()
    .reverse()
    .forEach((slot, idx) => {
      const slotHolder = document.createElement("div");
      const slotBox = document.createElement("div");

      slotHolder.className = `slot-holder ${
        slot.type === VesselPartTypeEnum.BAY ? "bay" : ""
      }`;
      slotBox.className = `slot-box`;
      slotBox.style.borderColor = `var(${PARTS_COLORS[slot.type]})`;

      const slotActionAft = createSelectShoelace<PartType>({
        ...commonOptions,
        id: `${idx}-fwd`,
        options: editorActionOptions,
        onChange: (partType) => {
          launchPartLaunchModal({
            partType,
            slotRefId: slot.id,
            posRef: ForeAftEnum.AFT,
            currentLabels,
            calculatedSlots,
            lcgVcgTcgAndPairings,
            applyChanges,
          });
        },
      });

      slotHolder.appendChild(slotActionAft);
      slotHolder.appendChild(slotBox);

      if (slot.type !== VesselPartTypeEnum.BAY) {
        const editBnt = document.createElement("sl-icon-button");
        editBnt.className = "part-slot-edit-btn";
        editBnt.name = "pencil";
        editBnt.addEventListener("click", () => {
          launchPartLaunchModal({
            partType:
              slot.type === VesselPartTypeEnum.BRIDGE
                ? "addBridge"
                : slot.type === VesselPartTypeEnum.CRANE
                ? "addCrane"
                : slot.type === VesselPartTypeEnum.SMOKE
                ? "addSmoke"
                : "addSpacer",
            original: slot.original,
            slotRefId: slot.original.slotRefId,
            posRef: slot.original.posRef,
            currentLabels,
            calculatedSlots,
            lcgVcgTcgAndPairings,
            applyChanges,
          });
        });

        slotBox.innerHTML = slot.label;
        slotBox.appendChild(editBnt);
      } else {
        slotBox.innerHTML = slot.label.split("-").join("<br>");
      }

      if (idx === last) {
        const slotActionFwd = createSelectShoelace<PartType>({
          ...commonOptions,
          id: `${idx}-aft`,
          options: editorActionOptions,
          onChange: (partType) => {
            launchPartLaunchModal({
              partType,
              slotRefId: slot.id,
              posRef: ForeAftEnum.FWD,
              currentLabels,
              calculatedSlots,
              lcgVcgTcgAndPairings,
              applyChanges,
            });
          },
        });

        slotHolder.appendChild(slotActionFwd);
      }

      vesselSlotsHolder.appendChild(slotHolder);
    });

  return mainHolder;
}

type PickCommonOptionsTedivoShoelace = Pick<
  ITedivoShoelaceSelectProps<PartType>,
  | "caret"
  | "ommitCheckSign"
  | "iconPrefix"
  | "buttonText"
  | "outlined"
  | "variant"
  | "size"
  | "pill"
  | "hoist"
>;
