import { BayLevelEnum, ForeAftEnum, } from "open-vessel-definition";
import { CONTAINER_HEIGHT_IN_MMM, SEPARATION_IN_BETWEEN, generateCoordFunctions, } from "@tedivo/tedivo-bay-grid-pure";
import { generateSideContainerSymbols, } from "./generateSideContainerSymbols";
import { feetToMillimeters, roundDec, sortNumericAsc, sortNumericDesc, } from "@tedivo/tedivo-pure-helpers";
import { createSvgPath } from "../helpers/createSvgPath";
import { drawSideBridge } from "./drawParts/drawSideBridge";
import { drawSideCrane } from "./drawParts/drawSideCrane";
import { drawSideSmokeStack } from "./drawParts/drawSideSmokeStack";
import { setTopBayNumbers } from "../helpers/setTopBayNumbers";
export const SVG_WIDTH = 1500;
export const DISTANCE_TO_CONTAINER_FOR_SHIP_LINE = 4000;
export function createSimpleSideView({ sizeSummary, baysData, lcgVcgTcgAndPairings, symbolsOptions, vesselPartsData = [], adjustedBottomBases = [], bayNumbersDoubleClickAction, }) {
    const { bayLevelPositionsAbove, bayLevelPositionsBelow, missingImportantVcgs, missingImportantLcgs, missingImportantTcgs, isoBaysArray, maxIsoBay, minLcg, maxLcg, minVcg, maxVcg, minTcg, maxTcg, totalSlotsCount, lcgsBy20Bay, pairedBaysCalc: pairedBays, deckBottomBases, blockBaysAndSizes, } = lcgVcgTcgAndPairings;
    const missingImportantXcgs = missingImportantTcgs || missingImportantLcgs || missingImportantVcgs;
    // 1. Add missing LCGs and VCGs if needed, return x,y functions
    const coordFns = generateCoordFunctions({
        maxIsoBay,
        sizeSummary,
        baysData,
        vesselPartsData,
        missingImportantXcgs,
        bayLevelPositionsAbove,
        bayLevelPositionsBelow,
        maxLcg,
        minLcg,
        maxVcg,
        minVcg,
        maxTcg,
        minTcg,
        lcgNormalization: SVG_WIDTH,
        lcgsBy20Bay,
        blockBaysAndSizes,
    });
    if (!coordFns) {
        throw new Error("Could not generate coord functions");
    }
    const { xRange, yRange, xPos, yPos, scaled, addX, svgSizeRatio, lastBaySizeInMM, bowLength, sternLength, allPartsLengthInMeters, vOneBaySlots, } = coordFns;
    const adjustedBottomBasesDict = missingImportantVcgs
        ? adjustedBottomBases.reduce((acc, bb) => {
            bb.bays.forEach((b) => {
                acc[`${b}-${bb.level}`] = bb.bottomBase;
            });
            return acc;
        }, {})
        : {};
    const sameBaySLps = createSameBayAbBeSizesAndCgs(bayLevelPositionsAbove, bayLevelPositionsBelow, adjustedBottomBasesDict, deckBottomBases);
    // 2. SVG Node
    const svgNode = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    if (xRange)
        svgNode.setAttribute("width", String(xRange));
    if (yRange)
        svgNode.setAttribute("height", String(yRange));
    if (xRange && yRange)
        svgNode.setAttribute("viewBox", `0 0 ${xRange} ${yRange}`);
    svgNode.setAttribute("data-scale", String(roundDec(svgSizeRatio, 5)));
    // 3. Add symbols
    const symbols = generateSideContainerSymbols(symbolsOptions || {}, svgSizeRatio);
    const symbolsNames = Object.keys(symbols);
    symbolsNames.forEach((key) => {
        svgNode.appendChild(symbols[key]);
    });
    // 4. Top bay numbers
    const drawTopBayNumbers = (blps) => setTopBayNumbers({
        blps,
        lcgsBy20Bay,
        svgNode,
        xPos,
        fontColor: symbolsOptions && symbolsOptions.fontColor,
        onDoubleClick: bayNumbersDoubleClickAction,
    });
    if (bayLevelPositionsAbove.length)
        drawTopBayNumbers(bayLevelPositionsAbove);
    else
        drawTopBayNumbers(bayLevelPositionsBelow);
    let svgBayLevelGroups = [];
    if (totalSlotsCount > 0) {
        // 5. Ship line
        const midVcg = drawShipLine();
        drawVesselParts(midVcg);
        // 6. Draw containers
        svgBayLevelGroups = drawAllContainers(bayLevelPositionsAbove, bayLevelPositionsBelow, pairedBays, adjustedBottomBasesDict);
        // 7. Draw bulkheads
        drawBulkheads(xRange);
        //_helperDraw();
    }
    return {
        sideViewSvg: svgNode,
        missingImportantXcgs,
        xRange,
        yRange,
        svgSizeRatio,
        yPos,
        svgBayLevelGroups,
    };
    function drawAllContainers(bayLevelPositionsAbove, bayLevelPositionsBelow, calculatedPairedBays, adjustedBottomBasesDict) {
        const svgGroups = [];
        [
            ...calculatedPairedBays.pairedBays,
            ...calculatedPairedBays.unpairedBays,
        ].forEach((pb) => {
            const blps = (pb.level === BayLevelEnum.ABOVE
                ? bayLevelPositionsAbove
                : bayLevelPositionsBelow).filter((blp) => pb.allBays.indexOf(blp.isoBay) >= 0);
            const svgGroupResp = createSvgGroup(blps, adjustedBottomBasesDict);
            if (!svgGroupResp)
                return;
            svgGroups.push(svgGroupResp);
            drawContainers(svgGroupResp.svgGroup, svgGroupResp.minX, missingImportantXcgs
                ? svgGroupResp.minY
                : svgGroupResp.minYforGroup || 0, blps);
            svgNode.appendChild(svgGroupResp.svgGroup);
        });
        return svgGroups;
        function createSvgGroup(blps, adjustedBottomBasesDict) {
            var _a, _b, _c, _d, _e, _f;
            let minX = Infinity, maxX = -Infinity, minY = Infinity, minYforGroup = Infinity, maxY = -Infinity, maxTierY = -Infinity, minTierY = Infinity;
            const adjustBB = (_f = (_c = adjustedBottomBasesDict[`${(_a = blps[0]) === null || _a === void 0 ? void 0 : _a.isoBay}-${(_b = blps[0]) === null || _b === void 0 ? void 0 : _b.level}`]) !== null && _c !== void 0 ? _c : adjustedBottomBasesDict[`${(_d = blps[1]) === null || _d === void 0 ? void 0 : _d.isoBay}-${(_e = blps[1]) === null || _e === void 0 ? void 0 : _e.level}`]) !== null && _f !== void 0 ? _f : 0;
            const halfHeightInMm = roundDec(CONTAINER_HEIGHT_IN_MMM * svgSizeRatio, 1) * 0.5;
            blps.forEach((blp) => {
                const tiers = Object.keys(blp.tiers).sort(sortNumericAsc);
                // Pass 1: Calculate min and max values
                tiers.forEach((tierName) => {
                    const tierInfo = blp.tiers[tierName];
                    if ((tierInfo.maxSize >= 40 || !tierInfo.pairedHas40) &&
                        tierInfo.lcg !== undefined &&
                        tierInfo.vcg !== undefined) {
                        const x = xPos(tierInfo.lcg);
                        const y = yPos(tierInfo.vcg);
                        const yfg = roundDec(yPos(tierInfo.vcg + adjustBB) - halfHeightInMm, 3);
                        const halfMaxSize = roundDec(feetToMillimeters(tierInfo.maxSize * 0.5) * svgSizeRatio, 1);
                        if (x < minX - halfMaxSize)
                            minX = roundDec(x - halfMaxSize, 3);
                        if (x > maxX + halfMaxSize)
                            maxX = roundDec(x + halfMaxSize, 3);
                        if (y < minY - halfHeightInMm)
                            minY = roundDec(y - halfHeightInMm, 3);
                        if (y > maxY + halfHeightInMm)
                            maxY = roundDec(y + halfHeightInMm, 3);
                        if (yfg < minYforGroup)
                            minYforGroup = yfg;
                        if (maxTierY < tierInfo.vcg)
                            maxTierY = tierInfo.vcg;
                        if (minTierY > tierInfo.vcg)
                            minTierY = tierInfo.vcg;
                    }
                });
            });
            if (minX === Infinity || minY === Infinity)
                return undefined;
            const svgGroup = document.createElementNS("http://www.w3.org/2000/svg", "g");
            svgGroup.setAttribute("data-id", `${blps.map((b) => b.isoBay).join("-")}|${blps[0].level}`);
            svgGroup.setAttribute("data-bbase", `${minTierY}`);
            svgGroup.setAttribute("data-bay", blps[0].isoBay);
            svgGroup.setAttribute("data-height", `${maxTierY - minTierY + CONTAINER_HEIGHT_IN_MMM}`);
            if (missingImportantXcgs)
                svgGroup.setAttribute("data-action", `move`);
            svgGroup.setAttribute("transform", `translate(${minX}, ${minYforGroup})`);
            // const rect = document.createElementNS(
            //   "http://www.w3.org/2000/svg",
            //   "rect",
            // );
            // rect.setAttribute("x", "0");
            // rect.setAttribute("y", "0");
            // rect.setAttribute("width", `${maxX - minX}`);
            // rect.setAttribute("height", `${maxY - minYforGroup}`);
            // rect.setAttribute("fill", "none");
            // rect.setAttribute("stroke", "red");
            // rect.setAttribute("stroke-width", "1");
            // rect.setAttribute("stroke-dasharray", "5,5");
            return { svgGroup, minX, minY, maxX, maxY, minYforGroup };
        }
        /** Draws the containers of a single bay-and-level */
        function drawContainers(svgGroup, minX, minY, blps) {
            blps.forEach((blp) => {
                const tiers = Object.keys(blp.tiers).sort(sortNumericAsc);
                tiers.forEach((tierName) => {
                    const tierInfo = blp.tiers[tierName];
                    if (
                    // If paired has 40, don't draw.
                    (tierInfo.maxSize < 40 && tierInfo.pairedHas40) ||
                        tierInfo.lcg === undefined ||
                        tierInfo.vcg === undefined) {
                        return;
                    }
                    const contSvg = document.createElementNS("http://www.w3.org/2000/svg", "use");
                    contSvg.setAttribute("href", `#smbCn${tierInfo.maxSize}-${blp.pairedBay || 0}`);
                    contSvg.setAttribute("x", `${roundDec(xPos(tierInfo.lcg) - minX, 3)}`);
                    contSvg.setAttribute("y", `${roundDec(yPos(tierInfo.vcg) - minY, 3)}`);
                    contSvg.setAttribute("data-p", `${blp.isoBay}-${tierName}`);
                    contSvg.setAttribute("data-bay", blp.isoBay);
                    const contTitle = document.createElementNS("http://www.w3.org/2000/svg", "title");
                    contTitle.innerHTML = `${blp.isoBay}-${tierName}`;
                    contSvg.appendChild(contTitle);
                    svgGroup.appendChild(contSvg);
                });
                svgNode.appendChild(svgGroup);
            });
        }
    }
    function createSameBayAbBeSizesAndCgs(blpsAbove, blpsBelow, adjustedBottomBasesDict = {}, deckBottomBases = {}) {
        const aboveBelowBlps = {};
        [...blpsAbove, ...blpsBelow].forEach((bcs) => {
            const { vcg, lcg, foreLcg, aftLcg, maxSize } = getNearestVcgFromTiers({
                blp: bcs,
                isAbove: bcs.level === BayLevelEnum.ABOVE,
            });
            const vcgAb = vcg !== undefined
                ? vcg + (adjustedBottomBasesDict[`${bcs.isoBay}-${bcs.level}`] || 0)
                : undefined;
            addToBay(bcs, vcgAb, lcg, foreLcg, aftLcg, maxSize, bcs.level === BayLevelEnum.ABOVE
                ? "nearestAboveVcg"
                : "nearestBelowVcg");
        });
        fillEmptyBaySlotsLcgs();
        supplyB20sLongPointsFor40s();
        return aboveBelowBlps;
        function getNearestVcgFromTiers({ blp, isAbove, }) {
            var _a, _b;
            const tiers = Object.keys(blp.tiers).sort(isAbove ? sortNumericAsc : sortNumericDesc);
            return {
                vcg: (_a = blp.tiers[tiers[0]]) === null || _a === void 0 ? void 0 : _a.vcg,
                lcg: roundDec(blp.maxSizeLcg.lcg || 0, 4),
                maxSize: (_b = blp.tiers[tiers[0]]) === null || _b === void 0 ? void 0 : _b.maxSize,
                foreLcg: roundDec(blp.maxSizeLcg.foreLcg || 0, 4),
                aftLcg: roundDec(blp.maxSizeLcg.aftLcg || 0, 4),
            };
        }
        function addToBay(blp, vcg, lcg, foreLcg, aftLcg, maxSize, propVcg) {
            let pairedInstance = aboveBelowBlps[blp.isoBay];
            if (pairedInstance === undefined) {
                aboveBelowBlps[blp.isoBay] = {
                    lcg,
                    aftLcg: aftLcg,
                    foreLcg: foreLcg,
                    pairedBay: blp.pairedBay,
                    maxSize,
                    b20: maxSize < 40
                        ? {
                            fwdLPoint: foreLcg,
                            aftLPoint: aftLcg,
                        }
                        : undefined,
                };
                pairedInstance = aboveBelowBlps[blp.isoBay];
            }
            if (pairedInstance.pairedBay === undefined && blp.pairedBay !== undefined)
                pairedInstance.pairedBay = blp.pairedBay;
            pairedInstance[propVcg] = vcg;
            pairedInstance.bottomBase = deckBottomBases[blp.isoBay];
        }
        function supplyB20sLongPointsFor40s() {
            const bays = Object.keys(aboveBelowBlps).sort();
            bays.forEach((bay) => {
                const bp = aboveBelowBlps[bay];
                if (bp.b20 || bp.maxSize < 40 || bp.pairedBay === undefined)
                    return;
                const midMaxSize = bp.maxSize * 0.5;
                const isPairedFwd = bp.pairedBay === ForeAftEnum.FWD;
                const bpLcg = bp.lcg;
                bp.b20 = {
                    fwdLPoint: isPairedFwd
                        ? bpLcg
                        : bpLcg +
                            feetToMillimeters(!Number.isNaN(midMaxSize) ? midMaxSize : 0),
                    aftLPoint: isPairedFwd
                        ? bpLcg -
                            feetToMillimeters(!Number.isNaN(midMaxSize) ? midMaxSize : 0)
                        : bpLcg,
                };
            });
        }
        function fillEmptyBaySlotsLcgs() {
            const pairedKeys = Object.keys(aboveBelowBlps).sort(sortNumericAsc);
            for (let i = 0; i < pairedKeys.length; i += 1) {
                const bay = pairedKeys[i];
                const paired = aboveBelowBlps[bay];
                if (paired.pairedBay !== undefined) {
                    const pairedBlp = aboveBelowBlps[paired.pairedBay === ForeAftEnum.FWD
                        ? pairedKeys[i - 1]
                        : pairedKeys[i + 1]];
                    if (!(paired === null || paired === void 0 ? void 0 : paired.lcg) && (pairedBlp === null || pairedBlp === void 0 ? void 0 : pairedBlp.lcg) !== undefined) {
                        paired.lcg =
                            pairedBlp.lcg +
                                (paired.pairedBay === ForeAftEnum.FWD ? -1 : 1) *
                                    feetToMillimeters(20);
                    }
                    if (!(paired === null || paired === void 0 ? void 0 : paired.bottomBase) && (pairedBlp === null || pairedBlp === void 0 ? void 0 : pairedBlp.bottomBase) !== undefined) {
                        paired.bottomBase = pairedBlp.bottomBase;
                    }
                }
            }
        }
    }
    function drawShipLine() {
        let xy = undefined;
        let lastWasWrong = false;
        let midVcg = 0;
        const segmentsXy = [
            { x: roundDec(addX * 0.5 + lastBaySizeInMM), y: yRange - 1 }, // bottom-left
            {
                x: roundDec(xRange - bowLength * svgSizeRatio * 0.5),
                y: yRange - 1,
            }, // bottom-right
            {
                x: roundDec(xRange),
                y: yRange * 0.875,
                bX: roundDec(xRange - bowLength * svgSizeRatio * 0.25),
                bY: yRange - 1,
            },
            {
                x: roundDec(xRange - bowLength * svgSizeRatio * 0.5),
                y: yRange * 0.75,
                bX: roundDec(xRange - bowLength * svgSizeRatio * 0.15),
                bY: yRange * 0.75,
            },
            {
                x: roundDec(xRange - bowLength * svgSizeRatio * 0.5),
                y: yRange * 0.75,
            },
        ];
        // #region add-nose
        const firstBay = isoBaysArray[0];
        const firstBaySLps = sameBaySLps[firstBay];
        if (firstBaySLps.bottomBase !== undefined) {
            midVcg =
                firstBaySLps.bottomBase +
                    (adjustedBottomBasesDict[`${firstBay}-${BayLevelEnum.ABOVE}`] || 0);
            // top-right (nose of the ship)
            const bowTop = yPos(midVcg + DISTANCE_TO_CONTAINER_FOR_SHIP_LINE, 1);
            segmentsXy.push({
                x: xRange,
                y: bowTop,
            }, {
                x: xPos(allPartsLengthInMeters, 1) +
                    scaled(DISTANCE_TO_CONTAINER_FOR_SHIP_LINE),
                y: bowTop,
            }, {
                x: xPos(allPartsLengthInMeters, 1),
                y: yPos(midVcg, 1),
            });
        }
        // #endregion add-nose
        let prevX = Infinity;
        let prevY = undefined;
        const THREE_METERS_IN_X = feetToMillimeters(10) * svgSizeRatio;
        isoBaysArray.forEach((isoBay) => {
            const paired = sameBaySLps[isoBay];
            if ((paired === null || paired === void 0 ? void 0 : paired.bottomBase) === undefined) {
                lastWasWrong = true;
                return;
            }
            const midVcg = paired.bottomBase +
                (adjustedBottomBasesDict[`${isoBay}-${BayLevelEnum.ABOVE}`] || 0);
            // Add parts of line
            if (!Number.isNaN(paired.foreLcg) && !Number.isNaN(paired.aftLcg)) {
                const y = yPos(midVcg, 1);
                const newForeX = Math.min(xPos(paired.foreLcg, 1), prevX);
                const newAftX = Math.min(xPos(paired.aftLcg, 1), prevX);
                if (prevY !== undefined &&
                    prevY !== y &&
                    Math.abs(newForeX - prevX) < THREE_METERS_IN_X) {
                    const midX = (newForeX + prevX) * 0.5;
                    segmentsXy.push({ x: midX, y: prevY });
                    segmentsXy.push({ x: midX, y: y, move: lastWasWrong });
                }
                // We have correct info about LCGs of 20s
                xy = { x: newForeX, y };
                if (lastWasWrong)
                    xy.move = true;
                segmentsXy.push(xy);
                xy = { x: newAftX, y };
                segmentsXy.push(xy);
                prevX = Math.min(xy.x, prevX);
                prevY = xy.y;
                lastWasWrong = false;
            }
            else {
                lastWasWrong = true;
            }
        });
        // Finally, add stern
        if (xy !== undefined) {
            segmentsXy.push({
                x: roundDec(addX * 0.6),
                y: xy.y,
                move: lastWasWrong,
            }, {
                x: roundDec(addX * 0.25),
                y: xy.y,
            });
        }
        segmentsXy.push({ x: roundDec(addX * 0.3, 2), y: roundDec(yRange * 0.7) }, { x: roundDec(addX * 0.4), y: roundDec(yRange * 0.7) });
        // Prepare SVG paths
        const svgOk = createSvgPath({
            strokeColor: symbolsOptions === null || symbolsOptions === void 0 ? void 0 : symbolsOptions.shipStrokeColor,
            strokeWidth: symbolsOptions === null || symbolsOptions === void 0 ? void 0 : symbolsOptions.strokeWidth,
            fillOpactiy: 0,
        });
        svgOk.dataset["edx"] = "shipLine";
        const svgWrong = createSvgPath({
            strokeColor: (symbolsOptions === null || symbolsOptions === void 0 ? void 0 : symbolsOptions.warningColor) || "red",
            strokeWidth: symbolsOptions === null || symbolsOptions === void 0 ? void 0 : symbolsOptions.strokeWidth,
            fillOpactiy: 0,
        });
        svgWrong.dataset["edx"] = "shipLineErrors";
        const pathArr = [];
        const pathArrWrong = [];
        let prevXyOk;
        segmentsXy.forEach((xy, i) => {
            if (xy.bX === undefined) {
                pathArr.push(`${i === 0 || xy.move ? "M" : "L"}${xy.x},${xy.y}`);
            }
            else {
                pathArr.push(`Q${xy.bX},${xy.bY} ${xy.x},${xy.y}`);
            }
            if (xy.move && prevXyOk) {
                pathArrWrong.push("M" + prevXyOk.x + "," + prevXyOk.y);
                pathArrWrong.push("L" + xy.x + "," + xy.y);
            }
            prevXyOk = xy;
        });
        const pathStr = `${pathArr.join(" ")} L${segmentsXy[0].x},${segmentsXy[0].y}`;
        if (pathStr.indexOf("Infinity") < 0) {
            svgOk.setAttribute("d", pathStr);
            svgWrong.setAttribute("d", pathArrWrong.join(" "));
        }
        svgNode.appendChild(svgOk);
        if (pathArrWrong.length)
            svgNode.appendChild(svgWrong);
        return yPos(midVcg);
    }
    function drawBulkheads(xRange) {
        bayLevelPositionsBelow
            .filter((m) => m.tiers && m.bulkhead && (m.bulkhead.fore || m.bulkhead.aft))
            .forEach((maxByTier) => {
            const paired = sameBaySLps[maxByTier.isoBay];
            //const tiers = Object.keys(maxByTier.tiers) as IRowPattern[];
            const midVcg = (paired.bottomBase || 0) +
                (adjustedBottomBasesDict[`${maxByTier.isoBay}-${BayLevelEnum.ABOVE}`] || 0);
            if (maxByTier.bulkhead) {
                const hasFore = maxByTier.bulkhead.fore;
                const hasAft = maxByTier.bulkhead.aft;
                const foreLcg = lcgsBy20Bay[maxByTier.isoBay].foreLcg + SEPARATION_IN_BETWEEN;
                const aftLcg = lcgsBy20Bay[maxByTier.isoBay].aftLcg - SEPARATION_IN_BETWEEN;
                if (hasFore) {
                    addBulkSvgNode(foreLcg, midVcg);
                }
                if (hasAft) {
                    addBulkSvgNode(aftLcg, midVcg);
                }
            }
        });
        function addBulkSvgNode(lcg, maxV) {
            const BULK_WIDTH = 1;
            if (!lcg || !maxV)
                return;
            const x = xPos(lcg);
            if (Math.abs(x / xRange) > 1.5)
                return;
            const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
            path.setAttribute("data-type", "bulkhead");
            path.setAttribute("stroke", (symbolsOptions === null || symbolsOptions === void 0 ? void 0 : symbolsOptions.shipStrokeColor) || "red");
            path.setAttribute("fill", (symbolsOptions === null || symbolsOptions === void 0 ? void 0 : symbolsOptions.shipStrokeColor) || "red");
            path.setAttribute("stroke-width", String((symbolsOptions === null || symbolsOptions === void 0 ? void 0 : symbolsOptions.strokeWidth) || 0.1));
            path.setAttribute("fill-opacity", "1");
            path.setAttribute("d", `M${xPos(lcg + BULK_WIDTH)},${yPos(maxV)} h${BULK_WIDTH} V${yRange - 1} h-${BULK_WIDTH} z`);
            svgNode.appendChild(path);
        }
    }
    // function calculateGeneralMidVcg(
    //   missingImportantXcgs: boolean,
    // ): number | undefined {
    //   if (!missingImportantXcgs) return undefined;
    //   let maxBelowVcg = -Infinity,
    //     minAboveVcg = Infinity;
    //   Object.keys(sameBaySLps).forEach((bay) => {
    //     const p = sameBaySLps[bay];
    //     if (p.nearestBelowVcg !== undefined && p.nearestBelowVcg > maxBelowVcg)
    //       maxBelowVcg = p.nearestBelowVcg;
    //     if (p.nearestAboveVcg !== undefined && p.nearestAboveVcg < minAboveVcg)
    //       minAboveVcg = p.nearestAboveVcg;
    //   });
    //   return (maxBelowVcg + minAboveVcg) * 0.5;
    // }
    function drawVesselParts(midVcg) {
        const rightOfShip = scaled(allPartsLengthInMeters + sternLength) + addX * 0.5;
        // console.log(
        //   "rightOfShip",
        //   { allPartsLengthInMeters, bowLength, sternLength },
        //   scaled(allPartsLengthInMeters + bowLength + sternLength),
        // );
        // const path2 = document.createElementNS(
        //   "http://www.w3.org/2000/svg",
        //   "path",
        // );
        // const ps: number[] = [];
        // vOneBaySlots.forEach((vOneBay, idx) => {
        //   const posX100 = vOneBay.positionX * 100;
        //   ps.push(feetToMillimeters(posX100));
        // });
        // console.log(ps);
        // path2.setAttribute("stroke", "red");
        // path2.setAttribute("stroke-width", "1");
        // path2.setAttribute(
        //   "d",
        //   ps.reduce((acc, po, idx) => {
        //     const p = rightOfShip - scaled(po);
        //     return `${acc} M${p},0 L${p},${yRange} `;
        //   }, ""),
        // );
        // svgNode.appendChild(path2);
        vOneBaySlots.forEach((vOneBay, idx) => {
            var _a, _b;
            if (vOneBay.type === "BAY")
                return;
            const posX100 = vOneBay.positionX * 100;
            const nextPosX100 = ((_a = vOneBaySlots[idx + 1]) === null || _a === void 0 ? void 0 : _a.positionX) * 100;
            let xtra = 0;
            const nextLen = (_b = vOneBaySlots[idx + 1]) === null || _b === void 0 ? void 0 : _b.len;
            if (nextLen !== undefined && nextLen > 40) {
                xtra = scaled(feetToMillimeters(nextLen - 40) * 0.5);
            }
            const cPosX = feetToMillimeters(posX100);
            const nPosX = feetToMillimeters(nextPosX100 ? nextPosX100 - xtra : posX100 + vOneBay.len);
            const x = rightOfShip - scaled(nPosX * 0.5 + cPosX * 0.5);
            let paths = undefined;
            switch (vOneBay.type) {
                case "BRG":
                    paths = drawSideBridge(vOneBay, midVcg, svgSizeRatio);
                    break;
                case "SMK":
                    paths = drawSideSmokeStack(vOneBay, midVcg, svgSizeRatio);
                    break;
                case "CRN":
                    paths = drawSideCrane(vOneBay, midVcg, svgSizeRatio);
                    break;
            }
            if (paths) {
                paths.forEach((p) => {
                    p.setAttribute("transform", `translate(${x}, ${0})`);
                    svgNode.appendChild(p);
                });
            }
        });
    }
    // function _helperDraw() {
    //   const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
    //   path.setAttribute("stroke", "red");
    //   path.setAttribute("stroke-width", String(symbolsOptions?.strokeWidth || 1));
    //   path.setAttribute("fill-opacity", "0");
    //   path.setAttribute("d", `M${xPos(1955)},0 L${xPos(1955)},${yRange}`);
    //   svgNode.appendChild(path);
    // }
}
