import Bugsnag, { BreadcrumbType } from "@bugsnag/js";
import BugsnagPerformance, {
  DefaultRoutingProvider,
} from "@bugsnag/browser-performance";

import { routeFns } from "../router/routes";

export const errorTracking = {
  start(bugsnagId: string, stage: string | undefined) {
    const appVersion = `v1.0.${process.env.NX_PUBLIC_BUILD_NUMBER ?? "Beta"}`;
    Bugsnag.start({
      apiKey: bugsnagId || "",
      releaseStage: stage || "unknown",
      appVersion,
      maxBreadcrumbs: 100,
    });

    console.info("TEDIVO Vessel Designer ", appVersion);

    if (process.env.NX_PUBLIC_STAGE === "dev") window.Bugsnag = Bugsnag;

    BugsnagPerformance.start({
      apiKey: bugsnagId,
      releaseStage: stage || "unknown",
      appVersion,
      routingProvider: new DefaultRoutingProvider(resolveRoutes()),
    });
  },

  identify(
    userSub: string,
    email: string,
    displayName: string,
    organizationName: string,
  ) {
    if (!Bugsnag.isStarted()) return;

    Bugsnag.setUser(userSub, email, `${displayName} / ${organizationName}`);
  },

  leaveBreadcrumb(
    message: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metadata?: { [key: string]: any },
    type?: BreadcrumbType,
  ) {
    if (!Bugsnag.isStarted()) return;

    Bugsnag.leaveBreadcrumb(message, metadata, type);
  },
};

function resolveRoutes(): (url: URL) => string {
  const pathsWithParams = [
    routeFns.fileCompareTvlSource(":id"),
    routeFns.fileCompareOwnVersion(":id", ":version"),
    routeFns.fileCompareConsumer(":id", ":consumerOrgId", ":consumerFileId"),
    routeFns.fileMap(":id"),
    routeFns.adminInvoices(":orgId"),
    routeFns.ovdEdit(":id"),
    routeFns.ovdViewOnly(":id"),
    routeFns.ovdViewOnlyVersion(":id", ":version"),
    routeFns.theirCloud(":orgId"),
    routeFns.viewTvl(":orgId", ":fileId"),
    routeFns.adminOrganizationUsers(":orgId"),
  ];

  const regexes = new Map<string, RegExp>();

  pathsWithParams.forEach((path) => {
    regexes.set(
      path,
      new RegExp(path.replace(/:\w+/g, "([\\w-]+)").replace(/\//g, "\\/")),
    );
  });

  return function resolveRoute(url: URL): string {
    regexes.forEach((regrex, path) => {
      const match = url.pathname.match(regrex);
      if (match) {
        return path;
      }
    });

    return url.pathname;
  };
}
