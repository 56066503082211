import { INodesOptions } from "@tedivo/tedivo-bay-grid-core";

export function getGridNodesOptions(): INodesOptions {
  const root = getComputedStyle(document.body);

  const nodesOptions: INodesOptions = {
    gridNumbersTextColor: root.getPropertyValue("--sl-color-neutral-500"),
    lineColor: root.getPropertyValue("--sl-color-neutral-300"),
    boxDisabledBackgroundColor: root.getPropertyValue("--sl-color-neutral-200"),
    boxDisabledBorderColor: root.getPropertyValue("--sl-color-neutral-200"),
    boxProhibitedBorderColor: root.getPropertyValue("--sl-color-neutral-300"),
    boxBackgroundColor: root.getPropertyValue("--sl-color-neutral-0"),
    boxBorderColor: root.getPropertyValue("--sl-color-neutral-300"),
    lidBackgroundColor: root.getPropertyValue("--sl-color-primary-100"),
    lidBorderColor: root.getPropertyValue("--sl-color-primary-400"),
    emphasizeLineColor: root.getPropertyValue("--sl-color-neutral-600"),
  };

  return nodesOptions;
}
