export function calculateSvgWidth(
  text: string,
  fontSize: number,
): { width: number; height: number } {
  const { svgNode, svgTextNode } = prepareNodesForTextWidthCalculation();

  svgNode.style.display = "block";
  svgTextNode.setAttribute("font-size", String(fontSize));
  svgTextNode.innerHTML = text;
  svgNode.style.visibility = "none";

  const bbox = svgTextNode.getBBox();
  const width = bbox.width;
  const height = bbox.height;

  return { width, height };
}

/* INTERNAL */
const nodes = {
  svgNode: undefined as SVGElement | undefined,
  svgTextNode: undefined as SVGElement | undefined,
};

function prepareNodesForTextWidthCalculation(): {
  svgNode: SVGElement;
  svgTextNode: SVGTextElement;
} {
  if (!nodes || !nodes.svgNode || !nodes.svgTextNode) {
    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svg.setAttribute("width", "800px");
    svg.setAttribute("height", "50px");
    svg.style.display = "none";

    const svgT = document.createElementNS("http://www.w3.org/2000/svg", "text");
    svgT.setAttribute("stroke-width", "0");
    svgT.setAttribute("font-family", "Arial, sans-serif");
    svgT.setAttribute("x", "0");
    svgT.setAttribute("y", "0");
    svg.appendChild(svgT);

    svg.style.position = "absolute";
    svg.style.visibility = "hidden";
    svg.style.height = "auto";
    svg.style.width = "auto";
    svg.style.pointerEvents = "none";

    nodes.svgNode = svg;
    nodes.svgTextNode = svgT;

    document.body.appendChild(svg);

    return { svgNode: svg, svgTextNode: svgT };
  } else {
    return nodes as {
      svgNode: SVGElement;
      svgTextNode: SVGTextElement;
    };
  }
}
