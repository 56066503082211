import { errorTracking } from "../tracking/errorTracking";
import goSquared from "../tracking/goSquared";
import googleRecaptcha from "../tracking/googleRecaptcha";
import googleTags from "../tracking/googleTags";
import msClarity from "../tracking/msClarity";

export function initializeTrackers() {
  // Bugsnag
  if (process.env.NX_PUBLIC_BUGSNAG_ID)
    errorTracking.start(
      process.env.NX_PUBLIC_BUGSNAG_ID,
      process.env.NX_PUBLIC_STAGE,
    );

  // GoSquared
  if (process.env.NX_PUBLIC_GOSQUARED_ID)
    goSquared.initialize(process.env.NX_PUBLIC_GOSQUARED_ID);

  // Ms Clarity
  if (process.env.NX_PUBLIC_MSCLARITY_ID)
    msClarity.initialize(process.env.NX_PUBLIC_MSCLARITY_ID);

  // Google Tags
  if (process.env.NX_PUBLIC_GOOGLE_ID)
    googleTags.initialize(process.env.NX_PUBLIC_GOOGLE_ID);

  googleRecaptcha.initialize("v2");
}
