import { createSlOption } from "./TedivoForm";
export function createFormFieldAndState({ fieldProps, formProps, formControlsByName, }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    if (fieldProps === undefined)
        return undefined;
    /** It's the actual form-field html node, like a sl-input, or sl-checkbox, ... */
    let formField = undefined;
    let titleField = undefined;
    let nodeField = undefined;
    const name = fieldProps.name;
    const stateRecord = {
        name,
        value: undefined,
        type: fieldProps.type,
        props: fieldProps,
    };
    let initVal = undefined;
    // 1. Create the field
    switch (fieldProps.type) {
        case "title":
            titleField = document.createElement((_a = fieldProps.tagName) !== null && _a !== void 0 ? _a : "h2");
            titleField.className = "subtitle";
            //stateRecord.field = titleField;
            break;
        case "node":
            nodeField = fieldProps.node;
            break;
        case "hidden":
            formField = document.createElement("tf-hidden");
            formField.style.display = "none";
            initVal = (_b = fieldProps.initialValue) !== null && _b !== void 0 ? _b : fieldProps.defaultValue;
            if (initVal !== undefined) {
                formField.value = String(initVal);
                stateRecord.value = String(initVal);
            }
            stateRecord.field = formField;
            break;
        case "color":
            formField = document.createElement("tf-input-color");
            if (fieldProps.initialValue) {
                initVal = fieldProps.initialValue;
                stateRecord.value = initVal;
                formField.value = initVal;
            }
            stateRecord.field = formField;
            break;
        case "textBox":
        case "date":
            formField = document.createElement("sl-input");
            if (fieldProps.type === "date") {
                formField.type = "date";
                if (fieldProps.initialValue) {
                    initVal =
                        typeof fieldProps.initialValue === "string"
                            ? new Date(fieldProps.initialValue)
                            : fieldProps.initialValue;
                }
                if (initVal !== undefined) {
                    formField.valueAsDate = initVal;
                    stateRecord.value = initVal;
                }
            }
            else {
                formField.type = !fieldProps.isPassword ? "text" : "password";
                initVal = (_c = fieldProps.initialValue) !== null && _c !== void 0 ? _c : fieldProps.defaultValue;
                if (fieldProps.placeholder)
                    formField.placeholder = fieldProps.placeholder;
                if (fieldProps.autocapitalize)
                    formField.autocapitalize = "characters";
                if (fieldProps.disabled)
                    formField.disabled = true;
                if (fieldProps.isPassword)
                    formField.passwordToggle = true;
                if (initVal !== undefined) {
                    formField.value = String(initVal);
                    stateRecord.value = String(initVal);
                }
            }
            stateRecord.field = formField;
            formField.autocorrect = "off";
            break;
        case "textArea":
            formField = document.createElement("sl-textarea");
            stateRecord.field = formField;
            initVal = (_d = fieldProps.initialValue) !== null && _d !== void 0 ? _d : fieldProps.defaultValue;
            if (initVal !== undefined) {
                formField.value = String(initVal);
                stateRecord.value = String(initVal);
            }
            if (fieldProps.placeholder)
                formField.placeholder = fieldProps.placeholder;
            if (fieldProps.autocapitalize)
                formField.autocapitalize = "characters";
            formField.autocorrect = "off";
            break;
        case "number":
            formField = document.createElement("sl-input");
            formField.type = "number";
            stateRecord.field = formField;
            initVal = (_e = fieldProps.initialValue) !== null && _e !== void 0 ? _e : fieldProps.defaultValue;
            if (initVal !== undefined) {
                formField.value = String(initVal);
                stateRecord.value = initVal;
            }
            if (fieldProps.placeholder)
                formField.placeholder = fieldProps.placeholder;
            if (fieldProps.step !== undefined)
                formField.step = fieldProps.step;
            break;
        case "file":
            formField = document.createElement("tf-file");
            if (fieldProps.accept)
                formField.accept = fieldProps.accept;
            stateRecord.value = fieldProps.initialValue;
            stateRecord.field = formField;
            break;
        case "checkbox":
            formField = document.createElement("tf-checkbox");
            if (fieldProps.isNumericEnum)
                formField.isNumericEnum = true;
            initVal = (_f = fieldProps.initialValue) !== null && _f !== void 0 ? _f : fieldProps.defaultValue;
            if (initVal !== undefined) {
                formField.value = !!initVal;
                stateRecord.value = fieldProps.isNumericEnum
                    ? initVal
                    : Boolean(initVal);
            }
            if (fieldProps.disabled !== undefined)
                formField.disabled = fieldProps.disabled;
            if (fieldProps.padStart)
                formField.padStart = true;
            if (fieldProps.required)
                formField.required = true;
            stateRecord.field = formField;
            break;
        case "radioButtonList":
            formField = document.createElement("tf-radio-group");
            formField.items = fieldProps.options;
            formField.isNumericEnum = !!fieldProps.isNumericEnum;
            if (fieldProps.fieldset)
                formField.fieldset = true;
            initVal = (_g = fieldProps.initialValue) !== null && _g !== void 0 ? _g : fieldProps.defaultValue;
            if (initVal !== undefined) {
                formField.value = String(initVal);
                stateRecord.value = initVal;
            }
            if (fieldProps.disabled)
                formField.disabled = true;
            stateRecord.field = formField;
            break;
        case "checkboxesList":
            formField = document.createElement("tf-checkbox-group");
            formField.items = fieldProps.options;
            formField.isNumericEnum = !!fieldProps.isNumericEnum;
            if (fieldProps.fieldset)
                formField.fieldset = true;
            if (fieldProps.fixedOptionWidth)
                formField.fixedOptionWidth = fieldProps.fixedOptionWidth;
            initVal = (_h = fieldProps.initialValue) !== null && _h !== void 0 ? _h : fieldProps.defaultValue;
            if (initVal !== undefined) {
                formField.value = initVal;
                stateRecord.value = initVal;
            }
            if (fieldProps.disabled !== undefined)
                formField.disabled = fieldProps.disabled;
            if (fieldProps.helpers)
                formField.helpers = fieldProps.helpers;
            stateRecord.field = formField;
            break;
        case "numberWithUnits":
            formField = document.createElement("tf-input-units");
            stateRecord.field = formField;
            formField.converter = fieldProps.converter;
            formField.transponser = fieldProps.transponser;
            initVal = (_j = fieldProps.initialValue) !== null && _j !== void 0 ? _j : fieldProps.defaultValue;
            if (initVal !== undefined) {
                formField.value = initVal;
                stateRecord.value = initVal;
            }
            break;
        case "select":
            formField = document.createElement("sl-select");
            initVal = (_k = fieldProps.initialValue) !== null && _k !== void 0 ? _k : fieldProps.defaultValue;
            if (initVal !== undefined) {
                formField.value = String(initVal);
                stateRecord.value = String(initVal);
            }
            if (fieldProps.hoist)
                formField.hoist = true;
            stateRecord.field = formField;
            {
                const createOptions = (opts) => {
                    opts.forEach((opt) => {
                        const option = createSlOption(opt);
                        if (option)
                            formField === null || formField === void 0 ? void 0 : formField.appendChild(option);
                    });
                };
                if (typeof fieldProps.options === "function") {
                    fieldProps.options().then((opts) => createOptions(opts));
                }
                else {
                    createOptions(fieldProps.options);
                }
            }
            break;
    }
    // 2. Add secondary properties to created fields
    switch (fieldProps.type) {
        case "title":
            // Title (heading)
            if (titleField) {
                return {
                    formField: titleField,
                    stateRecord: {
                        value: undefined,
                        type: "title",
                        field: titleField,
                        props: fieldProps,
                    },
                };
            }
            else {
                return undefined;
            }
        case "node":
            if (nodeField) {
                return {
                    formField: nodeField,
                    stateRecord: {
                        value: undefined,
                        type: "node",
                        field: nodeField,
                        props: fieldProps,
                    },
                };
            }
            else {
                return undefined;
            }
        default:
            if (formField === undefined)
                return undefined;
            // Keeps the value of previous rendered fields
            if (name && formControlsByName[name]) {
                stateRecord.value = formControlsByName[name].value;
            }
            formField.id = String(fieldProps.name);
            formField.name = String(fieldProps.name);
            formField.size = (_m = (_l = fieldProps.size) !== null && _l !== void 0 ? _l : formProps === null || formProps === void 0 ? void 0 : formProps.size) !== null && _m !== void 0 ? _m : "medium";
            // Label
            if (fieldProps.label) {
                formField.label = fieldProps.label;
            }
            else if (fieldProps.labelNode) {
                const lblNode = fieldProps.labelNode.cloneNode(true);
                lblNode.slot = "label";
                formField.appendChild(lblNode);
            }
            // helpText
            if (fieldProps.helpText)
                formField.helpText = fieldProps.helpText;
            return {
                formField,
                stateRecord,
            };
    }
}
