import { BayLevelEnum } from "open-vessel-definition";
import { createDictionary, sortNumericAsc } from "@tedivo/tedivo-pure-helpers";
import { getSizesFromSlots } from "../../getSizesFromSlots";
export function getBayBlocks(bls, pairedBaysCalc) {
    const blsDict = createDictionary(bls, (b) => `${b.isoBay}-${b.level}`);
    const allIsoBays = bls
        .map((b) => b.isoBay)
        .filter((v, i, a) => a.indexOf(v) === i)
        .sort(sortNumericAsc)
        .map((b) => ({ isoBay: b }));
    const blockBaysAndSizesKeys = calcBlockBaysAndSizesKeys(pairedBaysCalc);
    const blockBaysAndSizes = Array.from(blockBaysAndSizesKeys)
        .sort()
        .map((b) => ({
        allBays: b,
        base: b.split("-")[0],
        blockSize: 20,
        maxSizes: {},
        numberOfBays: 1,
    }));
    bls.forEach((bl) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        const bay = bl.isoBay;
        const paired = (_a = pairedBaysCalc.pairedBays.find((pb) => pb.base === bay)) === null || _a === void 0 ? void 0 : _a.paired;
        const maxSizeSameBay = Math.max(...[
            ...(((_c = getSizesFromSlots((_b = blsDict[`${bay}-${BayLevelEnum.ABOVE}`]) === null || _b === void 0 ? void 0 : _b.perSlotInfo)) === null || _c === void 0 ? void 0 : _c.sizes) || []),
            ...(((_e = getSizesFromSlots((_d = blsDict[`${bay}-${BayLevelEnum.BELOW}`]) === null || _d === void 0 ? void 0 : _d.perSlotInfo)) === null || _e === void 0 ? void 0 : _e.sizes) || []),
            20,
        ]);
        const maxSizePairedBay = paired
            ? Math.max(...[
                ...(((_g = getSizesFromSlots((_f = blsDict[`${paired}-${BayLevelEnum.ABOVE}`]) === null || _f === void 0 ? void 0 : _f.perSlotInfo)) === null || _g === void 0 ? void 0 : _g.sizes) || []),
                ...(((_j = getSizesFromSlots((_h = blsDict[`${paired}-${BayLevelEnum.BELOW}`]) === null || _h === void 0 ? void 0 : _h.perSlotInfo)) === null || _j === void 0 ? void 0 : _j.sizes) || []),
                20,
            ])
            : 0;
        const id = paired ? `${bay}-${paired}` : bay;
        const idx = blockBaysAndSizes.findIndex((b) => b.allBays === id);
        if (idx !== -1) {
            blockBaysAndSizes[idx].maxSizes = {
                [bay]: maxSizeSameBay,
            };
            if (paired)
                blockBaysAndSizes[idx].maxSizes[paired] = maxSizePairedBay;
            if (paired) {
                blockBaysAndSizes[idx].numberOfBays = 2;
                const maxSizeOfBoth = Math.max(maxSizeSameBay, maxSizePairedBay);
                if (maxSizeOfBoth >= 40) {
                    blockBaysAndSizes[idx].blockSize = maxSizeOfBoth;
                }
                else {
                    blockBaysAndSizes[idx].blockSize = maxSizeSameBay + maxSizePairedBay;
                }
            }
            else {
                blockBaysAndSizes[idx].blockSize = maxSizeSameBay;
                blockBaysAndSizes[idx].numberOfBays = 1;
            }
        }
    });
    const blockBaysAndSizesBy20Bay = blockBaysAndSizes.reduce((acc, b) => {
        if (b.blockSize === 0 || b.blockSize === -Infinity)
            return acc;
        b.allBays.split("-").forEach((bay) => {
            acc[bay] = b;
        });
        return acc;
    }, {});
    const blockBaysAndSizesByBlockBay = createDictionary(blockBaysAndSizes, (b) => b.allBays);
    return {
        allIsoBays,
        blockBaysAndSizes,
        blockBaysAndSizesByBlockBay,
        blockBaysAndSizesBy20Bay,
    };
}
function calcBlockBaysAndSizesKeys(pairedBaysCalc) {
    const { unpairedBays, pairedBays } = pairedBaysCalc;
    const blockBaysAndSizesKeys = new Set();
    unpairedBays.forEach((ub) => {
        blockBaysAndSizesKeys.add(ub.base);
    });
    pairedBays.forEach((pb) => {
        if (!blockBaysAndSizesKeys.has(pb.base) &&
            !blockBaysAndSizesKeys.has(pb.paired)) {
            blockBaysAndSizesKeys.add(pb.allBays.join("-"));
        }
        else {
            blockBaysAndSizesKeys.add(pb.base);
            blockBaysAndSizesKeys.add(pb.paired);
        }
    });
    return blockBaysAndSizesKeys;
}
