import { BayLevelEnum, VesselPartTypeEnum, } from "open-vessel-definition";
import { ONE_MILLIMETER_IN_FEET, createDictionary, roundDec, } from "@tedivo/tedivo-pure-helpers";
import { getAllThePairedBays } from "./getAllThePairedBays";
import { getSizesFromSlots } from "./getSizesFromSlots";
export function calculateSlotsForVesselOne(bls, calculatedSlots, lcgsBy20Bay) {
    const baySlots = [];
    const { pairedBays } = getAllThePairedBays(bls, false);
    const blsDict = createDictionary(bls, (b) => `${b.isoBay}-${b.level}`);
    const lenToVOne = (len) => len * ONE_MILLIMETER_IN_FEET;
    const firstBayIndexInCalculatedSlots = calculatedSlots.findIndex((b) => typeof b === "string");
    const lenOfInitialParts = firstBayIndexInCalculatedSlots === 0
        ? 0
        : calculatedSlots
            .slice(0, firstBayIndexInCalculatedSlots)
            .reduce((acc, part) => {
            if (typeof part !== "object")
                return acc;
            return acc + part.len;
        }, 0);
    const maxLcgFore = roundDec(Math.max(...Object.values(lcgsBy20Bay).map((v) => v.foreLcg)) +
        lenOfInitialParts, 4);
    const posX = (f) => ((maxLcgFore - f) / 100) * ONE_MILLIMETER_IN_FEET;
    let lastPositionXAft = 0;
    const processed = [];
    calculatedSlots.forEach((b) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
        // If it's a string, it's a bay
        if (typeof b === "string" && !isNaN(Number(b))) {
            const bay = b;
            if (processed.includes(bay))
                return;
            const paired = ((_a = pairedBays.find((pair) => pair.base === bay)) === null || _a === void 0 ? void 0 : _a.paired) ||
                ((_b = pairedBays.find((pair) => pair.paired === bay)) === null || _b === void 0 ? void 0 : _b.base);
            const maxSize = Math.max(...[
                ...(((_d = getSizesFromSlots((_c = blsDict[`${bay}-${BayLevelEnum.ABOVE}`]) === null || _c === void 0 ? void 0 : _c.perSlotInfo)) === null || _d === void 0 ? void 0 : _d.sizes) || []),
                ...(((_f = getSizesFromSlots((_e = blsDict[`${bay}-${BayLevelEnum.BELOW}`]) === null || _e === void 0 ? void 0 : _e.perSlotInfo)) === null || _f === void 0 ? void 0 : _f.sizes) || []),
                ...(paired
                    ? ((_h = getSizesFromSlots((_g = blsDict[`${paired}-${BayLevelEnum.ABOVE}`]) === null || _g === void 0 ? void 0 : _g.perSlotInfo)) === null || _h === void 0 ? void 0 : _h.sizes) || []
                    : []),
                ...(paired
                    ? ((_k = getSizesFromSlots((_j = blsDict[`${paired}-${BayLevelEnum.BELOW}`]) === null || _j === void 0 ? void 0 : _j.perSlotInfo)) === null || _k === void 0 ? void 0 : _k.sizes) || []
                    : []),
            ]);
            const id = paired ? `${bay}-${paired}` : bay;
            const calcLen = maxSize === -Infinity ? (paired ? 40 : 20) : undefined;
            const lcgs = [lcgsBy20Bay[bay].foreLcg, lcgsBy20Bay[bay].aftLcg];
            if (paired) {
                lcgs.push(lcgsBy20Bay[paired].foreLcg, lcgsBy20Bay[paired].aftLcg);
            }
            const lashingBridges = ((_l = blsDict[`${bay}-${BayLevelEnum.ABOVE}`]) === null || _l === void 0 ? void 0 : _l.lashingBridges) ||
                (paired &&
                    ((_m = blsDict[`${paired}-${BayLevelEnum.ABOVE}`]) === null || _m === void 0 ? void 0 : _m.lashingBridges)) ||
                undefined;
            const bulkheads = ((_o = blsDict[`${bay}-${BayLevelEnum.BELOW}`]) === null || _o === void 0 ? void 0 : _o.bulkhead) ||
                (paired && ((_p = blsDict[`${paired}-${BayLevelEnum.BELOW}`]) === null || _p === void 0 ? void 0 : _p.bulkhead)) ||
                undefined;
            const positionX = posX(Math.max(...lcgs));
            baySlots.push({
                id,
                type: VesselPartTypeEnum.BAY,
                label: id,
                lashingBridges,
                bulkheads,
                len: maxSize,
                calcLen,
                maxSize,
                positionX,
            });
            lastPositionXAft = positionX + maxSize / 100;
            processed.push(bay);
            if (paired)
                processed.push(paired);
            return;
        }
        if (typeof b !== "object")
            return;
        let len = 0;
        switch (b.type) {
            case VesselPartTypeEnum.CRANE:
                len = lenToVOne(b.len || 10000);
                baySlots.push({
                    id: b.id,
                    type: VesselPartTypeEnum.CRANE,
                    label: b.label,
                    cranes: b.cranes,
                    len,
                    positionX: lastPositionXAft,
                    original: b,
                });
                break;
            case VesselPartTypeEnum.BRIDGE:
                len = lenToVOne(b.len || 40000);
                baySlots.push({
                    id: b.id,
                    type: VesselPartTypeEnum.BRIDGE,
                    label: b.label,
                    heatSrcBelow: b.heatSrcBelow,
                    len,
                    positionX: lastPositionXAft,
                    original: b,
                });
                break;
            case VesselPartTypeEnum.SMOKE:
                len = lenToVOne(b.len || 20000);
                baySlots.push({
                    id: b.id,
                    type: VesselPartTypeEnum.SMOKE,
                    label: b.label,
                    numberOfSmokeStacks: b.numberOfSmokeStacks,
                    len,
                    positionX: lastPositionXAft,
                    original: b,
                });
                break;
            case VesselPartTypeEnum.SPACER:
                len = lenToVOne(b.len || 0);
                baySlots.push({
                    id: b.id,
                    type: VesselPartTypeEnum.SPACER,
                    label: b.label,
                    len,
                    positionX: lastPositionXAft,
                    original: b,
                });
                break;
        }
        lastPositionXAft += len;
    });
    return baySlots;
}
