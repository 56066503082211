import { getTranslation, i18nReactive } from "../i18/i18tn";

import LenUnitsEnum from "../enums/LenUnitsEnum";
import MassUnitsEnum from "../enums/MassUnitsEnum";
import { PortStarboardEnum } from "open-vessel-definition";
import UoLcgReferenceEnum from "../enums/UoLcgReferenceEnum";
import VcgVerticalReferenceEnum from "../enums/VcgVerticalReferenceEnum";
import globalUnits from "./globalUnits";
import { stylesFromStringToTag } from "@tedivo/tedivo-dom-helpers";

const AUTO_NO_SCROLL_MIN_WIDTH = 1300;

export enum ScrollModeEnum {
  NO_SCROLL = 1,
  SCROLL = 2,
  AUTO = 3,
}

export class UnitsScrollerElement extends HTMLElement {
  public static observedAttributes = [];

  public align: "left" | "center" | "right" = "left";

  private node: HTMLSpanElement;
  private nodeScroller: HTMLSpanElement;
  private scrollMode = ScrollModeEnum.SCROLL;
  private blockMode = false;

  constructor() {
    super();

    this.node = document.createElement("span");
    this.node.className = "holder";
    this.node.setAttribute("part", "holder");

    this.nodeScroller = document.createElement("span");
    this.nodeScroller.className = "scroller";
  }

  get doScroll() {
    return this.scrollMode;
  }

  set doScroll(v: ScrollModeEnum) {
    switch (v) {
      case ScrollModeEnum.SCROLL:
        this.nodeScroller.className = "scroller";
        break;
      case ScrollModeEnum.NO_SCROLL:
        this.nodeScroller.className = "no-scroller";
        break;
      case ScrollModeEnum.AUTO:
        this.changeClassDueToSize();
        break;
    }

    // Remove previous listener
    if (v !== ScrollModeEnum.AUTO && this.scrollMode === ScrollModeEnum.AUTO) {
      window.removeEventListener("resize", this.onWindowResize, false);
    }

    // Add listener if AUTO
    if (v === ScrollModeEnum.AUTO) {
      window.addEventListener("resize", this.onWindowResize, false);
    }

    this.scrollMode = v;
    this.update();
  }

  get block() {
    return this.blockMode;
  }

  set block(v: boolean) {
    this.blockMode = v;
    this.node.className = v ? "holder holder-block" : "holder";
    this.update();
  }

  connectedCallback() {
    try {
      const shadowRoot = this.attachShadow({ mode: "open" });

      this.node.appendChild(this.nodeScroller);

      shadowRoot.appendChild(stylesFromStringToTag(createStyles(this.align)));
      shadowRoot.appendChild(this.node);

      // document.body.addEventListener("globalUnitsChanged", this.update, false);

      i18nReactive.addEventListener("updateI18Consumers", this.update);

      this.update();
    } catch (e) {
      //Ommit
    }
  }

  disconnectedCallBack() {
    if (this.scrollMode === ScrollModeEnum.AUTO) {
      window.removeEventListener("resize", this.onWindowResize, false);
    }

    i18nReactive.removeEventListener("updateI18Consumers", this.update);
  }

  update = () => {
    const isScrolling = this.nodeScroller.className === "scroller";
    const separator = isScrolling ? "<br />" : "&nbsp; &middot; &nbsp;";
    const text: (string | undefined)[] = [
      getTranslation(
        `enums:unitsAbbreviated.LenUnitsEnum.${
          LenUnitsEnum[globalUnits.lengthUnits.units]
        }`,
      ),
      getTranslation(
        `enums:unitsAbbreviated.MassUnitsEnum.${
          MassUnitsEnum[globalUnits.massUnits.units]
        }`,
      ),
      getTranslation(
        `enums:unitsAbbreviated.UoLcgReferenceEnum.${
          UoLcgReferenceEnum[globalUnits.lcgTransposer.transposeTo]
        }`,
      ),
      getTranslation(
        `enums:unitsAbbreviated.TcgPortStarboardEnum.${
          PortStarboardEnum[globalUnits.tcgTransposer.transposeTo]
        }`,
      ),
      getTranslation(
        `enums:unitsAbbreviated.VcgVerticalReferenceEnum.${
          VcgVerticalReferenceEnum[globalUnits.vcgTransposer.transposeTo]
        }`,
      ),
      isScrolling
        ? getTranslation(
            `enums:unitsAbbreviated.LenUnitsEnum.${
              LenUnitsEnum[globalUnits.lengthUnits.units]
            }`,
          )
        : undefined,
    ];

    this.nodeScroller.innerHTML = text
      .map((t) => {
        if (t === undefined) return undefined;
        const ps = t.split(":");
        return `${ps[0]}:<em>${ps[1]}</em>`;
      })
      .filter(Boolean)
      .join(separator);

    this.node.title = text.filter((_, idx) => idx !== 5).join("\n");
  };

  private changeClassDueToSize = () => {
    this.nodeScroller.className =
      window.innerWidth < AUTO_NO_SCROLL_MIN_WIDTH ? "scroller" : "no-scroller";
  };

  private onWindowResize = () => {
    this.changeClassDueToSize();
    this.update();
  };
}

customElements.define("units-scroller-element", UnitsScrollerElement);

declare global {
  interface HTMLElementTagNameMap {
    "units-scroller-element": UnitsScrollerElement;
  }
}

const createStyles = (align: "left" | "center" | "right" = "left") => `
  .holder {
    display: inline-flex;
    height: 30px;
    overflow: hidden;
    margin: 0 0 0.5em 0;
    line-height: 30px;
  }

  .scroller {
    margin: 0;
    line-height: 30px;
    font-size: 0.9em;
    text-align: ${align};
    transition: all;
    transition-timing-function: ease-out;
    animation-duration: 10s;
    animation-name: scrollUp;
    animation-iteration-count: infinite;
    color: var(--sl-color-neutral-600);
    display: block;
    height: 180px;
    color: var(--sl-color-neutral-600);
  }

  .no-scroller {
    margin: 0;
    line-height: 30px;
    font-size: 0.9em;
    text-align: ${align};
    display: block;
    color: var(--sl-color-neutral-600);
  }

  .scroller em,
  .no-scroller em {
    color: var(--sl-color-green-500);
    font-style: normal;
  }

  @keyframes scrollUp {
    0% { transform: translateY(0) }
    15% { transform: translateY(0) }
    20% { transform: translateY(-30px) }
    35% { transform: translateY(-30px) }
    40% { transform: translateY(-60px) }
    55% { transform: translateY(-60px) }
    60% { transform: translateY(-90px) }
    75% { transform: translateY(-90px) }
    80% { transform: translateY(-120px) }
    95% { transform: translateY(-120px) }
    99.99% { transform: translateY(-150px) }
    100% { transform: translateY(0) }
  }
`;
