import {
  ALL_SINGLE_RENDER_METHODS,
  OVD_EVENT_TO_SECTIONS_MAP,
} from "./renderSectionsMap";
import BaysSection, { IBaysDrawOptions } from "../parts/bay-boxes/bays-section";
import {
  DivWithSpinner,
  IDictionaryDisplayElement,
  mapDataToDictionary,
} from "@tedivo/tedivo-ui";
import {
  IBayLevelData,
  IOpenVesselDefinitionV1,
  IShipData,
  ISizeSummary,
  ISlotData,
} from "open-vessel-definition";
import {
  IBayPattern,
  IGetBayLcgVcgAndPairingsResult,
  ISlotCombinedPattern,
  calculateTeusFromSlots,
} from "@tedivo/tedivo-bay-grid-pure";
import ViewCgsConfig, { ICgsPartData } from "../config/ViewCgsConfig";
import { getTranslation, i18n } from "../../../../app/i18/i18tn";
import ovdJsonStore, {
  ICustomOVDChangeEvent,
} from "../../../../app/stores/OVDJsonStore";

import CellSpacingEnum from "../../../../app/enums/CellSpacingEnum";
import GroupBaysPairedEnum from "../../../../app/enums/GroupBaysPairedEnum";
import { IFilesTags } from "@tedivo/tvd-api-models";
import type RendererForShip from "../parts/edits/helpers/viewEdit3D/draw/render/RendererForShip";
import { SelectShoelace } from "@tedivo/tedivo-form";
import SizeSmallMidLargeEnum from "../../../../app/enums/SizeSmallMidLargeEnum";
import SlTooltip from "@shoelace-style/shoelace/dist/components/tooltip/tooltip.component";
import { ViewJsonTypeEnum } from "../ViewJsonTypeEnum";
import ViewShipDataConfig from "../config/ViewShipDataConfig";
import ViewSizeSummaryConfig from "../config/ViewSizeSummaryConfig";
import { createDeckView } from "./createDeckView";
import { createPart } from "./createPart";
import { createSideView } from "./createSideView";
import { getPreferencesValue } from "@tedivo/tedivo-preferences";
import getTranslatedDictionary from "../../../../app/i18/getTranslatedDictionary";
import { removeChildren } from "@tedivo/tedivo-dom-helpers";

export default class ViewJsonRender {
  private nodes: ViewJsonParts;

  private mainNode: HTMLDivElement;

  private props: ViewJsonRenderProps;
  private context: {
    totalTEUs?: number;
    buttonsAndActions?: IEditButtonsAndActions;
  } = {};

  private rendererForShip: RendererForShip | undefined = undefined;
  private baysSection: BaysSection | undefined;
  private bayDrawOptions: IBaysDrawOptions;

  get node() {
    return this.mainNode;
  }

  set viewD3ClearColor(color: "dark" | "light") {
    if (!this.rendererForShip) return;
    this.rendererForShip.colorMode = color;
  }

  get bayGroupedDisplay() {
    return this.bayDrawOptions.baysPairedGrouped;
  }
  set bayGroupedDisplay(value: GroupBaysPairedEnum) {
    if (this.bayDrawOptions.baysPairedGrouped !== value)
      this.bayDrawOptions.baysPairedGrouped = value;

    this.nodes.baySection.bayBoxes.setAttribute(
      "data-group-paired-bays",
      String(value === GroupBaysPairedEnum.GROUPED),
    );
  }

  get fit2Vertical() {
    return this.bayDrawOptions.fit2Vertical;
  }
  set fit2Vertical(value: boolean) {
    if (this.bayDrawOptions.fit2Vertical !== value)
      this.bayDrawOptions.fit2Vertical = value;

    this.baysSection?.calcAndSetScale(value);
  }

  constructor(props: ViewJsonRenderProps) {
    this.props = props;
    this.bayDrawOptions = getBayDrawPreferences();

    this.mainNode = document.createElement("div");
    this.nodes = this.buildHtml(this.mainNode);
    this.bayGroupedDisplay = this.bayDrawOptions.baysPairedGrouped;
    this.fit2Vertical = this.bayDrawOptions.fit2Vertical;

    this.render(props.json, undefined, props.lcgVcgTcgAndPairings);
  }

  public updateData({
    event,
    json,
    lcgVcgTcgAndPairings,
  }: {
    event?: CustomEvent<ICustomOVDChangeEvent>;
    json: IOpenVesselDefinitionV1;
    lcgVcgTcgAndPairings?: IGetBayLcgVcgAndPairingsResult;
  }) {
    if (!json || !lcgVcgTcgAndPairings) {
      console.warn("No data to render");
      return;
    }

    // 1. Update data
    this.props.json = json;
    this.props.lcgVcgTcgAndPairings = lcgVcgTcgAndPairings;

    this.render(json, event, lcgVcgTcgAndPairings);
  }

  /** Updates the size and spacing options and redraws all the bays */
  public updateDrawPreferences(
    size: SizeSmallMidLargeEnum | undefined,
    cellSpacing: CellSpacingEnum | undefined,
    newBayGrouped: GroupBaysPairedEnum | undefined,
    fit2Vertical: boolean | undefined,
  ) {
    if (size !== undefined) this.bayDrawOptions.cellSize = size;

    if (cellSpacing !== undefined)
      this.bayDrawOptions.cellSpacing = cellSpacing;

    if (newBayGrouped !== undefined) this.bayGroupedDisplay = newBayGrouped;

    if (fit2Vertical !== undefined) this.fit2Vertical = fit2Vertical;

    this.renderBays();
  }

  private renderGeneral = () => {
    const json = this.props.json;
    if (!json) return;

    this.nodes.general.withLoading<void>(async () => {
      const shipNameAkas = (
        ovdJsonStore.tvdId?.shipNameAkaStr?.split("|") || []
      ).filter((s) => s && s !== json.shipData.shipName);
      const viewShipData = {
        ...json.shipData,
        shipNameAkas: shipNameAkas.length ? shipNameAkas : undefined,
      };

      const shipPartDataElements = getTranslatedDictionary(
        mapDataToDictionary<IShipData>(
          viewShipData,
          ViewShipDataConfig,
          i18n.language,
        ),
      );

      const partConfig: IPartConfig = {
        name: "shipData",
        title: "view:shipDataTitle",
        elements: shipPartDataElements,
        editAction: this.context.buttonsAndActions?.editGeneralButton,
      };

      const htmlOutput = createPart(
        partConfig.name,
        partConfig.title,
        partConfig.elements,
        partConfig.editAction,
      );

      removeChildren(this.nodes.general);
      this.nodes.general.dataset.lastUpdated = new Date().toISOString();
      this.nodes.general.appendChild(htmlOutput);
    });
  };

  private renderSizeSummary = () => {
    const json = this.props.json;
    if (!json) return;

    this.nodes.sizeSummary.withLoading<void>(async () => {
      const sizeSummaryDataElements = getTranslatedDictionary(
        mapDataToDictionary<ISizeSummary & { totalTEUs: number }>(
          { ...json.sizeSummary, totalTEUs: this.context.totalTEUs || 0 },
          ViewSizeSummaryConfig,
          i18n.language,
        ),
      );

      const partConfig: IPartConfig = {
        name: "sizeSummary",
        title: "view:sizeSummaryTitle",
        elements: sizeSummaryDataElements,
        editAction: this.context.buttonsAndActions?.editSizeSummaryButton,
      };

      const htmlOutput = createPart(
        partConfig.name,
        partConfig.title,
        partConfig.elements,
        partConfig.editAction,
      );

      removeChildren(this.nodes.sizeSummary);
      this.nodes.sizeSummary.dataset.lastUpdated = new Date().toISOString();
      this.nodes.sizeSummary.appendChild(htmlOutput);
    });
  };

  private renderCenterOfGravity = () => {
    const json = this.props.json;
    const cgsStats = this.props.lcgVcgTcgAndPairings?.cgsStats;

    if (!json || !cgsStats) return;

    this.nodes.centersOfGravity.withLoading<void>(async () => {
      const cgsPartData: ICgsPartData = {
        lcgValues: json.shipData.lcgOptions.values,
        vcgValues: json.shipData.vcgOptions.values,
        tcgValues: json.shipData.tcgOptions.values,
        lcgDataFilled: Math.floor(
          (cgsStats.definedLcgs / (cgsStats.countLcgs || 1)) * 100,
        ),
        vcgDataFilled: Math.floor(
          (cgsStats.definedBottomBase / (cgsStats.countBottomBase || 1)) * 100,
        ),
        tcgDataFilled: Math.floor(
          (cgsStats.definedTcgs / (cgsStats.countTcgs || 1)) * 100,
        ),
        lpp: json.shipData.lcgOptions.lpp,
        loa: json.shipData.loa || 0,
        sternToAftPp: json.shipData.sternToAftPp || 0,
      };

      const cgsPartDataElements = getTranslatedDictionary(
        mapDataToDictionary<ICgsPartData>(
          cgsPartData,
          ViewCgsConfig,
          i18n.language,
        ),
      );

      const partConfig: IPartConfig = {
        name: "cgsSummary",
        title: "view:cgsDataTitle",
        elements: cgsPartDataElements,
        disabled: !this.props.canOpenDrawer,
        editAction: this.context.buttonsAndActions?.editCGsButton,
      };

      const htmlOutput = createPart(
        partConfig.name,
        partConfig.title,
        partConfig.elements,
        partConfig.editAction,
      );

      removeChildren(this.nodes.centersOfGravity);
      this.nodes.centersOfGravity.dataset.lastUpdated =
        new Date().toISOString();
      this.nodes.centersOfGravity.appendChild(htmlOutput);
    });
  };

  private renderSideView() {
    const json = this.props.json;
    const lcgVcgTcgAndPairings = this.props.lcgVcgTcgAndPairings;

    if (!json || !lcgVcgTcgAndPairings) return;

    this.nodes.sideView.withLoading<void>(async () => {
      const htmlOutput = createSideView({
        sizeSummary: json.sizeSummary,
        baysData: json.baysData,
        vesselPartsData: json.vesselPartsData || [],
        adjustedBottomBases: ovdJsonStore.tvdId?.v3DParams?.adjustedBottomBases,
        lcgVcgTcgAndPairings,
        editActionNode: this.context.buttonsAndActions?.editSideViewActions,
        bayNumbersDoubleClickAction: doubleClickOnSvgBay,
      });

      removeChildren(this.nodes.sideView);
      this.nodes.sideView.dataset.lastUpdated = new Date().toISOString();
      this.nodes.sideView.appendChild(htmlOutput);
    });
  }

  private renderDeckView() {
    const json = this.props.json;
    const lcgVcgTcgAndPairings = this.props.lcgVcgTcgAndPairings;

    if (!json || !lcgVcgTcgAndPairings) return;

    const htmlOutput = createDeckView({
      sizeSummary: json.sizeSummary,
      masterCGs: json.shipData.masterCGs,
      baysData: json.baysData,
      lidData: json.lidData,
      vesselPartsData: json.vesselPartsData || [],
      lcgVcgTcgAndPairings,
      editActionNode: this.context.buttonsAndActions?.editDeckViewActions,
      bayNumbersDoubleClickAction: doubleClickOnSvgBay,
    });

    removeChildren(this.nodes.deckView);
    this.nodes.deckView.dataset.lastUpdated = new Date().toISOString();
    this.nodes.deckView.appendChild(htmlOutput);
  }

  private renderD3View = async () => {
    const json = this.props.json;
    const lcgVcgTcgAndPairings = this.props.lcgVcgTcgAndPairings;
    if (
      !json ||
      !lcgVcgTcgAndPairings ||
      lcgVcgTcgAndPairings.totalSlotsCount < 10
    )
      return;

    // if (!this.rendererForShip) {
    //   import(
    //     /* webpackChunkName: "create3DView", webpackPrefetch: true */ "./create3DView"
    //   ).then((module) => {
    //     const { container, renderer } = module.create3DView({
    //       shipData: json.shipData,
    //       sizeSummary: json.sizeSummary,
    //       baysData: json.baysData,
    //       vesselPartsData: json.vesselPartsData || [],
    //       lcgVcgTcgAndPairings,
    //       adjustedBottomBases:
    //         ovdJsonStore.tvdId?.v3DParams?.adjustedBottomBases,
    //       v3DParams: ovdJsonStore.tvdId?.v3DParams,
    //       colorMode: this.props.colorMode,
    //     });

    //     this.rendererForShip = renderer;

    //     const { card, toggleActivate } = create3DCardNodes(
    //       this.rendererForShip,
    //       this.context.buttonsAndActions?.editView3DActions,
    //     );

    //     card.isLoading = false;
    //     card.appendChild(container);
    //     card.appendChild(toggleActivate);

    //     removeChildren(this.nodes.d3View);
    //     this.nodes.d3View.dataset.lastUpdated = new Date().toISOString();
    //     this.nodes.d3View.appendChild(card);

    //     renderer.onWindowResize();
    //     renderer.shouldAnimate = 3000;
    //     renderer.animate();

    //     setTimeout(() => {
    //       renderer.resetView();
    //       renderer.active = false;
    //       toggleActivate.checked = false;
    //     }, 3000);
    //   });
    // } else {
    //   this.rendererForShip.reInit({
    //     shipData: json.shipData,
    //     sizeSummary: json.sizeSummary,
    //     baysData: json.baysData,
    //     vesselPartsData: json.vesselPartsData || [],
    //     lcgVcgTcgAndPairings,
    //     adjustedBottomBases: ovdJsonStore.tvdId?.v3DParams?.adjustedBottomBases,
    //     v3DParams: ovdJsonStore.tvdId?.v3DParams,
    //   });
    // }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function create3DCardNodes(
      rendererForShip: RendererForShip | undefined,
      editView3DActions: HTMLElement | undefined,
    ) {
      // Action Box
      const view3DActionBox = document.createElement("div");

      // Reset View Button
      const reset3DViewButton = document.createElement("sl-button");
      reset3DViewButton.size = "small";
      reset3DViewButton.pill = true;
      reset3DViewButton.innerHTML = getTranslation(
        "view:view3D.view3DActions.reset",
      );
      reset3DViewButton.addEventListener("click", () => {
        rendererForShip?.resetView();
      });

      view3DActionBox.appendChild(reset3DViewButton);

      // Edit Button
      if (editView3DActions) view3DActionBox.appendChild(editView3DActions);

      // Card of 3D View
      const card = document.createElement("card-element");
      card.titleHtml = "view:3dView.title";
      card.className = "card-3d-view";
      card.name = "3dView";
      card.isLoading = true;
      card.actions = view3DActionBox;

      // Activate toggle Button
      const toggleActivate = document.createElement("sl-switch");
      toggleActivate.size = "small";
      toggleActivate.innerHTML = getTranslation(
        "view:view3D.view3DActions.activate",
      );
      toggleActivate.className = "btn-activate-3d";
      toggleActivate.addEventListener("sl-change", () => {
        if (!rendererForShip) return;
        rendererForShip.active = toggleActivate.checked;
      });

      return {
        card,
        toggleActivate,
      };
    }
  };

  private renderBays(eventDetail?: ICustomOVDChangeEvent) {
    const bays = eventDetail?.data?.map((bay) => bay.split("-")[0]) as
      | IBayPattern[]
      | undefined;

    const btnsAndActions = this.context.buttonsAndActions;
    if (!btnsAndActions) return;

    if (!this.baysSection) {
      this.baysSection = new BaysSection({
        createEditBayButtonFunction: btnsAndActions.createEditBayButtonFunction,
        readonlyMode: this.props.readonlyMode,
        canOpenDrawer: this.props.canOpenDrawer,
        bayHelpersButton: btnsAndActions.bayHelpersButton,
      });

      this.baysSection?.calcAndSetScale(this.bayDrawOptions.fit2Vertical);

      this.nodes.baySection.bayBoxes.appendChild(this.baysSection.mainNode);
    }

    this.baysSection.render({
      lcgVcgTcgAndPairings: this.props.lcgVcgTcgAndPairings,
      bayDrawOptions: this.bayDrawOptions,
      baysToRender: bays,
    });
  }

  private buildHtml(mainNode: HTMLDivElement): ViewJsonParts {
    const nodes: ViewJsonParts = {
      general: document.createElement("div-spinner-element"),
      sizeSummary: document.createElement("div-spinner-element"),
      centersOfGravity: document.createElement("div-spinner-element"),
      sideView: document.createElement("div-spinner-element"),
      deckView: document.createElement("div-spinner-element"),
      d3View: document.createElement("div-spinner-element"),
      baySection: {
        holder: document.createElement("div-spinner-element"),
        legend: document.createElement("div"),
        bayBoxes: document.createElement("div"),
      },
    };

    nodes.baySection.holder.appendChild(nodes.baySection.legend);
    nodes.baySection.holder.appendChild(nodes.baySection.bayBoxes);

    mainNode.appendChild(nodes.general);
    mainNode.appendChild(nodes.sizeSummary);
    mainNode.appendChild(nodes.centersOfGravity);
    mainNode.appendChild(nodes.sideView);
    mainNode.appendChild(nodes.deckView);
    mainNode.appendChild(nodes.d3View);
    mainNode.appendChild(nodes.baySection.holder);

    return nodes;
  }

  render = (
    json: IOpenVesselDefinitionV1 | undefined,
    event: CustomEvent<ICustomOVDChangeEvent> | undefined,
    lcgVcgTcgAndPairings: IGetBayLcgVcgAndPairingsResult | undefined,
  ) => {
    if (!json || !lcgVcgTcgAndPairings) {
      console.warn("No data to render");
      return;
    }
    // 1. Update context
    this.context.totalTEUs = calculateTeusFromSlots(json.baysData);

    this.context.buttonsAndActions = this.props.createEditButtonsAndActions(
      lcgVcgTcgAndPairings, //this.props.lcgVcgTcgAndPairings,
    );

    // 2. Select render methods
    const type = event?.detail?.type;
    const renderMethods = type
      ? OVD_EVENT_TO_SECTIONS_MAP[type] || ALL_SINGLE_RENDER_METHODS
      : ALL_SINGLE_RENDER_METHODS;

    // 3. Render
    renderMethods.forEach((method) => this[method](event?.detail));
  };
}

function doubleClickOnSvgBay(node: SVGElement | HTMLElement) {
  const bay = node.dataset.bay;
  const el = document.getElementById(`bay-box-component-${bay}`);
  if (el) el.scrollIntoView({ block: "center", behavior: "auto" });
}

function getBayDrawPreferences(): IBaysDrawOptions {
  const baySizeOption = getPreferencesValue(
    "bay-data-size",
  ) as SizeSmallMidLargeEnum;

  const bayCellSeparationOption = getPreferencesValue(
    "bay-cell-separation",
  ) as CellSpacingEnum;

  const bayGroupedPairedOption = getPreferencesValue(
    "bay-grouped-paired",
  ) as GroupBaysPairedEnum;

  const fit2Vertical = getPreferencesValue("bay-fit2-vertical") as
    | "true"
    | "false"
    | boolean;

  return {
    cellSize: baySizeOption ?? SizeSmallMidLargeEnum.SMALL,
    cellSpacing: bayCellSeparationOption ?? CellSpacingEnum.NO_SPACE,
    baysPairedGrouped:
      bayGroupedPairedOption ?? GroupBaysPairedEnum.NOT_GROUPED,
    fit2Vertical: fit2Vertical === true || fit2Vertical === "true",
  };
}

type ViewJsonRenderProps = {
  json: IOpenVesselDefinitionV1 | undefined;
  fileId: string;
  fileOrgId: string;
  currentOrgId: string;
  canOpenDrawer: boolean;
  readonlyMode: boolean;
  viewType: ViewJsonTypeEnum;
  version?: string;
  fileTags: IFilesTags;
  lcgVcgTcgAndPairings?: IGetBayLcgVcgAndPairingsResult;
  colorMode: "dark" | "light";
  createEditButtonsAndActions: (
    lcgVcgTcgAndPairings: IGetBayLcgVcgAndPairingsResult,
  ) => IEditButtonsAndActions;
};

interface ViewJsonParts {
  general: DivWithSpinner;
  sizeSummary: DivWithSpinner;
  centersOfGravity: DivWithSpinner;
  sideView: DivWithSpinner;
  deckView: DivWithSpinner;
  d3View: DivWithSpinner;
  baySection: {
    holder: DivWithSpinner;
    legend: HTMLElement;
    bayBoxes: HTMLElement;
  };
}

type IPartConfig = {
  name: string;
  title: string;
  elements: IDictionaryDisplayElement[];
  editAction?: HTMLElement;
  disabled?: boolean;
};

export interface IEditButtonsAndActions {
  editCGsButton: SlTooltip | SelectShoelace<string>;
  editSideViewActions: SlTooltip | SelectShoelace<string>;
  editGeneralButton: SlTooltip | SelectShoelace<string> | HTMLElement;
  editSizeSummaryButton: SlTooltip | SelectShoelace<string> | HTMLElement;
  editDeckViewActions: SlTooltip | SelectShoelace<string> | HTMLElement;
  editView3DActions: SlTooltip | SelectShoelace<string> | HTMLElement;
  bayHelpersButton: SlTooltip | SelectShoelace<string> | HTMLElement;

  createEditBayButtonFunction: TEditBayButtonFunction;
}

export type TEditBayButtonFunction = (
  isoBay: IBayPattern,
  sizeSummary: ISizeSummary,
  shipData: IShipData,
  bayAbove: IBayLevelData | undefined,
  bayBelow: IBayLevelData | undefined,
  enabledCells: ISlotCombinedPattern[],
  slotsDataAbove: ISlotData[],
  slotsDataBelow: ISlotData[],
) => SlTooltip | SelectShoelace<string>;
