import { I18nComponentRegisterer } from "@tedivo/tedivo-i18";
import { IOrganizationsCognitoAttributeAugmented } from "@tedivo/tvd-api-models";
import Services from "../../../app/services";
import SlButton from "@shoelace-style/shoelace/dist/components/button/button";
import awsCognito from "../../../app/security/awsCognito";
import { i18nReactive } from "../../../app/i18/i18tn";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import securityModule from "../../../app/security/SecurityModule";

export class TVDTopNotificationsElement extends HTMLElement {
  public static observedAttributes = [];

  private i18nCR: I18nComponentRegisterer;
  private btn: SlButton;
  private inThrottle = 0;

  private set btnLookAvailable(notificationsAvailable: boolean) {
    if (notificationsAvailable) {
      this.btn.variant = "warning";
      this.btn.outline = true;
    } else {
      this.btn.variant = "default";
      this.btn.outline = false;
    }
  }

  constructor() {
    super();
    this.i18nCR = new I18nComponentRegisterer(i18nReactive);
    this.btn = document.createElement("sl-button");
  }

  connectedCallback() {
    const icon = document.createElement("sl-icon");
    icon.name = "bell";

    const btn = this.btn;
    btn.appendChild(icon);
    btn.pill = true;
    btn.size = "small";
    btn.outline = false;

    this.appendChild(btn);

    this.i18nCR.addConsumer(btn, "menu:notifications", "title");

    btn.addEventListener("click", () => {
      router.navigate(routeFns.notificationsList());
    });

    awsCognito.addEventListener("authenticationChanged", this.onAuthChanged);
    awsCognito.addEventListener("userDataRetrieved", this.onOrgChanged);
  }

  disconnectedCallback() {
    this.i18nCR.disconnect();

    awsCognito.removeEventListener("authenticationChanged", this.onAuthChanged);
    awsCognito.removeEventListener("userDataRetrieved", this.onOrgChanged);
  }

  /** Update notifications "bell" when Auth changes */
  private onAuthChanged = ((ev: CustomEvent<{ isLoggedIn: boolean }>): void => {
    const { isLoggedIn } = ev.detail;
    if (isLoggedIn && securityModule.currentOrganizationId) {
      this.updateNotificationsUI();
    } else {
      this.btnLookAvailable = false;
    }
  }) as EventListener;

  /** Update notifications "bell" when Organization changes */
  private onOrgChanged = ((
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ev: CustomEvent<IOrganizationsCognitoAttributeAugmented>,
  ): void => {
    if (securityModule.isLoggedIn && securityModule.currentOrganizationId)
      this.updateNotificationsUI();
  }) as EventListener;

  /** Internal: Call service to update notifications "bell" */
  private async updateNotificationsUI() {
    window.clearTimeout(this.inThrottle);
    this.inThrottle = window.setTimeout(async () => {
      const notifications = await Services.notifications.getAllCountOnly();
      this.btnLookAvailable = !!notifications.data;
    }, 250);
  }
}

customElements.define(
  "tvd-notifications-component",
  TVDTopNotificationsElement,
);

declare global {
  interface HTMLElementTagNameMap {
    "tvd-notifications-component": TVDTopNotificationsElement;
  }
}
