import {
  ICognitoUser,
  ICognitoUserWithOrgData,
  IInvoice,
  IOrganization,
  ISingleOrganizationAndUsers,
  TUserRole,
} from "@tedivo/tvd-api-models";
import {
  IFields,
  IFormReturn,
  TedivoForm,
  translateTedivoForm,
} from "@tedivo/tedivo-form";
import { ITable, SmartTable } from "@tedivo/tedivo-smart-table";
import OrgPlanTypeEnum, {
  ORG_PLAN_OPTIONS,
} from "../../../app/enums/OrgPlanTypeEnum";
import { getTranslation, i18n, i18nReactive } from "../../../app/i18/i18tn";

import BreadcrumbsElement from "../../common/misc/breadcrumbs.element";
import { DivWithSpinner } from "@tedivo/tedivo-ui";
import { EditDrawer } from "../../common/EditDrawer";
import { I18nComponentRegisterer } from "@tedivo/tedivo-i18";
import ICustomError from "../../types/ICustomError";
import { IntegratedDialogError } from "@tedivo/tedivo-ui";
import Services from "../../../app/services";
import SlButton from "@shoelace-style/shoelace/dist/components/button/button";
import SlDialog from "@shoelace-style/shoelace/dist/components/dialog/dialog";
import UserTypeEnum from "../../../app/enums/UserTypeEnum";
import { formatDateInCurrentTimezone } from "@tedivo/tedivo-pure-helpers";
import globalStore from "../../../app/stores/globalStore";
import goSquared from "../../../app/tracking/goSquared";
import { removeChildren } from "@tedivo/tedivo-dom-helpers";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import { setAppTitle } from "../../../app/setAppTitle";
import { showDeleteModal } from "../../common/ListComponentWithActions";
import topMessageElement from "../../layout/top-tools/getTopMessageElement";
import { z } from "zod";

export class TVDAdminOrgsUsersComponent extends HTMLElement {
  public static observedAttributes = [];

  private i18nCR: I18nComponentRegisterer;
  private dialogError: IntegratedDialogError;
  private usersWrapper: DivWithSpinner;
  private invoicesWrapper: DivWithSpinner;
  private mainUsersBox: HTMLDivElement;
  private mainInvoicesBox: HTMLDivElement;
  private deleteDialog: SlDialog;
  private breadcrumbs: BreadcrumbsElement;
  private editDrawer: EditDrawer;

  private data: ISingleOrganizationAndUsers | undefined;

  constructor() {
    super();
    this.i18nCR = new I18nComponentRegisterer(i18nReactive);

    this.dialogError = new IntegratedDialogError(
      this,
      false,
      getTranslation("general:common.close"),
    );
    this.usersWrapper = document.createElement("div-spinner-element");
    this.invoicesWrapper = document.createElement("div-spinner-element");

    this.deleteDialog = document.createElement("sl-dialog");
    this.breadcrumbs = new BreadcrumbsElement();
    this.editDrawer = new EditDrawer();

    this.mainUsersBox = document.createElement("div");
    this.mainUsersBox.className = "oss-card";
    this.mainInvoicesBox = document.createElement("div");
    this.mainInvoicesBox.className = "oss-card";

    setAppTitle(getTranslation("general:users.users"));
  }

  async connectedCallback() {
    goSquared.trackPage("TEDIVO Admin Pages - Users");
    goSquared.addEvent("Admin/Organization-Users - Show page");

    const usersWrapper = this.usersWrapper,
      invoicesWrapper = this.invoicesWrapper,
      breadcrumbs = this.breadcrumbs,
      h1 = document.createElement("h1");

    h1.innerHTML = "<small>loading...</small>";

    breadcrumbs.items = [
      {
        href: routeFns.adminOrganizations(),
        label: getTranslation("general:organizations.organizations"),
      },
      { label: "" },
    ];

    usersWrapper.appendChild(this.mainUsersBox);
    invoicesWrapper.appendChild(this.mainInvoicesBox);

    this.appendChild(this.editDrawer.drawer);
    this.appendChild(breadcrumbs);
    this.appendChild(h1);
    this.appendChild(usersWrapper);
    this.appendChild(invoicesWrapper);

    const orgId = router.getRouteParams().id;

    if (!orgId) {
      router.navigate(routeFns.adminOrganizations());
      return;
    }

    usersWrapper.setLoading(true);
    invoicesWrapper.setLoading(true);

    const resp = await Services.organizations.getSingleOrganizationAndUsers(
      orgId,
    );

    const orgData = resp.data;

    if (!orgData) {
      router.navigate(routeFns.adminOrganizations());
      return;
    }

    h1.innerHTML = orgData.organization?.name || "";

    this.displayOrganizationUsers(orgId, orgData);
    this.displayInvoices(orgId);

    this.i18nCR.addConsumer(
      topMessageElement.element,
      "general:adminArea",
      "innerHTML",
    );
  }

  private displayOrganizationUsers = async (
    orgId: string,
    orgData?: ISingleOrganizationAndUsers,
  ) => {
    globalStore.touchLastUserInteraction();

    const usersWrapper = this.usersWrapper;
    const mainUsersBox = this.mainUsersBox;
    removeChildren(mainUsersBox);

    const actionAddOrgButton = document.createElement("sl-button");
    actionAddOrgButton.innerHTML = getTranslation("general:users.adminCreate");
    actionAddOrgButton.variant = "primary";
    actionAddOrgButton.addEventListener(
      "click",
      () => {
        this.openOrganizationUserEditModal();
      },
      false,
    );

    usersWrapper.withLoading(async () => {
      try {
        const data =
          orgData ||
          (await Services.organizations.getSingleOrganizationAndUsers(orgId))
            .data;

        if (data) {
          this.data = data;

          mainUsersBox.appendChild(
            createUsersTable({
              data: data,
              actionBox: actionAddOrgButton,
              onEditUser: (u: ICognitoUserWithOrgData) => {
                this.openOrganizationUserEditModal({ ...u });
              },
              onDeleteUserFromOrg: this.deleteOrganizationUser,
            }),
          );
        }
      } catch (e) {
        this.showError(e as ICustomError);
      }
    });
  };

  private displayInvoices = async (orgId: string) => {
    globalStore.touchLastUserInteraction();

    const invoicesWrapper = this.invoicesWrapper;
    const mainInvoicesBox = this.mainInvoicesBox;
    removeChildren(mainInvoicesBox);

    const actionAddOrgButton = document.createElement("sl-button");
    actionAddOrgButton.innerHTML = getTranslation(
      "general:organizations.invoices.adminCreate",
    );
    actionAddOrgButton.variant = "primary";
    actionAddOrgButton.addEventListener(
      "click",
      () => {
        this.openInvoiceEditModal();
      },
      false,
    );

    invoicesWrapper.withLoading(async () => {
      try {
        const resp = await Services.organizations.getInvoicesOfOrg(orgId);
        const data = resp.data;
        if (!data) {
          return;
        }

        if (data) {
          mainInvoicesBox.appendChild(
            createInvoicesTable({
              invoices: data,
              actionBox: actionAddOrgButton,
              onEditFn: (u: IInvoice) => {
                this.openInvoiceEditModal({ ...u });
              },
              onDeleteFn: (pk: string, data: IInvoice) => {
                this.deleteInvoice(orgId, data);
              },
            }),
          );
        }
      } catch (e) {
        this.showError(e as ICustomError);
      }
    });
  };

  disconnectedCallback() {
    this.i18nCR.disconnect();
  }

  showError = (e: ICustomError) => {
    this.dialogError.show(
      getTranslation(`errors:errorHasOcurred`),
      getTranslation(typeof e === "string" ? e : e.translationKey),
    );
  };

  private openOrganizationUserEditModal = (
    prevData?: ICognitoUserWithOrgData,
  ) => {
    const orgId = router.getRouteParams().id;
    if (!orgId) return;

    const editForm = createOrgUserEdit({
      submitButton: this.editDrawer.submitButton,
      orgId,
      prevData,
      showError: this.showError,
      organizationData: this.data?.organization,
    });

    if (editForm) {
      const drawer = this.editDrawer.getEditDrawer({
        title: getTranslation("general:users.adminCreate"),
        showUnits: false,
        readOnlyMode: false,
        onSave: async () => {
          this.editDrawer.disabled = true;
          const valid = await editForm.submitFunction();
          this.editDrawer.disabled = false;

          if (valid) {
            this.displayOrganizationUsers(orgId);
          }

          return valid;
        },
      });
      drawer.appendChild(editForm.node);
      drawer.show();
    }
  };

  private deleteOrganizationUser = (
    orgId: string,
    dta: ICognitoUserWithOrgData,
  ) => {
    const dialog = this.deleteDialog;
    this.appendChild(dialog);

    const htmlContent = document.createElement("div");
    htmlContent.innerHTML = `${getTranslation(
      "general:users.delete",
    )}<br /><br /><strong>${dta.name} ${dta.familyName}</strong>`;

    showDeleteModal({
      dialog,
      htmlContent,
      deleteLabel: getTranslation("general:common.delete"),
      serviceDeleteCall: async () => {
        const resp = await Services.organizations.adminDeleteOrganizationUser(
          orgId,
          dta.sub,
        );
        this.displayOrganizationUsers(orgId);
        return resp;
      },
    });
  };

  private openInvoiceEditModal = (prevData?: IInvoice) => {
    const orgId = router.getRouteParams().id;
    if (!orgId) return;

    const editForm = createInvoicesEdit({
      submitButton: this.editDrawer.submitButton,
      prevData,
      orgId,
      orgName: this.data?.organization?.name || "",
    });

    if (editForm) {
      const drawer = this.editDrawer.getEditDrawer({
        title: getTranslation(
          `general:organizations.invoices.${
            prevData?.organizationId ? "adminEdit" : "adminCreate"
          }`,
        ),
        showUnits: false,
        readOnlyMode: false,
        onSave: async () => {
          this.editDrawer.disabled = true;
          const valid = await editForm.submitFunction();
          this.editDrawer.disabled = false;

          if (valid) {
            this.displayInvoices(orgId);
          }

          return valid;
        },
      });
      drawer.appendChild(editForm.node);
      drawer.show();
    }
  };

  private deleteInvoice = (orgId: string, dta: IInvoice) => {
    const dialog = this.deleteDialog;
    this.appendChild(dialog);

    const htmlContent = document.createElement("div");
    htmlContent.innerHTML = `${getTranslation(
      "general:organizations.invoices.delete",
    )}<br /><br /><strong>${dta.invoiceId}</strong>`;

    showDeleteModal({
      dialog,
      htmlContent,
      deleteLabel: getTranslation("general:common.delete"),
      serviceDeleteCall: async () => {
        const resp = await Services.organizations.deleteInvoice(dta.id, orgId);
        this.displayInvoices(orgId);
        return resp;
      },
    });
  };
}

customElements.define(
  "tvd-admin-orgs-users-component",
  TVDAdminOrgsUsersComponent,
);

declare global {
  interface HTMLElementTagNameMap {
    "tvd-admin-orgs-users-component": TVDAdminOrgsUsersComponent;
  }
}

function createUsersTable({
  data,
  actionBox,
  onEditUser,
  onDeleteUserFromOrg,
}: {
  data: ISingleOrganizationAndUsers;
  actionBox: HTMLElement | undefined;
  onEditUser: (org: ICognitoUserWithOrgData) => void;
  onDeleteUserFromOrg: (id: string, org: ICognitoUserWithOrgData) => void;
}): HTMLElement {
  const organizationId = data.organization?.organizationId || "";

  const users = mergeUsersWithCurrentOrgData(organizationId, data.users || []);

  const tableUsers = new SmartTable<ICognitoUserWithOrgData>().initialize({
    className: "tvd-table tvd-table-smart-table",
    data: users,
    defaultSort: "name",
    title: getTranslation("general:users.users"),
    fields: [
      {
        name: "name",
        label: getTranslation("general:common.name"),
      },
      {
        name: "familyName",
        label: getTranslation("general:users.familyName"),
      },
      {
        name: "email",
        label: getTranslation("general:common.email"),
      },
      {
        name: "organizationUserEnabled",
        label: getTranslation("general:common.enabled"),
        mapper: (u) =>
          getTranslation(
            `general:common.${
              u.organizationUserEnabled === "1" ? "yes" : "no"
            }`,
          ),
      },
      {
        name: "userStatusType",
        label: getTranslation("general:common.status"),
      },
      {
        name: "organizationUserRole",
        label: getTranslation("general:common.type"),
        mapper: (u) =>
          getTranslation(`enums:UserTypeEnum.${u.organizationUserRole}`),
      },
      {
        name: "userCreateDate",
        label: getTranslation("general:common.createdAt"),
        valueFunction: (dta) =>
          dta.userCreateDate
            ? formatDateInCurrentTimezone(dta.userCreateDate)
            : "",
        className: "centered",
      },
      {
        name: "userLastModifiedDate",
        label: getTranslation("general:common.modifiedAt"),
        valueFunction: (dta) =>
          dta.userLastModifiedDate
            ? formatDateInCurrentTimezone(dta.userLastModifiedDate)
            : "",

        className: "centered",
      },
      {
        name: "sub",
        label: getTranslation("general:common.edit"),
        type: "icon",
        icon: "pencil",
        notSortable: true,
        fixedWidth: "60px",
        onClick: (dta) => {
          onEditUser(dta);
        },
      },
      {
        name: "sub",
        label: getTranslation("general:common.delete"),
        type: "icon",
        icon: "trash",
        iconVariant: "danger",
        notSortable: true,
        fixedWidth: "60px",
        onClick: (dta) => {
          onDeleteUserFromOrg(organizationId, dta);
        },
      },
    ],
    settings: {
      sortEnabled: true,
      sortVisible: true,
      filterEnabled: true,
      labelSortBy: getTranslation("general:common.sortBy"),
      labelSearch: getTranslation("general:common.filterBy"),
      createSortIconFn: createSortIcon,
      filterFields: ["name", "familyName", "email"],
      actionBox,
    },
    pkFunction: (dta) => dta.sub,
  });

  return tableUsers;
}

function createOrgUserEdit({
  submitButton,
  orgId,
  prevData,
  showError,
  organizationData,
}: {
  submitButton: SlButton;
  orgId: string;
  prevData: ICognitoUserWithOrgData | undefined;
  showError: (e: ICustomError) => void;
  organizationData: IOrganization | undefined;
}): IFormReturn<ICognitoUserWithOrgData> | null {
  const holder = document.createElement("div");

  const availableUserTypeOptions = [
    { label: "USER", value: "USER" },
    { label: "ADMIN", value: "ADMIN" },
    { label: "SUPERADMIN", value: "SUPERADMIN" },
  ];

  const formFields: IFields<ICognitoUserWithOrgData> = [
    [
      {
        name: "name",
        label: "general:common.name",
        type: "textBox",
        initialValue: prevData?.name || "",
      },
      {
        name: "familyName",
        label: "general:users.familyName",
        type: "textBox",
        initialValue: prevData?.familyName || "",
      },
    ],
    [
      {
        name: "email",
        label: "general:common.email",
        type: "textBox",
        initialValue: prevData?.email || "",
        helpText: getTranslation("general:users.domainsHelpText", {
          domains: organizationData?.domains?.join(", ") || "",
        }),
      },
      {
        name: "organizationUserEnabled",
        label: "general:common.enabled",
        type: "checkbox",
        initialValue:
          prevData?.organizationUserEnabled !== undefined
            ? prevData?.organizationUserEnabled === "1"
            : true,
        padStart: true,
      },
    ],
    {
      name: "organizationUserRole",
      label: "general:common.type",
      type: "radioButtonList",
      fieldset: true,
      initialValue: prevData?.organizationUserRole ?? "USER",
      options: availableUserTypeOptions,
    },
  ];

  const tedivoForm = new TedivoForm<ICognitoUserWithOrgData>({
    fields: formFields,
    onSubmit: () => undefined,
    formValidator: createFormValidator(false, organizationData?.domains || []),
    submitButton: submitButton,
    formProps: { autoFocusOnFirstInput: true },
    hiddenData: prevData,
  });

  translateTedivoForm<ICognitoUserWithOrgData>({
    tedivoForm,
    getTranslation: getTranslation,
  });

  holder.appendChild(tedivoForm.form);

  return {
    node: holder,
    tedivoForm,
    submitFunction: submitPassedToEditDrawer,
  };

  async function submitPassedToEditDrawer() {
    const validResult = tedivoForm.doSubmitForm();

    if (validResult.success) {
      const values = validResult.data;
      if (prevData?.sub) {
        const data: ICognitoUserWithOrgData = {
          ...prevData,
          ...values,
          organizationId: orgId,
          organizationUserEnabled: values.organizationUserEnabled ? "1" : "0",
          organizationUserRole: values.organizationUserRole as TUserRole,
        };

        const resp = await Services.organizations.adminUpdateOrganizationUser(
          orgId,
          data,
        );

        if (!resp.data?.id) {
          showError({
            errorCode: String(resp.statusCode),
            message: resp.code || "errorModifyingUser",
            translationKey: resp.message || "errors:errorModifyingUser",
          });
          return false;
        }
      } else {
        const data: ICognitoUserWithOrgData = {
          ...values,
          organizationId: orgId,
          organizationUserEnabled: values.organizationUserEnabled ? "1" : "0",
          organizationUserRole: values.organizationUserRole as TUserRole,
        };

        const resp = await Services.organizations.adminCreateOrganizationUser(
          orgId,
          data,
        );

        if (!resp.data?.id) {
          showError({
            errorCode: String(resp.statusCode),
            message: resp.code || "errorCreatingUser",
            translationKey: resp.message || "errors:errorCreatingUser",
          });
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  }

  function createFormValidator(
    hasPassword: boolean,
    allowedDomains: string[],
  ): z.Schema<ICognitoUserWithOrgData> {
    const formValidator = z
      .object({
        email: z
          .string()
          .email()
          .transform((v) => v?.toLowerCase()),
        name: z.string().min(1),
        familyName: z.string().min(1),
        organizationUserEnabled: z.boolean(),
        organizationUserRole: z.nativeEnum(UserTypeEnum),
        password: z.string().min(6).optional(),
      })
      .refine(
        (data) => {
          if (data.email) {
            const domain = (data.email.split("@")[1] || "")
              .toLowerCase()
              .trim();
            if (allowedDomains.indexOf(domain) < 0) return false;
            return true;
          } else return false;
        },
        {
          path: ["email"],
        },
      )
      .refine(
        (data) => {
          if (hasPassword) {
            return !!data.password;
          }
          return true;
        },
        {
          path: ["password"],
        },
      );

    return formValidator as unknown as z.Schema<ICognitoUserWithOrgData>;
  }
}

function mergeUserWithCurrentOrgData(
  orgId: string,
  user: ICognitoUser,
): ICognitoUserWithOrgData {
  const { organizations, ...rest } = user;
  const curr = organizations.find((o) => o.orgId === orgId);

  return {
    ...rest,
    organizationUserRole: curr?.role || "USER",
    organizationUserEnabled: curr?.uEnabled || "0",
  };
}

export function mergeUsersWithCurrentOrgData(
  orgId: string,
  users: ICognitoUser[],
): ICognitoUserWithOrgData[] {
  return users.map((user) => mergeUserWithCurrentOrgData(orgId, user));
}

function createInvoicesTable({
  invoices,
  onEditFn,
  onDeleteFn,
  actionBox,
}: {
  invoices: IInvoice[];
  onEditFn: (org: IInvoice) => void;
  onDeleteFn: (pk: string, data: IInvoice) => void;
  actionBox: HTMLElement | undefined;
}): HTMLElement {
  const nn = new Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency: "USD",
  });

  const tableConfig: ITable<IInvoice> = {
    className: "tvd-table tvd-table-smart-table",
    data: invoices,
    defaultSort: "invoiceId",
    defaultSortAsc: false,
    title: getTranslation("general:organizations.invoices.title"),
    fields: [
      {
        name: "invoiceId",
        label: getTranslation("general:organizations.invoices.invoiceId"),
        className: "centered",
      },
      {
        name: "organizationPlan",
        label: getTranslation("general:organizations.plan"),
        mapper: (org) =>
          getTranslation(`enums:OrgPlanTypeEnum.${org.organizationPlan}`),
      },
      {
        name: "paymentMethod",
        label: getTranslation("general:organizations.invoices.paymentMethod"),
        fixedWidth: "170px",
      },
      {
        name: "paymentAmount",
        label: getTranslation("general:organizations.invoices.paymentAmount"),
        className: "right-aligned",
        valueFunction: (dta) =>
          dta.paymentAmount !== undefined ? nn.format(dta.paymentAmount) : "-",
        fixedWidth: "150px",
      },
      {
        name: "invoiceAmount",
        label: getTranslation("general:organizations.invoices.invoiceAmount"),
        className: "right-aligned",
        valueFunction: (dta) =>
          dta.invoiceAmount !== undefined ? nn.format(dta.invoiceAmount) : "-",
        fixedWidth: "150px",
      },
      {
        name: "createdAt",
        label: getTranslation("general:common.createdAt"),
        type: "date",
        valueFunction: (dta) => formatDateInCurrentTimezone(dta.createdAt),
        className: "centered",
        fixedWidth: "170px",
      },
      {
        name: "paymentDate",
        label: getTranslation("general:organizations.invoices.paymentDate"),
        type: "date",
        valueFunction: (dta) => formatDateInCurrentTimezone(dta.paymentDate),
        className: "centered",
        fixedWidth: "170px",
      },
      {
        name: "id",
        label: getTranslation("general:common.edit"),
        type: "icon",
        icon: "pencil",
        notSortable: true,
        fixedWidth: "60px",
        onClick: (dta) => {
          onEditFn(dta);
        },
      },
      {
        name: "id",
        label: getTranslation("general:common.delete"),
        type: "icon",
        icon: "trash",
        iconVariant: "danger",
        notSortable: true,
        fixedWidth: "60px",
        onClick: (dta) => {
          onDeleteFn?.(dta.id, dta);
        },
      },
    ],
    settings: {
      sortEnabled: true,
      sortVisible: true,
      filterEnabled: true,
      labelSortBy: getTranslation("general:common.sortBy"),
      labelSearch: getTranslation("general:common.filterBy"),
      filterFields: ["invoiceId", "paymentAmount", "invoiceAmount"],
      createSortIconFn: createSortIcon,
      actionBox,
    },
    pkFunction: (dta) => dta.id,
  };

  const tableInvoices = new SmartTable<IInvoice>().initialize(tableConfig);

  return tableInvoices;
}

function createInvoicesEdit({
  orgId,
  orgName,
  prevData,
  submitButton,
}: {
  orgId: string;
  orgName: string;
  prevData?: IInvoice;
  submitButton: SlButton;
}): IFormReturn<IInvoice> {
  const holder = document.createElement("div");
  const invalidInvoiceIds: string[] = [];

  if (!prevData) {
    prevData = {
      createdAt: new Date(),
    } as IInvoice;
  }

  if (!prevData.organizationId) prevData.organizationId = orgId;

  const formFields: IFields<IInvoice> = [
    [
      {
        name: "organizationPlan",
        label: "general:organizations.plan",
        type: "select",
        options: ORG_PLAN_OPTIONS.filter((f) => f.value !== "FREE").map(
          (f) => ({
            value: f.value,
            label: getTranslation(f.translationLabel),
          }),
        ),
        initialValue: prevData?.organizationPlan || OrgPlanTypeEnum.PAID1,
      },
      {
        name: "invoiceId",
        label: "general:organizations.invoices.invoiceId",
        type: "textBox",
        initialValue: prevData?.invoiceId || "",
        helpText: "3 characters minimum, non-existing invoice ID",
      },
      {
        name: "paymentMethod",
        label: "general:organizations.invoices.paymentMethod",
        type: "textBox",
        initialValue: prevData?.paymentMethod || "",
      },
    ],
    [
      {
        name: "paymentAmount",
        label: "general:organizations.invoices.paymentAmount",
        type: "number",
        initialValue: prevData?.paymentAmount || 0,
      },
      {
        name: "invoiceAmount",
        label: "general:organizations.invoices.invoiceAmount",
        type: "number",
        initialValue: prevData?.invoiceAmount || 0,
      },
    ],
    [
      {
        name: "paymentDate",
        label: "general:organizations.invoices.paymentDate",
        type: "date",
        initialValue: prevData?.paymentDate,
      },
      {
        name: "createdAt",
        label: "general:common.createdAt",
        type: "date",
        initialValue: prevData?.createdAt,
      },
    ],
  ];

  const tedivoForm = new TedivoForm<IInvoice>({
    fields: formFields,
    onSubmit: () => undefined,
    formValidator: createFormValidator(),
    submitButton: submitButton,
    formProps: { autoFocusOnFirstInput: true },
    hiddenData: { ...prevData },
  });

  tedivoForm.onDataChange = (data, keyName) => {
    if (
      keyName === "paymentAmount" &&
      !data.invoiceAmount &&
      data.paymentAmount
    ) {
      tedivoForm.setValue("invoiceAmount", data.paymentAmount);
    }
  };

  translateTedivoForm<IInvoice>({
    tedivoForm,
    getTranslation: getTranslation,
  });

  holder.appendChild(tedivoForm.form);

  return {
    node: holder,
    tedivoForm,
    submitFunction: submitPassedToEditDrawer,
  };

  function createFormValidator() {
    const formValidator = z.object({
      organizationId: z.string().min(1),
      organizationName: z.string().optional(),
      organizationPlan: z.string().min(1),
      invoiceId: z
        .string()
        .min(3)
        .refine((v) => invalidInvoiceIds.indexOf(v) < 0),
      paymentAmount: z.number().min(0),
      invoiceAmount: z.number().min(0),
      paymentMethod: z.string().min(1),
      paymentDate: z.date().optional(),
      createdAt: z.date(),
    }) as unknown as z.Schema<IInvoice>;
    return formValidator;
  }

  async function submitPassedToEditDrawer() {
    const validResult = tedivoForm.doSubmitForm();
    const values = tedivoForm.getValues();

    if (validResult.success) {
      const resp = await Services.organizations.createUpdateInvoice({
        ...values,
        id: prevData?.id,
        organizationId: orgId,
        organizationName: orgName,
      } as IInvoice);
      if (resp.statusCode === 409) {
        invalidInvoiceIds.push(values.invoiceId);
        tedivoForm.setFormValidator(createFormValidator()).execValidation();
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
}

function createSortIcon(asc: boolean) {
  const icon = document.createElement("sl-icon");
  icon.name = asc ? "sort-down-alt" : "sort-up-alt";
  return icon;
}
