import { DictionaryDisplay, LinkElement } from "@tedivo/tedivo-ui";

import { EditDrawer } from "../../components/common/EditDrawer";
import getDisplayValue from "../i18/getDisplayValue";
import { getTranslation } from "../../app/i18/i18tn";
import router from "../router";

export function bindCustomElementsHooks() {
  // Add translatable getDisplayValue to dictionary-display-element
  customElements.whenDefined("dictionary-display-element").then(() => {
    const DictionaryDisplayDef = customElements.get(
      "dictionary-display-element",
    ) as typeof DictionaryDisplay;

    DictionaryDisplayDef.getDisplayValue = getDisplayValue;
  });

  // Add router.navigate to link-element
  customElements.whenDefined("link-element").then(() => {
    const LinkElementDef = customElements.get(
      "link-element",
    ) as typeof LinkElement;

    LinkElementDef.onClickLink = router.navigate;
  });

  // Add getTranslation to edit-drawer
  EditDrawer.getTranslation = getTranslation;
}
