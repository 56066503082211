export const nodeTextWidth = { node: undefined as HTMLElement | undefined };

export function calculateTextWidth(text: string, fontSize: string) {
  if (nodeTextWidth.node === undefined) {
    nodeTextWidth.node = document.createElement("div");

    nodeTextWidth.node.style.position = "absolute";
    nodeTextWidth.node.style.visibility = "hidden";
    nodeTextWidth.node.style.height = "auto";
    nodeTextWidth.node.style.width = "auto";
    nodeTextWidth.node.style.whiteSpace = "nowrap";

    document.body.appendChild(nodeTextWidth.node);
  }
  nodeTextWidth.node.style.fontSize = fontSize;
  nodeTextWidth.node.innerHTML = text;
  return nodeTextWidth.node.clientWidth + 1;
}
