import {
  IFields,
  IFormReturn,
  TedivoForm,
  translateTedivoForm,
} from "@tedivo/tedivo-form";
import { IInvoice, IOrganization, systemRights } from "@tedivo/tvd-api-models";
import OrgPlanTypeEnum, {
  ORG_PLAN_OPTIONS,
} from "../../../app/enums/OrgPlanTypeEnum";
import { getTranslation, i18n } from "../../../app/i18/i18tn";

import { ITable } from "@tedivo/tedivo-smart-table";
import { ListComponentWithActions } from "../../common/ListComponentWithActions";
import Services from "../../../app/services";
import SlButton from "@shoelace-style/shoelace/dist/components/button/button";
import { formatDateInCurrentTimezone } from "@tedivo/tedivo-pure-helpers";
import goSquared from "../../../app/tracking/goSquared";
import securityModule from "../../../app/security/SecurityModule";
import { setAppTitle } from "../../../app/setAppTitle";
import { z } from "zod";

export class TVDInvoicesComponent extends ListComponentWithActions<IInvoice> {
  public static observedAttributes = [];

  constructor() {
    super();

    this.h1Label = "general:organizations.invoices.title";
    this.goSquaredLabel = "Admin/Invoices";
    this.topLabel = "menu:organization";
    this.createLabel = "general:organizations.invoices.adminCreate";
    this.deleteLabel = "general:organizations.invoices.delete";

    this.serviceListCall = () => Services.organizations.getInvoicesOfOrg("a");
    this.serviceDeleteCall = Services.organizations.deleteInvoice;
    this.serviceEditCall = Services.organizations.createUpdateInvoice;

    this.systemRightToEdit = systemRights.ORG.IsTedivoAdmin;

    this.tableConfig = (data: IInvoice[]) =>
      createInvoicesTableConfig(
        data,
        this.systemRightToEdit,
        this.openDeleteModal,
      );

    this.createEditForm = createInvoicesEdit;

    setAppTitle(getTranslation("general:organizations.invoices.title"));

    goSquared.trackPage("Invoices");
  }
}

customElements.define("tvd-invoices-component", TVDInvoicesComponent);

declare global {
  interface HTMLElementTagNameMap {
    "tvd-invoices-component": TVDInvoicesComponent;
  }
}

function createInvoicesTableConfig(
  apiKeys: IInvoice[],
  systemRightToEdit: string | undefined,
  onDeleteFn: (
    pk: string,
    data: IInvoice,
    name: keyof IInvoice,
    secondKey?: keyof IInvoice,
  ) => void,
): ITable<IInvoice> {
  const nn = new Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency: "USD",
  });

  const tableConfig: ITable<IInvoice> = {
    className: "tvd-table tvd-table-smart-table",
    data: apiKeys,
    defaultSort: "invoiceId",
    defaultSortAsc: false,
    fields: [
      {
        name: "invoiceId",
        label: getTranslation("general:organizations.invoices.invoiceId"),
        className: "centered",
      },
      {
        name: "organizationName",
        label: getTranslation("general:organizations.organization"),
      },
      {
        name: "organizationPlan",
        label: getTranslation("general:organizations.plan"),
        mapper: (org) =>
          getTranslation(`enums:OrgPlanTypeEnum.${org.organizationPlan}`),
      },
      {
        name: "paymentDate",
        label: getTranslation("general:organizations.invoices.paymentDate"),
        type: "date",
        valueFunction: (dta) => formatDateInCurrentTimezone(dta.paymentDate),
        className: "centered",
        fixedWidth: "170px",
      },
      {
        name: "createdAt",
        label: getTranslation("general:common.createdAt"),
        type: "date",
        valueFunction: (dta) => formatDateInCurrentTimezone(dta.createdAt),
        className: "centered",
        fixedWidth: "170px",
      },
      {
        name: "paymentMethod",
        label: getTranslation("general:organizations.invoices.paymentMethod"),
        fixedWidth: "170px",
      },
      {
        name: "paymentAmount",
        label: getTranslation("general:organizations.invoices.paymentAmount"),
        className: "right-aligned",
        valueFunction: (dta) =>
          dta.paymentAmount !== undefined ? nn.format(dta.paymentAmount) : "-",
        fixedWidth: "150px",
      },
      {
        name: "invoiceAmount",
        label: getTranslation("general:organizations.invoices.invoiceAmount"),
        className: "right-aligned",
        valueFunction: (dta) =>
          dta.invoiceAmount !== undefined ? nn.format(dta.invoiceAmount) : "-",
        fixedWidth: "150px",
      },
    ],
    settings: {
      sortEnabled: true,
      sortVisible: true,
      filterEnabled: true,
      labelSortBy: getTranslation("general:common.sortBy"),
      labelSearch: getTranslation("general:common.filterBy"),
      filterFields: ["organizationName", "invoiceId"],
    },
    pkFunction: (dta) => dta.id,
  };

  if (
    systemRightToEdit &&
    securityModule.userHasPermission(systemRightToEdit)
  ) {
    tableConfig.fields.push({
      name: "id",
      label: getTranslation("general:common.delete"),
      type: "icon",
      icon: "trash",
      iconVariant: "danger",
      notSortable: true,
      onClick: (dta) => {
        onDeleteFn?.(dta.id, dta, "invoiceId", "organizationId");
      },
    });
  }

  return tableConfig;
}

function createInvoicesEdit(
  submitButton: SlButton,
  prevData?: IInvoice,
): IFormReturn<IInvoice> {
  const holder = document.createElement("div");

  let organizationsList: IOrganization[] = [];

  if (!prevData) {
    prevData = { createdAt: new Date() } as IInvoice;
  }

  const formFields: IFields<IInvoice> = [
    [
      {
        name: "organizationId",
        label: "general:organizations.organization",
        type: "select",
        options: async () => {
          const orgsResp =
            await Services.organizations.adminGetAllOrganizations();

          if (!orgsResp?.data) return [];

          organizationsList = orgsResp.data;

          return orgsResp.data
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((org) => ({
              value: org.organizationId,
              label: org.name,
            }));
        },
        initialValue: prevData?.organizationId || "",
      },
      {
        name: "organizationPlan",
        label: "general:organizations.plan",
        type: "select",
        options: ORG_PLAN_OPTIONS.filter((f) => f.value !== "FREE").map(
          (f) => ({
            value: f.value,
            label: getTranslation(f.translationLabel),
          }),
        ),
        initialValue: prevData?.organizationPlan || OrgPlanTypeEnum.PAID1,
      },
    ],
    [
      {
        name: "invoiceId",
        label: "general:organizations.invoices.invoiceId",
        type: "textBox",
        initialValue: prevData?.invoiceId || "",
      },
      {
        name: "paymentMethod",
        label: "general:organizations.invoices.paymentMethod",
        type: "textBox",
        initialValue: prevData?.paymentMethod || "",
      },
    ],
    [
      {
        name: "paymentAmount",
        label: "general:organizations.invoices.paymentAmount",
        type: "number",
        initialValue: prevData?.paymentAmount || 0,
      },
      {
        name: "invoiceAmount",
        label: "general:organizations.invoices.invoiceAmount",
        type: "number",
        initialValue: prevData?.invoiceAmount || 0,
      },
    ],
    [
      {
        name: "paymentDate",
        label: "general:organizations.invoices.paymentDate",
        type: "date",
        initialValue: prevData?.paymentDate,
      },
      {
        name: "createdAt",
        label: "general:common.createdAt",
        type: "date",
        initialValue: prevData?.createdAt,
      },
    ],
  ];

  const formValidator = z.object({
    organizationId: z.string().min(1),
    organizationName: z.string().optional(),
    organizationPlan: z.string().min(1),
    invoiceId: z.string().min(3),
    paymentAmount: z.number().min(0),
    invoiceAmount: z.number().min(0),
    paymentMethod: z.string().min(1),
    paymentDate: z.date().optional(),
    createdAt: z.date(),
  }) as unknown as z.Schema<IInvoice>;

  const tedivoForm = new TedivoForm<IInvoice>({
    fields: formFields,
    onSubmit: () => undefined,
    formValidator: formValidator,
    submitButton: submitButton,
    formProps: { autoFocusOnFirstInput: true },
    hiddenData: { ...prevData },
  });

  translateTedivoForm<IInvoice>({
    tedivoForm,
    getTranslation: getTranslation,
  });

  holder.appendChild(tedivoForm.form);

  return {
    node: holder,
    tedivoForm,
    submitFunction: submitPassedToEditDrawer,
  };

  async function submitPassedToEditDrawer() {
    const validResult = tedivoForm.doSubmitForm();
    const values = tedivoForm.getValues();

    if (validResult.success) {
      await Services.organizations.createUpdateInvoice({
        ...values,
        organizationName:
          organizationsList.find(
            (o) => o.organizationId === values.organizationId,
          )?.name || "",
      } as IInvoice);
      return true;
    } else {
      return false;
    }
  }
}
