import * as enTranslations from "../../translations/en/export";
import * as esTranslations from "../../translations/es/export";

import { I18nReactive } from "@tedivo/tedivo-i18";
import { getBrowserLanguage } from "@tedivo/tedivo-dom-helpers";
import i18n from "i18next";

export type SupportedLanguagesType = (typeof supportedLanguages)[number];
export type IResource = typeof enTranslations;
export type IResources = { [L in SupportedLanguagesType]: IResource };

export const supportedLanguages = ["en", "es"] as const;
export const defaultLanguage = supportedLanguages[0];

const resources: IResources = {
  en: enTranslations,
  es: esTranslations,
};

i18n.init({
  lng: getBrowserLanguage<SupportedLanguagesType>(
    defaultLanguage,
    supportedLanguages,
  ),
  fallbackLng: defaultLanguage,
  ns: ["general", "view", "menu", "errors", "enums", "auth"],
  defaultNS: "general",
  debug: process.env.NODE_ENV === "development",
  resources: resources,
});

const i18nReactive = new I18nReactive(i18n);

function getTranslation(
  s: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  opts?: any,
) {
  const r = i18n.t(s, opts);
  return r as unknown as string;
}

function changeLanguage(lang: SupportedLanguagesType): void {
  if (lang === i18n.language) return;
  i18n.changeLanguage(lang);
  i18nReactive.updateI18Consumers();
}

export { changeLanguage, getTranslation, i18n, i18nReactive };
