import { ForeAftEnum } from "open-vessel-definition";
import { IBaySizesAndCgs } from "./types/IGetBayLcgVcgAndPairingsResult";
import { IRowPattern } from "./types/IPositionPatterns";

/**
 * Finds paired bays by tier, mutating the objects of the array
 * @param bayInfo
 */

export function findPaired40(bayInfo: IBaySizesAndCgs[] = []) {
  for (let bayIndex = 0; bayIndex < bayInfo.length; bayIndex += 1) {
    const dta = bayInfo[bayIndex];

    if (dta.pairedBay === undefined) continue;

    let someTierPairHas40 = false;

    const siblingBayAddIndex =
      bayIndex + (dta.pairedBay === ForeAftEnum.AFT ? 1 : -1);

    if (bayInfo[siblingBayAddIndex]) {
      // This would be wrong if both are paired in the same direction
      if (bayInfo[siblingBayAddIndex].pairedBay === dta.pairedBay) continue;

      dta.pairedToBay = bayInfo[siblingBayAddIndex].isoBay;

      const siblingTiers = bayInfo[siblingBayAddIndex].tiers;
      if (!siblingTiers) continue;

      const tiers = Object.keys(dta.tiers) as IRowPattern[];

      for (let t = 0; t < tiers.length; t += 1) {
        if (siblingTiers[tiers[t]] && siblingTiers[tiers[t]].maxSize >= 40) {
          dta.tiers[tiers[t]].pairedHas40 = true;
          someTierPairHas40 = true;
        }
      }
    }

    dta.pairedHas40 = someTierPairHas40;
  }
}
