import {
  BayLevelEnum,
  ForeAftEnum,
  IOpenVesselDefinitionV1,
} from "open-vessel-definition";

import { ICGsMissingOutput } from "@tedivo/tedivo-ovd-validators";
import { getTranslation } from "../../../../../../../app/i18/i18tn";
import ovdJsonStore from "../../../../../../../app/stores/OVDJsonStore";

export function createMissingCGsDialog(
  json: IOpenVesselDefinitionV1 | undefined,
): HTMLElement {
  const d = document.createElement("div");
  d.className = "missing-cgs-dialog";

  if (!json) return d;

  const missingCGs =
    ovdJsonStore.ovdValidator.getLastResultByName<ICGsMissingOutput>(
      "cgsValidator",
    )?.result.summary;

  if (!missingCGs) return d;

  const {
    masterAboveTCGs,
    masterBelowTCGs,
    masterVCGs,
    missingLCGs,
    missingBulkheadLCGs,
  } = missingCGs;

  const lpp = json.shipData.lcgOptions?.lpp;
  if (!lpp) {
    const h3 = document.createElement("h3");
    h3.innerHTML = getTranslation(
      "view:edit.cgHelpers.viewMissingCGs.missingLPP",
    );
    d.appendChild(h3);

    d.appendChild(
      createListWithLabels(
        [
          {
            label: getTranslation(
              "view:edit.cgHelpers.viewMissingCGs.missingLPPIs",
            ),
            value: "",
          },
        ],
        "",
        true,
      ),
    );
  }

  if (masterAboveTCGs.length || masterBelowTCGs.length) {
    const h3 = document.createElement("h3");
    h3.innerHTML = getTranslation(
      "view:edit.cgHelpers.viewMissingCGs.missingTCGs",
    );
    d.appendChild(h3);

    d.appendChild(
      createListWithLabels(
        [
          masterAboveTCGs
            ? {
                label: getTranslation("view:edit.editCGs.masterTcgsAbove"),
                value: masterAboveTCGs.join(", "),
              }
            : undefined,
          masterBelowTCGs
            ? {
                label: getTranslation("view:edit.editCGs.masterTcgsBelow"),
                value: masterBelowTCGs.join(", "),
              }
            : undefined,
        ].filter(Boolean) as ILabel[],
      ),
    );
  }

  if (masterVCGs.length) {
    const h3 = document.createElement("h3");
    h3.innerHTML = getTranslation(
      "view:edit.cgHelpers.viewMissingCGs.missingVCGs",
    );
    d.appendChild(h3);

    d.appendChild(
      createListWithLabels([
        {
          label: getTranslation("view:edit.editCGs.masterBottomBases"),
          value: masterVCGs.join(", "),
        },
      ]),
    );
  }

  if (missingLCGs.length) {
    const h3 = document.createElement("h3");
    h3.innerHTML = getTranslation(
      "view:edit.cgHelpers.viewMissingCGs.missingLCGs",
    );
    d.appendChild(h3);

    const groupedLabelsByBayLevel: { [key: string]: number[] } = {};
    missingLCGs.forEach((m) => {
      const key = `${m.bay}-${BayLevelEnum[m.level]}`;
      if (!groupedLabelsByBayLevel[key]) groupedLabelsByBayLevel[key] = [];
      groupedLabelsByBayLevel[key].push(m.size);
    });

    const missingLabels: ILabel[] = Object.keys(groupedLabelsByBayLevel).map(
      (key) => {
        const [bay, level] = key.split("-");
        return {
          label: `${getTranslation("general:grid.bay")} ${bay} ${getTranslation(
            `enums:BayLevelEnum.${level}`,
          )}`,
          value: groupedLabelsByBayLevel[key].join(", "),
        };
      },
    );

    d.appendChild(createListWithLabels(missingLabels));
  }

  if (missingBulkheadLCGs.length) {
    const h3 = document.createElement("h3");
    h3.innerHTML = getTranslation(
      "view:edit.cgHelpers.viewMissingCGs.missingBulkheadLCGs",
    );
    d.appendChild(h3);

    const groupedLabelsByBayLevel: { [key: string]: string[] } = {};
    missingBulkheadLCGs.forEach((m) => {
      const key = `${m.bay}-${BayLevelEnum[m.level]}`;
      if (!groupedLabelsByBayLevel[key]) groupedLabelsByBayLevel[key] = [];
      groupedLabelsByBayLevel[key].push(
        getTranslation(`enums:ForeAftEnum.${ForeAftEnum[m.pos]}`),
      );
    });

    const missingLabels: ILabel[] = Object.keys(groupedLabelsByBayLevel).map(
      (key) => {
        const [bay, level] = key.split("-");
        return {
          label: `${getTranslation("general:grid.bay")} ${bay} ${getTranslation(
            `enums:BayLevelEnum.${level}`,
          )}`,
          value: groupedLabelsByBayLevel[key].join(", "),
        };
      },
    );

    d.appendChild(createListWithLabels(missingLabels));
  }

  return d;
}

function createListWithLabels(
  labels: ILabel[],
  separator = ":",
  labelWarning = false,
): HTMLElement {
  const table = document.createElement("table");
  table.className = "table-missing-cgs";
  labels.filter(Boolean).forEach((label) => {
    const tr = document.createElement("tr");
    const tdLabel = document.createElement("td");
    if (labelWarning) tdLabel.className = "warning";
    tdLabel.innerHTML = `${label.label}${separator}`;
    const tdValue = document.createElement("td");
    tdValue.innerHTML = label.value;
    tr.appendChild(tdLabel);
    tr.appendChild(tdValue);
    table.appendChild(tr);
  });
  return table;
}

interface ILabel {
  label: string;
  value: string;
}
