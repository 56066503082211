import { IDictionaryDisplayElement } from "@tedivo/tedivo-ui";

export function createPart(
  name: string,
  titleLabel: string,
  elements: IDictionaryDisplayElement[],
  editAction?: HTMLElement,
): HTMLElement {
  const card = document.createElement("card-element");
  card.titleHtml = titleLabel;
  card.name = name;

  if (editAction) card.actions = editAction;

  const partDisplay = document.createElement("dictionary-display-element");
  partDisplay.className = name;
  partDisplay.elements = elements;

  card.appendChild(partDisplay);
  return card;
}
