import { __awaiter } from "tslib";
import { getTranslation, i18nReactive } from "../../../../app/i18/i18tn";
import { I18nComponentRegisterer } from "@tedivo/tedivo-i18";
import Services from "../../../../app/services";
import goSquared from "../../../../app/tracking/goSquared";
import { removeChildren } from "@tedivo/tedivo-dom-helpers";
import { routeFns } from "../../../../app/router/routes";
import router from "../../../../app/router";
import securityModule from "../../../../app/security/SecurityModule";
import { setAppTitle } from "../../../../app/setAppTitle";
export class TVDAuthVerifyAddressComponent extends HTMLElement {
    constructor() {
        super();
        this.processVerification = (values) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            const resp = yield Services.accounts.verifyEmail(values);
            return {
                isVerified: !!((_a = resp.data) === null || _a === void 0 ? void 0 : _a.isVerified),
                password: (_b = resp.data) === null || _b === void 0 ? void 0 : _b.password,
            };
        });
        this.i18nCR = new I18nComponentRegisterer(i18nReactive);
        this.divWithSpinner = document.createElement("div-spinner-element");
        setAppTitle(getTranslation("general:organizations.createNewAccount"));
    }
    connectedCallback() {
        if (securityModule.isLoggedIn) {
            const div = document.createElement("div");
            div.innerHTML = getTranslation("auth:logoutFirst", {
                name: securityModule.name,
            });
            this.appendChild(div);
            return;
        }
        goSquared.trackPage("Verify Address");
        this.appendChild(this.divWithSpinner);
        this.divWithSpinner.setLoading(true);
        const queryString = router.getRouteQuerystring();
        if (queryString["hash"] && queryString["email"]) {
            this.processVerification({
                hash: queryString["hash"],
                email: queryString["email"],
            })
                .then((resp) => {
                this.setCurrentFormAndNode(showResponse(resp.isVerified, resp.password, queryString["email"]));
                this.divWithSpinner.setLoading(false);
            })
                .catch((e) => {
                console.error(e);
            });
        }
        else {
            this.setCurrentFormAndNode(showResponse(false, undefined, undefined));
            this.divWithSpinner.setLoading(false);
        }
    }
    /** Replaces the UI */
    setCurrentFormAndNode(fan) {
        this.currentFormAndNode = fan;
        if (fan === null || fan === void 0 ? void 0 : fan.node) {
            this.divWithSpinner.setLoading(false);
            removeChildren(this.divWithSpinner);
            this.divWithSpinner.appendChild(fan.node);
        }
    }
}
TVDAuthVerifyAddressComponent.observedAttributes = [];
customElements.define("tvd-auth-verify-address-component", TVDAuthVerifyAddressComponent);
function showResponse(isOk, password, email) {
    if (isOk) {
        const viewState = { email, password, fromVerification: true };
        router.navigate(routeFns.login(), viewState, true);
        return;
    }
    const h1 = document.createElement("h1");
    const wrapper = document.createElement("div");
    const box = document.createElement("div");
    box.className = "oss-card";
    box.innerHTML = getTranslation("general:organizations.newAccountFailed");
    h1.innerHTML = getTranslation("general:organizations.verifyEmail");
    wrapper.appendChild(h1);
    wrapper.appendChild(box);
    return {
        node: wrapper,
        form: undefined,
    };
}
