import { ForeAftEnum, } from "open-vessel-definition";
import { SVG_WIDTH, TCG_IN_MMM } from "./consts";
import { feetToMillimeters, roundDec } from "@tedivo/tedivo-pure-helpers";
import { calculateSlotsForVesselOne } from "./calculateSlotsForVesselOne";
import { createRowsFromConfig } from "./createRowsFromConfig";
import { createTiersFromConfig } from "./createTiersFromConfig";
import { preCalculateCurrentVesselParts } from "./preCalculateCurrentVesselParts";
const FEET_15_IN_MMM = feetToMillimeters(15);
const FEET_20_IN_MMM = feetToMillimeters(20);
export function generateCoordFunctions({ missingImportantXcgs, sizeSummary, baysData, vesselPartsData, bayLevelPositionsAbove, bayLevelPositionsBelow, minLcg, maxVcg, minVcg, maxTcg, minTcg, lcgNormalization = SVG_WIDTH, lcgsBy20Bay, blockBaysAndSizes, }) {
    var _a;
    const preCalculatedSlots = preCalculateCurrentVesselParts(baysData, vesselPartsData || []);
    const vOneBaySlots = calculateSlotsForVesselOne(baysData, preCalculatedSlots, lcgsBy20Bay);
    if (vOneBaySlots.length === 0)
        return undefined;
    const { sternLength, bowLength } = sternAndBowInMM(sizeSummary.isoBays);
    const lastBaySlot = Object.values(vOneBaySlots).reduce((acc, v) => {
        if (acc.positionX > v.positionX)
            return acc;
        return v;
    }, { positionX: 0 });
    // Length of all parts in feet. If `calcLen` is available it's because there aren't slots defined. Then use it, otherwise use `len`
    const allPartsLengthInFeet = lastBaySlot.positionX * 100 + ((_a = lastBaySlot.calcLen) !== null && _a !== void 0 ? _a : lastBaySlot.len);
    // Find the aft most LCG of all 20 bays
    const aftMostLcg20 = Object.values(lcgsBy20Bay).reduce((acc, v) => {
        if (acc.aftLcg < v.aftLcg)
            return acc;
        return v;
    }, { aftLcg: Infinity });
    // Find the last bay size in MM
    const lastBaySizeInMM = feetToMillimeters(Math.max(...Object.values(blockBaysAndSizes[blockBaysAndSizes.length - 1].maxSizes)));
    // When the last bay is 40 and is paired AFT, but no 20 bay follows then we add half of the last bay size
    const addToTailDueToMissingBay = aftMostLcg20.maxSize >= 40 && aftMostLcg20.paired === ForeAftEnum.AFT
        ? feetToMillimeters(aftMostLcg20.maxSize * 0.5)
        : 0;
    const lowestLcgAft = aftMostLcg20.aftLcg - addToTailDueToMissingBay;
    const allPartsLengthInMeters = feetToMillimeters(allPartsLengthInFeet) + addToTailDueToMissingBay;
    const svgSizeRatio = lcgNormalization / (allPartsLengthInMeters + sternLength + bowLength);
    const scaled = (n) => n * svgSizeRatio;
    const addX = scaled(lastBaySizeInMM * 0.5 + sternLength + addToTailDueToMissingBay), addY = scaled(FEET_15_IN_MMM), addZ = scaled(FEET_20_IN_MMM);
    // A. With full XCGs
    if (!missingImportantXcgs) {
        const xRange = roundDec(scaled(allPartsLengthInMeters + sternLength + bowLength)), // 2 METER FOR STERN, 30 METERS FOR BOW
        yRange = roundDec(scaled(maxVcg - minVcg + FEET_15_IN_MMM * 2)), // Add 15*2 feet above and below
        zRange = roundDec(scaled(maxTcg - minTcg + FEET_20_IN_MMM * 2)); // Add 5*2 feet port and starboard
        return {
            addX,
            addY,
            addZ,
            xRange,
            yRange,
            zRange,
            xPos: (lcg, d = 2) => roundDec(scaled(lcg - minLcg) + addX, d),
            yPos: (vcg, d = 2) => roundDec(scaled(-vcg + maxVcg + FEET_15_IN_MMM), d),
            zPos: (tcg, d = 2) => roundDec(scaled(tcg - minTcg + FEET_20_IN_MMM), d),
            scaled,
            svgSizeRatio,
            lastBaySizeInMM: scaled(lastBaySizeInMM),
            sternLength,
            bowLength,
            allPartsLengthInMeters,
            lowestLcgAft,
            vOneBaySlots,
        };
    }
    // B. Only for missing XCGs *******************************
    // B.1 LCGs
    const allLCGs = Object.keys(lcgsBy20Bay).map((k) => lcgsBy20Bay[k].lcg || 0);
    const minIntLcg = Math.min.apply(null, allLCGs);
    // B.2 VCGs
    const aboveTiers = createTiersFromConfig(sizeSummary.minAboveTier, sizeSummary.maxAboveTier);
    const belowTiers = createTiersFromConfig(sizeSummary.minBelowTier, sizeSummary.maxBelowTier);
    const allTiers = belowTiers.concat(aboveTiers);
    const allTiersVcgs = allTiers.reduce((acc, t, idx) => {
        acc[t] = roundDec(feetToMillimeters(idx * 9) + (Number(t) >= 66 ? 2000 : -1000), 2);
        return acc;
    }, {});
    const maxIntVcg = allTiersVcgs[allTiers[allTiers.length - 1]];
    // B.3 TCGs
    const rows = createRowsFromConfig(!!sizeSummary.centerLineRow, sizeSummary.maxRow);
    const iRows = rows.map(Number);
    const maxRow = Math.max(...iRows);
    const minRow = Math.min(...iRows);
    const minIntTcg = -(maxRow - minRow) * 0.5 * TCG_IN_MMM;
    const allRowsTcgs = rows.reduce((acc, t, idx) => {
        acc[t] = roundDec(idx * TCG_IN_MMM + minIntTcg, 2);
        return acc;
    }, {});
    const maxIntTcg = allRowsTcgs[rows[rows.length - 1]];
    // B.4 Reposition function -- IMPURE function, mutates the input
    //     Adds LCGs, VCGs, TCGs to the bayLevelPositions
    const rePositionLcg = (blp) => {
        const lcgsOfBay = lcgsBy20Bay[blp.isoBay];
        if (lcgsOfBay) {
            // B.4.1. MaxSize LCG
            blp.maxSizeLcg.size = lcgsOfBay.maxSize;
            blp.maxSizeLcg.lcg = lcgsOfBay.lcg;
            blp.maxSizeLcg.aftLcg = lcgsOfBay.aftLcg;
            blp.maxSizeLcg.foreLcg = lcgsOfBay.foreLcg;
            // B.4.2. LCGs
            Object.keys(blp.tiers).forEach((tier) => {
                // As generated LCGs are for 20's, for 40's we need the aftLcg of a 20
                blp.tiers[tier].lcg = roundDec(blp.tiers[tier].maxSize >= 40
                    ? blp.pairedBay === ForeAftEnum.AFT
                        ? lcgsOfBay.aftLcg
                        : lcgsOfBay.foreLcg
                    : lcgsOfBay.lcg || 0, 2);
                //blp.tiers[tier].vcg = allTiersVcgs[tier];
            });
            // B.4.3. TCGs
            // const rowInfo = blp.rows;
            // const rowsOfBay = Object.keys(rowInfo) as IRowPattern[];
            // rowsOfBay.forEach((row) => {
            //   if (!blp.rows[row]) blp.rows[row] = {};
            //   blp.rows[row].tcg = allRowsTcgs[row];
            // });
        }
    };
    bayLevelPositionsAbove.forEach(rePositionLcg);
    bayLevelPositionsBelow.forEach(rePositionLcg);
    const xRange = roundDec(scaled(allPartsLengthInMeters + sternLength + bowLength)), // 15*2 -add 10 meters for bow & stern
    yRange = roundDec(scaled(maxIntVcg + FEET_15_IN_MMM * 2)), // Add 15*2 feet above and below
    zRange = roundDec(scaled(maxIntTcg - minIntTcg + FEET_20_IN_MMM * 2)); // Add 5*2 feet port and starboard
    return {
        addX,
        addY,
        addZ,
        xRange,
        yRange,
        zRange,
        xPos: (lcg, d = 2) => roundDec(scaled(lcg - minIntLcg) + addX, d),
        yPos: (vcg, d = 2) => roundDec(scaled(-vcg + maxIntVcg + FEET_15_IN_MMM), d),
        zPos: (tcg, d = 2) => roundDec(scaled(tcg - minIntTcg + FEET_20_IN_MMM), d),
        scaled,
        svgSizeRatio: svgSizeRatio,
        lastBaySizeInMM: scaled(lastBaySizeInMM),
        sternLength,
        bowLength,
        lowestLcgAft,
        allPartsLengthInMeters,
        vOneBaySlots,
    };
}
function sternAndBowInMM(numBays) {
    const STERN_TO_LAST_IN_MMM = 0;
    const BOW_TO_FIRST_IN_MMM_MIN = 7000;
    const BOW_TO_FIRST_IN_MMM_MAX = 30000;
    return {
        sternLength: STERN_TO_LAST_IN_MMM,
        bowLength: Math.max(Math.min((1 - numBays / 70) * BOW_TO_FIRST_IN_MMM_MIN +
            (numBays / 70) * BOW_TO_FIRST_IN_MMM_MAX, BOW_TO_FIRST_IN_MMM_MIN), BOW_TO_FIRST_IN_MMM_MAX),
    };
}
