import {
  BayLevelEnum,
  ForeAftEnum,
  IBayLevelData,
  ISlotData,
} from "open-vessel-definition";
import {
  IBayPattern,
  SlotDataReducer,
  SmartSlotsData,
} from "@tedivo/tedivo-bay-grid-pure";
import { cloneObject, pad3 } from "@tedivo/tedivo-pure-helpers";

export function createRestrictedsInPairedBay(
  isoBay: IBayPattern,
  level: BayLevelEnum,
  maxIsoBay: number,
  findBayInfo: (
    isoBay: IBayPattern,
    level: BayLevelEnum,
  ) => IBayLevelData | undefined,
): IBayLevelData | null {
  const ownBl = findBayInfo(isoBay, level);

  // Exit 0. If the bay is not found, return null
  if (!ownBl) return null;

  // Exit 1. If the bay is not paired, return null
  if (!ownBl.pairedBay) return null;

  // Exit 2. If the bay does not have slots, return null
  if (!ownBl?.perSlotInfo) return null;

  const ownSlotsSmartData = new SmartSlotsData(
    Object.values(ownBl.perSlotInfo) as ISlotData[],
  );

  // Exit 3. If the bay does not have 40s, return null
  if (!ownSlotsSmartData.someHave40s) return null;

  const pairedBay =
    Number(isoBay) + (ownBl.pairedBay === ForeAftEnum.FWD ? -2 : 2);

  // Exit 4. If the paired bay is out of bounds, return null
  if (pairedBay < 1 || pairedBay > maxIsoBay) return null;

  // Get or create the paired bay
  const pairedBl = cloneObject(findBayInfo(pad3(pairedBay), level));

  // Exit 5. If the paired bay is inexistent, return null
  if (!pairedBl) return null;

  // Exit 6. If the paired doesn't reciprocate the pairing, return null
  if (
    pairedBl.pairedBay === undefined ||
    pairedBl.pairedBay === ownBl.pairedBay // Need to be the opposite
  )
    return null;

  const pairedSlotsSmartData = new SmartSlotsData(
    Object.values(pairedBl.perSlotInfo || {}),
  );

  let slotsChanged = 0;

  ownSlotsSmartData.forEach((slotSmartData) => {
    if (!slotSmartData.has40s) return;

    const pairedSlot = pairedSlotsSmartData.getSlotByPos(slotSmartData.pos);

    if (!pairedSlot || (pairedSlot.hasNoSizes && !pairedSlot.isRestricted)) {
      slotsChanged++;
      pairedSlotsSmartData.addSlot(
        SlotDataReducer.ADD_RESTRICTED({
          pos: slotSmartData.pos,
          sizes: {},
        }),
      );
    }
  });

  if (slotsChanged === 0) return null;

  pairedBl.perSlotInfo = pairedSlotsSmartData.getPerSlotInfoObject();

  return pairedBl;
}
