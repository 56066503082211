import { feetToMillimeters, roundDec } from "@tedivo/tedivo-pure-helpers";

import { IVesselOneBaySlotSmoke } from "@tedivo/tedivo-bay-grid-pure";

const SMOKE_BASE = 2000;

export function drawDeckSmokeStack(
  sstack: IVesselOneBaySlotSmoke,
  zRange: number,
  addZ: number,
  svgSizeRatio: number,
): (SVGPathElement | SVGGElement)[] {
  function drawSingleSS() {
    const w2 = roundDec(feetToMillimeters(sstack.len) * 0.5 * 0.3, 2);
    const w2Svg = roundDec(w2 * svgSizeRatio, 2);
    const snokeBelow = roundDec(SMOKE_BASE * svgSizeRatio, 2);

    const g = document.createElementNS("http://www.w3.org/2000/svg", "g");
    g.setAttribute("data-type", sstack.type);
    g.setAttribute("data-id", sstack.id);

    const mainPath = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "path",
    );

    mainPath.setAttribute("stroke", "#888");
    mainPath.setAttribute("fill", "#fff");
    mainPath.setAttribute("stroke-width", "1");
    mainPath.setAttribute("fill-opacity", "0.2");

    mainPath.setAttribute(
      "d",
      `M${-w2Svg - snokeBelow},${-snokeBelow * 2} 
       L${-w2Svg - snokeBelow},${snokeBelow * 2} 
       L${w2Svg + snokeBelow},${snokeBelow * 2} 
       L${w2Svg + snokeBelow},${-snokeBelow * 2}
       L${-w2Svg - snokeBelow},${-snokeBelow * 2}`,
    );

    g.appendChild(mainPath);

    const ss1 = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "circle",
    );
    ss1.setAttribute("cx", `${0}`);
    ss1.setAttribute("cy", `${-snokeBelow}`);
    ss1.setAttribute("r", `${snokeBelow}`);
    ss1.setAttribute("fill", "#3d2814");
    ss1.setAttribute("stroke", "#888");
    g.appendChild(ss1);

    const ss2 = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "circle",
    );
    ss2.setAttribute("cx", `${0}`);
    ss2.setAttribute("cy", `${snokeBelow}`);
    ss2.setAttribute("r", `${snokeBelow}`);
    ss2.setAttribute("fill", "#3d2814");
    ss2.setAttribute("stroke", "#888");
    g.appendChild(ss2);

    return g;
  }

  const g = document.createElementNS("http://www.w3.org/2000/svg", "g");

  if (sstack.numberOfSmokeStacks === 1) {
    const s1 = drawSingleSS();
    s1.setAttribute("transform", `translate(0,${zRange * 0.5})`);
    g.appendChild(s1);
  } else {
    const s1 = drawSingleSS();
    const s2 = drawSingleSS();
    s1.setAttribute("transform", `translate(0,${zRange * 0.35})`);
    s2.setAttribute("transform", `translate(0,${zRange * 0.65})`);

    g.appendChild(s1);
    g.appendChild(s2);
  }

  const result: (SVGPathElement | SVGGElement)[] = [g];
  return result;
}
