import {
  ForeAftEnum,
  IVesselPartBridge,
  IVesselPartCrane,
  IVesselPartSmokeStack,
  IVesselPartSpacer,
  IVesselParts,
  VesselPartTypeEnum,
} from "open-vessel-definition";

import { cloneObject } from "@tedivo/tedivo-pure-helpers";

export function partsReducer(
  currentVesselParts: readonly IVesselParts[],
  cmd: IActionVesselPart,
): IVesselParts[] {
  const newVesselParts = cloneObject(currentVesselParts) as IVesselParts[];

  // Halt if data is inconsistent
  const possibleError = verifyDataConsistencyOfActionAndSlots(
    cmd,
    newVesselParts,
  );

  if (possibleError) {
    console.warn("Error including Vessel Part: ", possibleError);
    return newVesselParts;
  }

  switch (cmd.action) {
    case ActionTypesEnum.ADD_CRANE_PART:
    case ActionTypesEnum.ADD_BRIDGE_PART:
    case ActionTypesEnum.ADD_SMOKE_PART:
    case ActionTypesEnum.ADD_SPACER_PART: {
      const details = cmd.details;
      const newSlot = {
        ...details,
        posRef: cmd.posRef,
        slotRefId: cmd.slotRefId,
        id: createRandomId(),
      };

      // 1. Check if some other slot is already there
      const index = currentVesselParts.findIndex(
        (s) => s.slotRefId === cmd.slotRefId && s.posRef === cmd.posRef,
      );

      if (index === -1) {
        // 1.1 No slot is there, add it
        newVesselParts.push(newSlot);
      } else {
        // 1.2. Replace it and use the new slot as reference for the old
        const oldSlot = newVesselParts[index];
        oldSlot.slotRefId = newSlot.id;
        newVesselParts.splice(index, 0, newSlot);
      }

      break;
    }
    case ActionTypesEnum.REPLACE_CRANE_PART:
    case ActionTypesEnum.REPLACE_BRIDGE_PART:
    case ActionTypesEnum.REPLACE_SMOKE_PART:
    case ActionTypesEnum.REPLACE_SPACER_PART: {
      const details = cmd.details;
      const index = newVesselParts.findIndex((s) => s.id === details.id);
      const r = {
        ...details,
        id: details.id,
      };
      newVesselParts[index] = r;
      break;
    }
    case ActionTypesEnum.REMOVE_CRANE_PART:
    case ActionTypesEnum.REMOVE_BRIDGE_PART:
    case ActionTypesEnum.REMOVE_SMOKE_PART:
    case ActionTypesEnum.REMOVE_SPACER_PART: {
      const index = newVesselParts.findIndex((s) => s.id === cmd.id);
      newVesselParts.splice(index, 1);

      break;
    }
    case ActionTypesEnum.CLEAN_UNUSED_PARTS: {
      return cleanUnusableSlots(newVesselParts);
    }
  }

  return newVesselParts;
}

export enum ActionTypesEnum {
  ADD_CRANE_PART = "ADD_CRANE_PART",
  REPLACE_CRANE_PART = "REPLACE_CRANE_PART",
  REMOVE_CRANE_PART = "REMOVE_CRANE_PART",
  ADD_BRIDGE_PART = "ADD_BRIDGE_PART",
  REPLACE_BRIDGE_PART = "REPLACE_BRIDGE_PART",
  REMOVE_BRIDGE_PART = "REMOVE_BRIDGE_PART",
  ADD_SMOKE_PART = "ADD_SMOKE_PART",
  REPLACE_SMOKE_PART = "REPLACE_SMOKE_PART",
  REMOVE_SMOKE_PART = "REMOVE_SMOKE_PART",
  ADD_SPACER_PART = "ADD_SPACER_PART",
  REPLACE_SPACER_PART = "REPLACE_SPACER_PART",
  REMOVE_SPACER_PART = "REMOVE_SPACER_PART",
  CLEAN_UNUSED_PARTS = "CLEAN_UNUSED_PARTS",
}

/** Verifies that data and action are correct. Throw errors if not (and halt the function) */
function verifyDataConsistencyOfActionAndSlots(
  cmd: IActionVesselPart,
  vesselParts: readonly IVesselParts[],
):
  | "errors:slotRefNotFound"
  | "errors:partIncorrect"
  | "errors:slotToReplaceNotFound"
  | "errors:slotToRemoveNotFound"
  | undefined {
  if (cmd.action === ActionTypesEnum.CLEAN_UNUSED_PARTS) {
    return undefined;
  } else if (
    cmd.action === ActionTypesEnum.ADD_CRANE_PART ||
    cmd.action === ActionTypesEnum.ADD_BRIDGE_PART ||
    cmd.action === ActionTypesEnum.ADD_SMOKE_PART ||
    cmd.action === ActionTypesEnum.ADD_SPACER_PART
  ) {
    const slotRefIdIsBay = !isNaN(Number(cmd.slotRefId));
    if (slotRefIdIsBay) return undefined;

    const index = vesselParts.findIndex((s) => s.id === cmd.slotRefId);
    if (index === -1) {
      return "errors:slotRefNotFound";
    }
  } else if (
    // Verify data consistency for REPLACE actions
    cmd.action === ActionTypesEnum.REPLACE_CRANE_PART ||
    cmd.action === ActionTypesEnum.REPLACE_BRIDGE_PART ||
    cmd.action === ActionTypesEnum.REPLACE_SMOKE_PART ||
    cmd.action === ActionTypesEnum.REPLACE_SPACER_PART
  ) {
    const details = cmd.details;
    if (details.id !== cmd.id) {
      return "errors:partIncorrect";
    }
    const index = vesselParts.findIndex(
      (slot) =>
        ((slot.type === VesselPartTypeEnum.CRANE &&
          cmd.action === ActionTypesEnum.REPLACE_CRANE_PART) ||
          (slot.type === VesselPartTypeEnum.BRIDGE &&
            cmd.action === ActionTypesEnum.REPLACE_BRIDGE_PART) ||
          (slot.type === VesselPartTypeEnum.SMOKE &&
            cmd.action === ActionTypesEnum.REPLACE_SMOKE_PART) ||
          (slot.type === VesselPartTypeEnum.SPACER &&
            cmd.action === ActionTypesEnum.REPLACE_SPACER_PART)) &&
        slot.id === cmd.id,
    );
    if (index === -1) {
      return "errors:slotToReplaceNotFound";
    }
  } else if (
    // Verify data consistency for REMOVE actions
    cmd.action === ActionTypesEnum.REMOVE_CRANE_PART ||
    cmd.action === ActionTypesEnum.REMOVE_BRIDGE_PART ||
    cmd.action === ActionTypesEnum.REMOVE_SMOKE_PART ||
    cmd.action === ActionTypesEnum.REMOVE_SPACER_PART
  ) {
    const index = vesselParts.findIndex(
      (slot) =>
        ((slot.type === VesselPartTypeEnum.CRANE &&
          cmd.action === ActionTypesEnum.REMOVE_CRANE_PART) ||
          (slot.type === VesselPartTypeEnum.BRIDGE &&
            cmd.action === ActionTypesEnum.REMOVE_BRIDGE_PART) ||
          (slot.type === VesselPartTypeEnum.SMOKE &&
            cmd.action === ActionTypesEnum.REMOVE_SMOKE_PART) ||
          (slot.type === VesselPartTypeEnum.SPACER &&
            cmd.action === ActionTypesEnum.REMOVE_SPACER_PART)) &&
        slot.id === cmd.id,
    );
    if (index === -1) {
      return "errors:slotToRemoveNotFound";
    }
  }

  return undefined;
}

function cleanUnusableSlots(vesselParts: IVesselParts[]): IVesselParts[] {
  const usedIds = new Set<string>(vesselParts.map((slot) => slot.id));

  return vesselParts.filter((slot) => {
    const isBay = !isNaN(Number(slot.slotRefId));
    if (isBay) return true;

    return usedIds.has(slot.slotRefId);
  });
}

/** Create a random id */
function createRandomId() {
  const array = new Uint32Array(3);
  self.crypto.getRandomValues(array);
  return "id-" + array.join("-");
}

export type IActionVesselPart =
  | {
      action: ActionTypesEnum.ADD_CRANE_PART;
      slotRefId: string;
      posRef: ForeAftEnum;
      details: Omit<IVesselPartCrane, "id" | "slotRefId" | "posRef">;
    }
  | {
      action: ActionTypesEnum.REPLACE_CRANE_PART;
      id: string;
      details: IVesselPartCrane;
    }
  | {
      action: ActionTypesEnum.REMOVE_CRANE_PART;
      id: string;
    }
  | {
      action: ActionTypesEnum.ADD_BRIDGE_PART;
      slotRefId: string;
      posRef: ForeAftEnum;
      details: Omit<IVesselPartBridge, "id" | "slotRefId" | "posRef">;
    }
  | {
      action: ActionTypesEnum.REPLACE_BRIDGE_PART;
      id: string;
      details: IVesselPartBridge;
    }
  | {
      action: ActionTypesEnum.REMOVE_BRIDGE_PART;
      id: string;
    }
  | {
      action: ActionTypesEnum.ADD_SMOKE_PART;
      slotRefId: string;
      posRef: ForeAftEnum;
      details: Omit<IVesselPartSmokeStack, "id" | "slotRefId" | "posRef">;
    }
  | {
      action: ActionTypesEnum.REPLACE_SMOKE_PART;
      id: string;
      details: IVesselPartSmokeStack;
    }
  | {
      action: ActionTypesEnum.REMOVE_SMOKE_PART;
      id: string;
    }
  | {
      action: ActionTypesEnum.ADD_SPACER_PART;
      slotRefId: string;
      posRef: ForeAftEnum;
      details: Omit<IVesselPartSpacer, "id" | "slotRefId" | "posRef">;
    }
  | {
      action: ActionTypesEnum.REPLACE_SPACER_PART;
      id: string;
      details: IVesselPartSpacer;
    }
  | {
      action: ActionTypesEnum.REMOVE_SPACER_PART;
      id: string;
    }
  | {
      action: ActionTypesEnum.CLEAN_UNUSED_PARTS;
    };
