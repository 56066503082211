import { CONT_LENS, TCG_IN_MMM } from "@tedivo/tedivo-bay-grid-pure";
import { feetToMillimeters, roundDec } from "@tedivo/tedivo-pure-helpers";

import { TContainerLengths } from "open-vessel-definition";

export function generateTopContainerSymbols(
  {
    fillColor,
    strokeColor = "#666",
    strokeWidth = 0.15,
  }: IGenerateSideContainerSymbolsProps,
  svgSizeRatio: number,
) {
  const strokeDashLength = 1.5;
  const symbols = {} as { [name in TContainerLengths]: SVGElement };

  CONT_LENS.forEach((len) => {
    const depthInMilimeters = roundDec(TCG_IN_MMM * svgSizeRatio, 1);
    const lengthInMeters = feetToMillimeters(len) * svgSizeRatio;

    const svgBoxSymbol = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "symbol",
    );

    const rect = document.createElementNS("http://www.w3.org/2000/svg", "path");

    if (!isNaN(svgSizeRatio)) {
      rect.setAttribute("stroke", strokeColor);
      rect.setAttribute("stroke-width", String(strokeWidth));
      if (fillColor) {
        rect.setAttribute("fill", fillColor);
        rect.setAttribute("fill-opacity", "0.5");
      } else rect.setAttribute("fill-opacity", "0");

      // Outer box. This is centered on LCG/TCG (centroid)
      const pathParts: string[] = [
        `M${-lengthInMeters * 0.5},${
          -depthInMilimeters * 0.5
        } h${lengthInMeters} v${depthInMilimeters} h-${lengthInMeters} z`,
      ];

      if (len >= 40) {
        let lineFrom = roundDec(-depthInMilimeters * 0.5);
        let lineTo = roundDec(lineFrom + strokeDashLength);
        while (
          lineTo < depthInMilimeters * 0.5 &&
          !isNaN(lineTo) &&
          !isNaN(lineFrom)
        ) {
          pathParts.push(`M0,${lineFrom} L0,${lineTo}`);
          lineFrom = roundDec(lineTo + strokeDashLength);
          lineTo = roundDec(lineFrom + strokeDashLength);
        }
      }

      rect.setAttribute("d", pathParts.join(" "));
    }

    svgBoxSymbol.id = `dmbCn${len}`;
    svgBoxSymbol.setAttribute("style", "overflow:visible");
    svgBoxSymbol.appendChild(rect);
    symbols[len] = svgBoxSymbol;
  });

  return symbols;
}

export interface IGenerateSideContainerSymbolsProps {
  fillColor?: string;
  strokeColor?: string;
  shipStrokeColor?: string;
  strokeWidth?: number;
  fontColor?: string;
}
