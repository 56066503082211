import "@shoelace-style/shoelace/dist/components/button/button";
import "@shoelace-style/shoelace/dist/components/dropdown/dropdown";
import "@shoelace-style/shoelace/dist/components/menu-item/menu-item";
import "@shoelace-style/shoelace/dist/components/menu/menu";

import {
  SupportedLanguagesType,
  changeLanguage,
  i18n,
  supportedLanguages,
} from "../../../app/i18/i18tn";
import {
  getPreferencesValue,
  setPreferencesKeyAndValue,
} from "@tedivo/tedivo-preferences";

import { createSelectShoelace } from "@tedivo/tedivo-form";

export class TVDLangSelectorElement extends HTMLElement {
  public static observedAttributes = [];

  private currentLang: SupportedLanguagesType = supportedLanguages[0];

  connectedCallback() {
    const savedLanguage = getPreferencesValue(
      "i18n-language",
    ) as SupportedLanguagesType;

    this.currentLang =
      savedLanguage || (i18n.language as SupportedLanguagesType);

    const languageSelect = createSelectShoelace<SupportedLanguagesType>({
      id: "language-selector",
      buttonText: this.currentLang.toUpperCase(),
      caret: true,
      pill: true,
      size: "small",
      options: supportedLanguages.map((lang) => ({
        value: lang,
        name: lang.toUpperCase(),
      })),
      onChange: this.onChangeLanguage,
      updateButtonText: (v: SupportedLanguagesType) => v.toUpperCase(),
    });

    languageSelect.value = this.currentLang;

    this.appendChild(languageSelect);
  }

  private onChangeLanguage = (v: SupportedLanguagesType & string) => {
    if (this.currentLang === v) return;

    this.currentLang = v;
    setPreferencesKeyAndValue("i18n-language", v);

    changeLanguage(this.currentLang);

    document.documentElement.setAttribute("lang", v);
  };
}

customElements.define("tvd-language-sel-element", TVDLangSelectorElement);

declare global {
  interface HTMLElementTagNameMap {
    "tvd-language-sel-element": TVDLangSelectorElement;
  }
}
