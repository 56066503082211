import {
  BayLevelEnum,
  IBayLevelData,
  IMasterCGs,
  IShipData,
  ISizeSummary,
  TBayRowInfo,
  TCommonBayInfo,
  TContainerLengths,
  TRowInfoByLength,
  addBayToSummary,
} from "open-vessel-definition";
import {
  IBayPattern,
  IRowPattern,
  ISlotPattern,
  TCG_IN_MMM,
  createRowsFromConfig,
  getRowsAndTiersFromSlotKeys,
  getSizesFromSlots,
} from "@tedivo/tedivo-bay-grid-pure";
import {
  ITransponserFunctions,
  IUnitsConverterFunctions,
  RecursiveKeyOf,
  pad2,
  pad3,
  roundDec,
  sortNumericAsc,
  sortNumericDesc,
} from "@tedivo/tedivo-pure-helpers";
import {
  createInputWithUnits,
  createMultiEditButton,
} from "@tedivo/tedivo-form";

import BaySelectorComponent from "../bay-selector.component";
import { type EditDrawer } from "../../../../../components/common/EditDrawer";
import FieldsValuesStore from "../../../../../app/stores/FieldsValuesStore";
import { IBayRowInfo } from "open-vessel-definition/build/src/models/v1/parts/IBayLevelData";
import SlButton from "@shoelace-style/shoelace/dist/components/button/button";
import SlRadioGroup from "@shoelace-style/shoelace/dist/components/radio-group/radio-group";
import VcgVerticalReferenceEnum from "../../../../../app/enums/VcgVerticalReferenceEnum";
import { createCopyBayCGsDialog } from "./helpers/editCGs/createCopyBayCGsDialog";
import { createSvgText } from "@tedivo/tedivo-bay-grid-core";
import { getTranslation } from "../../../../../app/i18/i18tn";
import globalUnits from "../../../../../app/units/globalUnits";
import goSquared from "../../../../../app/tracking/goSquared";
import ovdJsonStore from "../../../../../app/stores/OVDJsonStore";
import { removeChildren } from "@tedivo/tedivo-dom-helpers";

export function createCGsEdit(
  shipData: IShipData,
  sizeSummary: ISizeSummary,
  bls: Array<IBayLevelData>,
  editDrawer: EditDrawer,
): IEditReturn {
  goSquared.addEvent("Edit-OVD - Edit Bay CGs");

  const dataStore = new FieldsValuesStore();

  const holder = document.createElement("div");
  const editingArea = document.createElement("section");
  editingArea.className = "editing-area";

  const {
    node: toolsNode,
    baySelect,
    radioButtonGroupLevel,
    btnNextBay,
    btnPrevBay,
    updatePrevNextButtons,
  } = createEditingTools(sizeSummary, dataStore);

  holder.appendChild(toolsNode);
  holder.appendChild(editingArea);

  const root = getComputedStyle(document.body);
  const diagramLookAndFeel: IDiagramLookAndFeel = {
    textColor: root.getPropertyValue("--sl-color-neutral-800"),
    containerFill: root.getPropertyValue("--sl-color-neutral-100"),
    containerStroke: root.getPropertyValue("--sl-color-neutral-300"),
    tcgStroke: root.getPropertyValue("--sl-color-orange-400"),
    bbStroke: root.getPropertyValue("--sl-color-primary-300"),
    thStroke: root.getPropertyValue("--sl-color-green-300"),
  };

  createBayEditingFields(
    baySelect.value as IBayPattern,
    Number(radioButtonGroupLevel.value),
    shipData,
    sizeSummary,
    bls,
    editingArea,
    dataStore,
    diagramLookAndFeel,
    editDrawer,
  );

  dataStore.updateConsumers();

  baySelect.dropdown.addEventListener("selected", reDrawEditingFields, false);
  radioButtonGroupLevel.addEventListener(
    "sl-change",
    reDrawEditingFields,
    false,
  );
  btnNextBay.addEventListener("click", goNext, false);
  btnPrevBay.addEventListener("click", goPrev, false);
  btnPrevBay.disabled = true;

  return {
    node: holder,
    submitFunction: submitPassedToEditDrawer,
  };

  // #region internalFns
  function reDrawEditingFields() {
    dataStore.cleanUp();
    removeChildren(editingArea);
    createBayEditingFields(
      baySelect.value as IBayPattern,
      Number(radioButtonGroupLevel.value),
      shipData,
      sizeSummary,
      bls,
      editingArea,
      dataStore,
      diagramLookAndFeel,
      editDrawer,
    );
    dataStore.updateConsumers();
  }

  function goNext() {
    const cBay = Number(baySelect.value);
    if (cBay < sizeSummary.isoBays) {
      baySelect.value = pad3(cBay + 2);
      reDrawEditingFields();
      updatePrevNextButtons(baySelect.value);
    }
  }

  function goPrev() {
    const cBay = Number(baySelect.value);
    if (cBay > 1) {
      baySelect.value = pad3(cBay - 2);
      reDrawEditingFields();
      updatePrevNextButtons(baySelect.value);
    }
  }

  function submitPassedToEditDrawer() {
    const allKeys = dataStore.getAllKeys();
    const baysLevels = getBayLevelKeysFromDsKeys(allKeys);

    baysLevels.forEach(({ isoBay, level, dsKey }) => {
      const newPerRowInfo: TBayRowInfo = { each: {} };

      // 1 bay.infoByContLength
      const infoByContLength = dataStore.getValuesOfParent<TRowInfoByLength>(
        `${dsKey}.infoByContLength`,
      );
      (
        Object.keys(infoByContLength).map(Number) as TContainerLengths[]
      ).forEach((size) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        infoByContLength[size]!.size = size;
      });

      // 2.1 Row Info - common
      newPerRowInfo.common = dataStore.getValuesOfParent<TCommonBayInfo>(
        `${dsKey}.common`,
      );

      // 2.2 Row Info - each
      const dsEachPrefix = `${dsKey}.each`;
      const dsEachPrefixLen = dsEachPrefix.length + 1;
      const rows = allKeys
        .filter((s) => s.indexOf(dsEachPrefix) === 0)
        .map((s) => s.substring(dsEachPrefixLen).split(".").shift())
        .filter((s, idx, arr) => arr.indexOf(s) === idx) as IRowPattern[];

      rows.forEach((row) => {
        const rowInfo: IBayRowInfo = {
          ...dataStore.getValuesOfParent<IBayRowInfo>(`${dsEachPrefix}.${row}`),
          isoRow: row,
        };

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        newPerRowInfo.each![row] = clearRowInfo(rowInfo);
      });

      goSquared.addEvent("Edit-OVD - Edit Bay CGs - Save");

      ovdJsonStore.setCGsInfo(isoBay, level, newPerRowInfo, infoByContLength);
    });

    return true;

    function getBayLevelKeysFromDsKeys(allKeys: string[]): IBayLevelKeys[] {
      return allKeys
        .map((key) => key.split(".").shift())
        .filter((v, idx, arr) => arr?.indexOf(v) === idx)
        .map((s) => {
          if (s) {
            const ps = s.split("-");
            return {
              isoBay: ps[0].substring(1) as IBayPattern,
              level: Number(ps[1]) as BayLevelEnum,
              dsKey: s,
            };
          }
          return undefined;
        })
        .filter((b) => b !== undefined) as IBayLevelKeys[];
    }

    function clearRowInfo(rowInfo: IBayRowInfo) {
      const propKeys = Object.getOwnPropertyNames(
        rowInfo,
      ) as (keyof IBayRowInfo)[];

      // Delete undefineds
      propKeys.forEach((key) => {
        if (rowInfo[key] === undefined) delete rowInfo[key];
      });

      const rowInfoByLength = rowInfo.rowInfoByLength;
      if (rowInfoByLength !== undefined) {
        const sizes = Object.keys(rowInfoByLength).map(
          Number,
        ) as TContainerLengths[];
        sizes.forEach((size) => {
          const o = rowInfoByLength[size];
          if (
            o?.bottomWeight === undefined &&
            o?.lcg === undefined &&
            o?.rowWeight === undefined
          ) {
            delete rowInfoByLength[size];
          }
        });
      }

      if (
        rowInfoByLength &&
        Object.getOwnPropertyNames(rowInfoByLength).length === 0
      )
        delete rowInfo.rowInfoByLength;

      return rowInfo;
    }
  }

  // #endregion internalFns
}

function createBayEditingFields(
  isoBay: IBayPattern,
  level: BayLevelEnum,
  shipData: IShipData,
  sizeSummary: ISizeSummary,
  bls: Array<IBayLevelData>,
  holder: HTMLElement,
  dataStore: FieldsValuesStore,
  diagramLookAndFeel: IDiagramLookAndFeel,
  editDrawer: EditDrawer,
) {
  const bayData = bls.find((bl) => bl.isoBay === isoBay && bl.level === level);

  if (!bayData) {
    holder.appendChild(
      document.createTextNode(getTranslation("view:noBayLevelData")),
    );
    return;
  }

  const baseKey = `b${isoBay}-${level}`;

  const baySummary: ISizeSummary = {
    isoBays: 1,
    centerLineRow: sizeSummary.centerLineRow,
    maxRow: undefined,
    maxAboveTier: undefined,
    minAboveTier: undefined,
    maxBelowTier: undefined,
    minBelowTier: undefined,
  };

  // 0. Calculate Size from bay data
  addBayToSummary(bayData, baySummary);

  // 0.1 Get the Rows in bay
  const bayRows = createRowsFromConfig(
    !!baySummary.centerLineRow,
    baySummary.maxRow,
  );

  // 1 Use perSlot to discover current lengths used
  const allSizes = getSizesFromSlots(bayData.perSlotInfo).sizes.sort(
    sortNumericAsc,
  );

  // 1.1 Use perSlot to get bottom and top tiers per row
  const { minTier, tiersByRow } = getRowsAndTiersFromSlotKeys(
    bayData?.perSlotInfo
      ? (Object.keys(bayData?.perSlotInfo) as ISlotPattern[])
      : undefined,
  );

  const tableVcgs = document.createElement("table");
  const tHead = document.createElement("thead");
  const tbody = document.createElement("tbody");

  const rowsParts: string[][] = [
    bayRows.filter((s) => Number(s) % 2 === 0).sort(sortNumericDesc),
    bayRows.filter((s) => Number(s) % 2 === 1).sort(sortNumericDesc),
  ];

  if (sizeSummary.centerLineRow) {
    rowsParts[1].push("");
  }

  const maxCells = Math.max(rowsParts[0].length, rowsParts[1].length) + 2;

  const valuesSign: { [s: string]: "positive" | "negative" } = {
    "0_2": "negative",
    "0_1": "positive",
    "1_2": "positive",
    "1_1": "negative",
  };

  const commonRowInfo = bayData.perRowInfo?.common;

  rowsParts.forEach((rowsSide, idx) => {
    const trTitle = document.createElement("tr");
    const trSideTitle = document.createElement("tr");

    const showCommonColumn = idx === 0;

    // Row 0, Side
    const tdSide = document.createElement("th");
    tdSide.colSpan = maxCells;
    tdSide.innerHTML = `${getTranslation(
      `enums:PortStarboardEnum.${showCommonColumn ? "PORT" : "STARBOARD"}`,
    )} <small>(${getTranslation("view:tcg")} ${getTranslation(
      `general:valuesSign.${valuesSign[`${idx}_${globalUnits.units.tcgDir}`]}`,
    )})</small>`;
    trSideTitle.appendChild(tdSide);

    // Row 1, Label, Common and Rows numbers
    const tdRowTitle = document.createElement("th");
    tdRowTitle.innerHTML = getTranslation("general:grid.row");
    trTitle.appendChild(tdRowTitle);

    const tdCommonLabel = document.createElement("th");
    if (showCommonColumn)
      tdCommonLabel.innerHTML = getTranslation("general:common.common");
    trTitle.appendChild(tdCommonLabel);

    tbody.appendChild(trSideTitle);
    tbody.appendChild(trTitle);

    // Labels of Rows (12, 10, 08, ...)
    rowsSide.forEach((rowLabel) => {
      const tdHeader = document.createElement("th");
      tdHeader.innerHTML = rowLabel;
      trTitle.appendChild(tdHeader);
    });

    // Attributes
    const fields: IAttributesRows[] = [
      {
        label: getTranslation("view:tcg"),
        labelClassname: "view-tcg",
        converter: globalUnits.lengthUnits,
        transponser: globalUnits.tcgTransposer,
        noCommon: true,
        dataAttr: "tcg",
        placeHolderFn: (row: IRowPattern) =>
          shipData.masterCGs[
            level === BayLevelEnum.ABOVE ? "aboveTcgs" : "belowTcgs"
          ][row],
      },
      {
        label: getTranslation("view:vcgFields.bottomBase"),
        labelClassname: "view-vcgFields-bottomBase",
        subLabel:
          globalUnits.vcgTransposer.transposeTo !==
          VcgVerticalReferenceEnum.BOTTOM_BASE
            ? getTranslation(
                `enums:VcgVerticalReferenceEnum.${
                  VcgVerticalReferenceEnum[
                    globalUnits.vcgTransposer.transposeTo
                  ]
                }`,
              )
            : undefined,
        dataAttr: "bottomBase",
        placeHolderFn: (row: IRowPattern) => {
          const bottomIsoTier = tiersByRow[row]?.minTier
            ? pad2(tiersByRow[row]?.minTier)
            : "";
          return commonRowInfo?.bottomBase
            ? commonRowInfo.bottomBase
            : bottomIsoTier
            ? shipData.masterCGs.bottomBases[bottomIsoTier]
            : undefined;
        },
        converter: globalUnits.lengthUnits,
        transponser: globalUnits.vcgTransposer,
        commonAttr: "bottomBase",
        commonPlaceHolderFn: () =>
          minTier ? shipData.masterCGs.bottomBases[minTier] : undefined,
      },
      {
        label: getTranslation("view:maxHeight"),
        labelClassname: "view-maxHeight",
        dataAttr: "maxHeight",
        converter: globalUnits.lengthUnits,
        commonAttr: "maxHeight",
        placeHolderFn: () => commonRowInfo?.maxHeight || undefined,
        commonPlaceHolderFn: () => undefined,
      },
    ];

    // Add rowInfoByLength
    allSizes.forEach((size) => {
      fields.push({
        label: `<strong>${size}'</strong> ${getTranslation("view:lcg")}`,
        dataAttr: `rowInfoByLength.${size}.lcg`,
        transponser: globalUnits.lcgTransposer,
        converter: globalUnits.lengthUnits,
        infoByLengthAttr: `${size}.lcg`,
        placeHolderFn: () => bayData.infoByContLength?.[size]?.lcg,
        commonPlaceHolderFn: () => undefined,
      });

      fields.push({
        label: `<strong>${size}'</strong> ${getTranslation("view:rowWeight")}`,
        dataAttr: `rowInfoByLength.${size}.rowWeight`,
        converter: globalUnits.massUnits,
        infoByLengthAttr: `${size}.rowWeight`,
        placeHolderFn: () => bayData.infoByContLength?.[size]?.rowWeight,
        commonPlaceHolderFn: () => undefined,
      });

      fields.push({
        label: `<strong>${size}'</strong> ${getTranslation(
          "view:bottomWeight",
        )}`,
        dataAttr: `rowInfoByLength.${size}.bottomWeight`,
        converter: globalUnits.massUnits,
        infoByLengthAttr: `${size}.bottomWeight`,
        placeHolderFn: () => bayData.infoByContLength?.[size]?.bottomWeight,
        commonPlaceHolderFn: () => undefined,
      });
    });

    fields.forEach((field) => {
      const tr = drawRow(field, showCommonColumn, rowsSide);
      tbody.appendChild(tr);
    });
  });

  tableVcgs.appendChild(tHead);
  tableVcgs.appendChild(tbody);

  tableVcgs.className = "tvd-table tvd-table-sticky";
  tableVcgs.appendChild(tHead);
  tableVcgs.appendChild(tbody);
  holder.appendChild(tableVcgs);

  const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  svg.setAttribute("width", `400px`);
  svg.setAttribute("height", `0px`);
  svg.setAttribute("viewBox", `0 0 400 0`);
  svg.setAttribute("class", "cgsBayView");

  drawAftDiagram(
    isoBay,
    level,
    bayRows,
    bayData,
    shipData.masterCGs,
    dataStore,
    svg,
    diagramLookAndFeel,
  );

  dataStore.onChange = () => {
    drawAftDiagram(
      isoBay,
      level,
      bayRows,
      bayData,
      shipData.masterCGs,
      dataStore,
      svg,
      diagramLookAndFeel,
    );
    editDrawer.dataIsDirty = true;
  };

  holder.appendChild(svg);

  /**
   * Draws each Row with Common and Rows inputs
   * @returns A TR
   */
  function drawRow(
    rowAttrs: IAttributesRows,
    usesCommon: boolean,
    rowsInSide: string[],
  ) {
    const tr = document.createElement("tr");

    // Label
    const tdLabel = document.createElement("td");
    const sp = document.createElement("span");
    if (rowAttrs.labelClassname) sp.className = rowAttrs.labelClassname;
    sp.innerHTML = rowAttrs.label;

    tdLabel.appendChild(sp);
    if (rowAttrs.subLabel) {
      const subLabelSmall = document.createElement("small");
      subLabelSmall.innerHTML = rowAttrs.subLabel;
      tdLabel.appendChild(document.createElement("br"));
      tdLabel.appendChild(subLabelSmall);
    }
    tdLabel.style.minWidth = "12ch";
    tr.appendChild(tdLabel);

    // Common field
    const tdCommon = document.createElement("td");
    if (usesCommon && !rowAttrs.noCommon) {
      const name = rowAttrs.commonAttr
        ? `${baseKey}.common.${rowAttrs.commonAttr}`
        : `${baseKey}.infoByContLength.${rowAttrs.infoByLengthAttr}`;

      const value = rowAttrs.commonAttr
        ? dataStore.getValueAsNumber(name) ||
          commonRowInfo?.[rowAttrs.commonAttr]
        : dataStore.getValueAsNumber(name) ||
          getInfoByLengthValue(
            bayData?.infoByContLength,
            rowAttrs.infoByLengthAttr,
          );

      const inp = createInputWithUnits({
        name,
        value,
        placeholder: rowAttrs.commonPlaceHolderFn(),
        converter: rowAttrs.converter,
        transponser: rowAttrs.transponser,
        noSpinButtons: true,
      });
      dataStore.registerInputField(inp);
      tdCommon.appendChild(inp);
    }

    tr.appendChild(tdCommon);

    // Data per Row
    rowsInSide.forEach((row) => {
      const td = document.createElement("td");

      if (row !== "") {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const rowInfo = bayData!.perRowInfo?.each?.[row as IRowPattern];
        const rowKey = `${baseKey}.each.${row}.${rowAttrs.dataAttr}`;

        const inp = createInputWithUnits({
          name: rowKey,
          value:
            dataStore.getValueAsNumber(rowKey) ||
            getRowValue(rowInfo, rowAttrs.dataAttr),
          placeholder: rowAttrs.placeHolderFn(row as IRowPattern),
          converter: rowAttrs.converter,
          transponser: rowAttrs.transponser,
          noSpinButtons: true,
        });
        dataStore.registerInputField(inp);

        if (rowAttrs.commonAttr) {
          dataStore.registerDataConsumer(
            inp,
            "placeholder",
            `${baseKey}.common.${rowAttrs.commonAttr}`,
          );
        } else if (rowAttrs.infoByLengthAttr) {
          dataStore.registerDataConsumer(
            inp,
            "placeholder",
            `${baseKey}.infoByContLength.${rowAttrs.infoByLengthAttr}`,
          );
        }

        if (row !== "00") {
          inp.addEventListener("sl-change", () => {
            const nameParts = inp.name.split(".");
            if (nameParts.length > 3 && !isNaN(Number(nameParts[2]))) {
              const iRow = Number(nameParts[2]);
              const sisterIRow = iRow % 2 === 1 ? iRow + 1 : iRow - 1;
              const sisterFieldName = nameParts
                .map((p, idx) => (idx === 2 ? pad2(sisterIRow) : p))
                .join(".");

              if (dataStore.getValue(sisterFieldName) === undefined) {
                const value =
                  nameParts[nameParts.length - 1] === "tcg"
                    ? -Number(inp.value)
                    : inp.value;
                dataStore.setValue(sisterFieldName, value);
                dataStore.updateConsumers();
              }
            }
          });
        }

        td.appendChild(inp);
      }

      tr.appendChild(td);
    });

    return tr;

    function getRowValue(
      rowInfo: IBayRowInfo | undefined,
      key: RecursiveKeyOf<IBayRowInfo>,
    ) {
      if (rowInfo === undefined) return undefined;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let tempObj: any = rowInfo;
      key.split(".").forEach((key) => {
        if (tempObj === undefined) return undefined;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tempObj = (tempObj as any)[key];
      });
      return tempObj;
    }

    function getInfoByLengthValue(
      infoByLen: TRowInfoByLength | undefined,
      key: RecursiveKeyOf<TRowInfoByLength>,
    ) {
      if (infoByLen === undefined) return undefined;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let tempObj: any = infoByLen;
      key.split(".").forEach((key) => {
        if (tempObj === undefined) return undefined;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tempObj = (tempObj as any)[key];
      });
      return tempObj;
    }
  }
}

function createEditingTools(
  sizeSummary: ISizeSummary,
  dataStore: FieldsValuesStore,
): ICreateEditingTools {
  const holder = document.createElement("section");
  holder.className = "cg-tools";

  const btnPrev = document.createElement("sl-button");
  btnPrev.innerHTML = "&larr;";
  btnPrev.variant = "primary";
  btnPrev.tabIndex = 0;

  const btnNext = document.createElement("sl-button");
  btnNext.innerHTML = "&rarr;";
  btnNext.variant = "primary";
  btnNext.tabIndex = 0;

  const baySelect = new BaySelectorComponent(
    sizeSummary.isoBays,
    updatePrevNextButtons,
    1,
  );

  const radioButtonGroupLevel = document.createElement("sl-radio-group");
  const radioAbove = document.createElement("sl-radio");
  const radioBelow = document.createElement("sl-radio");
  radioAbove.innerHTML = getTranslation("enums:BayLevelEnum.ABOVE");
  radioAbove.value = String(BayLevelEnum.ABOVE);
  radioBelow.innerHTML = getTranslation("enums:BayLevelEnum.BELOW");
  radioBelow.value = String(BayLevelEnum.BELOW);
  radioButtonGroupLevel.appendChild(radioAbove);
  radioButtonGroupLevel.appendChild(radioBelow);
  radioButtonGroupLevel.value = String(BayLevelEnum.ABOVE);

  const g = document.createElement("sl-button-group");

  g.appendChild(btnPrev);
  g.appendChild(baySelect.dropdown);
  g.appendChild(btnNext);

  const helpersCommonDialog = document.createElement("sl-dialog");

  const helpersHolder = document.createElement("div");
  helpersHolder.className = "helpers-holder";
  const helpersMainButton = createMultiEditButton({
    id: `helpersCGs`,
    title: getTranslation("view:edit.cgHelpers.title"),
    size: "small",
    variant: "primary",
    showTitleAsTooltip: false,
    iconName: "command",
    options: [
      {
        value: "copyCGsFromBay",
        name: getTranslation("view:edit.cgHelpers.copyBayCGs"),
        action: () => {
          if (
            createCopyBayCGsDialog(
              helpersCommonDialog,
              Number(baySelect.value),
              Number(radioButtonGroupLevel.value),
              ovdJsonStore.currentJson,
              dataStore,
            )
          ) {
            helpersCommonDialog.show();
          }
        },
      },
    ],
  });
  helpersHolder.appendChild(helpersMainButton);

  holder.appendChild(g);
  holder.appendChild(radioButtonGroupLevel);
  holder.appendChild(helpersHolder);
  holder.appendChild(helpersCommonDialog);

  return {
    node: holder,
    baySelect,
    radioButtonGroupLevel,
    btnPrevBay: btnPrev,
    btnNextBay: btnNext,
    updatePrevNextButtons,
  };

  function updatePrevNextButtons(v: string) {
    btnPrev.disabled = Number(v) === 1;
    btnNext.disabled = Number(v) === sizeSummary.isoBays;
  }
}

function drawAftDiagram(
  isoBay: IBayPattern,
  level: BayLevelEnum,
  rows: IRowPattern[],
  bayData: IBayLevelData,
  masterCGs: IMasterCGs,
  dataStore: FieldsValuesStore,
  svg: SVGElement,
  diagramLookAndFeel: IDiagramLookAndFeel,
) {
  const SVG_WIDTH = 400;
  removeChildren(svg);

  const perRowInfo = bayData.perRowInfo;
  if (perRowInfo === undefined) return;

  const baseKey = `b${isoBay}-${level}`;

  const portRow = rows[0],
    stbdRow = rows[rows.length - 1];

  const masterTcgsToUse =
    level === BayLevelEnum.ABOVE ? masterCGs.aboveTcgs : masterCGs.belowTcgs;

  const portTcg =
    dataStore.getValueAsNumber(`${baseKey}.each.${portRow}.tcg`) ||
    masterTcgsToUse[portRow];
  const stbdTcg =
    dataStore.getValueAsNumber(`${baseKey}.each.${stbdRow}.tcg`) ||
    masterTcgsToUse[stbdRow];

  const width = stbdTcg - portTcg + TCG_IN_MMM;

  const bottomBases: { [key: IRowPattern]: number | undefined } = {};
  const totalHeights: { [key: IRowPattern]: number | undefined } = {};
  const bottomTiers: { [key: IRowPattern]: string | undefined } = {};
  const numTiers: { [key: IRowPattern]: number } = {};

  let maxBB = -Infinity,
    minBB = Infinity;

  // 1.1 Use perSlot to get bottom and top tiers per row
  const { tiersByRow } = getRowsAndTiersFromSlotKeys(
    bayData?.perSlotInfo
      ? (Object.keys(bayData?.perSlotInfo) as ISlotPattern[])
      : undefined,
  );

  rows
    .filter((row) => !!tiersByRow[row])
    .forEach((row) => {
      const bottomTier = pad2(tiersByRow[row].minTier);
      const topTier = pad2(tiersByRow[row].maxTier);
      const tiersNum = (Number(topTier) - Number(bottomTier)) * 0.5 + 1;
      const bb =
        dataStore.getValueAsNumber(`${baseKey}.each.${row}.bottomBase`) ??
        dataStore.getValueAsNumber(`${baseKey}.common.bottomBase`) ??
        (bottomTier !== undefined
          ? masterCGs.bottomBases[bottomTier]
          : undefined);
      const mh =
        dataStore.getValueAsNumber(`${baseKey}.each.${row}.maxHeight`) ??
        dataStore.getValueAsNumber(`${baseKey}.common.maxHeight`) ??
        undefined;

      bottomBases[row] = bb;
      totalHeights[row] = mh;
      bottomTiers[row] = bottomTier;
      numTiers[row] = tiersNum;

      if (bb == undefined) return;

      if (mh !== undefined && bb + mh > maxBB) maxBB = bb + mh;
      if (bb + tiersNum * 2591 > maxBB) maxBB = bb + tiersNum * 2591;
      if (bb < minBB) minBB = bb;
    });

  const scaled = (n: number) => roundDec((SVG_WIDTH / width) * n, 2);
  const height = scaled(maxBB - minBB + 3000);

  if (isNaN(width) || isNaN(height)) return;

  const SCALED_2591 = scaled(2591);
  const SCALED_2440 = scaled(2440);

  const bottomBasesPathParts: string[] = [];
  const maxHeigtsPathParts: string[] = [];

  const rowTitles: SVGTextElement[] = [];
  const containers: SVGElement[] = [];
  const tcgPoints: SVGElement[] = [];

  const addX = TCG_IN_MMM * 0.5 + Math.abs(portTcg);
  const textColor = diagramLookAndFeel.textColor;

  rows.forEach((row, idx) => {
    // x (TCGs)
    const tcg =
      dataStore.getValueAsNumber(`${baseKey}.each.${row}.tcg`) ??
      masterTcgsToUse[row];
    const prevTcg =
      idx === 0
        ? tcg - TCG_IN_MMM * 0.5
        : ((dataStore.getValueAsNumber(
            `${baseKey}.each.${rows[idx - 1]}.tcg`,
          ) ?? masterTcgsToUse[rows[idx - 1]]) +
            tcg) *
          0.5;
    const nextTcg =
      idx === rows.length - 1
        ? tcg + TCG_IN_MMM * 0.5
        : ((dataStore.getValueAsNumber(
            `${baseKey}.each.${rows[idx + 1]}.tcg`,
          ) ?? masterTcgsToUse[rows[idx + 1]]) +
            tcg) *
          0.5;

    // y (bottomBases)
    const bottomBase = bottomBases[row];

    if (bottomBase !== undefined) {
      const y = roundDec(height - scaled(-minBB + bottomBase) - 10);

      if (bottomBasesPathParts.length === 0) {
        bottomBasesPathParts.push(`M${scaled(prevTcg + addX)},${y}`);
      }

      bottomBasesPathParts.push(`L${scaled(prevTcg + addX)},${y}`);
      bottomBasesPathParts.push(`L${scaled(nextTcg + addX)},${y}`);

      rowTitles.push(
        createSvgText({
          text: `${row}${bottomTiers[row]}`,
          x: scaled(tcg + addX),
          y: y - 5,
          fontSize: 6,
          textColor,
        }),
      );

      const totalHeightOfRow = totalHeights[row];
      if (totalHeightOfRow !== undefined) {
        const h = -minBB + totalHeightOfRow + bottomBase;
        maxHeigtsPathParts.push(
          `M${scaled(prevTcg + addX)},${height - scaled(h) - 10} L${scaled(
            nextTcg + addX,
          )},${height - scaled(h) - 10}`,
        );
      }

      for (let t = 0; t < numTiers[row]; t += 1) {
        const contSvg = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "use",
        );

        // TODO: Check height of diagram

        contSvg.setAttribute("href", `#cntAftView`);
        contSvg.setAttribute("x", `${scaled(tcg + addX)}`);
        contSvg.setAttribute("y", `${y - t * SCALED_2591}`);

        containers.push(contSvg);
      }

      const tcgPoint = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "use",
      );
      tcgPoint.setAttribute("href", `#tcgPoint`);
      tcgPoint.setAttribute("x", `${scaled(tcg + addX)}`);
      tcgPoint.setAttribute("y", `${y - 2}`);

      tcgPoints.push(tcgPoint);
    }
  });

  svg.setAttribute("width", `${SVG_WIDTH}px`);
  svg.setAttribute("height", `${height}px`);
  svg.setAttribute("viewBox", `0 0 ${SVG_WIDTH} ${height}`);

  const container86Symbol = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "symbol",
  );
  const rect = document.createElementNS("http://www.w3.org/2000/svg", "path");
  rect.setAttribute(
    "d",
    `M${-scaled(
      2440 * 0.5,
    )},${-SCALED_2591} h${SCALED_2440} v${SCALED_2591} h-${SCALED_2440} z`,
  );
  rect.setAttribute("fill", diagramLookAndFeel.containerFill);
  rect.setAttribute("stroke", diagramLookAndFeel.containerStroke);
  rect.setAttribute("stroke-width", "0.5");

  container86Symbol.id = `cntAftView`;
  container86Symbol.setAttribute("style", "overflow:visible");
  container86Symbol.appendChild(rect);
  svg.appendChild(container86Symbol);

  const tcgMarkerSymbol = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "symbol",
  );
  const tcgPoint = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "line",
  );
  tcgPoint.setAttribute("x1", "0");
  tcgPoint.setAttribute("y1", "-3");
  tcgPoint.setAttribute("x2", "0");
  tcgPoint.setAttribute("y2", "6");
  tcgPoint.setAttribute("stroke", diagramLookAndFeel.tcgStroke);
  tcgPoint.setAttribute("stroke-width", "2");

  tcgMarkerSymbol.id = "tcgPoint";
  tcgMarkerSymbol.appendChild(tcgPoint);
  svg.appendChild(tcgMarkerSymbol);

  containers.forEach((cnt) => {
    svg.appendChild(cnt);
  });

  const svgBb = document.createElementNS("http://www.w3.org/2000/svg", "path");
  svgBb.setAttribute("stroke", diagramLookAndFeel.bbStroke);
  svgBb.setAttribute("stroke-width", "1");
  svgBb.setAttribute("fill-opacity", "0");
  svgBb.setAttribute("class", "grid-lines");
  svgBb.setAttribute("stroke-linecap", "round");
  svgBb.setAttribute("d", bottomBasesPathParts.join(" "));
  svg.appendChild(svgBb);

  const svgTh = document.createElementNS("http://www.w3.org/2000/svg", "path");
  svgTh.setAttribute("stroke", diagramLookAndFeel.thStroke);
  svgTh.setAttribute("stroke-width", "1");
  svgTh.setAttribute("fill-opacity", "0");
  svgTh.setAttribute("class", "grid-lines");
  svgTh.setAttribute("stroke-linecap", "round");
  svgTh.setAttribute("d", maxHeigtsPathParts.join(" "));
  svg.appendChild(svgTh);

  rowTitles.forEach((svgText) => {
    svg.appendChild(svgText);
  });

  tcgPoints.forEach((point) => {
    svg.appendChild(point);
  });

  return svg;
}

interface ICreateEditingTools {
  node: HTMLElement;
  baySelect: BaySelectorComponent;
  radioButtonGroupLevel: SlRadioGroup;
  btnPrevBay: SlButton;
  btnNextBay: SlButton;
  updatePrevNextButtons: (s: string) => void;
}

interface IEditReturn {
  node: HTMLElement;
  submitFunction: () => boolean;
}

interface IBayLevelKeys {
  isoBay: IBayPattern;
  level: BayLevelEnum;
  dsKey: string;
}

interface IDiagramLookAndFeel {
  textColor: string;
  containerFill: string;
  containerStroke: string;
  tcgStroke: string;
  bbStroke: string;
  thStroke: string;
}

type IAttributesRows =
  | {
      label: string;
      subLabel?: string;
      labelClassname?: string;
      converter?: IUnitsConverterFunctions<unknown>;
      transponser?: ITransponserFunctions<unknown>;
      dataAttr: RecursiveKeyOf<IBayRowInfo>;
      placeHolderFn: (row: IRowPattern) => number | undefined;
    } & (
      | ({
          noCommon?: false;
          commonPlaceHolderFn: () => number | undefined;
        } & (
          | {
              commonAttr: RecursiveKeyOf<TCommonBayInfo>;
              bySizeCommonAttr?: never;
              infoByLengthAttr?: never;
            }
          | {
              bySizeCommonAttr?: string;
              commonAttr?: never;
              infoByLengthAttr: RecursiveKeyOf<TRowInfoByLength>;
            }
        ))
      | {
          noCommon: true;
          bySizeCommonAttr?: never;
          commonAttr?: never;
          infoByLengthAttr?: never;
          commonPlaceHolderFn?: never;
        }
    );
