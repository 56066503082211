export class LinkElement extends HTMLElement {
    set text(value) {
        this.setAttribute("text", value);
    }
    get text() {
        return this.getAttribute("text") || "";
    }
    set url(value) {
        this.setAttribute("url", value);
    }
    get url() {
        return this.getAttribute("url") || "";
    }
    constructor() {
        super();
        this.aElement = document.createElement("a");
        if (!LinkElement.onClickLink) {
            console.warn("LinkElement.onClickLink is not set");
        }
    }
    connectedCallback() {
        const a = this.aElement;
        const url = this.url;
        a.innerHTML = this.text;
        a.href = url;
        a.setAttribute("data-url", url);
        a.onclick = (ev) => {
            ev.preventDefault();
            const url = a.getAttribute("data-url");
            if (url && LinkElement.onClickLink)
                LinkElement.onClickLink(url);
            return false;
        };
        this.appendChild(a);
    }
    attributeChangedCallback(attrName) {
        var _a, _b;
        switch (attrName) {
            case "text": {
                this.aElement.innerHTML =
                    ((_a = this.attributes.getNamedItem("text")) === null || _a === void 0 ? void 0 : _a.value) || "";
                break;
            }
            case "url": {
                const url = ((_b = this.attributes.getNamedItem("url")) === null || _b === void 0 ? void 0 : _b.value) || "";
                this.aElement.href = url;
                this.aElement.setAttribute("data-url", url);
                break;
            }
        }
    }
}
LinkElement.observedAttributes = ["text", "url"];
LinkElement.onClickLink = undefined;
customElements.define("link-element", LinkElement);
