import { DivWithSpinner, IntegratedDialogError } from "@tedivo/tedivo-ui";
import {
  IFields,
  IFormReturn,
  TedivoForm,
  translateTedivoForm,
} from "@tedivo/tedivo-form";
import { IFilesCount, IOrganization, TPlan } from "@tedivo/tvd-api-models";
import { getTranslation, i18nReactive } from "../../../app/i18/i18tn";

import { EditDrawer } from "../../common/EditDrawer";
import { I18nComponentRegisterer } from "@tedivo/tedivo-i18";
import ICustomError from "../../types/ICustomError";
import { ORG_PLAN_OPTIONS } from "../../../app/enums/OrgPlanTypeEnum";
import Services from "../../../app/services";
import SlButton from "@shoelace-style/shoelace/dist/components/button/button";
import SlDialog from "@shoelace-style/shoelace/dist/components/dialog/dialog";
import { SmartTable } from "@tedivo/tedivo-smart-table";
import { formatDateInCurrentTimezone } from "@tedivo/tedivo-pure-helpers";
import globalStore from "../../../app/stores/globalStore";
import goSquared from "../../../app/tracking/goSquared";
import { removeChildren } from "@tedivo/tedivo-dom-helpers";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import securityModule from "../../../app/security/SecurityModule";
import { setAppTitle } from "../../../app/setAppTitle";
import topMessageElement from "../../layout/top-tools/getTopMessageElement";
import { z } from "zod";

export class TVDAdminOrgsComponent extends HTMLElement {
  public static observedAttributes = [];

  private i18nCR: I18nComponentRegisterer;
  private dialogError: IntegratedDialogError;
  private wrapper: DivWithSpinner;
  private mainBox: HTMLDivElement;
  private deleteDialog: SlDialog;
  private editDrawer: EditDrawer;

  constructor() {
    super();
    this.i18nCR = new I18nComponentRegisterer(i18nReactive);
    this.dialogError = new IntegratedDialogError(
      this,
      false,
      getTranslation("general:common.close"),
    );
    this.wrapper = document.createElement("div-spinner-element");
    this.mainBox = document.createElement("div");
    this.mainBox.className = "oss-card";
    this.deleteDialog = document.createElement("sl-dialog");
    this.editDrawer = new EditDrawer();

    setAppTitle(getTranslation("general:organizations.organizations"));
  }

  connectedCallback() {
    goSquared.trackPage("TEDIVO Admin Pages - Organizations");
    goSquared.addEvent("Admin/Organizations - Show page");

    const wrapper = this.wrapper,
      h1 = document.createElement("h1");

    wrapper.appendChild(this.mainBox);

    this.appendChild(this.editDrawer.drawer);
    this.appendChild(h1);
    this.appendChild(wrapper);

    this.displayOrganizations();

    this.i18nCR.addConsumer(
      topMessageElement.element,
      "general:adminArea",
      "innerHTML",
    );

    this.i18nCR.addConsumer(
      h1,
      "general:organizations.organizations",
      "innerHTML",
    );
  }

  private displayOrganizations = () => {
    globalStore.touchLastUserInteraction();

    const wrapper = this.wrapper;
    const box = this.mainBox;
    removeChildren(box);

    const actionAddOrgButton = document.createElement("sl-button");
    actionAddOrgButton.innerHTML = getTranslation(
      "general:organizations.adminCreate",
    );
    actionAddOrgButton.variant = "primary";
    actionAddOrgButton.addEventListener(
      "click",
      () => this.openOrganizationEditModal(),
      false,
    );

    wrapper.withLoading(async () => {
      try {
        const resp = await Services.organizations.adminGetAllOrganizations();

        if (resp.data) {
          box.appendChild(
            createOrgsTable(
              resp.data,
              actionAddOrgButton,
              (org: IOrganization) => {
                this.openOrganizationEditModal({ ...org });
              },
              this.deleteOrganization,
            ),
          );
        }
      } catch (e) {
        this.showError(e as ICustomError);
      }
    });
  };

  disconnectedCallback() {
    this.i18nCR.disconnect();
  }

  showError = (e: ICustomError) => {
    this.dialogError.show(
      getTranslation(`errors:errorHasOcurred`),
      getTranslation(typeof e === "string" ? e : e.translationKey),
    );
    console.error(e);
  };

  private openOrganizationEditModal = (prevData?: IOrganization) => {
    const editForm = createOrgEdit(this.editDrawer.submitButton, prevData);

    if (editForm) {
      const drawer = this.editDrawer.getEditDrawer({
        title: prevData
          ? getTranslation("general:organizations.adminEdit")
          : getTranslation("general:organizations.adminCreate"),
        showUnits: false,
        readOnlyMode: false,
        onSave: async () => {
          this.editDrawer.disabled = true;
          const valid = await editForm.submitFunction();
          this.editDrawer.disabled = false;

          if (valid) {
            this.displayOrganizations();
          }

          return valid;
        },
      });
      drawer.appendChild(editForm.node);
      drawer.show();
    }
  };

  private deleteOrganization = (id: string, dta: IOrganization) => {
    if (id === securityModule.currentOrganizationId) return;

    const dialog = this.deleteDialog;
    removeChildren(dialog);

    dialog.label = getTranslation("general:organizations.delete");

    const htmlContent = document.createElement("div");
    htmlContent.innerHTML = `${getTranslation(
      "general:organizations.delete",
    )}<br /><br /><strong>${dta.name}</strong>`;

    const closeBtn = document.createElement("sl-button");
    closeBtn.slot = "footer";
    closeBtn.variant = "danger";
    closeBtn.autofocus = true;
    closeBtn.tabIndex = 0;
    closeBtn.innerHTML = getTranslation("general:common.delete");
    closeBtn.addEventListener(
      "click",
      async () => {
        closeBtn.disabled = true;
        await Services.organizations.adminDeleteOrganization(id);
        dialog.hide();
        this.displayOrganizations();
      },
      false,
    );

    dialog.appendChild(htmlContent);
    dialog.appendChild(closeBtn);
    this.appendChild(dialog);

    dialog.show();
  };
}

customElements.define("tvd-admin-orgs-component", TVDAdminOrgsComponent);

declare global {
  interface HTMLElementTagNameMap {
    "tvd-admin-orgs-component": TVDAdminOrgsComponent;
  }
}

function createOrgsTable(
  organizations: IOrganization[] = [],
  actionBox: HTMLElement | undefined,
  onEditOrganization: (org: IOrganization) => void,
  onDeleteOrganization: (id: string, org: IOrganization) => void,
): HTMLElement {
  const createSortIcon = (asc: boolean) => {
    const icon = document.createElement("sl-icon");
    icon.name = asc ? "sort-down-alt" : "sort-up-alt";
    return icon;
  };

  const tableUsers = new SmartTable<IOrganization>().initialize({
    className: "tvd-table tvd-table-smart-table",
    data: organizations,
    defaultSort: "name",
    fields: [
      {
        name: "name",
        label: getTranslation("general:common.name"),
      },
      {
        name: "planId",
        label: getTranslation("general:organizations.plan"),
        mapper: (org) => getTranslation(`enums:OrgPlanTypeEnum.${org.planId}`),
      },
      {
        name: "enabled",
        label: getTranslation("general:common.status"),
        mapper: (org) =>
          getTranslation(
            `general:common.${org.enabled ? "enabled" : "disabled"}`,
          ),
      },
      {
        name: "usersCount",
        label: getTranslation("general:users.users"),
        className: "centered",
        mapper: (org) => {
          const btnLink = document.createElement("link-button-element");
          btnLink.link = routeFns.adminOrganizationUsers(org.organizationId);
          btnLink.innerHTML = String(org.usersCount || 0);
          btnLink.onClick = router.navigate;
          return btnLink;
        },
      },
      {
        name: "createdAt",
        label: getTranslation("general:common.createdAt"),
        valueFunction: (dta) => formatDateInCurrentTimezone(dta.createdAt),
        className: "centered",
      },
      {
        name: "modifiedAt",
        label: getTranslation("general:common.modifiedAt"),
        valueFunction: (dta) => formatDateInCurrentTimezone(dta.modifiedAt),
        className: "centered",
      },
      {
        name: "organizationId",
        label: getTranslation("general:common.expirationDate"),
        valueFunction: (dta) =>
          formatDateInCurrentTimezone(dta?.expiresAt, true),
        className: "centered",
      },
      {
        name: "organizationId",
        label: getTranslation("general:common.vesselFiles"),
        notSortable: true,
        className: "centered",
        mapper: (org) => {
          const btnLink = document.createElement("link-button-element");
          btnLink.onClick = router.navigate;
          btnLink.state = { organizationName: org.name };
          btnLink.link = routeFns.theirCloud(org.organizationId);
          btnLink.innerHTML = `${org.filesCount?.draft || "-"}/${
            org.filesCount?.private || "-"
          }/${org.filesCount?.community || "-"}`;

          btnLink.buttonElement.variant = "success";
          btnLink.buttonElement.title = createAltTextForFilesState(
            org.filesCount || {},
          );

          return btnLink;
        },
      },
      {
        name: "organizationId",
        label: getTranslation("general:common.edit"),
        type: "icon",
        icon: "pencil",
        notSortable: true,
        onClick: (dta) => {
          onEditOrganization(dta);
        },
      },
      {
        name: "organizationId",
        label: getTranslation("general:common.delete"),
        type: "icon",
        icon: (dta) =>
          dta.organizationId !== securityModule.currentOrganizationId
            ? "trash"
            : "",
        iconVariant: "danger",
        notSortable: true,
        onClick: (dta) => {
          onDeleteOrganization(dta.organizationId, dta);
        },
      },
    ],
    settings: {
      sortEnabled: true,
      sortVisible: true,
      filterEnabled: true,
      labelSortBy: getTranslation("general:common.sortBy"),
      labelSearch: getTranslation("general:common.filterBy"),
      createSortIconFn: createSortIcon,
      filterFields: ["name"],
      actionBox,
    },
    pkFunction: (dta) => dta.organizationId,
  });

  return tableUsers;
}

function createFormValidator(): z.Schema<IOrganizationEdit> {
  const formValidator = z.object({
    name: z.string().min(1),
    enabled: z.boolean(),
    planId: z.string(),
    domains: z.string(),
    freeTrialMax: z.number().min(1),
  });

  return formValidator as unknown as z.Schema<IOrganizationEdit>;
}

function createOrgEdit(
  submitButton: SlButton,
  prevData?: IOrganization,
): IFormReturn<IOrganizationEdit> | null {
  const holder = document.createElement("div");

  const formFields: IFields<IOrganizationEdit> = [
    {
      name: "name",
      label: "general:common.name",
      type: "textBox",
      initialValue: prevData?.name || "",
    },

    {
      name: "planId",
      label: "general:organizations.plan",
      type: "radioButtonList",
      fieldset: true,
      options: ORG_PLAN_OPTIONS.map((f) => ({
        value: f.value,
        label: getTranslation(f.translationLabel),
      })),
      initialValue: prevData?.planId || "FREE",
    },
    {
      name: "freeTrialMax",
      label: "general:organizations.freeTrialMax",
      type: "number",
      initialValue: prevData?.freeTrialMax ?? 3,
      helpText: "Minimum 1",
    },
    {
      name: "domains",
      label: "general:organizations.domains",
      type: "textArea",
      helpText: "general:organizations.domainsHelp",
      initialValue: prevData?.domains?.join("\n") || "",
    },
    [
      {
        name: "expirationDate",
        type: "date",
        label: "general:common.expirationDate",
        initialValue: prevData?.expiresAt,
      },
      {
        name: "enabled",
        label: "general:common.enabled",
        type: "checkbox",
        initialValue: prevData?.enabled ?? true,
        padStart: true,
      },
    ],
  ];

  const tedivoForm = new TedivoForm<IOrganizationEdit>({
    fields: formFields,
    onSubmit: () => undefined,
    formValidator: createFormValidator(),
    submitButton: submitButton,
    formProps: { autoFocusOnFirstInput: true },
    hiddenData: {
      ...prevData,
      domains: prevData?.domains.join(",") || "",
    },
  });

  translateTedivoForm<IOrganizationEdit>({
    tedivoForm,
    getTranslation: getTranslation,
  });

  holder.appendChild(tedivoForm.form);

  return {
    node: holder,
    tedivoForm,
    submitFunction: submitPassedToEditDrawer,
  };

  async function submitPassedToEditDrawer() {
    const validResult = tedivoForm.doSubmitForm();

    if (validResult.success) {
      const values = tedivoForm.getValues();

      const { domains: domainsStr, expirationDate, ...rest } = values;
      const domains = (domainsStr || "")
        .split("\n")
        .map((f) =>
          f.trim().toLowerCase().replace(/^\./, "").replace(/\.$/, ""),
        )
        .filter(Boolean);

      if (prevData?.organizationId) {
        await Services.organizations.adminUpdateOrganization({
          ...prevData,
          ...rest,
          organizationId: prevData.organizationId,
          expiresAt: expirationDate,
          domains,
        });
      } else {
        const updRecord: IOrganization = {
          ...(rest as unknown as IOrganization),
          domains,
          expiresAt: expirationDate,
        };

        await Services.organizations.adminUpdateOrganization(updRecord);
      }
      return true;
    } else {
      return false;
    }
  }
}

interface IOrganizationEdit extends Record<string, unknown> {
  name: string;
  organizationId: string;
  planId: TPlan;
  enabled: boolean;
  domains: string;
  expirationDate: Date;
}

function createAltTextForFilesState(filesCount: Partial<IFilesCount>) {
  return `${getTranslation("enums:FileStateEnum.DRAFT")}: ${
    filesCount?.draft || "0"
  }
${getTranslation("enums:FileStateEnum.ORG_READY")}: ${
    filesCount?.private || "0"
  }
${getTranslation("enums:FileStateEnum.COMMUNITY")}: ${
    filesCount?.community || "0"
  }`;
}
