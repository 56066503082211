/* eslint-disable @typescript-eslint/no-explicit-any */

import { TPlan } from "@tedivo/tvd-api-models";

const goSquared = {
  isActive: false,
  chatIsOpened: false,

  initialize(appId?: string) {
    if (!appId) return;

    goSquared.isActive = true;

    function loadScript(g: any, s: any, q: any, r: any, d?: any) {
      r = g[r] =
        g[r] ||
        function () {
          // eslint-disable-next-line prefer-rest-params
          (r.q = r.q || []).push(arguments as any);
        };
      d = s.createElement(q);
      d.src = "//d1l6p2sc9645hc.cloudfront.net/gosquared.js";
      q = s.getElementsByTagName(q)[0];
      q.parentNode.insertBefore(d, q);
    }

    loadScript(window, document, "script", "_gs");
    window._gs(appId);
    // window._gs("set", "chat", { button: false });
    window._gs("set", "anonymizeIP", true);
    window._gs("set", "cookieDomain", "tedivo.com");
  },

  identify({
    email,
    name,
    organizationName,
    marketingMailsConsent,
    expirationDate,
    organizationPlanId,
  }: {
    email: string;
    name: string;
    organizationName: string;
    marketingMailsConsent?: boolean;
    expirationDate?: string | Date;
    organizationPlanId: TPlan;
  }) {
    if (!window._gs) return;

    const obj = {
      email,
      name,
      company_name: organizationName,
      custom: {
        expiration_date:
          expirationDate === undefined
            ? ""
            : typeof expirationDate === "string"
            ? expirationDate
            : expirationDate?.toISOString(),
        tier: organizationPlanId,
      },
    } as Record<string, unknown>;

    if (marketingMailsConsent !== undefined) {
      obj.unsubscribe = !marketingMailsConsent;
    }

    window._gs("identify", obj);
  },

  unIdentify() {
    if (!window._gs) return;

    window._gs("unidentify");
    window._gs("properties", {});
  },

  toggle() {
    if (!window._gs) return;

    if (!goSquared.chatIsOpened) {
      window._gs("chat", "show");
      goSquared.chatIsOpened = true;
    } else {
      window._gs("chat", "hide");
      goSquared.chatIsOpened = false;
    }
  },

  addEvent(event: string) {
    if (!window._gs) return;
    window._gs("event", event);

    return goSquared;
  },

  trackPage(title: string) {
    if (!window._gs) return;
    const url = new URL(window.location.href);
    const path = url.pathname + url.search;
    window._gs("track", path, title);

    return goSquared;
  },

  trackPageAndEvent(title: string, event: string) {
    goSquared.trackPage(title);
    goSquared.addEvent(event);

    return goSquared;
  },
};

export default goSquared;
