import { CraneSideEnum, VesselPartTypeEnum, } from "open-vessel-definition";
import { calculateSpaceAvailableNext } from "../helpers/calculateSpaceAvailableNext";
import { createModalForBridge } from "./createModalForBridge";
import { createModalForCranes } from "./createModalForCranes";
import { createModalForSmokeStacks } from "./createModalForSmokeStacks";
import { createModalForSpacer } from "./createModalForSpacer";
import { createSimpleAlert } from "@tedivo/tedivo-ui";
import { getTranslation } from "../../../../../../../../../app/i18/i18tn";
/** Launches modals for creation, updation and deletion of parts */
export function launchPartLaunchModal({ partType, original, slotRefId, posRef, currentLabels, calculatedSlots, lcgVcgTcgAndPairings, applyChanges, }) {
    const spaceAvailableCalcFn = (slotRefId, posRef, id) => calculateSpaceAvailableNext({
        slotRefId,
        posRef,
        calculatedSlots,
        lcgVcgTcgAndPairings,
        includeLenOfId: id,
    });
    const spaceAvailable = spaceAvailableCalcFn(slotRefId, posRef, original === null || original === void 0 ? void 0 : original.id);
    if (spaceAvailable !== undefined) {
        if (spaceAvailable <= 0) {
            createSimpleAlert({
                title: getTranslation("general:common.error"),
                message: getTranslation("view:view3D.alerts.noSpaceAvailable"),
                closeLabel: getTranslation("general:common.accept"),
            });
            return;
        }
        if (spaceAvailable < 1000) {
            createSimpleAlert({
                title: getTranslation("general:common.attention"),
                message: getTranslation("view:view3D.alerts.notEnoughSpace"),
                closeLabel: getTranslation("general:common.accept"),
                onOk: () => {
                    launch();
                },
            });
            return;
        }
    }
    launch();
    function launch() {
        var _a;
        switch (partType) {
            case "addBridge":
                createModalForBridge({
                    bridgeData: {
                        id: original === null || original === void 0 ? void 0 : original.id,
                        label: (original === null || original === void 0 ? void 0 : original.label) ||
                            `Bridge ${calculatedSlots.filter((s) => s.type === VesselPartTypeEnum.BRIDGE).length || ""}`.trim(),
                        slotRefId: (original === null || original === void 0 ? void 0 : original.slotRefId) || slotRefId,
                        posRef: (original === null || original === void 0 ? void 0 : original.posRef) || posRef,
                        len: (original === null || original === void 0 ? void 0 : original.len) || 0,
                        heatSrcBelow: (original === null || original === void 0 ? void 0 : original.heatSrcBelow) || false,
                        type: VesselPartTypeEnum.BRIDGE,
                    },
                    currentLabels,
                    calculatedSlots,
                    maxLen: spaceAvailable,
                    defaultLen: 14000,
                    onSubmitAction: applyChanges,
                    spaceAvailableCalcFn,
                });
                break;
            case "addCrane":
                createModalForCranes({
                    craneData: {
                        id: original === null || original === void 0 ? void 0 : original.id,
                        label: (original === null || original === void 0 ? void 0 : original.label) ||
                            `Cranes ${calculatedSlots.filter((s) => s.type === VesselPartTypeEnum.CRANE).length + 1}`,
                        slotRefId: (original === null || original === void 0 ? void 0 : original.slotRefId) || slotRefId,
                        posRef: (original === null || original === void 0 ? void 0 : original.posRef) || posRef,
                        len: (original === null || original === void 0 ? void 0 : original.len) || 0,
                        cranes: (original === null || original === void 0 ? void 0 : original.cranes) || [
                            CraneSideEnum.PORT,
                        ],
                        type: VesselPartTypeEnum.CRANE,
                    },
                    currentLabels,
                    calculatedSlots,
                    maxLen: spaceAvailable,
                    defaultLen: 6096,
                    onSubmitAction: applyChanges,
                    spaceAvailableCalcFn,
                });
                break;
            case "addSmoke":
                createModalForSmokeStacks({
                    smokeStacksData: {
                        id: original === null || original === void 0 ? void 0 : original.id,
                        label: (original === null || original === void 0 ? void 0 : original.label) ||
                            `Smoke Stack ${calculatedSlots.filter((s) => s.type === VesselPartTypeEnum.SMOKE).length || ""}`,
                        slotRefId: (original === null || original === void 0 ? void 0 : original.slotRefId) || slotRefId,
                        posRef: (original === null || original === void 0 ? void 0 : original.posRef) || posRef,
                        len: (original === null || original === void 0 ? void 0 : original.len) || 0,
                        numberOfSmokeStacks: (_a = original === null || original === void 0 ? void 0 : original.numberOfSmokeStacks) !== null && _a !== void 0 ? _a : 1,
                        type: VesselPartTypeEnum.SMOKE,
                    },
                    currentLabels,
                    calculatedSlots,
                    maxLen: spaceAvailable,
                    defaultLen: 6096,
                    onSubmitAction: applyChanges,
                    spaceAvailableCalcFn,
                });
                break;
            case "addSpacer":
                createModalForSpacer({
                    spacerData: {
                        id: original === null || original === void 0 ? void 0 : original.id,
                        label: (original === null || original === void 0 ? void 0 : original.label) ||
                            `Gap ${calculatedSlots.filter((s) => s.type === VesselPartTypeEnum.SPACER).length + 1}`,
                        slotRefId: (original === null || original === void 0 ? void 0 : original.slotRefId) || slotRefId,
                        posRef: (original === null || original === void 0 ? void 0 : original.posRef) || posRef,
                        len: (original === null || original === void 0 ? void 0 : original.len) || 0,
                        heatSrcBelow: (original === null || original === void 0 ? void 0 : original.heatSrcBelow) || false,
                        type: VesselPartTypeEnum.SPACER,
                    },
                    currentLabels,
                    calculatedSlots,
                    maxLen: spaceAvailable,
                    defaultLen: 6096,
                    onSubmitAction: applyChanges,
                    spaceAvailableCalcFn,
                });
                break;
        }
    }
}
