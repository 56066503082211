import "./tvd-top-tools.component.scss";

import { getTranslation, i18n, i18nReactive } from "../../../app/i18/i18tn";

import { I18nComponentRegisterer } from "@tedivo/tedivo-i18";
import { IntegratedDialog } from "@tedivo/tedivo-ui";
import { ScrollModeEnum } from "../../../app/units/units-scroller-element";
import awsCognito from "../../../app/security/awsCognito";
import securityModule from "../../../app/security/SecurityModule";

const MAX_TIME_TO_EXPIRE = 1000 * 60 * 60 * 24 * 7;

export class OssTopToolsElement extends HTMLElement {
  public static observedAttributes = [];

  private i18nCR: I18nComponentRegisterer;

  private wrapper: HTMLDivElement = document.createElement("div");
  private accountExpiredNode: HTMLElement | undefined = undefined;
  private accounAboutToExpiredNode: HTMLElement | undefined = undefined;

  constructor() {
    super();

    this.i18nCR = new I18nComponentRegisterer(i18nReactive);

    awsCognito.addEventListener(
      "authenticationChanged",
      this.showHideLoggedInOptions,
    );
  }

  connectedCallback() {
    const wrapper = document.createElement("div"),
      initialSlot = document.createElement("div"),
      accountExpired = document.createElement("sl-badge"),
      accounAboutToExpired = document.createElement("sl-badge"),
      topTitle = document.createElement("span"),
      languageSelect = document.createElement("tvd-language-sel-element"),
      colorModeSelect = document.createElement("tvd-color-mode-element"),
      optionsCogBtn = document.createElement("tvd-user-options-component"),
      notificationsBtn = document.createElement("tvd-notifications-component");

    const unitsScroller = document.createElement("units-scroller-element");
    unitsScroller.align = "right";
    unitsScroller.doScroll = ScrollModeEnum.AUTO;

    wrapper.className = "oss-top-tools";
    this.wrapper = wrapper;

    initialSlot.className = "oss-top-message";

    initialSlot.appendChild(accountExpired);
    initialSlot.appendChild(accounAboutToExpired);
    initialSlot.appendChild(topTitle);

    accountExpired.className = "account-expired";
    accountExpired.id = "oss-account-expired";
    accountExpired.variant = "danger";
    accountExpired.pulse = true;
    accountExpired.innerHTML = getTranslation(
      "general:organizations.planIsExpired",
    );
    this.accountExpiredNode = accountExpired;

    accounAboutToExpired.className = "account-expired";
    accounAboutToExpired.id = "oss-account-about-to-expire";
    accounAboutToExpired.variant = "warning";
    accounAboutToExpired.pulse = true;
    this.accounAboutToExpiredNode = accounAboutToExpired;

    topTitle.className = "top-title";
    topTitle.id = "oss-top-message";

    colorModeSelect.id = "color-mode-select";

    wrapper.appendChild(initialSlot);
    wrapper.appendChild(unitsScroller);
    wrapper.appendChild(optionsCogBtn);
    wrapper.appendChild(notificationsBtn);
    wrapper.appendChild(languageSelect);
    wrapper.appendChild(colorModeSelect);
    this.appendChild(wrapper);

    document.documentElement.addEventListener("globalUnitsChanged", () => {
      unitsScroller.update();
    });

    globalThis.requestAnimationFrame(() => {
      this.showHideLoggedInOptions();
      this.checkAccountExpiration();
    });

    this.i18nCR.addConsumer(
      accounAboutToExpired,
      "general:organizations.planIsAboutToExpire",
      "innerHTML",
      () => {
        const rtf1 = new Intl.RelativeTimeFormat(i18n.language, {
          style: "short",
        });
        return {
          time: rtf1.format(
            Math.round(
              (securityModule.planExpirationTimeLeft || 0) /
                1000 /
                60 /
                60 /
                24,
            ),
            "days",
          ),
        };
      },
    );

    this.attachEvents();
  }

  private attachEvents() {
    this.accountExpiredNode?.addEventListener(
      "click",
      this.showDialogWithInstructionsToRenewPlan,
    );
    this.accounAboutToExpiredNode?.addEventListener(
      "click",
      this.showDialogWithInstructionsToRenewPlan,
    );
  }

  private showHideLoggedInOptions = () => {
    // In the SCSS file we have a rule that hides the some elements when the user is not logged in.
    this.wrapper.dataset.loggedIn = securityModule.isLoggedIn
      ? "true"
      : "false";
  };

  private checkAccountExpiration = () => {
    const accountExpiredNode = this.accountExpiredNode;
    const accounAboutToExpiredNode = this.accounAboutToExpiredNode;

    if (!accountExpiredNode || !accounAboutToExpiredNode) return;

    const timeToExpirePlan = securityModule.planExpirationTimeLeft;

    if (timeToExpirePlan === undefined && !securityModule.planIsExpired) {
      accountExpiredNode.dataset.expired = "false";
      accounAboutToExpiredNode.dataset.expired = "false";
      return;
    }

    if (securityModule.planIsExpired) {
      accountExpiredNode.dataset.expired = "true";
    } else if (
      timeToExpirePlan !== undefined &&
      timeToExpirePlan < MAX_TIME_TO_EXPIRE
    ) {
      accounAboutToExpiredNode.dataset.expired = "true";
    }
  };

  private showDialogWithInstructionsToRenewPlan = () => {
    const dialog = new IntegratedDialog({
      parentNode: this,
      removeDialogWhenHidden: true,
      buttonsAttrs: [
        {
          label: getTranslation("general:common.ok"),
          value: "ok",
          type: "submit",
        },
      ],
    });

    const options = {
      time: (() => {
        if (securityModule.planIsExpired) return "";

        const rtf1 = new Intl.RelativeTimeFormat(i18n.language, {
          style: "short",
        });

        return rtf1.format(
          Math.round(
            (securityModule.planExpirationTimeLeft || 0) / 1000 / 60 / 60 / 24,
          ),
          "days",
        );
      })(),
    };

    dialog.show(
      getTranslation(
        securityModule.planIsExpired
          ? "general:organizations.planIsExpired"
          : "general:organizations.planIsAboutToExpire",
        options,
      ),
      getTranslation("general:organizations.renewPlanInstructions"),
    );
  };
}

customElements.define("tvd-top-tools-component", OssTopToolsElement);
