import { __rest } from "tslib";
import { createSelectShoelace } from "@tedivo/tedivo-form";
import { calculateTextWidth, findParentBy, removeChildren, } from "@tedivo/tedivo-dom-helpers";
import { IntegratedDialog } from "@tedivo/tedivo-ui";
import { buildObserverWithCallback } from "./helpers/buildObserverWithCallback";
import { createDictionary } from "@tedivo/tedivo-pure-helpers";
import { sortByFields } from "./helpers/sortByFields";
const DEFAULT_ROWS_VISIBLE = 40;
/** Web component to create a Table */
export class SmartTable extends HTMLElement {
    constructor() {
        super();
        this.rowsByPk = {};
        this.selection = [];
        this.sortedBy = undefined;
        this.filterText = "";
        this._columnsVisible = [];
        this._tableWidths = [];
        this._rendered = false;
        this._onlyThesePks = [];
        this._tBodyVisible = true;
        this._dialog = undefined;
        this._pksSelected = new Set();
        this._rowsCheckboxes = [];
        this._globalCheckbox = undefined;
        this.attachEvents = () => {
            const options = this.options, tbody = this.nodes.tbody;
            if (options.settings.draggable) {
                const addDraggability = () => {
                    let startIndex = -1;
                    const mouseSelectMove = (ev) => {
                        ev.preventDefault();
                        const target = ev.target;
                        if (!target)
                            return;
                        const tr = findParentBy(target, "TR");
                        if (!tr)
                            return;
                        const currentIndex = Number(tr.dataset["index"]);
                        this.selectRows({ startIndex, endIndex: currentIndex });
                    };
                    const mouseSelectStart = (ev) => {
                        if (ev.altKey || ev.ctrlKey || ev.shiftKey)
                            return;
                        ev.preventDefault();
                        const target = ev.target;
                        if (!target)
                            return;
                        const tr = findParentBy(target, "TR");
                        if (!tr)
                            return;
                        startIndex = Number(tr.dataset["index"]);
                        tbody.addEventListener("mousemove", mouseSelectMove, false);
                    };
                    const mouseSelectEnd = (ev) => {
                        if (ev.altKey || ev.ctrlKey || ev.shiftKey)
                            return;
                        ev.preventDefault();
                        tbody.removeEventListener("mousemove", mouseSelectMove);
                        if (options.onSelection)
                            options.onSelection(this.selection.map((s) => s.pk));
                    };
                    tbody.addEventListener("mousedown", mouseSelectStart, false);
                    tbody.addEventListener("mouseup", mouseSelectEnd, false);
                };
                addDraggability();
            }
            const optionsOnClickFn = options.onClick;
            if (optionsOnClickFn !== undefined)
                tbody.addEventListener("click", (ev) => {
                    const target = ev.target;
                    if (!target)
                        return;
                    const tr = findParentBy(target, "TR");
                    if (!tr)
                        return;
                    const pk = tr.dataset["pk"];
                    const index = Number(tr.dataset["index"]);
                    if (isNaN(index) || !pk)
                        return;
                    optionsOnClickFn(pk, index, target);
                    if (options.settings.autoSelectOnClick) {
                        this.selectRows({ pk });
                    }
                }, false);
        };
        this.onSort = (ev) => {
            var _a, _b;
            const target = ev.target, name = target.dataset["name"];
            if (this.sortedBy) {
                const fieldIsSortedIndex = this.sortedBy.findIndex((s) => s.name === name);
                if (fieldIsSortedIndex >= 0) {
                    const fieldSorted = this.sortedBy[fieldIsSortedIndex];
                    fieldSorted.ascending = !fieldSorted.ascending;
                }
                else if (this.options.settings.sortEnabled) {
                    this.sortedBy = [
                        {
                            name,
                            ascending: true,
                            isNumeric: ((_a = this.fieldsByName.get(name)) === null || _a === void 0 ? void 0 : _a.type) === "number" || false,
                        },
                    ];
                }
            }
            else {
                if (!this.options.settings.sortEnabled)
                    return;
                this.sortedBy = [
                    {
                        name,
                        ascending: true,
                        isNumeric: ((_b = this.fieldsByName.get(name)) === null || _b === void 0 ? void 0 : _b.type) === "number" || false,
                    },
                ];
            }
            if (this.options.onSortFunction) {
                this.options.onSortFunction(this.sortedBy, this);
            }
            else {
                this.updateData();
            }
            if (this.options.onSortParamsChanged) {
                this.options.onSortParamsChanged(this.sortedBy);
            }
            !this.nodes.table.classList.contains("just-sorted") &&
                this.nodes.table.classList.add("just-sorted");
            window.setTimeout(() => {
                this.nodes.table.classList.remove("just-sorted");
            }, 500);
        };
        this.onSearchPaste = (ev) => {
            if (!ev.clipboardData)
                return;
            const text = ev.clipboardData.getData("text");
            this.applySearch(text.toUpperCase());
        };
        this.onSearch = (ev) => {
            const target = ev.target, value = target.value.toUpperCase();
            if (ev.code === "Escape") {
                this.clearSearch();
            }
            else {
                this.applySearch(value);
            }
        };
        this.setParentHeight = (tablePassed) => {
            const settings = this.options.settings, table = tablePassed || this.nodes.table, parentTable = table.parentElement;
            if (!settings.fixHeightOfParentElement || !parentTable) {
                return;
            }
            setTimeout(() => {
                const bbox = table.getBoundingClientRect();
                parentTable.style.minHeight = `${Math.ceil(bbox.height)}px`;
            }, 50);
        };
        /**
         * Clear the filter input box and update visibility
         */
        this.clearSearch = () => {
            if (this._searchInput) {
                this._searchInput.value = "";
                this._searchInput.dispatchEvent(new Event("keyup"));
            }
            if (this.options.onFilterTextChange) {
                this.options.onFilterTextChange("");
            }
            return this._searchInput;
        };
        this.sortedData = [];
        this.effectiveData = [];
        this.fieldsByName = {};
        this.rows = [];
        this.options = {};
        this.nodes = {};
    }
    /**
     * Creates a sortable/filterable table
     * @example {
     *  className: "extra",
     *  tableNode: HTMLTableElement,
     *  title: "Table title",
     *  fields: Array<IField<T>>,
     *  defaultSort: "containerID",
     *  data: Array<T>,
     *  settings: {
     *    filterEnabled: true | false, // allow filtering
     *    sortEnabled: true | false, // allow column sorting
     *    draggable: true | false, // allow selection by dragging
     *    autoSelectOnClick: true | false, // auto select row on click
     *    preserveWidthOnUpdate: true | false, // keep column widths after updates
     *    sortByLabel: "Sort by", // label
     *    searchLabel: "Filter by", // label
     *  },
     *  pkFunction: (dta: T) => string;
     *  onSortFunction?: (key: keyof T, ascending: boolean) => void;
     *  onClick?: (pk: string, index: number, target: HTMLElement) => void;
     * }
     */
    initialize(options) {
        const resolvedOptions = options;
        this.options = resolvedOptions;
        let data;
        this.fieldsByName = resolvedOptions.fields.reduce((fbn, f) => (f ? fbn.set(f.name, f) : fbn), new Map());
        if (resolvedOptions.defaultSort) {
            const sortFields = Array.isArray(resolvedOptions.defaultSort)
                ? resolvedOptions.defaultSort
                : [resolvedOptions.defaultSort];
            const sortDirections = Array.isArray(resolvedOptions.defaultSortAsc)
                ? resolvedOptions.defaultSortAsc
                : [resolvedOptions.defaultSortAsc];
            this.sortedBy = sortFields.map((fieldName, idx) => {
                var _a;
                const fieldProps = this.fieldsByName.get(fieldName);
                return {
                    name: fieldName,
                    ascending: (_a = sortDirections[idx]) !== null && _a !== void 0 ? _a : true,
                    isNumeric: (fieldProps === null || fieldProps === void 0 ? void 0 : fieldProps.type) === "number" ||
                        (fieldProps === null || fieldProps === void 0 ? void 0 : fieldProps.editType) === "number" ||
                        (fieldProps === null || fieldProps === void 0 ? void 0 : fieldProps.type) ===
                            "inlineEditPadded" ||
                        (fieldProps === null || fieldProps === void 0 ? void 0 : fieldProps.isNumeric) ||
                        false,
                };
            });
            data = resolvedOptions.data.slice().sort(sortByFields(this.sortedBy));
        }
        else {
            data = resolvedOptions.data.slice();
        }
        this.sortedData = data;
        this.effectiveData = data;
        this.rows = new Array(data.length);
        this.rowsByPk = {};
        if (resolvedOptions.initialFilter) {
            this.filterText = resolvedOptions.initialFilter;
            resolvedOptions.initialFilter = undefined;
        }
        return this;
    }
    connectedCallback() {
        this.nodes = this._buildHtml(this.options, false);
        this.attachEvents();
        this.appendChild(this.nodes.table);
        this._rendered = true;
        if (this.options.settings.columnSelector) {
            this._dialog = new IntegratedDialog({
                parentNode: this,
                buttonsAttrs: [
                    {
                        value: "ok",
                        label: this.options.settings.columnSelectorButtonText,
                        variant: "primary",
                        type: "submit",
                    },
                ],
            });
        }
    }
    get sortEnabled() {
        return this.options.settings.sortEnabled;
    }
    get filterEnabled() {
        return this.options.settings.filterEnabled;
    }
    get rowsNumber() {
        return this.rows.filter(Boolean).length || 0;
    }
    changeSettings(settings) {
        this.options.settings = Object.assign(this.options.settings, settings);
        this.updateData();
    }
    changeFields(fields) {
        this.rows = new Array(this.options.data.length);
        this.rowsByPk = {};
        this.options.fields = fields;
        this.updateData();
    }
    get tBodyVisible() {
        return this._tBodyVisible;
    }
    set tBodyVisible(v) {
        this._tBodyVisible = v;
        if (!this.nodes)
            return;
        if (v) {
            this.nodes.tbody.style.visibility = "";
        }
        else {
            this.nodes.tbody.style.visibility = "hidden";
        }
    }
    get restrictedToPks() {
        return this._onlyThesePks;
    }
    set restrictedToPks(pks) {
        this._onlyThesePks = pks;
        if (this._rendered)
            this.updateData();
    }
    get columnsVisible() {
        return this._columnsVisible;
    }
    set columnsVisible(cols) {
        this._columnsVisible = cols;
        if (this._rendered)
            this.updateData();
    }
    _getFieldsVisible(options) {
        const fields = options.fields.filter((f) => Boolean(f) &&
            (this._columnsVisible.length === 0 ||
                this._columnsVisible.some((c) => c === (f === null || f === void 0 ? void 0 : f.name))));
        const fieldsByName = createDictionary(fields, (f) => f.name, true);
        return { fields, fieldsByName };
    }
    get pksSelected() {
        return Array.from(this._pksSelected);
    }
    set pksSelected(pks) {
        var _a;
        if (!this.options.settings.rowAddCheckboxes)
            return;
        this._pksSelected = new Set(pks);
        this.selectRows({ pks });
        this._rowsCheckboxes.forEach((c) => {
            c.checked = pks.includes(c.value);
        });
        (_a = this._rowsCheckboxes[0]) === null || _a === void 0 ? void 0 : _a.dispatchEvent(new CustomEvent("sl-change"));
    }
    _buildHtml(options, dataWasUpdated) {
        var _a, _b, _c, _d, _e, _f;
        const { fields, fieldsByName } = this._getFieldsVisible(options);
        const nodes = this.nodes;
        const sortEnabled = options.settings.sortEnabled;
        const sortVisible = options.settings.sortVisible;
        const docFrag = document.createDocumentFragment();
        const table = (nodes && nodes.table) ||
            options.tableNode ||
            document.createElement("table");
        const thead = document.createElement("thead");
        const tbody = (nodes && nodes.tbody) || document.createElement("tbody");
        removeChildren(table);
        table.className = `smart-table ${sortEnabled ? "sort-enabled" : ""} ${sortVisible ? "sort-visible" : ""} ${options.className || ""}`;
        // Table Header
        const trHead = document.createElement("tr");
        // First column for selection?
        if (options.settings.rowAddCheckboxes) {
            this._rowsCheckboxes = [];
            const th = document.createElement("th");
            th.style.width = "70px";
            th.className = "td-smt-selector z-index-10000";
            const chk = document.createElement("sl-checkbox");
            this._globalCheckbox = chk;
            chk.addEventListener("sl-change", (ev) => {
                var _a, _b;
                const target = ev.target;
                const checked = target.checked;
                this._rowsCheckboxes.forEach((c) => {
                    c.checked = checked;
                    c.dispatchEvent(new CustomEvent("sl-change"));
                });
                if (checked) {
                    this._rowsCheckboxes
                        .map((c) => c.value)
                        .forEach((v) => {
                        this._pksSelected.add(v);
                    });
                }
                else {
                    this._pksSelected.clear();
                }
                chk.checked = checked;
                chk.indeterminate = false;
                (_b = (_a = this.options.settings).rowOnCheckedChange) === null || _b === void 0 ? void 0 : _b.call(_a, this.pksSelected);
            });
            const onClickAction = (action) => {
                const pksSelected = Array.from(this._pksSelected);
                if (options.settings.rowOnClickAction) {
                    options.settings.rowOnClickAction(action, pksSelected);
                }
            };
            const rowsActionsSelector = createSelectShoelace({
                id: "node-actions",
                options: options.settings.rowActions,
                buttonText: "",
                iconPrefix: "command",
                variant: "primary",
                size: "small",
                textAlignment: "left",
                ommitCheckSign: true,
                placement: "bottom-start",
                onChange: onClickAction,
            });
            rowsActionsSelector.disabled = true;
            this._globalSelectedActionButton = rowsActionsSelector;
            th.appendChild(chk);
            th.appendChild(rowsActionsSelector);
            trHead.appendChild(th);
        }
        const ths = fields.filter(Boolean).map(({ shortLabel, label, name, notSortable, className: classNamePrev, fixedWidth, smallPadding, createSortIconFn, }) => {
            const th = document.createElement("th");
            const className = classNamePrev
                ? typeof classNamePrev === "function"
                    ? classNamePrev()
                    : classNamePrev
                : "";
            th.className = `${className || ""}`;
            if (fixedWidth)
                th.style.width = fixedWidth;
            if (smallPadding)
                th.classList.add("small-padding");
            // Sort buttons
            if ((sortEnabled || sortVisible) && !notSortable) {
                const btnSort = document.createElement("button");
                // Set className
                if (this.sortedBy) {
                    const fieldIsSortedIndex = this.sortedBy.findIndex((s) => s.name === name);
                    if (fieldIsSortedIndex >= 0) {
                        const fieldSorted = this.sortedBy[fieldIsSortedIndex];
                        btnSort.className = `sort-by sorted-by-${fieldSorted.ascending ? "asc" : "desc"} ${fieldIsSortedIndex > 0 ? "sort-by-secondary" : ""}`;
                    }
                    else {
                        btnSort.className = sortEnabled ? "sort-by" : "no-sort-by";
                    }
                }
                else {
                    btnSort.className = sortEnabled ? "sort-by" : "no-sort-by";
                }
                const sortByLabel = options.settings.labelSortBy || "Order by";
                btnSort.innerHTML = shortLabel || label;
                btnSort.dataset["name"] = String(name);
                btnSort.setAttribute("title", `${sortByLabel} ${label}`);
                if (this.sortedBy && options.settings.createSortIconFn) {
                    const effectiveIconFn = createSortIconFn || options.settings.createSortIconFn;
                    if (effectiveIconFn) {
                        const sortIcons = document.createElement("span");
                        sortIcons.className = "sort-icons";
                        const iconAsc = document.createElement("span");
                        iconAsc.className = "icon-asc-holder";
                        iconAsc.appendChild(effectiveIconFn(true));
                        const iconDesc = document.createElement("span");
                        iconDesc.className = "icon-desc-holder";
                        iconDesc.appendChild(effectiveIconFn(false));
                        sortIcons.appendChild(iconAsc);
                        sortIcons.appendChild(iconDesc);
                        btnSort.appendChild(sortIcons);
                    }
                }
                th.appendChild(btnSort);
                btnSort.addEventListener("click", this.onSort, false);
            }
            else {
                th.innerHTML = shortLabel || label;
            }
            th.dataset["name"] = String(name);
            trHead.appendChild(th);
            return th;
        });
        thead.appendChild(trHead);
        // Table Body
        this.selectRows({ clearSelection: true });
        this.rows = new Array(this.options.data.length);
        this.updateEffectiveDataWithSearch(this.filterText, true); // Hide depending on previous search
        const initialRows = options.initialRows || DEFAULT_ROWS_VISIBLE;
        removeChildren(tbody); // This node is kept. Clean it before.
        this.renderGroupFromToIndex(0, initialRows, tbody, dataWasUpdated);
        // Add all to docFrag
        if (options.title ||
            ((_a = options.settings) === null || _a === void 0 ? void 0 : _a.filterEnabled) ||
            ((_b = options.settings) === null || _b === void 0 ? void 0 : _b.actionBox) ||
            ((_c = options.settings) === null || _c === void 0 ? void 0 : _c.columnSelector)) {
            const caption = document.createElement("caption"), captionContent = document.createElement("div"), headerPseudoSlot = document.createElement("div");
            captionContent.className = "smart-table-caption-content";
            caption.appendChild(captionContent);
            if (options.title) {
                const h2 = document.createElement("h2");
                h2.innerHTML = options.title;
                captionContent.appendChild(h2);
            }
            const spacer = document.createElement("span");
            spacer.className = "spacer";
            captionContent.appendChild(spacer);
            // Header pseudo slot (at the left of the actions box)
            this._headerPseudoSlot = headerPseudoSlot;
            headerPseudoSlot.className = "header-pseudo-slot";
            if (this._headerPseudoSlotContent)
                headerPseudoSlot.appendChild(this._headerPseudoSlotContent);
            captionContent.appendChild(headerPseudoSlot);
            if ((_d = options.settings) === null || _d === void 0 ? void 0 : _d.filterEnabled) {
                const searchBoxHolder = document.createElement("div"), searchBox = document.createElement("div"), searchInput = document.createElement("sl-input"), searchResults = document.createElement("span");
                const searchLabel = options.settings.labelSearch || "Filter by ...";
                const searchTooltip = document.createElement("sl-tooltip");
                searchTooltip.content =
                    searchLabel +
                        (this.options.settings.filterFields
                            ? ` (${this.options.settings.filterFields
                                .filter((n) => !!fieldsByName[n])
                                .map((n) => fieldsByName[n].label)
                                .join(", ")})`
                            : "");
                searchBoxHolder.className = "smart-table-search";
                searchBoxHolder.appendChild(searchBox);
                searchBox.className = "smart-table-search-box";
                searchInput.placeholder = searchLabel;
                searchInput.value = this.filterText || "";
                searchInput.clearable = true;
                searchInput.filled = true;
                searchResults.className = "smart-table-search-results";
                const searchIcon = document.createElement("sl-icon");
                searchIcon.name = "funnel";
                searchIcon.slot = "prefix";
                searchInput.appendChild(searchIcon);
                searchTooltip.appendChild(searchInput);
                searchBox.appendChild(searchTooltip);
                searchBoxHolder.appendChild(searchResults);
                searchInput.addEventListener("keyup", this.onSearch, false);
                searchInput.addEventListener("paste", this.onSearchPaste, false);
                searchInput.addEventListener("sl-clear", this.clearSearch, false);
                this._searchInput = searchInput;
                this._searchResults = searchResults;
                captionContent.appendChild(searchBoxHolder);
            }
            if ((_e = options.settings) === null || _e === void 0 ? void 0 : _e.columnSelector) {
                const btn = document.createElement("sl-button");
                btn.className = "smart-table-column-selector";
                btn.variant = "primary";
                btn.outline = true;
                const icon = document.createElement("sl-icon");
                icon.name = "layout-three-columns";
                icon.slot = "prefix";
                btn.appendChild(icon);
                const tooltip = document.createElement("sl-tooltip");
                tooltip.content = options.settings.columnSelectorHelp || "Columns";
                tooltip.appendChild(btn);
                btn.addEventListener("click", () => {
                    if (!this._dialog)
                        return;
                    const allFields = options.fields.filter(Boolean);
                    const columnsFixed = options.settings.columnSelectorFixed || [];
                    const div = document.createElement("div");
                    div.className = "modal-column-selector";
                    allFields.forEach((f) => {
                        const chkbox = document.createElement("sl-checkbox");
                        chkbox.innerHTML = f.label;
                        chkbox.value = f.name;
                        if (columnsFixed.includes(f.name)) {
                            chkbox.disabled = true;
                            chkbox.checked = true;
                        }
                        else {
                            chkbox.checked =
                                this._columnsVisible.length === 0 ||
                                    this._columnsVisible.includes(f.name);
                        }
                        div.appendChild(chkbox);
                    });
                    this._dialog.show(options.settings.columnSelectorHelp || "Columns", div);
                    this._dialog.onButtonClicked = (btnClicked) => {
                        if (btnClicked === "ok") {
                            const checkboxes = div.querySelectorAll("sl-checkbox");
                            const columnsVisible = Array.from(checkboxes)
                                .filter((c) => c.checked)
                                .map((c) => c.value);
                            this.columnsVisible = columnsVisible;
                            if (options.settings.onColumnSelectorChange) {
                                options.settings.onColumnSelectorChange(columnsVisible);
                            }
                        }
                    };
                });
                captionContent.appendChild(tooltip);
            }
            if ((_f = options.settings) === null || _f === void 0 ? void 0 : _f.actionBox) {
                captionContent.appendChild(options.settings.actionBox);
            }
            docFrag.appendChild(caption);
        }
        docFrag.appendChild(thead);
        docFrag.appendChild(tbody);
        // Finally append to table
        table.appendChild(docFrag);
        // Save widths (to prevent re-flow)?
        if (options.settings.preserveWidthOnUpdate) {
            let totalWidth = 0;
            const tableWidths = this._tableWidths;
            if (tableWidths.length === 0) {
                // Headers
                fields.forEach(({ label, shortLabel }, idx) => {
                    const w = Math.ceil(calculateTextWidth(shortLabel || label, "15px"));
                    tableWidths[idx] = { minWidth: w + 24, percentage: 0 };
                });
                // First rows (10)
                for (let r = 0; r < Math.min(10, this.effectiveData.length); r += 1) {
                    const dta = this.effectiveData[r];
                    fields.forEach(({ name, valueFunction }, idx) => {
                        const value = valueFunction
                            ? valueFunction(dta)
                            : String(dta[name]);
                        const w = Math.ceil(calculateTextWidth(value, "15px"));
                        if (tableWidths[idx].minWidth < w) {
                            tableWidths[idx].minWidth = w;
                        }
                    });
                }
                totalWidth = tableWidths.reduce((prev, curr) => (prev += curr.minWidth), 0);
                // Calculate percentages
                for (let j = 0; j < tableWidths.length; j += 1) {
                    tableWidths[j].percentage =
                        Math.round((tableWidths[j].minWidth / totalWidth) * 1000) / 10;
                }
            }
            for (let j = 0; j < ths.length; j += 1) {
                if (tableWidths[j]) {
                    ths[j].style.width = `${tableWidths[j].percentage}%`;
                    ths[j].style.minWidth = `${tableWidths[j].minWidth}px`;
                }
            }
        }
        // Set min-height of parent to prevent re-flow
        this.setParentHeight(table);
        // Counter
        this.updateSearchCounter();
        return {
            table,
            tbody,
        };
    }
    renderGroupFromToIndex(from, to, parent, dataWasUpdated) {
        const data = this.effectiveData;
        const dataLenght = data.length;
        const effectiveTo = Math.min(to, dataLenght);
        // Create
        const trs = data
            .filter((_, idx) => idx >= from && idx < effectiveTo)
            .map((dta, index) => this.renderRow(dta, index + from, dataWasUpdated, this.options.settings.rowAddCheckboxes || false));
        // Append
        const docFrag = document.createDocumentFragment();
        trs.forEach((tr) => {
            if (tr !== null) {
                docFrag.appendChild(tr);
            }
        });
        parent.appendChild(docFrag);
        // Add observer to last to auto-load next rows
        const lastTr = trs.length ? trs[trs.length - 1] : undefined;
        if (lastTr) {
            const ioObserver = buildObserverWithCallback((newIndex) => {
                this.renderGroupFromToIndex(newIndex, newIndex + DEFAULT_ROWS_VISIBLE, parent, dataWasUpdated);
                this.setParentHeight();
            });
            ioObserver.observe(lastTr);
        }
    }
    renderRow(dta, index, dataWasUpdated, rowAddCheckboxes) {
        var _a, _b;
        const options = this.options;
        if (dta === undefined || dta === null)
            return null;
        const pk = options.pkFunction(dta);
        const className = ((_b = (_a = this.options).rowClassFunction) === null || _b === void 0 ? void 0 : _b.call(_a, dta)) || undefined;
        if (pk === undefined)
            return null;
        const rowsByPk = this.rowsByPk, rowExists = rowsByPk[pk];
        // If row has been generated already
        if (rowExists && !dataWasUpdated) {
            rowExists.index = index;
            rowExists.row.dataset["index"] = String(index);
            this.rows[index] = rowExists;
            if (className && !rowExists.row.classList.contains(className))
                rowExists.row.classList.add(className);
            return rowExists.row;
        }
        const { fields } = this._getFieldsVisible(options);
        const tr = document.createElement("tr");
        if (className)
            tr.classList.add(className);
        if (rowAddCheckboxes) {
            const td = document.createElement("td");
            td.className = "td-smt-selector";
            const chk = document.createElement("sl-checkbox");
            chk.value = pk;
            if (this._pksSelected.has(pk))
                chk.checked = true;
            this._rowsCheckboxes.push(chk);
            chk.addEventListener("sl-change", (ev) => {
                var _a, _b;
                const target = ev.target;
                if (target.checked) {
                    this._pksSelected.add(pk);
                }
                else {
                    this._pksSelected.delete(pk);
                }
                if (this._globalCheckbox) {
                    this._globalCheckbox.indeterminate = true;
                }
                if (this._globalSelectedActionButton) {
                    this._globalSelectedActionButton.disabled =
                        this._pksSelected.size === 0;
                }
                this.selectRows({ pks: Array.from(this._pksSelected) });
                (_b = (_a = this.options.settings).rowOnCheckedChange) === null || _b === void 0 ? void 0 : _b.call(_a, this.pksSelected);
            });
            td.appendChild(chk);
            tr.appendChild(td);
        }
        tr.dataset["index"] = String(index);
        if (pk)
            tr.dataset["pk"] = pk;
        fields.forEach((_a) => {
            var _b;
            var { name, className: classNamePrev, valueFunction, mapper, type, icon, iconVariant, onClick, options, label, smallPadding, spanColumns, fixedWidth } = _a, rest = __rest(_a, ["name", "className", "valueFunction", "mapper", "type", "icon", "iconVariant", "onClick", "options", "label", "smallPadding", "spanColumns", "fixedWidth"]);
            const className = classNamePrev
                ? typeof classNamePrev === "function"
                    ? classNamePrev(dta)
                    : classNamePrev
                : "";
            const td = document.createElement("td");
            td.className = `td-smt-${name} ${className || ""}`;
            const colSpan = spanColumns ? spanColumns(dta) : 1;
            if (colSpan !== undefined && colSpan !== 1) {
                if (colSpan === 0) {
                    return;
                }
                td.colSpan = colSpan;
            }
            const value = valueFunction ? valueFunction(dta) : String(dta[name]);
            switch (type) {
                case "icon":
                    {
                        const iconStr = typeof icon === "function" ? icon(dta) : icon;
                        if (iconStr !== "") {
                            const iconBtn = document.createElement("sl-button");
                            iconBtn.size = "small";
                            iconBtn.variant = iconVariant || "primary";
                            iconBtn.outline = true;
                            const iconEl = document.createElement("sl-icon");
                            iconEl.name = iconStr;
                            iconBtn.appendChild(iconEl);
                            const isDisabled = ((_b = rest === null || rest === void 0 ? void 0 : rest.disabled) === null || _b === void 0 ? void 0 : _b.call(rest, dta)) || false;
                            if (isDisabled) {
                                iconBtn.disabled = true;
                            }
                            else {
                                iconBtn.onclick = () => {
                                    onClick(dta, name, this);
                                };
                            }
                            td.classList.add("centered");
                            td.classList.add("v-align-middle");
                            td.appendChild(iconBtn);
                        }
                    }
                    break;
                case "actions":
                    {
                        const opts = typeof options === "function" ? options(dta) : options;
                        const changeStateSelect = createSelectShoelace({
                            id: "actions",
                            caret: false,
                            pill: true,
                            ommitCheckSign: true,
                            size: "small",
                            variant: typeof iconVariant === "function"
                                ? iconVariant(dta)
                                : iconVariant || "neutral",
                            buttonText: "",
                            iconPrefix: typeof icon === "function" ? icon(dta) : icon,
                            disabled: opts.length === 0,
                            title: label,
                            options: opts.map((o) => ({
                                name: o.label,
                                value: o.value,
                                icon: o.icon,
                                variant: o.variant,
                            })),
                            onChange: (d) => onClick(dta, name, this, d),
                        });
                        td.className = "centered v-align-middle";
                        td.appendChild(changeStateSelect);
                    }
                    break;
                case "inlineToggle":
                    {
                        const inlineToggle = document.createElement("sl-switch");
                        inlineToggle.id = `toggle-${pk}-${String(name)}`;
                        inlineToggle.name = String(name);
                        inlineToggle.checked = rest.getValueFn(dta);
                        inlineToggle.addEventListener("sl-change", (ev) => {
                            const target = ev.target;
                            rest.onToggle(dta, name, this, target.checked);
                        });
                        td.appendChild(inlineToggle);
                    }
                    break;
                case "inlineRadioButtons":
                    {
                        const inlineRbns = document.createElement("tf-radio-group");
                        inlineRbns.id = `tfrb-${pk}-${String(name)}`;
                        inlineRbns.name = `tfrb-${pk}-${String(name)}`;
                        inlineRbns.items = rest.radioOptions;
                        inlineRbns.style.display = "inline-block";
                        inlineRbns.value = valueFunction
                            ? valueFunction(dta)
                            : String(dta[name]) || "";
                        inlineRbns.addEventListener("sl-change", (ev) => {
                            const target = ev.target;
                            rest.onChange(dta, name, this, target.value, target);
                        });
                        td.appendChild(inlineRbns);
                    }
                    break;
                case "inlineEdit":
                    {
                        const inlineInput = document.createElement("sl-input");
                        inlineInput.id = `input-${pk}-${String(name)}`;
                        inlineInput.name = String(name);
                        inlineInput.size = "small";
                        const editType = rest.editType || "text";
                        inlineInput.type = editType;
                        inlineInput.style.width = fixedWidth || "100%";
                        inlineInput.value = valueFunction
                            ? valueFunction(dta)
                            : String(dta[name]) || "";
                        inlineInput.addEventListener("sl-change", (ev) => {
                            const target = ev.target;
                            rest.onChange(dta, name, this, editType === "text" ? target.value : target.valueAsNumber, target);
                        });
                        td.appendChild(inlineInput);
                    }
                    break;
                case "inlineEditPadded":
                    {
                        const inlineInput = document.createElement("tf-input-padded");
                        inlineInput.id = `input-${pk}-${String(name)}`;
                        inlineInput.name = String(name);
                        inlineInput.size = "small";
                        inlineInput.padZeros = rest.padZeros;
                        inlineInput.style.width = fixedWidth || "100%";
                        inlineInput.value = Number(dta[name]);
                        inlineInput.addEventListener("sl-change", (ev) => {
                            const target = ev.target;
                            rest.onChange(dta, name, this, Number(target.value), target);
                        });
                        td.appendChild(inlineInput);
                    }
                    break;
                case "inlineEditWithUnits":
                    {
                        const inlineInput = document.createElement("tf-input-units");
                        inlineInput.id = `input-${pk}-${String(name)}`;
                        inlineInput.name = String(name);
                        inlineInput.size = "small";
                        inlineInput.style.width = fixedWidth || "100%";
                        if (rest.converter)
                            inlineInput.converter = rest.converter;
                        if (rest.transponser)
                            inlineInput.transponser = rest.transponser;
                        inlineInput.value = dta[name];
                        inlineInput.addEventListener("sl-change", (ev) => {
                            const target = ev.target;
                            rest.onChange(dta, name, this, target.value, target);
                        });
                        td.appendChild(inlineInput);
                    }
                    break;
                default:
                    if (mapper) {
                        const res = mapper(dta);
                        if (typeof res === "string") {
                            td.innerHTML = res;
                        }
                        else {
                            td.appendChild(res);
                        }
                    }
                    else {
                        td.innerHTML = value;
                    }
            }
            if (smallPadding)
                td.classList.add("small-padding");
            tr.appendChild(td);
        });
        const iRow = {
            index,
            pk,
            row: tr,
            data: dta,
            visible: true,
        };
        this.rows[index] = iRow;
        rowsByPk[pk] = iRow;
        return tr;
    }
    /**
     * Updates the effectiveData
     * @param searchTerm
     */
    updateEffectiveDataWithSearch(searchTerm, force = false) {
        // Don't update if same search term
        var _a;
        if (!force && searchTerm.toUpperCase().trim() === this.filterText) {
            return false;
        }
        // Update filterText
        this.filterText = searchTerm.toUpperCase().trim();
        const pkFunction = this.options.pkFunction;
        const options = this.options, filterTexts = this.filterText
            .toUpperCase()
            .trim()
            .split(" ")
            .filter(Boolean)
            .map((s) => s.trim()), data = this.sortedData, dataCount = data.length, fields = options.fields.filter(Boolean).filter((f) => !options.settings.filterFields ||
            options.settings.filterFields.indexOf(f.name) >= 0);
        // Number of search terms
        const filterTextsNumber = filterTexts.length;
        const onlyThesePks = this._onlyThesePks;
        const hasOnlyThesePks = onlyThesePks.length > 0;
        // No search, set effective data as copy of sorted data
        if (!filterTextsNumber && !hasOnlyThesePks) {
            this.effectiveData = this.sortedData.slice();
            return true; // --> FAST RETURN
        }
        const effectiveData = [];
        for (let r = 0; r < dataCount; r += 1) {
            const dta = data[r];
            if (hasOnlyThesePks) {
                const pk = (_a = pkFunction(dta)) === null || _a === void 0 ? void 0 : _a.toString();
                if (!pk || !onlyThesePks.includes(pk))
                    continue;
            }
            if (filterTextsNumber) {
                let meetsArraySearchCriteria = 0;
                for (let j = 0; j < filterTextsNumber; j += 1) {
                    for (let i = 0; i < fields.length; i += 1) {
                        const field = fields[i];
                        const dataVal = String(field.valueFunction
                            ? field.valueFunction(dta)
                            : dta[fields[i].name]).toUpperCase();
                        if (dataVal.indexOf(filterTexts[j]) >= 0) {
                            meetsArraySearchCriteria += 1;
                            break; // from fields for
                        }
                    }
                    if (meetsArraySearchCriteria === filterTextsNumber)
                        effectiveData.push(dta);
                }
            }
            else {
                effectiveData.push(dta);
            }
        }
        this.effectiveData = effectiveData;
        if (this.options.onFilterTextChange) {
            this.options.onFilterTextChange(this.filterText);
        }
        return true;
    }
    /**
     * Hide and show rows, depending on filterText
     */
    applySearch(searchTerm) {
        const dataChanged = this.updateEffectiveDataWithSearch(searchTerm);
        if (!dataChanged)
            return;
        // 1. Counter
        this.updateSearchCounter();
        // 2. Table Body
        const initialRows = this.options.initialRows || DEFAULT_ROWS_VISIBLE;
        removeChildren(this.nodes.tbody);
        this.renderGroupFromToIndex(0, initialRows, this.nodes.tbody, false);
        this.setParentHeight();
    }
    updateSearchCounter() {
        if (!this._searchResults)
            return;
        const filteredCount = this.effectiveData.length, dataCount = this.sortedData.length;
        if (this.filterText) {
            this._searchResults.innerHTML = `${SmartTable.numberFormat.format(filteredCount)} <small>${SmartTable.numberFormat.format(dataCount)}</small>`;
            this._searchResults.style.display = "inline-block";
        }
        else {
            this._searchResults.style.display = "none";
        }
    }
    updateSingleData(pk, dta, refresh = false) {
        const dtaIdx = this.options.data.findIndex((d) => this.options.pkFunction(d) === pk);
        if (dtaIdx < 0)
            return dtaIdx;
        const newData = Object.assign(Object.assign({}, this.options.data[dtaIdx]), dta);
        this.options.data[dtaIdx] = newData;
        if (refresh)
            this.updateData();
        return dtaIdx;
    }
    /**
     * Updates the table with the new data. Re-constructs all the HTML.
     * @param data An array of data
     * @param useInternalSort boolean. If false, it won't sort internally
     */
    updateData(data, useInternalSort = true) {
        let dataToUse;
        if (data === undefined) {
            dataToUse = this.options.data;
        }
        else {
            this.options.data = data;
            dataToUse = data;
        }
        if (useInternalSort && this.sortedBy) {
            this.sortedData = dataToUse.slice().sort(sortByFields(this.sortedBy));
        }
        else {
            this.sortedData = dataToUse;
        }
        this._buildHtml(this.options, true);
    }
    /**
     * Update caption of table
     * @param title
     */
    updateTitle(title) {
        this.options.title = title;
        return this;
    }
    selectRows({ index, pk, pks, startIndex, endIndex, clearSelection, scrollIntoView = false, }) {
        const rows = this.rows, rowsLenght = rows.length;
        let sIndex = startIndex || -1;
        let eIndex = endIndex || -1;
        // Flip if draggin upwards
        if (sIndex >= 0 && eIndex >= 0 && eIndex < sIndex) {
            sIndex = eIndex;
            eIndex = startIndex || -1;
        }
        if (index !== undefined && index >= 0 && index < rowsLenght) {
            // By index
            sIndex = index;
            eIndex = sIndex;
        }
        else if (pk !== undefined) {
            // By PK
            sIndex = this.rowsByPk[pk] !== undefined ? this.rowsByPk[pk].index : -1;
            eIndex = sIndex;
        }
        // Select
        if (sIndex >= 0 && sIndex <= eIndex && eIndex < rowsLenght) {
            this.selection = rows.filter(filterSelected);
        }
        else if (pks === null || pks === void 0 ? void 0 : pks.length) {
            // By PKs
            const pksSet = new Set(pks);
            this.selection = rows.filter((r) => pksSet.has(r.pk) && r.visible);
        }
        else if (clearSelection) {
            // Clear
            this.selection = [];
        }
        // Apply selected classes
        rows.forEach((r) => {
            if (!clearSelection && this.selection.includes(r)) {
                if (!r.row.dataset["rowSelected"])
                    r.row.dataset["rowSelected"] = "true";
            }
            else {
                delete r.row.dataset["rowSelected"];
            }
        });
        if (scrollIntoView && this.selection.length) {
            const effectiveData = this.effectiveData;
            const effectiveDataLength = effectiveData.length;
            const firstRowSelected = this.selection[0];
            const block = typeof scrollIntoView === "boolean"
                ? "end"
                : scrollIntoView === "optimal"
                    ? firstRowSelected.index < 9
                        ? "nearest"
                        : firstRowSelected.index > effectiveDataLength - 2
                            ? "end"
                            : "nearest"
                    : scrollIntoView;
            firstRowSelected.row.scrollIntoView({
                behavior: "smooth",
                block,
            });
        }
        return this.selection.map((r) => r.index);
        function filterSelected(r, idx) {
            return r.visible && idx >= sIndex && idx <= eIndex;
        }
    }
    getRowByPk(pk) {
        return this.rowsByPk[pk];
    }
    removeRow({ index, pk, }) {
        const rows = this.rows, rowsByPk = this.rowsByPk, rowsLenght = rows.length;
        if (index !== undefined && index >= 0 && index < rowsLenght) {
            const rowFoundByIndex = rows[index];
            if (rowFoundByIndex !== undefined) {
                rows.splice(index, 1);
                delete rowsByPk[rowFoundByIndex.pk];
                reIndexRows();
                const parentNode = rowFoundByIndex.row.parentNode;
                if (parentNode)
                    parentNode.removeChild(rowFoundByIndex.row);
            }
            return true;
        }
        else if (pk) {
            const indexFound = rows.findIndex((r) => r.pk === pk);
            const rowFound = indexFound >= 0 ? rows[indexFound] : undefined;
            if (rowFound) {
                rows.splice(indexFound, 1);
                delete rowsByPk[rowFound.pk];
                reIndexRows();
                const parentNode = rowFound.row.parentNode;
                if (parentNode)
                    parentNode.removeChild(rowFound.row);
                return true;
            }
        }
        return false;
        function reIndexRows() {
            rows.forEach((row, index) => {
                row.index = index;
            });
        }
    }
    appendToHeaderPseudoSlot(node, removePrevious = true) {
        if (removePrevious && this._headerPseudoSlotContent) {
            this._headerPseudoSlotContent.remove();
        }
        this._headerPseudoSlotContent = node;
        if (this._headerPseudoSlot)
            this._headerPseudoSlot.appendChild(node);
    }
    updateRowWithNewData(pk, dta) {
        const dtaIdx = this.updateSingleData(pk, dta);
        if (dtaIdx < 0)
            return;
        const newData = this.options.data[dtaIdx];
        const row = this.rowsByPk[pk];
        if (!row)
            return;
        row.data = newData;
        const tr = this.renderRow(newData, row.index, true, this.options.settings.rowAddCheckboxes || false);
        if (tr) {
            row.row.replaceWith(tr);
            row.row = tr;
        }
    }
}
SmartTable.numberFormat = Intl.NumberFormat("en-US");
customElements.define("smart-table", SmartTable);
