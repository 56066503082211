import "./fileMap-component.scss";

import {
  DivWithSpinner,
  IMapConfigArray,
  mapDataToDictionary,
} from "@tedivo/tedivo-ui";
import {
  IFile,
  IFileConsumerPublic,
  TSourceFileCreated,
  systemRights,
} from "@tedivo/tvd-api-models";
import { getTranslation, i18n } from "../../../app/i18/i18tn";

import Services from "../../../app/services";
import SlDialog from "@shoelace-style/shoelace/dist/components/dialog/dialog";
import { createModalOptionsForCloneModified } from "../my-cloud/createModalOptionsForCloneModified";
import { createModalOptionsForConsumerUpdated } from "../my-cloud/createModalOptionsForConsumerUpdated";
import { formatDateInCurrentTimezone } from "@tedivo/tedivo-pure-helpers";
import getTranslatedDictionary from "../../../app/i18/getTranslatedDictionary";
import goSquared from "../../../app/tracking/goSquared";
import { openOvdInTvd } from "../../../app/helpers/openOvdInTvd";
import { removeChildren } from "@tedivo/tedivo-dom-helpers";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import securityModule from "../../../app/security/SecurityModule";
import { setAppTitle } from "../../../app/setAppTitle";
import { showFileHistory } from "../view-json/fileHistory/showFileHistory";
import topMessageElement from "../../layout/top-tools/getTopMessageElement";

export class TVDFilesComparer extends HTMLElement {
  public static observedAttributes = [];

  private holder: DivWithSpinner;
  private divContent: HTMLDivElement;
  private confirmDialog: SlDialog | undefined = undefined;
  private dialogHistory: SlDialog;

  constructor() {
    super();

    this.holder = document.createElement("div-spinner-element");
    this.divContent = document.createElement("div");
    this.dialogHistory = document.createElement("sl-dialog");
  }

  async connectedCallback() {
    const holder = this.holder;
    this.appendChild(holder);

    const id = router.getRouteParams().id;
    const orgId =
      (router.currentState as { organizationId: string })?.organizationId ||
      securityModule.currentOrganizationId;

    if (!id || !orgId) {
      return router.navigate(routeFns.myCloud());
    }

    holder.setLoading(true);

    // Request data
    const [fileData, fileConsumers] = await Promise.all([
      Services.files.get(id, true),
      Services.files.getFileConsumers(orgId, id),
    ]);

    if (fileData?.data === undefined) {
      return router.navigate(routeFns.myCloud());
    }

    goSquared.trackPage("File Map");
    goSquared.addEvent(`File Map - Show page`);

    this.dialogHistory.setAttribute("style", "--width: 80vw");
    this.dialogHistory.label = getTranslation("view:edit.fileHistory.title");
    this.appendChild(this.dialogHistory);

    setAppTitle(
      getTranslation("view:fileMap.pageTitle", {
        vesselName: fileData.data?.name || "?",
      }),
    );

    const topEle = topMessageElement.element;
    if (topEle) topEle.innerHTML = fileData.data.organizationName;

    await this.render(fileData.data, fileConsumers.data || []);

    holder.setLoading(false);
  }

  async render(json: IFile, consumers: IFileConsumerPublic[]) {
    removeChildren(this.divContent);

    const titleWithActions = document.createElement("div");
    titleWithActions.className = "title-with-action";

    const h1 = document.createElement("h1");
    h1.innerHTML = getTranslation("view:fileMap.pageTitle", {
      vesselName: json.name,
    });

    titleWithActions.appendChild(h1);
    // titleWithActions.appendChild(actionsDropdown);

    this.divContent.appendChild(
      await createPage({
        file: json,
        consumers,
        dialogHistory: this.dialogHistory,
        sourceActions: this.sourceActions,
        consumerActions: this.consumerActions,
      }),
    );

    this.holder.appendChild(titleWithActions);
    this.holder.appendChild(this.divContent);
  }

  /** Actions for Clone Source */
  sourceActions = async (file: IFile, sourceOrgName: string) => {
    if (!file.cloneDiffers) {
      const [fileOrgId, fileId] = file?.cloneFrom?.split(":") || [];
      openOvdInTvd(
        {
          organizationId: fileOrgId || "",
          fileId: fileId || "",
          name: file.name,
          organizationName: sourceOrgName,
        },
        undefined,
      );
    } else {
      createModalOptionsForCloneModified(file, async (action) => {
        switch (action) {
          case "view":
            {
              const [fileOrgId, fileId] = file?.cloneFrom?.split(":") || [];
              openOvdInTvd(
                {
                  organizationId: fileOrgId || "",
                  fileId: fileId || "",
                  name: file.name,
                  organizationName: sourceOrgName,
                },
                undefined,
              );
            }
            break;
          case "update":
            await Services.files.updateWithSource(file.fileId);
            router.navigate(routeFns.ovdEdit(file.fileId));
            break;
          case "viewDiff":
            router.navigate(routeFns.fileCompareTvlSource(file.fileId));
            break;
          case "ignoreUpdate":
            await Services.files.ignoreCloneDiffers(file.fileId);
            router.navigate(routeFns.fileMap(file.fileId), undefined, true);
            break;
        }
      });
    }
  };

  /** Actions for Consumers */
  consumerActions = async (file: IFile, fc: IFileConsumerPublic) => {
    const showUpdatesAvailable = fc.consumerDiffers && fc.sourceIgnore === "N";

    if (!showUpdatesAvailable) {
      openOvdInTvd(
        {
          organizationId: fc.consumerOrgId,
          fileId: fc.consumerFileId,
          name: fc.consumerVesselName,
          organizationName: fc.consumerOrgName,
        },
        undefined,
      );
    } else {
      createModalOptionsForConsumerUpdated(async (action) => {
        this.holder.setLoading(true);
        switch (action) {
          case "view":
            openOvdInTvd(
              {
                organizationId: fc.consumerOrgId,
                fileId: fc.consumerFileId,
                name: fc.consumerVesselName,
                organizationName: fc.consumerOrgName,
              },
              undefined,
            );
            break;
          case "update":
            await Services.files.updateWithConsumer({
              sourceFileId: file.fileId,
              consumerOrgId: fc.consumerOrgId,
              consumerFileId: fc.consumerFileId,
            });
            router.navigate(routeFns.ovdEdit(file.fileId));
            break;
          case "viewDiff":
            router.navigate(
              routeFns.fileCompareConsumer(
                file.fileId,
                fc.consumerOrgId,
                fc.consumerFileId,
              ),
            );
            break;
          case "ignoreUpdate":
          case "ignoreFile":
            await Services.files.ignoreConsumerDiffers({
              sourceOrgId: file.organizationId,
              sourceFileId: file.fileId,
              consumerOrgId: fc.consumerOrgId,
              consumerFileId: fc.consumerFileId,
              ignoreType: action === "ignoreFile" ? "all" : "current",
            });

            router.navigate(routeFns.fileMap(file.fileId), undefined, true);
            break;
        }
      });
    }
  };
}

customElements.define("tvd-filemap-component", TVDFilesComparer);

declare global {
  interface HTMLElementTagNameMap {
    "tvd-filemap-component": TVDFilesComparer;
  }
}

async function createPage({
  file,
  consumers,
  dialogHistory,
  sourceActions,
  consumerActions,
}: {
  file: IFile;
  consumers: IFileConsumerPublic[];
  dialogHistory: SlDialog;
  sourceActions: (file: IFile, sourceOrgName: string) => void;
  consumerActions: (file: IFile, fc: IFileConsumerPublic) => void;
}): Promise<HTMLElement> {
  const page = document.createElement("div");
  page.className = " fileMap-main-part";

  page.appendChild(createSourcePart());
  page.appendChild(createMainPart());

  if (consumers.length > 0) page.appendChild(createConsumersPart());

  return page;

  /** This is the one where you can see how the file came to exist: ImportSTAF, Cloned, ... */
  function createSourcePart() {
    let sourceOrgName = "*****";

    const originalSource =
      file.originalSource || ("ImportSTAF" as TSourceFileCreated);

    const sourcePart = document.createElement("div");
    sourcePart.className = "boxed-part source-part";

    const sourcePartTitle = document.createElement("span");
    sourcePartTitle.innerHTML = getTranslation(
      `enums:TSourceFileCreated.${originalSource}`,
    );
    const p = document.createElement("p");
    p.className = "date";
    p.innerHTML = formatDateInCurrentTimezone(
      file.createdAt || file.lastModified,
    );

    sourcePart.appendChild(sourcePartTitle);
    sourcePart.appendChild(p);

    if (originalSource === "ClonedOthersOVD") {
      const btnViewTitle = getTranslation(
        file.cloneDiffers
          ? "view:fileMap.sourceChanges"
          : "view:fileMap.sourceNoChanges",
      );

      const btnView = document.createElement("sl-button");
      btnView.pill = true;
      btnView.variant = "neutral";
      btnView.size = "small";
      btnView.outline = true;
      btnView.innerHTML = btnViewTitle;

      const tooltip = document.createElement("sl-tooltip");
      tooltip.content = btnViewTitle;
      tooltip.appendChild(btnView);
      tooltip.disabled = true;

      if (file.cloneDiffers) btnView.variant = "warning";

      sourcePart.appendChild(tooltip);
      sourcePart.classList.add("isClone");

      btnView.addEventListener("click", () =>
        sourceActions(file, sourceOrgName),
      );

      btnView.disabled = true;

      const [fileOrgId, fileId] = file.cloneFrom?.split(":") || [];

      if (!fileOrgId) return sourcePart;

      Services.files.isCommunityFile(fileOrgId, fileId).then((r) => {
        const disabled = !(r.data?.isCommunity ?? false);
        btnView.disabled = disabled;
        btnView.title = disabled
          ? getTranslation("errors:fileNotInCommunity")
          : btnViewTitle;

        if (disabled) {
          tooltip.disabled = false;
          tooltip.content = getTranslation("errors:fileNotInCommunity");
        }
      });

      if (
        securityModule.userHasPermission(
          systemRights.ORG.CanSeePublicFileOrgName,
        )
      )
        Services.organizations.getOrganizationName(fileOrgId).then((r) => {
          sourceOrgName = r.data?.name || "*****";
        });
    }

    return sourcePart;
  }

  function createMainPart() {
    const mainPart = document.createElement("div");
    mainPart.className = "boxed-part current-part";

    const h2 = document.createElement("h2");
    h2.innerHTML = file.name;

    const infoIcon = document.createElement("sl-icon");
    infoIcon.name = "info-circle";

    const h3 = document.createElement("h3");
    h3.title = getTranslation("general:fileState");
    h3.appendChild(infoIcon);
    h3.appendChild(
      document.createTextNode(
        getTranslation(`enums:FileStateEnum.${file.state}`),
      ),
    );

    const editButton = document.createElement("sl-button");
    const pencilIcon = document.createElement("sl-icon");
    pencilIcon.name = "pencil";
    pencilIcon.slot = "prefix";
    editButton.pill = true;
    editButton.variant = "primary";
    editButton.outline = true;
    editButton.title = getTranslation("general:common.edit");
    editButton.appendChild(pencilIcon);
    editButton.addEventListener("click", () => {
      router.navigate(routeFns.ovdEdit(file.fileId));
    });

    const icon = document.createElement("sl-icon");
    icon.name = "binoculars";
    icon.slot = "prefix";

    const fileHistoryBtn = document.createElement("sl-button");
    fileHistoryBtn.pill = true;
    fileHistoryBtn.variant = "primary";
    fileHistoryBtn.outline = true;
    fileHistoryBtn.size = "small";

    fileHistoryBtn.addEventListener("click", () => {
      removeChildren(dialogHistory);
      const node = showFileHistory({
        name: file.name,
        organizationId: file.organizationId,
        fileId: file.fileId,
        createdAt: file.createdAt,
        userSub: file.userSub,
        updatedAt: file.lastModified,
        updatedBy: file.lastAuthor,
        fromBvoName: file.fromBvoName,
        lastComment: file.lastComment,
      });
      if (node) dialogHistory.appendChild(node);
      dialogHistory.show();
    });

    fileHistoryBtn.innerHTML = getTranslation("view:edit.fileHistory.title");
    fileHistoryBtn.appendChild(icon);

    const buttons = document.createElement("div");
    buttons.className = "current-buttons";
    buttons.appendChild(fileHistoryBtn);
    buttons.appendChild(editButton);

    mainPart.appendChild(h2);
    mainPart.appendChild(h3);
    mainPart.appendChild(createCurrenPartDict(file));
    mainPart.appendChild(buttons);
    return mainPart;

    function createCurrenPartDict(file: IFile) {
      const ViewShipDataConfig: IMapConfigArray<ICurrentPartData> = [
        {
          label: "view:imoCode",
          key: "imoCode",
          type: "string",
          undefinedIsDash: true,
        },
        {
          label: "view:callSign",
          key: "callSign",
          type: "string",
          undefinedIsDash: true,
        },
        {
          label: "general:common.lastUpdated",
          key: "lastModified",
          type: "string",
          format: (v) => formatDateInCurrentTimezone(v as Date),
          undefinedIsDash: true,
        },
        {
          label: "view:downloads",
          key: "downloads",
          type: "number",
          undefinedIsDash: true,
        },
        {
          label: "view:votes",
          key: "votes",
          type: "number",
          undefinedIsDash: true,
        },
      ];

      const elements = getTranslatedDictionary(
        mapDataToDictionary<ICurrentPartData>(
          {
            imoCode: file.imoCode,
            callSign: file.callSign,
            lastModified: file.lastModified,
            downloads: file.downloads || 0,
            votes: file.votes || 0,
          },
          ViewShipDataConfig,
          i18n.language,
        ),
      );

      const partDisplay = document.createElement("dictionary-display-element");
      partDisplay.className = "current";
      partDisplay.elements = elements;

      return partDisplay;
    }
  }

  function createConsumersPart() {
    const consumersPart = document.createElement("div");
    consumersPart.className = "boxed-part consumers-part";

    const h2 = document.createElement("div");
    h2.className = "title";
    h2.innerHTML = getTranslation("view:fileMap.consumers");

    const ul = document.createElement("ul");
    ul.className = "ul-consumers";

    consumers
      .sort(
        (a, b) =>
          new Date(b.consumerLastModified as string).getTime() -
          new Date(a.consumerLastModified as string).getTime(),
      )
      .forEach((fc) => {
        const li = document.createElement("li");

        const orgName = document.createElement("span");
        orgName.className = "org-name";
        orgName.innerHTML = fc.consumerOrgName;

        const vesselName = document.createElement("span");
        vesselName.className = "vessel-name";
        vesselName.innerHTML = fc.consumerVesselName || "";

        const lastModified = document.createElement("span");
        lastModified.className = "date";
        lastModified.innerHTML = formatDateInCurrentTimezone(
          fc.consumerLastModified,
        );

        const actions = document.createElement("div");
        actions.className = "actions";

        const showWarning = fc.consumerDiffers && fc.sourceIgnore === "N";

        const btn = document.createElement("sl-button");
        btn.pill = true;
        btn.variant = showWarning ? "warning" : "neutral";
        btn.outline = true;
        btn.size = "small";
        btn.innerHTML = getTranslation(
          showWarning
            ? "view:fileMap.consumerChanges"
            : "view:fileMap.consumerNoChanges",
        );

        btn.addEventListener("click", async () => {
          consumerActions(file, fc);
        });

        li.appendChild(vesselName);
        li.appendChild(orgName);
        li.appendChild(lastModified);
        li.appendChild(actions);

        actions.appendChild(btn);

        ul.appendChild(li);
      });

    consumersPart.appendChild(h2);
    consumersPart.appendChild(ul);

    return consumersPart;
  }
}

interface ICurrentPartData {
  imoCode: string | undefined;
  callSign: string | undefined;
  lastModified: Date;
  downloads: number;
  votes: number;
}
