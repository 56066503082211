import { createRowsFromConfig, } from "@tedivo/tedivo-bay-grid-pure";
const name = "lidDataForBaysValidator";
const okTranslationKey = "validators:lids.lidDataForBaysValidator.ok";
const errorTranslationKey = "validators:lids.lidDataForBaysValidator.error";
const specialScopes = [];
/** Check if lids extend correctly over below bays */
export const lidDataForBaysValidator = {
    name,
    okTranslationKey,
    errorTranslationKey,
    fn: lidDataForBaysValidatorFn,
    specialScopes,
};
export function lidDataForBaysValidatorFn({ data: { json, lcgVcgTcgAndPairings }, }) {
    const { sizeSummary, lidData } = json;
    const blps = (lcgVcgTcgAndPairings === null || lcgVcgTcgAndPairings === void 0 ? void 0 : lcgVcgTcgAndPairings.bayLevelPositionsBelow) || [];
    const blockBaysAndSizesBy20Bay = (lcgVcgTcgAndPairings === null || lcgVcgTcgAndPairings === void 0 ? void 0 : lcgVcgTcgAndPairings.blockBaysAndSizesBy20Bay) || {};
    if (sizeSummary.maxRow === undefined)
        return {
            name,
            isValid: true,
            messageTranslationKey: okTranslationKey,
            result: {
                validResults: [],
                invalidResults: [],
                allLids: [],
                rowTcgsByBay: {},
            },
        };
    const rowsArraySorted = createRowsFromConfig(!!sizeSummary.centerLineRow, sizeSummary.maxRow);
    const rowTcgsByBay = copyTcgOfRowsByBay(blps);
    const lidsDataWithValidation = lidData.map((lid) => {
        const portIndex = rowsArraySorted.indexOf(lid.portIsoRow);
        const stbdIndex = rowsArraySorted.indexOf(lid.starboardIsoRow);
        let notValid = undefined;
        let containersWithTcg = 0;
        const bays = [
            ...blockBaysAndSizesBy20Bay[lid.startIsoBay].allBays.split("-"),
            ...blockBaysAndSizesBy20Bay[lid.endIsoBay].allBays.split("-"),
        ].filter((b, idx, arr) => arr.indexOf(b) === idx);
        for (let i = portIndex; i <= stbdIndex; i++) {
            const row = rowsArraySorted[i];
            const tcg = bays.reduce((acc, bay) => {
                var _a;
                return acc || ((_a = rowTcgsByBay === null || rowTcgsByBay === void 0 ? void 0 : rowTcgsByBay[bay]) === null || _a === void 0 ? void 0 : _a[row]);
            }, undefined);
            if (tcg !== undefined) {
                containersWithTcg++;
            }
        }
        if (containersWithTcg === 0) {
            notValid = "danger";
        }
        else if (containersWithTcg < Math.abs(stbdIndex - portIndex) + 1) {
            notValid = "warning";
        }
        const res = Object.assign(Object.assign({}, lid), { notValid });
        return res;
    });
    const invalidResults = lidsDataWithValidation.filter((lid) => lid.notValid);
    const validResults = lidsDataWithValidation.filter((lid) => !lid.notValid);
    const isValid = invalidResults.length === 0;
    return {
        name,
        isValid,
        messageTranslationKey: isValid ? okTranslationKey : errorTranslationKey,
        result: {
            validResults,
            invalidResults,
            allLids: lidsDataWithValidation,
            rowTcgsByBay,
        },
    };
}
//  Helpers
function copyTcgOfRowsByBay(blps) {
    const rowTcgsByBay = {};
    blps.forEach(copyTcgOfRows);
    function copyTcgOfRows(blp) {
        const rows = blp.rows;
        const rowKeys = Object.keys(rows);
        if (!rowTcgsByBay[blp.isoBay])
            rowTcgsByBay[blp.isoBay] = {};
        rowKeys.forEach((rowKey) => {
            rowTcgsByBay[blp.isoBay][rowKey] = rows[rowKey].tcg || 0;
        });
    }
    return rowTcgsByBay;
}
