import { ActionTypesEnum, IActionVesselPart } from "../../partsReducer";
import {
  ForeAftEnum,
  IVesselParts,
  VesselPartTypeEnum,
} from "open-vessel-definition";
import {
  IField,
  IFields,
  InputWithUnits,
  TedivoForm,
  translateTedivoForm,
} from "@tedivo/tedivo-form";

import { IVesselOneBaySlot } from "../../../../../../../../../components/features/view-json/vessel3D/types/IVesselOneBaySlot";
import LenUnitsEnum from "../../../../../../../../../app/enums/LenUnitsEnum";
import { findComposedRefId } from "../helpers/findComposedRefId";
import { getTranslation } from "../../../../../../../../../app/i18/i18tn";
import globalUnits from "../../../../../../../../../app/units/globalUnits";
import { z } from "zod";

type ICustomVesselPartWithoutIdAndType = Omit<IVesselParts, "id"> & {
  type: VesselPartTypeEnum;
  id?: string; // For the edit
};

/**
 * Creates a modal for the parts edit or creation
 * @param data Current data
 * @param partFields Extra fields for the Part
 * @param currentPartsLabels All the labels for the current parts { id: label }
 * @param validator Validator for the part
 */
export function createBaseModalForParts<
  T extends ICustomVesselPartWithoutIdAndType,
>({
  data,
  partFields,
  type,
  defaultLen,
  maxLen,
  calculatedSlots,
  currentLabels,
  createValidator,
  spaceAvailableCalcFn,
  onSubmit,
}: IBaseModalForPartsProps & {
  data?: ICustomVesselPartWithoutIdAndType;
  partFields: IField<T>[];
  type: VesselPartTypeEnum;
  createValidator: (len: number | undefined) => z.Schema<T>;
  onSubmit: (data: T | undefined, action?: IActionVesselPart) => void;
}) {
  const currentId = data?.id;
  const currentUnits = globalUnits.lengthUnits.units;

  const effectiveLen = currentId
    ? data?.len || 0
    : maxLen !== undefined
    ? Math.min(defaultLen, maxLen)
    : defaultLen;

  const dialog = document.createElement("sl-dialog");
  dialog.id = `createModalForParts-${type}`;

  const submitButton = document.createElement("sl-button");
  submitButton.variant = "primary";
  submitButton.type = "submit";
  submitButton.slot = "footer";
  submitButton.innerText = getTranslation("general:common.save");

  const fields: IFields<T> = [
    {
      name: "label",
      type: "textBox",
      label: "view:view3D.partsParams.label",
      initialValue: data?.label,
    },
    [
      {
        name: "slotRefId",
        type: "select",
        label: "view:view3D.partsParams.slotRefId",
        options: Object.keys(currentLabels).map((k) => ({
          value: k,
          label: currentLabels[k],
          disabled: k === currentId,
        })),
        initialValue: data?.slotRefId
          ? findComposedRefId(data.slotRefId, calculatedSlots)
          : "",
      },
      {
        name: "posRef",
        type: "radioButtonList",
        fieldset: false,
        label: "view:view3D.partsParams.posRef",
        isNumericEnum: true,
        options: [
          {
            value: ForeAftEnum.AFT,
            label: getTranslation("enums:ForeAftEnum.AFT"),
          },
          {
            value: ForeAftEnum.FWD,
            label: getTranslation("enums:ForeAftEnum.FWD"),
          },
        ],
        initialValue: data?.posRef,
      },
    ],
    {
      label: getTranslation("view:view3D.partsParams.len", {
        units: getTranslation(
          `enums:LenUnitsEnum.${LenUnitsEnum[currentUnits]}`,
        ),
      }),
      name: "len",
      type: "numberWithUnits",
      converter: globalUnits.lengthUnits,
      initialValue: effectiveLen,
      helpText:
        maxLen !== undefined && Math.abs(maxLen) < Number.MAX_SAFE_INTEGER
          ? getTranslation("view:view3D.partsParams.lenHelpText", {
              max: globalUnits.lengthUnits.fromValueToDisplay(maxLen),
            })
          : undefined,
    },
    {
      name: "type",
      type: "hidden",
      initialValue: type,
    },
    ...partFields,
  ];

  const tedivoForm = new TedivoForm<T>({
    fields,
    onSubmit: (data) => {
      onSubmit(data, undefined);
    },
    formValidator: createValidator(maxLen),
    submitButton: submitButton,
    formProps: { autoFocusOnFirstInput: true },
  });

  tedivoForm.onDataChange = (d, keyChanged) => {
    switch (keyChanged) {
      case "slotRefId":
      case "posRef":
        {
          const spaceAvailable = spaceAvailableCalcFn(
            d.slotRefId,
            d.posRef,
            data?.id,
          );

          const helpTextLen =
            spaceAvailable !== undefined &&
            Math.abs(spaceAvailable) < Number.MAX_SAFE_INTEGER
              ? getTranslation("view:view3D.partsParams.lenHelpText", {
                  max: globalUnits.lengthUnits.fromValueToDisplay(
                    spaceAvailable,
                  ),
                })
              : "";

          const lenField = tedivoForm.getFormControlsByName().len
            .field as InputWithUnits<LenUnitsEnum>;

          lenField.helpText = helpTextLen;

          tedivoForm
            .setFormValidator(createValidator(spaceAvailable))
            .execValidation();
        }
        break;
    }
  };

  translateTedivoForm<T>({
    tedivoForm,
    getTranslation,
  });

  dialog.appendChild(tedivoForm.form);
  dialog.appendChild(submitButton);

  if (currentId) {
    const deleteButton = document.createElement("delete-button");
    deleteButton.slot = "header-actions";
    deleteButton.buttonText = getTranslation("general:common.delete");
    deleteButton.confirmDeleteText = getTranslation(
      "general:common.confirmDelete",
    );
    deleteButton.cancelText = getTranslation("general:common.cancel");
    deleteButton.onConfirmDelete = () => {
      const cmd: IActionVesselPart = {
        action:
          type === VesselPartTypeEnum.BRIDGE
            ? ActionTypesEnum.REMOVE_BRIDGE_PART
            : type === VesselPartTypeEnum.CRANE
            ? ActionTypesEnum.REMOVE_CRANE_PART
            : type === VesselPartTypeEnum.SMOKE
            ? ActionTypesEnum.REMOVE_SMOKE_PART
            : ActionTypesEnum.REMOVE_SPACER_PART,
        id: currentId,
      };
      onSubmit(undefined, cmd);
      dialog.hide();
    };

    dialog.appendChild(deleteButton);
  }

  submitButton.addEventListener("click", () => {
    tedivoForm.doSubmitForm();
  });

  dialog.addEventListener("sl-after-hide", (ev) => {
    if (ev.target !== dialog) return;
    dialog.remove();
  });

  document.body.appendChild(dialog);

  return dialog;
}

export function extractEffectiveRefId(
  dataSlotRefId: string,
  dataPosRef: ForeAftEnum,
) {
  const slotRefIdTemp = dataSlotRefId;
  const [slotRefId1, slotRefId2] = slotRefIdTemp.split("-");
  const isBay = !isNaN(Number(slotRefId1));
  const effectiveRefId = !isBay
    ? slotRefIdTemp
    : dataPosRef === ForeAftEnum.FWD
    ? slotRefId1
    : slotRefId2;

  return effectiveRefId;
}

export function createBasePartValidator(maxLenAvailable: number | undefined) {
  return z.object({
    label: z.string().min(1),
    slotRefId: z.string(),
    posRef: z
      .nativeEnum(ForeAftEnum)
      .transform((v) => Number(v) as ForeAftEnum),
    len: z
      .number()
      .min(1)
      .refine(
        (data) =>
          data > 0 &&
          (maxLenAvailable === undefined || data <= maxLenAvailable),
        {
          path: ["len"],
          message: "view:view3D.partsParams.lenMax",
        },
      ),
  });
}

export type IBaseModalForPartsProps = {
  currentLabels: Record<string, string>; // { id: label }
  defaultLen: number;
  maxLen: number | undefined;
  calculatedSlots: IVesselOneBaySlot[];
  spaceAvailableCalcFn: (
    slotRefId: string,
    posRef: ForeAftEnum,
    id: string | undefined,
  ) => number | undefined;
};
