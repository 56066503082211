import { ActionTypesEnum, IActionVesselPart } from "../../partsReducer";
import {
  CraneSideEnum,
  IVesselPartCrane,
  VesselPartTypeEnum,
} from "open-vessel-definition";
import {
  IBaseModalForPartsProps,
  createBaseModalForParts,
  createBasePartValidator,
  extractEffectiveRefId,
} from "./createBaseModalForParts";

import { getTranslation } from "../../../../../../../../../app/i18/i18tn";
import { z } from "zod";

type ICustomTypeCranePartWithoutId = Omit<IVesselPartCrane, "id"> & {
  type: VesselPartTypeEnum.CRANE;
  id?: string; // For the edit
};

export function createModalForCranes({
  craneData,
  currentLabels,
  calculatedSlots,
  maxLen,
  defaultLen,
  onSubmitAction,
  spaceAvailableCalcFn,
}: {
  craneData: ICustomTypeCranePartWithoutId;
  onSubmitAction: (data: IActionVesselPart) => void;
} & IBaseModalForPartsProps) {
  function createValidator(
    maxLen: number | undefined,
  ): z.Schema<ICustomTypeCranePartWithoutId> {
    return createBasePartValidator(maxLen).and(
      z.object({
        type: z.literal(VesselPartTypeEnum.CRANE),
        cranes: z.array(z.nativeEnum(CraneSideEnum)).min(1),
      }),
    );
  }

  const id = craneData.id;

  const dialog = createBaseModalForParts<ICustomTypeCranePartWithoutId>({
    data: craneData,
    type: VesselPartTypeEnum.CRANE,
    maxLen,
    defaultLen,
    calculatedSlots,
    partFields: [
      {
        name: "cranes",
        type: "checkboxesList",
        fieldset: true,
        label: "view:view3D.partsParams.cranesPositions",
        options: [
          {
            label: getTranslation("enums:CraneSideEnum.PORT"),
            value: CraneSideEnum.PORT,
          },
          {
            label: getTranslation("enums:CraneSideEnum.CENTER"),
            value: CraneSideEnum.CENTER,
          },
          {
            label: getTranslation("enums:CraneSideEnum.STARBOARD"),
            value: CraneSideEnum.STARBOARD,
          },
        ],
        initialValue: craneData.cranes,
      },
    ],
    currentLabels: currentLabels,
    createValidator,
    spaceAvailableCalcFn,
    onSubmit: (data, cmd) => {
      if (data) {
        if (id) {
          const cmd: IActionVesselPart = {
            action: ActionTypesEnum.REPLACE_CRANE_PART,
            id,
            details: {
              id,
              ...data,
            },
          };
          onSubmitAction(cmd);
        } else {
          const cmd: IActionVesselPart = {
            action: ActionTypesEnum.ADD_CRANE_PART,
            slotRefId: extractEffectiveRefId(data.slotRefId, data.posRef),
            posRef: data.posRef,
            details: {
              ...data,
            },
          };
          onSubmitAction(cmd);
        }
        dialog.hide();
      } else {
        if (cmd) onSubmitAction(cmd);
      }
    },
  });

  dialog.label = getTranslation("view:view3D.actions.addCrane");
  dialog.show();
}
