import { __awaiter } from "tslib";
import { TedivoForm, translateTedivoForm, } from "@tedivo/tedivo-form";
import { IntegratedDialog, IntegratedDialogError } from "@tedivo/tedivo-ui";
import { LcgReferenceEnum, ValuesSourceRowTierEnum, } from "open-vessel-definition";
import { createFormFields, createFormValidator } from "./importStafFields";
import { getFilenameParts, roundDec, sanitizeString, } from "@tedivo/tedivo-pure-helpers";
import { getTranslation, i18nReactive } from "../../../app/i18/i18tn";
import { I18nComponentRegisterer } from "@tedivo/tedivo-i18";
import { ImoFoundResultEnum } from "./ImoFoundResultEnum";
import LenUnitsEnum from "../../../app/enums/LenUnitsEnum";
import Services from "../../../app/services";
import VcgVerticalReferenceEnum from "../../../app/enums/VcgVerticalReferenceEnum";
import { createFoundImoDialog } from "./createFoundImoDialog";
import { createNotFoundImoDialog } from "./createNotFoundImoDialog";
import { focusAndSelect } from "./focusAndSelect";
import { getBayLcgVcgTcgAndPairings } from "@tedivo/tedivo-bay-grid-pure";
import globalStore from "../../../app/stores/globalStore";
import globalUnits from "../../../app/units/globalUnits";
import goSquared from "../../../app/tracking/goSquared";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import securityModule from "../../../app/security/SecurityModule";
import { setAppTitle } from "../../../app/setAppTitle";
import { splitNameForMainNames } from "../../../app/services/import-staf/stafFileConvertToOVD";
import topMessageElement from "../../layout/top-tools/getTopMessageElement";
export class TVDImportStafComponent extends HTMLElement {
    constructor() {
        super();
        this.dialogError = new IntegratedDialogError(this, false, getTranslation("general:common.close"));
        this.submitButton = null;
        this.ownFiles = {
            lastUpdated: 0,
            list: [],
        };
        this.lppFieldsState = {
            formWithLpp: false,
            numberOfDefinedCgs: 0,
            numberOfCgsNeeded: 0,
            lppFromApi: 0,
            name: "",
            shipName: "",
            vmdShipNameLatest: undefined,
            shipClass: "",
        };
        this.readyToSubmit = false;
        this.internalMatch = undefined;
        this.imoApiState = ImoFoundResultEnum.INITIALIZED;
        this.onSubmitDataForm = (values) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            globalStore.touchLastUserInteraction();
            if (!this.readyToSubmit)
                return;
            if (this.submitButton) {
                this.submitButton.loading = true;
                this.submitButton.disabled = true;
            }
            const file = values.fileInput;
            const filenameParts = getFilenameParts(file.name);
            const name = sanitizeString(filenameParts.name);
            const viewState = {
                file,
                filenameParts,
                source: "stafText",
                vmdShipNameLatest: this.lppFieldsState.vmdShipNameLatest,
                formFields: {
                    lpp: Number((_a = (values.lpp || this.lppFieldsState.lppFromApi)) !== null && _a !== void 0 ? _a : 0),
                    loa: Number((_b = (values.loa || this.lppFieldsState.loaFromApi)) !== null && _b !== void 0 ? _b : 0),
                    shipClass: values.shipClass || "",
                    shipName: values.shipName || "",
                    lineOperator: values.lineOperator || "",
                    imoCode: values.imoCode || "",
                    callSign: values.callSign,
                    yearBuilt: values.yearBuilt,
                    vcgRef: values.vcgRef || VcgVerticalReferenceEnum.VCG_45_8_6,
                    aboveTier82is: values.aboveTier82is || 82,
                    removeCGs: values.removeCGs || false,
                },
            };
            yield this.getOwnFiles();
            if (this.ownFiles.list.some(({ name }) => name === values.shipName)) {
                const modal = new IntegratedDialog({
                    parentNode: this,
                    preventDefaultClose: true,
                    preventClose: true,
                    buttonsAttrs: [
                        {
                            label: getTranslation("general:common.saveAnyway"),
                            value: "saveAnyway",
                            variant: "danger",
                            icon: "exclamation-triangle",
                            type: "submit",
                        },
                        {
                            label: getTranslation("general:common.cancel"),
                            value: "cancel",
                            icon: "x-lg",
                            type: "button",
                            autofocus: true,
                        },
                    ],
                    removeDialogWhenHidden: true,
                });
                modal.show(getTranslation("general:common.attention"), getTranslation("errors:fileWithSameNameExists"));
                modal.onButtonClicked = (btnSelected) => {
                    if (btnSelected === "saveAnyway") {
                        goSquared.addEvent("Import-STAF - Exec");
                        router.navigate(routeFns.ovdEdit(name), viewState);
                    }
                };
            }
            else {
                goSquared.addEvent("Import-STAF - Exec");
                router.navigate(routeFns.ovdEdit(name), viewState);
            }
            if (this.submitButton) {
                this.submitButton.loading = false;
                this.submitButton.disabled = false;
            }
        });
        this.showError = (e, errorTlt = "errors:errorHasOcurred", errorStr = "errors:notValidStafFile") => {
            this.dialogError.show(getTranslation(errorTlt), getTranslation(errorStr));
        };
        this.i18nCR = new I18nComponentRegisterer(i18nReactive);
        setAppTitle(getTranslation("menu:fileImportStaf"));
    }
    connectedCallback() {
        if (securityModule.planIsReadOnly) {
            router.navigate(routeFns.myCloud());
            return;
        }
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const me = this;
        goSquared.trackPage("Import-STAF");
        goSquared.addEvent("Import-STAF - Show page");
        const wrapper = document.createElement("div"), h1 = document.createElement("h1");
        const box = document.createElement("div");
        box.className = "sections-oss-card";
        const submitButton = document.createElement("sl-button");
        submitButton.type = "submit";
        submitButton.innerHTML = getTranslation("general:common.submit");
        submitButton.variant = "primary";
        submitButton.disabled = true;
        this.submitButton = submitButton;
        const nextButton = document.createElement("sl-button");
        nextButton.type = "button";
        nextButton.innerHTML = getTranslation("general:common.next");
        nextButton.outline = true;
        nextButton.variant = "primary";
        const tedivoForm = new TedivoForm({
            fields: createFormFields({ imoCode: "" }),
            onSubmit: this.onSubmitDataForm,
            formValidator: createFormValidator(),
            submitButton: submitButton,
        });
        const loadingBlocker = document.createElement("loading-blocker-element");
        this.appendChild(loadingBlocker);
        translateTedivoForm({
            tedivoForm,
            i18nCR: this.i18nCR,
        });
        tedivoForm.onDataChange = (values, name) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d, _e;
            globalStore.touchLastUserInteraction();
            const fileInput = values.fileInput;
            let fnameParts;
            // This part is used to augment fields (LPP) when needed (LGCRef !== AftPerp)
            switch (name) {
                case "fileInput":
                    if (fileInput === undefined)
                        return;
                    fnameParts = getFilenameParts(values.fileInput.name);
                    this.lppFieldsState.name = fnameParts.filename;
                    this.lppFieldsState.formWithLpp = false;
                    this.lppFieldsState.numberOfDefinedCgs = 0;
                    this.lppFieldsState.numberOfCgsNeeded = 0;
                    if (fnameParts.name === "") {
                        this.showError(undefined, "general:common.attention", "errors:fileNameInvalid");
                        tedivoForm.getFormControlsByName().fileInput.field.reset();
                        return;
                    }
                    Services.importStaf
                        .stafFileReadShipData(fileInput)
                        .then((shipData) => __awaiter(this, void 0, void 0, function* () {
                        var _a;
                        const formWithLpp = shipData.lcgOptions.reference !==
                            LcgReferenceEnum.AFT_PERPENDICULAR;
                        /** This is only to calculate existing CGs */
                        const jsonTemporary = yield Services.importStaf.stafFileConvertToOVD(fileInput, 0, 1, 82);
                        const { cgsStats } = getBayLcgVcgTcgAndPairings({
                            bls: jsonTemporary.baysData,
                            vesselPartsData: jsonTemporary.vesselPartsData || [],
                            sizeSummary: jsonTemporary.sizeSummary,
                            masterCGs: jsonTemporary.shipData.masterCGs,
                        });
                        const numberOfCgsNeeded = cgsStats.countLcgs +
                            cgsStats.countBottomBase +
                            cgsStats.countTcgs;
                        const numberOfDefinedCgs = cgsStats.definedLcgs +
                            cgsStats.definedBottomBase +
                            cgsStats.definedTcgs;
                        this.lppFieldsState.formWithLpp = formWithLpp;
                        this.lppFieldsState.numberOfCgsNeeded = numberOfCgsNeeded;
                        this.lppFieldsState.numberOfDefinedCgs = numberOfDefinedCgs;
                        console.info(`"${this.lppFieldsState.name}" STAF file summary:\nLGC Ref: ${LcgReferenceEnum[shipData.lcgOptions.reference]} / VCG Ref: ${ValuesSourceRowTierEnum[shipData.vcgOptions.values]} / CGs: ${numberOfDefinedCgs}/${numberOfCgsNeeded}: ${(100 * numberOfDefinedCgs) / roundDec(numberOfCgsNeeded, 2)}%`);
                        showOtherFields(this.lppFieldsState, this.i18nCR, this.imoApiState);
                        if (this.internalMatch) {
                            const match = this.internalMatch;
                            tedivoForm
                                .setValue("shipName", match.SHIPNAME)
                                .setValue("callSign", match.CALLSIGN || "N/A")
                                .setValue("yearBuilt", Number((_a = match.BUILT) !== null && _a !== void 0 ? _a : 0));
                        }
                    }))
                        .catch((e) => {
                        this.showError(e);
                        console.error(e);
                        tedivoForm.getFormControlsByName().fileInput
                            .field.reset();
                    });
                    break;
                case "imoCode":
                    if (values.imoCode) {
                        loadingBlocker.show();
                        const [nameInfo, searchResults] = yield Promise.all([
                            Services.vesselsMasterData.searchVesselByImoCode(values.imoCode),
                            Services.vesselSearch.search(`'${values.imoCode}'`, ["imoCode"]),
                        ]);
                        const tvlResults = ((_a = searchResults === null || searchResults === void 0 ? void 0 : searchResults.data) === null || _a === void 0 ? void 0 : _a.filter((r) => r.item.organizationId !==
                            securityModule.currentOrganizationId)) || [];
                        loadingBlocker.hide();
                        if (((_b = nameInfo === null || nameInfo === void 0 ? void 0 : nameInfo.data) === null || _b === void 0 ? void 0 : _b.source) === "error") {
                            if (tedivoForm.execValidation(["imoCode", "fileInput"]).success) {
                                this.imoApiState = ImoFoundResultEnum.NOT_FOUND_CONTINUE;
                                showOtherFields(this.lppFieldsState, this.i18nCR, this.imoApiState);
                            }
                        }
                        const match = (_c = nameInfo === null || nameInfo === void 0 ? void 0 : nameInfo.data) === null || _c === void 0 ? void 0 : _c.match;
                        this.internalMatch = match;
                        if (!match) {
                            createNotFoundImoDialog({
                                parentNode: this,
                                imoCode: values.imoCode || "",
                                onButtonClicked: (btnSelected) => {
                                    switch (btnSelected) {
                                        case "continue":
                                            this.imoApiState = ImoFoundResultEnum.NOT_FOUND_CONTINUE;
                                            break;
                                        case "fix":
                                            this.imoApiState = ImoFoundResultEnum.NOT_FOUND_FIX;
                                            setTimeout(() => {
                                                focusAndSelect(tedivoForm.getFormControlsByName().imoCode
                                                    .field);
                                            }, 150);
                                            break;
                                    }
                                    if (tedivoForm.execValidation(["imoCode", "fileInput"]).success) {
                                        showOtherFields(this.lppFieldsState, this.i18nCR, this.imoApiState);
                                    }
                                },
                            });
                        }
                        else {
                            createFoundImoDialog({
                                parentNode: this,
                                imoCode: values.imoCode || "",
                                btnSeeTvlSearchResults: tvlResults.length > 0,
                                lastFetched: String(match.modifiedAt),
                                shipName: match.SHIPNAME,
                                onButtonClicked: (btnSelected) => {
                                    var _a, _b, _c;
                                    switch (btnSelected) {
                                        case "continue":
                                            this.imoApiState = ImoFoundResultEnum.FOUND_CONTINUE;
                                            this.lppFieldsState.shipName = match.SHIPNAME;
                                            this.lppFieldsState.vmdShipNameLatest = match.SHIPNAME;
                                            this.lppFieldsState.lppFromApi =
                                                Number(((_a = match.mtParticularsJson) === null || _a === void 0 ? void 0 : _a.LENGTH_B_W_PERPENDICULARS) ||
                                                    0) * 1000;
                                            this.lppFieldsState.loaFromApi =
                                                Number(((_b = match.mtParticularsJson) === null || _b === void 0 ? void 0 : _b.LENGTH_OVERALL) || 0) *
                                                    1000;
                                            // Show
                                            if (tedivoForm.execValidation(["imoCode", "fileInput"])
                                                .success) {
                                                showOtherFields(this.lppFieldsState, this.i18nCR, this.imoApiState);
                                            }
                                            // Set fields
                                            tedivoForm
                                                .setValue("shipName", match.SHIPNAME)
                                                .setValue("callSign", match.CALLSIGN || "N/A")
                                                .setValue("yearBuilt", Number((_c = match.BUILT) !== null && _c !== void 0 ? _c : 0))
                                                .setValue("lpp", this.lppFieldsState.lppFromApi)
                                                .setValue("loa", this.lppFieldsState.loaFromApi);
                                            break;
                                        case "fix":
                                            this.imoApiState = ImoFoundResultEnum.FOUND_FIX_IMO;
                                            if (tedivoForm.execValidation(["imoCode", "fileInput"])
                                                .success) {
                                                showOtherFields(this.lppFieldsState, this.i18nCR, this.imoApiState);
                                            }
                                            focusAndSelect(tedivoForm.getFormControlsByName().imoCode
                                                .field);
                                            break;
                                        case "doubleCheck":
                                            this.imoApiState = ImoFoundResultEnum.FOUND_MANUAL_SET;
                                            loadingBlocker.show();
                                            Services.vesselsMasterData
                                                .searchVesselByImoCode(values.imoCode || "", true)
                                                .then((doucleCheckNameInfo) => {
                                                var _a;
                                                loadingBlocker.hide();
                                                const newMatch = (_a = doucleCheckNameInfo === null || doucleCheckNameInfo === void 0 ? void 0 : doucleCheckNameInfo.data) === null || _a === void 0 ? void 0 : _a.match;
                                                if (newMatch) {
                                                    this.lppFieldsState.shipName = newMatch.SHIPNAME;
                                                    this.lppFieldsState.vmdShipNameLatest =
                                                        match.SHIPNAME;
                                                    tedivoForm.setValue("shipName", newMatch.SHIPNAME);
                                                }
                                            })
                                                .catch(() => {
                                                loadingBlocker.hide();
                                            });
                                            break;
                                        case "seeSearchResults":
                                            router.navigate(routeFns.communityFiles(), {
                                                searchText: values.imoCode,
                                                fields: ["imoCode"],
                                                data: searchResults.data,
                                            });
                                            break;
                                    }
                                },
                            });
                        }
                    }
                    break;
                case "removeCGs": {
                    const fields = tedivoForm.getFormControlsByName();
                    const disabledValue = values.removeCGs ? "true" : "false";
                    (_d = fields.lpp) === null || _d === void 0 ? void 0 : _d.field.setAttribute("disabled", disabledValue);
                    (_e = fields.loa) === null || _e === void 0 ? void 0 : _e.field.setAttribute("disabled", disabledValue);
                }
            }
            function showOtherFields(lppFieldsState, i18nCR, imoApiState) {
                const { formWithLpp, numberOfDefinedCgs, name, shipClass, shipName } = lppFieldsState;
                const doContinue = imoApiState === ImoFoundResultEnum.FOUND_CONTINUE ||
                    imoApiState === ImoFoundResultEnum.FOUND_MANUAL_SET ||
                    imoApiState === ImoFoundResultEnum.NOT_FOUND_CONTINUE;
                if (!doContinue)
                    return;
                me.readyToSubmit = true;
                const nameFields = splitNameForMainNames(name || "", shipClass || "");
                if (shipName)
                    nameFields.shipName = shipName;
                const newFields = createFormFields({
                    imoCode: values.imoCode || "",
                    fileLabel: name,
                    withLpp: formWithLpp && numberOfDefinedCgs > 0,
                    withNameFields: nameFields,
                });
                tedivoForm.setFields(newFields).setFormValidator(createFormValidator({
                    withLpp: formWithLpp && numberOfDefinedCgs > 0,
                    withNameFields: true,
                }));
                const divButtons = document.createElement("div");
                divButtons.className = "form-buttons";
                divButtons.appendChild(submitButton);
                tedivoForm.form.appendChild(divButtons);
                translateTedivoForm({
                    tedivoForm,
                    i18nCR,
                });
                submitButton.disabled = false;
            }
        });
        box.appendChild(tedivoForm.form);
        const divButtons = document.createElement("div");
        divButtons.className = "form-buttons";
        divButtons.appendChild(nextButton);
        tedivoForm.form.appendChild(divButtons);
        wrapper.appendChild(h1);
        wrapper.appendChild(box);
        this.appendChild(wrapper);
        // // Translations
        const i18nCR = this.i18nCR;
        i18nCR.addConsumer(h1, "menu:fileImportStaf", "innerHTML");
        i18nCR.addConsumer(topMessageElement.element, "menu:fileImportStaf", "innerHTML");
        nextButton.addEventListener("click", () => {
            var _a;
            (_a = tedivoForm.onDataChange) === null || _a === void 0 ? void 0 : _a.call(tedivoForm, tedivoForm.getValues(), "imoCode");
        });
        document.documentElement.addEventListener("globalUnitsChanged", () => {
            const lppField = tedivoForm.getFormControlsByName().lpp;
            if (lppField)
                i18nCR.addConsumer(lppField.field, `enums:LenUnitsEnum.${LenUnitsEnum[globalUnits.lengthUnits.units]}`, "helpText");
        });
    }
    disconnectedCallback() {
        this.i18nCR.disconnect();
    }
    getOwnFiles() {
        return __awaiter(this, void 0, void 0, function* () {
            const lastUpdated = this.ownFiles.lastUpdated;
            //Fetch if first-time or cache is > 5s
            if (lastUpdated === 0 || Date.now() - lastUpdated > 5000) {
                const data = yield Services.files.getAll();
                if (data.statusCode === 200 && data.data !== undefined) {
                    this.ownFiles.list = data.data;
                    this.ownFiles.lastUpdated = Date.now();
                }
            }
        });
    }
}
TVDImportStafComponent.observedAttributes = [];
customElements.define("tvd-import-staf-component", TVDImportStafComponent);
