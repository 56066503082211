import {
  CELL_ATTRIBUTES,
  INodesOptions,
  IReturnDrawFunction,
  SYMBOL_NAMES,
  createSvgText,
} from "@tedivo/tedivo-bay-grid-core";
import { ISlotData, TContainerLengths } from "open-vessel-definition";

import { ISlotCombinedPattern } from "@tedivo/tedivo-bay-grid-pure";
import SizeSmallMidLargeEnum from "../../../../../app/enums/SizeSmallMidLargeEnum";
import { calculateSvgWidth } from "@tedivo/tedivo-dom-helpers";

const internalEmphLabelsCache: Record<string, SVGElement> = {};
const internalTextCache: Record<string, { node: SVGElement; width: number }> =
  {};
const LABELS_SEPARATION = 2;

export function createFnSlotEmphasizedCell(
  cellSize: SizeSmallMidLargeEnum,
  symbolPrefix: string,
  nodesOptions: INodesOptions,
) {
  return function createSlotEmphasizedCell(
    pos: ISlotCombinedPattern,
    slotData: ISlotData | undefined,
  ): IReturnDrawFunction {
    const emph = document.createElementNS("http://www.w3.org/2000/svg", "use");

    emph.setAttribute("href", `#${symbolPrefix}${SYMBOL_NAMES.EMPHASIZED}`);

    let emphLabel: SVGGElement | undefined = undefined;

    if (slotData) {
      const legendParts: Array<{ t: string; color: string }> =
        slotData.restricted
          ? [{ t: "-", color: CELL_ATTRIBUTES.restricted.restricted.bgColor }]
          : ([
              ...Object.keys(slotData.sizes || {})
                .sort()
                .map((size) => ({
                  t: size,
                  color:
                    CELL_ATTRIBUTES.sizes[Number(size) as TContainerLengths]
                      .bgColor,
                })),
              slotData.reefer
                ? { t: "r", color: CELL_ATTRIBUTES.misc.reefer.bgColor }
                : undefined,
            ].filter(Boolean) as Array<{ t: string; color: string }>);

      const legendText = legendParts.map((s) => s.t).join(",");

      if (internalEmphLabelsCache[legendText]) {
        emphLabel = internalEmphLabelsCache[legendText].cloneNode(
          true,
        ) as SVGGElement;
      } else {
        let labelX = 0;

        const emphLabelGroup = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "g",
        );

        legendParts.map((part) => {
          const isRestricted = part.t === "-";
          const textColor = !isRestricted
            ? part.color || nodesOptions.emphasizeLineColor || "#777"
            : "#222";

          if (!internalTextCache[part.t]) {
            if (isRestricted) {
              const emphLabelBg = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "path",
              );

              emphLabelBg.setAttribute("d", `M0,-3 h8 v2 h-8 z`);
              emphLabelBg.setAttribute("fill", part.color);

              internalTextCache[part.t] = { node: emphLabelBg, width: 8 };
            } else {
              const textNode = createSvgText({
                x: 0,
                y: 0,
                text: part.t,
                fontSize: 5,
                textColor,
                textAnchor: "start",
              });

              const { width } = calculateSvgWidth(part.t, 5);
              internalTextCache[part.t] = { node: textNode, width };
            }

            const node = internalTextCache[part.t].node.cloneNode(
              true,
            ) as SVGGElement;
            const width = internalTextCache[part.t].width;

            node.setAttribute("x", labelX.toString());
            emphLabelGroup.appendChild(node);
            labelX += width + LABELS_SEPARATION;
          } else {
            const { node, width } = internalTextCache[part.t];

            node.setAttribute("x", labelX.toString());
            emphLabelGroup.appendChild(node.cloneNode(true));
            labelX += width + LABELS_SEPARATION;
          }
        });

        internalEmphLabelsCache[legendText] = emphLabelGroup;
        emphLabel = emphLabelGroup;
      }
    }

    return {
      node: emph,
      nodeLabel: emphLabel,
      pos,
    };
  };
}
