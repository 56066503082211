export function handleDoubleClick(
  node: SVGElement | HTMLElement,
  fn: (ev: SVGElement | HTMLElement) => void,
) {
  let lastClicked = 0;
  node.addEventListener(
    "click",
    () => {
      const diff = Date.now() - lastClicked;

      if (diff < 500) {
        fn(node);
      }

      lastClicked = Date.now();
    },
    false,
  );
}
