import { stylesFromStringToTag } from "@tedivo/tedivo-dom-helpers";
export class DeleteButtonWithConfirm extends HTMLElement {
    constructor() {
        super();
        this.confirmDelete = undefined;
        this.deleteConfirmationDialog = undefined;
        this.buttonText = "Delete";
        this.confirmDeleteText = "Confirm deletion?";
        this.cancelText = "Cancel";
        this.onConfirmDelete = () => null;
    }
    connectedCallback() {
        const inlineDeleteButton = this.slot === "header-actions"
            ? document.createElement("sl-icon-button")
            : document.createElement("sl-button");
        // First button
        if (this.slot === "header-actions") {
            inlineDeleteButton.name = "trash";
            inlineDeleteButton.slot = "header-actions";
            inlineDeleteButton.title = this.buttonText;
            inlineDeleteButton.classList.add("danger");
        }
        else {
            inlineDeleteButton.slot = "footer";
            inlineDeleteButton.innerHTML = this.buttonText;
            inlineDeleteButton.tabIndex = 0;
            inlineDeleteButton.variant = "danger";
            inlineDeleteButton.type = "button";
            const icon = document.createElement("sl-icon");
            icon.name = "trash";
            icon.slot = "prefix";
            inlineDeleteButton.appendChild(icon);
        }
        this.appendChild(inlineDeleteButton);
        // Confirm dialog and confirm button
        const deleteConfirmationDialog = this.createConfirmDeleteDialog();
        /** Show the confirmatipn dialog */
        inlineDeleteButton.onclick = function () {
            deleteConfirmationDialog.show();
        };
        this.appendChild(stylesFromStringToTag(styles));
    }
    createConfirmDeleteDialog() {
        const confirmationDialog = document.createElement("sl-dialog");
        confirmationDialog.classList.add("higher-modal-z-index");
        /** Set the dialog label */
        confirmationDialog.label = this.buttonText;
        /** Set the dialog content */
        confirmationDialog.appendChild(document.createTextNode(this.confirmDeleteText));
        /** Create the final delete button */
        const confirmDeleteButton = document.createElement("sl-button");
        confirmDeleteButton.slot = "footer";
        confirmDeleteButton.innerText = this.buttonText;
        confirmDeleteButton.variant = "danger";
        const icon = document.createElement("sl-icon");
        icon.name = "trash";
        icon.slot = "prefix";
        confirmDeleteButton.appendChild(icon);
        confirmationDialog.appendChild(confirmDeleteButton);
        const cancelDeleteButton = document.createElement("sl-button");
        cancelDeleteButton.slot = "footer";
        cancelDeleteButton.innerText = this.cancelText;
        confirmationDialog.appendChild(cancelDeleteButton);
        /** Execute deletion callback */
        confirmDeleteButton.onclick = () => {
            this.onConfirmDelete();
            confirmationDialog.hide();
        };
        /** Never mind */
        cancelDeleteButton.onclick = () => {
            confirmationDialog.hide();
        };
        document.body.appendChild(confirmationDialog);
        this.deleteConfirmationDialog = confirmationDialog;
        return confirmationDialog;
    }
    disconnectedCallback() {
        var _a, _b;
        (_a = this.confirmDelete) === null || _a === void 0 ? void 0 : _a.remove();
        (_b = this.deleteConfirmationDialog) === null || _b === void 0 ? void 0 : _b.remove();
    }
}
customElements.define("delete-button", DeleteButtonWithConfirm);
const styles = `
  .danger {
    color: var(--sl-color-danger-500);
  }
`;
