import { IVesselOneBaySlot } from "../../../../../../../../../components/features/view-json/vessel3D/types/IVesselOneBaySlot";

export function findComposedRefId(
  slotRefId: string,
  calculatedSlots: IVesselOneBaySlot[],
) {
  const isBay = !isNaN(Number(slotRefId));

  if (!isBay) return slotRefId;

  for (let i = 0; i < calculatedSlots.length; i++) {
    const s = calculatedSlots[i];
    const bays = s.id.split("-");
    if (bays.indexOf(slotRefId) >= 0) return s.id;
  }

  return "";
}
