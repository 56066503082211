import routes, { routeFns, routesKeys } from "./routes";

import { Router } from "@tedivo/tedivo-router";
import { createErrorNotification } from "../notifications/createNotification";
import { getTranslation } from "../i18/i18tn";
import { removeChildren } from "@tedivo/tedivo-dom-helpers";
import securityModule from "../security/SecurityModule";
import { systemRights } from "@tedivo/tvd-api-models";

const ID_OF_MAIN_CONTENT = "st-main-content";
const ID_OF_SIDEBAR = "st-sidebar";

// Initialize TedivoRouter
const router = new Router();

router.forceRenderOfPaths = [routeFns.login()];

routesKeys.forEach((path) => {
  router.addRoute(path, () =>
    document.createElement(routes[path].componentName),
  );
});

const routerState = {
  tmt: 0,
  fulfilledRequests: {} as Record<number, boolean>,
};

router.basePath = "";

router.onChange = (
  route: string,
  rounteKey: string,
  elFn: () => HTMLElement,
  hideMenu?: boolean,
) => {
  const cNode = document.getElementById(ID_OF_MAIN_CONTENT);
  const cMenu = document.getElementById(ID_OF_SIDEBAR);

  if (!cNode || !cMenu) return;

  cMenu.style.display = hideMenu ? "none" : "block";

  removeChildren(cNode);

  const lastRequest = Date.now();

  // Get info (for systemRights)
  const routeInfo = routes[rounteKey];

  if (!routeInfo) {
    console.error(`Route "${rounteKey}" not found`);
    return;
  }

  // Render component if no security is required
  if (routeInfo.securityRight === systemRights.ANONYMOUS) {
    renderComponent();
    return;
  }

  if (routeInfo.securityRight === systemRights.LOGGED_IN) {
    if (securityModule.isLoggedIn) {
      renderComponent();
      return;
    }

    router.navigate(routeFns.login(router.currentPathname));
    return;
  }

  // Security is required. Is he logged in?
  if (!securityModule.isLoggedIn) {
    router.navigate(routeFns.login(router.currentPathname));
    return;
  }

  if (!securityModule.isLoginInfoComplete) {
    window.clearTimeout(routerState.tmt);
    routerState.tmt = window.setTimeout(() => {
      if (router.onError) router.onError();
    }, 10000);

    const onUserDataRetrieved = () => {
      if (checkRightOrShowNotAccessAllowed()) renderComponent();
      securityModule.removeEventListener(
        "userDataRetrieved",
        onUserDataRetrieved,
      );
    };

    securityModule.addEventListener("userDataRetrieved", onUserDataRetrieved);
  } else {
    if (checkRightOrShowNotAccessAllowed()) renderComponent();
  }

  function checkRightOrShowNotAccessAllowed(): boolean {
    if (securityModule.userHasPermission(routeInfo.securityRight)) return true;

    const pageTitle = routeInfo.componentTitle
      ? `: ${getTranslation(routeInfo.componentTitle)}`
      : "";

    router.navigate("/", undefined, true).then(() => {
      createErrorNotification(
        `${getTranslation("errors:notAllowed")}${pageTitle}`,
      );
    });
    return false;
  }

  function renderComponent() {
    window.clearTimeout(routerState.tmt);
    if (!routerState.fulfilledRequests[lastRequest]) {
      routerState.fulfilledRequests[lastRequest] = true;
      const newEl = elFn();
      newEl.dataset.pathName = route;
      (cNode as HTMLElement).appendChild(newEl);
      router.dispatchEvent(new CustomEvent("navigationComponentChanged"));
    }
  }
};

router.onError = () => {
  console.error("Error on router");
  router.navigate(routeFns.error());
};

router.onRouteNotFound = () => {
  console.error("Route not found");
  router.navigate(routeFns.myCloud());
};

export default router;
