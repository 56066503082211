import "@shoelace-style/shoelace/dist/components/avatar/avatar";
import "@shoelace-style/shoelace/dist/components/button/button";
import "@shoelace-style/shoelace/dist/components/icon/icon";
import "@shoelace-style/shoelace/dist/components/menu-item/menu-item";
import "@shoelace-style/shoelace/dist/components/menu/menu";
import "./tvd-menu.element.scss";
import MenuDefinition, { TOGGLE_CHILDREN } from "./MenuDefinition";
// import goSquared from "../../../app/tracking/goSquared";
import { getTranslation, i18nReactive } from "../../../app/i18/i18tn";
import { I18nComponentRegisterer } from "@tedivo/tedivo-i18";
import awsCognito from "../../../app/security/awsCognito";
import { createMenuList } from "@tedivo/tedivo-form";
import globalStore from "../../../app/stores/globalStore";
import { onOffLineObserver } from "../../../app/offline/observer/OnOffLineObserver";
import { removeChildren } from "@tedivo/tedivo-dom-helpers";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import securityModule from "../../../app/security/SecurityModule";
export class TVDMenuElement extends HTMLElement {
    constructor() {
        super();
        this.avatar = undefined;
        this.currentColorMode = globalStore.resolvedColorMode || "light";
        this.onDisconnect = [];
        this.drawMenuItems = () => {
            const menu = this.ulMenu;
            removeChildren(menu);
            MenuDefinition.filter((menuItem) => (hasSystemRight(menuItem) && !menuItem.isHiddenInReadOnly) ||
                (menuItem.isHiddenInReadOnly &&
                    !securityModule.planIsReadOnly &&
                    securityModule.isLoggedIn)).forEach(({ action, label, icon, iconSrc, iconSrcDark, menuItems, actionBase, }) => {
                const menuItem = document.createElement("li");
                menuItem.role = "menuitem";
                menuItem.setAttribute("data-action", actionBase || action || "");
                const menuButton = document.createElement("sl-button");
                menuButton.value = action;
                menuButton.className = "main-option";
                i18nReactive.addConsumer(menuButton, label, "title");
                const iconEl = document.createElement("sl-icon");
                iconEl.slot = "prefix";
                i18nReactive.addConsumer(iconEl, label, "label");
                menuButton.appendChild(iconEl);
                if (iconSrc) {
                    iconEl.setAttribute("src", this.currentColorMode === "light"
                        ? iconSrc
                        : iconSrcDark || iconSrc);
                    iconEl.style.fontSize = "1.25rem";
                    iconEl.classList.add("custom-icon");
                    menuButton.classList.add("custom-icon-button");
                }
                else {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    iconEl.setAttribute("name", icon);
                }
                menuItem.appendChild(menuButton);
                const hasChildren = !!menuItems && menuItems.length > 0;
                if (!hasChildren) {
                    menu.appendChild(menuItem);
                    return; // --> FAST RETURN - NO CHILDREN
                }
                const effectiveMenuItems = menuItems.filter(({ systemRights }) => systemRights === null || systemRights === void 0 ? void 0 : systemRights.reduce((acc, sr) => {
                    return acc && securityModule.userHasPermission(sr);
                }, true));
                if (effectiveMenuItems.length === 0)
                    return; // --> FAST RETURN - NO CHILDREN - NO SYSTEM RIGHTS
                const { menu: subMenu } = createMenuList({
                    options: menuItems.map((mi) => ({
                        name: getTranslation(mi.label),
                        value: mi.action,
                        icon: mi.icon,
                    })),
                    ommitCheckSign: true,
                    currentValue: undefined,
                });
                menuItem.appendChild(subMenu);
                menu.appendChild(menuItem);
            });
            this.setActiveRoute();
        };
        this.attachEvents = () => {
            this.ulMenu.addEventListener("click", (ev) => {
                const target = ev.target;
                const divClickOutside = this.divClickOutside;
                if (!target ||
                    !(target.nodeName === "SL-BUTTON" ||
                        target.nodeName === "BUTTON" ||
                        target.nodeName === "SL-MENU-ITEM"))
                    return;
                if (target.value === TOGGLE_CHILDREN) {
                    if (target.nextSibling) {
                        const ulSubMenu = target.nextSibling;
                        const isOpened = divClickOutside.dataset.opened === "1";
                        if (isOpened) {
                            // opened now, then close
                            divClickOutside.dataset.opened = "0";
                            ulSubMenu.dataset.opened = "0";
                            this.ulOpenedSubMenu = undefined;
                        }
                        else {
                            // do open
                            divClickOutside.dataset.opened = "1";
                            ulSubMenu.dataset.opened = "1";
                            this.ulOpenedSubMenu = ulSubMenu;
                        }
                    }
                }
                else {
                    if (this.onOptionSelected)
                        this.onOptionSelected(target.value);
                    this.toggleOff();
                }
            });
            this.divClickOutside.addEventListener("click", this.toggleOff, false);
            securityModule.addEventListener("userDataRetrieved", this.onUpdateSources);
            awsCognito.addEventListener("sessionTimedOut", this.onUpdateSources);
            router.addEventListener("navigationComponentChanged", this.setActiveRoute);
            document.documentElement.addEventListener("colorModeChanged", this.onColorModeChanged);
            document.documentElement.addEventListener("keydown", this.onEscape);
            const updateI18Consumers = () => {
                i18nReactive.updateI18Consumers(false);
            };
            securityModule.addEventListener("loggedIn", updateI18Consumers);
            securityModule.addEventListener("loggedOut", updateI18Consumers);
            this.onDisconnect.push(() => {
                securityModule.removeEventListener("loggedIn", updateI18Consumers);
                securityModule.removeEventListener("loggedOut", updateI18Consumers);
            });
        };
        this.onColorModeChanged = ((ev) => {
            this.currentColorMode = ev.detail.mode;
            this.drawMenuItems();
        });
        this.onUpdateSources = (() => {
            this.updateAvatar();
            this.drawMenuItems();
        });
        this.onEscape = (ev) => {
            if (ev.key === "Escape") {
                this.toggleOff();
            }
        };
        this.updateAvatar = () => {
            if (this.avatar) {
                this.avatar.setAttribute("initials", securityModule.initials);
            }
        };
        this.toggleOff = () => {
            this.divClickOutside.dataset.opened = "0";
            if (this.ulOpenedSubMenu) {
                this.ulOpenedSubMenu.dataset.opened = "0";
                this.ulOpenedSubMenu = undefined;
            }
        };
        this.setActiveRoute = () => {
            if (!this.ulMenu.children)
                return;
            const menuChildren = Array.from(this.ulMenu.children);
            menuChildren.forEach((c) => {
                const routeTo = c.getAttribute("data-action");
                const btn = c.getElementsByTagName("sl-button")[0];
                if (router.isLocationRouteActive(window.location.pathname, routeTo)) {
                    if (btn) {
                        btn.variant = "primary";
                        btn.outline = true;
                    }
                }
                else {
                    if (btn) {
                        btn.variant = "default";
                        btn.outline = false;
                    }
                }
            });
        };
        this.i18nCR = new I18nComponentRegisterer(i18nReactive);
        this.ulMenu = document.createElement("ul");
        this.ulMenu.className = "ul-main-menu";
        this.ulMenu.setAttribute("role", "menu");
        this.divClickOutside = document.createElement("div");
        this.divClickOutside.className = "click-outside";
    }
    connectedCallback() {
        const clickOutside = this.divClickOutside;
        this.drawMenuItems();
        // Avatar
        const profileTooltip = document.createElement("sl-tooltip");
        profileTooltip.placement = "bottom-end";
        profileTooltip.className = "menu-avatar";
        const loginLink = document.createElement("sl-button");
        loginLink.circle = true;
        loginLink.addEventListener("click", () => {
            router.navigate(securityModule.isLoggedIn ? routeFns.myProfile() : routeFns.login());
        });
        const avatar = document.createElement("sl-avatar");
        avatar.initials = securityModule.initials;
        this.avatar = avatar;
        profileTooltip.appendChild(loginLink);
        loginLink.appendChild(avatar);
        // Off line
        const offlineTooltip = document.createElement("sl-tooltip");
        offlineTooltip.className = "offline-icon";
        offlineTooltip.placement = "bottom-end";
        offlineTooltip.style.display = onOffLineObserver.isOnline
            ? "none"
            : "block";
        const offLineIcon = document.createElement("sl-icon");
        offLineIcon.setAttribute("name", "wifi-off");
        offlineTooltip.appendChild(offLineIcon);
        onOffLineObserver.subscribe((isOnline) => {
            offlineTooltip.style.display = isOnline ? "none" : "block";
        });
        // Holder
        const divColumn = document.createElement("div");
        divColumn.className = "column";
        divColumn.appendChild(this.ulMenu);
        divColumn.appendChild(profileTooltip);
        divColumn.appendChild(offlineTooltip);
        this.appendChild(clickOutside);
        this.appendChild(divColumn);
        // this.addChatButton();
        this.divClickOutside = clickOutside;
        this.i18nCR
            .addConsumer(profileTooltip, () => (securityModule.isLoggedIn ? "auth:openMyProfile" : "auth:logIn"), "content")
            .addConsumer(offlineTooltip, "general:onOffLine:offline", "content");
        this.attachEvents();
    }
    disconnectedCallback() {
        securityModule.removeEventListener("userDataRetrieved", this.onUpdateSources);
        awsCognito.removeEventListener("sessionTimedOut", this.onUpdateSources);
        document.documentElement.removeEventListener("colorModeChanged", this.onColorModeChanged);
        document.documentElement.removeEventListener("keydown", this.onEscape);
        router.removeEventListener("navigationComponentChanged", this.setActiveRoute);
        this.onDisconnect.forEach((fn) => fn());
    }
}
TVDMenuElement.observedAttributes = [];
customElements.define("tvd-menu-component", TVDMenuElement);
function hasSystemRight(menuItem) {
    var _a, _b;
    if (menuItem.action === TOGGLE_CHILDREN) {
        const isAllowed = (_a = menuItem.menuItems) === null || _a === void 0 ? void 0 : _a.some((ch) => {
            var _a;
            return (_a = ch.systemRights) === null || _a === void 0 ? void 0 : _a.reduce((acc, sr) => {
                return acc && securityModule.userHasPermission(sr);
            }, true);
        });
        return !!isAllowed;
    }
    else {
        return !!((_b = menuItem.systemRights) === null || _b === void 0 ? void 0 : _b.reduce((acc, sr) => {
            return acc && securityModule.userHasPermission(sr);
        }, true));
    }
}
