import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import securityModule from "../../../app/security/SecurityModule";

export class TvdWelcomeElement extends HTMLElement {
  public static observedAttributes = [];

  constructor() {
    super();
  }

  connectedCallback() {
    if (securityModule.isLoggedIn) {
      router.navigate(routeFns.myCloud());
    } else {
      router.navigate(routeFns.login(), undefined, true);
    }
  }
}

customElements.define("tvd-welcome-component", TvdWelcomeElement);

declare global {
  interface HTMLElementTagNameMap {
    "tvd-welcome-component": TvdWelcomeElement;
  }
}
