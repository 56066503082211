import { ForeAftEnum } from "open-vessel-definition";
import { getTranslation } from "../../../../../app/i18/i18tn";
export function getHeaderDetailPills(blA, blB, validationResult) {
    const holder = document.createElement("div");
    holder.setAttribute("part", "details-holder");
    if (blA === undefined && blB === undefined) {
        return holder;
    }
    const foreAftTranslations = {
        [ForeAftEnum.AFT]: getTranslation(`enums:ForeAftEnum.AFT`),
        [ForeAftEnum.FWD]: getTranslation(`enums:ForeAftEnum.FWD`),
        [0]: getTranslation(`general:common.unknown`),
    };
    const arrowDirection = {
        [ForeAftEnum.AFT]: "→",
        [ForeAftEnum.FWD]: "←",
        [0]: "?",
    };
    const yesNoTranslations = (v) => getTranslation(v ? "general:common.yes" : "general:common.no");
    const yesNoSymbols = (v) => (v ? "✓" : "&times;");
    const allBaysWith40sArePairedValidatorResult = validationResult.find((v) => v.name === "allBaysWith40sArePairedValidator");
    drawSingleDetail(blA, blB, {
        key: "pairedBay",
        labelKey: "view:details.paired",
        iconName: "link",
        textStrFn: (a) => foreAftTranslations[(a === null || a === void 0 ? void 0 : a.pairedBay) || 0],
        textIconFn: (a) => ({
            content: arrowDirection[(a === null || a === void 0 ? void 0 : a.pairedBay) || 0],
            nodeType: "span",
            className: "bay-arrow-direction",
        }),
        warningFn: (a, b) => {
            if (allBaysWith40sArePairedValidatorResult) {
                return allBaysWith40sArePairedValidatorResult.result.invalidResults.some((r) => (r.bay === (a === null || a === void 0 ? void 0 : a.isoBay) && a.level === r.level) ||
                    (r.bay === (b === null || b === void 0 ? void 0 : b.isoBay) && b.level === r.level));
            }
            return false;
        },
    });
    drawSingleDetail(blA, blB, {
        key: "reeferPlugs",
        labelKey: "view:details.reeferPlugs",
        iconName: "plug",
        undefinedIsZero: true,
        textStrFn: (a) => foreAftTranslations[(a === null || a === void 0 ? void 0 : a.reeferPlugs) || 0],
        textIconFn: (a) => ({ content: arrowDirection[(a === null || a === void 0 ? void 0 : a.reeferPlugs) || 0] }),
    });
    drawSingleDetail(blA, blB, {
        key: "doors",
        labelKey: "view:details.doors",
        iconName: "door-closed",
        undefinedIsZero: true,
        textStrFn: (a) => foreAftTranslations[(a === null || a === void 0 ? void 0 : a.doors) || 0],
        textIconFn: (a) => ({ content: arrowDirection[(a === null || a === void 0 ? void 0 : a.doors) || 0] }),
    });
    drawSingleDetail(blA, blB, {
        key: "telescoping",
        labelKey: "view:details.telescoping",
        iconName: "arrow-left-right",
        ommitBothTrue: true,
        undefinedIsZero: true,
        textStrFn: (a) => yesNoTranslations(!!(a === null || a === void 0 ? void 0 : a.telescoping)),
        textIconFn: (a) => ({ content: yesNoSymbols(!!(a === null || a === void 0 ? void 0 : a.telescoping)) }),
    });
    drawNotesIcon(blA, blB, {
        labelKey: "view:edit.bayLevelData.notes",
        iconName: "journal-text",
    });
    return holder;
    function appendTextWithIcon(node, { content, nodeType, className, }) {
        if (nodeType) {
            const n = document.createElement(nodeType);
            n.className = className || "";
            n.innerHTML = content;
            n.setAttribute("part", className || "");
            node.appendChild(n);
        }
        else {
            node.appendChild(document.createTextNode(content));
        }
    }
    function drawSingleDetail(blA, blB, { iconName, labelKey, key, ommitBothTrue, undefinedIsZero = false, textStrFn, textIconFn, warningFn, }) {
        if (blA === undefined && blB === undefined)
            return;
        const label = getTranslation(labelKey);
        const valA = !undefinedIsZero ? blA === null || blA === void 0 ? void 0 : blA[key] : (blA === null || blA === void 0 ? void 0 : blA[key]) || 0;
        const valB = !undefinedIsZero ? blB === null || blB === void 0 ? void 0 : blB[key] : (blB === null || blB === void 0 ? void 0 : blB[key]) || 0;
        if (valA && valB && valA === valB) {
            const commonBl = blA !== null && blA !== void 0 ? blA : blB;
            const title = `${label}: ${textStrFn(commonBl)}`;
            const badge = document.createElement("sl-badge");
            const icon = document.createElement("sl-icon");
            icon.setAttribute("name", iconName);
            icon.className = "icon-big";
            badge.title = title;
            badge.appendChild(icon);
            badge.variant = "neutral";
            badge.pill = true;
            if (!ommitBothTrue)
                appendTextWithIcon(badge, textIconFn(commonBl));
            holder.appendChild(badge);
            if (warningFn && warningFn(blA, blB)) {
                badge.variant = "warning";
            }
            else {
                badge.variant = "neutral";
            }
        }
        else if (valA !== valB) {
            const title = [
                blA
                    ? `${label} ${getTranslation(`enums:BayLevelEnum.ABOVE`)}: ${textStrFn(blA)}`
                    : undefined,
                blB
                    ? `${label} ${getTranslation(`enums:BayLevelEnum.BELOW`)}: ${textStrFn(blB)}`
                    : undefined,
            ]
                .filter(Boolean)
                .join("\n");
            const inners = [];
            if (blA) {
                const supNode = document.createElement("sup");
                appendTextWithIcon(supNode, textIconFn(blA));
                inners.push(supNode);
            }
            if (blB) {
                const subNode = document.createElement("sub");
                appendTextWithIcon(subNode, textIconFn(blB));
                inners.push(subNode);
            }
            const badge = document.createElement("sl-badge");
            const icon = document.createElement("sl-icon");
            icon.setAttribute("name", iconName);
            icon.className = "icon-big";
            const innerSpan = document.createElement("span");
            if (inners[0])
                innerSpan.appendChild(inners[0]);
            if (inners[0] && inners[1])
                innerSpan.appendChild(document.createTextNode("/"));
            if (inners[1])
                innerSpan.appendChild(inners[1]);
            badge.title = title;
            badge.appendChild(icon);
            badge.appendChild(innerSpan);
            if (warningFn && warningFn(blA, blB)) {
                badge.variant = "warning";
            }
            else {
                badge.variant = "neutral";
            }
            badge.pill = true;
            holder.appendChild(badge);
        }
    }
    function drawNotesIcon(blA, blB, { iconName, labelKey, }) {
        var _a, _b, _c, _d;
        if (!((_a = blA === null || blA === void 0 ? void 0 : blA.meta) === null || _a === void 0 ? void 0 : _a.notes) && !((_b = blB === null || blB === void 0 ? void 0 : blB.meta) === null || _b === void 0 ? void 0 : _b.notes)) {
            return;
        }
        const label = getTranslation(labelKey);
        const aboveNotes = (((_c = blA === null || blA === void 0 ? void 0 : blA.meta) === null || _c === void 0 ? void 0 : _c.notes) || "").trim();
        const belowNotes = (((_d = blB === null || blB === void 0 ? void 0 : blB.meta) === null || _d === void 0 ? void 0 : _d.notes) || "").trim();
        const title = [];
        if (aboveNotes) {
            title.push(`${label} ${getTranslation(`enums:BayLevelEnum.ABOVE`)}:`);
            title.push(`${aboveNotes}`);
        }
        if (belowNotes) {
            if (title.length > 0)
                title.push("");
            title.push(`${label} ${getTranslation(`enums:BayLevelEnum.BELOW`)}:`);
            title.push(`${belowNotes}`);
        }
        const contentHtml = document.createElement("div");
        contentHtml.innerHTML = title.join("\n").replace(/\n/g, "<br />");
        contentHtml.slot = "content";
        const slTooltip = document.createElement("sl-tooltip");
        slTooltip.appendChild(contentHtml);
        slTooltip.placement = "bottom-end";
        //${blA?.meta?.notes || ""}<br />${blB?.meta?.notes || ""}`;
        const badge = document.createElement("sl-badge");
        const icon = document.createElement("sl-icon");
        icon.setAttribute("name", iconName);
        icon.className = "icon-big";
        //badge.title = title.join("\n");
        badge.appendChild(icon);
        badge.variant = "success";
        badge.pill = true;
        slTooltip.appendChild(badge);
        holder.appendChild(slTooltip);
    }
}
