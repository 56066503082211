import { IEditBayFields } from "./bay-edit.component";
import TYesNoEnum from "../../../../../../app/enums/TYesNoEnum";
import { z } from "zod";

const booleanTYesNoSchema = z.boolean().transform((value) => {
  return value ? 1 : 0;
}) as unknown as z.ZodNativeEnum<typeof TYesNoEnum>;

export const bayDetailsFormValidator: z.Schema<IEditBayFields> = z.object({
  notes: z
    .string()
    .optional()
    .transform((v) => v?.trim()),

  label20: z
    .string()
    .optional()
    .transform((v) => v?.trim()),

  label40: z
    .string()
    .optional()
    .transform((v) => v?.trim()),

  reeferPlugs: z.number().optional(),
  doors: z.number().optional(),
  pairedBay: z.number().optional(),

  reeferPlugLimit: z.number().optional(),

  centerLineRow: booleanTYesNoSchema.optional(),
  athwartShip: booleanTYesNoSchema.optional(),
  foreHatch: booleanTYesNoSchema.optional(),
  ventilated: booleanTYesNoSchema.optional(),

  heatSrcFore: booleanTYesNoSchema.optional(),
  ignitionSrcFore: booleanTYesNoSchema.optional(),
  quartersFore: booleanTYesNoSchema.optional(),
  engineRmBulkFore: booleanTYesNoSchema.optional(),
  telescoping: booleanTYesNoSchema.optional(),
});
