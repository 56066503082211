import { BayLevelEnum, IBayLevelData } from "open-vessel-definition";
import {
  IBayBlocksAndSizes,
  IGetBayBlocksOutput,
} from "../../types/IBayBlocksAndSizes";
import { createDictionary, sortNumericAsc } from "@tedivo/tedivo-pure-helpers";

import { IBayPattern } from "../../types/IPositionPatterns";
import { IGetPairedBaysOutput } from "../../getAllThePairedBays";
import { getSizesFromSlots } from "../../getSizesFromSlots";

export function getBayBlocks(
  bls: IBayLevelData[],
  pairedBaysCalc: IGetPairedBaysOutput,
): IGetBayBlocksOutput {
  const blsDict: Record<string, IBayLevelData> = createDictionary(
    bls,
    (b) => `${b.isoBay}-${b.level}`,
  );

  const allIsoBays = bls
    .map((b) => b.isoBay)
    .filter((v, i, a) => a.indexOf(v) === i)
    .sort(sortNumericAsc)
    .map((b) => ({ isoBay: b }));

  const blockBaysAndSizesKeys = calcBlockBaysAndSizesKeys(pairedBaysCalc);

  const blockBaysAndSizes: IBayBlocksAndSizes[] = Array.from(
    blockBaysAndSizesKeys,
  )
    .sort()
    .map((b) => ({
      allBays: b,
      base: b.split("-")[0] as IBayPattern,
      blockSize: 20,
      maxSizes: {},
      numberOfBays: 1,
    }));

  bls.forEach((bl) => {
    const bay = bl.isoBay;
    const paired = pairedBaysCalc.pairedBays.find(
      (pb) => pb.base === bay,
    )?.paired;

    const maxSizeSameBay = Math.max(
      ...[
        ...(getSizesFromSlots(
          blsDict[`${bay}-${BayLevelEnum.ABOVE}`]?.perSlotInfo,
        )?.sizes || []),
        ...(getSizesFromSlots(
          blsDict[`${bay}-${BayLevelEnum.BELOW}`]?.perSlotInfo,
        )?.sizes || []),
        20,
      ],
    );

    const maxSizePairedBay = paired
      ? Math.max(
          ...[
            ...(getSizesFromSlots(
              blsDict[`${paired}-${BayLevelEnum.ABOVE}`]?.perSlotInfo,
            )?.sizes || []),
            ...(getSizesFromSlots(
              blsDict[`${paired}-${BayLevelEnum.BELOW}`]?.perSlotInfo,
            )?.sizes || []),
            20,
          ],
        )
      : 0;

    const id = paired ? `${bay}-${paired}` : bay;
    const idx = blockBaysAndSizes.findIndex((b) => b.allBays === id);

    if (idx !== -1) {
      blockBaysAndSizes[idx].maxSizes = {
        [bay]: maxSizeSameBay,
      };

      if (paired) blockBaysAndSizes[idx].maxSizes[paired] = maxSizePairedBay;

      if (paired) {
        blockBaysAndSizes[idx].numberOfBays = 2;
        const maxSizeOfBoth = Math.max(maxSizeSameBay, maxSizePairedBay);

        if (maxSizeOfBoth >= 40) {
          blockBaysAndSizes[idx].blockSize = maxSizeOfBoth;
        } else {
          blockBaysAndSizes[idx].blockSize = maxSizeSameBay + maxSizePairedBay;
        }
      } else {
        blockBaysAndSizes[idx].blockSize = maxSizeSameBay;
        blockBaysAndSizes[idx].numberOfBays = 1;
      }
    }
  });

  const blockBaysAndSizesBy20Bay = blockBaysAndSizes.reduce((acc, b) => {
    if (b.blockSize === 0 || b.blockSize === -Infinity) return acc;

    b.allBays.split("-").forEach((bay) => {
      acc[bay as IBayPattern] = b;
    });

    return acc;
  }, {} as Record<IBayPattern, IBayBlocksAndSizes>);

  const blockBaysAndSizesByBlockBay = createDictionary(
    blockBaysAndSizes,
    (b) => b.allBays,
  );

  return {
    allIsoBays,
    blockBaysAndSizes,
    blockBaysAndSizesByBlockBay,
    blockBaysAndSizesBy20Bay,
  };
}

function calcBlockBaysAndSizesKeys(pairedBaysCalc: IGetPairedBaysOutput) {
  const { unpairedBays, pairedBays } = pairedBaysCalc;

  const blockBaysAndSizesKeys: Set<string> = new Set();

  unpairedBays.forEach((ub) => {
    blockBaysAndSizesKeys.add(ub.base);
  });

  pairedBays.forEach((pb) => {
    if (
      !blockBaysAndSizesKeys.has(pb.base) &&
      !blockBaysAndSizesKeys.has(pb.paired)
    ) {
      blockBaysAndSizesKeys.add(pb.allBays.join("-"));
    } else {
      blockBaysAndSizesKeys.add(pb.base);
      blockBaysAndSizesKeys.add(pb.paired);
    }
  });

  return blockBaysAndSizesKeys;
}
