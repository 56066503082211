/* eslint-disable @typescript-eslint/no-explicit-any */
import { IField, ISlOption } from "../types/IField";

import { IFieldsTypes } from "../types/IFieldsTypes";
import { IFormProps } from "../types/IFormProps";
import { ITedivoFormRecord } from "../types/controls/ITedivoFormRecord";
import { ITedivoFormRecordOfControl } from "../types/controls/ITedivoFormRecordOfControl";
import { ITedivoFormRecordOfControlByName } from "../types/controls/ITedivoFormRecordOfControlByName";
import SlSelect from "@shoelace-style/shoelace/dist/components/select/select.component";
import SlTextarea from "@shoelace-style/shoelace/dist/components/textarea/textarea.component";
import { createSlOption } from "./TedivoForm";

export function createFormFieldAndState<
  TFormSchema extends Record<string, any>,
>({
  fieldProps,
  formProps,
  formControlsByName,
}: {
  fieldProps: IField<TFormSchema> | undefined;
  formProps: IFormProps | undefined;
  formControlsByName: ITedivoFormRecordOfControlByName<TFormSchema>;
}):
  | {
      formField: IFieldsTypes | HTMLHeadingElement | HTMLElement | undefined;
      stateRecord: ITedivoFormRecord<TFormSchema> | undefined;
    }
  | undefined {
  if (fieldProps === undefined) return undefined;

  /** It's the actual form-field html node, like a sl-input, or sl-checkbox, ... */
  let formField: IFieldsTypes | undefined = undefined;
  let titleField: HTMLHeadingElement | HTMLParagraphElement | undefined =
    undefined;
  let nodeField: HTMLElement | undefined = undefined;

  const name = (fieldProps as any).name as string | undefined;
  const stateRecord = {
    name,
    value: undefined,
    type: fieldProps.type,
    props: fieldProps,
  } as ITedivoFormRecordOfControl<TFormSchema>;

  let initVal: any = undefined;

  // 1. Create the field
  switch (fieldProps.type) {
    case "title":
      titleField = document.createElement(fieldProps.tagName ?? "h2");
      titleField.className = "subtitle";
      //stateRecord.field = titleField;
      break;

    case "node":
      nodeField = fieldProps.node;
      break;

    case "hidden":
      formField = document.createElement("tf-hidden");
      formField.style.display = "none";

      initVal = fieldProps.initialValue ?? fieldProps.defaultValue;
      if (initVal !== undefined) {
        formField.value = String(initVal);
        stateRecord.value = String(initVal);
      }

      stateRecord.field = formField;

      break;

    case "color":
      formField = document.createElement("tf-input-color");
      if (fieldProps.initialValue) {
        initVal = fieldProps.initialValue;
        stateRecord.value = initVal;
        formField.value = initVal;
      }

      stateRecord.field = formField;

      break;
    case "textBox":
    case "date":
      formField = document.createElement("sl-input");

      if (fieldProps.type === "date") {
        formField.type = "date";
        if (fieldProps.initialValue) {
          initVal =
            typeof fieldProps.initialValue === "string"
              ? new Date((fieldProps as any).initialValue)
              : fieldProps.initialValue;
        }

        if (initVal !== undefined) {
          formField.valueAsDate = initVal;
          stateRecord.value = initVal;
        }
      } else {
        formField.type = !fieldProps.isPassword ? "text" : "password";
        initVal = fieldProps.initialValue ?? fieldProps.defaultValue;

        if (fieldProps.placeholder)
          formField.placeholder = fieldProps.placeholder;
        if (fieldProps.autocapitalize) formField.autocapitalize = "characters";
        if (fieldProps.disabled) formField.disabled = true;

        if (fieldProps.isPassword) formField.passwordToggle = true;

        if (initVal !== undefined) {
          formField.value = String(initVal);
          stateRecord.value = String(initVal);
        }
      }

      stateRecord.field = formField;

      formField.autocorrect = "off";

      break;

    case "textArea":
      formField = document.createElement("sl-textarea") as SlTextarea;
      stateRecord.field = formField;

      initVal = fieldProps.initialValue ?? fieldProps.defaultValue;
      if (initVal !== undefined) {
        formField.value = String(initVal);
        stateRecord.value = String(initVal);
      }

      if (fieldProps.placeholder)
        formField.placeholder = fieldProps.placeholder;

      if (fieldProps.autocapitalize) formField.autocapitalize = "characters";

      formField.autocorrect = "off";

      break;

    case "number":
      formField = document.createElement("sl-input");
      formField.type = "number";
      stateRecord.field = formField;

      initVal = fieldProps.initialValue ?? fieldProps.defaultValue;
      if (initVal !== undefined) {
        formField.value = String(initVal);
        stateRecord.value = initVal;
      }

      if (fieldProps.placeholder)
        formField.placeholder = fieldProps.placeholder;
      if (fieldProps.step !== undefined) formField.step = fieldProps.step;

      break;

    case "file":
      formField = document.createElement("tf-file");
      if (fieldProps.accept) formField.accept = fieldProps.accept;
      stateRecord.value = fieldProps.initialValue;
      stateRecord.field = formField;

      break;

    case "checkbox":
      formField = document.createElement("tf-checkbox");
      if (fieldProps.isNumericEnum) formField.isNumericEnum = true;

      initVal = fieldProps.initialValue ?? fieldProps.defaultValue;
      if (initVal !== undefined) {
        formField.value = !!initVal;
        stateRecord.value = fieldProps.isNumericEnum
          ? initVal
          : Boolean(initVal);
      }

      if (fieldProps.disabled !== undefined)
        formField.disabled = fieldProps.disabled;
      if (fieldProps.padStart) formField.padStart = true;
      if (fieldProps.required) formField.required = true;

      stateRecord.field = formField;
      break;

    case "radioButtonList":
      formField = document.createElement("tf-radio-group");
      formField.items = fieldProps.options;
      formField.isNumericEnum = !!fieldProps.isNumericEnum;
      if (fieldProps.fieldset) formField.fieldset = true;

      initVal = fieldProps.initialValue ?? fieldProps.defaultValue;
      if (initVal !== undefined) {
        formField.value = String(initVal);
        stateRecord.value = initVal;
      }

      if (fieldProps.disabled) formField.disabled = true;

      stateRecord.field = formField;

      break;

    case "checkboxesList":
      formField = document.createElement("tf-checkbox-group");
      formField.items = fieldProps.options;
      formField.isNumericEnum = !!fieldProps.isNumericEnum;
      if (fieldProps.fieldset) formField.fieldset = true;

      if (fieldProps.fixedOptionWidth)
        formField.fixedOptionWidth = fieldProps.fixedOptionWidth;

      initVal = fieldProps.initialValue ?? fieldProps.defaultValue;
      if (initVal !== undefined) {
        formField.value = initVal;
        stateRecord.value = initVal;
      }

      if (fieldProps.disabled !== undefined)
        formField.disabled = fieldProps.disabled;

      if (fieldProps.helpers) formField.helpers = fieldProps.helpers;

      stateRecord.field = formField;

      break;

    case "numberWithUnits":
      formField = document.createElement("tf-input-units");
      stateRecord.field = formField;
      formField.converter = fieldProps.converter;
      formField.transponser = fieldProps.transponser;

      initVal = fieldProps.initialValue ?? fieldProps.defaultValue;
      if (initVal !== undefined) {
        formField.value = initVal;
        stateRecord.value = initVal;
      }

      break;

    case "select":
      formField = document.createElement("sl-select") as SlSelect;

      initVal = fieldProps.initialValue ?? fieldProps.defaultValue;
      if (initVal !== undefined) {
        formField.value = String(initVal);
        stateRecord.value = String(initVal);
      }

      if (fieldProps.hoist) formField.hoist = true;

      stateRecord.field = formField;

      {
        const createOptions = (opts: ISlOption[]) => {
          opts.forEach((opt) => {
            const option = createSlOption(opt);
            if (option) formField?.appendChild(option);
          });
        };

        if (typeof fieldProps.options === "function") {
          fieldProps.options().then((opts) => createOptions(opts));
        } else {
          createOptions(fieldProps.options);
        }
      }

      break;
  }

  // 2. Add secondary properties to created fields
  switch (fieldProps.type) {
    case "title":
      // Title (heading)
      if (titleField) {
        return {
          formField: titleField,
          stateRecord: {
            value: undefined,
            type: "title",
            field: titleField,
            props: fieldProps,
          },
        };
      } else {
        return undefined;
      }

    case "node":
      if (nodeField) {
        return {
          formField: nodeField,
          stateRecord: {
            value: undefined,
            type: "node",
            field: nodeField,
            props: fieldProps,
          },
        };
      } else {
        return undefined;
      }

    default:
      if (formField === undefined) return undefined;

      // Keeps the value of previous rendered fields
      if (name && formControlsByName[name]) {
        stateRecord.value = formControlsByName[name].value;
      }

      formField.id = String(fieldProps.name);
      formField.name = String(fieldProps.name);
      formField.size = fieldProps.size ?? formProps?.size ?? "medium";

      // Label
      if (fieldProps.label) {
        formField.label = fieldProps.label;
      } else if (fieldProps.labelNode) {
        const lblNode = fieldProps.labelNode.cloneNode(true) as HTMLElement;
        lblNode.slot = "label";
        formField.appendChild(lblNode);
      }

      // helpText
      if (fieldProps.helpText)
        (formField as any).helpText = fieldProps.helpText;

      return {
        formField,
        stateRecord,
      };
  }
}
