import { TedivoForm, translateTedivoForm } from "@tedivo/tedivo-form";
import {
  convertILidDataToLidDataStore,
  convertLidDataStoreToILidData,
} from "./convertILidDataToLidDataStore";
import { findParentBy, removeChildren } from "@tedivo/tedivo-dom-helpers";
import {
  generateRandomKey,
  numberIsOdd,
  pad2,
} from "@tedivo/tedivo-pure-helpers";

import { IFormCopyPaste } from "./IFormCopyPaste";
import { ILidData } from "open-vessel-definition";
import { ILidDataStore } from "./createLidsFormFields";
import { IntegratedDialogError } from "@tedivo/tedivo-ui";
import SlDialog from "@shoelace-style/shoelace/dist/components/dialog/dialog.component";
import { SmartTable } from "@tedivo/tedivo-smart-table";
import { generateNewLabel } from "./generateNewLabel";
import { getTranslation } from "../../../../../../app/i18/i18tn";
import { z } from "zod";

export default class LidRowsFactory {
  private deleteConfirmationDialog: SlDialog = {} as SlDialog;
  private hoveredNode: HTMLElement | SVGElement | undefined = undefined;

  private highlightedRows: Element[] = [];
  private highlightedRowsPksHash = "";

  usingAModal: ((a: boolean) => void) | undefined = undefined;
  onChange: Map<string, () => void> = new Map();

  private _data: Map<string, ILidDataStore> = new Map();

  private _editingArea: HTMLElement;
  private _svgWrapperNode: HTMLElement;

  private _hoverIsActive = false;

  public smartTable: SmartTable<ILidDataStore> | undefined = undefined;

  public hasBeenSubmitted = false;

  constructor(
    editingArea: HTMLElement,
    svgWrapperNode: HTMLElement,
    usingAModal: (a: boolean) => void,
  ) {
    this.deleteConfirmationDialog = document.createElement("sl-dialog");
    this.deleteConfirmationDialog.classList.add("higher-modal-z-index");
    editingArea.appendChild(this.deleteConfirmationDialog);

    this.usingAModal = usingAModal;
    this._editingArea = editingArea;
    this._svgWrapperNode = svgWrapperNode;

    this.hoverIsActive = true;
  }

  set hoverIsActive(isActive: boolean) {
    if (this._hoverIsActive === isActive) return;
    this._hoverIsActive = isActive;

    if (isActive) {
      this._editingArea.addEventListener(
        "mousemove",
        this.onLidOrRowHover,
        false,
      );
    } else {
      this._editingArea.removeEventListener(
        "mousemove",
        this.onLidOrRowHover,
        false,
      );
    }
  }

  setData(data: ILidData[]) {
    data.forEach((d) => {
      const key = generateRandomKey();
      this._data.set(key, convertILidDataToLidDataStore(d, key));
    });
  }

  getData(): ILidData[] {
    return Array.from(this._data.values()).map((d) =>
      convertLidDataStoreToILidData(d),
    );
  }

  getRawData(): ILidDataStore[] {
    return Array.from(this._data.values());
  }

  addLidData = (data: ILidDataStore): string => {
    const pk = generateRandomKey();
    const lidDataStore: ILidDataStore = { ...data, pk };
    this._data.set(pk, lidDataStore);
    return pk;
  };

  updateLidData(pk: string, data: ILidDataStore): Promise<void> {
    return new Promise((resolve) => {
      if (this._data.get(pk)) {
        this._data.set(pk, { ...data, pk });
      }
      resolve();
    });
  }

  deleteLidData(pk: string) {
    this._data.delete(pk);
  }

  getSingleLidData(pk: string) {
    return this._data.get(pk);
  }

  copyPasteLids({
    pks,
    cb,
    usingAModal,
  }: {
    pks: string[];
    cb?: (newPks: string[]) => void;
    usingAModal?: (a: boolean) => void;
  }) {
    // Get the data to copy
    const lidsDataToCopy = pks
      .map((pk) => this.getSingleLidData(pk))
      .filter(Boolean) as ILidDataStore[];

    // If there is no data to copy, return
    if (lidsDataToCopy.length === 0) return;

    // Check if all lids have the same startIsoBay
    if (
      lidsDataToCopy
        .map((l) => l.startIsoBay)
        .filter((v, idx, arr) => arr.indexOf(v) === idx).length > 1
    ) {
      usingAModal?.(true);
      new IntegratedDialogError(
        this._editingArea,
        true,
        getTranslation("general:common.close"),
      ).show(
        getTranslation("general:common.attention"),
        getTranslation("view:lids.copyPasteSameStartIsoBay"),
      ).onDismissed = () => {
        console.log("dismissed");
        usingAModal?.(false);
      };

      return;
    }

    const addLidData = this.addLidData;

    // Create the modal
    const confirmPaste = document.createElement("sl-button");
    confirmPaste.innerHTML = getTranslation("view:lids.pasteLids");
    confirmPaste.slot = "footer";
    confirmPaste.tabIndex = 0;
    confirmPaste.variant = "primary";
    confirmPaste.addEventListener(
      "click",
      () =>
        executeCopyPasteLids(
          this.getRawData(),
          this.onChange,
          this.deleteConfirmationDialog,
        ),
      false,
    );

    removeChildren(this.deleteConfirmationDialog);
    this.deleteConfirmationDialog.label = getTranslation(
      "general:common.copyPasteSelected",
    );

    const minStartIsoBay = Math.min(
      ...lidsDataToCopy.map((l) => l.startIsoBay),
    );
    const maxEndIsoBay = Math.max(...lidsDataToCopy.map((l) => l.endIsoBay));
    const diffIsoBay = Math.abs(maxEndIsoBay - minStartIsoBay);

    const tedivoForm = new TedivoForm<IFormCopyPaste>({
      fields: [
        {
          type: "title",
          label: `${getTranslation("view:lids.plural")}: ${lidsDataToCopy
            .map((l) => l.label || "")
            .join(", ")}`,
        },
        [
          {
            label: "view:lids.startIsoBay",
            name: "startIsoBay",
            type: "number",
            initialValue: maxEndIsoBay + 2,
          },
          {
            label: "view:lids.endIsoBay",
            name: "endIsoBay",
            type: "number",
            initialValue: maxEndIsoBay + 2 + diffIsoBay,
          },
        ],
      ],
      onSubmit: () => null,
      formValidator: createCopyPasteValidator(),
      submitButton: confirmPaste,
      formProps: { autoFocusOnFirstInput: true },
    });

    translateTedivoForm<IFormCopyPaste>({
      tedivoForm,
      getTranslation,
    });

    this.deleteConfirmationDialog.appendChild(tedivoForm.form);
    this.deleteConfirmationDialog.appendChild(confirmPaste);

    this.usingAModal?.(true);
    this.deleteConfirmationDialog.show();

    /** This function creates the copy of data */
    function executeCopyPasteLids(
      data: ILidDataStore[],
      onChange: Map<string, () => void> = new Map(),
      deleteConfirmationDialog: SlDialog,
    ) {
      const validResult = tedivoForm.doSubmitForm();

      if (!validResult.success) return false;

      const values = tedivoForm.getValues();

      const loops = calculateLoops(
        values.endIsoBay,
        values.startIsoBay,
        diffIsoBay,
      );

      const otherLabels = data.map((l) => l.label);

      const newPks: string[] = [];
      let addBaysInLoop = 0;

      for (let i = 0; i < loops; i++) {
        newPks.push(
          ...lidsDataToCopy.map((lid) => {
            const baseLabel = pad2(values.startIsoBay + addBaysInLoop);

            const lbl = generateNewLabel(baseLabel, otherLabels);

            otherLabels.push(lbl);

            const lidData: Omit<ILidDataStore, "pk"> = {
              label: lbl,
              startIsoBay: values.startIsoBay + addBaysInLoop,
              endIsoBay: values.startIsoBay + diffIsoBay + addBaysInLoop,
              portIsoRow: lid.portIsoRow,
              starboardIsoRow: lid.starboardIsoRow,
              weight: lid.weight,
              overlapPort: lid.overlapPort ? 1 : 0,
              overlapStarboard: lid.overlapStarboard ? 1 : 0,
            };

            return addLidData(lidData as ILidDataStore);
          }),
        );

        addBaysInLoop += diffIsoBay * 2;
      }

      onChange.forEach((fn) => fn());
      cb?.(newPks);

      deleteConfirmationDialog.hide();
      return true;
    }

    function createCopyPasteValidator(): z.Schema<IFormCopyPaste> {
      return z
        .object({
          startIsoBay: z
            .number()
            .min(1)
            .refine(numberIsOdd)
            .refine((v) => v !== minStartIsoBay),
          endIsoBay: z
            .number()
            .min(1)
            .refine(numberIsOdd)
            .refine((v) => v !== minStartIsoBay),
        })
        .superRefine((values, ctx) => {
          if (values.endIsoBay < values.startIsoBay) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: getTranslation(
                "errors:formCustom.endIsoBayGreaterThanStartIsoBay",
              ),
              path: ["startIsoBay"],
            });

            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: getTranslation(
                "errors:formCustom.endIsoBayGreaterThanStartIsoBay",
              ),
              path: ["endIsoBay"],
            });
          }
        });
    }
  }

  deleteLids(pks: string[]) {
    const confirmDelete = document.createElement("sl-button");
    confirmDelete.innerHTML = getTranslation("general:common.delete");
    confirmDelete.slot = "footer";
    confirmDelete.tabIndex = 0;
    confirmDelete.variant = "danger";

    removeChildren(this.deleteConfirmationDialog);
    this.deleteConfirmationDialog.label = getTranslation("view:lids.deleteLid");

    const lidsData = pks
      .map((pk) => this.getSingleLidData(pk))
      .filter(Boolean) as ILidDataStore[];

    this.deleteConfirmationDialog.innerHTML = lidsData
      .map((l) => l.label || "")
      .join(", ");

    this.deleteConfirmationDialog.appendChild(confirmDelete);

    confirmDelete.onclick = () => {
      this.usingAModal?.(false);
      this.deleteConfirmationDialog.hide();

      pks.forEach((pk) => {
        this.deleteLidData(pk);
      });

      this.onChange.forEach((fn) => fn());
    };

    this.usingAModal?.(true);
    this.deleteConfirmationDialog.show();
  }

  onLidOrRowHover = (e: Event) => {
    const HTML_NODES_ALLOWED = [
      "TD",
      "SL-INPUT",
      "SL-CHECKBOX",
      "TF-INPUT-UNITS",
      "SL-SWITCH",
    ];

    const target = e.target as HTMLElement | SVGElement;

    // Don't pass if the same node
    if (this.hoveredNode === target) return;
    this.hoveredNode = target;

    // Check it's TD or PATH
    const nodeName = target.nodeName;
    const isSvgPath = nodeName === "path";

    if (HTML_NODES_ALLOWED.indexOf(nodeName) < 0 && nodeName !== "path") {
      this.smartTable?.selectRows({ clearSelection: true });
      return;
    }

    let pk: string | undefined = undefined;
    // const tableRowIndex = -1;

    // Find IDX/PK
    if (isSvgPath) {
      pk = target.dataset.idx;
    } else {
      const tr = findParentBy(target, "TR") as HTMLElement | null;
      pk = tr ? tr.dataset.pk : undefined;
    }

    if (pk === undefined) {
      this.smartTable?.selectRows({ clearSelection: true });
      this.highlightPaths();
      return;
    }

    // Highlight the row in the table
    this.smartTable?.selectRows({
      pk,
      scrollIntoView: isSvgPath ? "optimal" : false,
    });
    // Highlight the paths in the SVG
    this.highlightPaths([pk], !isSvgPath);
  };

  highlightPaths = (pks?: string[], scrollIntoView = false) => {
    // Check if the pks are the same as the last time
    const pksHash = pks?.slice().sort().join(",") || "";
    if (pksHash === this.highlightedRowsPksHash) return;

    // Un-highlight nodes
    this.highlightedRowsPksHash = pks?.slice().sort().join(",") || "";

    this.highlightedRows.forEach((node) => {
      node.classList.remove("row-highlighted");
    });

    if (!pks || pks.length === 0) return;

    const pathsToHighlight = document.querySelectorAll(
      pks.map((pk) => `.editing-area path[data-idx='${pk}']`).join(", "),
    );

    pathsToHighlight.forEach((node, index) => {
      if (!node.classList.contains("row-highlighted")) {
        if (scrollIntoView && index === 0) {
          const box = node.getBoundingClientRect();
          this._svgWrapperNode.scrollBy({
            top: box.top - 350,
            left: 0,
            behavior: "smooth",
          });
        }

        node.classList.add("row-highlighted");
      }
    });

    this.highlightedRows = Array.from(pathsToHighlight);
  };
}

function calculateLoops(end: number, start: number, diff: number): number {
  if (start === end) return 1;
  if (diff === 2) return Math.ceil(Math.abs(end - start) / (diff + 1)) || 1;
  return Math.ceil(Math.abs(end - start) / 2) + 1;
}
