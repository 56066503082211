import {
  IFileVersion,
  ILogEvent,
  LogEventEntitiesEnum,
  LogEventTypesEnum,
} from "@tedivo/tvd-api-models";
import { IMapConfigArray, mapDataToDictionary } from "@tedivo/tedivo-ui";
import { ITableField, SmartTable } from "@tedivo/tedivo-smart-table";
import {
  formatDateInCurrentTimezone,
  safeDateString,
} from "@tedivo/tedivo-pure-helpers";
import { getTranslation, i18n } from "../../../../app/i18/i18tn";

import Services from "../../../../app/services";
import getTranslatedDictionary from "../../../../app/i18/getTranslatedDictionary";
import { routeFns } from "../../../../app/router/routes";
import router from "../../../../app/router";
import securityModule from "../../../../app/security/SecurityModule";

export function showFileHistory(
  fileInfo: ISubTvdId | undefined,
): HTMLElement | undefined {
  if (fileInfo === undefined) return undefined;

  let minHeight = 0;
  let lastEvaluatedLogKey: string | undefined = undefined;
  let totalLogData: ILogEvent[] = [];
  let usersNamesByIdOfOrganization: Record<string, string> | undefined =
    undefined;

  const {
    holder,
    historyListTable,
    versionsListTable,
    showHistoryButton,
    showVersionsButton,
    plusIcon,
    displayMetaHistoryDiv,
    tabGroup,
  } = createBaseNodes();

  holder.setLoading(true);

  securityModule
    .getOrganizationUsersBySub(fileInfo.organizationId)
    .then((usersById) => {
      usersNamesByIdOfOrganization = usersById;
      displayMetaHistoryDiv.elements = createBasicHistoryPart(fileInfo);
      holder.setLoading(false);
    });

  const smartTableLogs = createEventsSmartTable(
    historyListTable,
    fileInfo.fromBvoName,
  );

  const smartTableFVersions = createFileVersionsSmartTable(versionsListTable);

  showHistoryButton.addEventListener("click", () => {
    loadEventLogs(fileInfo, smartTableLogs);
  });

  loadFileVersions(fileInfo, smartTableFVersions);

  let shownFirstHistory = false;

  tabGroup.addEventListener("sl-tab-show", (event: Event) => {
    if (shownFirstHistory) return;

    const evDet = event as CustomEvent;
    if (evDet.detail.name === "history-on-demand") {
      loadEventLogs(fileInfo, smartTableLogs);
      shownFirstHistory = true;
    }
  });

  return holder;

  function createBaseNodes() {
    const holder = document.createElement("div-spinner-element");

    const displayMetaHistoryDiv = document.createElement(
      "dictionary-display-element",
    );
    displayMetaHistoryDiv.className = "fileHistory";

    holder.appendChild(displayMetaHistoryDiv);

    const tabGroup = document.createElement("sl-tab-group");
    const tabHistoryMenu = document.createElement("sl-tab");
    const tabVersionsMenu = document.createElement("sl-tab");
    const tabHistoryPanel = document.createElement("sl-tab-panel");
    const tabVersionsPanel = document.createElement("sl-tab-panel");

    tabHistoryMenu.innerHTML = getTranslation(
      "view:edit.fileHistory.tabHistory",
    );

    tabVersionsMenu.innerHTML = getTranslation(
      "view:edit.fileHistory.tabVersions",
    );

    tabHistoryMenu.slot = "nav";
    tabHistoryMenu.panel = "history-on-demand";

    tabVersionsMenu.slot = "nav";
    tabVersionsMenu.panel = "versions-on-demand";
    tabVersionsMenu.active = true;

    tabHistoryPanel.name = "history-on-demand";
    tabHistoryPanel.className = "history-on-demand";
    tabVersionsPanel.name = "versions-on-demand";
    tabVersionsPanel.className = "versions-on-demand";

    tabGroup.appendChild(tabVersionsMenu);
    tabGroup.appendChild(tabHistoryMenu);
    tabGroup.appendChild(tabVersionsPanel);
    tabGroup.appendChild(tabHistoryPanel);

    holder.appendChild(tabGroup);

    // File History
    const showHistoryButton = document.createElement("sl-button");
    showHistoryButton.variant = "primary";
    showHistoryButton.size = "small";
    showHistoryButton.innerHTML = getTranslation(
      "view:edit.fileHistory.loadEvents",
    );

    const plusIcon = document.createElement("sl-icon");
    plusIcon.name = "plus-lg";
    plusIcon.slot = "prefix";
    showHistoryButton.appendChild(plusIcon);

    const historyListTable = document.createElement("div");

    tabHistoryPanel.appendChild(historyListTable);
    tabHistoryPanel.appendChild(showHistoryButton);

    // Versions
    const showVersionsButton = document.createElement("sl-button");
    showVersionsButton.variant = "primary";
    showVersionsButton.size = "small";
    showVersionsButton.innerHTML = getTranslation(
      "view:edit.fileHistory.loadVersions",
    );

    const plusIcon2 = document.createElement("sl-icon");
    plusIcon2.name = "plus-lg";
    plusIcon2.slot = "prefix";
    showVersionsButton.appendChild(plusIcon2);

    const versionsListTable = document.createElement("div");

    tabVersionsPanel.appendChild(versionsListTable);
    tabVersionsPanel.appendChild(showVersionsButton);

    return {
      holder,
      historyListTable,
      versionsListTable,
      showHistoryButton,
      showVersionsButton,
      plusIcon,
      displayMetaHistoryDiv,
      tabGroup,
      tabHistoryPanel,
      tabVersionsPanel,
    };
  }

  function createBasicHistoryPart(tvdId: ISubTvdId) {
    const partData: IHistoryMetaData = {
      createdAt: tvdId.createdAt,
      createdBy: tvdId.userSub,
      updatedAt: tvdId.updatedAt,
      updatedBy: tvdId.updatedBy,
      fromBvoName: tvdId.fromBvoName,
      lastComment: tvdId.lastComment,
    };

    const isWideEnough = document.documentElement.clientWidth > 1400;

    const HistoryDataConfig: IMapConfigArray<IHistoryMetaData> = [
      {
        label: "view:edit.fileHistory.createdAt",
        key: "createdAt",
        type: "longDate",
        undefinedIsDash: true,
        format: (v) => formatDateInCurrentTimezone(v as Date),
      },
      {
        label: "view:edit.fileHistory.createdBy",
        key: "createdBy",
        type: "string",
        undefinedIsDash: true,
        format: (v) =>
          partData.fromBvoName
            ? getTranslation("general:bvo")
            : usersNamesByIdOfOrganization?.[v as string] || "-",
      },
      {
        label: "view:edit.fileHistory.updatedAt",
        key: "updatedAt",
        type: "longDate",
        undefinedIsDash: true,
        format: (v) => formatDateInCurrentTimezone(v as Date),
      },
      {
        label: "view:edit.fileHistory.updatedBy",
        key: "updatedBy",
        type: "string",
        undefinedIsDash: true,
        format: (v) => usersNamesByIdOfOrganization?.[v as string] || "-",
      },
      {
        breakBefore: !isWideEnough,
        label: "view:edit.fileHistory.lastComment",
        key: "lastComment",
        type: "string",
        undefinedIsDash: true,
        format: (v) => (v as string).replace(/\n/g, "<br />"),
      },
    ];

    const shipPartDataElements = getTranslatedDictionary(
      mapDataToDictionary<IHistoryMetaData>(
        partData,
        HistoryDataConfig,
        i18n.language,
      ),
    );
    return shipPartDataElements;
  }

  function createEventsSmartTable(
    node: HTMLElement,
    fromBvoName: string | undefined,
  ) {
    const fields: Array<ITableField<ILogEvent>> = [
      {
        name: "date",
        label: getTranslation("view:edit.fileHistory.fields.date"),
        valueFunction: (dta) => formatDateInCurrentTimezone(dta.date),
        className: "centered",
        fixedWidth: "180px",
      },
      {
        name: "subEvent",
        label: getTranslation("view:edit.fileHistory.fields.event"),
        valueFunction: (dta) => {
          switch (dta.eventType) {
            case LogEventTypesEnum.Modified:
              if (dta.subEvent) {
                const parts = dta.subEvent.split("-");
                const part0 = parts?.[0];
                if (part0 === "Bay") {
                  return `<span class="span-action">${getTranslation(
                    `general:common.modified`,
                  )}:</span> ${getTranslation(
                    `view:edit.fileHistory.eventSubTypes.Bay`,
                  )}
                     ${parts?.[1]} ${
                    parts?.[2] === "Ab"
                      ? getTranslation("enums:BayLevelEnum.ABOVE")
                      : getTranslation("enums:BayLevelEnum.BELOW")
                  }`;
                } else {
                  return `<span class="span-action">${getTranslation(
                    `general:common.modified`,
                  )}:</span> ${getTranslation(
                    `view:edit.fileHistory.eventSubTypes.${part0}`,
                  )}`;
                }
              } else {
                return "";
              }
            case LogEventTypesEnum.StateChanged:
              return `<span class="span-action">${getTranslation(
                "view:edit.fileHistory.eventSubTypes.StateChanged",
              )}:</span> ${getTranslation(
                `enums:FileStateEnum.${dta.details.oldState}`,
              )} &rarr; ${getTranslation(
                `enums:FileStateEnum.${dta.details.newState}`,
              )} ${
                dta.details.comments
                  ? ` <br /><span class="history-comments">${dta.details.comments.replace(
                      /\n/g,
                      "<br />",
                    )}</span>`
                  : ""
              }`;
              break;
            case LogEventTypesEnum.Created:
              if (dta.eventEntity === LogEventEntitiesEnum.File) {
                return `<span class="span-action">${getTranslation(
                  "view:edit.fileHistory.eventTypes.Created",
                )}:</span> ${dta.details.fileName} &rarr; ${
                  dta.details.source
                    ? getTranslation(
                        `enums:TSourceFileCreated.${dta.details.source}`,
                      )
                    : ""
                }`;
              } else {
                return "";
              }
            case LogEventTypesEnum.ClonedByThirdParty:
              return `<span class="span-action">${getTranslation(
                "view:edit.fileHistory.eventTypes.ClonedByThirdParty",
              )}:</span> ${dta.details.orgName}`;
              break;
            case LogEventTypesEnum.ReplacedByVersion:
              return `<span class="span-action">${getTranslation(
                `general:common.modified`,
              )}:</span> ${getTranslation(
                `view:edit.fileHistory.eventSubTypes.VersionReplace`,
              )}: v${dta.details?.version || ""}`;

            case LogEventTypesEnum.ReplacedByConsumer:
              return `<span class="span-action">${getTranslation(
                `general:common.modified`,
              )}:</span> ${getTranslation(
                `view:edit.fileHistory.eventSubTypes.VersionReplace`,
              )}`;
              break;
            case LogEventTypesEnum.UpVoted:
            case LogEventTypesEnum.RemovedVote:
              return `<span class="span-action">${getTranslation(
                `view:edit.fileHistory.eventTypes.${dta.eventType}`,
              )}:</span> ${dta.details.orgName}`;
              break;
            default:
              return "";
          }
        },
      },
      {
        name: "userSub",
        label: getTranslation("general:common.author"),
        valueFunction: (dta) =>
          fromBvoName &&
          dta.eventType === LogEventTypesEnum.Created &&
          dta.eventEntity === LogEventEntitiesEnum.File
            ? getTranslation("general:bvo")
            : usersNamesByIdOfOrganization?.[dta.userSub] ||
              dta.userDisplayName ||
              "-",
      },
    ];

    const smartTableLogs = new SmartTable<ILogEvent>().initialize({
      className: "tvd-table tvd-table-smart-table margin-bottom",
      data: [],
      fields,
      initialRows: 1000,
      settings: {
        sortEnabled: false,
        sortVisible: false,
        filterEnabled: false,
      },
      pkFunction: (dta) => `${new Date(dta.date).toISOString()}#${dta.itemId}`,
    });

    node.appendChild(smartTableLogs);
    node.style.display = "none";

    return smartTableLogs;
  }

  function createFileVersionsSmartTable(node: HTMLElement) {
    const fields: Array<ITableField<IFileVersion>> = [
      {
        name: "vNumber",
        label: getTranslation("general:common.version"),
        valueFunction: (dta) => `v${dta.vNumber}`,
        className: "centered",
        fixedWidth: "60px",
      },
      {
        name: "date",
        label: getTranslation("view:edit.fileHistory.fields.date"),
        valueFunction: (dta) => formatDateInCurrentTimezone(dta.date),
        className: "centered",
        fixedWidth: "180px",
      },
      {
        name: "reason",
        label: getTranslation("view:edit.fileHistory.fields.event"),
        valueFunction: (dta) =>
          getTranslation(
            `enums:VersionReasonEnum.${dta.reason || "PUBLISHED"}`,
          ),
        className: "centered",
      },
      {
        name: "fileId",
        label: getTranslation("view:edit.fileHistory.fields.comments"),
        valueFunction: (dta) =>
          dta.reason === "REPLACED_BY_VERSION"
            ? getTranslation("view:comparer.replacedWithVersion", {
                version: dta.details.comments,
              })
            : dta.details.comments || "-",
      },
      {
        name: "fileId",
        label: getTranslation("general:common.view"),
        type: "icon",
        icon: "eye",
        notSortable: true,
        fixedWidth: "40px",
        smallPadding: true,
        onClick: (dta) => {
          router.navigate(
            routeFns.ovdViewOnlyVersion(
              dta.fileId,
              encodeURI(safeDateString(dta.date)),
            ),
            {
              cloudId: dta.fileId,
              organizationId: dta.organizationId,
              source: "cloudOvdJson",
            },
          );
        },
      },
      {
        name: "fileId",
        label: getTranslation("general:common.compare"),
        type: "icon",
        icon: "code-slash",
        notSortable: true,
        fixedWidth: "80px",
        smallPadding: true,
        onClick: (dta) => {
          const route = routeFns.fileCompareOwnVersion(
            dta.fileId,
            encodeURI(safeDateString(dta.date)),
          );
          router.navigate(route, { organizationId: dta.organizationId });
        },
      },
    ];

    const smartTableFVersions = new SmartTable<IFileVersion>().initialize({
      className: "tvd-table tvd-table-smart-table margin-bottom",
      data: [],
      fields,
      initialRows: 1000,
      settings: {
        sortEnabled: false,
        sortVisible: false,
        filterEnabled: false,
      },
      pkFunction: (dta) => `${new Date(dta.date).toISOString()}`,
    });

    node.appendChild(smartTableFVersions);
    node.style.display = "none";

    return smartTableFVersions;
  }

  async function loadEventLogs(
    tvdId: ISubTvdId,
    smartTableLogs: SmartTable<ILogEvent>,
  ) {
    showHistoryButton.loading = true;
    showHistoryButton.disabled = true;

    const { statusCode, data } = await Services.logEvents.getFileLogEvents(
      tvdId.organizationId,
      tvdId.fileId,
      lastEvaluatedLogKey,
    );

    if (statusCode !== 200) {
      showHistoryButton.loading = false;
      if (statusCode !== 404) showHistoryButton.variant = "danger";
      showHistoryButton.innerHTML = getTranslation(
        statusCode === 404
          ? "view:edit.fileHistory.loadEventsNoEvents"
          : "view:edit.fileHistory.loadEventsError",
      );
      return;
    }

    historyListTable.style.display = "block";

    lastEvaluatedLogKey = data?.lastEvaluatedKey;
    totalLogData = totalLogData.concat(data?.data || []);

    if (totalLogData.length !== 0) {
      smartTableLogs.updateData(totalLogData);
      updateTabHeight();
    }

    if (!lastEvaluatedLogKey) {
      showHistoryButton.style.display = "none";
    } else {
      showHistoryButton.loading = false;
      showHistoryButton.disabled = false;
      showHistoryButton.innerHTML = getTranslation(
        "view:edit.fileHistory.loadMoreEvents",
      );
      showHistoryButton.appendChild(plusIcon);
    }
  }

  async function loadFileVersions(
    tvdId: ISubTvdId,
    smartTableFVersions: SmartTable<IFileVersion>,
  ) {
    showVersionsButton.loading = true;
    showVersionsButton.disabled = true;

    const { statusCode, data } = await Services.files.getFileVersions(
      tvdId.organizationId,
      tvdId.fileId,
    );

    showVersionsButton.loading = false;

    if (statusCode !== 200) {
      if (statusCode !== 404) showVersionsButton.variant = "danger";
      showVersionsButton.innerHTML = getTranslation(
        statusCode === 404
          ? "view:edit.fileHistory.loadEventsNoEvents"
          : "view:edit.fileHistory.loadEventsError",
      );
      showVersionsButton.disabled = true;
      return;
    }

    versionsListTable.style.display = "block";

    const sortedData = data?.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
    );

    if (sortedData && sortedData.length > 0) {
      smartTableFVersions.updateData(sortedData);
      updateTabHeight();
    }

    showVersionsButton.style.display = "none";
  }

  function updateTabHeight() {
    const tHeight = tabGroup.offsetHeight;
    if (tHeight > minHeight) {
      minHeight = tHeight;
      tabGroup.style.minHeight = `${minHeight - 15}px`;
    }
  }
}

export interface IHistoryMetaData {
  createdAt?: Date;
  createdBy: string;
  updatedAt?: Date;
  updatedBy?: string;
  fromBvoName?: string;
  lastComment?: string;
}

interface ISubTvdId {
  name?: string;
  organizationId: string;
  fileId: string;
  createdAt?: Date;
  userSub: string;
  updatedAt?: Date;
  updatedBy?: string;
  fromBvoName?: string;
  lastComment?: string;
}
