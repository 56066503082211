/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ForeAftEnum, } from "open-vessel-definition";
import { cloneObject, createDictionary, pad3, } from "@tedivo/tedivo-pure-helpers";
export function extract40sOfPairedBays({ isoBay, level, bls, target40s, currentCellsData, setRestrictedOnEmptySlots, }) {
    if (target40s === undefined) {
        return; // -> Fast return, no target of 40s selected
    }
    const blsClone = cloneObject(bls);
    const baseBay = blsClone.find((bl) => bl.isoBay === isoBay && bl.level === level);
    if (!baseBay || !baseBay.pairedBay) {
        return undefined; // -> Fast return, no paired bays
    }
    const pairedToIsoBay = Number(isoBay) + (baseBay.pairedBay === ForeAftEnum.FWD ? -2 : 2);
    const pairedBay = blsClone.find((bl) => bl.isoBay === pad3(pairedToIsoBay) && bl.level === level);
    if (!pairedBay) {
        return undefined; // -> Fast return, no paired bays
    }
    // Copy of BLs, sorted by pairedBay (FWD first)
    const pairedBls = [baseBay, pairedBay].sort(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    (a, b) => b.pairedBay - a.pairedBay);
    const pairedBlsDict = createDictionary(pairedBls, (b) => `${b.isoBay}-${b.level}`);
    /** Temporary inventory of 40s. Extract first, co-locate after */
    const tempInventory4xs = {};
    /** Temporary inventory of 40s. Extract first, co-locate after */
    const tempInventoryReefers = {};
    /** Temporary inventory of LCGs of 40s. Extract first, co-locate after */
    const tempInventoryICL = {};
    /** Temporary inventory of LCGs of 40s. Extract first, co-locate after */
    const tempInventoryRowEach = {};
    /** Temporary inventory of Common Row Info. Extract first, co-locate after */
    //const tempInventoryRowCommon: TCommonBayInfo = {};
    let currentPerSlotInfo = undefined;
    // 0. Apply current edits of CellsData to corresponding BayLevelData, or use existing
    if (!pairedBlsDict[`${isoBay}-${level}`]) {
        return undefined; // -> Fast return, no paired bays
    }
    if (currentCellsData) {
        // Replace perSlotsData with currentCellsData
        currentPerSlotInfo = currentCellsData.reduce((acc, slotData) => {
            const clonedSlot = Object.assign({}, slotData);
            if (slotData.sizes)
                clonedSlot.sizes = Object.assign({}, slotData.sizes);
            acc[slotData.pos] = clonedSlot;
            return acc;
        }, {});
        pairedBlsDict[`${isoBay}-${level}`].perSlotInfo = currentPerSlotInfo;
    }
    // A. Extract all 40s and CGs of 40s
    pairedBls.forEach((bl) => {
        const perSlotInfo = bl.perSlotInfo;
        // A.1. Find 40s in each slot and move it to temporary inventory
        if (perSlotInfo) {
            const slots = Object.keys(perSlotInfo);
            slots.forEach((pos) => {
                if (!perSlotInfo[pos].sizes)
                    return;
                const sizes4x = Object.keys(perSlotInfo[pos].sizes)
                    .map(Number)
                    .filter((size) => size >= 40);
                if (sizes4x.length > 0) {
                    if (!tempInventory4xs[pos]) {
                        tempInventory4xs[pos] = { pos, sizes: {} };
                    }
                    sizes4x.forEach((size) => {
                        // Add to inventory
                        tempInventory4xs[pos].sizes[size] = perSlotInfo[pos].sizes[size];
                        // Remove 40s from slot
                        delete perSlotInfo[pos].sizes[size];
                    });
                    const remainingSizes = Object.keys(perSlotInfo[pos].sizes).length;
                    if (setRestrictedOnEmptySlots && !remainingSizes) {
                        perSlotInfo[pos].restricted = 1;
                    }
                    if (remainingSizes === 0 && perSlotInfo[pos].reefer) {
                        delete perSlotInfo[pos].reefer;
                        tempInventoryReefers[pos] = true;
                    }
                }
            });
        }
        // A.2. Find BAY LCGs of each size and move it to temporary inventory
        const infoByContLength = bl.infoByContLength;
        if (infoByContLength) {
            const sizes4x = Object.keys(infoByContLength)
                .map(Number)
                .filter((size) => size >= 40);
            sizes4x.forEach((size) => {
                tempInventoryICL[size] = cloneObject(infoByContLength[size] || { size });
                delete infoByContLength[size];
            });
        }
        // A.3. Find ROW LCGs of each size and move it to temporary inventory
        const perRowInfo = bl.perRowInfo;
        if (perRowInfo) {
            // A.3.1 Each row
            if ((perRowInfo === null || perRowInfo === void 0 ? void 0 : perRowInfo.each) !== undefined) {
                const rows = Object.keys(perRowInfo.each);
                rows.forEach((row) => {
                    var _a;
                    const rowInfo = (_a = perRowInfo.each) === null || _a === void 0 ? void 0 : _a[row];
                    // tcg?: number;
                    // bottomBase?: number;
                    // maxHeight?: number;
                    if (rowInfo === null || rowInfo === void 0 ? void 0 : rowInfo.rowInfoByLength) {
                        const sizes4x = Object.keys(rowInfo.rowInfoByLength)
                            .map(Number)
                            .filter((size) => size >= 40);
                        if (!tempInventoryRowEach[row]) {
                            tempInventoryRowEach[row] = Object.assign(Object.assign({}, rowInfo), { rowInfoByLength: {} });
                        }
                        sizes4x.forEach((size) => {
                            var _a, _b;
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            tempInventoryRowEach[row].rowInfoByLength[size] = cloneObject((_a = rowInfo.rowInfoByLength) === null || _a === void 0 ? void 0 : _a[size]);
                            (_b = rowInfo.rowInfoByLength) === null || _b === void 0 ? true : delete _b[size];
                        });
                    }
                });
            }
            // A.3.2 Common rows
            // if (perRowInfo.common !== undefined) {
            //   if (tempInventoryRowCommon.bottomBase === undefined) {
            //     tempInventoryRowCommon.bottomBase = perRowInfo.common.bottomBase;
            //   } else {
            //     tempInventoryRowCommon.bottomBase =
            //       Math.min(
            //         perRowInfo.common.bottomBase || Infinity,
            //         tempInventoryRowCommon.bottomBase,
            //       ) || Infinity;
            //     if (tempInventoryRowCommon.bottomBase === Infinity)
            //       delete tempInventoryRowCommon.bottomBase;
            //   }
            //   if (tempInventoryRowCommon.maxHeight === undefined) {
            //     tempInventoryRowCommon.maxHeight = perRowInfo.common.maxHeight;
            //   } else {
            //     tempInventoryRowCommon.maxHeight =
            //       Math.max(
            //         perRowInfo.common.maxHeight || 0,
            //         tempInventoryRowCommon.maxHeight,
            //       ) || undefined;
            //   }
            // }
        }
        // A.4 Re-calculate Bottom Bases according to bottom iso Tier
        //TODO: Implement
    });
    // B. Co-locate all 40s and CGs of 40s
    const targetBay = target40s === ForeAftEnum.FWD ? pairedBls[0] : pairedBls[1];
    const targetPerSlotInfo = targetBay.perSlotInfo || {};
    // B.1 Co-locate 40s slots
    Object.keys(tempInventory4xs).forEach((pos) => {
        const slotData = targetPerSlotInfo[pos];
        if (!slotData) {
            targetPerSlotInfo[pos] = tempInventory4xs[pos];
        }
        else {
            const sizesToAdd = Object.keys(tempInventory4xs[pos].sizes).map(Number);
            sizesToAdd.forEach((size) => {
                slotData.sizes[size] = tempInventory4xs[pos].sizes[size];
            });
            if (Object.keys(slotData.sizes).length > 0)
                delete slotData.restricted;
            targetPerSlotInfo[pos] = slotData;
        }
    });
    Object.keys(tempInventoryReefers).forEach((pos) => {
        targetPerSlotInfo[pos].reefer = 1;
    });
    targetBay.perSlotInfo = targetPerSlotInfo;
    // B.2 Co-locate 40s LCGs
    const targetInfoByContLength = targetBay.infoByContLength || {};
    Object.keys(tempInventoryICL).map(Number).forEach((size) => {
        var _a, _b, _c, _d, _e, _f;
        const infoBySize = targetInfoByContLength[size];
        if (!infoBySize) {
            targetInfoByContLength[size] = tempInventoryICL[size];
        }
        else {
            targetInfoByContLength[size] = {
                size,
                lcg: (_a = infoBySize.lcg) !== null && _a !== void 0 ? _a : (_b = tempInventoryICL[size]) === null || _b === void 0 ? void 0 : _b.lcg,
                rowWeight: (_c = infoBySize.rowWeight) !== null && _c !== void 0 ? _c : (_d = tempInventoryICL[size]) === null || _d === void 0 ? void 0 : _d.rowWeight,
                bottomWeight: (_e = infoBySize.bottomWeight) !== null && _e !== void 0 ? _e : (_f = tempInventoryICL[size]) === null || _f === void 0 ? void 0 : _f.bottomWeight,
            };
        }
    });
    targetBay.infoByContLength = targetInfoByContLength;
    // B.3 Co-locate 40s ROW LCGs
    const targetPerRowInfo = targetBay.perRowInfo || {
        each: {},
        common: {},
    };
    //targetPerRowInfo.common = tempInventoryRowCommon;
    Object.keys(tempInventoryRowEach).forEach((row) => {
        var _a;
        const sizesToAdd = Object.keys(tempInventoryRowEach[row].rowInfoByLength || {}).map(Number);
        const rowInfo = ((_a = targetPerRowInfo.each) === null || _a === void 0 ? void 0 : _a[row]) || { isoRow: row };
        if (sizesToAdd.length > 0) {
            const rowInfoByLength = rowInfo.rowInfoByLength || {};
            sizesToAdd.forEach((size) => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                if (!rowInfoByLength[size])
                    rowInfoByLength[size] = { size };
                if (((_a = rowInfoByLength[size]) === null || _a === void 0 ? void 0 : _a.bottomWeight) === undefined)
                    rowInfoByLength[size].bottomWeight =
                        (_c = (_b = tempInventoryRowEach[row].rowInfoByLength) === null || _b === void 0 ? void 0 : _b[size]) === null || _c === void 0 ? void 0 : _c.bottomWeight;
                if (((_d = rowInfoByLength[size]) === null || _d === void 0 ? void 0 : _d.lcg) === undefined)
                    rowInfoByLength[size].lcg =
                        (_f = (_e = tempInventoryRowEach[row].rowInfoByLength) === null || _e === void 0 ? void 0 : _e[size]) === null || _f === void 0 ? void 0 : _f.lcg;
                if (((_g = rowInfoByLength[size]) === null || _g === void 0 ? void 0 : _g.rowWeight) === undefined)
                    rowInfoByLength[size].rowWeight =
                        (_j = (_h = tempInventoryRowEach[row].rowInfoByLength) === null || _h === void 0 ? void 0 : _h[size]) === null || _j === void 0 ? void 0 : _j.rowWeight;
            });
            rowInfo.rowInfoByLength = rowInfoByLength;
        }
        targetPerRowInfo.each[row] = rowInfo;
    });
    targetBay.perRowInfo = targetPerRowInfo;
    return {
        tempInventory4xs,
        tempInventoryICL,
        tempInventoryRowEach,
        currentPerSlotInfo,
        pairedBlsDict,
        pairedBls,
    };
}
