import { IFields } from "@tedivo/tedivo-form";
import { ISizeSummary } from "open-vessel-definition";
import MassUnitsEnum from "../../../../../../app/enums/MassUnitsEnum";
import { getTranslation } from "../../../../../../app/i18/i18tn";
import globalUnits from "../../../../../../app/units/globalUnits";
import { numberIsOdd } from "@tedivo/tedivo-pure-helpers";
import { z } from "zod";

const createFormFields = (
  label: string,
  startIsoBay: number,
  endIsoBay: number,
  portIsoRow: number,
  starboardIsoRow: number,
  weight: number,
  overlapPort: number,
  overlapStarboard: number,
  pk: string,
): IFields<ILidDataStore> => [
  {
    name: "startIsoBay",
    type: "number",
    label: "view:lids.startIsoBay",
    initialValue: startIsoBay,
    holderClassName: "field50centered",
  },
  [
    {
      name: "portIsoRow",
      type: "number",
      initialValue: portIsoRow,
      label: "view:lids.portIsoRow",
    },
    {
      name: "starboardIsoRow",
      type: "number",
      initialValue: starboardIsoRow,
      label: "view:lids.starboardIsoRow",
    },
  ],
  {
    name: "endIsoBay",
    type: "number",
    initialValue: endIsoBay,
    label: "view:lids.endIsoBay",
    holderClassName: "field50centered",
  },
  [
    {
      name: "label",
      type: "textBox",
      label: "general:common.label",
      initialValue: label,
    },
    {
      name: "weight",
      type: "numberWithUnits",
      label: "view:weight",
      initialValue: weight,
      converter: globalUnits.massUnits,
      helpText: `enums:MassUnitsEnum.${
        MassUnitsEnum[globalUnits.massUnits.units]
      }`,
    },
    {
      name: "pk",
      type: "hidden",
      initialValue: pk,
    },
  ],
  [
    {
      name: "overlapPort",
      type: "checkbox",
      label: "view:lids.overlapPort",
      initialValue: overlapPort,
    },
    {
      name: "overlapStarboard",
      type: "checkbox",
      label: "view:lids.overlapStarboard",
      initialValue: overlapStarboard,
    },
  ],
];

export default createFormFields;

export function createLidSchemaValidator(sizeSummary: ISizeSummary) {
  const lidEditSchema: z.Schema<ILidDataStore> = z
    .object({
      portIsoRow: z
        .number()
        .min(sizeSummary.centerLineRow ? 0 : 1)
        .max(sizeSummary.maxRow || 100),
      starboardIsoRow: z
        .number()
        .min(sizeSummary.centerLineRow ? 0 : 1)
        .max(sizeSummary.maxRow || 100),
      startIsoBay: z
        .number()
        .min(1)
        .max(sizeSummary.isoBays)
        .refine(numberIsOdd),
      endIsoBay: z.number().min(1).max(sizeSummary.isoBays).refine(numberIsOdd),
      weight: z.number().min(0).optional(),
      overlapPort: z.preprocess<z.ZodNumber>(
        (v) => (v ? 1 : 0),
        z.number(),
      ) as z.ZodEffects<z.ZodNumber>,
      overlapStarboard: z.preprocess<z.ZodNumber>(
        (v) => (v ? 1 : 0),
        z.number(),
      ) as z.ZodEffects<z.ZodNumber>,
      label: z.string().min(1),
      pk: z.string(),
    })
    .superRefine((values, ctx) => {
      const portIsEven = values.portIsoRow % 2 === 0,
        stbdIsEven = values.starboardIsoRow % 2 === 0;

      let res = true;

      if (portIsEven && stbdIsEven)
        res = values.portIsoRow >= values.starboardIsoRow;

      if (portIsEven && !stbdIsEven) res = true;

      if (!portIsEven && stbdIsEven) res = false;

      if (!portIsEven && !stbdIsEven)
        res = values.portIsoRow <= values.starboardIsoRow;

      if (!res) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: getTranslation("errors:formCustom.stbdRowRightOfPort"),
          path: ["portIsoRow"],
        });

        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: getTranslation("errors:formCustom.stbdRowRightOfPort"),
          path: ["starboardIsoRow"],
        });
      }
    })
    .superRefine((values, ctx) => {
      if (values.endIsoBay < values.startIsoBay) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: getTranslation(
            "errors:formCustom.endIsoBayGreaterThanStartIsoBay",
          ),
          path: ["startIsoBay"],
        });

        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: getTranslation(
            "errors:formCustom.endIsoBayGreaterThanStartIsoBay",
          ),
          path: ["endIsoBay"],
        });
      }
    });

  return lidEditSchema;
}

export interface ILidDataStore extends Record<string, unknown> {
  label?: string;
  portIsoRow: number;
  starboardIsoRow: number;
  startIsoBay: number;
  endIsoBay: number;
  overlapPort: number;
  overlapStarboard: number;
  weight?: number;
  pk: string;
}
