import "@shoelace-style/shoelace/dist/components/button/button";
import "@shoelace-style/shoelace/dist/components/dropdown/dropdown";
import "@shoelace-style/shoelace/dist/components/menu-item/menu-item";
import "@shoelace-style/shoelace/dist/components/menu/menu";
import { changeLanguage, i18n, supportedLanguages, } from "../../../app/i18/i18tn";
import { getPreferencesValue, setPreferencesKeyAndValue, } from "@tedivo/tedivo-preferences";
import { createSelectShoelace } from "@tedivo/tedivo-form";
export class TVDLangSelectorElement extends HTMLElement {
    constructor() {
        super(...arguments);
        this.currentLang = supportedLanguages[0];
        this.onChangeLanguage = (v) => {
            if (this.currentLang === v)
                return;
            this.currentLang = v;
            setPreferencesKeyAndValue("i18n-language", v);
            changeLanguage(this.currentLang);
            document.documentElement.setAttribute("lang", v);
        };
    }
    connectedCallback() {
        const savedLanguage = getPreferencesValue("i18n-language");
        this.currentLang =
            savedLanguage || i18n.language;
        const languageSelect = createSelectShoelace({
            id: "language-selector",
            buttonText: this.currentLang.toUpperCase(),
            caret: true,
            pill: true,
            size: "small",
            options: supportedLanguages.map((lang) => ({
                value: lang,
                name: lang.toUpperCase(),
            })),
            onChange: this.onChangeLanguage,
            updateButtonText: (v) => v.toUpperCase(),
        });
        languageSelect.value = this.currentLang;
        this.appendChild(languageSelect);
    }
}
TVDLangSelectorElement.observedAttributes = [];
customElements.define("tvd-language-sel-element", TVDLangSelectorElement);
