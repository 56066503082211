import { IntegratedDialog } from "./IntegratedDialog";

export class IntegratedDialogError extends IntegratedDialog {
  constructor(
    parentNode: HTMLElement,
    removeDialogWhenHidden: boolean,
    closeLabel: string,
    onDismiss?: (v: string) => boolean | Promise<boolean> | void,
  ) {
    super({
      parentNode,
      removeDialogWhenHidden,
      buttonsAttrs: [
        {
          label: closeLabel,
          value: "close",
          type: "submit",
          autofocus: true,
        },
      ],
    });

    if (onDismiss) {
      this.onButtonClicked = onDismiss;
    }
  }
}
