import CellSpacingEnum from "../../../../../app/enums/CellSpacingEnum";
import SizeSmallMidLargeEnum from "../../../../../app/enums/SizeSmallMidLargeEnum";
import VcgVerticalReferenceEnum from "../../../../../app/enums/VcgVerticalReferenceEnum";

export const GRID_OPTIONS_BY_CELL_DIMENSION = {
  [SizeSmallMidLargeEnum.SMALL]: {
    containerWidth: 20,
    containerHeight: 20,
    belowGridSeparation: 20,
    gridNumbersFontSize: 9,
    lidHeight: 6,
  },
  [SizeSmallMidLargeEnum.MIDDLE]: {
    containerWidth: 30,
    containerHeight: 36,
    belowGridSeparation: 36,
    gridNumbersFontSize: 9,
    lidHeight: 11,
  },
  [SizeSmallMidLargeEnum.LARGE]: {
    containerWidth: 40,
    containerHeight: 48,
    belowGridSeparation: 48,
    gridNumbersFontSize: 10,
    lidHeight: 15,
  },
  [SizeSmallMidLargeEnum.XLARGE]: {
    containerWidth: 70,
    containerHeight: 80,
    belowGridSeparation: 180,
    gridNumbersFontSize: 11,
    lidHeight: 40,
  },
};

export const GRID_OPTIONS_BY_CELL_GAP = {
  [CellSpacingEnum.NO_SPACE]: {
    cellSeparation: 0,
  },
  [CellSpacingEnum.SPACED]: { cellSeparation: 5 },
};

export const SLOT_OPTIONS_BY_CELL_DIMENSION = {
  [SizeSmallMidLargeEnum.SMALL]: {
    slotWidth: 20,
    slotHeight: 20,
    fontSize: 0,
    labelWidth: 6,
    labelHeight: 6,
    labelGap: 1,
  },
  [SizeSmallMidLargeEnum.MIDDLE]: {
    slotWidth: 30,
    slotHeight: 36,
    fontSize: 7,
    labelWidth: 12,
    labelHeight: 10,
    labelGap: 1,
  },
  [SizeSmallMidLargeEnum.LARGE]: {
    slotWidth: 40,
    slotHeight: 48,
    fontSize: 9,
    labelWidth: 14,
    labelHeight: 12,
    labelGap: 1,
  },
  [SizeSmallMidLargeEnum.XLARGE]: {
    slotWidth: 70,
    slotHeight: 80,
    fontSize: 12,
    labelWidth: 20,
    labelHeight: 18,
    labelGap: 1,
  },
};

export const VCG_REF_TO_FACTOR: Record<VcgVerticalReferenceEnum, number> = {
  [VcgVerticalReferenceEnum.BOTTOM_BASE]: 0,
  [VcgVerticalReferenceEnum.VCG_45_8_6]: 0.45,
  [VcgVerticalReferenceEnum.VCG_50_8_6]: 0.5,
};
