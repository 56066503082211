import Services from "../../../app/services";
import SlDialog from "@shoelace-style/shoelace/dist/components/dialog/dialog";
import SlInput from "@shoelace-style/shoelace/dist/components/input/input";
import { getTranslation } from "../../../app/i18/i18tn";
import goSquared from "../../../app/tracking/goSquared";
import { removeChildren } from "@tedivo/tedivo-dom-helpers";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";

export function cloneFileDialog({
  onlyCommunity,
  modal,
  fileData,
  actionLabel,
  goSquaredLabel,
}: {
  onlyCommunity: boolean;
  modal: SlDialog;
  fileData: {
    name: string;
    fileId: string;
    organizationId: string;
  };
  actionLabel: string;
  goSquaredLabel: string;
}) {
  let newName = "";

  modal.label = getTranslation(actionLabel);

  removeChildren(modal);

  const htmlContent = document.createElement("div");
  htmlContent.innerHTML = `<strong>${fileData.name}</strong>`;

  const closeBtn = document.createElement("sl-button");
  closeBtn.slot = "footer";
  closeBtn.variant = "primary";
  closeBtn.autofocus = true;
  closeBtn.tabIndex = 0;
  closeBtn.innerHTML = getTranslation(actionLabel);

  closeBtn.addEventListener(
    "click",
    async () => {
      goSquared.addEvent(goSquaredLabel);

      const spinner = document.createElement("sl-spinner");
      spinner.style.fontSize = "1rem";

      removeChildren(htmlContent);
      htmlContent.appendChild(spinner);

      closeBtn.disabled = true;
      closeBtn.loading = true;

      try {
        const res = await Services.files.clone(
          fileData.fileId,
          fileData.organizationId,
          onlyCommunity,
          newName,
        );

        closeBtn.loading = false;

        switch (res.statusCode) {
          case 200:
            modal.hide();
            if (res.data?.id) router.navigate(routeFns.ovdEdit(res.data.id));
            break;
          case 409:
            closeBtn.disabled = false;
            removeChildren(htmlContent);
            htmlContent.appendChild(createNameTextBox());
            break;
          case 402:
            closeBtn.disabled = false;
            htmlContent.className = "text-danger";
            htmlContent.innerHTML = getTranslation(
              "errors:maxFreeDownloadsReached",
            );
            break;
          default:
            throw res.code || res.message || "Error cloning file";
        }
      } catch (err) {
        removeChildren(htmlContent);
        htmlContent.className = "text-danger";
        htmlContent.innerHTML = getTranslation(err as string);
      }
    },
    false,
  );

  modal.appendChild(htmlContent);
  modal.appendChild(closeBtn);
  modal.show();

  function createNameTextBox(): SlInput {
    const input = document.createElement("sl-input");
    input.type = "text";
    input.label = getTranslation("general:search.setVesselName");
    input.placeholder = getTranslation("general:common.name");
    input.autofocus = true;
    input.value = fileData.name;
    input.addEventListener("keyup", updateNameVar, false);
    input.addEventListener("change", updateNameVar, false);

    function updateNameVar() {
      newName = input.value.toUpperCase().trim();
    }

    return input;
  }
}
