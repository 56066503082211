import { __rest } from "tslib";
import { createRowsFromConfig, getRowsAndTiersFromSlotKeys, } from "@tedivo/tedivo-bay-grid-pure";
import { pad2 } from "@tedivo/tedivo-pure-helpers";
export function postProcessingForm(shipData, result, isoBay, level, prevBayData, newSlotsData) {
    var _a;
    const { notes, pairedBay, doors, reeferPlugs, centerLineRow, athwartShip, foreHatch, ventilated, heatSrcFore, ignitionSrcFore, quartersFore, engineRmBulkFore, telescoping } = result, restOfFormData = __rest(result, ["notes", "pairedBay", "doors", "reeferPlugs", "centerLineRow", "athwartShip", "foreHatch", "ventilated", "heatSrcFore", "ignitionSrcFore", "quartersFore", "engineRmBulkFore", "telescoping"]);
    const bayData = Object.assign(Object.assign(Object.assign({}, prevBayData), restOfFormData), { isoBay,
        level, pairedBay: pairedBay || undefined, doors: doors || undefined, reeferPlugs: reeferPlugs || undefined, centerLineRow: centerLineRow ? 1 : 0, athwartShip: athwartShip ? 1 : undefined, foreHatch: foreHatch ? 1 : undefined, ventilated: ventilated ? 1 : undefined, heatSrcFore: heatSrcFore ? 1 : undefined, ignitionSrcFore: ignitionSrcFore ? 1 : undefined, quartersFore: quartersFore ? 1 : undefined, engineRmBulkFore: engineRmBulkFore ? 1 : undefined, telescoping: telescoping ? 1 : undefined, meta: { notes }, infoByContLength: (prevBayData === null || prevBayData === void 0 ? void 0 : prevBayData.infoByContLength) || {} });
    if (newSlotsData !== undefined) {
        const newPerSlotInfo = {};
        const newPerRowInfo = { each: {}, common: {} };
        const newSlotsDataPosArray = newSlotsData.map((slotData) => slotData.pos);
        const { maxRow, centerLineRow, tiersByRow } = getRowsAndTiersFromSlotKeys(newSlotsDataPosArray);
        // 1. perSlotInfo
        newSlotsData.forEach((slotData) => {
            const pos = slotData.pos;
            newPerSlotInfo[pos] = slotData;
        });
        bayData.perSlotInfo = newPerSlotInfo;
        // 2. perRowInfo
        const newRows = createRowsFromConfig(!!centerLineRow, maxRow);
        const prevRowsCalcs = getRowsAndTiersFromSlotKeys(((prevBayData === null || prevBayData === void 0 ? void 0 : prevBayData.perSlotInfo)
            ? Object.keys(prevBayData === null || prevBayData === void 0 ? void 0 : prevBayData.perSlotInfo)
            : []));
        let rowsThatDifferInMinTier = 0;
        newRows.forEach((row) => {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            const prevRowInfoClonedOrNew = ((_b = (_a = prevBayData === null || prevBayData === void 0 ? void 0 : prevBayData.perRowInfo) === null || _a === void 0 ? void 0 : _a.each) === null || _b === void 0 ? void 0 : _b[row])
                ? Object.assign(Object.assign({}, (_c = prevBayData.perRowInfo.each) === null || _c === void 0 ? void 0 : _c[row]), { rowInfoByLength: Object.assign({}, (_d = prevBayData.perRowInfo.each) === null || _d === void 0 ? void 0 : _d[row].rowInfoByLength) }) : { isoRow: row };
            if (((_e = prevRowsCalcs.tiersByRow[row]) === null || _e === void 0 ? void 0 : _e.minTier) !== ((_f = tiersByRow[row]) === null || _f === void 0 ? void 0 : _f.minTier)) {
                const newMinTier = ((_g = tiersByRow[row]) === null || _g === void 0 ? void 0 : _g.minTier) !== undefined
                    ? pad2((_h = tiersByRow[row]) === null || _h === void 0 ? void 0 : _h.minTier)
                    : undefined;
                prevRowInfoClonedOrNew.bottomBase =
                    newMinTier !== undefined
                        ? shipData.masterCGs.bottomBases[newMinTier]
                        : undefined;
                prevRowInfoClonedOrNew.maxHeight;
                rowsThatDifferInMinTier += 1;
            }
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            newPerRowInfo.each[row] = prevRowInfoClonedOrNew;
        });
        if (rowsThatDifferInMinTier < prevRowsCalcs.rows.length) {
            newPerRowInfo.common = Object.assign({}, (_a = prevBayData === null || prevBayData === void 0 ? void 0 : prevBayData.perRowInfo) === null || _a === void 0 ? void 0 : _a.common);
        }
        bayData.perRowInfo = newPerRowInfo;
    }
    return bayData;
}
