import { TitleWithActions } from "../titleWithActions/title-with-actions.component";
import { stylesFromStringToTag } from "@tedivo/tedivo-dom-helpers";

export class SectionElement extends HTMLElement {
  public static observedAttributes = [];

  public titleHtml = "";
  public name = "";

  public intActions: HTMLElement | null | undefined = null;
  public titlePart: TitleWithActions;

  get actions() {
    return this.intActions;
  }

  set actions(v: HTMLElement | null | undefined) {
    if (!v) return;

    this.intActions = v;
    const actionsDiv = document.createElement("div");
    actionsDiv.appendChild(v);
    actionsDiv.slot = "actions";
    this.titlePart.appendChild(actionsDiv);
  }

  constructor() {
    super();
    this.titlePart = document.createElement("title-with-actions-component");
  }

  connectedCallback() {
    const shadowRoot = this.attachShadow({ mode: "open" });
    const holder = document.createElement("div");
    holder.className = "section-element-holder";

    const titlePart = this.titlePart;
    titlePart.titleHtml = this.titleHtml;
    titlePart.titleNodeName = "h4";
    this.titlePart = titlePart;

    const slot = document.createElement("slot");

    holder.appendChild(titlePart);
    holder.appendChild(slot);

    shadowRoot.appendChild(stylesFromStringToTag(styles));
    shadowRoot.appendChild(holder);
  }
}

customElements.define("section-element", SectionElement);

declare global {
  interface HTMLElementTagNameMap {
    "section-element": SectionElement;
  }
}

const styles = `
  .section-element-holder {
    margin-bottom: var(--sl-spacing-medium);
  }

  .section-element-holder h3 {
    border-bottom: solid var(--border-width) var(--border-color);
  }

  title-with-actions-component {
    display: flex;
  }
`;
