import { IFile } from "@tedivo/tvd-api-models";

export default function showCGsCell(dta: IFile): HTMLElement {
  const holder = document.createElement("div");
  holder.className = "tvd-cgs-holder";
  holder.innerHTML = (dta.cgsPercentage || 0) + "<small>%</small> ";

  const spLcg = document.createElement("span");
  spLcg.className = `tvd-cgs-dot ${colorLevel(dta.lcgsPercentage)}`;
  spLcg.title = `LCGs ${dta.lcgsPercentage || 0}%`;
  holder.appendChild(spLcg);

  const spTcg = document.createElement("span");
  spTcg.className = `tvd-cgs-dot ${colorLevel(dta.tcgsPercentage)}`;
  spTcg.title = `TCGs ${dta.tcgsPercentage || 0}%`;
  holder.appendChild(spTcg);

  const spVcg = document.createElement("span");
  spVcg.className = `tvd-cgs-dot ${colorLevel(dta.vcgsPercentage)}`;
  spVcg.title = `VCGs ${dta.vcgsPercentage || 0}%`;
  holder.appendChild(spVcg);

  return holder;

  function colorLevel(n: number | undefined) {
    if (n === undefined || n === 0) return "gray";
    if (n < 50) return "orange";
    if (n < 100) return "yellow";
    if (n === 100) return "green";
  }
}
