import { routeFns } from "../router/routes";
import router from "../router";
import securityModule from "../security/SecurityModule";

export function bindSecurityModuleEvents() {
  securityModule.addEventListener("httpCallProhibited", () => {
    router.navigate(routeFns.myCloud());
  });

  securityModule.addEventListener("sessionTimedOut", () => {
    router.navigate(routeFns.login());
  });
}
