import { I20LcgsByBay } from "../../types/I20Lcgs";
import { IBaySizesAndCgs } from "../../types/IGetBayLcgVcgAndPairingsResult";
import { fillMissingPlausibleData } from "./fillMissingPlausibleData";
import { find20Lcgs } from "./find20Lcgs";

export function findForCompleteCGs(
  bayLevelSizesAndCgsBelow: IBaySizesAndCgs[],
  bayLevelSizesAndCgsAbove: IBaySizesAndCgs[],
  lcgsBy20Bay: I20LcgsByBay,
) {
  [...bayLevelSizesAndCgsBelow, ...bayLevelSizesAndCgsAbove]
    .filter(
      (b) =>
        lcgsBy20Bay[b.isoBay]?.lcg === undefined &&
        b.maxSizeLcg?.size !== undefined,
    )
    .forEach(find20Lcgs(lcgsBy20Bay));

  fillMissingPlausibleData(lcgsBy20Bay);
}
