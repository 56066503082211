import {
  BayLevelEnum,
  ForeAftEnum,
  IOpenVesselDefinitionV1,
} from "open-vessel-definition";
import { IFields, TedivoForm, translateTedivoForm } from "@tedivo/tedivo-form";
import {
  IGetPairedBaysOutput,
  getAllThePairedBays,
} from "@tedivo/tedivo-bay-grid-pure";
import ovdJsonStore, {
  OVDChangesEnum,
} from "../../../../../../../app/stores/OVDJsonStore";

import { SmartTable } from "@tedivo/tedivo-smart-table";
import { extract40sOfPairedBays } from "./move40sOfPairedBays";
import { getTranslation } from "../../../../../../../app/i18/i18tn";
import goSquared from "../../../../../../../app/tracking/goSquared";
import { z } from "zod";

export function createMoveAll40s(
  json: IOpenVesselDefinitionV1 | undefined,
): IEditReturn {
  if (!json) {
    return { node: document.createElement("div"), submitFunction: () => false };
  }

  goSquared.addEvent("Edit-OVD - Move All 40s to paired bay");

  const holder = document.createElement("div");
  const tedivoForm = createMoveAll40sForm(json);
  holder.appendChild(tedivoForm.form);

  return {
    node: holder,
    submitFunction: submitPassedToEditDrawer,
  };

  function submitPassedToEditDrawer(): boolean {
    if (!json) return false;

    const validate = tedivoForm.doSubmitForm();
    if (!validate.success) return false;

    const direction = tedivoForm.getValues().direction;
    const { pairedBays } = getAllThePairedBays(json.baysData, true);

    pairedBays.forEach((pair) => {
      const res = extract40sOfPairedBays({
        isoBay: pair.base,
        level: pair.level,
        bls: json.baysData,
        target40s: direction,
        setRestrictedOnEmptySlots: true,
      });

      if (res) {
        res.pairedBls.forEach((bl) => {
          ovdJsonStore.replaceBayInfo(bl.isoBay, bl.level, bl, false);
        });
      }
    });

    ovdJsonStore.emitChange({ type: OVDChangesEnum.ALL_BAYS });

    goSquared.addEvent("Edit-OVD - Move All 40s to paired bay - Save");

    return true;
  }
}

function createMoveAll40sForm(
  json: IOpenVesselDefinitionV1,
): TedivoForm<ITranslate40sForm> {
  const fields: IFields<ITranslate40sForm> = [
    {
      name: "direction",
      type: "radioButtonList",
      label: "view:edit.helpers.c40sExtendDirection",
      initialValue: ForeAftEnum.FWD,
      isNumericEnum: true,
      // helpText: "view:edit.helpers.c40sExtendDirectionHelp",
      fieldset: true,
      options: [
        {
          value: ForeAftEnum.FWD,
          label: getTranslation("enums:ForeAftEnum.FWD"),
        },
        {
          value: ForeAftEnum.AFT,
          label: getTranslation("enums:ForeAftEnum.AFT"),
        },
      ],
    },
    {
      name: "confirm",
      type: "checkbox",
      label: "view:edit.helpers.c40sExtendDirectionConfirm",
      initialValue: false,
    },
    {
      name: "confirmCGs",
      type: "checkbox",
      label: "view:edit.helpers.c40sExtendDirectionConfirmCGs",
      initialValue: false,
    },
  ];

  const validator: z.Schema<ITranslate40sForm> = z.object({
    direction: z.nativeEnum(ForeAftEnum),
    confirm: z.boolean().refine((v) => !!v),
    confirmCGs: z.boolean().refine((v) => !!v),
  });

  const tedivoForm = new TedivoForm<ITranslate40sForm>({
    fields,
    onSubmit: () => null,
    formValidator: validator,
    submitButton: document.createElement("button"),
  });

  translateTedivoForm<ITranslate40sForm>({ tedivoForm, getTranslation });

  const { unpairedBays } = getAllThePairedBays(json.baysData, true);

  if (unpairedBays.length > 0) {
    const example = new SmartTable<
      IGetPairedBaysOutput["unpairedBays"][0]
    >().initialize({
      className: "tvd-table tvd-table-smart-table min-width-100-ch small-title",
      data: unpairedBays,
      defaultSort: "base",
      title: getTranslation("view:edit.helpers.baysNotPaired"),
      fields: [
        {
          name: "base",
          label: getTranslation("general:grid.bay"),
          className: "centered",
        },
        {
          name: "level",
          label: getTranslation("general:grid.level"),
          className: "centered",
          mapper: (dta) =>
            getTranslation(`enums:BayLevelEnum.${BayLevelEnum[dta.level]}`),
        },
      ],
      settings: {
        sortEnabled: false,
        sortVisible: false,
        filterEnabled: false,
      },
      pkFunction: (dta) => `${dta.base}-${dta.level}`,
    });

    tedivoForm.form.appendChild(document.createElement("p"));
    tedivoForm.form.appendChild(example);
  }

  return tedivoForm;
}

export interface ITranslate40sForm extends Record<string, unknown> {
  direction: ForeAftEnum;
  confirm: boolean;
  confirmCGs: boolean;
}

interface IEditReturn {
  node: HTMLElement;
  submitFunction: () => boolean;
}
