import { __awaiter } from "tslib";
import { ALL_SINGLE_RENDER_METHODS, OVD_EVENT_TO_SECTIONS_MAP, } from "./renderSectionsMap";
import BaysSection from "../parts/bay-boxes/bays-section";
import { mapDataToDictionary, } from "@tedivo/tedivo-ui";
import { calculateTeusFromSlots, } from "@tedivo/tedivo-bay-grid-pure";
import ViewCgsConfig from "../config/ViewCgsConfig";
import { getTranslation, i18n } from "../../../../app/i18/i18tn";
import ovdJsonStore from "../../../../app/stores/OVDJsonStore";
import CellSpacingEnum from "../../../../app/enums/CellSpacingEnum";
import GroupBaysPairedEnum from "../../../../app/enums/GroupBaysPairedEnum";
import SizeSmallMidLargeEnum from "../../../../app/enums/SizeSmallMidLargeEnum";
import ViewShipDataConfig from "../config/ViewShipDataConfig";
import ViewSizeSummaryConfig from "../config/ViewSizeSummaryConfig";
import { createDeckView } from "./createDeckView";
import { createPart } from "./createPart";
import { createSideView } from "./createSideView";
import { getPreferencesValue } from "@tedivo/tedivo-preferences";
import getTranslatedDictionary from "../../../../app/i18/getTranslatedDictionary";
import { removeChildren } from "@tedivo/tedivo-dom-helpers";
export default class ViewJsonRender {
    get node() {
        return this.mainNode;
    }
    set viewD3ClearColor(color) {
        if (!this.rendererForShip)
            return;
        this.rendererForShip.colorMode = color;
    }
    get bayGroupedDisplay() {
        return this.bayDrawOptions.baysPairedGrouped;
    }
    set bayGroupedDisplay(value) {
        if (this.bayDrawOptions.baysPairedGrouped !== value)
            this.bayDrawOptions.baysPairedGrouped = value;
        this.nodes.baySection.bayBoxes.setAttribute("data-group-paired-bays", String(value === GroupBaysPairedEnum.GROUPED));
    }
    get fit2Vertical() {
        return this.bayDrawOptions.fit2Vertical;
    }
    set fit2Vertical(value) {
        var _a;
        if (this.bayDrawOptions.fit2Vertical !== value)
            this.bayDrawOptions.fit2Vertical = value;
        (_a = this.baysSection) === null || _a === void 0 ? void 0 : _a.calcAndSetScale(value);
    }
    constructor(props) {
        this.context = {};
        this.rendererForShip = undefined;
        this.renderGeneral = () => {
            const json = this.props.json;
            if (!json)
                return;
            this.nodes.general.withLoading(() => __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const shipNameAkas = (((_b = (_a = ovdJsonStore.tvdId) === null || _a === void 0 ? void 0 : _a.shipNameAkaStr) === null || _b === void 0 ? void 0 : _b.split("|")) || []).filter((s) => s && s !== json.shipData.shipName);
                const viewShipData = Object.assign(Object.assign({}, json.shipData), { shipNameAkas: shipNameAkas.length ? shipNameAkas : undefined });
                const shipPartDataElements = getTranslatedDictionary(mapDataToDictionary(viewShipData, ViewShipDataConfig, i18n.language));
                const partConfig = {
                    name: "shipData",
                    title: "view:shipDataTitle",
                    elements: shipPartDataElements,
                    editAction: (_c = this.context.buttonsAndActions) === null || _c === void 0 ? void 0 : _c.editGeneralButton,
                };
                const htmlOutput = createPart(partConfig.name, partConfig.title, partConfig.elements, partConfig.editAction);
                removeChildren(this.nodes.general);
                this.nodes.general.dataset.lastUpdated = new Date().toISOString();
                this.nodes.general.appendChild(htmlOutput);
            }));
        };
        this.renderSizeSummary = () => {
            const json = this.props.json;
            if (!json)
                return;
            this.nodes.sizeSummary.withLoading(() => __awaiter(this, void 0, void 0, function* () {
                var _a;
                const sizeSummaryDataElements = getTranslatedDictionary(mapDataToDictionary(Object.assign(Object.assign({}, json.sizeSummary), { totalTEUs: this.context.totalTEUs || 0 }), ViewSizeSummaryConfig, i18n.language));
                const partConfig = {
                    name: "sizeSummary",
                    title: "view:sizeSummaryTitle",
                    elements: sizeSummaryDataElements,
                    editAction: (_a = this.context.buttonsAndActions) === null || _a === void 0 ? void 0 : _a.editSizeSummaryButton,
                };
                const htmlOutput = createPart(partConfig.name, partConfig.title, partConfig.elements, partConfig.editAction);
                removeChildren(this.nodes.sizeSummary);
                this.nodes.sizeSummary.dataset.lastUpdated = new Date().toISOString();
                this.nodes.sizeSummary.appendChild(htmlOutput);
            }));
        };
        this.renderCenterOfGravity = () => {
            var _a;
            const json = this.props.json;
            const cgsStats = (_a = this.props.lcgVcgTcgAndPairings) === null || _a === void 0 ? void 0 : _a.cgsStats;
            if (!json || !cgsStats)
                return;
            this.nodes.centersOfGravity.withLoading(() => __awaiter(this, void 0, void 0, function* () {
                var _a;
                const cgsPartData = {
                    lcgValues: json.shipData.lcgOptions.values,
                    vcgValues: json.shipData.vcgOptions.values,
                    tcgValues: json.shipData.tcgOptions.values,
                    lcgDataFilled: Math.floor((cgsStats.definedLcgs / (cgsStats.countLcgs || 1)) * 100),
                    vcgDataFilled: Math.floor((cgsStats.definedBottomBase / (cgsStats.countBottomBase || 1)) * 100),
                    tcgDataFilled: Math.floor((cgsStats.definedTcgs / (cgsStats.countTcgs || 1)) * 100),
                    lpp: json.shipData.lcgOptions.lpp,
                    loa: json.shipData.loa || 0,
                    sternToAftPp: json.shipData.sternToAftPp || 0,
                };
                const cgsPartDataElements = getTranslatedDictionary(mapDataToDictionary(cgsPartData, ViewCgsConfig, i18n.language));
                const partConfig = {
                    name: "cgsSummary",
                    title: "view:cgsDataTitle",
                    elements: cgsPartDataElements,
                    disabled: !this.props.canOpenDrawer,
                    editAction: (_a = this.context.buttonsAndActions) === null || _a === void 0 ? void 0 : _a.editCGsButton,
                };
                const htmlOutput = createPart(partConfig.name, partConfig.title, partConfig.elements, partConfig.editAction);
                removeChildren(this.nodes.centersOfGravity);
                this.nodes.centersOfGravity.dataset.lastUpdated =
                    new Date().toISOString();
                this.nodes.centersOfGravity.appendChild(htmlOutput);
            }));
        };
        this.renderD3View = () => __awaiter(this, void 0, void 0, function* () {
            const json = this.props.json;
            const lcgVcgTcgAndPairings = this.props.lcgVcgTcgAndPairings;
            if (!json ||
                !lcgVcgTcgAndPairings ||
                lcgVcgTcgAndPairings.totalSlotsCount < 10)
                return;
            // if (!this.rendererForShip) {
            //   import(
            //     /* webpackChunkName: "create3DView", webpackPrefetch: true */ "./create3DView"
            //   ).then((module) => {
            //     const { container, renderer } = module.create3DView({
            //       shipData: json.shipData,
            //       sizeSummary: json.sizeSummary,
            //       baysData: json.baysData,
            //       vesselPartsData: json.vesselPartsData || [],
            //       lcgVcgTcgAndPairings,
            //       adjustedBottomBases:
            //         ovdJsonStore.tvdId?.v3DParams?.adjustedBottomBases,
            //       v3DParams: ovdJsonStore.tvdId?.v3DParams,
            //       colorMode: this.props.colorMode,
            //     });
            //     this.rendererForShip = renderer;
            //     const { card, toggleActivate } = create3DCardNodes(
            //       this.rendererForShip,
            //       this.context.buttonsAndActions?.editView3DActions,
            //     );
            //     card.isLoading = false;
            //     card.appendChild(container);
            //     card.appendChild(toggleActivate);
            //     removeChildren(this.nodes.d3View);
            //     this.nodes.d3View.dataset.lastUpdated = new Date().toISOString();
            //     this.nodes.d3View.appendChild(card);
            //     renderer.onWindowResize();
            //     renderer.shouldAnimate = 3000;
            //     renderer.animate();
            //     setTimeout(() => {
            //       renderer.resetView();
            //       renderer.active = false;
            //       toggleActivate.checked = false;
            //     }, 3000);
            //   });
            // } else {
            //   this.rendererForShip.reInit({
            //     shipData: json.shipData,
            //     sizeSummary: json.sizeSummary,
            //     baysData: json.baysData,
            //     vesselPartsData: json.vesselPartsData || [],
            //     lcgVcgTcgAndPairings,
            //     adjustedBottomBases: ovdJsonStore.tvdId?.v3DParams?.adjustedBottomBases,
            //     v3DParams: ovdJsonStore.tvdId?.v3DParams,
            //   });
            // }
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            function create3DCardNodes(rendererForShip, editView3DActions) {
                // Action Box
                const view3DActionBox = document.createElement("div");
                // Reset View Button
                const reset3DViewButton = document.createElement("sl-button");
                reset3DViewButton.size = "small";
                reset3DViewButton.pill = true;
                reset3DViewButton.innerHTML = getTranslation("view:view3D.view3DActions.reset");
                reset3DViewButton.addEventListener("click", () => {
                    rendererForShip === null || rendererForShip === void 0 ? void 0 : rendererForShip.resetView();
                });
                view3DActionBox.appendChild(reset3DViewButton);
                // Edit Button
                if (editView3DActions)
                    view3DActionBox.appendChild(editView3DActions);
                // Card of 3D View
                const card = document.createElement("card-element");
                card.titleHtml = "view:3dView.title";
                card.className = "card-3d-view";
                card.name = "3dView";
                card.isLoading = true;
                card.actions = view3DActionBox;
                // Activate toggle Button
                const toggleActivate = document.createElement("sl-switch");
                toggleActivate.size = "small";
                toggleActivate.innerHTML = getTranslation("view:view3D.view3DActions.activate");
                toggleActivate.className = "btn-activate-3d";
                toggleActivate.addEventListener("sl-change", () => {
                    if (!rendererForShip)
                        return;
                    rendererForShip.active = toggleActivate.checked;
                });
                return {
                    card,
                    toggleActivate,
                };
            }
        });
        this.render = (json, event, lcgVcgTcgAndPairings) => {
            var _a;
            if (!json || !lcgVcgTcgAndPairings) {
                console.warn("No data to render");
                return;
            }
            // 1. Update context
            this.context.totalTEUs = calculateTeusFromSlots(json.baysData);
            this.context.buttonsAndActions = this.props.createEditButtonsAndActions(lcgVcgTcgAndPairings);
            // 2. Select render methods
            const type = (_a = event === null || event === void 0 ? void 0 : event.detail) === null || _a === void 0 ? void 0 : _a.type;
            const renderMethods = type
                ? OVD_EVENT_TO_SECTIONS_MAP[type] || ALL_SINGLE_RENDER_METHODS
                : ALL_SINGLE_RENDER_METHODS;
            // 3. Render
            renderMethods.forEach((method) => this[method](event === null || event === void 0 ? void 0 : event.detail));
        };
        this.props = props;
        this.bayDrawOptions = getBayDrawPreferences();
        this.mainNode = document.createElement("div");
        this.nodes = this.buildHtml(this.mainNode);
        this.bayGroupedDisplay = this.bayDrawOptions.baysPairedGrouped;
        this.fit2Vertical = this.bayDrawOptions.fit2Vertical;
        this.render(props.json, undefined, props.lcgVcgTcgAndPairings);
    }
    updateData({ event, json, lcgVcgTcgAndPairings, }) {
        if (!json || !lcgVcgTcgAndPairings) {
            console.warn("No data to render");
            return;
        }
        // 1. Update data
        this.props.json = json;
        this.props.lcgVcgTcgAndPairings = lcgVcgTcgAndPairings;
        this.render(json, event, lcgVcgTcgAndPairings);
    }
    /** Updates the size and spacing options and redraws all the bays */
    updateDrawPreferences(size, cellSpacing, newBayGrouped, fit2Vertical) {
        if (size !== undefined)
            this.bayDrawOptions.cellSize = size;
        if (cellSpacing !== undefined)
            this.bayDrawOptions.cellSpacing = cellSpacing;
        if (newBayGrouped !== undefined)
            this.bayGroupedDisplay = newBayGrouped;
        if (fit2Vertical !== undefined)
            this.fit2Vertical = fit2Vertical;
        this.renderBays();
    }
    renderSideView() {
        const json = this.props.json;
        const lcgVcgTcgAndPairings = this.props.lcgVcgTcgAndPairings;
        if (!json || !lcgVcgTcgAndPairings)
            return;
        this.nodes.sideView.withLoading(() => __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c;
            const htmlOutput = createSideView({
                sizeSummary: json.sizeSummary,
                baysData: json.baysData,
                vesselPartsData: json.vesselPartsData || [],
                adjustedBottomBases: (_b = (_a = ovdJsonStore.tvdId) === null || _a === void 0 ? void 0 : _a.v3DParams) === null || _b === void 0 ? void 0 : _b.adjustedBottomBases,
                lcgVcgTcgAndPairings,
                editActionNode: (_c = this.context.buttonsAndActions) === null || _c === void 0 ? void 0 : _c.editSideViewActions,
                bayNumbersDoubleClickAction: doubleClickOnSvgBay,
            });
            removeChildren(this.nodes.sideView);
            this.nodes.sideView.dataset.lastUpdated = new Date().toISOString();
            this.nodes.sideView.appendChild(htmlOutput);
        }));
    }
    renderDeckView() {
        var _a;
        const json = this.props.json;
        const lcgVcgTcgAndPairings = this.props.lcgVcgTcgAndPairings;
        if (!json || !lcgVcgTcgAndPairings)
            return;
        const htmlOutput = createDeckView({
            sizeSummary: json.sizeSummary,
            masterCGs: json.shipData.masterCGs,
            baysData: json.baysData,
            lidData: json.lidData,
            vesselPartsData: json.vesselPartsData || [],
            lcgVcgTcgAndPairings,
            editActionNode: (_a = this.context.buttonsAndActions) === null || _a === void 0 ? void 0 : _a.editDeckViewActions,
            bayNumbersDoubleClickAction: doubleClickOnSvgBay,
        });
        removeChildren(this.nodes.deckView);
        this.nodes.deckView.dataset.lastUpdated = new Date().toISOString();
        this.nodes.deckView.appendChild(htmlOutput);
    }
    renderBays(eventDetail) {
        var _a, _b;
        const bays = (_a = eventDetail === null || eventDetail === void 0 ? void 0 : eventDetail.data) === null || _a === void 0 ? void 0 : _a.map((bay) => bay.split("-")[0]);
        const btnsAndActions = this.context.buttonsAndActions;
        if (!btnsAndActions)
            return;
        if (!this.baysSection) {
            this.baysSection = new BaysSection({
                createEditBayButtonFunction: btnsAndActions.createEditBayButtonFunction,
                readonlyMode: this.props.readonlyMode,
                canOpenDrawer: this.props.canOpenDrawer,
                bayHelpersButton: btnsAndActions.bayHelpersButton,
            });
            (_b = this.baysSection) === null || _b === void 0 ? void 0 : _b.calcAndSetScale(this.bayDrawOptions.fit2Vertical);
            this.nodes.baySection.bayBoxes.appendChild(this.baysSection.mainNode);
        }
        this.baysSection.render({
            lcgVcgTcgAndPairings: this.props.lcgVcgTcgAndPairings,
            bayDrawOptions: this.bayDrawOptions,
            baysToRender: bays,
        });
    }
    buildHtml(mainNode) {
        const nodes = {
            general: document.createElement("div-spinner-element"),
            sizeSummary: document.createElement("div-spinner-element"),
            centersOfGravity: document.createElement("div-spinner-element"),
            sideView: document.createElement("div-spinner-element"),
            deckView: document.createElement("div-spinner-element"),
            d3View: document.createElement("div-spinner-element"),
            baySection: {
                holder: document.createElement("div-spinner-element"),
                legend: document.createElement("div"),
                bayBoxes: document.createElement("div"),
            },
        };
        nodes.baySection.holder.appendChild(nodes.baySection.legend);
        nodes.baySection.holder.appendChild(nodes.baySection.bayBoxes);
        mainNode.appendChild(nodes.general);
        mainNode.appendChild(nodes.sizeSummary);
        mainNode.appendChild(nodes.centersOfGravity);
        mainNode.appendChild(nodes.sideView);
        mainNode.appendChild(nodes.deckView);
        mainNode.appendChild(nodes.d3View);
        mainNode.appendChild(nodes.baySection.holder);
        return nodes;
    }
}
function doubleClickOnSvgBay(node) {
    const bay = node.dataset.bay;
    const el = document.getElementById(`bay-box-component-${bay}`);
    if (el)
        el.scrollIntoView({ block: "center", behavior: "auto" });
}
function getBayDrawPreferences() {
    const baySizeOption = getPreferencesValue("bay-data-size");
    const bayCellSeparationOption = getPreferencesValue("bay-cell-separation");
    const bayGroupedPairedOption = getPreferencesValue("bay-grouped-paired");
    const fit2Vertical = getPreferencesValue("bay-fit2-vertical");
    return {
        cellSize: baySizeOption !== null && baySizeOption !== void 0 ? baySizeOption : SizeSmallMidLargeEnum.SMALL,
        cellSpacing: bayCellSeparationOption !== null && bayCellSeparationOption !== void 0 ? bayCellSeparationOption : CellSpacingEnum.NO_SPACE,
        baysPairedGrouped: bayGroupedPairedOption !== null && bayGroupedPairedOption !== void 0 ? bayGroupedPairedOption : GroupBaysPairedEnum.NOT_GROUPED,
        fit2Vertical: fit2Vertical === true || fit2Vertical === "true",
    };
}
