import { __awaiter } from "tslib";
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import "@shoelace-style/shoelace/dist/components/alert/alert";
import "@shoelace-style/shoelace/dist/components/avatar/avatar";
import "@shoelace-style/shoelace/dist/components/badge/badge";
import "@shoelace-style/shoelace/dist/components/button-group/button-group";
import "@shoelace-style/shoelace/dist/components/button/button";
import "@shoelace-style/shoelace/dist/components/checkbox/checkbox";
import "@shoelace-style/shoelace/dist/components/color-picker/color-picker";
import "@shoelace-style/shoelace/dist/components/copy-button/copy-button";
import "@shoelace-style/shoelace/dist/components/details/details";
import "@shoelace-style/shoelace/dist/components/dialog/dialog";
import "@shoelace-style/shoelace/dist/components/divider/divider";
import "@shoelace-style/shoelace/dist/components/drawer/drawer";
import "@shoelace-style/shoelace/dist/components/dropdown/dropdown";
import "@shoelace-style/shoelace/dist/components/icon-button/icon-button";
import "@shoelace-style/shoelace/dist/components/icon/icon";
import "@shoelace-style/shoelace/dist/components/input/input";
import "@shoelace-style/shoelace/dist/components/menu-item/menu-item";
import "@shoelace-style/shoelace/dist/components/menu/menu";
import "@shoelace-style/shoelace/dist/components/option/option";
import "@shoelace-style/shoelace/dist/components/qr-code/qr-code";
import "@shoelace-style/shoelace/dist/components/radio-button/radio-button";
import "@shoelace-style/shoelace/dist/components/radio-group/radio-group";
import "@shoelace-style/shoelace/dist/components/radio/radio";
import "@shoelace-style/shoelace/dist/components/relative-time/relative-time";
import "@shoelace-style/shoelace/dist/components/select/select";
import "@shoelace-style/shoelace/dist/components/spinner/spinner";
import "@shoelace-style/shoelace/dist/components/switch/switch";
import "@shoelace-style/shoelace/dist/components/tab-group/tab-group";
import "@shoelace-style/shoelace/dist/components/tab-panel/tab-panel";
import "@shoelace-style/shoelace/dist/components/tab/tab";
import "@shoelace-style/shoelace/dist/components/textarea/textarea";
import "@shoelace-style/shoelace/dist/components/tooltip/tooltip";
import "./app.element.scss";
import { IntegratedDialogError } from "@tedivo/tedivo-ui";
import { bindCustomElementsHooks } from "./start/bindCustomElementsHooks";
import { bindI18Events } from "./start/bindI18Events";
import { bindOvdJsonStoreEvents } from "./start/bindOvdJsonStoreEvents";
import { bindRouter } from "./start/bindRouter";
import { bindSecurityModuleEvents } from "./start/bindSecurityModuleEvents";
import { getTranslation } from "./i18/i18tn";
import { initializeTrackers } from "./start/initializeTrackers";
import { isBrowserNotSupported } from "@tedivo/tedivo-dom-helpers";
import { onOffLineObserver } from "./offline/observer/OnOffLineObserver";
import { routeFns } from "./router/routes";
import router from "./router";
import securityModule from "./security/SecurityModule";
import { setShoelaceModifications } from "./start/setShoelaceModifications";
const SUPPORTED_BROWSERS = {
    Firefox: { minVer: 92 },
    Chrome: { minVer: 89 },
    Safari: { minVer: 15 },
    Edge: { minVer: 89 },
    Edg: { minVer: 89 },
    "Mobile Safari": { minVer: 15 },
    "Android Browser": { minVer: 80 },
};
// Set Shoelace modifications
setShoelaceModifications();
export class AppElement extends HTMLElement {
    constructor() {
        var _a;
        super();
        this.dialogError = new IntegratedDialogError(this, false, getTranslation("general:common.close"));
        this.nodes = null;
        this.showError = (e) => {
            this.dialogError.show(typeof e === "string"
                ? getTranslation(`errors:errorHasOcurred`)
                : getTranslation(e.message), getTranslation(typeof e === "string" ? e : e.translationKey));
        };
        const browserError = isBrowserNotSupported(navigator.userAgent, SUPPORTED_BROWSERS);
        // Exit gracefully if the browser is not supported
        if (browserError) {
            console.log(browserError);
            const sb = document.getElementById("unsupported-browser");
            if (sb) {
                sb.style.display = "flex";
                sb.appendChild(document.createTextNode(browserError));
            }
            (_a = document.getElementById("tvd-editor-root")) === null || _a === void 0 ? void 0 : _a.remove();
            return; // ----> Stop the execution
        }
        globalThis.setTimeout(() => __awaiter(this, void 0, void 0, function* () {
            securityModule.checkIfUserIsLoggedIn();
        }), 0);
        this.attachEvents();
    }
    attachEvents() {
        initializeTrackers();
        bindCustomElementsHooks();
        bindI18Events();
        bindOvdJsonStoreEvents();
        bindSecurityModuleEvents();
        bindRouter();
        document.body.addEventListener("customError", (ev) => {
            const { detail } = ev;
            this.showError(detail);
        });
    }
    connectedCallback() {
        this.createOnOffLineDialog();
        this.nodes = buildHtml();
        this.appendChild(this.nodes.main);
        setTimeout(() => {
            var _a;
            const toolColor = document.getElementById("color-mode-select");
            if (toolColor && ((_a = this.nodes) === null || _a === void 0 ? void 0 : _a.logo))
                this.nodes.logo.src = setResolvedLogoSrc(toolColor.resolvedMode);
        }, 0);
    }
    createOnOffLineDialog() {
        const dialog = new IntegratedDialogError(this, false, getTranslation("general:common.close"));
        dialog.name = "onOffLine";
        onOffLineObserver.subscribe((isOnline) => {
            if (onOffLineObserver.isOnline === isOnline || !securityModule.isLoggedIn)
                return;
            if (dialog.isOpen)
                dialog.hide();
            dialog.show(getTranslation("general:common.attention"), getTranslation(`general:onOffLine.${isOnline ? "online" : "offline"}`));
        });
    }
}
AppElement.observedAttributes = [];
customElements.define("tvd-editor-root", AppElement);
function setResolvedLogoSrc(resolvedMode) {
    return `assets/images/Icon_Designer-Icon-${resolvedMode === "light" ? "color" : "white"}.svg`;
}
function buildHtml() {
    const contentHolder = document.createElement("div"), content = document.createElement("div"), main = document.createElement("main"), sidebar = document.createElement("aside"), logo = document.createElement("img"), topTools = document.createElement("tvd-top-tools-component"), timeOutDialog = document.createElement("sl-dialog");
    content.id = "st-main-content";
    sidebar.className = "st-sidebar";
    sidebar.id = "st-sidebar";
    content.className = "st-content";
    contentHolder.className = "st-content-holder";
    logo.className = "st-main-logo";
    logo.src = "assets/images/favicon.svg";
    logo.alt = getTranslation("general:appName");
    logo.title = getTranslation("general:appName");
    logo.setAttribute("role", "branding");
    logo.addEventListener("click", () => router.navigate(routeFns.myCloud()));
    document.documentElement.addEventListener("colorModeChanged", (ev) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const resolvedMode = ev.detail.mode;
        logo.src = setResolvedLogoSrc(resolvedMode);
    });
    sidebar.appendChild(logo);
    // Menu
    const menu = document.createElement("tvd-menu-component");
    menu.onOptionSelected = (action) => {
        router.navigate(action);
    };
    sidebar.appendChild(menu);
    // Timeout Dialog
    timeOutDialog.noHeader = true;
    timeOutDialog.addEventListener("sl-request-close", (event) => {
        if (event.detail.source === "overlay") {
            event.preventDefault();
        }
    });
    contentHolder.appendChild(topTools);
    contentHolder.appendChild(content);
    contentHolder.appendChild(timeOutDialog);
    main.appendChild(sidebar);
    main.appendChild(contentHolder);
    return {
        main,
        logo,
        topTools,
    };
}
