import {
  BayEditTopTools,
  ICustomEventEmphasizeCheckbox,
} from "./BayEditTopTools";
import {
  BayLevelEnum,
  ForeAftEnum,
  IBayLevelData,
  IBaySlotData,
  IFeaturesAllowed,
  ISizeSummary,
  ISlotData,
  TContainerLengths,
  ValuesSourceEnum,
} from "open-vessel-definition";
import {
  IBayPattern,
  ISlotCombinedPattern,
  ISlotPattern,
  getBaySlots,
  getSizesFromSlotDataArray,
  hasZeroRow,
  slotIdJoinedToMixed,
} from "@tedivo/tedivo-bay-grid-pure";
import {
  IFields,
  TedivoForm,
  TfRadioGroup,
  translateTedivoForm,
} from "@tedivo/tedivo-form";
import {
  LogEventEntitiesEnum,
  LogEventTypesEnum,
} from "@tedivo/tvd-api-models";
import {
  arraysAreEqual,
  cloneObject,
  objectsAreEqual,
  pad2,
  pad3,
} from "@tedivo/tedivo-pure-helpers";
import {
  getPreferencesValue,
  setPreferencesKeyAndValue,
} from "@tedivo/tedivo-preferences";
import {
  modifyCellsWithActions,
  slotDataIsNotEmpty,
} from "../../../serv/modifyCellsWithActions";
import ovdJsonStore, {
  OVDChangesEnum,
} from "../../../../../../app/stores/OVDJsonStore";

import BeaconServices from "../../../../../../app/beaconServices";
import { EditDrawer } from "../../../../../common/EditDrawer";
import EditToolsEnum from "../../../types/EditToolsEnum";
import { GRID_OPTIONS_BY_CELL_DIMENSION } from "../../bay-boxes/gridConfigConstants";
import { ICreateGridFromConfigProps } from "@tedivo/tedivo-bay-grid-core";
import { IOptionChanged } from "../../../../../../../typings";
import { ISlotSizeOptions } from "open-vessel-definition/build/src/models/v1/parts/ISlotData";
import { IToolsState } from "../../../types/IToolsState";
import { IntegratedDialogError } from "@tedivo/tedivo-ui";
import { InteractiveGrid } from "@tedivo/tedivo-bay-grid-interactive";
import SizeSmallMidLargeEnum from "../../../../../../app/enums/SizeSmallMidLargeEnum";
import SlDialog from "@shoelace-style/shoelace/dist/components/dialog/dialog";
import TYesNoEnum from "../../../../../../app/enums/TYesNoEnum";
import { bayDetailsFormValidator } from "./bayDetailsFormValidator";
import { createBayDetailsFormFields } from "./createBayDetailsFormFields";
import { createFnSlotCell } from "../../bay-boxes/createFnSlotCell";
import { createFnSlotEmphasizedCell } from "../../bay-boxes/createFnSlotEmphasizedCell";
import { getGridNodesOptions } from "../../bay-boxes/getGridNodesOptions";
import { getTranslation } from "../../../../../../app/i18/i18tn";
import goSquared from "../../../../../../app/tracking/goSquared";
import { postProcessingForm } from "./postProcessingForm";
import { removeChildren } from "@tedivo/tedivo-dom-helpers";
import securityModule from "../../../../../../app/security/SecurityModule";
import { updateFormOnSlotsChanged } from "./updateFormOnSlotsChanged";
import { z } from "zod";

const BAY_HELPERS_PREF_ID = "bayHelpers-copyBayData";
const BAY_HELPERS_PREF_FROM_ID = "bayHelpers-copyFromBayData";

export class BayEditComponent extends HTMLElement {
  data: IBayEditComponentData = {} as IBayEditComponentData;

  public onSlotsChanged?: (data: ISlotData[]) => void;

  private toolsState: IToolsState = {
    activeTool: EditToolsEnum.ADD_MANY,
    tools: {
      [EditToolsEnum.ADD_MANY]: [],
      [EditToolsEnum.ADD_REMOVE_SINGLE]: [],
      [EditToolsEnum.CLEAR]: [],
      [EditToolsEnum.ADD_REMOVE_RESTRICTED]: [],
      [EditToolsEnum.ADD_REMOVE_SPECIAL]: [],
    },
  };

  private internalProhibitedCells?: Array<ISlotCombinedPattern>;
  private internalCenterLineRow: TYesNoEnum = 0;
  private internalPairedBay: ForeAftEnum | undefined;
  private internalCellsData: Array<ISlotData> = [];

  public readOnlyMode = false;
  private isInitialized = false;

  private gridOptions: ICreateGridFromConfigProps<ISlotData>;
  private emphasizeCells?: ISlotCombinedPattern[];
  private mirrorSlotsWhenDefining = true;

  private interactiveGrid: InteractiveGrid<ISlotData>;
  private interactiveTools: BayEditTopTools;
  private editDrawer: EditDrawer | undefined = undefined;

  private helpersCommonDialog: SlDialog;

  public postSaveActions: Array<IPostSaveAction> = [];

  public updateDetailsScreen: (data: Partial<IEditBayFields>) => void = () =>
    undefined;

  get cellsData(): Array<ISlotData> {
    return this.internalCellsData;
  }
  set cellsData(cd: Array<ISlotData> | undefined) {
    this.internalCellsData = cd || [];
  }

  constructor() {
    super();

    const nodesOptions = getGridNodesOptions();

    this.interactiveGrid = new InteractiveGrid<ISlotData>();
    this.interactiveGrid.className = "svg-bay-edit";
    this.interactiveGrid.onSelection = this.onUserDiagramSelected;

    this.interactiveTools = new BayEditTopTools();

    this.helpersCommonDialog = document.createElement("sl-dialog");

    this.gridOptions = {
      nodesOptions,
      ...GRID_OPTIONS_BY_CELL_DIMENSION[SizeSmallMidLargeEnum.MIDDLE],
      cellSeparation: 10,
      cellsDrawFunction: createFnSlotCell(SizeSmallMidLargeEnum.MIDDLE),
      emphasizeCellsDrawFunction: createFnSlotEmphasizedCell(
        SizeSmallMidLargeEnum.MIDDLE,
        "editBay",
        nodesOptions,
      ),
      centerLineRow: 0,
      maxRow: 0,
    };
  }

  get prohibitedCells() {
    return this.internalProhibitedCells || [];
  }
  set prohibitedCells(pc: Array<ISlotCombinedPattern>) {
    this.internalProhibitedCells = pc;
    this.updateGrid();
  }

  get centerLineRowValue() {
    return this.internalCenterLineRow;
  }
  set centerLineRowValue(n: TYesNoEnum) {
    this.internalCenterLineRow = n;
    this.updateGrid();
  }

  get pairedBayValue() {
    return this.internalPairedBay;
  }
  set pairedBayValue(v: ForeAftEnum | undefined) {
    this.internalPairedBay = v;
    this.emphasizeSlots(this.interactiveTools.emphasizedValue);
    this.interactiveTools.emphasizeCheckboxDisabled = !v;
  }

  attachEvents() {
    this.helpersCommonDialog.addEventListener(
      "sl-after-hide",
      (ev) => {
        if (ev.target === this.helpersCommonDialog) {
          this.keepDrawerOpened(false);
          removeChildren(this.helpersCommonDialog);
        }
      },
      false,
    );

    this.helpersCommonDialog.addEventListener(
      "sl-after-show",
      (ev) => {
        if (ev.target === this.helpersCommonDialog) this.keepDrawerOpened(true);
      },
      false,
    );

    this.interactiveTools.addEventListener(
      "emphasizePairedSlotsChanged",
      ((ev: CustomEvent<ICustomEventEmphasizeCheckbox>) => {
        this.emphasizeSlots(ev.detail.isChecked);
      }) as EventListenerOrEventListenerObject,
      false,
    );

    this.interactiveTools.addEventListener(
      "mirrorSlotsDefinitionChanged",
      ((ev: CustomEvent<ICustomEventEmphasizeCheckbox>) => {
        this.mirrorSlotsWhenDefining = ev.detail.isChecked;
      }) as EventListenerOrEventListenerObject,
      false,
    );

    this.interactiveTools.addEventListener(
      "copyFromBay",
      this.copyFromBay as EventListener,
      { capture: false },
    );

    this.interactiveTools.addEventListener(
      "copyToBays",
      this.copyToBays as EventListener,
      { capture: false },
    );

    this.interactiveTools.addEventListener(
      "remapTiers",
      this.remapTiers as EventListener,
      { capture: false },
    );

    this.interactiveTools.addEventListener(
      "move40Definitions",
      this.move40Definitions as EventListener,
      { capture: false },
    );

    this.interactiveTools.addEventListener(
      "optionChanged",
      this.onToolsChanged as EventListener,
      { capture: false },
    );
  }

  connectedCallback() {
    removeChildren(this);

    const div = document.createElement("div");
    div.className = "bec";

    this.toolsState = this.interactiveTools.state;

    div.appendChild(this.interactiveTools.mainNode);
    div.appendChild(this.interactiveGrid);

    this.appendChild(div);
    this.appendChild(this.helpersCommonDialog);

    if (!this.isInitialized) {
      this.attachEvents();
      this.isInitialized = true;
    }

    this.mirrorSlotsWhenDefining =
      this.interactiveTools.mirrorSlotsWhenDefining;
  }

  setEditDrawer(editDrawer: EditDrawer) {
    this.editDrawer = editDrawer;
    return this;
  }

  openBayEditInDrawer(isoBay: IBayPattern, level: BayLevelEnum) {
    const json = ovdJsonStore.currentJson;
    const editDrawer = this.editDrawer;
    const bayData = ovdJsonStore.findBayInfo(isoBay, level);
    const sizeSummary = json?.sizeSummary;

    if (!json || !editDrawer || !bayData || !sizeSummary || !sizeSummary.maxRow)
      return;

    this.interactiveTools.updateToolsOptions(
      json.shipData.containersLengths || [],
      json.shipData.featuresAllowed,
    );

    goSquared.addEvent("Edit-OVD - Edit Bay");

    const gridSize: ISizeSummary = {
      centerLineRow: bayData?.centerLineRow ?? sizeSummary.centerLineRow,
      isoBays: sizeSummary.isoBays,
      maxRow: sizeSummary.maxRow,
    };

    if (level === BayLevelEnum.ABOVE) {
      gridSize.maxAboveTier = sizeSummary.maxAboveTier;
      gridSize.minAboveTier = sizeSummary.minAboveTier;
    } else {
      gridSize.maxBelowTier = sizeSummary.maxBelowTier;
      gridSize.minBelowTier = sizeSummary.minBelowTier;
    }

    const baySlots = getBaySlots(isoBay, json.baysData);

    const gridData = {
      ...this.gridOptions,
      ...gridSize,
      enabledCells: baySlots.enabledCells,
      cellsToDraw:
        level === BayLevelEnum.ABOVE
          ? baySlots.slotsDataAbove
          : baySlots.slotsDataBelow,
      emphasizeCells: this.emphasizeCells,
    };

    this.data.isoBay = isoBay;
    this.data.level = level;
    this.data.maxIsoBay = sizeSummary.isoBays;
    this.data.currentBayData = bayData;
    this.data.gridData = gridData;
    this.data.availableLengths = json.shipData.containersLengths;
    this.data.featuresAllowed = json.shipData.featuresAllowed;

    this.internalCellsData = gridData.cellsToDraw?.map(cloneSlotData) || [];
    this.internalCenterLineRow = gridData.centerLineRow;
    this.internalPairedBay = bayData?.pairedBay;

    this.mirrorSlotsWhenDefining =
      this.interactiveTools.mirrorSlotsWhenDefining;

    this.interactiveGrid.initialize(gridData);
    let internaSlotData: ISlotData[] = gridData.cellsToDraw || [];

    const drawer = editDrawer.getEditDrawer({
      title: `${getTranslation("general:common.edit")} ${getTranslation(
        "general:grid.bay",
      )} ${isoBay} ${getTranslation(
        `enums:BayLevelEnum.${BayLevelEnum[level]}`,
      )}`,
      showUnits: false,
      readOnlyMode: this.readOnlyMode,
      extraButtons: [
        {
          value: "goToAftBay",
          label: getTranslation("view:nav.aftBay"),
          outline: true,
          variant: "primary",
          onClick: () => {
            const tmp = Number(isoBay) + 2;
            const aftIsoBay = tmp > sizeSummary.isoBays ? undefined : pad3(tmp);

            const cb = () => {
              if (aftIsoBay) this.openBayEditInDrawer(aftIsoBay, level);
            };

            if (editDrawer.canClose || this.readOnlyMode) {
              if (aftIsoBay) drawer.hide();
              cb();
            } else {
              editDrawer.promprUserIfShouldNotClose(cb);
            }
          },
        },
        {
          value: "goToOtherDeck",
          label: getTranslation("view:nav.changeDeck"),
          outline: true,
          variant: "primary",
          onClick: () => {
            const cb = () => {
              const newLevel =
                level === BayLevelEnum.ABOVE
                  ? BayLevelEnum.BELOW
                  : level === BayLevelEnum.BELOW
                  ? BayLevelEnum.ABOVE
                  : level;
              this.openBayEditInDrawer(isoBay, newLevel);
            };

            if (editDrawer.canClose || this.readOnlyMode) {
              drawer.hide();
              cb();
            } else {
              editDrawer.promprUserIfShouldNotClose(cb);
            }
          },
        },
        {
          value: "goToFwdBay",
          label: getTranslation("view:nav.foreBay"),
          outline: true,
          variant: "primary",
          onClick: () => {
            const tmp = Number(isoBay) - 2;
            const fwdIsoBay = tmp < 0 ? undefined : pad3(tmp);

            const cb = () => {
              if (fwdIsoBay) this.openBayEditInDrawer(fwdIsoBay, level);
            };

            if (editDrawer.canClose || this.readOnlyMode) {
              if (fwdIsoBay) drawer.hide();
              cb();
            } else {
              editDrawer.promprUserIfShouldNotClose(cb);
            }
          },
        },
      ],
    });

    // editDrawer.addEventListener(
    //   "data-dirty",
    //   ((ev: CustomEvent<{ detail: boolean }>) => {
    //     const disableButtons = ev.detail;
    //     editDrawer.extraButtonsNodes.forEach((btn) => {
    //       if (disableButtons) btn.setAttribute("disabled", "");
    //       else btn.removeAttribute("disabled");
    //     });
    //   }) as EventListener,
    //   false,
    // );

    editDrawer.shouldNotSelectPanel = true;

    const bayDetailsTedivoForm = new TedivoForm<IEditBayFields>({
      formProps: { size: "small", className: "form-boxed max500px" },
      fields: createBayDetailsFormFields(
        sizeSummary,
        bayData,
        bayData ? hasZeroRow(bayData) : false,
      ),
      onSubmit: () => undefined,
      formValidator: bayDetailsFormValidator,
      submitButton: editDrawer.submitButton,
    });

    this.updateDetailsScreen = (data: Partial<IEditBayFields>) => {
      Object.keys(data).forEach((key) => {
        bayDetailsTedivoForm.setValue(
          key as keyof IEditBayFields,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          data[key] as any,
        );
      });
    };

    translateTedivoForm<IEditBayFields>({
      tedivoForm: bayDetailsTedivoForm,
      getTranslation,
    });

    const initialData = {
      slotsData: cloneObject(internaSlotData),
      formValues: bayDetailsTedivoForm.getValues(),
    };

    // On Form change
    bayDetailsTedivoForm.onDataChange = (
      values: IEditBayFields,
      keyChanged: keyof IEditBayFields | undefined,
    ) => {
      switch (keyChanged) {
        case "pairedBay":
          if (this.pairedBayValue !== values.pairedBay)
            this.pairedBayValue = values.pairedBay;
          break;
        case "athwartShip":
          (
            bayDetailsTedivoForm.getFormControlsByName().pairedBay
              .field as TfRadioGroup
          ).disabled = !!values.athwartShip;
          break;
        case "centerLineRow":
          this.centerLineRowValue = values.centerLineRow || 0;
          break;
      }

      editDrawer.dataIsDirty = !isCurrentDataEqualToInitialData();
    };

    // On Interactive drawing change
    this.onSlotsChanged = (slotData: ISlotData[]) => {
      internaSlotData = slotData;
      updateFormOnSlotsChanged(slotData, bayDetailsTedivoForm);
      editDrawer.dataIsDirty = !isCurrentDataEqualToInitialData();
    };

    // Append Edit part
    const editPart = document.createElement("div");
    editPart.className = "bay-edit-components";
    editPart.appendChild(this);
    editPart.appendChild(bayDetailsTedivoForm.form);
    drawer.appendChild(editPart);

    // Finally, Do open Drawer
    editDrawer.onSave = () => submitPassedToEditDrawer(this);

    if (this.internalPairedBay) this.emphasizeSlots(true);
    else this.updateGrid();

    drawer.show();

    function submitPassedToEditDrawer(bayEditComp: BayEditComponent) {
      const validResult = bayDetailsTedivoForm.doSubmitForm();
      const shouldSendBeacon = validResult.success && editDrawer?.dataIsDirty;

      if (editDrawer) {
        editDrawer.shouldNotClose = false;
        editDrawer.dataIsDirty = false;
      }

      if (validResult.success && json) {
        const newBayData = postProcessingForm(
          json.shipData,
          validResult.data,
          isoBay,
          level,
          bayData,
          internaSlotData,
        );

        if (bayEditComp.postSaveActions.length) {
          bayEditComp.postSaveActions.forEach(({ isoBay, level, action }) => {
            const targetBayData = ovdJsonStore.findBayInfo(isoBay, level);
            if (targetBayData)
              ovdJsonStore.replaceBayInfo(isoBay, level, action(targetBayData));
          });
        }

        goSquared.addEvent("Edit-OVD - Edit Bay - Save");

        ovdJsonStore.replaceBayInfo(isoBay, level, newBayData);

        if (shouldSendBeacon && ovdJsonStore.tvdId)
          BeaconServices.logEvents.notifyXhttp({
            eventEntity: LogEventEntitiesEnum.File,
            eventType: LogEventTypesEnum.Modified,
            subEvent: `Bay-${isoBay}-${
              level === BayLevelEnum.ABOVE ? "Ab" : "Be"
            }-Modified`,
            itemId: ovdJsonStore.tvdId.fileId,
            ...securityModule.getBeaconMetadata(),
          });

        return true;
      }

      return false;
    }

    function isCurrentDataEqualToInitialData() {
      const currentData = {
        slotsData: internaSlotData,
        formValues: bayDetailsTedivoForm.getValues(),
      };

      const slotsDataAreEqual = arraysAreEqual(
        currentData.slotsData,
        initialData.slotsData,
        true,
      );

      const formValuesAreEqual = objectsAreEqual(
        currentData.formValues,
        initialData.formValues,
        true,
      );

      return slotsDataAreEqual && formValuesAreEqual;
    }
  }

  private onToolsChanged = (ev: CustomEvent<IOptionChanged>) => {
    this.toolsState = ev.detail.state;
  };

  private onUserDiagramSelected = (
    positionsSelected: ISlotCombinedPattern[],
  ) => {
    if (!positionsSelected || !positionsSelected.length) return;

    const positionsSelectedToUse = this.mirrorSlotsWhenDefining
      ? mirrorSlots(positionsSelected)
      : positionsSelected;

    this.cellsData = modifyCellsWithActions(
      this.toolsState,
      positionsSelectedToUse,
      this.cellsData || [],
    )
      .filter(slotDataIsNotEmpty)
      .filter(
        (slotData) =>
          !this.internalProhibitedCells ||
          this.internalProhibitedCells.indexOf(
            slotIdJoinedToMixed(slotData.pos),
          ) < 0,
      );

    this.updateGrid();

    if (this.onSlotsChanged) {
      this.onSlotsChanged(this.cellsData);
    }
  };

  private updateGrid = () => {
    this.interactiveGrid.updateCellData(
      this.cellsData || [],
      this.internalCenterLineRow,
      this.prohibitedCells,
      getSlotPos,
      this.emphasizeCells,
    );
  };

  private emphasizeSlots = (doEmphasize: boolean) => {
    // 1. Get Bay data
    const bayData = this.data.currentBayData;
    const pairedBayAdd = this.internalPairedBay;

    if (!doEmphasize || !bayData || !pairedBayAdd) {
      this.emphasizeCells = [];
      this.updateGrid();
      return;
    }

    const bay =
      Number(this.data.isoBay) + (pairedBayAdd === ForeAftEnum.AFT ? 2 : -2);

    if (bay >= 1 && bay <= this.data.maxIsoBay) {
      const bayInfo = ovdJsonStore.findBayInfo(pad3(bay), this.data.level);

      if (bayInfo?.perSlotInfo !== undefined) {
        this.emphasizeCells = Object.values(bayInfo.perSlotInfo);
      }
    } else {
      this.emphasizeCells = [];
    }

    // 2. Update
    this.updateGrid();
  };

  private copyFromBay = () => {
    const json = ovdJsonStore.currentJson;
    if (json === undefined) return;

    const helpersCommonDialog = this.helpersCommonDialog;
    const isoBay = this.data.isoBay;
    const level = this.data.level;
    const updateDetailsScreen = this.updateDetailsScreen;
    const updateCellsData = (cellsData: ISlotData[]) => {
      this.cellsData = cellsData;
      this.updateGrid();
      if (this.onSlotsChanged) {
        this.onSlotsChanged(this.cellsData);
      }
    };

    helpersCommonDialog.label = getTranslation(
      "view:edit.helpers.copyFromBay.title",
    );
    helpersCommonDialog.setAttribute("style", "--width: 900px");

    const btn = document.createElement("sl-button");
    btn.innerHTML = getTranslation("general:common.ok");
    btn.slot = "footer";
    btn.variant = "primary";
    btn.type = "submit";
    btn.addEventListener("click", execute, false);

    const { fields, validator } = createCopyDataFromBayFormFields(
      isoBay,
      level,
      json.sizeSummary,
      json.shipData.containersLengths,
      json.shipData.featuresAllowed,
    );

    const tedivoForm = new TedivoForm<ICopyFromBayData>({
      fields,
      onSubmit: () => null,
      formValidator: validator,
      submitButton: btn,
    });

    translateTedivoForm<ICopyFromBayData>({ tedivoForm, getTranslation });

    helpersCommonDialog.appendChild(tedivoForm.form);
    helpersCommonDialog.appendChild(btn);

    helpersCommonDialog.show();

    function execute() {
      executeCopyFromData(
        tedivoForm.getValues(),
        updateDetailsScreen,
        updateCellsData,
      );
      helpersCommonDialog.hide();
    }
  };

  private copyToBays = () => {
    const execute = () => {
      const res = executeCopyToData(tedivoForm.getValues());
      if (res !== EnumUpdateResult.ERROR) helpersCommonDialog.hide();
      if (res === EnumUpdateResult.OK) this.editDrawer?.drawer.hide();
    };

    const json = ovdJsonStore.currentJson;
    if (json === undefined) return;

    if (this.editDrawer?.dataIsDirty) {
      new IntegratedDialogError(
        document.body,
        true,
        getTranslation("general:common.close"),
      ).show(
        getTranslation("view:edit.helpers.copyToBays.error"),
        getTranslation("view:edit.helpers.copyToBays.errorText"),
      );
      return;
    }

    const helpersCommonDialog = this.helpersCommonDialog;
    const isoBay = this.data.isoBay;
    const level = this.data.level;

    helpersCommonDialog.label = getTranslation(
      "view:edit.helpers.copyToBays.title",
    );
    helpersCommonDialog.setAttribute("style", "--width: 900px");

    const btn = document.createElement("sl-button");
    btn.innerHTML = getTranslation("general:common.ok");
    btn.slot = "footer";
    btn.variant = "primary";
    btn.type = "submit";
    btn.addEventListener("click", execute, false);

    const { fields, validator } = createPasteToBaysFormFields(
      isoBay,
      level,
      json.sizeSummary,
    );

    const tedivoForm = new TedivoForm<ICopyToBayData>({
      fields,
      onSubmit: () => null,
      formValidator: validator,
      submitButton: btn,
    });

    translateTedivoForm<ICopyToBayData>({ tedivoForm, getTranslation });

    helpersCommonDialog.appendChild(tedivoForm.form);
    helpersCommonDialog.appendChild(btn);

    helpersCommonDialog.show();
  };

  private remapTiers = () => {
    if (ovdJsonStore.currentJson === undefined) return;

    const helpersCommonDialog = this.helpersCommonDialog;
    const cellsData = this.cellsData || [];
    const bayTiers = cellsData.map((s) => Number(s.pos.substring(2)));
    const minTier = Math.min(...bayTiers);
    const maxTier = Math.max(...bayTiers);
    const tierDiff = maxTier - minTier;
    const sizeSummary = ovdJsonStore.currentJson.sizeSummary;

    helpersCommonDialog.label = getTranslation(
      "view:edit.helpers.remapTiers.title",
    );
    helpersCommonDialog.setAttribute("style", "--width: 600px");

    const btn = document.createElement("sl-button");
    btn.innerHTML = getTranslation("general:common.ok");
    btn.slot = "footer";
    btn.variant = "primary";
    btn.type = "submit";
    btn.addEventListener(
      "click",
      () => {
        const newCellsData = doTheTranslation();
        if (newCellsData) {
          this.cellsData = newCellsData;

          if (this.onSlotsChanged) {
            this.onSlotsChanged(this.cellsData);
          }

          this.updateGrid();
        }
      },
      false,
    );

    const options: { value: string; label: string }[] = [];
    const tierFrom = Number(
      this.data.level === BayLevelEnum.ABOVE
        ? sizeSummary.minAboveTier
        : sizeSummary.minBelowTier,
    );
    const tierTo =
      Number(
        this.data.level === BayLevelEnum.ABOVE
          ? sizeSummary.maxAboveTier
          : sizeSummary.maxBelowTier,
      ) - tierDiff;

    for (let i = tierTo; i >= tierFrom; i -= 2) {
      options.push({ value: pad2(i), label: pad2(i) });
    }

    const fields: IFields<IRemapTiersFields> = [
      {
        name: "newTier",
        type: "select",
        label: "view:tier",
        options,
        initialValue: pad2(minTier),
        hoist: true,
      },
    ];

    const validator: z.Schema<IRemapTiersFields> = z.object({
      newTier: z.number(),
    });

    const tedivoForm = new TedivoForm<IRemapTiersFields>({
      fields,
      onSubmit: () => null,
      formValidator: validator,
      submitButton: btn,
    });

    translateTedivoForm<IRemapTiersFields>({ tedivoForm, getTranslation });

    removeChildren(helpersCommonDialog);

    helpersCommonDialog.innerHTML = getTranslation(
      "view:edit.helpers.remapTiers.intro",
      { minTier },
    );

    tedivoForm.form.classList.add("tvd-form-margin-top");

    helpersCommonDialog.appendChild(tedivoForm.form);
    helpersCommonDialog.appendChild(btn);

    helpersCommonDialog.show();

    function doTheTranslation(): ISlotData[] | null {
      helpersCommonDialog.hide();
      const newDiff = Number(tedivoForm.getValues().newTier) - minTier;
      if (newDiff === 0) return null;

      const newCellsData: ISlotData[] = cloneObject(cellsData);

      newCellsData.forEach((slotData) => {
        const row = Number(slotData.pos.substring(0, 2));
        const tier = Number(slotData.pos.substring(2));
        slotData.pos = `${pad2(row)}${pad2(tier + newDiff)}`;
      });

      return newCellsData;
    }
  };

  private move40Definitions = () => {
    if (ovdJsonStore.currentJson === undefined) return;

    const helpersCommonDialog = this.helpersCommonDialog;
    const cellsData = this.cellsData || [];
    const sizeSummary = ovdJsonStore.currentJson.sizeSummary;
    const cellsDataHas40s = getSizesFromSlotDataArray(cellsData).some(
      (size) => size >= 40,
    );

    const shipData = ovdJsonStore.currentJson.shipData;
    const shipHasCGs =
      shipData.lcgOptions?.values === ValuesSourceEnum.KNOWN ||
      shipData.vcgOptions?.values === ValuesSourceEnum.KNOWN ||
      shipData.tcgOptions?.values === ValuesSourceEnum.KNOWN;

    helpersCommonDialog.label = getTranslation(
      "view:edit.helpers.move40Definitions.title",
    );
    helpersCommonDialog.setAttribute("style", "--width: 600px");

    const btn = document.createElement("sl-button");
    btn.innerHTML = getTranslation("general:common.ok");
    btn.slot = "footer";
    btn.variant = "primary";
    btn.type = "submit";
    btn.addEventListener(
      "click",
      () => {
        const movedCells = doTheMovement(
          this.data.isoBay,
          this.data.level,
          this.pairedBayValue,
          cellsDataHas40s,
        );
        if (movedCells) {
          this.cellsData = movedCells.newCellsData;

          if (movedCells.postAction)
            this.postSaveActions.push(movedCells.postAction);

          this.updateGrid();

          if (this.onSlotsChanged) {
            this.onSlotsChanged(this.cellsData);
          }
        }
      },
      false,
    );

    removeChildren(helpersCommonDialog);

    if (cellsDataHas40s) {
      if (this.pairedBayValue) {
        const pairedBay =
          Number(this.data.isoBay) +
          (this.pairedBayValue === ForeAftEnum.FWD ? -2 : 2);

        if (pairedBay < 1 || pairedBay > sizeSummary.isoBays) {
          helpersCommonDialog.innerHTML = getTranslation(
            "view:edit.helpers.move40Definitions.incorrect",
          );
        } else {
          helpersCommonDialog.innerHTML = `${getTranslation(
            "view:edit.helpers.move40Definitions.intro",
            { pairedBay: pad3(pairedBay) },
          )}${
            shipHasCGs
              ? "<br /><br /><strong>" +
                getTranslation(
                  "view:edit.helpers.move40Definitions.reEnterCGs",
                ) +
                "</strong>"
              : ""
          }`;
        }
      } else {
        helpersCommonDialog.innerHTML = getTranslation(
          "view:edit.helpers.move40Definitions.notPaired",
        );
      }
    } else {
      helpersCommonDialog.innerHTML = getTranslation(
        "view:edit.helpers.move40Definitions.no40s",
      );
    }

    helpersCommonDialog.appendChild(btn);
    helpersCommonDialog.show();

    /** This function moves out the 40s and creates a postAction to add them to the pairedBay */
    function doTheMovement(
      isoBay: IBayPattern,
      level: BayLevelEnum,
      currentIsPaired: ForeAftEnum | undefined,
      cellsDataHas40s: boolean,
    ): { newCellsData: ISlotData[]; postAction?: IPostSaveAction } | null {
      helpersCommonDialog.hide();
      if (!currentIsPaired || !cellsDataHas40s) return null;

      const pairedBay =
        Number(isoBay) + (currentIsPaired === ForeAftEnum.FWD ? -2 : 2);

      if (pairedBay < 1 || pairedBay > sizeSummary.isoBays) return null;

      const newCellsData: ISlotData[] = cloneObject(cellsData);
      /** Contains the 40s data for the paired bay */
      const infoOf40s: { [pos: ISlotPattern]: TContainerLengths[] } = {};

      for (let i = 0; i < newCellsData.length; i++) {
        const slotData = newCellsData[i];
        const sizesInSlot = Object.keys(slotData.sizes).map(
          Number,
        ) as TContainerLengths[];

        sizesInSlot.forEach((size) => {
          if (size >= 40) {
            // Delete from current slot
            delete slotData.sizes[size];
            // Save the data to move it later
            if (!infoOf40s[slotData.pos]) infoOf40s[slotData.pos] = [];
            infoOf40s[slotData.pos].push(size);
          }
        });

        const remainingSizes = Object.keys(slotData.sizes).length;
        if (!remainingSizes) slotData.restricted = 1;
      }

      const positionsToComplete = Object.keys(infoOf40s) as ISlotPattern[];

      if (!positionsToComplete.length) return { newCellsData };

      const postAction: IPostSaveAction = {
        isoBay: pad3(pairedBay),
        level,
        action: (bl: IBayLevelData) => {
          const newPerSlotInfo: IBaySlotData = JSON.parse(
            JSON.stringify(bl.perSlotInfo || {}),
          );

          positionsToComplete.forEach((pos) => {
            if (!newPerSlotInfo[pos]) newPerSlotInfo[pos] = { pos, sizes: {} };
            if (!newPerSlotInfo[pos].sizes) newPerSlotInfo[pos].sizes = {};

            if (infoOf40s[pos]) {
              infoOf40s[pos].forEach((size) => {
                newPerSlotInfo[pos].sizes[size] = 1;
              });
              delete newPerSlotInfo[pos].restricted;
            }
          });

          return {
            ...bl,
            perSlotInfo: newPerSlotInfo,
          };
        },
      };

      return { newCellsData, postAction };
    }
  };

  private keepDrawerOpened = (isOpened: boolean) => {
    if (this.editDrawer) this.editDrawer.shouldNotClose = isOpened;
  };
}

customElements.define("tvd-bay-edit-component", BayEditComponent);

function mirrorSlots(positionsSelected: ISlotCombinedPattern[]) {
  const positionsSelectedToUse: ISlotCombinedPattern[] = [];
  positionsSelected.forEach((pos) => {
    const [row, tier] = pos.split("|").map(Number);
    if (row > 0) {
      // 1 Self
      positionsSelectedToUse.push(`${pad2(row)}|${pad2(tier)}`);
      // 2 Mirror
      positionsSelectedToUse.push(
        `${pad2(row + (row % 2 === 0 ? -1 : 1))}|${pad2(tier)}`,
      );
    } else {
      positionsSelectedToUse.push(pos);
    }
    return positionsSelectedToUse;
  });

  return positionsSelectedToUse.filter((v, idx, arr) => arr.indexOf(v) === idx);
}

function cloneSlotData(slot: ISlotData): ISlotData {
  const newData = { ...slot, sizes: { ...slot.sizes } } as ISlotData;
  return newData;
}

function getSlotPos(slotData: ISlotData) {
  return slotIdJoinedToMixed(slotData.pos);
}

function createCopyDataFromBayFormFields(
  isoBay: IBayPattern,
  level: BayLevelEnum,
  sizeSummary: ISizeSummary,
  availableLengths: TContainerLengths[],
  featuresAllowed: IFeaturesAllowed = {} as IFeaturesAllowed,
): {
  fields: IFields<ICopyFromBayData>;
  validator: z.ZodType<ICopyFromBayData, z.ZodTypeDef, ICopyFromBayData>;
} {
  const bayLabel = getTranslation("general:grid.bay");
  const options = generateBayLevelOptions({
    sizeSummary,
    level,
    isoBay,
    bayLabel,
    includeSelf: false,
  });

  const currentBay = Number(isoBay);
  const defaultOption = `${currentBay === 1 ? 3 : currentBay - 2}-${level}`;

  const sizes = availableLengths.map((size) => ({
    value: `size-${size}`,
    label: getTranslation(`general:slotCapabilities.size.${size}`),
  }));

  const sizesWithCones = featuresAllowed.slotConeRequired
    ? availableLengths.map((size) => ({
        value: `sizeC-${size}`,
        label: getTranslation(`general:slotCapabilities.size.${size}c`),
      }))
    : [];

  const slotOptions = [
    ...sizes,
    ...sizesWithCones,
    {
      value: "restricted",
      label: getTranslation("general:slotCapabilities.restricted.-"),
    },
    {
      value: "reefer",
      label: getTranslation("general:slotCapabilities.misc.r"),
    },
    featuresAllowed?.slotHazardousProhibited
      ? {
          value: "hazardousProhibited",
          label: getTranslation("general:slotCapabilities.misc.h"),
        }
      : undefined,
    featuresAllowed?.slotCoolStowProhibited
      ? {
          value: "coolStowProhibited",
          label: getTranslation("general:slotCapabilities.misc.k"),
        }
      : undefined,
  ].filter(Boolean) as { value: string | number; label: string }[];

  const bayOptions = [
    {
      value: "pairedBay",
      label: getTranslation("view:details.paired"),
    },
    {
      value: "reeferPlugs",
      label: getTranslation("view:details.reeferPlugs"),
    },
    {
      value: "doors",
      label: getTranslation("view:details.doors"),
    },
    {
      value: "telescoping",
      label: getTranslation("view:details.telescoping"),
    },
  ];

  const initalValues = JSON.parse(
    (getPreferencesValue(BAY_HELPERS_PREF_ID) as string) || "{}",
  ) as ICopyFromBayData;

  const fields: IFields<ICopyFromBayData> = [
    {
      name: "fromBay",
      type: "select",
      label: "view:edit.helpers.fromBay",
      options,
      initialValue: defaultOption,
      hoist: true,
    },
    {
      name: "bayOptions",
      label: "view:edit.helpers.copyFromBay.bayOptions",
      type: "checkboxesList",
      fieldset: true,
      options: bayOptions,
      initialValue: initalValues.bayOptions || [],
    },
    {
      name: "slotOptions",
      type: "checkboxesList",
      label: "view:edit.helpers.copyFromBay.slotOptions",
      fieldset: true,
      options: slotOptions,
      initialValue: initalValues.slotOptions || slotOptions.map((v) => v.value),
    },
  ];

  const validator: z.Schema<ICopyFromBayData> = z.object({
    fromBay: z.string(),
    bayOptions: z.array(z.any()),
    slotOptions: z.array(z.string()),
  });
  return { fields, validator };
}

function executeCopyFromData(
  { fromBay: src, bayOptions, slotOptions }: ICopyFromBayData,
  updateDetailsScreen: (data: IEditBayFields) => void,
  updateCellsData: (cellData: ISlotData[]) => void,
): void {
  const [fromIsoBay, level] = src.split("-").map(Number) as [
    number,
    BayLevelEnum,
  ];

  const fromBayData = ovdJsonStore.findBayInfo(pad3(fromIsoBay), level);

  if (!fromBayData) return;

  // 1. Update Bay Details
  const bayDataToChange: IEditBayFields = {};
  bayOptions.forEach((option) => {
    bayDataToChange[option as unknown as keyof IEditBayFields] =
      fromBayData[option];
  });

  if (Object.keys(bayDataToChange).length) updateDetailsScreen(bayDataToChange);

  // 2. Update Slots
  const srcPerSlotInfo = fromBayData.perSlotInfo;
  if (srcPerSlotInfo) {
    const slotNames = Object.keys(srcPerSlotInfo) as ISlotPattern[];
    const cellData = slotNames.map((slotName) => srcPerSlotInfo[slotName]);
    const slotOptionsAttrs = convertSlotOptionsToAttrs(slotOptions);

    if (cellData.length) {
      const copiedCellData: ISlotData[] = [];

      for (let i = 0; i < cellData.length; i += 1) {
        const newSlot = copySlotSelectedAttributes(
          cellData[i],
          slotOptionsAttrs,
        );

        if (newSlot) copiedCellData.push(newSlot);
      }

      updateCellsData(copiedCellData);
    }
  }

  // 3. Save preferences
  setPreferencesKeyAndValue(BAY_HELPERS_PREF_ID, {
    bayOptions,
    slotOptions,
  });

  function convertSlotOptionsToAttrs(slotOptions: string[]) {
    const attrs: ISlotOptionsAttrs = { misc: [], sizes: [], cones: [] };

    slotOptions.forEach((option) => {
      const [type, value] = option.split("-") as [string, TContainerLengths];

      if (type === "size") attrs.sizes.push(value);
      else if (type === "sizeC") attrs.cones.push(value);
      else attrs.misc.push(type as TAllowedKeys);
    });

    return attrs;
  }

  function copySlotSelectedAttributes(
    src: ISlotData,
    slotOptionsAttrs: ISlotOptionsAttrs,
  ): ISlotData {
    const cell: ISlotData = { pos: src.pos, sizes: {} };

    slotOptionsAttrs.sizes.forEach((size) => {
      if (src.sizes[size]) cell.sizes[size] = 1;
    });

    slotOptionsAttrs.cones.forEach((size) => {
      if (
        src.sizes[size] &&
        typeof src.sizes[size] === "object" &&
        (src.sizes[size] as ISlotSizeOptions).cone
      )
        cell.sizes[size] = { cone: 1 };
    });

    slotOptionsAttrs.misc.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (key && src[key]) (cell[key] as any) = src[key] as TYesNoEnum;
    });

    return cell;
  }
}

function createPasteToBaysFormFields(
  isoBay: IBayPattern,
  level: BayLevelEnum,
  sizeSummary: ISizeSummary,
) {
  const currentBay = Number(isoBay);

  const bayLabel = getTranslation("general:grid.bay");
  const options = generateBayLevelOptions({
    sizeSummary,
    level,
    isoBay,
    bayLabel,
    addLevelInLabel: false,
    includeSelf: true,
    disableSelf: true,
  });

  const bayOptions = [
    {
      value: "pairedBay",
      label: getTranslation("view:details.paired"),
      selected: true,
    },
    {
      value: "reeferPlugs",
      label: getTranslation("view:details.reeferPlugs"),
    },
    {
      value: "doors",
      label: getTranslation("view:details.doors"),
    },
    {
      value: "telescoping",
      label: getTranslation("view:details.telescoping"),
    },
  ];

  const initialValuesStr = getPreferencesValue(BAY_HELPERS_PREF_FROM_ID) as
    | string
    | undefined;

  const initalValues = (
    initialValuesStr
      ? JSON.parse(initialValuesStr)
      : { bayOptions: ["pairedBay"] }
  ) as ICopyToBayData;

  const fields: IFields<ICopyToBayData> = [
    {
      name: "fromBay",
      type: "hidden",
      initialValue: `${pad3(currentBay)}-${level}`,
    },
    {
      name: "toIsoBays",
      type: "checkboxesList",
      label: `${getTranslation(
        "view:edit.helpers.copyToBays.toBays",
      )} - ${getTranslation(`enums:BayLevelEnum.${BayLevelEnum[level]}`)}`,
      fixedOptionWidth: "100px",
      options,
      disabled: [`${currentBay}-${level}`],
      helpers: [
        {
          label: getTranslation("general:common.selectAll"),
          value: "all",
          onClick: (_, me) => me.selectAll(),
        },
        {
          label: getTranslation("general:common.selectNone"),
          value: "none",
          onClick: (_, me) => me.selectNone(),
        },
      ],
      fieldset: true,
    },
    {
      name: "bayOptions",
      label: "view:edit.helpers.copyFromBay.bayOptions",
      type: "checkboxesList",
      fieldset: true,
      options: bayOptions,
      initialValue: initalValues.bayOptions || [],
    },
  ];

  const validator: z.Schema<ICopyToBayData> = z.object({
    fromBay: z.string(),
    toIsoBays: z.array(z.any()).refine((data) => data && data.length > 0),
    bayOptions: z.array(z.any()),
  });

  return { fields, validator };
}

function executeCopyToData({
  fromBay: src,
  toIsoBays,
  bayOptions,
}: ICopyToBayData): EnumUpdateResult {
  const json = ovdJsonStore.currentJson;
  if (json === undefined) return EnumUpdateResult.OMMITED;

  const [fromIsoBay, levelStr] = src.split("-") as [IBayPattern, BayLevelEnum];

  const level = Number(levelStr);

  const fromBayLevelData = ovdJsonStore.findBayInfo(fromIsoBay, level);
  if (!fromBayLevelData) return EnumUpdateResult.OMMITED;

  if (!toIsoBays || !toIsoBays.length) {
    new IntegratedDialogError(
      document.body,
      true,
      getTranslation("general:common.close"),
    ).show(
      getTranslation("view:edit.helpers.copyToBays.error"),
      getTranslation("view:edit.helpers.copyToBays.noBaysSelected"),
    );
    return EnumUpdateResult.ERROR;
  }

  const bayLevelDataToCopy: IEditBayFields = {};

  const toIsoBaysArray = toIsoBays
    .map((b: string) => b.split("-")[0])
    .map(pad3);

  bayOptions.forEach((option) => {
    bayLevelDataToCopy[option as unknown as keyof IEditBayFields] =
      fromBayLevelData[option];
  });

  const srcPerSlotInfo = fromBayLevelData.perSlotInfo
    ? cloneObject(fromBayLevelData.perSlotInfo)
    : undefined;

  const baysToUpdate = json.baysData
    .filter((b) => b && toIsoBaysArray.includes(b.isoBay) && b.level === level)
    .map(cloneObject) as IBayLevelData[];

  for (let i = 0; i < toIsoBaysArray.length; i += 1) {
    const bayToUpdate = baysToUpdate[i];
    if (!bayToUpdate) continue;

    // 1. Update Bay Details
    bayOptions.forEach((option: keyof IBayLevelData) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (bayToUpdate as any)[option] = bayLevelDataToCopy[option];
    });

    // 2. Update Slots
    if (srcPerSlotInfo) {
      bayToUpdate.perSlotInfo = cloneObject(srcPerSlotInfo);
    }
  }

  baysToUpdate.forEach((b) => {
    ovdJsonStore.replaceBayInfo(b.isoBay, b.level, b, false);
  });

  ovdJsonStore.emitChange({
    type: OVDChangesEnum.BAY_INFO,
    data: baysToUpdate.map((b) => b.isoBay),
  });

  // 3. Save preferences
  setPreferencesKeyAndValue(BAY_HELPERS_PREF_FROM_ID, {
    bayOptions,
  });

  return EnumUpdateResult.OK;
}

interface IBayEditComponentData {
  isoBay: IBayPattern;
  level: BayLevelEnum;
  maxIsoBay: number;
  currentBayData: IBayLevelData | undefined;
  gridData: ICreateGridFromConfigProps<ISlotData>;
  availableLengths: Array<TContainerLengths>;
  featuresAllowed?: IFeaturesAllowed;
}

interface IRemapTiersFields extends Record<string, unknown> {
  newTier: number;
}

interface IPostSaveAction {
  isoBay: IBayPattern;
  level: BayLevelEnum;
  action: (bl: IBayLevelData) => IBayLevelData;
}

interface ICopyFromBayData extends Record<string, unknown> {
  fromBay: string;
  bayOptions: Array<keyof IBayLevelData>;
  slotOptions: string[];
}

interface ICopyToBayData extends Record<string, unknown> {
  fromBay: string;
  toIsoBays: Array<IBayPattern>;
  bayOptions: Array<keyof IBayLevelData>;
}

interface ISlotOptionsAttrs {
  misc: TAllowedKeys[];
  sizes: TContainerLengths[];
  cones: TContainerLengths[];
}

export interface IEditBayFields extends Record<string, unknown> {
  notes?: string;

  label20?: string;
  label40?: string;

  reeferPlugs?: ForeAftEnum;
  doors?: ForeAftEnum;
  pairedBay?: ForeAftEnum;

  reeferPlugLimit?: number;

  centerLineRow?: TYesNoEnum;
  athwartShip?: TYesNoEnum;
  foreHatch?: TYesNoEnum;
  ventilated?: TYesNoEnum;

  heatSrcFore?: TYesNoEnum;
  ignitionSrcFore?: TYesNoEnum;
  quartersFore?: TYesNoEnum;
  engineRmBulkFore?: TYesNoEnum;
  telescoping?: TYesNoEnum;
}

type TAllowedKeys =
  | "restricted"
  | "reefer"
  | "hazardousProhibited"
  | "coolStowProhibited";

function generateBayLevelOptions({
  sizeSummary,
  level,
  isoBay,
  bayLabel,
  addLevelInLabel = true,
  includeSelf = false,
  disableSelf = false,
}: {
  sizeSummary: ISizeSummary;
  level: BayLevelEnum;
  isoBay: string;
  bayLabel: string;
  addLevelInLabel?: boolean;
  includeSelf?: boolean;
  disableSelf?: boolean;
}) {
  const options: { value: string; label: string; disabled?: boolean }[] = [];

  for (let b = 1; b <= sizeSummary.isoBays; b += 2) {
    const pb = pad3(b);

    if (
      level === BayLevelEnum.ABOVE &&
      sizeSummary.minAboveTier !== undefined &&
      sizeSummary.maxAboveTier !== undefined &&
      (includeSelf || (!includeSelf && pb !== isoBay))
    )
      options.push({
        value: `${b}-${BayLevelEnum.ABOVE}`,
        label: `${bayLabel} ${pb} ${
          addLevelInLabel
            ? `, ${getTranslation("enums:BayLevelEnum.ABOVE")}`
            : ""
        }`,
        disabled: disableSelf && pb === isoBay,
      });

    if (
      level === BayLevelEnum.BELOW &&
      sizeSummary.minBelowTier !== undefined &&
      sizeSummary.maxBelowTier !== undefined &&
      (includeSelf || (!includeSelf && pb !== isoBay))
    )
      options.push({
        value: `${b}-${BayLevelEnum.BELOW}`,
        label: `${bayLabel} ${pb} ${
          addLevelInLabel
            ? `, ${getTranslation("enums:BayLevelEnum.BELOW")}`
            : ""
        }`,
        disabled: disableSelf && pb === isoBay,
      });
  }

  return options;
}

enum EnumUpdateResult {
  OMMITED,
  OK,
  ERROR,
}
