import "./login.component.scss";

import { IntegratedDialog, IntegratedDialogError } from "@tedivo/tedivo-ui";
import {
  getPreferencesValue,
  setPreferencesKeyAndValue,
} from "@tedivo/tedivo-preferences";
import { getTranslation, i18nReactive } from "../../../../app/i18/i18tn";

import { DivWithSpinner } from "@tedivo/tedivo-ui";
import { I18nComponentRegisterer } from "@tedivo/tedivo-i18";
import ICustomError from "../../../types/ICustomError";
import { IFormAndNode } from "../../../helpers/createScreen";
import USER_LINKS from "./UserLinks";
import awsCognito from "../../../../app/security/awsCognito";
import { formatDateInCurrentTimezone } from "@tedivo/tedivo-pure-helpers";
import goSquared from "../../../../app/tracking/goSquared";
import { removeChildren } from "@tedivo/tedivo-dom-helpers";
import { routeFns } from "../../../../app/router/routes";
import router from "../../../../app/router";
import securityModule from "../../../../app/security/SecurityModule";
import { setAppTitle } from "../../../../app/setAppTitle";
import topMessageElement from "../../../layout/top-tools/getTopMessageElement";

export class TVDAuthProfileComponent extends HTMLElement {
  public static observedAttributes = [];

  private i18nCR: I18nComponentRegisterer;
  private divWithSpinner: DivWithSpinner;
  private h1Node: HTMLHeadingElement;
  private prevLoginStatus: boolean | undefined = undefined;

  private currentFormAndNode?:
    | IFormAndNode<IAuthLoginFields>
    | IFormAndNode<IEmpty>
    | IFormAndNode<IAuthNewPassword>
    | IFormAndNode<IAuthVerifyMfa>;

  private dialogError: IntegratedDialogError = new IntegratedDialogError(
    this,
    false,
    getTranslation("general:common.close"),
  );

  private dialogForMfa: IntegratedDialog = new IntegratedDialog({
    parentNode: this,
  });

  private dialogForChangeOrg: IntegratedDialog = new IntegratedDialog({
    parentNode: this,
  });

  constructor() {
    super();
    this.i18nCR = new I18nComponentRegisterer(i18nReactive);
    this.divWithSpinner = document.createElement("div-spinner-element");
    this.h1Node = document.createElement("h1");

    setAppTitle(getTranslation("auth:myProfile"));
  }

  /** Replaces the UI */
  private setCurrentFormAndNode = (fan: typeof this.currentFormAndNode) => {
    this.currentFormAndNode = fan;

    if (fan?.node) {
      this.divWithSpinner.setLoading(false);
      removeChildren(this.divWithSpinner);
      this.divWithSpinner.appendChild(fan.node);
    }
  };

  connectedCallback() {
    this.appendChild(this.divWithSpinner);
    this.showScreen();

    goSquared.trackPage("Profile page");

    securityModule.addEventListener("loggedIn", this.onShowScreen);
    securityModule.addEventListener("loggedOut", this.onShowScreen);
  }

  private onShowScreen = () => {
    this.showScreen(false);
  };

  /** Shows/Replaces the log-in screen based on securityModule.isLoggedIn, force = false */
  showScreen = (force = false) => {
    const { isLoggedIn } = securityModule;

    if (!isLoggedIn) {
      router.navigate(routeFns.login(), undefined, true);
      return;
    }

    if (this.prevLoginStatus === isLoggedIn && !force) return;

    this.prepend(this.h1Node);

    goSquared.addEvent("Profile - Show page");
    setAppTitle(getTranslation("auth:loggedInDetails"));

    this.setCurrentFormAndNode(
      createProfileScreen(this.h1Node, this.logOut, this.i18nCR, this),
    );

    if (router.getRouteHash()["setMfa"] && !securityModule.isMfaEnabled) {
      this.setMfa();
    }

    const hasMultipleOrgs = awsCognito.allOrganizationsInfo.length > 1;
    const preferredOrgId = getPreferencesValue("preferredOrgId");

    if (
      hasMultipleOrgs &&
      preferredOrgId !== securityModule.currentOrganizationId
    ) {
      this.changeOrganization();
    }

    this.prevLoginStatus = isLoggedIn;

    topMessageElement.planIsExpired = securityModule.planIsExpired;
  };

  setMfa = () => {
    if (!securityModule.isMfaEnabled) {
      this.dialogForMfa.updateOptions({
        preventClose:
          securityModule.organizationMfaForAllUsers &&
          !securityModule.isMfaEnabled,
      });
      openDialogAndHandleSettingMfa(this.dialogForMfa, this.showScreen);
    } else {
      this.dialogForMfa.updateOptions({ preventClose: false });
      openDialogAndHandleRemovingMfa(this.dialogForMfa);
    }

    function openDialogAndHandleRemovingMfa(dialog: IntegratedDialog) {
      const input = document.createElement("sl-input");
      input.type = "number";
      input.noSpinButtons = true;
      input.label = getTranslation("auth:mfa.mfaCode");
      input.placeholder = getTranslation("auth:mfa.mfaApp3");
      input.autofocus = true;
      window.setTimeout(() => input.focus(), 500);

      const holder = document.createElement("div");
      holder.className = "login-mfa-holder";
      holder.appendChild(input);

      dialog
        .updateOptions({
          buttonsAttrs: [
            {
              value: "remove",
              type: "submit",
              variant: "danger",
              label: getTranslation("auth:mfa.removeMfa"),
            },
          ],
        })
        .show(getTranslation("auth:mfa.setMfa"), holder);

      dialog.onButtonClicked = async () => {
        const val = String(input.value || "").trim();
        if (val === "") {
          input.focus();
          return false;
        }

        const isVerified =
          await securityModule.verifyAndFinishMfaDeviceAssociation(val);

        if (isVerified) {
          await awsCognito.enableMfa(false);

          notifyAndSignOut({
            errorCode: "",
            message: "auth:mfa.mfaChanged",
            translationKey: "auth:mfa.mfaRemoved",
          });
          return true;
        } else {
          document.body.dispatchEvent(
            new CustomEvent<ICustomError>("customError", {
              detail: {
                errorCode: "MFA_TOO_SOON",
                message: "auth:mfa.setMfa",
                translationKey: "auth:mfa.mfaCodeTooSoon",
              },
            }),
          );
          return false;
        }
      };
    }

    function openDialogAndHandleSettingMfa(
      dialog: IntegratedDialog,
      showScreen: (s: boolean) => void,
    ) {
      const root = getComputedStyle(document.body),
        fillColor = root.getPropertyValue("--sl-color-primary-700"),
        foreColor = root.getPropertyValue("--sl-color-neutral-0");

      securityModule.associateMfaDevice({
        associateSecretCode: (secretCode) => {
          const username = securityModule.email;
          const url = `otpauth://totp/${username}?secret=${secretCode}&issuer=TEDIVO${
            process.env.NX_PUBLIC_STAGE === "prod"
              ? ""
              : `-${process.env.NX_PUBLIC_STAGE}`
          }&digits=6&period=30&label=${securityModule.email}`;

          const qrCode = document.createElement("sl-qr-code");
          qrCode.size = 220;
          qrCode.fill = fillColor;
          qrCode.background = foreColor;
          qrCode.value = url;

          const text = document.createElement("p");
          text.innerHTML = getTranslation("auth:mfa.mfaApp");

          const input = document.createElement("sl-input");
          input.type = "number";
          input.noSpinButtons = true;
          input.label = getTranslation("auth:mfa.mfaApp2");
          input.placeholder = getTranslation("auth:mfa.mfaApp3");
          input.autofocus = true;
          window.setTimeout(() => input.focus(), 500);

          const theCode = document.createElement("code");
          theCode.innerHTML = secretCode;

          const holder = document.createElement("div");
          holder.className = "login-mfa-holder";

          if (securityModule.organizationMfaForAllUsers) {
            const preText = document.createElement("p");
            preText.className = "warning-text";
            const strong = document.createElement("strong");
            strong.innerHTML = getTranslation("auth:mfa.mfaForAllUsersWhy");
            preText.appendChild(strong);
            holder.appendChild(preText);
          }

          holder.appendChild(text);
          holder.appendChild(qrCode);
          holder.appendChild(theCode);
          holder.appendChild(input);

          dialog
            .updateOptions({
              buttonsAttrs: [
                {
                  value: "go",
                  type: "submit",
                  label: getTranslation("general:common.ok"),
                },
              ],
              width: "600px",
            })
            .show(getTranslation("auth:mfa.setMfa"), holder);

          dialog.onButtonClicked = async () => {
            const val = String(input.value).trim();
            if (val === "") {
              input.focus();
              return false;
            }

            const isVerified =
              await securityModule.verifyAndFinishMfaDeviceAssociation(val);

            if (isVerified) {
              await awsCognito.enableMfa(true);
              notifyAndSignOut({
                errorCode: "",
                message: "auth:mfa.mfaChanged",
                translationKey: "auth:mfa.mfaChangedContent",
              });
            } else {
              router.navigate(routeFns.login(), undefined, true);
            }

            return isVerified;
          };
        },
        onFailure: (err) => {
          console.error(err);
          showScreen(true);
        },
      });
    }

    async function notifyAndSignOut(err: ICustomError) {
      document.body.dispatchEvent(
        new CustomEvent<ICustomError>("customError", {
          detail: err,
        }),
      );
      await securityModule.signOut();
    }
  };

  changeOrganization = () => {
    const currentOrganizationId = securityModule.currentOrganizationId;

    const selectOrganizations = document.createElement("sl-select");
    selectOrganizations.name = "select-organization";
    selectOrganizations.hoist = true;
    selectOrganizations.multiple = false;

    awsCognito.allOrganizationsInfo
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name))
      .forEach((org) => {
        const option = document.createElement("sl-option");
        option.value = org.orgId;
        option.innerHTML = `${org.name} - ${getTranslation(
          `enums:UserTypeEnum.${org.role}`,
        )}`;

        if (org.uEnabled === "0" || org.oEnabled === "0")
          option.disabled = true;

        selectOrganizations.appendChild(option);
      });

    selectOrganizations.role = "listbox";
    selectOrganizations.label = getTranslation("auth:selectOrganization");
    selectOrganizations.placeholder = getTranslation("auth:selectOrganization");
    selectOrganizations.value = currentOrganizationId;

    this.dialogForChangeOrg.updateOptions({
      buttonsAttrs: [
        {
          value: "change",
          type: "submit",
          label: getTranslation("general:common.change"),
        },
      ],
    });

    this.dialogForChangeOrg.onButtonClicked = () => {
      const newOrgId = selectOrganizations.value as string;

      if (newOrgId === currentOrganizationId) return true;

      const changed = awsCognito.changeCurrentOrganization(newOrgId);

      if (changed) {
        setPreferencesKeyAndValue("preferredOrgId", newOrgId);
        router.navigate(routeFns.myCloud(), undefined, true);
        topMessageElement.planIsExpired = securityModule.planIsExpired;
        return true;
      }

      return false;
    };

    this.dialogForChangeOrg.show(
      getTranslation(
        currentOrganizationId
          ? "auth:changeOrganization"
          : "auth:selectOrganization",
      ),
      selectOrganizations,
    );
  };

  logOut = (ev: Event) => {
    ev.preventDefault();
    this.divWithSpinner.setLoading(true);
    goSquared.addEvent("Log-Out - Exec");

    securityModule.signOut().then(() => {
      router.navigate(routeFns.login(), undefined, true);
    });

    topMessageElement.planIsExpired = false;

    this.divWithSpinner.setLoading(false);
    return false;
  };

  disconnectedCallback() {
    this.i18nCR.disconnect();

    securityModule.removeEventListener("loggedIn", this.onShowScreen);
    securityModule.removeEventListener("loggedOut", this.onShowScreen);
  }

  showError = (e: ICustomError) => {
    this.dialogError.show(
      getTranslation(`errors:errorHasOcurred`),
      getTranslation(typeof e === "string" ? e : e.translationKey),
    );

    this.dialogError.onButtonClicked = () => {
      this.showScreen(true);
    };

    console.error(e);
  };
}

customElements.define("tvd-auth-profile-component", TVDAuthProfileComponent);

declare global {
  interface HTMLElementTagNameMap {
    "tvd-auth-profile-component": TVDAuthProfileComponent;
  }
}

function createProfileScreen(
  h1Node: HTMLHeadingElement,
  onSubmitDataForm: (ev: Event) => void,
  i18nCR: I18nComponentRegisterer,
  component: TVDAuthProfileComponent,
): IFormAndNode<IEmpty> {
  const h1 = h1Node;

  const wrapper = document.createElement("div");

  const logoutButton = document.createElement("sl-button");
  logoutButton.type = "submit";
  i18nCR.addConsumer(logoutButton, "auth:logOut", "innerHTML");
  logoutButton.variant = "primary";

  const box = document.createElement("div");

  const form = document.createElement("form");

  const section = document.createElement("section");
  section.className = "loggedin-user-sections oss-card";
  box.appendChild(section);

  USER_LINKS.forEach(({ label, links, congnitoCondition }) => {
    if (congnitoCondition && !congnitoCondition(awsCognito)) return;

    const lis = links
      .filter(
        ({ systemRight }) =>
          !systemRight || securityModule.userHasPermission(systemRight),
      )
      .map(({ label, route, action, variant, url, description }) => {
        const li = document.createElement("li");
        const a = document.createElement(url ? "a" : "link-element");
        if (variant) a.classList.add(variant);

        i18nCR.addConsumer(a, label, "text");

        if (url) {
          a.setAttribute("href", url);
          a.setAttribute("target", "_blank");
          a.innerHTML = getTranslation(label);
        } else if (route) {
          a.setAttribute("url", route);
        } else if (action) {
          a.dataset.action = action;
          a.onclick = (ev) => {
            ev.preventDefault();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (component as any)?.[action]();
          };
        }

        li.appendChild(a);

        if (description) {
          const p = document.createElement("p");
          p.className = "hlp-description";
          p.innerHTML = getTranslation(description);
          li.appendChild(p);
        }

        return li;
      });

    if (lis.length === 0) return;

    const div = document.createElement("div");
    div.className = "loggedin-user-section";

    const hx = document.createElement("h4");
    i18nCR.addConsumer(hx, label, "innerHTML");
    div.appendChild(hx);

    const ul = document.createElement("ul");
    div.appendChild(ul);

    lis.forEach((li) => ul.appendChild(li));

    section.appendChild(div);
  });

  form.onsubmit = onSubmitDataForm;
  form.appendChild(logoutButton);

  const titleTKey = "auth:loggedInDetails";
  h1.innerHTML = `${securityModule.displayName} - ${securityModule.currentOrganizationName}`;

  i18nCR.addConsumer(topMessageElement.element, titleTKey, "innerHTML");

  wrapper.appendChild(h1);
  wrapper.appendChild(box);

  const expirationMessage = document.createElement("p");
  expirationMessage.className = "plan-expiration-message";

  expirationMessage.innerHTML = getTranslation(
    "general:organizations.expirationDateOfPlan",
    {
      planId: getTranslation(
        `enums:OrgPlanTypeEnum.${securityModule.currentOrganizationPlanId}`,
      ),
      date: securityModule.planExpirationDate
        ? formatDateInCurrentTimezone(securityModule.planExpirationDate)
        : getTranslation("general:organizations.noExpirationDate"),
    },
  );

  wrapper.appendChild(expirationMessage);

  wrapper.appendChild(form);

  return {
    node: wrapper,
  };
}

interface IAuthLoginFields {
  username: string;
  password: string;
}

interface IAuthNewPassword {
  password: string;
  retypePassword: string;
  acceptsConditions: boolean;
  acceptsPrivacyPolicy: boolean;
  acceptsMarketing?: boolean;
}

interface IEmpty {
  id?: string;
}

interface IAuthVerifyMfa {
  code: string;
}
