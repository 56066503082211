import { IntegratedDialog } from "@tedivo/tedivo-ui";
import { getTranslation } from "../../../app/i18/i18tn";

export function createNotFoundImoDialog({
  parentNode,
  imoCode,
  onButtonClicked,
}: {
  parentNode: HTMLElement;
  imoCode: string;
  onButtonClicked: (v: "fix" | "continue") => void;
}): void {
  const commonDialog: IntegratedDialog<"fix" | "continue"> =
    new IntegratedDialog({
      parentNode,
      preventDefaultClose: true,
      width: "600px",
      buttonsAttrs: [
        {
          label: getTranslation("general:imoValidation.notFound.btnOk"),
          value: "continue",
          outlined: true,
          type: "button",
        },
        {
          label: getTranslation("general:imoValidation.notFound.btnFix"),
          value: "fix",
          type: "submit",
          autofocus: true,
          variant: "warning",
        },
      ],
    });

  commonDialog.onButtonClicked = onButtonClicked;

  commonDialog.show(
    getTranslation("general:imoValidation.notFound.title"),
    getTranslation("general:imoValidation.notFound.message", { imoCode }),
  );
}
