import { IDictionaryDisplayElement } from "@tedivo/tedivo-ui";
import { i18n } from "./i18tn";

export default function getTranslatedDictionary(
  elements: Array<IDictionaryDisplayElement>,
): Array<IDictionaryDisplayElement> {
  return elements.map((dict) =>
    Object.assign({}, dict, { label: i18n.t(dict.label) }),
  );
}
