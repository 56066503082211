import { EnumHelpers, roundDec } from "@tedivo/tedivo-pure-helpers";
import { GRID_OPTIONS_BY_CELL_DIMENSION, GRID_OPTIONS_BY_CELL_GAP, } from "./gridConfigConstants";
import { getBaySlots, } from "@tedivo/tedivo-bay-grid-pure";
import { calculateGridFromConfigDimensions, } from "@tedivo/tedivo-bay-grid-core";
import { createSelectShoelace } from "@tedivo/tedivo-form";
import CellSpacingEnum from "../../../../../app/enums/CellSpacingEnum";
import GroupBaysPairedEnum from "../../../../../app/enums/GroupBaysPairedEnum";
import SizeSmallMidLargeEnum from "../../../../../app/enums/SizeSmallMidLargeEnum";
import { createFnSlotCell } from "./createFnSlotCell";
import { createLegend } from "./createLegend";
import { drawBayStackWeights } from "./drawBayStackWeights";
import { getGridNodesOptions } from "./getGridNodesOptions";
import { getTranslation } from "../../../../../app/i18/i18tn";
import ovdJsonStore from "../../../../../app/stores/OVDJsonStore";
import { removeChildren } from "@tedivo/tedivo-dom-helpers";
import { setPreferencesKeyAndValue } from "@tedivo/tedivo-preferences";
class BaysSection {
    constructor(props) {
        this.bayBoxesComponents = new Map();
        this.blocksOfBaysNodes = new Map();
        this.bayDrawOptions = {
            cellSize: SizeSmallMidLargeEnum.SMALL,
            cellSpacing: CellSpacingEnum.NO_SPACE,
            baysPairedGrouped: GroupBaysPairedEnum.NOT_GROUPED,
            fit2Vertical: false,
        };
        this._scale = 1;
        this.props = props;
        const { mainNode, legendNode, baysNode, helpersNode, sizeSelShoelace, cellpaddingSelShoelace, groupedByBaySwitch, fit2VerticalSwitch, } = buildHtml({
            bayDrawOptions: this.bayDrawOptions,
        });
        this.mainNode = mainNode;
        this.baysNode = baysNode;
        this.legendNode = legendNode;
        this.helpersNode = helpersNode;
        this.sizeSelShoelace = sizeSelShoelace;
        this.cellpaddingSelShoelace = cellpaddingSelShoelace;
        this.groupedByBaySwitch = groupedByBaySwitch;
        this.fit2VerticalSwitch = fit2VerticalSwitch;
        this.nodesOptions = getGridNodesOptions();
    }
    calcAndSetScale(scaleActive) {
        let availableHeight = 0;
        let realScale = 1;
        const json = ovdJsonStore.currentJson;
        const isSmallSize = this.bayDrawOptions.cellSize === SizeSmallMidLargeEnum.SMALL;
        if (scaleActive && isSmallSize && (json === null || json === void 0 ? void 0 : json.sizeSummary.maxRow) !== undefined) {
            let baysTitlePartHeight = 60;
            const baysTitlePart = document.getElementById("bays-title-part");
            if (baysTitlePart)
                baysTitlePartHeight = baysTitlePart.clientHeight;
            availableHeight = window.innerHeight - baysTitlePartHeight;
            const { height } = calculateGridFromConfigDimensions(Object.assign(Object.assign(Object.assign(Object.assign({}, json.sizeSummary), GRID_OPTIONS_BY_CELL_DIMENSION[this.bayDrawOptions.cellSize]), GRID_OPTIONS_BY_CELL_GAP[this.bayDrawOptions.cellSpacing]), { maxRow: json.sizeSummary.maxRow }));
            realScale = roundDec(availableHeight / (2 * (60 + height + 30)), 5);
        }
        if (realScale > 1)
            realScale = 1;
        this.bayBoxesComponents.forEach((comp) => {
            comp.scale = realScale;
        });
        this._scale = realScale;
    }
    render({ bayDrawOptions, baysToRender, lcgVcgTcgAndPairings, }) {
        this.bayDrawOptions = bayDrawOptions;
        const json = ovdJsonStore.currentJson;
        if (!json || !lcgVcgTcgAndPairings) {
            return;
        }
        const shipData = json.shipData;
        this.nodesOptions = getGridNodesOptions();
        if (!baysToRender) {
            // Legend
            removeChildren(this.legendNode);
            this.legendNode.appendChild(createLegend(shipData.containersLengths, shipData.featuresAllowed));
            // Helpers
            removeChildren(this.helpersNode);
            if (this.props.bayHelpersButton)
                this.helpersNode.appendChild(this.props.bayHelpersButton);
        }
        this.sizeSelShoelace.value = String(bayDrawOptions.cellSize);
        this.cellpaddingSelShoelace.value = String(bayDrawOptions.cellSpacing);
        this.groupedByBaySwitch.checked =
            bayDrawOptions.baysPairedGrouped === GroupBaysPairedEnum.GROUPED;
        this.fit2VerticalSwitch.checked = bayDrawOptions.fit2Vertical;
        // Bays
        this.renderBays(json, bayDrawOptions, baysToRender, lcgVcgTcgAndPairings);
    }
    renderBays(json, bayDrawOptions, baysToDraw, lcgVcgTcgAndPairings) {
        const { shipData, baysData: bls, sizeSummary, lidData } = json;
        const maxRow = sizeSummary.maxRow;
        if (maxRow === undefined || maxRow === null) {
            return document.createElement("div");
        }
        const { cellSize: size, cellSpacing: cellpadding } = bayDrawOptions;
        this.baysNode.className = `bays-wrapper cSize-${SizeSmallMidLargeEnum[size]} cSep-${CellSpacingEnum[cellpadding]}`;
        // Create divs for each block of bays
        const { blockBaysAndSizes, blockBaysAndSizesBy20Bay } = lcgVcgTcgAndPairings;
        blockBaysAndSizes.forEach((b, idx) => {
            const blockNode = this.blocksOfBaysNodes.get(b.allBays);
            const newOrder = String(idx);
            if (blockNode) {
                const order = blockNode.dataset.order;
                if (newOrder !== order) {
                    blockNode.style.order = newOrder;
                }
            }
            else {
                const newBlockNode = document.createElement("div");
                newBlockNode.className = "block-bays";
                newBlockNode.id = `block-bays-${b.allBays}`;
                newBlockNode.style.order = newOrder;
                this.blocksOfBaysNodes.set(b.allBays, newBlockNode);
                this.baysNode.appendChild(newBlockNode);
            }
        });
        // Grid dimensions
        const { width, height } = calculateGridFromConfigDimensions(Object.assign(Object.assign(Object.assign(Object.assign({}, sizeSummary), GRID_OPTIONS_BY_CELL_DIMENSION[size]), GRID_OPTIONS_BY_CELL_GAP[cellpadding]), { maxRow }));
        // Validation
        const validationResult = ovdJsonStore.ovdValidator.getLastResult();
        // Calculate the lids of each bay
        const lidsOfBay = createLidsDictByBay(lidData);
        const uniqueBays = getUniqueBays(bls);
        uniqueBays
            .filter((isoBay) => !baysToDraw || baysToDraw.includes(isoBay))
            .forEach((isoBay) => {
            let bayBoxComp = this.bayBoxesComponents.get(isoBay);
            if (!bayBoxComp) {
                // Create new bay box component
                bayBoxComp = document.createElement("bay-box-component");
                bayBoxComp.setAttribute("id", `bay-box-component-${isoBay}`);
                bayBoxComp.style.order = isoBay;
                // Initialize the component
                bayBoxComp.setDrawingColors(this.nodesOptions).setDrawingProps({
                    createFnSlotCell,
                    createEditBayButtonFunction: this.props.createEditBayButtonFunction,
                    drawStackWeights: drawBayStackWeights,
                });
                // Save the component for reuse in next render
                this.bayBoxesComponents.set(isoBay, bayBoxComp);
            }
            bayBoxComp
                .setDrawingColors(this.nodesOptions)
                .setSize(width, height)
                .setData(Object.assign({ sizeSummary,
                shipData, lidData: lidsOfBay[isoBay] || [], isoBay,
                width,
                height, ssMaxRow: maxRow, cellSize: size, cellSeparation: cellpadding, validationResult }, getBaySlots(isoBay, bls)));
        });
        // Commands over each BayBoxComponent
        uniqueBays.forEach((isoBay) => {
            var _a;
            const bayBoxComp = this.bayBoxesComponents.get(isoBay);
            if (!bayBoxComp)
                return;
            // 1. Append the component to the bays node
            const blockOfBaysNodeName = (_a = blockBaysAndSizesBy20Bay[isoBay]) === null || _a === void 0 ? void 0 : _a.allBays;
            if (!blockOfBaysNodeName)
                return;
            const blockOfBaysNode = this.blocksOfBaysNodes.get(blockOfBaysNodeName);
            if (!blockOfBaysNode)
                return;
            if (bayBoxComp.parentNode !== blockOfBaysNode) {
                blockOfBaysNode.appendChild(bayBoxComp);
            }
            bayBoxComp.scale = this._scale;
        });
    }
}
export default BaysSection;
function buildHtml({ bayDrawOptions }) {
    const mainNode = document.createElement("div");
    mainNode.className = `oss-card holder-bays`;
    const baysNode = document.createElement("div");
    const legendNode = document.createElement("div");
    const helpersNode = document.createElement("div");
    helpersNode.className = "helpers-holder helpers-bays";
    const titlePart = document.createElement("title-with-actions-component");
    titlePart.titleHtml = getTranslation("view:bayDataTitle");
    titlePart.id = "bays-title-part";
    titlePart.titleNodeName = "h2";
    const { cellSize, cellSpacing, baysPairedGrouped, fit2Vertical } = bayDrawOptions;
    // Actions
    const groupedByBaySwitch = document.createElement("sl-switch");
    groupedByBaySwitch.innerHTML = getTranslation("view:baysGrouped");
    groupedByBaySwitch.size = "small";
    groupedByBaySwitch.className = "margin-left-05";
    groupedByBaySwitch.addEventListener("sl-change", (e) => {
        const newBayGrouped = e.target.checked
            ? GroupBaysPairedEnum.GROUPED
            : GroupBaysPairedEnum.NOT_GROUPED;
        setPreferencesKeyAndValue("bay-grouped-paired", newBayGrouped);
        document.documentElement.dispatchEvent(new CustomEvent("bayDisplayPairedGroupChanged", {
            detail: {
                newBayGrouped,
                newBaySize: undefined,
                newCellSeparation: undefined,
                fit2Vertical: undefined,
            },
        }));
    });
    const fit2VerticalSwitch = document.createElement("sl-switch");
    fit2VerticalSwitch.innerHTML = getTranslation("view:fit2Vertical");
    fit2VerticalSwitch.size = "small";
    fit2VerticalSwitch.className = "margin-left-05";
    fit2VerticalSwitch.addEventListener("sl-change", (e) => {
        const fit2Vertical = e.target.checked;
        setPreferencesKeyAndValue("bay-fit2-vertical", fit2Vertical);
        document.documentElement.dispatchEvent(new CustomEvent("bayDisplayFit2VerticalChanged", {
            detail: {
                newBayGrouped: undefined,
                newBaySize: undefined,
                newCellSeparation: undefined,
                fit2Vertical,
            },
        }));
    });
    const sizeSelShoelace = createSelectShoelace({
        id: "baySize",
        caret: true,
        buttonText: getTranslation("view:bayDataSize"),
        options: EnumHelpers.getNamesAndValues(SizeSmallMidLargeEnum).map(({ name, value }) => ({
            value,
            name: getTranslation(`enums:SizeSmallMidLargeEnum.${name}`),
        })),
        onChange: (newVal, oldVal) => {
            if (newVal === oldVal)
                return;
            let fit2Vertical = undefined;
            const newBaySize = Number(newVal);
            if (newBaySize === SizeSmallMidLargeEnum.SMALL) {
                fit2Vertical = fit2VerticalSwitch.checked;
                fit2VerticalSwitch.disabled = false;
            }
            else {
                fit2Vertical = false;
                fit2VerticalSwitch.disabled = true;
            }
            setPreferencesKeyAndValue("bay-data-size", newBaySize);
            document.documentElement.dispatchEvent(new CustomEvent("bayDisplaySizeChanged", {
                detail: {
                    newBaySize,
                    newCellSeparation: undefined,
                    newBayGrouped: undefined,
                    fit2Vertical,
                },
            }));
        },
    });
    const cellpaddingSelShoelace = createSelectShoelace({
        id: "cellSeparation",
        caret: true,
        buttonText: getTranslation("view:bayCellSeparation"),
        options: EnumHelpers.getNamesAndValues(CellSpacingEnum).map(({ name, value }) => ({
            value,
            name: getTranslation(`enums:CellSpacingEnum.${name}`),
        })),
        onChange: (newVal, oldVal) => {
            if (newVal === oldVal)
                return;
            const newCellSeparation = Number(newVal);
            setPreferencesKeyAndValue("bay-cell-separation", newCellSeparation);
            document.documentElement.dispatchEvent(new CustomEvent("bayDisplaySizeChanged", {
                detail: {
                    newBaySize: undefined,
                    newCellSeparation,
                    newBayGrouped: undefined,
                    fit2Vertical: undefined,
                },
            }));
        },
    });
    sizeSelShoelace.value = String(cellSize);
    cellpaddingSelShoelace.value = String(cellSpacing);
    fit2VerticalSwitch.checked = fit2Vertical;
    groupedByBaySwitch.value =
        baysPairedGrouped === GroupBaysPairedEnum.GROUPED ? "true" : "false";
    const bayViewOptionsDiv = document.createElement("div");
    bayViewOptionsDiv.className = "bayView-options";
    bayViewOptionsDiv.appendChild(fit2VerticalSwitch);
    bayViewOptionsDiv.appendChild(groupedByBaySwitch);
    bayViewOptionsDiv.appendChild(sizeSelShoelace);
    bayViewOptionsDiv.appendChild(cellpaddingSelShoelace);
    const actionsDiv = document.createElement("div");
    actionsDiv.appendChild(helpersNode);
    actionsDiv.appendChild(bayViewOptionsDiv);
    actionsDiv.slot = "actions";
    titlePart.appendChild(actionsDiv);
    mainNode.appendChild(titlePart);
    mainNode.appendChild(legendNode);
    mainNode.appendChild(baysNode);
    return {
        mainNode,
        baysNode,
        legendNode,
        helpersNode,
        sizeSelShoelace,
        cellpaddingSelShoelace,
        groupedByBaySwitch,
        fit2VerticalSwitch,
    };
}
function getUniqueBays(bls) {
    return bls
        .map((bl) => bl.isoBay)
        .filter((v, idx, arr) => arr.indexOf(v) === idx);
}
function createLidsDictByBay(lidData) {
    return lidData.reduce((acc, v) => {
        if (!acc[v.startIsoBay])
            acc[v.startIsoBay] = [];
        if (!acc[v.endIsoBay])
            acc[v.endIsoBay] = [];
        acc[v.startIsoBay].push(v);
        if (v.startIsoBay !== v.endIsoBay)
            acc[v.endIsoBay].push(v);
        return acc;
    }, {});
}
