import { getTranslation, i18nReactive } from "../../../app/i18/i18tn";

import { I18nComponentRegisterer } from "@tedivo/tedivo-i18";
import { IFile } from "@tedivo/tvd-api-models";
import SlDialog from "@shoelace-style/shoelace/dist/components/dialog/dialog";
import { TVDVoteTvlFileComponent } from "./voteFileButton";
import { TitleWithActions } from "../../common/titleWithActions/title-with-actions.component";
import { cloneFileDialog } from "./cloneFileDialog";
import goSquared from "../../../app/tracking/goSquared";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import { setAppTitle } from "../../../app/setAppTitle";
import topMessageElement from "../../layout/top-tools/getTopMessageElement";

export class TVDViewTvlComponent extends HTMLElement {
  public static observedAttributes = [];

  private titleNode: TitleWithActions | undefined = undefined;
  private btnVoteFileBtn: TVDVoteTvlFileComponent;
  private cloneDialog: SlDialog;

  private fileOrgId: string | undefined = undefined;
  private fileId: string | undefined = undefined;
  private fileName: string | undefined = undefined;

  private i18nCR: I18nComponentRegisterer;

  constructor() {
    super();

    this.btnVoteFileBtn = document.createElement("tvd-vote-tvl-component");
    this.cloneDialog = document.createElement("sl-dialog");

    const state = router.currentState as { vesselName: string };
    if (state?.vesselName) this.fileName = state.vesselName;

    this.i18nCR = new I18nComponentRegisterer(i18nReactive);
  }

  connectedCallback() {
    goSquared.trackPage("View TVD File");
    setAppTitle(getTranslation("general:viewTvl.title"));

    this.fileId = router.getRouteParams().id;
    this.fileOrgId = router.getRouteParams().orgId;

    if (!this.fileOrgId || !this.fileId) {
      router.navigate(routeFns.myCloud());
      return;
    }

    this.btnVoteFileBtn.fileOrgId = this.fileOrgId;
    this.btnVoteFileBtn.fileId = this.fileId;

    const divWithTitle = this.createNodes();
    const iframe = this.loadIframe(this.fileId);

    this.appendChild(divWithTitle);
    this.appendChild(iframe);

    const box = this.getBoundingClientRect();
    iframe.style.height = `calc(100vh - ${box.top + 100}px)`;

    const topEle = topMessageElement.element;
    if (topEle) topEle.innerHTML = getTranslation("general:tvl");
  }

  private createNodes() {
    const divWithTitle = document.createElement("title-with-actions-component");
    divWithTitle.titleNodeName = "h1";
    divWithTitle.titleHtml = "general:viewTvl.title";
    this.titleNode = divWithTitle;

    const actionsNode = document.createElement("div");
    actionsNode.slot = "actions";
    actionsNode.className = "slot-actions";

    const btnClone = document.createElement("sl-button");
    const iconClone = document.createElement("sl-icon");
    iconClone.name = "cloud-download-fill";
    iconClone.slot = "prefix";

    btnClone.innerHTML = getTranslation("general:search.cloneItToMyAccount");
    btnClone.variant = "primary";
    btnClone.size = "small";
    btnClone.addEventListener("click", () => {
      this.cloneToMyAccount({
        name: this.fileName || "",
        fileId: this.fileId || "",
        organizationId: this.fileOrgId || "",
      });
    });

    btnClone.appendChild(iconClone);

    actionsNode.appendChild(btnClone);
    actionsNode.appendChild(this.btnVoteFileBtn);

    divWithTitle.appendChild(actionsNode);

    return divWithTitle;
  }

  private loadIframe(cloudId: string) {
    let fullTitle = getTranslation("general:viewTvl.title");

    const iframe = document.createElement("iframe");
    iframe.className = "tvd-view-tvl-iframe";
    iframe.src = `${process.env.NX_PUBLIC_TVL_URL}/redirect/${cloudId}`;

    iframe.addEventListener("load", () => {
      try {
        const title = iframe.contentWindow?.document.body.innerHTML;
        if (title) {
          fullTitle = `${getTranslation("general:viewTvl.title")}: ${title}`;
          this.setTitle(fullTitle);
          setAppTitle(fullTitle);
        }
      } catch (e) {
        console.warn("Error getting title from iframe", e);
      }
    });

    return iframe;
  }

  private setTitle = (title: string) => {
    if (!this.titleNode) return;
    this.titleNode.titleHtml = title;
  };

  private cloneToMyAccount = async (
    dta: Pick<IFile, "name" | "organizationId" | "fileId">,
  ) => {
    cloneFileDialog({
      onlyCommunity: true,
      actionLabel: "general:search.cloneItToMyAccount",
      goSquaredLabel: "TVL - Clone file",
      modal: this.cloneDialog,
      fileData: dta,
    });

    this.appendChild(this.cloneDialog);
  };
}

customElements.define("tvl-view-component", TVDViewTvlComponent);

declare global {
  interface HTMLElementTagNameMap {
    "tvl-view-component": TVDViewTvlComponent;
  }
}
