import IDictionaryDisplayElement, {
  IDictionaryDisplayType,
} from "../types/IDictionaryDisplayElement";

import { RecursiveKeyOf } from "@tedivo/tedivo-pure-helpers";

export function mapDataToDictionary<T>(
  data: T,
  mapConfig: IMapConfigArray<T>,
  language: string,
): Array<IDictionaryDisplayElement> {
  return mapConfig.map(
    ({ key, label, type, format, undefinedIsDash, breakBefore, className }) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const v: any = getNestedValue<T>(data, key, type, format, language);

      return {
        value: v === undefined ? (undefinedIsDash ? "-" : "") : v,
        label,
        type,
        format: v === undefined ? undefined : format,
        breakBefore,
        className:
          typeof className === "function" ? className(data) : className,
      };
    },
  );
}

export type IMapConfigArray<T> = Array<IMapConfigPart<T>>;

interface IMapConfigPart<T> {
  key: RecursiveKeyOf<T>;
  label: string;
  type: IDictionaryDisplayType;
  format?: (
    v: string | string[] | number | boolean | Date | undefined,
  ) => string;
  undefinedIsDash?: boolean;
  breakBefore?: boolean;
  className?: string | ((v: T) => string);
}

export function getNestedValue<T>(
  data: T,
  key: RecursiveKeyOf<T>,
  type: IDictionaryDisplayType,
  format?: IFormat,
  language = "en",
): string | number | boolean | Array<string> | object | undefined {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let v = data as any;

  const keyParts = key.split(".") as (keyof T)[];

  for (let i = 0; i < keyParts.length; i += 1) {
    v = v[keyParts[i]];
    if (v === undefined) return undefined;
  }

  if (type === "number") {
    if (format === undefined) {
      const nn = new Intl.NumberFormat(language);
      return nn.format(v as number);
    } else {
      return String(v);
    }
  }

  if (
    typeof v === "string" ||
    typeof v === "number" ||
    typeof v === "boolean" ||
    typeof v === "object" ||
    Array.isArray(v)
  )
    return v;

  return undefined;
}

type IFormat = (
  v: string | string[] | number | boolean | Date | undefined,
) => string;
