enum OrgPlanTypeEnum {
  "FREE" = "FREE",
  "PAID1" = "PAID1",
  "PAID2" = "PAID2",
}

export default OrgPlanTypeEnum;

export const ORG_PLAN_OPTIONS = [
  {
    value: OrgPlanTypeEnum.FREE,
    translationLabel: "enums:OrgPlanTypeEnum.FREE",
  },
  {
    value: OrgPlanTypeEnum.PAID1,
    translationLabel: "enums:OrgPlanTypeEnum.PAID1",
  },
  {
    value: OrgPlanTypeEnum.PAID2,
    translationLabel: "enums:OrgPlanTypeEnum.PAID2",
  },
];
