import {
  ForeAftEnum,
  IBayLevelData,
  ISizeSummary,
} from "open-vessel-definition";

import { IEditBayFields } from "./bay-edit.component";
import { IFields } from "@tedivo/tedivo-form";
import { getTranslation } from "../../../../../../app/i18/i18tn";

export function createBayDetailsFormFields(
  sizeSummary: ISizeSummary,
  bayData: IBayLevelData,
  dataHasZeroRow: boolean,
) {
  const notesLabelNode = document.createElement("span");
  const notesIcon = document.createElement("sl-icon");
  notesIcon.setAttribute("name", "journal-text");
  notesLabelNode.appendChild(notesIcon);
  notesLabelNode.appendChild(document.createTextNode(" "));
  notesLabelNode.appendChild(
    document.createTextNode(getTranslation("view:edit.bayLevelData.notes")),
  );

  const aftFwdOptions = [
    {
      value: ForeAftEnum.AFT,
      label: getTranslation(`enums:ForeAftEnum.AFT`),
    },
    {
      value: ForeAftEnum.FWD,
      label: getTranslation(`enums:ForeAftEnum.FWD`),
    },
    { value: 0, label: getTranslation(`general:common.none`) },
  ];

  const bayDetailsFormFields: IFields<IEditBayFields> = [
    [
      {
        name: "centerLineRow",
        label: "view:centerLineRow",
        type: "checkbox",
        isNumericEnum: true,
        initialValue: bayData?.centerLineRow,
        defaultValue: 0,
        disabled:
          (!sizeSummary.centerLineRow && !dataHasZeroRow) ||
          (!!sizeSummary.centerLineRow && dataHasZeroRow),
        helpText: !sizeSummary.centerLineRow
          ? "view:centerLineRowNotSet"
          : dataHasZeroRow
          ? "view:centerLineRowDisabled"
          : "",
      },
      {
        name: "athwartShip",
        label: "view:details.athwartShip",
        type: "checkbox",
        isNumericEnum: true,
        initialValue: bayData?.athwartShip,
        defaultValue: 0,
      },
    ],
    {
      name: "pairedBay",
      label: "view:details.paired",
      type: "radioButtonList",
      isNumericEnum: true,
      fieldset: true,
      initialValue: bayData?.pairedBay,
      defaultValue: 0,
      options: aftFwdOptions,
    },
    [
      {
        name: "doors",
        label: "view:details.doors",
        type: "radioButtonList",
        isNumericEnum: true,
        fieldset: true,
        initialValue: bayData?.doors,
        defaultValue: 0,
        options: aftFwdOptions,
      },
      {
        name: "reeferPlugs",
        label: "view:details.reeferPlugs",
        type: "radioButtonList",
        isNumericEnum: true,
        fieldset: true,
        initialValue: bayData?.reeferPlugs,
        defaultValue: 0,
        options: aftFwdOptions,
      },
    ],
    [
      {
        name: "foreHatch",
        label: "view:details.foreHatch",
        type: "checkbox",
        initialValue: bayData?.foreHatch,
        defaultValue: 0,
        isNumericEnum: true,
      },
      {
        name: "ventilated",
        label: "view:details.ventilated",
        type: "checkbox",
        initialValue: bayData?.ventilated,
        defaultValue: 0,
        isNumericEnum: true,
      },
    ],
    [
      {
        name: "heatSrcFore",
        label: "view:details.heatSrcFore",
        type: "checkbox",
        initialValue: bayData?.heatSrcFore,
        defaultValue: 0,
        isNumericEnum: true,
      },
      {
        name: "ignitionSrcFore",
        label: "view:details.ignitionSrcFore",
        type: "checkbox",
        initialValue: bayData?.ignitionSrcFore,
        defaultValue: 0,
        isNumericEnum: true,
      },
    ],
    [
      {
        name: "quartersFore",
        label: "view:details.quartersFore",
        type: "checkbox",
        initialValue: bayData?.quartersFore,
        defaultValue: 0,
        isNumericEnum: true,
      },
      {
        name: "engineRmBulkFore",
        label: "view:details.engineRmBulkFore",
        type: "checkbox",
        initialValue: bayData?.engineRmBulkFore,
        defaultValue: 0,
        isNumericEnum: true,
      },
    ],
    [
      {
        name: "telescoping",
        label: "view:details.telescoping",
        type: "checkbox",
        initialValue: bayData?.telescoping,
        defaultValue: 0,
        isNumericEnum: true,
      },
    ],
    [
      {
        name: "label20",
        label: "view:edit.bayLevelData.label20",
        type: "textBox",
        initialValue: bayData?.label20,
      },
      {
        name: "label40",
        label: "view:edit.bayLevelData.label40",
        type: "textBox",
        initialValue: bayData?.label40,
      },
    ],
    {
      name: "notes",
      labelNode: notesLabelNode,
      type: "textArea",
      initialValue: bayData?.meta?.notes,
    },
  ];

  return bayDetailsFormFields;
}
