import HttpClient, { IResponseModel } from "../HttpClient";
import {
  IResultVesselMasterByImoData,
  IVesselFinderStatus,
} from "@tedivo/tvd-api-models";

const VesselsMasterDataService = {
  searchVesselByImoCode: async (
    imoCode: string,
    forceApi = false,
  ): Promise<IResponseModel<IResultVesselMasterByImoData>> => {
    const client = new HttpClient();
    const url = [
      `vesselInfo/byImo/${encodeURI(imoCode)}`,
      forceApi ? "forceApi=true" : "",
    ]
      .filter(Boolean)
      .join("?");

    return await client.request<IResultVesselMasterByImoData>({
      method: "GET",
      url,
    });
  },

  adminGetVfCredits: async (): Promise<IResponseModel<IVesselFinderStatus>> => {
    const client = new HttpClient();
    return await client.request<IVesselFinderStatus>({
      method: "GET",
      url: `vesselInfo/getVfCredits`,
    });
  },
};

export default VesselsMasterDataService;
