// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class SimpleObserver<K extends any[]> {
  private subscribers = new Set<(...args: K) => void>();
  private args: K = [] as unknown as K;

  subscribe(observer: (...args: K) => void) {
    this.subscribers.add(observer);
  }

  unsubscribe(observer: (...args: K) => void) {
    this.subscribers.delete(observer);
  }

  unsubscribeAll() {
    this.subscribers.clear();
  }

  update(...args: K) {
    this.args = args;
    return this;
  }

  notify() {
    this.subscribers.forEach((observer) => observer(...this.args));
  }
}
