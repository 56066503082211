import { setBasePath } from "@shoelace-style/shoelace/dist/utilities/base-path";
import { setDefaultAnimation } from "@shoelace-style/shoelace/dist/utilities/animation-registry";

/** Changes animations behaviours and sets options */
export function setShoelaceModifications() {
  setBasePath("assets/shoelace");

  // Override animation of drawer
  setDefaultAnimation("drawer.showEnd", {
    keyframes: [
      { opacity: 0, transform: "translateX(0)" },
      { opacity: 1, transform: "translateX(0)" },
    ],
    rtlKeyframes: [
      { opacity: 0, transform: "translateX(0)" },
      { opacity: 1, transform: "translateX(0)" },
    ],
    options: { duration: 0, easing: "ease" },
  });

  setDefaultAnimation("drawer.denyClose", {
    keyframes: [{ scale: 1 }],
    options: { duration: 0 },
  });
}
