import { BayLevelEnum, IBayLevelData } from "open-vessel-definition";
import {
  GRID_OPTIONS_BY_CELL_DIMENSION,
  GRID_OPTIONS_BY_CELL_GAP,
} from "./gridConfigConstants";

import CellSpacingEnum from "../../../../../app/enums/CellSpacingEnum";
import { ICellPositions } from "@tedivo/tedivo-bay-grid-core";
import { ISlotCombinedPattern } from "@tedivo/tedivo-bay-grid-pure";
import MassUnitsEnum from "../../../../../app/enums/MassUnitsEnum";
import SizeSmallMidLargeEnum from "../../../../../app/enums/SizeSmallMidLargeEnum";
import { getStackWeightsOfBayLevel } from "./getStackWeightsOfBayLevel";
import { getTranslation } from "../../../../../app/i18/i18tn";
import globalUnits from "../../../../../app/units/globalUnits";
import { pad2 } from "@tedivo/tedivo-pure-helpers";

export function drawBayStackWeights({
  bayAbove,
  bayBelow,
  cellPositions,
  cellSize = SizeSmallMidLargeEnum.SMALL,
  cellPadding = CellSpacingEnum.NO_SPACE,
}: {
  bayAbove: IBayLevelData | undefined;
  bayBelow: IBayLevelData | undefined;
  cellPositions: ICellPositions;
  cellSize?: SizeSmallMidLargeEnum;
  cellPadding?: CellSpacingEnum;
}): HTMLElement {
  const { containerWidth, containerHeight } =
    GRID_OPTIONS_BY_CELL_DIMENSION[cellSize];
  const { cellSeparation } = GRID_OPTIONS_BY_CELL_GAP[cellPadding];

  const stackWeights = [
    ...getStackWeightsOfBayLevel(bayAbove, BayLevelEnum.ABOVE),
    ...getStackWeightsOfBayLevel(bayBelow, BayLevelEnum.BELOW),
  ];

  const stackWeightsDiv = document.createElement("div");
  stackWeightsDiv.setAttribute("part", "stack-weights");

  const massUnitsStr = getTranslation(
    `enums:unitsMinimal.MassUnitsEnum.${
      MassUnitsEnum[globalUnits.massUnits.units]
    }`,
  );

  const preText = `SW (${massUnitsStr}):`;

  stackWeights.forEach(({ row, sws, bottomTier }) => {
    const uniqueWeights = new Set([...sws.values()]);

    if (uniqueWeights.size === 0) return;

    const mapOfWeightsToSizes = new Map<number, number[]>();

    uniqueWeights.forEach((weight) => {
      mapOfWeightsToSizes.set(weight, []);
    });

    sws.forEach((weight, size) => {
      const sizes = mapOfWeightsToSizes.get(weight);
      if (sizes) sizes.push(size);
    });

    const text: string[] = [
      preText,
      ...Array.from(mapOfWeightsToSizes).reduce((acc, [weight, sizes]) => {
        acc.push(
          `${sizes
            .map((s) => `${s}ft`)
            .join("/")}: ${globalUnits.massUnits.fromValueToFormattedDisplay(
            weight,
          )}`,
        );
        return acc;
      }, [] as string[]),
    ];

    const textTitle: string[] = [
      ...Array.from(mapOfWeightsToSizes).reduce((acc, [weight, sizes]) => {
        acc.push(
          `${sizes
            .map((s) => `${s}ft`)
            .join("/")}: ${globalUnits.massUnits.fromValueToFormattedDisplay(
            weight,
          )} ${massUnitsStr}`,
        );
        return acc;
      }, [] as string[]),
    ];

    const cellPos =
      cellPositions[`${row}|${pad2(bottomTier)}` as ISlotCombinedPattern];
    const stackWeight = document.createElement("div");
    stackWeight.setAttribute("data-row", row);
    stackWeight.setAttribute("part", "stack-weight");
    stackWeight.style.left = `${cellPos.x + 1}px`;
    stackWeight.style.top = `${cellPos.y + containerHeight + cellSeparation}px`;
    stackWeight.style.width = `${containerWidth - 2}px`;
    stackWeight.innerHTML = text.join("<br>");
    stackWeight.title = `Stack Weight of row ${row}\n${textTitle.join("\n")}`;

    stackWeightsDiv.appendChild(stackWeight);
  });

  return stackWeightsDiv;
}
