import {
  ComponentWithFormAndNode,
  IFormAndNode,
} from "../../common/ComponentWithFormAndNode";

import { I18nComponentRegisterer } from "@tedivo/tedivo-i18";
import { IFields } from "@tedivo/tedivo-form";
import Services from "../../../app/services";
import awsCognito from "../../../app/security/awsCognito";
import { createScreen } from "../../helpers/createScreen";
import { getTranslation } from "../../../app/i18/i18tn";
import goSquared from "../../../app/tracking/goSquared";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import securityModule from "../../../app/security/SecurityModule";
import { setAppTitle } from "../../../app/setAppTitle";
import { z } from "zod";

const labelId = "auth:deleteAccount.deleteMyAccount";

export class TVDDeleteAccountComponent extends ComponentWithFormAndNode<IDeleteAccount> {
  public static observedAttributes = [];

  private mainBox: HTMLDivElement;

  constructor() {
    super();
    this.mainBox = document.createElement("div");
    this.mainBox.className = "oss-card";

    setAppTitle(getTranslation(labelId));
    goSquared.trackPage("Delete User Account");
  }

  connectedCallback() {
    this.appendChild(this.divWithSpinner);
    this.divWithSpinner.appendChild(this.mainBox);

    this.setCurrentFormAndNode(
      createDeleteAccountScreen(this.onSubmitDataForm, this.i18nCR),
    );
  }

  onSubmitDataForm = async (values: IDeleteAccount) => {
    this.divWithSpinner.setLoading(true);

    if (securityModule.isMfaEnabled) {
      if (!values.mfaCode) {
        this.showDialog("auth:deleteAccount.mfaCodeRequired");
        this.divWithSpinner.setLoading(false);
        return false;
      }
    }

    const resp = await Services.accounts.deleteMyUserAccount(
      values.mfaCode || "",
      awsCognito.accessToken || "",
    );

    this.divWithSpinner.setLoading(false);

    if (resp.statusCode !== 204) {
      this.showDialog(resp.message || "auth:deleteAccount.deleteAccountFailed");
      return false;
    } else {
      securityModule.signOut().then(() => {
        router.navigate(routeFns.login(), undefined, true);
      });
    }
  };
}

customElements.define(
  "tvd-delete-account-component",
  TVDDeleteAccountComponent,
);

declare global {
  interface HTMLElementTagNameMap {
    "tvd-delete-account-component": TVDDeleteAccountComponent;
  }
}

function createDeleteAccountScreen(
  onSubmitDataForm: (values: IDeleteAccount) => void,
  i18nCR: I18nComponentRegisterer,
): IFormAndNode<IDeleteAccount> | undefined {
  const DeleteAccountFormValidator = z.object({
    reType: z.string().refine((inp) => getTranslation(labelId) === inp),
    mfaCode: z
      .string()
      .optional()
      .refine((cc) => cc || !securityModule.isMfaEnabled),
  });

  const fields: IFields<IDeleteAccount> = [
    {
      type: "title",
      label: getTranslation("auth:deleteAccount.text"),
      createSection: false,
      tagName: "h3",
    },
    {
      type: "textBox",
      name: "reType",
      label: getTranslation("general:common.reTypeToConfirm", {
        word: getTranslation(labelId),
      }),
      placeholder: getTranslation(labelId),
      autoCompleteAttribute: "off",
    },
  ];

  if (securityModule.isMfaEnabled) {
    fields.push({
      type: "textBox",
      name: "mfaCode",
      label: "auth:deleteAccount.mfa",
      helpText: "auth:deleteAccount.mfaCodeRequired",
      autoCompleteAttribute: "one-time-code",
    });
  }

  const screen = createScreen<IDeleteAccount>({
    fields,
    onSubmitDataForm,
    formValidator: DeleteAccountFormValidator,
    i18nCR,
    h1Text: labelId,
    titleText: "menu:organization",
  });

  return screen;
}

interface IDeleteAccount {
  reType: string;
  mfaCode?: string;
}
