import { ActionTypesEnum, IActionVesselPart } from "../../partsReducer";
import {
  IBaseModalForPartsProps,
  createBaseModalForParts,
  createBasePartValidator,
  extractEffectiveRefId,
} from "./createBaseModalForParts";
import { IVesselPartBridge, VesselPartTypeEnum } from "open-vessel-definition";

import { getTranslation } from "../../../../../../../../../app/i18/i18tn";
import { z } from "zod";

type ICustomTypeBridgePartWithoutId = Omit<IVesselPartBridge, "id"> & {
  type: VesselPartTypeEnum.BRIDGE;
  id?: string; // For the edit
};

export function createModalForBridge({
  bridgeData,
  currentLabels,
  calculatedSlots,
  maxLen,
  defaultLen,
  onSubmitAction,
  spaceAvailableCalcFn,
}: {
  bridgeData: ICustomTypeBridgePartWithoutId;
  onSubmitAction: (data: IActionVesselPart) => void;
} & IBaseModalForPartsProps) {
  function createValidator(
    maxLen: number | undefined,
  ): z.Schema<ICustomTypeBridgePartWithoutId> {
    return createBasePartValidator(maxLen).and(
      z.object({
        type: z.literal(VesselPartTypeEnum.BRIDGE),
        heatSrcBelow: z.boolean(),
      }),
    );
  }

  const id = bridgeData.id;

  const dialog = createBaseModalForParts<ICustomTypeBridgePartWithoutId>({
    data: bridgeData,
    type: VesselPartTypeEnum.BRIDGE,
    maxLen,
    defaultLen,
    calculatedSlots,
    partFields: [
      {
        name: "heatSrcBelow",
        type: "checkbox",
        label: "view:view3D.partsParams.heatSrcBelow",
        initialValue: bridgeData.heatSrcBelow,
      },
    ],
    currentLabels: currentLabels,
    createValidator,
    spaceAvailableCalcFn,
    onSubmit: (data, cmd) => {
      if (data) {
        if (id) {
          const cmd: IActionVesselPart = {
            action: ActionTypesEnum.REPLACE_BRIDGE_PART,
            id,
            details: {
              id,
              ...data,
            },
          };
          onSubmitAction(cmd);
        } else {
          const cmd: IActionVesselPart = {
            action: ActionTypesEnum.ADD_BRIDGE_PART,
            slotRefId: extractEffectiveRefId(data.slotRefId, data.posRef),
            posRef: data.posRef,
            details: {
              ...data,
            },
          };
          onSubmitAction(cmd);
        }
      } else {
        if (cmd) onSubmitAction(cmd);
      }
      dialog.hide();
    },
  });

  dialog.label = getTranslation("view:view3D.actions.addBridge");
  dialog.show();
}
